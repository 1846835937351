/* eslint-disable react/display-name */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { withInvoicesStore, WithInvoicesStoreProps } from '@src/stores/with-store';
import { LoadingState } from '@src/stores/models';
import { Loader, Table, TableHeader, ErrorToastDownload } from '@src/components';
import { InvoicesSingleDetails } from './InvoicesSingleDetails';

import './invoices.sass';
import { formatDate } from '@src/theme/utils/helpers';

export const InvoicesWithStore: React.FC<WithInvoicesStoreProps> = (
    props: WithInvoicesStoreProps
) => {
    const store = props.InvoicesStore;
    const loading = store.loadingState;
    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const transformPrice = (price: number) => (price ? `$${(price / 100).toFixed(2)}` : undefined);
    const invoices = store.invoices?.map((el) => {
        return {
            id: el.id,
            date: formatDate(moment.unix(el.created).toDate()),
            number: el.number,
            payment: `${store.billingAccount?.card?.brand} (#### #### #### ${store.billingAccount?.card?.last4})`,
            retailer: 'Myopolis',
            subscription: el.lines.data.map((d) => d.description).join(', '),
            tax: transformPrice(el.tax) || 'N/A',
            price: transformPrice(el.amount_due),
            pdf: el.invoice_pdf,
        };
    });
    const { path, url } = useRouteMatch();
    const HEADERS: TableHeader[] = [
        {
            title: 'Date',
            field: 'date',
            headerCls: ['left'],
            cls: ['left'],
            flexWidth: 3,
        },
        {
            title: 'Number',
            field: 'number',
            headerCls: ['left'],
            cls: ['left'],
            flexWidth: 3,
        },
        {
            title: 'Price',
            field: 'price',
            headerCls: ['left'],
            cls: ['left'],
            flexWidth: 2,
        },
        {
            title: 'Actions',
            template: (header, data) => (
                <div>
                    <Link to={`${url}/${data['id']}`} className="btn-primary">
                        View
                    </Link>
                </div>
            ),
            flexWidth: 2,
        },
    ];

    store.onError = (type: string) => {
        store.loadingState = LoadingState.Error;
        switch (type) {
            case 'getBE': {
                toast.error(<ErrorToastDownload getBEData={() => store.getInvoices('getBE')} />);
                break;
            }
            default: {
                return;
            }
        }
    };

    const getBEData = () => {
        store.getInvoices('getBE', isFirstLoading);
        setIsFirstLoading(false);
    };

    useEffect(() => {
        getBEData();
    }, []);

    useLayoutEffect(() => {
        store.init();
    }, []);

    return (
        <div className="Invoices">
            <Loader loadingState={loading} />
            <>
                {loading !== LoadingState.Init && loading !== LoadingState.FirstLoading && (
                    <>
                        <Switch>
                            <Route exact path={path}>
                                <div className="InvoicesList">
                                    <div className="InvoicesList__title">Invoices</div>
                                    <div className="InvoicesList__subtitle">
                                        Get your invoices details
                                    </div>
                                    {store.dataLoaded && (
                                        <div className="InvoicesList__list">
                                            <Table
                                                headers={HEADERS}
                                                data={invoices}
                                                totalCount={invoices.length}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Route>
                            <Route path={`${path}/:invoiceId`}>
                                <InvoicesSingleDetails />
                            </Route>
                        </Switch>
                    </>
                )}
            </>
        </div>
    );
};

export const Invoices = withInvoicesStore(InvoicesWithStore);
