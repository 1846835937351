import React, { useState } from 'react';
import { FormField } from '@src/components';
import { AsYouType } from 'libphonenumber-js';
import InputMask from 'react-input-mask';
import { withStores, STORES, WithSignupStoreProps } from '@src/stores/with-store';

import './NewNumber2.sass';

interface NewNumberProps {
    onNumberSelection: (number: string) => void;
}

type props = NewNumberProps & WithSignupStoreProps

export const NewNumber2WithStore: React.FC<props> = ({SignupStore, onNumberSelection}) => {
    const [numbers, setNumbers] = useState(null);
    const [emptyNumbers, setEmptyNumbers] = useState(false);

    const searchNumber = (num: string): void => {
        if (!num || num === "(___)" || num === "+1 (___) ___-____") {
            setEmptyNumbers(false);
            return;
        }
        const areaCode = num.slice(1, 4);
        if (areaCode.match(/^-{0,1}\d+$/)) {
            SignupStore.getNumbers({areaCode, regionCode: null}).then(() => {
                if (SignupStore.numbersToSelect.length !== 0) {
                    setNumbers(SignupStore.numbersToSelect);
                    setEmptyNumbers(false);
                } else {
                    setEmptyNumbers(true);
                }
            });
        } else if (numbers) setNumbers(null);
    };

    const pickNumber = (num): void => {
        setNumbers(null);
        onNumberSelection('+'.concat(num.replace(/\D/g, '')));
    };

    return (
        <div className="NewNumber">
            <h2 className="NewNumber__Header">Pick your own number</h2>
            <div>
                <div className="NewNumber__AeraCode">
                    <FormField
                        label='Area Code'
                        htmlFor="phone"
                    >
                        <InputMask
                            type="text"
                            mask='(999)'
                            maskChar="_"
                            name="phone"
                            placeholder="Type in first 3 digits"
                            className='form-input'
                            onChange={(e) => searchNumber(e.target.value)}
                        />
                    </FormField>
                </div>
                {numbers && (
                    <div className='NewNumber__Numbers'>
                        {numbers.slice(0,7).map((n: string, i: number) => {
                            return (
                                <div key={i} className="NewNumber__Numbers-item" onClick={() => pickNumber(n)}>
                                    {new AsYouType('US').input(('' + n).replace(/\D/g, ''))}
                                    <div className="greyBGOnHover">{new AsYouType('US').input(('' + n).replace(/\D/g, ''))}</div>
                                </div>
                            );
                        })}
                    </div>
                )}
                {emptyNumbers && (
                    <div className="NewNumber__Empty">
                        There are no phone numbers available for the specified area. Please try an alternative area code.
                    </div>
                )}
            </div>
        </div>
    );
};

export const NewNumber2 = withStores(NewNumber2WithStore, [STORES.Signup]);