import React from "react";
import {
    STORES,
    WithConversationsStoreProps,
    withStores,
    WithMessagesStoreProps,
} from "@src/stores/with-store";
import { Conversation } from "@src/stores/models/team-chat";
import { Loader } from '@src/components';


interface NewDirectMessageProps {
    onClose: () => void;
    onNewDirectConversation: (c: Conversation) => void;
}

type props = NewDirectMessageProps & WithMessagesStoreProps & WithConversationsStoreProps;

const NewDirectMessageWithStore: React.FC<props> = (props) => {
    const ConversationsStore = props.ConversationsStore;
    const { employees, employee } = props.MessagesStore.ThreadsStore.constants;
    const colleaguesWithAlreadyExistingChats = ConversationsStore.ConversationsListStore.directConversationsList.map(el => {
        const participants = el.participants.find(p => p.participantId !== employee.id);
        return participants.participantId
    });

    const colleaguesWithoutChats = Object.values(employees)
    .filter(e => e.id !== employee.id)
    .filter(e => {
        let isDiffrent = true;
        colleaguesWithAlreadyExistingChats.map(cId => {
            if (cId === e.id) isDiffrent = false;
        })
        return isDiffrent;
    });

    const startConversation = (c) => {
        ConversationsStore.SelectedDirectConversationStore.startConversation(c.id)
            .then((r) =>  {
                ConversationsStore.ConversationsListStore.addConversation(r);
                props.onClose();
                props.onNewDirectConversation(r);
            });
    };

    const sortResults = (a, b) => {
        const A = `${a.firstName?.toLowerCase()} ${a.lastName?.toLowerCase()}`;
        const B = `${b.firstName?.toLowerCase()} ${b.lastName?.toLowerCase()}`;
        if (A < B) {
            return -1;
        }
        if (A > B) {
            return 1;
        }
        return 0;
    };

    const TeamList =
        <div className="TagsChannelsEdition__content-view add">
            <div className="list newDirectMessage">
                {!colleaguesWithoutChats.length ?
                    <div>No employees to start conversation with.</div> :
                    colleaguesWithoutChats
                    .sort(sortResults)
                    .map((c, i) => {
                        return (
                            <div
                                key={i}
                                className="item"
                            >
                                <div className="name">
                                    {c.firstName} {c.lastName}
                                </div>
                                <div className="actions adding">
                                    <div
                                        className="btn-tetriary-blue"
                                        onClick={() => startConversation(c)}
                                    >
                                        Start
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>

    return (
        <div>
            <Loader loadingState={ConversationsStore.SelectedDirectConversationStore.directLoading} />
            <div className="TagsChannelsEdition">
                <div className="TagsChannelsEdition__title">
                    <span className="editionType">New Direct Team Conversation</span>
                </div>
                    {TeamList}
            </div>
        </div>
    );
}

export const NewDirectMessage = withStores(NewDirectMessageWithStore, [
    STORES.Messages,
    STORES.ConversationsStore
]);
