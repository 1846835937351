import { observable, action, runInAction } from 'mobx';
import {
    MainPhoneSettings,
    ForwardNumberData,
    FeatureFlags,
    LoadingState,
    OnErrorDataPhone,
} from './models';
import { phoneRequests } from '@src/requests';

export class PhoneStore {
    @observable smsSettings: MainPhoneSettings = {};
    @observable featureFlags: FeatureFlags = {};
    @observable forwardNumbers = [];
    @observable dataLoaded = false;
    @observable loadingState: LoadingState = LoadingState.Init;
    private _onError: (type: string, data?: OnErrorDataPhone) => void;
    private _onSuccess: () => void;

    set onSuccess(fn: () => void) {
        this._onSuccess = fn;
    }

    set onError(fn: (type: string, data?: OnErrorDataPhone) => void) {
        this._onError = fn;
    }

    @action init(): void {
        this.smsSettings = {};
        this.featureFlags = {};
        this.forwardNumbers = [];
        this.dataLoaded = false;
        this.loadingState = LoadingState.Init;
    }

    @action getBEData = (errAction: string, firstLoading = false) => {
        this.loadingState = firstLoading ? LoadingState.FirstLoading : LoadingState.Loading;
        phoneRequests.getSmsSettings().then(
            ({ data }) => {
                runInAction(() => {
                    const { smsSettings, forwardNumbers, featureFlags } = data;
                    this.forwardNumbers = forwardNumbers;
                    this.smsSettings = smsSettings;
                    this.featureFlags = featureFlags;
                    this.dataLoaded = true;
                    this.loadingState = LoadingState.Loaded;
                });
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction);
                    }
                });
            }
        );
    };

    @action addPhoneNumber = (number: string, errAction: string) => {
        this.loadingState = LoadingState.Loading;
        return phoneRequests.postNewForwardNumber(number).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return this.getBEData('getBE');
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { number });
                    }
                });
            }
        );
    };

    @action removePhoneNumber = (id: string, errAction: string) => {
        this.loadingState = LoadingState.Loading;
        return phoneRequests.removeForwardNumber(id).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return this.getBEData('getBE');
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { id });
                    }
                });
            }
        );
    };

    @action changeActivity = (data: ForwardNumberData, errAction: string) => {
        this.loadingState = LoadingState.Loading;
        return phoneRequests.toggleActive(data.id, !data.active).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return this.getBEData('getBE');
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { id: data.id, active: data.active });
                    }
                });
            }
        );
    };

    @action toggleSettings = (
        toggleWhat: string,
        id: string,
        active: boolean,
        errAction: string
    ) => {
        this.loadingState = LoadingState.Loading;
        return phoneRequests[toggleWhat](id, active).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return this.getBEData('getBE');
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { toggleWhat, id, active });
                    }
                });
            }
        );
    };

    @action changeMsg = (id: string, msg: string, errAction: string) => {
        this.loadingState = LoadingState.Loading;
        return phoneRequests.updateAutoMessage(id, msg).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return this.getBEData('getBE');
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { id, msg });
                    }
                });
            }
        );
    };

    @action uploadGreeting = (file: Blob, errAction: string) => {
        this.loadingState = LoadingState.Loading;
        return phoneRequests.greetingUpload(file).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return this.getBEData('getBE');
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { file });
                    }
                });
            }
        );
    };
}

export default new PhoneStore();
