import React from 'react';
import { AsYouType } from 'libphonenumber-js';

import { TwilioPhoneStore } from '@src/stores/twilio-phone.store';
import { STORES, withStores, WithUserStoreProps } from '@src/stores/with-store';

import './twilio-phone.sass';

type TwilioPhoneProps = WithUserStoreProps & {
    phoneNumber: number;
    TwilioPhoneStore: TwilioPhoneStore;
};

const TwilioPhoneWithStore: React.FC<TwilioPhoneProps> = (props) => {
    const store = props.TwilioPhoneStore;
    if (props.children && props.phoneNumber) {
        return (
            <div className="TwilioPhone" onClick={() => store.call(props.phoneNumber, props.UserStore.user.employee.id)}>
                {props.children}
            </div>
        );
    }

    return (
        <>
            {props.phoneNumber && (
                <a className="TwilioPhone" onClick={() => store.call(props.phoneNumber, props.UserStore.user.employee.id)}>
                    {new AsYouType('US').input(props?.phoneNumber.toString())}
                </a>
            )}
        </>
    );
};

export const TwilioPhone = withStores(TwilioPhoneWithStore, [STORES.User, STORES.TwilioPhoneStore]);
