import React, { useState, useEffect } from 'react';
import { Loader } from '@src/components';
import { images } from '@src/theme/images';
import { toast } from 'react-toastify';

import './Onboarding.sass';

interface BasicProps {
    setView?: (view: string) => void;
    setSucceeded?: (success: string) => void;
    uploadCustomers?: (file) => void;
}

export const CSV: React.FC<BasicProps> = (props) => {
    const [fileState, setFileState] = useState('');
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const example = () => {
        let csv = 'First Name, Last Name, Phone Number, Email, Company\n';
        const data = [
            ['John', 'Doe', '(123)-123-1234, john@doe.com', 'John Doe Company'],
            ['John', 'Not Doe', '(987)-987-9876, john@not.doe', 'John Not Doe Company'],
        ];

        data.forEach((r) => {
            csv += r.join(',');
            csv += '\n';
        });

        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'people.csv';
        hiddenElement.click();
    };

    const onFilesLoaded = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const files = ev.target.files;
        if (files.length < 1) {
            ev.target.value = null;
            return;
        };

        const filenameDivided = files[0].name.split('.');
        const extension = filenameDivided[filenameDivided.length - 1];
        if (extension !== 'csv') {
            ev.target.value = null;
            toast.error('Only csv files are accepted', { autoClose: 4000 });
            return;
        };

        setFile(files.item(0));
        ev.target.value = null;
    };

    const uploadCustomers = () => {
        props.uploadCustomers(file);
    };

    useEffect(() => {
        setFileState('');
        setFile(null);
        setError(false);
        setLoading(false);
    }, []);

    return (
        <div>
            <Loader loading={loading} />
            <h3>Upload Customers</h3>
            <p>
                You can upload your contacts into Myopolis to easily reach out to them about
                reviews, services, promotions…whatever you want!
            </p>
            <p>
                Import your customer list from a <b>.csv format file</b>{' '}
                <button className="Onboarding__Example" onClick={() => example()}>
                    (download example)
                </button>
                . Columns must be in the order first name, last name, phone number, email, and
                company.
            </p>
            <p>
                First name, last name, and phone are required. Email address and company are ideal
                but optional.
            </p>

            <div className="Onboarding__File">
                {fileState === 'error' ? (
                    <>
                        <div className="error">
                            {error ? (
                                <h3>
                                    Some values are missing. Check{' '}
                                    <button
                                        className="Onboarding__Example"
                                        onClick={() => example()}
                                    >
                                        example
                                    </button>{' '}
                                    file.
                                </h3>
                            ) : (
                                <h3>Ops, something went wrong!</h3>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <img src={images.upload} alt="upload" />
                        {file ? (
                            <>
                                <p className="file-name">{(file as File).name}</p>
                                <p>Simply drag and drop a .csv file here or click to replace</p>
                            </>
                        ) : (
                            <p>Simply drag and drop a .csv file here or click</p>
                        )}
                    </>
                )}
                <input
                    type="file"
                    accept=".csv"
                    onError={() => setFileState('error')}
                    onLoadStart={() => setFileState('loading')}
                    onLoadedData={() => setFileState('')}
                    onChange={(e) => onFilesLoaded(e)}
                />
            </div>

            <button
                className="btn-secondary backToPopulate"
                onClick={() => props.setView('populate')}
            >
                Back
            </button>
            <button className="btn-primary" onClick={() => uploadCustomers()} disabled={!file}>
                Upload
            </button>
        </div>
    );
};
