import React from 'react';
import './Pill.sass';

interface PillProps {
    show: boolean;
    text: string;
};

const Pill: React.FunctionComponent<PillProps> = ({show, text}) => {
    return (
        <div className={`Pill ${show ? 'Pill-visible' : ''}`}>
            {text}
        </div>
    );
};

export default Pill;
