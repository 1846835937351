import React, { PropsWithChildren, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Dialog, FormField, Loader, ProgressBar } from '@src/components';
import { images } from '@src/theme/icons/dashboard';
import { KeyMetricsStats, LoadingState } from '@src/stores/models';
import { Metric } from './../Metric/Metric';

import './keyMetrics.sass';
import { useForm } from 'react-hook-form';

interface KeyMetricsProps {
    period: string;
    stats: KeyMetricsStats;
    canEditGoal?: boolean;
    goalSaveState?: LoadingState;
    onEditGoal?: (newGoal: number) => Promise<void>;
}

export const KeyMetrics: React.FC<PropsWithChildren<KeyMetricsProps>> = ({
    children,
    period,
    stats,
    canEditGoal,
    goalSaveState,
    onEditGoal,
}) => {
    const [showGoalDialog, setShowGoalDialog] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const calcPart = (a, b): number => Math.abs(Math.round(((a / b) * 100 - 100) * 100) / 100); // 'a' as part of 'b'

    const reviewRequestExtra = () => {
        if (stats?.currentRequests === 0 && stats?.lastRequests === 0) {
            return 0;
        } else if (stats?.lastRequests === 0) {
            return 100;
        } else {
            return calcPart(stats?.currentRequests, stats?.lastRequests);
        }
    };

    const totalReviewsExtra = () => {
        if (stats?.currentTotal === 0 && stats?.lastRequests === 0) {
            return null;
        } else if (stats?.lastRequests === 0) {
            return 100;
        } else {
            return calcPart(stats?.currentTotal, stats?.lastRequests);
        }
    };

    const setPeriodToCompareFn = (p) => {
        return p === 'This Year'
            ? 'last year'
            : p === 'This Month'
            ? 'last month'
            : p === 'This Week'
            ? 'last week'
            : p === 'Today'
            ? 'last day'
            : null;
    };
    const [periodToCompare, setPeriodToCompare] = useState(setPeriodToCompareFn(period));

    useEffect(() => {
        setPeriodToCompare(setPeriodToCompareFn(period));
    }, [period]);

    useEffect(() => {
        if (goalSaveState) {
            setShowGoalDialog(false);
        }
    }, [goalSaveState]);

    const goalValues = () => {
        if (!stats?.reviewGoal) {
            if (canEditGoal) {
                return null;
            }
        }

        return (
            <>
                <div className="data">
                    <img src={images.goal} alt="goal" />
                    {stats?.reviewGoalTotal || 0}/{stats?.reviewGoal || 0}
                </div>
                <ProgressBar current={stats?.reviewGoalTotal} total={stats?.reviewGoal} />
            </>
        );
    };

    const onNewGoalSubmit = (form: { newGoal: string }) => {
        if (onEditGoal && form?.newGoal) {
            onEditGoal(+form.newGoal);
        }
    };

    return (
        <div className="KeyMetrics card">
            <div className="Metric">
                <h3 className={classNames('title', { small: period?.length > 15 })}>Review Goal</h3>
                {goalValues()}
                {canEditGoal && (
                    <button
                        className={classNames({
                            'btn-primary': true,
                            'no-border': !!stats?.reviewGoal,
                        })}
                        onClick={() => setShowGoalDialog(true)}
                    >
                        {!!stats?.reviewGoal ? 'Edit' : 'Set Goal'}
                    </button>
                )}
            </div>

            <Metric
                titleRow1="Review"
                titleRow2="Request Sent"
                iconName="calendar"
                toShow={stats?.currentRequests || 0}
                toCompare={stats?.lastRequests}
                hideComparing={Boolean(!periodToCompare)}
            >
                {reviewRequestExtra()}% vs {periodToCompare}
            </Metric>

            <Metric
                titleRow1="Total Reviews"
                titleRow2={`${period}`}
                titleSizeRow2={period?.length > 15 ? 'small' : null}
                iconName="total"
                toShow={stats?.currentTotal || 0}
                toCompare={stats?.lastTotal}
                hideComparing={Boolean(!periodToCompare)}
            >
                {stats?.currentTotal == stats?.lastTotal ? '' : totalReviewsExtra() + '% '}
                {stats?.currentTotal == stats?.lastTotal
                    ? 'the same as ' + periodToCompare
                    : stats?.currentTotal > stats?.lastTotal
                    ? 'higher increase'
                    : 'lower increase'}
            </Metric>

            <Metric
                titleRow1="Overall Rating"
                titleRow2={`${period}`}
                titleSizeRow2={period?.length > 15 ? 'small' : null}
                iconName="star"
                toShow={stats?.currentRating || 0}
                toCompare={stats?.lastRating}
                hideComparing={Boolean(!periodToCompare)}
            >
                {stats?.currentRating == stats?.lastRating
                    ? 'the same as ' + periodToCompare
                    : 'from ' + stats?.lastRating}
            </Metric>

            {children ? children : null}
            <Dialog
                className="SetGoal"
                title="Set Goal"
                onClose={() => setShowGoalDialog(false)}
                open={showGoalDialog}
            >
                <Loader loadingState={goalSaveState} />
                <form onSubmit={handleSubmit(onNewGoalSubmit)}>
                    <FormField label="New Goal" htmlFor="newGoal" error={errors.newGoal}>
                        <input
                            name="newGoal"
                            className={classNames('form-input', { error: errors.newGoal })}
                            defaultValue={stats?.reviewGoal}
                            ref={register({
                                required: 'Missing value',
                                pattern: {
                                    value: /^[0-9]+$/,
                                    message: 'Incorrect value - use only positive digits',
                                },
                            })}
                        />
                    </FormField>
                    <button className="btn-primary" type="submit">
                        Save
                    </button>
                </form>
            </Dialog>
        </div>
    );
};
