import React, { useEffect, useState } from 'react';
import { Dialog } from '@src/components';
import { ReactComponent as BroadcastSvg } from '@src/theme/icons/broadcast.svg';
import './ScheduleDialog.sass';
import Button from '../Button/Button';
import { Calendar } from 'react-date-range';
import { timezones } from '@src/stores/models/employee';
import { ScheduleTime, dateToScheduleTime, scheduleTimeToDate, formatScheduleTime } from '../helpers/date';

interface ScheduleDialogProps {
    initialDate?: Date;
    open: boolean;
    timeZone?: string;
    onClose: () => void;
    onScheduleClick?: (date: Date) => void;
};

const ScheduleDialog: React.FunctionComponent<ScheduleDialogProps> = ({ initialDate, open, timeZone, onClose, onScheduleClick }) => {
    const [date, setDate] = useState<Date>(initialDate || new Date());
    const [scheduleTime, setScheduleTime] = useState<ScheduleTime>(dateToScheduleTime(initialDate || new Date(), timeZone));

    const handleTimeChange = (key: string, value: string) => {
        const newTime = {
            ...scheduleTime,
            [key]: key === 'hours' || key === 'minutes' ? +value : value
        };
        setScheduleTime(newTime);
    };

    const handleCalendarChange = (range) => {
        const newDate = scheduleTimeToDate(scheduleTime, range as Date);
        setDate(newDate);
    };

    useEffect(() => {
        const newDate = scheduleTimeToDate(scheduleTime, date);
        setDate(newDate);
    },[scheduleTime]);

    return (
        <Dialog className="schedule-dialog" open={open} onClose={onClose}>
            <div className="dialog-content">
                <header>
                    <BroadcastSvg className="svg"/>
                    <h4>Schedule Broadcast</h4>
                    <p>Select date and time to send your broadcast.</p>
                </header>
                <Calendar
                    date={date}
                    minDate={new Date()}
                    onChange={handleCalendarChange}
                />
                <div className="time-bar">
                    <select
                        defaultValue={scheduleTime.hours}
                        onChange={(event) => {handleTimeChange('hours', event.target.value)}}
                    >
                        <option value={1}>01</option>
                        <option value={2}>02</option>
                        <option value={3}>03</option>
                        <option value={4}>04</option>
                        <option value={5}>05</option>
                        <option value={6}>06</option>
                        <option value={7}>07</option>
                        <option value={8}>08</option>
                        <option value={9}>09</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                    </select>
                    <select
                        defaultValue={scheduleTime.minutes}
                        onChange={(event) => {handleTimeChange('minutes', event.target.value)}}
                    >
                        <option value={0}>00</option>
                        <option value={15}>15</option>
                        <option value={30}>30</option>
                        <option value={45}>45</option>
                    </select>
                    <select
                        defaultValue={scheduleTime.ampm}
                        onChange={(event) => {handleTimeChange('ampm', event.target.value)}}
                    >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                    <select
                        defaultValue={scheduleTime.zone}
                        onChange={(event) => {handleTimeChange('zone', event.target.value)}}
                    >
                        {timezones.map((el) => (
                            <option key={el.value} value={el.value}>
                                {el.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="calendar-caption">
                    <p>Your broadcast will publish on</p>
                    <span>{date.toLocaleDateString()} at {formatScheduleTime(scheduleTime)}</span>
                </div>
                <Button fill="full" onClick={() => onScheduleClick(date)}>Schedule Broadcast</Button>
            </div>
        </Dialog>
    );
};

export default ScheduleDialog;
