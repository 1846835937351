import React, { useEffect, useRef, useState } from 'react';
import './CreateBroadcastCard.sass'
import { ReactComponent as BroadcastSvg } from '@src/theme/icons/broadcast.svg';
import './CreateBroadcastCard.sass'
import { FormField } from '@src/components/FormField/FormField';
import Button from '../Button/Button';
import ScheduleDialog from '../ScheduleDialog/ScheduleDialog';
import { ParsedPrompts } from '@src/stores/models/templates';
import { VariableInput } from '@src/components/VariableInput/VariableInput';
import { DraftEditorLocalStore } from "@src/components/VariableInput/DraftEditorLocalStore";
import { Attachment, Template } from '@src/stores/models';
import IconButtonBar, { IconButton } from '../IconButtonBar/IconButtonBar';
import { ReactComponent as AttachmentIcon } from '@src/theme/icons/attach.svg';
import { ReactComponent as TemplateIcon } from '@src/theme/icons/template.svg';
import { ReactComponent as VariableIcon } from '@src/theme/icons/variable.svg';
import { ReactComponent as EmojiIcon } from '@src/theme/icons/emoji.svg';
import { TemplatesMes } from '@src/pages/Messages/components/TemplatesMes/TemplatesMes';
import { STORES, withStores } from '@src/stores/with-store';
import { Broadcast } from '@src/stores/models/broadcast_new';
import RecipientsLists from './RecipientsLists';
import { v4 as uuidv4 } from 'uuid';
import { Picker as EmojiPicker } from 'emoji-mart'
import { Attachments } from '@src/components/Attachments';
import { AttachmentFile } from '@src/components/Attachments/attachment-file';

import MessagesStore from '@src/stores/messages/messages.store';
import { BroadcastsStore } from '@src/stores/broadcasts.store';
import RecipientsPicker from './RecipientsPicker';
import { RecipientOption, BroadcastReceipientsLocalStore, recipientOptionsToEditRecipients } from './BroadcastReceipientsLocalStore.store';

import 'emoji-mart/css/emoji-mart.css'
import { isAttachmentFile } from '@src/components/Attachments/AttachmentTile';

const sendSecsPerRecipient = 1;

export const getEstimatedTimeInMins = (recipientsTotal: number) => {
    return Math.ceil((120 + recipientsTotal * sendSecsPerRecipient) / 60);
};

export interface FormData {
    attachments: Attachment[];
    name: string;
    message: string;
    recipients: RecipientOption[];
    schedule?: Date;
};

const emptyFormData: FormData = {
    name: '',
    message: '',
    recipients: [],
    attachments: []
};

const broadcastToFormData = (broadcast?: Broadcast): FormData => {
    if (!broadcast) {
        return emptyFormData;
    }
    return ({
        name: broadcast.name,
        message: broadcast.text,
        recipients: [],
        schedule: broadcast.scheduled,
        attachments: broadcast.attachments
    })
};

interface PickerContainerProps {
    className?: string;
    onPickerOutsideClick: () => void;
}

const PickerContainer: React.FunctionComponent<PickerContainerProps> = ({className, children, onPickerOutsideClick}) => (
    <div className={`picker-container ${className}`}>
        <div className="screen-cover" onClick={() => onPickerOutsideClick()}/>
        {children}
    </div>
);

interface CreateBroadcastCardProps {
    BroadcastsStore: BroadcastsStore;
    broadcast?: Broadcast;
    timeZone?: string;
    onPublish?: () => void;
    // onSchedule?: () => void;
};

type ActivePickerType = 'attachment' | 'emoji' | 'mention' | 'template' | null;

const CreateBroadcastCardWithStores: React.FunctionComponent<CreateBroadcastCardProps> = ({ broadcast, timeZone, onPublish, ...props }) => {
    const [scheduleDialogOpen, setScheduleDialogOpen] = useState<boolean>(false);
    const [template, setTemplate] = useState<string>('');
    const [activePicker, setActivePicker] = useState<ActivePickerType>(null);
    const [chosenAttachments, setChosenAttachments] = useState<Array<AttachmentFile | Attachment>>(broadcast?.attachments ?? []);
    const [formData, setFormData] = useState(broadcastToFormData(broadcast));
    const [broadcastReceipientsLocalStore] = useState(() => new BroadcastReceipientsLocalStore(props.BroadcastsStore))
    const [draftEditorLocalStore] = useState(() => new DraftEditorLocalStore())
    const [openMentions, setOpenMentions] = useState<boolean>(false);

    const attachmentInputRef = useRef(null);

    const loadRecipients = () => {
        broadcastReceipientsLocalStore.loadRecipientsFromOtherBroadcast(history.state.broadcastId, history.state.state);
    };

    useEffect(()=> {
        const history = window.history.state
        if(history && history.state && history.state.broadcastId && history.state.state) {
            loadRecipients();
        }
    }, []);

    const changeActivePicker = (picker: ActivePickerType) => {
        setActivePicker(prevPicker => prevPicker === picker ? null : picker);
        setOpenMentions(picker === 'mention')
        if (picker === 'attachment') {
            attachmentInputRef.current.click();
        };
    };

    const messageToolButtons: IconButton[] = [
        {
            icon: <AttachmentIcon />,
            onClick: () => changeActivePicker('attachment')
        },
        {
            icon: <TemplateIcon />,
            onClick: () => changeActivePicker('template')
        },
        {
            icon: <VariableIcon />,
            onClick: () => changeActivePicker('mention')
        },
        {
            icon: <EmojiIcon />,
            onClick: () => changeActivePicker('emoji')
        }
    ];

    const isFormFilled = () => (formData && formData.message && formData.message !== '' && broadcastReceipientsLocalStore.recipients.results.length > 0);

    const handleBroadcastNameChange = (value: string) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            name: value
        }));
    };

    const scheduleOrPublish = (date?: Date) => {
        if (!!broadcast?.id) {
            props.BroadcastsStore.editBroadcast({
                ...formData,
                id: broadcast.id,
                schedule: date ?? null
            });
        } else {
            props.BroadcastsStore.createBroadcast({
                ...formData,
                recipients: recipientOptionsToEditRecipients(broadcastReceipientsLocalStore.recipients.results),
                schedule: date ?? null
            });
        };
    };

    const handlePublish = () => {
        scheduleOrPublish();
        onPublish && onPublish();
    };

    const handleScheduleClick = (date: Date) => {
        setScheduleDialogOpen(false);
        scheduleOrPublish(date);
        // onSchedule && onSchedule();
    };

    const handleMessageChange = (value: string) => {
        if (value === undefined) {
            return;
        };
        setFormData(prevData => ({
            ...prevData,
            message: value
        }));
    };

    const handleAttachmentInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeActivePicker(null)
        const files = event.target.files;
        const newAttachments: AttachmentFile[] = [];
        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);
            file['id'] = uuidv4();
            newAttachments.push(file as AttachmentFile);
        };
        setChosenAttachments(prevAttachments => [...prevAttachments, ...newAttachments])
    };

    const updateAttachments = (newAttachments: (Attachment | AttachmentFile)[]) => {
        setChosenAttachments([...newAttachments]);
        const uploaded = newAttachments.filter(attachment => !isAttachmentFile(attachment));
        setFormData(prevFormData => ({
            ...prevFormData,
            attachments: [...uploaded]
        }));
    };

    const handleAttachmentUploaded = (att: Attachment) => {
        const attachments = chosenAttachments;
        const idx = attachments.findIndex((x) => x.id === att.id);
        if (idx < 0) {
            return;
        };
        attachments[idx] = att;
        updateAttachments(attachments);
    };

    const handleRemoveAttachment = (attId: string) => {
        const attachments = chosenAttachments;
        const idx = attachments.findIndex((x) => x.id === attId);
        if (idx < 0) {
            return;
        };
        attachments.splice(idx, 1);
        updateAttachments(attachments);
    };

    const handleTemplateClick = (template: Template) => {
        changeActivePicker(null);
        setTemplate(template.message);
    };

    const runRecipientsQuery = () => {
        broadcastReceipientsLocalStore.runRecipientsQuery(broadcast.id);
    };

    useEffect(() => {
        !!broadcast && runRecipientsQuery();
    }, []);

    return (
        <div className="create-broadcast-card">
            <div className="main">
                <header>
                    <BroadcastSvg className="svg"/>
                    <h4>{props.BroadcastsStore.currentStatus === 'CREATE' ? 'Create' : 'Edit'} Broadcast</h4>
                    <p>Send a message to a larger audience with a Broadcast.</p>
                    <p>Select multiple users, tags, or a combination of both.</p>
                </header>
                <form>
                    <FormField label="Name of the Broadcast" htmlFor="broadcast-name">
                        <input
                            type="text"
                            name="broadcast-name"
                            className="form-input"
                            defaultValue={formData.name}
                            onChange={(e) => handleBroadcastNameChange(e.target.value)}
                        />
                    </FormField>
                    <FormField label="Add Recipients (Name, Phone Number or Tag)" htmlFor="recipients">
                        <RecipientsPicker broadcastReceipientsLocalStore={broadcastReceipientsLocalStore} />
                    </FormField>
                    <FormField label="Broadcast Message *">
                        <div className="variable-input-container">
                            <VariableInput
                                draftEditorLocalStore={draftEditorLocalStore}
                                onChange={handleMessageChange}
                                message={formData.message || ''}
                                usedTemplate={template}
                                activateVariablesView="viewBroadcast"
                                prompts={ParsedPrompts({
                                    customer: {},
                                    owner: MessagesStore.ThreadsStore.owner,
                                    constants: MessagesStore.ThreadsStore.messagingConstants
                                })}
                                hideSendSaveButton
                                openMentions={openMentions}
                                onMentionOpenChange={(open: boolean) => setOpenMentions(open)}
                            />
                        </div>
                        {chosenAttachments &&
                            <div className="form-row">
                                <Attachments
                                    attachments={chosenAttachments}
                                    onAttachmentRemove={(att) => handleRemoveAttachment(att)}
                                    onAttachmentsChange={(att) => handleAttachmentUploaded(att)}
                                />
                            </div>
                        }
                        <div className="message-tools-bar">
                            <IconButtonBar
                                buttons={messageToolButtons}
                            />
                            <div className="estimated-time-container">
                                <p>Estimated completion time:</p>
                                <span>
                                    {!broadcastReceipientsLocalStore.recipients.loading && `${getEstimatedTimeInMins(broadcastReceipientsLocalStore.recipients.totalCount)} minutes`}
                                </span>
                            </div>
                            {activePicker === 'template' && (
                                <PickerContainer className="templates-container" onPickerOutsideClick={() => changeActivePicker(null)}>
                                    <TemplatesMes
                                        onTemplateSelect={handleTemplateClick}
                                    />
                                </PickerContainer>
                            )}
                            {activePicker === 'emoji' &&
                                <PickerContainer onPickerOutsideClick={() => changeActivePicker(null)}>
                                    <EmojiPicker
                                        native={true}
                                        onSelect={(emoji) => {draftEditorLocalStore.insertEmoji(emoji)}}
                                        onClick={(_, event) => {!event.shiftKey && changeActivePicker(null) }}
                                        showPreview={false}
                                        showSkinTones={false}
                                    />
                                </PickerContainer>
                            }
                        </div>
                    </FormField>
                    <div className="form-footer">
                        <Button
                            type="button"
                            fill="outlined"
                            disabled={!isFormFilled()}
                            onClick={() => setScheduleDialogOpen(true)}
                        >
                            Schedule For Later
                        </Button>
                        <Button
                            type="button"
                            fill="full"
                            disabled={!isFormFilled()}
                            onClick={() => handlePublish()}
                        >
                            Publish Now
                        </Button>
                    </div>
                    {scheduleDialogOpen &&
                        <ScheduleDialog
                            initialDate={broadcast?.scheduled}
                            open={scheduleDialogOpen}
                            timeZone={timeZone}
                            onClose={() => setScheduleDialogOpen(false)}
                            onScheduleClick={handleScheduleClick}
                        />
                    }
                    <input type="file" id="attachment" multiple ref={attachmentInputRef} style={{display: 'none'}} onChange={handleAttachmentInputChange} />
                </form>
            </div>
            <RecipientsLists broadcastReceipientsLocalStore={broadcastReceipientsLocalStore}/>
        </div>
    );
};

const CreateBroadcastCard = withStores(CreateBroadcastCardWithStores, [
    STORES.BroadcastsStore
]);

export default CreateBroadcastCard;
