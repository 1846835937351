import React, { useState, useEffect } from 'react';
import { FormViewType } from '../ContactForm/FormViewType';
import { Customer } from '@src/stores/models';
import { withStores, STORES } from '@src/stores/with-store';
import { ThreadsStore } from '@src/stores/messages/threads.store';
import { CustomersStore } from '@src/stores/customers.store';
import { SendFirstMessage } from './SendFirstMessage';
import { NewContactSearch } from './NewContactSearch';
import { NewContactAddAndMessage } from './NewContactAddAndMessage';
import { NewContactOnlyAdd } from './NewContactOnlyAdd';
import { images } from '@src/theme/images';

import './new-contact.sass';
import '../messages-common.sass';

interface NewContactWrapperProps {
    ThreadsStore: ThreadsStore;
    CustomersStore: CustomersStore;
    openThreadWithCustomer?: (action: FormViewType, customer?: Customer) => void;
    isFirstMessageChanged?: (isFirstMessage: boolean) => void;
}

enum View {
    SEND_FIRST_MESSAGE,
    SEARCH,
    ADD_AND_MESSAGE,
    ONLY_ADD
}

export interface InsertedData {
    firstName?: string;
    lastName?: string;
    phone?: string;
    message?: string;
}

const firstMessageText = 'Welcome to Myopolis! This is how you can communicate directly with your customers. To text on the go, download the mobile app at https://myopolis.page.link/app';

const NewContactWrapperWithStore: React.FC<NewContactWrapperProps> = (props) => {
    const [viewToDisplay, setViewToDisplay] = useState(View.SEARCH);
    const [insertedData, setInsertedData] = useState<InsertedData>(null);
    const [noMessagesYet, setNoMessagesYet] = useState(false);

    const isAnyMessageSent = () => {
        if (props.ThreadsStore.searchResults.length) {
            return;
        } else {
            props.ThreadsStore.checkAllThreads()
            .then(res => {
                if (res.threads.length) {
                    return;
                } else {
                    const timeout = setTimeout(() => {
                        setNoMessagesYet(true);
                        props.isFirstMessageChanged(true);
                        setViewToDisplay(View.SEND_FIRST_MESSAGE);
                    }, 1);
                    return clearTimeout(timeout);
                };
            });
        };
    };

    useEffect(() => {
        return isAnyMessageSent();
    }, []);

    const handleSendFirstMessage = (toUsersPhone: boolean) => {
        if (toUsersPhone) {
            setInsertedData({
                firstName: props.ThreadsStore.constants.employee.firstName,
                lastName: props.ThreadsStore.constants.employee.lastName,
                message: firstMessageText,
                phone: props.ThreadsStore.constants.employee.phone,
            });
        } else {
            setInsertedData({
                firstName: props.ThreadsStore.constants.employee.firstName,
                lastName: props.ThreadsStore.constants.employee.lastName,
                message: firstMessageText,
            });
        };
        setViewToDisplay(View.ADD_AND_MESSAGE);
    };

    return (
        <div className="NewContact card">
            {(viewToDisplay === View.ADD_AND_MESSAGE || viewToDisplay === View.ONLY_ADD) &&
                <div
                    className="NewContact__arrowBack"
                    onClick={() => setViewToDisplay(noMessagesYet ? View.SEND_FIRST_MESSAGE : View.SEARCH)}
                >
                    <img src={images.arrowLeft} alt="arrow left" />
                </div>
            }
            {
                viewToDisplay === View.SEND_FIRST_MESSAGE ?
                    <SendFirstMessage
                        userPhoneNumber={props.ThreadsStore.constants?.employee?.phone}
                        onSendFirstMessage={toUsersPhone => handleSendFirstMessage(toUsersPhone)}
                    />
                : viewToDisplay === View.SEARCH ?
                    <NewContactSearch
                        setAddContactView={() => setViewToDisplay(View.ONLY_ADD)}
                        setAddAndMessageContactView={(insertedData) => {
                            setInsertedData(insertedData);
                            setViewToDisplay(View.ADD_AND_MESSAGE);
                        }}
                        openThreadWithCustomer={(action: FormViewType, customer?: Customer) => props.openThreadWithCustomer(action, customer)}
                    />
                : viewToDisplay === View.ADD_AND_MESSAGE ?
                    <NewContactAddAndMessage
                        sendMessage={(customer: Customer) => props.openThreadWithCustomer(FormViewType.SEND_MESSAGE, customer)}
                        insertedData={insertedData}
                    />
                : viewToDisplay === View.ONLY_ADD ?
                    <NewContactOnlyAdd />
                : null
            }
        </div>
    );
};

export const NewContactWrapper = withStores(NewContactWrapperWithStore, [STORES.ThreadsStore]);
