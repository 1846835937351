import { Dropdown } from '@src/components/Dropdown/Dropdown';
import React, { useState } from 'react';

import '../DetailsCard/DetailsCard.sass';
import './SendCard.sass';

type RecipientQuantifier = "replied" | "unreplied";
const recipientOptions: Array<RecipientQuantifier>= [
    'replied',
    'unreplied'
];

interface CardProps {
    disabled?: boolean;
    onStartBroadcastClick: (props?: {state: "replied" | "unreplied"}) => void;
};

const SendCard: React.FunctionComponent<CardProps> = ({ disabled, onStartBroadcastClick }) => {
    const [selectedOption, setSelectedOption] = useState<number>()

    const handleOptionSelect = (i: number) => {
        setSelectedOption(i)
    }

    const handleClick = () => {
        onStartBroadcastClick({state: recipientOptions[selectedOption]})
    }

    return (
        <div className="Broadcasts-details-card">
            <div className="send-card-content">
                <span>Send another Broadcast to</span>
                <Dropdown
                    optionsArr={recipientOptions}
                    darkColor={false}
                    optionSelected={handleOptionSelect}
                    disabled={disabled}
                />
            </div>
            <footer className="Broadcasts-details-card__card-footer">
                <button
                  className={`${Number.isInteger(selectedOption) ? 'blue' : 'disabled'}`}
                  disabled={disabled || !Number.isInteger(selectedOption)}
                  onClick={() => handleClick()}
                  >
                  Start Broadcast
                </button>
            </footer>
        </div>
    );
};

export default SendCard;
