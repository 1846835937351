import { observable, action, runInAction } from 'mobx';
import UserStore from '@src/stores/user.store';
import ConversationsStore from '@src/stores/teamchat/conversations.store';
import NotificationsStore from '@src/stores/notifications.store';
import SocketInstance from '@src/stores/socket-instance';
import TwilioPhoneStore from '@src/stores/twilio-phone.store';
import BroadcastsStore from '@src/stores/broadcasts.store';
import MessagesStore from '@src/stores/messages/messages.store';
import ReviewsStore from '@src/stores/reviews.store';
import { getAuth, logout } from '@src/api/api';
import { SocketEventType, SocketWrapper } from '@src/api/socket-wrapper';
import TagsStore from './tags/tags.store';
import BillingStore from './billing.store';
import CouponStore from './coupon.store';
import CustomerStore from './customer.store';
import CustomersStore from './customers.store';
import DashboardStore from './dashboard.store';
import ImportCustomersStore from './import-customers.store';
import IntegrationsStore from './integrations.store';
import InvoicesStore from './invoices.store';
import LeaderboardStore from './leaderboard.store';
import PersonalSettingsStore from './personal-settings.store';
import PhoneStore from './phone.store';
import ProfileStore from './profile.store';
import ReviewSitesStore from './review-sites.store';
import SignupStore from './signup.store';
import TeamStore from './team.store';
import TemplateStore from './template.store';
import WalkthroughStore from './walkthrough.store';
import WebchatStore from './webchat.store';
import { setTimeoutAndClear } from '@src/utils/timeout';

const { ThreadsStore } = MessagesStore;

export type AuthLoginResponse = 'success' | 'successIncomplete' | 'failure';

export interface Auth {
    token?: string;
}

export class AuthStore {
    @observable auth: Auth;
    @observable appLoaded = false;
    @observable accountStatus = '';

    private socketWrapper: SocketWrapper;

    private logoutInProgress: boolean = false;

    constructor(socketWrapper: SocketWrapper) {
        this.socketWrapper = socketWrapper;
    }

    private logoutOnWS(): void {
        if (this.logoutInProgress) {
            setTimeoutAndClear(() => {
                this.logoutOnWS();
            }, 1000);
        } else {
            this.logoutInProgress = false;
            this.resetAuth();
            location.reload();
        }
    }

    @action setAuth(auth: Auth): void {
        this.auth = auth;
    }

    @action setAppLoaded(): void {
        this.appLoaded = true;
    }

    @action setAccountStatus(val: string): void {
        this.accountStatus = val;
    }

    @action logout(): Promise<any> {
        this.logoutInProgress = true;
        return logout().then(() => {
            runInAction(() => {
                this.logoutInProgress = false;
                this.resetAuth();
            });
        });
    }

    @action pureLogout(): Promise<any> {
        this.logoutInProgress = true;
        return logout().then(() => {
            runInAction(() => {
                this.logoutInProgress = false;
            });
        });
    }

    @action loadAuth(): Promise<void> {
        return getAuth().then(
            ({ data }) => {
                runInAction(() => {
                    this.accountStatus = data.accountStatus;
                    if (data.accountStatus !== 'INCOMPLETE') {
                        UserStore.setUser(data);
                        this.setAppLoaded();
                        SocketInstance.open();
                        if (data.accountStatus !== 'LOCKED') {
                            NotificationsStore.loadNotification(1, 5);
                            ReviewsStore.fetchReviewGeneralData();
                            MessagesStore.loadMessagesConstants({});
                            TwilioPhoneStore.init();
                        }
                        ConversationsStore.init();
                        ThreadsStore.init();
                        ReviewsStore.init();

                        if (this.socketWrapper) {
                            this.socketWrapper.addEventListener(
                                SocketEventType.LogUserOut,
                                (incomingMsg) => {
                                    if (incomingMsg) {
                                        this.logoutOnWS();
                                    }
                                }
                            );
                        }
                    } else {
                        UserStore.setSignupUser(data);
                        this.setAppLoaded();
                    }
                });
            },
            (err) => {
                runInAction(() => {
                    SocketInstance.close();
                    TwilioPhoneStore.reset();
                    TwilioPhoneStore.closeConnection();
                    // ThreadsStore.init();
                    // ConversationsStore.init();
                    // ReviewsStore.init();
                });
                this.setAppLoaded();
                return Promise.reject(err);
            }
        );
    }

    @action loadAuthLogin(): Promise<AuthLoginResponse> {
        return getAuth()
            .then(({ data }) => {
                this.setAppLoaded();
                runInAction(() => {
                    this.accountStatus = data.accountStatus;
                });
                if (data.accountStatus !== 'INCOMPLETE') {
                    runInAction(() => {
                        this.accountStatus = data.accountStatus;
                        UserStore.setUser(data);
                        ThreadsStore.init();
                        ConversationsStore.init();
                        if (data.accountStatus !== 'LOCKED') {
                            MessagesStore.loadMessagesConstants({});
                        }
                    });
                    return 'success';
                } else {
                    runInAction(() => {
                        UserStore.setSignupUser(data);
                    });
                    return 'successIncomplete';
                }
            })
            .catch(() => {
                this.setAppLoaded();
                return 'failure';
            });
    }

    @action resetAuth(): void {
        this.accountStatus = '';
        this.appLoaded = false;
        UserStore.destroy();
        SocketInstance.close();
        TwilioPhoneStore.reset();
        TwilioPhoneStore.closeConnection();
        ThreadsStore.destroy();
        MessagesStore.destroy();
        BroadcastsStore.destroy();
        TagsStore.init();
        ConversationsStore.destroy();
        BillingStore.init();
        CouponStore.init();
        CustomerStore.init();
        CustomersStore.init();
        DashboardStore.init();
        ImportCustomersStore.init();
        IntegrationsStore.destroy();
        InvoicesStore.init();
        LeaderboardStore.init();
        NotificationsStore.destroy();
        PersonalSettingsStore.init();
        PhoneStore.init();
        ProfileStore.init();
        ReviewSitesStore.destroy();
        ReviewsStore.destroy();
        SignupStore.destroy();
        TeamStore.init();
        TemplateStore.init();
        WalkthroughStore.init();
        WebchatStore.init();
        localStorage.removeItem('typedMessages');
    }

    @action updateAuth(): Promise<void> {
        return getAuth().then(({ data }) => {
            runInAction(() => {
                this.accountStatus = data.accountStatus;
                if (data.accountStatus !== 'INCOMPLETE') {
                    UserStore.setUser(data);
                    if (data.accountStatus !== 'LOCKED') {
                        MessagesStore.loadMessagesConstants({});
                    }
                }
            });
        });
    }
}

export default new AuthStore(SocketInstance);
