import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Loader, GenericFileIcon } from '@src/components';
import { Attachment, UploadedFile } from '@src/stores/models';
import { MessagesApi } from '@src/requests';
import { fileExtensionPattern } from '@src/theme/utils/constants';
import { AttachmentFile } from './attachment-file';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { AttachmentMouseEventHandler } from '@src/pages/Messages/components/Chat/MessageAttachments';
import { ERROR_TOAST_AUTOCLOSE } from '@src/theme/utils/constants';

import '@src/theme/theme.sass';

export function isAttachmentFile(attachment: Attachment | AttachmentFile): attachment is AttachmentFile {
    return (attachment as AttachmentFile).arrayBuffer !== undefined;
};

type AttachmentState = 'loaded' | 'loading' | 'error';

interface AttachmentTileProps {
    attachment: AttachmentFile | Attachment;
    classNames?: string;
    onClick?: AttachmentMouseEventHandler;
    onRemove?: (attachmentId: string) => void;
    onUploaded?: (attachment: Attachment) => void;
};

export const AttachmentTile: React.FC<AttachmentTileProps> = (props) => {
    const [loadedAttachment, setLoadedAttachment] = useState<Attachment>(null);
    const [state, setState] = useState<AttachmentState>('loading');

    const upload = () => {
        MessagesApi.uploadAttachment(props.attachment as File)
        .then((res) => {
            const uFile: UploadedFile = res.data;
            const loadedFile: Attachment = {
                id: props.attachment.id,
                contentType: uFile.contentType,
                name: uFile.fileName,
                url: uFile.file,
                uri: uFile.file,
            };
            props.onUploaded && props.onUploaded(loadedFile);
            setLoadedAttachment(loadedFile);
            setState('loaded');
        })
        .catch(err => {
            setState('error');
            setLoadedAttachment(null);
            const toastMessage = err.response.request.status === 413 ? 'File too large' : 'Error while uploading';
            toast.error(toastMessage, ERROR_TOAST_AUTOCLOSE);
        });
    };

    useEffect(() => {
        if (loadedAttachment) {
            setState('loaded');
            return;
        }

        if (props.attachment?.contentType) {
            const attachmentPreview = {
                id: props.attachment.id,
                url: props.attachment.url,
                name: props.attachment.name,
                contentType: props.attachment.contentType,
            };
            setLoadedAttachment(attachmentPreview);
            setState('loaded');
            return;
        }

        if (isAttachmentFile(props.attachment)) {
            upload();
        }
    }, [props.attachment]);

    const getAttachmentRender = (attachment: Attachment) => {
        const slash = attachment.contentType?.indexOf('/');
        const ext = !!attachment.name ? fileExtensionPattern.exec(attachment.name) : 'fileName.unknown';
        const type = slash < 0 ? '' : attachment.contentType.substr(0, slash);
        const extension = ext && ext[1] ? ext[1] : '';
        switch (type) {
            case 'image':
                return (
                    <img
                        key={attachment.id}
                        className={`attachment ${props.onClick ? 'clickable' : ''}`}
                        src={attachment.url}
                        alt="attachment"
                        data-tip={attachment.name || ''}
                        onClick={props.onClick}
                    />
                );
            case 'video':
                return (
                    <video
                        key={attachment.id}
                        className={`attachment ${props.onClick ? 'clickable' : ''}`}
                        controls
                        src={attachment.url}
                        data-tip={attachment.name || ''}
                        onClick={props.onClick}
                    />
                );
            default:
                return (
                    <div
                        className="unknown-attachment"
                        key={attachment.id}
                        data-tip={attachment.name || ''}
                    >
                        <a
                            href={attachment.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={attachment.id}
                        >
                            <GenericFileIcon text={extension} />
                        </a>
                    </div>
                );
        }
    };

    const cls = classNames({ AttachmentTile: true, [props.classNames]: !!props.classNames });
    return (
        <div className={cls}>
            {state === 'loading' &&
                <div className="file-placeholder">
                    <Loader loading={true} />
                </div>
            }
            {state !== 'loading' &&
                <>
                    {props.onRemove && (
                        <button type="button" onClick={() => props.onRemove(props.attachment.id)}>
                            <span>×</span>
                        </button>
                    )}
                    <ReactTooltip />

                    {state === 'loaded' && getAttachmentRender(loadedAttachment)}
                    {state === 'error' &&
                        <div className="error-tile" key={props.attachment.id}/>
                    }
                </>
            }
        </div>
    );
};
