import React, { useEffect, useLayoutEffect } from 'react';
import { toast } from 'react-toastify';
import {
    STORES,
    WithProfileStoreProps,
    WithReviewsStoreProps,
    WithDashboardStoreProps,
    withStores,
} from '@src/stores/with-store';
import { TimePeriod, ReviewDate, LoadingState } from '@src/stores/models';

import './dashboard.sass';
import { DashboardStore } from '@src/stores/dashboard.store';
import { ReviewsStore } from '@src/stores/reviews.store';
import { MessagesStats } from './components/MessagesStats/MessagesStats';
import { ContactsStats } from './components/ContactsStats/ContactsStats';
import { ReviewsStats } from './components/ReviewsStats/ReviewsStats';
import { OnTheGoCard } from './components/OnTheGoCard/OnTheGoCard';
import { ReviewsMetrics } from './components/ReviewsStats/ReviewsMetrics';
import { Leaderboard } from './components/Leaderboard/Leaderboard';
import { Profile } from './components/Profile/Profile';
import { Help } from './components/Help/Help';
import PageHeader from '@src/components/PageHeader/PageHeader';
import PeriodDropdown, {
    defaultPeriodNumber,
    timePeriods,
} from '@src/components/PeriodDropdown/PeriodDropdown';

export const errorMsg = (
    dataDesc: string,
    callback: (...args: unknown[]) => void,
    arg: unknown
) => {
    return (
        <div className="toast">
            <span>
                {dataDesc} data has not been loaded successfully. Please reload or try again later.
            </span>
            <button className="btn-primary" onClick={() => callback(arg ? arg : '')}>
                Reload
            </button>
        </div>
    );
};

export const getGeneralDashInfo = (dashboardStore: DashboardStore) => {
    const onError = () => toast.error(errorMsg('Company and messages', getGeneralDashInfo, null));
    dashboardStore.getGeneralDashInfo(onError);
};

const getThreads = (dashboardStore: DashboardStore) => {
    const onError = () => toast.error(errorMsg('Messages', getThreads, null));
    dashboardStore.getThreads(onError);
};

const setNewPeriod = (
    period: TimePeriod,
    dashboardStore: DashboardStore,
    reviewsStore: ReviewsStore
) => {
    const onError = () => toast.error(errorMsg('Leaderboard and stats', setNewPeriod, period));
    dashboardStore?.setNewPeriod(period, onError);

    if (period.date == ReviewDate.CUSTOM) {
        reviewsStore?.filters.customDate.set([period.firstDay, period.lastDay]);
    }

    reviewsStore?.setReviewDate(period.date);
};

type Props = WithReviewsStoreProps & WithProfileStoreProps & WithDashboardStoreProps;

const DashboardWithStore: React.FC<Props> = ({ DashboardStore, ProfileStore, ReviewsStore }) => {
    const getCompleteProfileInfo = () => {
        const onError = () => toast.error(errorMsg('Profile', getCompleteProfileInfo, null));
        ProfileStore.getProfileInfo(onError);
    };

    useEffect(() => {
        getGeneralDashInfo(DashboardStore);
        getCompleteProfileInfo();
        setNewPeriod(timePeriods[defaultPeriodNumber], DashboardStore, ReviewsStore);
        getThreads(DashboardStore);
    }, []);

    useLayoutEffect(() => {
        DashboardStore.init();
        ProfileStore.init();
        ReviewsStore.init();
    }, []);

    return (
        <div className="dashboard">
            <PageHeader
                asideHeader={
                    <PeriodDropdown
                        onSelectPeriod={(period) =>
                            setNewPeriod(period, DashboardStore, ReviewsStore)
                        }
                    />
                }
                isLoading={DashboardStore.loadingGeneral === LoadingState.Loading}
                title={DashboardStore.companyInfo?.name}
            />
            <div className="dashboard__content">
                <div className="dashboard__content-main">
                    <ReviewsMetrics />
                    <MessagesStats />
                    <ContactsStats />
                    <ReviewsStats />
                    {DashboardStore.phone && <OnTheGoCard />}
                    <Leaderboard />
                </div>
                <aside className="dashboard__content-aside">
                    <Profile />
                    <Help />
                </aside>
            </div>
        </div>
    );
};

export const Dashboard = withStores(DashboardWithStore, [
    STORES.Reviews,
    STORES.Dashboard,
    STORES.Profile,
]);
