import api from '@src/api/api';
import { Attachment } from '@src/stores/models';
import { CreateBroadcastRecipients } from '@src/stores/models/broadcast_new';

export const getBroadcasts = (
    page = 1,
    size = 30,
    status?: string,
    search = null
) => {
    return api.get('/broadcasts', { params: { page, size, status, nameSearch: search } });
};

export const createBroadcast = (name: string, text: string, recipients: CreateBroadcastRecipients, scheduledAt: Date = null, attachments: Attachment[] = []) => {
    // IMPORTANT here we send scheduledAt as Date
    return api.post(`/broadcasts`, {
        name,
        text,
        scheduledAt,
        recipients,
        attachments,
    });
};

export const deleteBroadcast = (bid: string) => { // only can delete SCHEDULED broadcast
    return api.delete(`/broadcasts/${bid}`);
};

export const editDetails = (bid: string, name: string, text: string, scheduledAt: Date = null, attachments: Attachment[] = []) => {
    // IMPORTANT here we send scheduledAt as ISOString
    const scheduled = !!scheduledAt ? scheduledAt.toISOString() : null;
    return api.patch(`/broadcasts/${bid}`, {
        name,
        text,
        scheduled,
        attachments,
    });
};

export const getRecipients = (
    broadcastId: string,
    page = 1,
    size = 30,
    search?: string
) => {
    return api.get(`/broadcasts/${broadcastId}/recipients`, { params: { page, size, search } });
};

// TODO: remove this function when replaced by getAvailableRecipients
export const getNonRecipients = (
    broadcastId?: string,
    page = 1,
    size = 30,
    search?: string
) => {
    //return api.get(`/broadcasts/availableRecipients`, { params: { broadcastId, page, size, search } });
    return api.get(`/broadcasts/${broadcastId}/nonRecipients`, { params: { page, size, search } });
};

export const getAvailableRecipients = (
    broadcastId?: string,
    page = 1,
    size = 30,
    search?: string
) => {
    return api.get(`/broadcasts/availableRecipients`, { params: { broadcastId, page, size, search } });
};

// TODO: add recipients type
export const addRecipients = (bid: string, recipients) => {
    return api.post(`/broadcasts/${bid}/recipients`, recipients );
};

export const removeRecipient = (bid: string, memberId: string) => { // co z idsArray?
    return api.delete(`/broadcasts/${bid}/recipients`, { data: [memberId]});
};

export const getFromOtherBroadcast = (broadcastId: string, state: "replied" | "unreplied") => {
    return api.get(`/broadcasts/${broadcastId}/${state}`);
};
