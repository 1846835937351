import arrowDown from './arrow-down.svg';
import calendar from './calendar.svg';
import checkmarkSelectedB from './checkmark-selected-b.svg';
import checkmarkUnselected from './checkmark-unselected.svg';
import dataB from './data-b.svg';
import drop from './drop.svg';
import fb from './fb.svg';
import goal from './goal.svg';
import google from './google.svg';
import growth from './growth.svg';
import leaderboardB from './leaderboard-b.svg';
import messageB from './message-b.svg';
import perSiteB from './per-site-b.svg';
import profileB from './profile-b.svg';
import reviewsB from './reviews-b.svg';
import star from './star-d.svg';
import starEmpty from './star-empty.svg';
import starFull from './star-full.svg';
import thumb from './thumb.svg';
import total from './total.svg';
import unlinked from './unlinked.svg';

export const images: { [key: string]: string } = {
    arrowDown,
    calendar,
    checkmarkSelectedB,
    checkmarkUnselected,
    dataB,
    drop,
    fb,
    goal,
    google,
    growth,
    leaderboardB,
    messageB,
    perSiteB,
    profileB,
    reviewsB,
    star,
    starEmpty,
    starFull,
    thumb,
    total,
    unlinked
};
