import React from 'react';
import { Template } from '@src/stores/models';

import './suggested-response.sass';

interface SuggestedResponseTemplate {
    recommendations: Template[]; // Recommendation interface to be created when recommendations are supported
    onRecommendationSelect: (temp: Template) => void;
}

export const SuggestedResponse: React.FC<SuggestedResponseTemplate> = (props) => {
    const templates = props.recommendations.slice(0, 3);

    const parseMarkups = (text: string): { __html: string } => {
        let newText = text;
        const matches = text.match(/:(.*):/g) || [];
        matches.forEach((m) => {
            const parsed = m.replace(/:/g, '').replace(/_/g, ' ');
            newText = newText.replace(m, `<span>${parsed}</span>`);
        });
        return { __html: newText };
    };

    return (
        <div className="SuggestedResponse">
            <h5>Suggested Response</h5>
            {templates.map((
                temp // here recommendations, not templates - but so far recommendations hasn't been supported yet
            ) => (
                <button key={temp.id} onClick={() => props.onRecommendationSelect(temp)}>
                    <p dangerouslySetInnerHTML={parseMarkups(temp.title)} />
                </button>
            ))}
        </div>
    );
};
