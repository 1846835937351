import axios from 'axios';

const standaloneAxios = axios.create({
    baseURL: '/',
})

export const getExternalVersion = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        standaloneAxios.get(`index.html?try=${new Date().getTime()}`)
            .then((r) => {
                const DOM = new DOMParser().parseFromString(r.data, "text/html");
                const versionsElements = DOM.getElementsByName('version')
                if (versionsElements.length) {
                    resolve((versionsElements[0] as any).content)
                }
                reject('no version')
            })
            .catch((e) => {
                reject('error during checking version')
            });
    })
};

export const versionsRequests = {
    getExternalVersion
};
