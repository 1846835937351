import { BillingStore } from '@src/stores/billing.store';
import React from 'react';
import { PlanDetails } from '@src/components';

import '../payment-inside-stripe.sass';

interface HeaderProps {
    billingStore?: BillingStore;
};

const UpdatePaymentInSettingsHeader: React.FunctionComponent<HeaderProps> = ({ billingStore }) => {
    return (
        <>
            <button
                className="PaymentInsideStripe__back btn-tetriary"
                onClick={() => billingStore?.setIsViewBilling(true)}
            >
                &lt; See Billing
            </button>
            <div className="PaymentInsideStripe__title">Payment</div>
            <div className="PaymentInsideStripe__subtitle">Update payment method</div>
            <PlanDetails
                view="updatePayment"
            />
        </>
    )
};

export default UpdatePaymentInSettingsHeader;
