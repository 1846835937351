import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { WithTemplateStoreProps, STORES, withStores } from '@src/stores/with-store';
import { onSuccess } from '@src/theme/utils/constants';
import { ReactComponent as AttachmentIcon } from '@src/theme/icons/attach.svg';
import { toast } from 'react-toastify';
import {
    PROMPTS,
    Attachment,
    HashMap,
    LoadingState,
    OnErrorDataTemplates,
} from '@src/stores/models';
import { FormField, Attachments, AttachmentFile, ErrorToastSave, Dropdown } from '@src/components';

import './templates.sass';

interface TemplateFormProps {
    messagesView?: boolean;
    newTemplateAdded?: (t) => void;
    closeForm?: () => void;
}

type props = WithTemplateStoreProps & TemplateFormProps;

let loaded: HashMap<Attachment> = {};

export const TemplateFormWithStore: React.FC<props> = (props) => {
    const { register, handleSubmit, errors } = useForm();
    const store = props.TemplateStore;
    const { templates } = store;
    const history = useHistory();
    const attachmentRef = useRef(null);
    const [attachments, setAttachments] = useState<AttachmentFile[]>([]);
    const [showAttachments, setAttStatus] = useState(false);
    const {templateId} = useParams() as any;
    const template = templateId && templates.find((el) => el.id === templateId);

    store.onError = (type: string, data?: OnErrorDataTemplates) => {
        store.loadingState = LoadingState.Error;
        switch (type) {
            case 'updateTemplate': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.updateTemplate(
                                data.templateId,
                                data.title,
                                data.message,
                                data.mappedAttachments,
                                'updateTemplate'
                            )
                        }
                    />
                );
                break;
            }
            default: {
                return;
            }
        }
    };
    store.onSuccess = () => {
        store.loadingState = LoadingState.Loaded;
        onSuccess();
    };

    const removeAttachment = (attId: string) => delete loaded[attId];
    const attachmentsChange = (loadedAtt: Attachment) => (loaded[loadedAtt.id] = loadedAtt);

    const updateTemplate = ({ title, message }) => {
        store.updateTemplate(templateId, title, message, loaded, 'updateTemplate').then((res) => {
            setAttachments([]);
            if (props.messagesView) props.newTemplateAdded(res);
            else history.push('/settings/templates');
        });
    };

    const insertVariable = (msg, textIdx: number) => {
        const text = PROMPTS[textIdx].value + ' ';
        const doc = msg.ownerDocument;
        const addedVariablePosition = msg.selectionStart + text.length;
        //IE support
        if (doc?.selection) {
            msg.focus();
            const sel = doc.selection.createRange();
            // sel.collapse(false);
            sel.text = text;
            // sel.select();
        }
        //MOZILLA and others
        else if (msg.selectionStart || msg.selectionStart == 0) {
            const startPos = msg.selectionStart;
            const endPos = msg.selectionEnd;
            msg.value =
                msg.value.substring(0, startPos) +
                text +
                msg.value.substring(endPos, msg.value.length);
        } else {
            msg.value += text;
        }
        msg.focus();
        msg.setSelectionRange(addedVariablePosition, addedVariablePosition);
    };

    const onFilesAdded = (files: FileList) => {
        const newAttachments = [];
        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);
            file['id'] = uuidv4();
            newAttachments.push(file);
        }
        setAttachments(newAttachments);
        setAttStatus(true);
        attachmentRef.current.value = '';
    };

    useEffect(() => {
        if (template) {
            const att = template.attachments || [];
            setAttachments(att);
            att.map((a) => (loaded[a.id] = { ...a, uri: a.url }));
            setTimeout(() => setAttStatus(true), 500);
        }
    }, [template]);

    function onInput() {
        this.style.height = 'auto';
        const height = this.scrollHeight - 24 <= 76 ? this.scrollHeight - 24 : 76;
        this.style.height = height + 'px';
    }

    useEffect(() => {
        loaded = {};

        const didTextareaAppear = setInterval(() => {
            const msgField = document.getElementById('message');
            if (msgField) {
                const height = msgField.scrollHeight - 24 <= 76 ? msgField.scrollHeight - 24 : 76;
                msgField.setAttribute("style", "height:" + height + "px;");
                msgField?.addEventListener("input", onInput, false);
                clearInterval(didTextareaAppear);
            }
        }, 500);
    }, []);

    return (
        <div className="TemplatesForm">
            {!props.messagesView && (
                <Link className="TemplatesForm__back btn-tetriary" to={'/settings/templates'}>
                    &lt; Templates List
                </Link>
            )}
            {!props.messagesView && (
                <div className="TemplatesForm__title">
                    <div>{templateId ? 'Edit' : 'Create New'} Template</div>
                </div>
            )}
            <div className="TemplatesForm__content">
                <form onSubmit={handleSubmit(updateTemplate)}>
                    <FormField label="Working title" htmlFor="title" error={errors.title}>
                        <input
                            type="text"
                            name="title"
                            className={classNames('form-input', { error: errors.title })}
                            defaultValue={template?.title}
                            ref={register({ required: 'Missing working title' })}
                        />
                    </FormField>
                    <div className="templateMessageWrapper">
                        <FormField label="Message" htmlFor="message" error={errors.message}>
                            <textarea
                                name="message"
                                id="message"
                                className={classNames('form-input', 'textarea', {
                                    error: errors.message,
                                })}
                                defaultValue={template?.message}
                                ref={register({ required: 'Missing message' })}
                            />
                        </FormField>
                        <div className="helpers">
                            <Dropdown
                                optionsArr={PROMPTS.map((el) => el.name)}
                                dropdownName=":abc:"
                                darkColor={false}
                                optionSelected={(i) =>
                                    insertVariable(document.getElementById('message'), i)
                                }
                            />
                            <div className="attachment">
                                <label htmlFor="file" className="btn-secondary">
                                    <AttachmentIcon />
                                </label>
                                <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    className="inputfile"
                                    onChange={(e) => onFilesAdded(e.target.files)}
                                    multiple
                                    ref={attachmentRef}
                                />
                            </div>
                        </div>
                    </div>
                    {showAttachments && attachments.length > 0 && (
                        <div className="TemplatesForm__attachments">
                            <div className="form-label">Attachments</div>
                            <Attachments
                                onAttachmentsChange={(att) => attachmentsChange(att)}
                                onAttachmentRemove={(att) => removeAttachment(att)}
                                attachments={attachments}
                                isInTemplatesFormComponent={true}
                            />
                        </div>
                    )}
                    <div className="actions">
                        {!props.messagesView ? (
                            <Link className="btn-secondary" to={'/settings/templates'}>
                                Cancel
                            </Link>
                        ) : (
                            <button className="btn-secondary" onClick={() => props.closeForm()}>
                                Cancel
                            </button>
                        )}
                        <button className="btn-primary">{templateId ? 'Update' : 'Save'}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export const TemplatesForm = withStores(TemplateFormWithStore, [STORES.Template]);
