import api from '@src/api/api';
import { Role } from '@src/stores/models';

export const getTeam = () => {
    return api.get('/team');
};

export const addTeamMember = (member: {}) => {
    return api.post('/team/add', { ...member });
};

export const archiveTeamMember = (id: string) => {
    return api.post(`/team/archive/${id}`);
};

export const restoreTeamMember = (id: string, password: string, confirmPassword: string) => {
    return api.post(`/team/restore/${id}`, { id, password, confirmPassword });
};

export const resendInvitation = (id: string) => {
    return api.post(`/team/user/${id}/activationemail`, {});
};

// export const getTeamMember = (memberId: string) => {
//   return api.get(`/team/user/${memberId}`);
// }

export const updateTeamMemberName = (
    id: string,
    firstName: string,
    lastName: string,
    phone: string
) => {
    return api.post(`/team/user/name:${id}`, { id, firstName, lastName, phone });
};

export const updateTeamMemberEmail = (id: string, email: string, emailConfirm: string) => {
    return api.post(`/team/user/email:${id}`, { id, email, emailConfirm });
};

export const updateTeamMemberRole = (id: string, role: Role) => {
    return api.post(`/team/user/role:${id}`, { id, role: { value: role } });
};
