import { getCoupon, redeemOffer } from '@src/requests/coupon.requests';
import { action, observable, runInAction } from 'mobx';
import { BaseStore } from './base.store';
import { LoadingState } from './models/loading-state';
import { isBefore, parseISO } from 'date-fns';
import AuthStore from '@src/stores/auth.store';

export type CouponStatus = 'PENDING' | 'CLAIMED';
export type CouponDuration = 'forever' | 'once' | 'repeating';

export interface Coupon {
    amountOff?: number;
    code: string;
    created: Date;
    currency: string;
    deleted: boolean;
    duration: CouponDuration;
    id: string;
    maxRedemptions: number;
    months: number;
    name: string;
    percentOff?: number;
    redeemBy: Date | null;
    updated: Date;
};

export interface CouponOffer {
    id: string;
    accountId: string;
    coupon: Coupon | null;
    created: Date;
    name: string;
    offerExpires: Date | null;
    // TODO: remove this prop
    redeemBefore: Date; //initially set as offerExpires, it may be changed by the paramter ?dt of the url
    status: CouponStatus;
    updated: Date;
};
// TODO: remove this function
const redeemDate = (redeemBeforeDate: Date | null, offerExpires: Date | null): Date | null => {
    return offerExpires;
    // if ((!!redeemBeforeDate && !offerExpires) || isBefore(redeemBeforeDate, offerExpires)) {
    //     return redeemBeforeDate;
    // };
    // if (!redeemBeforeDate && !!offerExpires) {
    //     return offerExpires;
    // };
    // return null;
};

export class CouponStore extends BaseStore {
    @observable loading: LoadingState = LoadingState.Loading;
    @observable couponOffer: CouponOffer | null;

    @action init(): void {
        this.loading = LoadingState.Loading;
        this.couponOffer = null;
    };

    @action getCouponOffer(cid: string, redeemBeforeDate: Date | null, onError?: (message) => void, dt?) {
        this.loading = LoadingState.Loading;

        return getCoupon(cid, dt).then(
            (result) => {
                this.couponOffer = {
                    ...result.data.couponOffer,
                    created: parseISO(result.data.couponOffer.created),
                    offerExpires: !!result.data.couponOffer.offerExpires ? parseISO(result.data.couponOffer.offerExpires) : null,
                    redeemBefore: redeemDate(redeemBeforeDate, !!result.data.couponOffer.offerExpires ? parseISO(result.data.couponOffer.offerExpires) : null),
                    updated: parseISO(result.data.couponOffer.updated),
                    accountId: result.data.couponOffer.accountId.value,
                    id: result.data.couponOffer.id.value,
                    coupon: {
                        ...result.data.couponOffer.coupon,
                        id: result.data.couponOffer.coupon.id.value,
                        created: parseISO(result.data.couponOffer.coupon.created),
                        redeemBy: parseISO(result.data.couponOffer.coupon.redeemBy),
                        updated: parseISO(result.data.couponOffer.coupon.updated),
                    },
                };
                this.loading = LoadingState.Loaded;
            },
            (err) => {
                if (onError) onError(err.message);
                this.loading = LoadingState.Error;
            }
        );
    };

    @action redeemOffer(oid: string, stripeToken?: string, onError?: (message) => void) {
        this.loading = LoadingState.Loading;

        return redeemOffer(oid, stripeToken).then(
            (result) => {
                runInAction(() => {
                    this.loading = LoadingState.Loaded;
                })
                return AuthStore.updateAuth()
                // .then(() => {
                //     runInAction(() => {
                //         AuthStore.setAccountStatus('ACTIVE');
                //     })
                // });
            },
            (err) => {
                runInAction(() => {
                    if (onError) onError(err.message);
                    this.loading = LoadingState.Error;
                })
            }
        );
    };
};

export default new CouponStore();
