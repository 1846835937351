import './paging.sass';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as Arrow } from '@src/theme/icons/arrow.svg';

interface PagingProps {
    total: number;
    currPage?: number;
    pageSize?: number;
    onPageChange: (page: number) => void;
}

export const Paging: React.FC<PagingProps> = ({ total, pageSize, currPage, onPageChange }) => {
    const itemsPerPage = pageSize || 6;
    const [currentPage, setPage] = useState(currPage || 1);
    const pageCount = Math.ceil(total / itemsPerPage);
    const first = (currentPage - 1) * itemsPerPage + 1;
    const last = Math.min(first + itemsPerPage - 1, total);

    useEffect(() => {
        const newPage = isNaN(currPage) ? 1 : currPage;
        setPage(newPage);
    }, [currPage]);

    const firstPage =
        currentPage <= 3
            ? 1
            : currentPage + 2 > pageCount
            ? currentPage === pageCount
                ? Math.max(1, pageCount - 4)
                : currentPage - 3
            : currentPage - 2;
    const pages = [];
    for (let i = 0; i < 5; i++) {
        if (firstPage + i <= pageCount) {
            pages.push(i + firstPage);
        }
    }

    const pageChange = (page: number): void => {
        if (page !== currentPage) {
            setPage(page);
            onPageChange(page);
        }
    };

    const previousPage = (): void => {
        if (currentPage - 1 >= 1) {
            pageChange(currentPage - 1);
        }
    };

    const nextPage = (): void => {
        if (currentPage + 1 <= pageCount) {
            pageChange(currentPage + 1);
        }
    };

    return (
        <div className="Paging">
            <div className="page-info">
                {total > 0 ? `Showing ${first} to ${last} of ${total} entries` : null}
            </div>
            <div className="pages">
                {total > itemsPerPage ? (
                    <React.Fragment>
                        <button
                            className="arrow-btn left"
                            onClick={() => previousPage()}
                            disabled={currentPage === 1}
                        >
                            <Arrow />
                        </button>
                        {pages.map((page) => {
                            const cls = classNames({ 'current-page': page === currentPage });
                            return (
                                <button onClick={() => pageChange(page)} className={cls} key={page}>
                                    {page}
                                </button>
                            );
                        })}
                        <button
                            className="arrow-btn"
                            onClick={() => nextPage()}
                            disabled={currentPage === pageCount}
                        >
                            <Arrow />
                        </button>
                    </React.Fragment>
                ) : null}
            </div>
        </div>
    );
};
