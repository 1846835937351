export interface Employee {
    id?: string;
    accountId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    emailVerified?: boolean;
    // status?: 'ACTIVE' | 'INACTIVE' | 'ARCHIVED';
    status?: Status;
    role?: Role;
    phone?: string;
    timeZone?: string;
}

export interface EmployeeStats {
    employeeId?: string;
    conversations?: number;
    sent?: number;
    credited?: number;
    responses?: number;
    avgResponseTime?: any;
    rating?: string;
}

export interface MemberData {
    phone: string;
    firstName: string;
    lastName: string;
    role: string;
    email: string;
    emailConfirm?: string;
}

export enum Role {
    OWNER,
    LEADER,
    MEMBER,
}

export const RoleTxt = ['Owner', 'Leader', 'Member'];

export enum Status {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    ARCHIVED = 'ARCHIVED',
}

export const timezones = [{
    "value": "America/Blanc-Sablon",
    "name": "AST - QC (Lower North Shore)"
}, {"value": "America/Anchorage", "name": "Alaska"}, {
    "value": "America/Nome",
    "name": "Alaska (west)"
}, {"value": "America/Metlakatla", "name": "Alaska - Annette Island"}, {
    "value": "America/Juneau",
    "name": "Alaska - Juneau area"
}, {"value": "America/Sitka", "name": "Alaska - Sitka area"}, {
    "value": "America/Yakutat",
    "name": "Alaska - Yakutat"
}, {"value": "America/Adak", "name": "Aleutian Islands"}, {
    "value": "America/Goose_Bay",
    "name": "Atlantic - Labrador"
}, {"value": "America/Glace_Bay", "name": "Atlantic - NS (Cape Breton)"}, {
    "value": "America/Halifax",
    "name": "Atlantic - NS; PE"
}, {"value": "America/Moncton", "name": "Atlantic - New Brunswick"}, {
    "value": "America/Swift_Current",
    "name": "CST - SK (midwest)"
}, {"value": "America/Regina", "name": "CST - SK"}, {
    "value": "America/Chicago",
    "name": "Central"
}, {"value": "America/Indiana/Tell_City", "name": "Central - IN (Perry)"}, {
    "value": "America/Indiana/Knox",
    "name": "Central - IN (Starke)"
}, {"value": "America/Menominee", "name": "Central - MI (Wisconsin border)"}, {
    "value": "America/North_Dakota/Beulah",
    "name": "Central - ND (Mercer)"
}, {
    "value": "America/North_Dakota/New_Salem",
    "name": "Central - ND (Morton rural)"
}, {"value": "America/North_Dakota/Center", "name": "Central - ND (Oliver)"}, {
    "value": "America/Resolute",
    "name": "Central - NU (Resolute)"
}, {"value": "America/Rankin_Inlet", "name": "Central - NU (central)"}, {
    "value": "America/Rainy_River",
    "name": "Central - ON (Rainy R, Ft Frances)"
}, {"value": "America/Winnipeg", "name": "Central - ON (west); Manitoba"}, {
    "value": "America/Atikokan",
    "name": "EST - ON (Atikokan); NU (Coral H)"
}, {"value": "America/New_York", "name": "Eastern"}, {
    "value": "America/Indiana/Marengo",
    "name": "Eastern - IN (Crawford)"
}, {
    "value": "America/Indiana/Vincennes",
    "name": "Eastern - IN (Da, Du, K, Mn)"
}, {"value": "America/Indiana/Petersburg", "name": "Eastern - IN (Pike)"}, {
    "value": "America/Indiana/Winamac",
    "name": "Eastern - IN (Pulaski)"
}, {"value": "America/Indiana/Vevay", "name": "Eastern - IN (Switzerland)"}, {
    "value": "America/Indiana/Indianapolis",
    "name": "Eastern - IN"
}, {
    "value": "America/Kentucky/Louisville",
    "name": "Eastern - KY (Louisville area)"
}, {"value": "America/Kentucky/Monticello", "name": "Eastern - KY (Wayne)"}, {
    "value": "America/Detroit",
    "name": "Eastern - MI"
}, {"value": "America/Pangnirtung", "name": "Eastern - NU (Pangnirtung)"}, {
    "value": "America/Iqaluit",
    "name": "Eastern - NU (most east areas)"
}, {"value": "America/Thunder_Bay", "name": "Eastern - ON (Thunder Bay)"}, {
    "value": "America/Toronto",
    "name": "Eastern - ON, QC"
}, {"value": "Pacific/Honolulu", "name": "Hawaii"}, {
    "value": "America/Phoenix",
    "name": "MST - Arizona (except Navajo)"
}, {"value": "America/Creston", "name": "MST - BC (Creston)"}, {
    "value": "America/Dawson_Creek",
    "name": "MST - BC (Dawson Cr, Ft St John)"
}, {"value": "America/Fort_Nelson", "name": "MST - BC (Ft Nelson)"}, {
    "value": "America/Whitehorse",
    "name": "MST - Yukon (east)"
}, {"value": "America/Dawson", "name": "MST - Yukon (west)"}, {
    "value": "America/Denver",
    "name": "Mountain"
}, {"value": "America/Edmonton", "name": "Mountain - AB; BC (E); SK (W)"}, {
    "value": "America/Boise",
    "name": "Mountain - ID (south); OR (east)"
}, {"value": "America/Yellowknife", "name": "Mountain - NT (central)"}, {
    "value": "America/Inuvik",
    "name": "Mountain - NT (west)"
}, {"value": "America/Cambridge_Bay", "name": "Mountain - NU (west)"}, {
    "value": "America/St_Johns",
    "name": "Newfoundland; Labrador (southeast)"
}, {"value": "America/Los_Angeles", "name": "Pacific"}, {
    "value": "America/Vancouver",
    "name": "Pacific - BC"
}];
