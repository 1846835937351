import { action, observable, runInAction, computed } from 'mobx';
import { LoadingState } from '../models';
import { Conversation, TeamMessageEvent } from "@src/stores/models/team-chat";
import {
    getConversations,
    markAsRead,
    deleteConversation
} from "@src/requests/team-chat.requests";
import { BaseStore } from "@src/stores/base.store";
import MessagesStore from '@src/stores/messages/messages.store';
import UserStore from '@src/stores/user.store';
import { SocketEventType, SocketWrapper } from "@src/api/socket-wrapper";
import AuthStore from '@src/stores/auth.store';

export default class ConversationsListStore extends BaseStore {
    @observable ConversationsStore;
    @observable _channelsList: Conversation[] = [];
    @observable _directConversationsList = [];
    private socketWrapper: SocketWrapper;

    @observable dataLoaded = false;
    @observable hasMoreToLoad = false;
    @observable loadingState: LoadingState = LoadingState.Init;

    constructor(socketWrapper: SocketWrapper, ConversationsStore) {
        super();

        this.socketWrapper = socketWrapper;
        if (this.socketWrapper) {
            this.socketWrapper.addEventListener(SocketEventType.TeamMembershipChange, (incomingMsg) => {
                this.changeDisplayingChannel(incomingMsg);
            });
            this.socketWrapper.addEventListener(SocketEventType.TeamMessage, (incomingMsg) => {
                this.displayNewDirectConversation(incomingMsg);
            });
        }

        this.ConversationsStore = ConversationsStore;
    }

    @computed get channelsList(): Conversation[] {
        return this._channelsList;
    }

    @action setChannelsList(val: Conversation[]) {
        this._channelsList = val;
    }

    @computed get directConversationsList(): Conversation[] {
        return this._directConversationsList;
    }

    // @action setDirectConversationsList(val: Conversation[]) {
    //     this._directConversationsList = val;
    // }

    @action init(): void {
        this._channelsList = [];
        this._directConversationsList = [];
        this.dataLoaded = false;
        this.hasMoreToLoad = false
        this.loadingState = LoadingState.Init;
    }

    @action displayNewDirectConversation = (e: TeamMessageEvent) => {
        if (this._directConversationsList.find(el => el.conversationId === e.event.conversationId) || !e.directMessage) return;

        const newConversation: Conversation = {
            conversationId: e.event.conversationId,
            conversationType: 'DIRECT',
            participants: e.participants.map((el: string) => {return {participantId: el, conversationId: e.event.conversationId }}),
            lastupdate: e.event?.created,
            timestamp: new Date(1607681104982)
        };
        this.addConversation(newConversation);
        this._directConversationsList = this._directConversationsList.sort(this.sortResults);
    }

    @action changeDisplayingChannel = (e) => {
        if (e.conversation?.type !== 'CHANNEL' || !e.data.members.find(el => el === MessagesStore.ThreadsStore.constants.employee.id)) return;

        if (!this._channelsList.find(el => el.conversationId === e.event.conversationId) && e.data?.type === 'ADDED_TO' ) {
            const newConversation: Conversation = {
                conversationId: e.event.conversationId,
                conversationType: 'CHANNEL',
                name: e.conversation.name,
                participants: e.participants.map((el: string) => {return {participantId: el}}),
                lastupdate: e.conversation.lastupdate,
                timestamp: e.conversation.timestamp
            };
            this.addConversation(newConversation);
            this._channelsList = this._channelsList.sort(this.sortResults);
        } else if (this._channelsList.find(el => el.conversationId === e.event.conversationId) && e.data?.type === 'REMOVED_FROM' ) {
            this._channelsList = this._channelsList.filter(el => el.conversationId !== e.event.conversationId);
            this.ConversationsStore.SelectedChannelStore.setSelectedChannel(null);
            this.ConversationsStore.SelectedChannelStore.setUserWasRemovedFromChannel(true);
        }
    }

    sortResults = (a, b) => {
        const A = new Date(a.lastupdate ? a.lastupdate : a.timestamp);
        const B = new Date(b.lastupdate ? b.lastupdate : b.timestamp);
        if (A > B) {
            return -1;
        }
        if (B > A) {
            return 1;
        }
        return 0;
    };

    setDMs = (userId: string, conversation: Conversation) => {
        const colleagueId = conversation.participants.find(p => p.participantId !== userId).participantId;
        const colleague = MessagesStore?.ThreadsStore.constants?.employees[colleagueId] || UserStore.user['employees'].find(el => el.id === colleagueId);
        const newDirectConversation = {
            colleagueName: `${colleague?.firstName} ${colleague?.lastName}`,
            lastread: conversation.lastread || conversation.timestamp,
            ...conversation
        }
        this._directConversationsList = [
            ...this._directConversationsList,
            newDirectConversation
        ]
    };

    addConversation = (conversation: Conversation) => {
        runInAction(() => {
            if (conversation.conversationType === "DIRECT") {
                const userId = MessagesStore?.ThreadsStore.constants?.employee.id || UserStore.user.employee.id;
                if (userId) {
                    this.setDMs(userId, conversation);
                }
                // else {
                //     MessagesStore.loadMessagesConstants({})
                //     .then(() => {
                //         console.log(2)
                //         this.setDMs(userId, conversation);
                //     })
                // }
            } else {
                const newChannel = {
                    lastread: conversation.lastread || conversation.timestamp,
                    ...conversation
                }
                this._channelsList = [
                    ...this._channelsList,
                    newChannel
                ]
            }
        })
    }

    reorderLists = () => {
        this._channelsList = this._channelsList.slice().sort(this.sortResults);
        this._directConversationsList = this._directConversationsList.slice().sort(this.sortResults);
    }

    @action getAndSetConversations = (firstLoading = false): Promise<void> => {
        this.loadingState = firstLoading ? LoadingState.FirstLoading : LoadingState.Loading;
        return getConversations().then(
            (data) => {
                runInAction(() => {
                    this._directConversationsList = [];
                    this._channelsList = [];
                    data.conversations.map(c => this.addConversation(c));
                    this.reorderLists();
                    this.hasMoreToLoad = false //res.data.total !== this._groupsList.length;
                    this.dataLoaded = true;
                    this.loadingState = LoadingState.Loaded;
                });
            },
            (err) => {
                if (err?.response?.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    runInAction(() => {
                        this.loadingState = LoadingState.Error;
                    });
                };
            }
        );
    };

    @action updateList = (id: string, change: string) => {
        const DM = this._directConversationsList.find(el => el.conversationId === id);
        const channel = this._channelsList.find(el => el.conversationId === id);
        if (DM) {
            const updated = change === 'lastread' ?
                {
                    ...DM,
                    lastread: new Date(),
                    lastupdate: new Date(DM.lastupdate)
                } : change === 'lastupdate' ?
                {
                    ...DM,
                    lastupdate: new Date(),
                    lastread: new Date(DM.lastread)
                } :
                {
                    ...DM,
                    lastupdate: new Date(),
                    lastread: new Date()
                }
            this._directConversationsList = this._directConversationsList.filter(el => el.conversationId !== id);
            this._directConversationsList = [
                updated,
                ...this._directConversationsList,
            ];
            this._directConversationsList = this._directConversationsList.slice().sort(this.sortResults);
        } else if (channel) {
            const updated = change === 'lastread' ?
                {
                    ...channel,
                    lastread: new Date(),
                    lastupdate: new Date(channel.lastupdate)
                } : change === 'lastupdate' ?
                {
                    ...channel,
                    lastupdate: new Date(),
                    lastread: new Date(channel.lastread)
                } :
                {
                    ...channel,
                    lastupdate: new Date(),
                    lastread: new Date()
                };
            this._channelsList = this._channelsList.filter(el => el.conversationId !== id);
            this._channelsList = [
                updated,
                ...this._channelsList,
            ];
            this._channelsList = this._channelsList.slice().sort(this.sortResults);
        }
    };

    @action markAsRead = (conversationId: string): Promise<void> => {
        return markAsRead(conversationId, Date.now()).then(
            () => {
                runInAction(() => {
                    this.updateList(conversationId, 'lastread')
                });
                UserStore.removeUnreadMessagesDot('teamConversations', conversationId)
            },
            () => {
                runInAction(() => {
                    //
                });
            }
        );
    };

    @action deleteConversation = (conversationId: string) => {
        return deleteConversation(conversationId).then(
            () => {
                runInAction(() => {
                    this._directConversationsList = this._directConversationsList.filter((d) => (d?.conversationId !== conversationId))
                });
                return true;
            },
            () => {
                runInAction(() => {
                    //
                });
                return null;
            }
        );
    }
}