/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import api from '@src/api/api';
import { withStores, STORES, WithUserStoreProps, WithWebchatStoreProps } from '@src/stores/with-store';
import { toast } from 'react-toastify';
import { images } from '@src/theme/images';
import { FormField, ToggleTabNew } from '@src/components';
import classNames from 'classnames';
import { useDebounce } from '@src/hooks';

import './webchat.sass';

type props = WithUserStoreProps & WithWebchatStoreProps;

const instruction = `To add the webchat on your webpage copy below snippet and paste it inside your webpage's <head></head> section. If you use the welcome message bubble and/or your own logo instead of Myopolis logo, please make sure to paste the snippet on your webpage everytime you change the message/logo's URL here.`

export const WebchatWithStore: React.FC<props> = (props) => {
    const locationId = props.UserStore.user.locationId;
    const baseUrl = api.defaults.baseURL;
    const baseUrlWithSlash = baseUrl.split('')[baseUrl.length - 1] === '/' ? baseUrl : baseUrl + '/';
    const [codeSnippet, setCodeSnippet] = useState(`<script src='${window.location.protocol}//${window.location.host + baseUrlWithSlash}messaging/webchat/${locationId}.js'></script>`);
    const [welcomeMessageContent, setWelcomeMessageContent] = useState(props.WebchatStore.welcomeMessage);
    const [ownLogoUrl, setOwnLogoUrl] = useState(props.WebchatStore.ownLogoUrl);
    const debounceWelcomeMessageContent = useDebounce(welcomeMessageContent, 3000);
    const debounceOwnLogoUrl = useDebounce(ownLogoUrl, 3000);
    const [userWantsWelcomeMessage, setUserWantsWelcomeMessage] = useState<boolean>(Boolean(props.WebchatStore.welcomeMessage));
    const [userWantsOwnLogo, setUserWantsOwnLogo] = useState<boolean>(Boolean(props.WebchatStore.ownLogoUrl));
    const [messageJustExceeded60, setMessageJustExceeded60] = useState<boolean>(false);
    const [key, setKey] = useState<number>(0);
    const [allowSaving, setAllowSaving] = useState<boolean>(false);

    const getWebchatData = () => {
        props.WebchatStore.getWebchatData()
        .then(() => {
            setUserWantsWelcomeMessage(Boolean(props.WebchatStore.welcomeMessage));
            setWelcomeMessageContent(props.WebchatStore.welcomeMessage);
            setUserWantsOwnLogo(Boolean(props.WebchatStore.ownLogoUrl));
            setOwnLogoUrl(props.WebchatStore.ownLogoUrl);
        });
    };

    useEffect(() => {
        getWebchatData();
    }, []);

    const updateCodeSnippet = () => {
        let welcomeMsg = '', logo = '';
        if (userWantsWelcomeMessage && welcomeMessageContent && welcomeMessageContent.length) {
            welcomeMsg = ` welcometext='${encodeURI(welcomeMessageContent)}'`;
        };
        if (userWantsOwnLogo && ownLogoUrl && ownLogoUrl.length) {
            logo = ` logo='${ownLogoUrl}'`;
        };
        setCodeSnippet(`<script src='${window.location.protocol}//${window.location.host + baseUrlWithSlash}messaging/webchat/${locationId}.js'${welcomeMsg}${logo}></script>`);

        setKey(key +1);

        if (!allowSaving) {
            setAllowSaving(true);
        };
    };

    useEffect(() => {
        updateCodeSnippet();
        if (!userWantsWelcomeMessage && welcomeMessageContent?.length) {
            setWelcomeMessageContent('');
        };
    }, [welcomeMessageContent, userWantsWelcomeMessage]);

    useEffect(() => {
        updateCodeSnippet();
        if (!userWantsOwnLogo && ownLogoUrl?.length) {
            setOwnLogoUrl('');
        };
    }, [ownLogoUrl, userWantsOwnLogo]);

    useEffect(() => {
        if (allowSaving) {
            props.WebchatStore.saveWebchatData({welcomeMessage: welcomeMessageContent, ownLogoUrl});
            setAllowSaving(false);
        };
    }, [debounceWelcomeMessageContent, debounceOwnLogoUrl]);

    const copyCodeSnippet = () => {
        const copyText: any = document.getElementById("codeSnippetInput");
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
        document.execCommand("copy");
        toast.success('Copied! Now you can paste it to your webpage.', { autoClose: 4000 });

        if (allowSaving) {
            props.WebchatStore.saveWebchatData({welcomeMessage: welcomeMessageContent, ownLogoUrl});
            setAllowSaving(false);
        };
    };

    const isOver60Characters = (e) => {
        if (welcomeMessageContent?.length >= 60 && (
            e.keyCode == 32 || // spacebar
            (e.keyCode > 47 && e.keyCode < 58) || // number keys
            (e.keyCode > 64 && e.keyCode < 91) || // letter keys
            (e.keyCode > 95 && e.keyCode < 112) || // numpad keys
            (e.keyCode > 185 && e.keyCode < 193) || // ;=,-./` (in order)
            (e.keyCode > 218 && e.keyCode < 223) // [\]' (in order)
        )) {
            setMessageJustExceeded60(true);
            setTimeout(() => setMessageJustExceeded60(false), 200);
        };
    };

    return (
        <div className="Webchat">
            <div className="Webchat__title">Webchat</div>
            {/* <div className="Webchat__subtitle">
                Find out more about Webchat
            </div> */}
            <div className="Webchat__section ilustration">
                <img src={images.webchatIlustration} alt="webchat ilustration" />
            </div>
            <div className="Webchat__section description">
                Webchat is a widget you can place on your webpage. If a customer wants to contact you, the webchat will collect their name, phone number, and message & send them to your Myopolis account (Messages section).
            </div>
            <div className="Webchat__section">
                <div className="Webchat__sectionOptional">
                    <div className="Webchat__sectionOptional-main">
                        <div className="optionalDescription">
                            Optional #1: Webchat may have a welcome message bubble. Once user closes it, or opens the webchat form, it will not be shown till the next month.
                        </div>
                        <img src={images.webchatBubble2} className="bubbleIlustrationVisibleOnSmallScreen" alt="welcome bubble message ilustration" />
                        <div className="optionalData">
                            <div className="question">Would you like to add a welcome message bubble?</div>
                            <div className="insertedOptionalData">
                                <ToggleTabNew
                                    key={key}
                                    commentBefore="NO"
                                    commentAfter="YES"
                                    isChecked={userWantsWelcomeMessage}
                                    stateChanged={(e) => setUserWantsWelcomeMessage(e)}
                                />
                                {userWantsWelcomeMessage &&
                                    <div className="settingOptionalContent">
                                        <div className={
                                            classNames('lettersCounter', {
                                                justExceeded60: messageJustExceeded60,
                                                exceeded60: welcomeMessageContent?.length >= 60
                                            })
                                        }>
                                            {welcomeMessageContent?.length}/60
                                        </div>
                                        <FormField htmlFor="msg">
                                            <input
                                                name="msg"
                                                defaultValue={welcomeMessageContent}
                                                onChange={(e) => setWelcomeMessageContent(e.target.value)}
                                                onKeyUp={isOver60Characters}
                                                autoComplete="off"
                                                className="form-input"
                                                maxLength={60}
                                                placeholder="Type your welcome message here"
                                            />
                                        </FormField>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="bubbleIlustrationVisibleOnBigScreen">
                        <img src={images.webchatBubble2} alt="welcome bubble message ilustration" />
                    </div>
                </div>
            </div>
            <div className="Webchat__section">
                <div className="Webchat__sectionOptional">
                    <div className="Webchat__sectionOptional-main">
                        <div className="optionalDescription">
                            Optional #2: You can also use your own logo instead of Myopolis logo. You would need the logo's URL. The logo suggestions:
                            <ul>
                                <li>small size (below 1MB) so it would not slow down your webpage - maximal displayed dimensions will be 40px x 40px</li>
                                <li>transparent background</li>
                                <li>looks good on <span className="colorBg whiteBg">white (#FFFFFF)</span> and <span className="colorBg navyBlueBg">navy blue (#232554)</span> background</li>
                            </ul>
                        </div>
                        <div className="optionalData">
                            <div className="question">Would you like to use your own logo?</div>
                            <div className="insertedOptionalData">
                                <ToggleTabNew
                                    key={key}
                                    commentBefore="NO"
                                    commentAfter="YES"
                                    isChecked={userWantsOwnLogo}
                                    stateChanged={(e) => setUserWantsOwnLogo(e)}
                                />
                                {userWantsOwnLogo &&
                                    <div className="settingOptionalContent">
                                        <FormField htmlFor="msg">
                                            <input
                                                name="msg"
                                                defaultValue={ownLogoUrl}
                                                onChange={(e) => setOwnLogoUrl(e.target.value)}
                                                autoComplete="off"
                                                className="form-input"
                                                placeholder="Provide your logo's URL here"
                                            />
                                        </FormField>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Webchat__section description">{instruction}</div>
            <div className="Webchat__section codeToCopy">
                <input
                    key={key}
                    type="text"
                    defaultValue={codeSnippet}
                    id="codeSnippetInput"
                />
                <button
                    className="btn-primary"
                    onClick={() => copyCodeSnippet()}
                >
                    Copy
                </button>
            </div>
        </div>
    );
};

// export const Webchat = withMessagesStore(WebchatWithStore);

export const Webchat = withStores(WebchatWithStore, [
    STORES.Webchat,
    STORES.User
]);
