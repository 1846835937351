import { GenericFileIcon } from '@src/components/GenericFileIcon/GenericFileIcon';
import { Attachment } from '@src/stores/models';
import React from 'react';
import BigPicture from 'bigpicture';

import '@src/theme/theme.sass';

export type AttachmentMouseEventHandler = React.MouseEventHandler<HTMLImageElement | HTMLVideoElement>

interface AttachmentProps {
    attachment: Attachment;
    className?: string;
    extension?: string
    onClick?: AttachmentMouseEventHandler;
}

export const AttachmentImage: React.FunctionComponent<AttachmentProps> = ({ attachment, className, onClick }) => (
    <img
        key={attachment.id}
        className={`attachment ${!!className ? className : ''} ${onClick ? 'clickable' : ''}`}
        src={attachment.url}
        alt="image attachment"
        onClick={onClick}
    />
);

export const AttachmentVideo: React.FunctionComponent<AttachmentProps> = ({ attachment, className, onClick }) => (
    <video
        key={attachment.id}
        className={`attachment ${!!className ? className : ''} ${onClick ? 'clickable' : ''}`}
        controls
        src={attachment.url}
    />
);

export const AttachmentUnknown: React.FunctionComponent<AttachmentProps> = ({ attachment, extension }) => (
    <div className="unknown-attachment" key={attachment.id}>
        <a
            href={attachment.url}
            target="_blank"
            rel="noopener noreferrer"
            key={attachment.id}
        >
            <GenericFileIcon text={extension} />
        </a>
    </div>
);

interface MessageAttachmentProps extends AttachmentProps {
    slim?: boolean;
};

const attachments = {
    image: AttachmentImage,
    video: AttachmentVideo,
    unknown: AttachmentUnknown
};

const MessageAttachment: React.FunctionComponent<MessageAttachmentProps> = ({ attachment, className, slim, onClick }) => {
    const slash = attachment.contentType.indexOf('/');
    let type = slim ? 'unknown' : attachment.contentType.substr(0, slash);
    if (type !== 'image' && type !== 'video') type = 'unknown';
    const extension = attachment.contentType.substr(slash + 1);

    return (
        attachments[type]({
            attachment,
            className,
            extension,
            onClick
        })
    );
};

interface MessageAttachmentsProps {
    msg: any;
    slim?: boolean;
}

const MessageAttachments: React.FunctionComponent<MessageAttachmentsProps> = ({ msg, slim }) => {
    const handleAttachmentClick = (msg, e: React.MouseEvent, attachmentId: string) => {
        const atts = msg.attachments.map(att => ({src: att.url}));
        const position = msg.attachments.findIndex((att: Attachment) => att.id === attachmentId);
        BigPicture({
            el: e.target,
            gallery: atts,
            position: position
        });
    };

    return (
        <>
            {msg.attachments.map((att: Attachment, i: number) => {
                return <MessageAttachment
                    key={i+att.id}
                    attachment={att}
                    slim={slim}
                    onClick={(e) => handleAttachmentClick(msg, e, att.id)}
                />
            })}
        </>
    );
};

export default MessageAttachments;
