import React, {useEffect, useMemo} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FilterBarSection, PopupHintsBubbleMessage } from '@src/components';
import {
    WithTagStoreProps,
    WithThreadsStoreProps,
    WithConversationsStoreProps,
    STORES,
    withStores,
} from '@src/stores/with-store';
import classNames from 'classnames';
import { ThreadFeed } from '@src/stores/models';
import { LeftMenuList } from './LeftMenuList';
import { ReactComponent as ArrowThinDownIcon } from '@src/theme/icons/arrow-thin-down.svg';
import { LoadingState } from '@src/stores/models';

import './LeftMenu.sass';

const CUSTOMER_FILTERS: FilterBarSection = {
    [ThreadFeed.ALL]: 'All',
    [ThreadFeed.ASSIGNED_TO_ME]: 'Assigned to me',
    [ThreadFeed.UNASSIGNED]: 'Unassigned',
};

const CUSTOMERS_FEED_MAP = {
    0: 0,
    1: 2,
    2: 1,
};

interface LeftMenuProps {
    tutorialNumber: number;
    changeTutorialDisplaying: (n: number) => void;
    closeTutorial: () => void;
}

type props =
    WithTagStoreProps
    & WithThreadsStoreProps
    & WithConversationsStoreProps
    & LeftMenuProps;

const LeftMenuWithStore: React.FC<props> = (props) => {
    const ThreadsStore = props.ThreadsStore;
    const TagsStore = props.TagsStore;
    const getFilterCls = (selected: boolean): string => classNames('item', { selected: !!selected });

    const customerFilters = useMemo(() => Object.entries(CUSTOMER_FILTERS), [CUSTOMER_FILTERS]);

    const getGroups = () => {
        return props.ConversationsStore.ConversationsListStore.getAndSetConversations();
    };

    useEffect(() => {
        getGroups();
    }, []);

    useEffect(() => {
        if (props.tutorialNumber) {
            TagsStore.TagsListStore.setDisplayTagsListAsOpen(false);
        }
    }, [props.tutorialNumber]);

    const closeTutorial = () => {
        props.closeTutorial();
    };

    const goToView = (n: number) => {
        props.changeTutorialDisplaying(n);
    };

    return (
        <section className="LeftMenu">
            <div className="LeftMenu__division">
                <div className="LeftMenu__subtitle firstSubtitle">Customers</div>
                <div className="LeftMenu__sections">
                    <div className={classNames("LeftMenu__section assigned", {popupHintsHighlightedAreaContainer: props.tutorialNumber === 1 })}>
                        {props.tutorialNumber === 1 ?
                            <PopupHintsBubbleMessage
                                hint='hint1'
                                title="Inbox"
                                number={1}
                                copy={[{content: 'Here you can find all your customer communication in one place.'}]}
                                closeTutorial={() => closeTutorial()}
                                goToView={(n) => goToView(n)}
                            />
                        : null}
                        {customerFilters.map(([key, label]) => {
                            const rightKey = CUSTOMERS_FEED_MAP[key];
                            const cls = getFilterCls(+rightKey === ThreadsStore.feed);
                            const countValue = ThreadsStore.filtersCount?.get(+key);
                            return (
                                <div key={uuidv4()} className={cls}>
                                    <div onClick={() => ThreadsStore.searchThreads({ feed: rightKey })}>
                                        <div><span>{label}</span></div>
                                        {!!countValue && <div className="pill white_background">{countValue}</div>}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={classNames('tags', {popupHintsHighlightedAreaContainer: props.tutorialNumber === 2 })}>
                        {props.tutorialNumber === 2 ?
                            <PopupHintsBubbleMessage
                                hint='hint2'
                                title="Organization"
                                number={2}
                                copy={[{content: 'Tags help with filtering your conversations.'}]}
                                closeTutorial={() => closeTutorial()}
                                goToView={(n) => goToView(n)}
                            />
                        : null}
                        <LeftMenuList type="tag" />
                    </div>
                </div>
            </div>
            <div className="LeftMenu__division">
                <div className={classNames('teamMessaging', {popupHintsHighlightedAreaContainer: props.tutorialNumber === 3 })}>
                    <div className="LeftMenu__subtitle">Team</div>
                    {props.tutorialNumber === 3 ?
                        <PopupHintsBubbleMessage
                            hint='hint3'
                            title="Internal Communication"
                            number={3}
                            copy={[{content: 'Manage your internal communication with Groups and Direct Messages.'}]}
                            closeTutorial={() => closeTutorial()}
                            goToView={(n) => goToView(n)}
                        />
                    : null}
                   {(TagsStore.TagsListStore.displayTagsListAsOpen || TagsStore.TagsListStore.loading === LoadingState.Loading) ?
                        <div className="LeftMenu__sections">
                            <div className="LeftMenu__section">
                                <div
                                    className="yellowBtn showMoreHide"
                                    onClick={() => TagsStore.TagsListStore.setDisplayTagsListAsOpen(false)}
                                >
                                    <ArrowThinDownIcon />Show more
                                </div>
                            </div>
                        </div> :
                        <div className="LeftMenu__sections">
                            <LeftMenuList type="teamChannel" />
                            <LeftMenuList type="teamDirect" />
                        </div>
                   }
                </div>
            </div>
        </section>
    );
};

export const LeftMenu = withStores(LeftMenuWithStore, [
    STORES.Messages,
    STORES.Tags,
    STORES.ThreadsStore,
    STORES.ConversationsStore,
]);
