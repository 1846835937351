import { observable, action } from 'mobx';
import { SocketEventType, SocketWrapper } from '@src/api/socket-wrapper';
import SocketInstance from '@src/stores/socket-instance';
import { notificationsApi } from '@src/requests';
import { Notification, LoadingState } from './models';
import { BaseStore } from './base.store';

export class NotificationsStore extends BaseStore {
    private socketWrapper: SocketWrapper;
    constructor(socketWrapper: SocketWrapper) {
        super();
        this.socketWrapper = socketWrapper;
    }

    @observable notifications: Notification[] = [];
    @observable loadingState: LoadingState = LoadingState.Init;

    @action destroy(): void {
        this.notifications = [];
        this.loadingState = LoadingState.Init;
        if (this.socketWrapper) {
            this.socketWrapper.destroy();
        };
    }

    @action.bound init(): void {
        if (this.socketWrapper) {
            this.socketWrapper.addEventListener(SocketEventType.Notification, (incoming) => {
                const newNotifications = [...this.notifications, incoming];
                this.setNotifications(newNotifications);
            });
        }
    }

    @action setNotifications(notifications: Notification[]): void {
        this.notifications = notifications;
    }

    @action.bound loadNotification(page?, size?): Promise<void> {
        this.loadingState = LoadingState.Loading;
        return notificationsApi.getNotifications(page, size).then(
            ({ data }) => {
                const notifications = data.sort((a, b) => b.created - a.created);
                this.setNotifications(notifications);
                this.loadingState = LoadingState.Loaded;
            },
            (error) => {
                console.log('error :', error);
                this.loadingState = LoadingState.Loaded;
            }
        );
    }

    @action.bound markAllNotificationsAsRead(): Promise<void> {
        this.loadingState = LoadingState.Loading;
        return notificationsApi.markAllNotificationsAsRead().then(
            () => {
                const notifications = this.notifications.map((n) => ({ ...n, read: true }));
                console.log('notifications', notifications);
                this.setNotifications(notifications);
                this.loadingState = LoadingState.Loaded;
            },
            (error) => {
                console.log('error :', error);
                this.loadingState = LoadingState.Loaded;
            }
        );
    }
}

export default new NotificationsStore(SocketInstance);
