import React, { useState, useEffect } from 'react';
import { images } from '@src/theme/images';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { FormField, CheckboxField } from '@src/components/FormField/FormField';
import { NavLink } from 'react-router-dom';
import { postLogin } from '@src/requests/auth.requests';
import { useHistory } from 'react-router-dom';
import { WithAuthStoreProps, WithSignupStoreProps, STORES, withStores } from '@src/stores/with-store';
import { REDIRECT_BACK_TO_LOCALSTORAGE_KEY } from '@src/theme/utils/constants';
import { ERROR_TOAST_AUTOCLOSE } from '@src/theme/utils/constants';

import '../Auth.sass';


type SignInProps = WithAuthStoreProps & WithSignupStoreProps;

export const SignInStore: React.FC<SignInProps> = (props) => {
    const re = /^(?:(?:[\w`~!#$%^&*\-=+;:{}'|,?\/]+(?:(?:\.(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)*"|[\w`~!#$%^&*\-=+;:{}'|,?\/]+))*\.[\w`~!#$%^&*\-=+;:{}'|,?\/]+)?)|(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d\-]+(?:\.[a-zA-Z\d\-]+)*|\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])$/;
    const { register, handleSubmit, errors } = useForm();
    const [error, setError] = useState(false);
    const history = useHistory();
    const redirectBackTo = localStorage.getItem(REDIRECT_BACK_TO_LOCALSTORAGE_KEY);
    const redirectPath = redirectBackTo && redirectBackTo || '/dashboard';

    const signIn = (data) => {
        if (error) setError(false);

        postLogin(data).then(
            () => {
                props.AuthStore.loadAuthLogin().then((val) => {
                    if (val === 'successIncomplete') {
                        history.push('/signupfull');
                        toast.success("Please complete your sign up here!", { autoClose: 4000 });
                    } else if (val === 'success') {
                        history.push(redirectPath);
                        localStorage.removeItem(REDIRECT_BACK_TO_LOCALSTORAGE_KEY);
                    }
                });
            },
            () => setError(true)
        );
    };

    //this is to support the production signup being pointed toward the LP quiz
    function SignUpLink() {
        if (window['_env_'].SIGNUP_URL) {
            return <a href={window['_env_'].SIGNUP_URL}>Sign Up</a>;
        }
        return <a href={"https://www.myopolis.com/pricing/"}>Sign Up</a>;
    }

    const googleAuthorizationSuccessOrAccountAlreadyCreated = () => {
        props.AuthStore.loadAuthLogin().then((val) => {
            if (val.startsWith('success')) {
                history.push(redirectPath);
                localStorage.removeItem(REDIRECT_BACK_TO_LOCALSTORAGE_KEY);
            }
        });
    };

    useEffect(() => {
        if (props.SignupStore.googleAuthorization === 'success' || props.SignupStore.googleAuthorization === 'accountAlreadyCreated') {
            googleAuthorizationSuccessOrAccountAlreadyCreated();
        } else if (props.SignupStore.googleAuthorization === 'requestCompleteSignup') {
            toast.success("We don't have your account - please complete your sign up here!", ERROR_TOAST_AUTOCLOSE);
            history.push(`/signupfull`);
        } else if (props.SignupStore.googleAuthorization === 'fail') {
            toast.error('Something went wrong. Please try again.', ERROR_TOAST_AUTOCLOSE);
        }
    }, [props.SignupStore.googleAuthorization]);

    return (
        <div className="Auth">
            <div className="AuthLogo">
                <img src={images.logo} className="AuthLogo-image" alt="app-logo" />
            </div>
            <div className="AuthContainer signin">
                <h1 className="AuthContainer__header">Sign In</h1>
                <form className="AuthContainer__form" onSubmit={handleSubmit(signIn)}>
                    {error && <div className="AuthContainer__form-error">Invalid email/password</div>}
                    <FormField>
                        <input
                            type="text"
                            name="email"
                            data-test="email"
                            placeholder="Email"
                            className={classNames('form-input', { error: errors.email })}
                            ref={register({
                                required: 'Missing Email',
                                pattern: {
                                    value: re,
                                    message: 'Invalid email',
                                },
                            })}
                        />
                    </FormField>
                    <FormField>
                        <input
                            type="password"
                            name="password"
                            data-test="password"
                            placeholder="Password"
                            className={classNames('form-input', { error: errors.password })}
                            ref={register({
                                required: 'Missing Password',
                            })}
                            autoComplete="current-password"
                        />
                    </FormField>
                    <div className="options">
                        <div>
                            <CheckboxField
                                label="Remember me"
                                defaultValue={true}
                                name="rememberMe"
                                ref={register()}
                            />
                        </div>
                        <NavLink to="/forgot">Forgot pwd?</NavLink>
                    </div>
                    <div>
                        <input type="submit" className="btn-primary" value="Sign In" />
                    </div>
                </form>
                <div className="AuthContainer__accept">
                    By continuing, I accept the Myopolis{' '}
                    <a
                        href="https://www.myopolis.com/terms-of-use/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        terms of service
                    </a>{' '}
                    and have read the{' '}
                    <a
                        href="https://www.myopolis.com/privacy-policy/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        privacy policy
                    </a>
                </div>
            </div>
        </div>
    );
};

// export const SignIn = withAuthStore(SignInStore);
export const SignIn = withStores(SignInStore, [STORES.Auth, STORES.Signup]);
