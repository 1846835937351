import { useOutsideClick } from '@src/hooks';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';

import './Autocomplete.sass';

export interface Option {
    label?: string;
    value: string;
}

interface AutocompleteProps<T extends Option> {
    className?: string;
    emptyOptionsText: string;
    hasMore?: boolean;
    minLengthToShowOptions?: number;
    name?: string;
    options: T[];
    scrollThreshold?: number;
    value?: string;
    loader?: React.ReactNode;
    loadMore?: (page: number) => void;
    onChange?: (value: string) => void;
    onOptionClick?: (option: T) => void;
    renderOption: ((option: T, selected: boolean) => React.ReactNode);
    renderAddNewElementOption?: React.ReactNode;
}

export function Autocomplete<T extends Option>({
    className,
    emptyOptionsText,
    hasMore,
    minLengthToShowOptions=2,
    name,
    options,
    scrollThreshold=10,
    value,
    loader,
    loadMore,
    onChange,
    onOptionClick,
    renderOption,
    renderAddNewElementOption
}: AutocompleteProps<T>): React.ReactElement {
    const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
    const [selectedIdx, setSelectedIdx] = useState<number>(-1);
    const selectedOptionRef = useRef(null);
    const autocompleteRef = useRef(null);

    useOutsideClick(autocompleteRef, () => {
        setIsShowOptions(false);
    });

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setIsShowOptions(newValue.length >= minLengthToShowOptions);
        onChange && onChange(newValue);
    };

    const handleOptionClick = (value: T) => {
        // setSelectedIdx(-1);
        onOptionClick && onOptionClick(value);
    };

    const handleDropDownClick = () => {
        setIsShowOptions(prevShowOptions => !prevShowOptions);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        const up = 38;
        const down = 40;
        const enter = 13;
        const esc = 27;

        if (e.keyCode === esc) {
            e.preventDefault();
            setIsShowOptions(false);
            return;
        };

        if (e.keyCode === enter) {
            onOptionClick && onOptionClick(options[selectedIdx]);
            setIsShowOptions(false);
            return;
        };

        if (!isShowOptions && e.keyCode === down) {
            e.preventDefault();
            setSelectedIdx(options.findIndex(option => option.value === value));
            setIsShowOptions(true);
        };

        if (isShowOptions && e.keyCode === down) {
            e.preventDefault();
            const newIdx = selectedIdx === options.length - 1 ? selectedIdx : selectedIdx + 1;
            newIdx !== selectedIdx && setSelectedIdx(newIdx);
            setIsShowOptions(true);
            return;
        };

        if (isShowOptions && e.keyCode === up) {
            e.preventDefault();
            const newIdx = selectedIdx - 1;
            setSelectedIdx(newIdx);
            setIsShowOptions(newIdx > -1);
            return;
        };
    };

    useEffect(() => {
        if (selectedOptionRef?.current) {
            selectedOptionRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }, [selectedIdx]);


    const emptyList = options.length === 0 &&
        <div className="noItemsToDisplay">
            {emptyOptionsText}
        </div>

    const resultsList = options.length > 0 && options.map((option, i) => (
        <div
            key={i + option.value}
            className="option-container"
            tabIndex={-1}
            onClick={() => handleOptionClick(option)}
            ref={selectedIdx === i ? selectedOptionRef : undefined}
        >
            {typeof renderOption === 'function' ? renderOption(option, selectedIdx === i) : renderOption}
        </div>
    ))

    return (
        <div className={`autocomplete ${className}`} ref={autocompleteRef}>
            <input
                type="text"
                name={name}
                className="form-input"
                value={value ?? ''}
                onChange={handleValueChange}
                onKeyDown={handleKeyDown}
                autoComplete='off'
            />
            <button type="button" onClick={handleDropDownClick}>
                <div className={`arrow-down ${isShowOptions ? 'rotate180' : ''}`}/>
            </button>
            {isShowOptions &&
                <div className='autocomplete-options'>
                    <InfiniteScroll
                        pageStart={0}
                        loader={loader ?? <div className="loader" key={0}>Loading ...</div>}
                        loadMore={loadMore}
                        initialLoad={false}
                        hasMore={hasMore}
                        useWindow={false}
                        threshold={scrollThreshold}
                    >
                        {/* { renderAddNewElementOption } */}
                        { renderAddNewElementOption || emptyList || resultsList }
                    </InfiniteScroll>
                </div>
            }
        </div>
    );
};

export default Autocomplete;

