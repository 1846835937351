import React, { useState, useEffect } from 'react';
import { FormField } from '@src/components/FormField/FormField';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import './Signup.sass';
import './Email.sass';
import { useHistory } from 'react-router-dom';
import { SignupForm } from '@src/stores/models/signup';
import { re } from '@src/theme/utils/constants';
import { trackGoogleEvent, SignupAnalyticsEvents } from '@src/theme/utils/Analytics';

// const re = /^(?:(?:[\w`~!#$%^&*\-=+;:{}'|,?\/]+(?:(?:\.(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)*"|[\w`~!#$%^&*\-=+;:{}'|,?\/]+))*\.[\w`~!#$%^&*\-=+;:{}'|,?\/]+)?)|(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d\-]+(?:\.[a-zA-Z\d\-]+)*|\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])$/;

export interface FormData {
    email: string;
}

interface EmailFormProps {
    previousData: SignupForm;
    onNext: (data: FormData) => void;
    googleAuthorize: () => void;
}

export const EmailForm: React.FunctionComponent<EmailFormProps> = ({ previousData, onNext, googleAuthorize }) => {
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm<FormData>();
    const [ currentDataEmail, setCurrentDataEmail ] = useState('');

    useEffect(() => {
        trackGoogleEvent(SignupAnalyticsEvents.FlowEntry);
        setCurrentDataEmail(previousData?.email)
    }, [previousData?.email]);

    return (
        <div className='email-form-container'>
            <h2>Let&apos;s create your unique Myopolis account</h2>
            <form className='email-form' onSubmit={handleSubmit(onNext)}>
                <FormField label="Work email" htmlFor="email" error={errors.email}>
                    <input
                        data-test="email"
                        type="text"
                        name="email"
                        placeholder="Enter work email"
                        defaultValue={previousData?.email}
                        className={classNames('form-input', { error: errors.email })}
                        ref={register({
                            required: 'Missing work email',
                            pattern: {
                                value: re,
                                message: 'Invalid email',
                            },
                        })}
                        onChange={(e) => setCurrentDataEmail(e.target.value)}
                    />
                </FormField>
                <button
                    type="submit"
                    className={classNames('btn-primary btn-submit')}
                    disabled={!currentDataEmail}
                >
                    Continue
                </button>
            </form>
            <div className="email-form-footer">
                <p className="subtitle AuthContainer__subtitle">Already have an account?</p>
                <button
                    className="btn-tetriary-blue"
                    onClick={() => history.replace({ pathname: `/signin` })}
                >
                    Login
                </button>
            </div>
        </div>
    )
}