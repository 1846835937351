import React from 'react';
// import ReactTooltip from 'react-tooltip';
import { ActivePlan } from '@src/stores/models/billing';
import { getProperPriceObj } from '@src/theme/utils/helpers';
import { displayIntegerOrTwoDecimalPlaces } from '@src/theme/utils/helpers';

import './Plan.sass';


interface PlanMobileProps {
    activePlan: ActivePlan;
    actionBar: React.ReactNode;
    interval: 'month' | 'year';
}

const PlanMobile: React.FunctionComponent<PlanMobileProps> = ({ actionBar, activePlan, interval }) => {
    const { billingPlan: plan, components } = activePlan;

    return (
        <div className='PricingPlan'>
            <div className="PricingPlan__name">{plan.billingPlan.name}</div>
            {getProperPriceObj(plan, interval) ?
                <>
                    <div className="PricingPlan__priceContainer">
                        {(interval === 'year' && plan.prices.find(el => el.interval.interval === 'month')) &&
                            <div className="PricingPlan__priceContainer-monthPrice">${displayIntegerOrTwoDecimalPlaces(getProperPriceObj(plan, 'month').amount)}</div>
                        }
                        <div className="PricingPlan__priceContainer-price">
                            <div className="PricingPlan__priceContainer-price-amount">
                                ${displayIntegerOrTwoDecimalPlaces(interval === 'year' ? (getProperPriceObj(plan, interval).amount / 12) : getProperPriceObj(plan, interval).amount)}
                            </div>
                            <div className="PricingPlan__priceContainer-price-perMonth">/mo</div>
                        </div>
                    </div>
                    <div className="PricingPlan__action">
                        {actionBar}
                    </div>
                </>
                : <>
                    <div className="PricingPlan__priceContainer">
                        <div className="PricingPlan__priceContainer-price"></div>
                        <div className="PricingPlan__priceContainer-monthly">ONLY AVAILABLE CHARGED PER {interval === 'month' ? 'YEAR' : 'MONTH'}</div>
                    </div>
                    <div className="PricingPlan__action"></div>
                </>
            }
        </div>
    );
};

export default PlanMobile;
