import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Input } from 'reactstrap';
import InputMask from 'react-input-mask';
import { FormViewType } from '../ContactForm/FormViewType';
import {
    Customer,
    CustomerBase,
    LoadingState,
    OnErrorThread
} from '@src/stores/models';
import { toast } from 'react-toastify';
import { onSuccess as toastHandleSuccess } from '@src/theme/utils/constants';
import { AsYouType } from 'libphonenumber-js';
import {
    FormField,
    ErrorToastSave
} from '@src/components';
import { withStores, STORES } from '@src/stores/with-store';
import { ThreadsStore } from '@src/stores/messages/threads.store';
import { TagsStore } from '@src/stores/tags/tags.store';
import { re } from '@src/theme/utils/constants';
import { v4 as uuidv4 } from 'uuid';
import { convertToPureNumber } from '@src/theme/utils/helpers';
import { ERROR_TOAST_AUTOCLOSE } from '@src/theme/utils/constants';

import './new-contact.sass';
import '../messages-common.sass';

type OwnerPage = 'messages' | 'customers';

interface MessageProps {
    expanded?: boolean;
    notesOnly?: boolean;
    changeExpanded?: () => void;
    closeDialog?: () => void;
    ownerPage?: OwnerPage;
    ThreadsStore: ThreadsStore;
    TagsStore: TagsStore;
    onCustomerSendMsg?: (action: FormViewType, customer?: Customer) => void;
    onCreateClick?: () => void;
    onSuccess?: () => void;
}

const NewContactOnlyAddWithStore: React.FC<MessageProps> = ({ ownerPage = 'messages', ...props}) => {
    const store = props.ThreadsStore;
    const history = useHistory();
    const [emailError, setEmailError] = useState(null);
    const [suggestedTags, setSuggestedTags] = useState(props.TagsStore.TagsListStore.tagsListOnAddContactView);

    const inputsRefs = {
        firstName: useRef(null),
        lastName: useRef(null),
        phone: useRef(null),
        email: useRef(null),
        msg: useRef(null),
        tags: useRef(null)
    };

    const [phone, setPhone] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [tags, setTags] = useState('');

    const getTags = () => {
        return props.TagsStore.TagsListStore.searchMoreTagsForAddContactView()
        .then(() => {
            setSuggestedTags(props.TagsStore.TagsListStore.tagsListOnAddContactView);
        });
    };

    useEffect(() => {
        getTags();
        inputsRefs.firstName.current.focus();
    }, []);

    const validData = (data: string) => {
        if (data === 'firstName' && firstName) return true;
        if (data === 'lastName' && lastName) return true;
        if (
            data === 'phone' &&
            phone &&
            convertToPureNumber(phone).length === 10
        ) return true;
        if (data === 'email' && email) return email.match(re) ? true : false;
        if (data === 'tags') return true;
        return false;
    };

    const createNewThread = (c: CustomerBase) => {
        const isEmailValid = c.email ? validData('email') : true;
        if (!isEmailValid) {
            setEmailError({message: 'Invalid email'});
            return;
        } else {
            const customer =
                c.email && c.tags ? c
                : c.email ? { firstName: c.firstName, lastName: c.lastName, phone: c.phone, email: c.email }
                : c.tags ? { firstName: c.firstName, lastName: c.lastName, phone: c.phone, tags: c.tags }
                : { firstName: c.firstName, lastName: c.lastName, phone: c.phone };
            store
                .createNewThread(customer, ownerPage === 'messages', 'addCustomer')
                .then(() => history.replace({ pathname: ownerPage === 'messages' ? `/messages/?new` : '/customers' }));
        };
    };

    const handleCreateClick = (customer: CustomerBase) => {
        createNewThread(customer);
        props.onCreateClick && props.onCreateClick();
    };

    const onErrorIncorrectPhone = () => {
        toast.error('Phone number is invalid. Please provide a valid 10 digit phone number', ERROR_TOAST_AUTOCLOSE);
    };
    store.onErrorAddingCustomer = (type: string, data?: OnErrorThread) => {
        store.loading = LoadingState.Error;
        switch (type) {
            case 'addCustomer': {
                toast.error(<ErrorToastSave repeatUploading={() => createNewThread(data)} />);
                break;
            }
            default: {
                return;
            }
        }
    };
    store.onSuccess = () => {
        store.loading = LoadingState.Loaded;
        toastHandleSuccess();
        props.onSuccess && props.onSuccess();
    };

    store.onErrorIncorrectPhone = () => {
        store.loading = LoadingState.Error;
        onErrorIncorrectPhone();
    };

    const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(new AsYouType('US').input(e.target.value));
    };

    const dontAllowButtons = Boolean(
        !firstName ||
        !lastName ||
        !phone ||
        convertToPureNumber(phone).length !== 10
    );

    const inputClicked = (e: React.KeyboardEvent<HTMLInputElement>, input: string): void => {
        if (e.keyCode === 9) {
            e.preventDefault();
        };
        if ((e.keyCode === 13 || e.keyCode === 9) && validData(input)) { // enter, tab
            if (input === 'firstName' && firstName) {
                inputsRefs.lastName.current.focus();
            } else if (input === 'lastName' && lastName) {
                inputsRefs.phone.current.focus();
            } else if (input === 'phone' && phone) {
                inputsRefs.email.current.focus();
            } else if (input === 'email' && email) {
                inputsRefs.tags.current.focus();
            } else if (input === 'tags' && tags) {
                createNewThread({ firstName, lastName, phone, email, tags });
            };
        } else if (e.keyCode === 8) { // backspace
            if (input === 'lastName' && !lastName) {
                inputsRefs.firstName.current.focus();
                e.preventDefault();
            } else if (input === 'phone' && (!phone || phone === '+1 (___) ___-____' || phone === '+1 ' || phone === '+1')) {
                inputsRefs.lastName.current.focus();
                e.preventDefault();
            } else if (input === 'email' && !email) {
                inputsRefs.phone.current.focus();
                e.preventDefault();
            } else if (input === 'tags' && !tags) {
                inputsRefs.email.current.focus();
                e.preventDefault();
            };
        } else {
            setTimeout(() => {
                if (input === 'phone' && phone && convertToPureNumber(phone).length === 9) {
                    inputsRefs.email.current.focus();
                };
            }, 100);
        };
    };

    const selectTag = (t) => {
        setTags(tags.length ? tags + ', ' + t.value : t.value);
        setSuggestedTags(suggestedTags.filter(el => el.id !== t.id));
    };

    const detailedInputs = (
        <>
            <div key="name-wrapper" className="ncRow nameRow">
                <FormField label="First Name *" htmlFor="firstName">
                    <input
                        data-test="firstName"
                        type="text"
                        name="firstName"
                        defaultValue={firstName}
                        onChange={(e) => {
                            setFirstName(e.target.value);
                        }}
                        onKeyDown={(e) => inputClicked(e, 'firstName')}
                        className='form-input'
                        ref={inputsRefs.firstName}
                    />
                </FormField>
                <FormField label="Last Name *" htmlFor="lastName">
                    <input
                        data-test="lastName"
                        type="text"
                        name="lastName"
                        defaultValue={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        onKeyDown={(e) => inputClicked(e, 'lastName')}
                        className='form-input'
                        ref={inputsRefs.lastName}
                    />
                </FormField>
            </div>
            <div key="phone" className="ncRow">
                <FormField label="Phone Number *" htmlFor="phone">
                    <Input
                        data-test="phone"
                        type="text"
                        mask="+1 (999) 999-9999"
                        name="phone"
                        value={phone}
                        onChange={(e) => onPhoneChange(e)}
                        onKeyDown={(e) => inputClicked(e, 'phone')}
                        className='form-input'
                        inputRef={(e) => inputsRefs.phone.current = e}
                        tag={InputMask}
                    />
                </FormField>
            </div>
            <div key="email" className="ncRow">
                <FormField label="Email" htmlFor="email">
                    <input
                        type="email"
                        name="email"
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailError(null);
                        }}
                        onKeyDown={(e) => inputClicked(e, 'email')}
                        className={classNames('form-input', { error: emailError })}
                        ref={inputsRefs.email}
                    />
                </FormField>
            </div>
            <div key="tagstotype" className="ncRow">
                <FormField label="Tags (separate them by commas)" htmlFor="tags">
                    <input
                        type="tags"
                        name="tags"
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                        onKeyDown={(e) => inputClicked(e, 'tags')}
                        className='form-input'
                        ref={inputsRefs.tags}
                    />
                </FormField>
            </div>
            {(suggestedTags.length || props.TagsStore.TagsListStore.loadingTagsListOnAddContactView !== LoadingState.Loaded) ?
                <div key="suggestedtags" className="ncRow suggestedTagsRow">
                    <div className="form-label suggestedTagsTitle">Suggested Tags</div>
                    <div className="suggestedTagsItems">
                        {suggestedTags.map((t) => {
                            return (
                                <div
                                    key={uuidv4()}
                                    className="tagItem"
                                    onClick={() => selectTag(t)}
                                >
                                    {t.value}
                                </div>
                            );
                        })}
                    </div>
                </div>
            : null}
            <div key="createbutton" className="ncRow actionRow">
                <button
                    className='btn-primary btn-createContact'
                    disabled={dontAllowButtons}
                    onClick={() => handleCreateClick({ firstName, lastName, phone, email, tags })}
                >
                    {`Create New Contact${ownerPage === 'messages' ? ' & Message' : ''}`}
                </button>
            </div>
        </>
    );

    return (
        <section className="NewContactOnlyAdd">
            <div className="NewContactOnlyAdd__header">
                <h2>Add Contact</h2>
            </div>
            <div className="NewContactOnlyAdd__form">
                {detailedInputs}
            </div>
        </section>
    );
};

export const NewContactOnlyAdd = withStores(NewContactOnlyAddWithStore, [STORES.ThreadsStore, STORES.Tags]);
