import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { STORES, WithDashboardStoreProps, WithProfileStoreProps, withStores, WithWalkthroughStoreProps } from '@src/stores/with-store';
import { ImportCustomers, Loader } from '@src/components';
import ProfileTasks, { getTasks } from './ProfileTasks';
import ProfileCompletion from './ProfileCompletion';
import ProfilePhone from './ProfilePhone';
import { LoadingState } from '@src/stores/models';

import './Profile.sass';

type ProfileProps = WithProfileStoreProps &  WithDashboardStoreProps & WithWalkthroughStoreProps;

export const ProfileWithStore: React.FC<ProfileProps> = ({ ProfileStore, DashboardStore, WalkthroughStore }) => {
    const profile = ProfileStore.profile;
    const [dialogOpen, setDialogOpen] = useState(false);
    const history = useHistory();
    const showTasks = !Object.values(profile).reduce((acc, value) => acc && value, true);

    const handleGo = (url: string) => {
        if (url) {
            history.push(url);
        } else {
            setDialogOpen(true);
        }
    };

    const renderTasks = (DashboardStore.loadingGeneral !== LoadingState.Loaded || ProfileStore.loading !== LoadingState.Loaded || showTasks) && <>
        <div className="Profile__getting-started">
            <h3>Getting Started</h3>
            <p>In 5 minutes you're going to be a Myopolis superstar!</p>
        </div>
        <ProfileCompletion profile={profile}/>
        <ProfileTasks
            userRole={DashboardStore.employee?.role}
            tasks={getTasks(profile, WalkthroughStore)}
            onGo={handleGo}
        />
    </>;

    return (
        <div className="Profile">
            <Loader loadingState={ProfileStore.loading} />
            <ImportCustomers openDialog={dialogOpen} closeDialog={() => setDialogOpen(false)} />
            <ProfilePhone
                loading={ProfileStore.loading !== LoadingState.Loaded}
                phone={DashboardStore.phone}
            />
            {renderTasks}
        </div>
    );
};

export const Profile = withStores(ProfileWithStore, [
    STORES.Dashboard,
    STORES.Profile,
    STORES.Walkthrough
]);
