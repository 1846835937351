import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {toast} from "react-toastify";
import classNames from 'classnames';
import { STORES, WithAuthStoreProps, WithSignupStoreProps, withStores } from '@src/stores/with-store';
import {images} from "@src/theme/images";
import {setTimeoutAndClear} from "@src/utils/timeout";
import { transformPhoneNumber } from '@src/theme/utils/helpers';
import {ChangePhone} from "./ChangePhone";
import { ERROR_TOAST_AUTOCLOSE } from '@src/theme/utils/constants';

import './PhoneVerificationForm.sass';

interface PhoneVerificationProps {
    phone?: string;
    onNext?: () => void;
    onBack?: () => void;
}

type props = WithAuthStoreProps & WithSignupStoreProps & PhoneVerificationProps;
type CodeType = string;
type LocationState = {
    userPhone: string,
    priceId: string,
    priceAmount: number,
    requireCC: boolean
}

const PhoneVerificationFormWithStore: React.FunctionComponent<props> = (props): JSX.Element | null => {
    const {SignupStore} = props;
    const history = useHistory();
    const location = useLocation();
    const [userPhone, setUserPhone] = useState(location.state ? (location.state as LocationState).userPhone : props.phone); // like (213) 123-1231);

    const isItShortSignup = !props.phone;

    useEffect(() => {
        if (!SignupStore.user) {
            SignupStore.getUser()
            .then((val) => {
                if (!val) {
                    history.push('/signup');
                    toast.error('Something went wrong, please try again', ERROR_TOAST_AUTOCLOSE);
                }
            });
        }
        sendSMS();
    }, []);

    const sendSMS = (correctedNumber?: string) => {
        SignupStore.initVerification(getPhoneNumberToSent(correctedNumber))
            .then(() => {
                toast.success(
                    'SMS code was sent to your phone',
                    { autoClose: 3000 }
                );
            })
            .catch(() => {
                toast.error(
                    'Whoops!, the provided phone number is either a VOIP number or does not support SMS. Please provide an SMS enabled mobile phone number',
                    { autoClose: 5000 }
                );
            })
    }

    const likeSubmit = (): void => {
        if (code.filter(Boolean).length === 6) {
            verification(getPhoneNumberToSent())
                .then(() => {
                    if (!isItShortSignup) {
                        props.onNext();
                    } else if (location.state) {
                        history.push('/signup/phone', {
                            priceId: (location.state as LocationState).priceId,
                            priceAmount: (location.state as LocationState).priceAmount,
                            requireCC: (location.state as LocationState).requireCC
                        });
                    }
                })
        }
    };
    const [code, setCode] = useState<CodeType[]>(new Array(6).fill(''));
    const inputWrapper = useRef(null);

    const phoneInputHandle = (index: number, value: string): void => {
        const newCodes = [...code]
        const next = () => {
            setTimeoutAndClear(() => {
                if (index < code.length - 1) {
                    inputWrapper.current.getElementsByTagName('input')[index + 1].focus()
                }
            })
        }

        if (!value.length || (!Number(value) && value !== '0')) {
            newCodes[index] = '';
        } else if ( value.length > 1) {
            newCodes[index] = value.substring(0, 1) || ''
            next();
        } else {
            newCodes[index] = value;
            next();
        }
        setCode([...newCodes])
    }

    const verification = (phone: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            SignupStore.makeVerification(phone, code.join(''))
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    toast.error(err?.response?.data === 'Wrong code or phone number, please try again.' ? 'Wrong code, please try again.' : 'Something went wrong. Please try again.', ERROR_TOAST_AUTOCLOSE);
                    setCode((new Array(6).fill('')));
                    reject();
                })
        })
    }

    const getPhoneNumberToSent = (correctedNumber?: string): string | null => {
        // if (['localhost', 'qa.myopolis.com', 'aq.myopolis.com', 'local.myopolis.com'].some(el => el === window.location.hostname)) return encodeURIComponent('+48790546255')
        const number = correctedNumber || userPhone;
        if (!number) {
            return null
        }
        return encodeURIComponent('+1' + number
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace('+', '')
            .replace(/\s/g,''))
    }

    if (!userPhone && isItShortSignup) {
        history.push('/signup');
        return null
    }

    if (!userPhone && !isItShortSignup) {
        props.onBack();
        return null
    }

    const inputClicked = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            index === code.length - 1 &&
            (e.keyCode === 13 || e.keyCode === 9) &&
            code.filter(Boolean).length === 6
        ) {
            inputWrapper.current.getElementsByTagName('input')[code.length - 1].blur();
            likeSubmit();
        }
    };

    const handleNumberCorrection = (correctedNumber: string) => {
        setUserPhone(correctedNumber);
        const dto ={
            companyName: SignupStore.user.companyInfo.companyName,
            address: SignupStore.user.companyInfo.address,
            firstName: SignupStore.user.user.firstName,
            lastName: SignupStore.user.user.lastName,
            phone: correctedNumber,
            email: SignupStore.user.user.email,
            timeZone: SignupStore.user.user.timeZone
        }
        SignupStore.createOrUpdateAccount(dto, true)
        .then((val) => {
            if (val === 'ok') {
                history.push(location.pathname, {
                    userPhone: correctedNumber,
                    priceId: (location.state as LocationState).priceId,
                    priceAmount: (location.state as LocationState).priceAmount,
                    requireCC: (location.state as LocationState).requireCC
                });
                sendSMS(correctedNumber);
            }
            if (!val) {
                history.push('/signup');
                toast.error('Something went wrong, please try again', ERROR_TOAST_AUTOCLOSE);
            }
        });
    };

    return (
        <div className={classNames("Signup__main PhoneVerificationForm", {shortSignupPhoneVerification: isItShortSignup, longSignupPhoneVerification: !isItShortSignup})}>
            <p className="PhoneVerificationForm__header">Verify your phone</p>
            <img className="PhoneVerificationForm__img" src={images.illustrationPhone} alt=""/>
            <p className="PhoneVerificationForm__desc">
                We sent you a verification code <br/>
                to your phone number {transformPhoneNumber(userPhone)}
            </p>

            {isItShortSignup && <ChangePhone onNumberCorrection={handleNumberCorrection} />}

            <div className="PhoneVerificationForm__inputWrapper">
                <label>Enter the verification code:</label>
                <div ref={inputWrapper}>
                    {code.map((value: string, index : number) => {
                        return (
                            <input
                                key={index}
                                value={code[index]}
                                type="text"
                                onChange={(e) => phoneInputHandle(index, e.currentTarget.value)}
                                onKeyDown={(e) => inputClicked(index, e)}
                            />
                        )
                    })}
                </div>

                <div className="again">
                    <p>Didn’t receive the code?</p>
                    <button onClick={() => sendSMS()}>Request again</button>
                </div>

                {isItShortSignup ?
                    <div className="shortSignupButtons">
                        <button
                            type="button"
                            className='btn-primary-full btn-submit'
                            onClick={likeSubmit}
                            disabled={code.filter(Boolean).length !== 6}
                        >
                            Next
                        </button>
                    </div>
                :
                    <div className="longSignupButtons">
                        <button
                            type="button"
                            className='btn-tetriary-blue'
                            onClick={() => props.onBack()}
                        >
                            Back
                        </button>
                        <button
                            type="button"
                            className='btn-primary-full'
                            onClick={likeSubmit}
                            disabled={code.filter(Boolean).length !== 6}
                        >
                            Next
                        </button>
                    </div>
                }

            </div>
        </div>
    );
};

export const PhoneVerificationForm = withStores(PhoneVerificationFormWithStore, [STORES.Signup, STORES.Auth]);
