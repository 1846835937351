import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import { useHistory } from 'react-router-dom';
import {
    STORES,
    withStores,
    WithMessagesStoreProps,
    WithTagStoreProps,
    WithConversationsStoreProps,
} from '@src/stores/with-store';
import { ThreadsStore } from '@src/stores/messages/threads.store';
import { Dialog } from '@src/components';
import { TagsChannelsEdition } from '@src/pages/Messages/components/Edition/TagsChannelsEdition';
import { images } from '@src/theme/images';
import { ReactComponent as Hashtag } from '@src/theme/icons/groups.svg';
import { ReactComponent as AddIcon } from '@src/theme/icons/add.svg';
import { ReactComponent as ClearIcon } from '@src/theme/icons/clear.svg';
import { ReactComponent as Tag } from '@src/theme/icons/tag-outlined-grey.svg';
import { ReactComponent as TagSelected } from '@src/theme/icons/tag-filled.svg';
import { ReactComponent as ChatIcon } from '@src/theme/icons/chat.svg';
import { ReactComponent as CloseIcon } from '@src/theme/icons/close-sm.svg';
import { ReactComponent as ArrowThinDownIcon } from '@src/theme/icons/arrow-thin-down.svg';
import { ReactComponent as ArrowThinUpIcon } from '@src/theme/icons/arrow-thin-up.svg';
import { useOutsideClick, useDebounce } from '@src/hooks';
import { onSuccess } from '@src/theme/utils/constants';
import { NewDirectMessage } from "@src/pages/Messages/components/Edition/NewDirectMessage";
import { tagsListItemsNumberWhenListNotExpanded } from '@src/theme/utils/constants';
import { LoadingState } from '@src/stores/models';

interface LeftMenuListProps {
    type: string;
}

type props =
    LeftMenuListProps &
    { ThreadsStore: ThreadsStore } &
    WithMessagesStoreProps &
    WithTagStoreProps &
    WithConversationsStoreProps;

const LeftMenuListWithStore: React.FC<props> = (props) => {
    const ThreadsStore = props.ThreadsStore;
    const TagsStore = props.TagsStore;
    const MessagesStore = props.MessagesStore;
    const ConversationsStore = props.ConversationsStore;

    const viewTag = props.type === 'tag';
    const viewTeamDirect = props.type === 'teamDirect';
    const viewTeamChannel = props.type === 'teamChannel';

    const history = useHistory();
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const [openEdition, setOpenEdition] = useState(false);
    const [openCreation, setOpenCreation] = useState(false);
    const [showEmptyTag, setShowEmptyTag] = useState(false);
    const [listLength, setListLength] = useState(TagsStore.TagsListStore.displayTagsListAsOpen ? TagsStore.TagsListStore.tagsList.length : tagsListItemsNumberWhenListNotExpanded);
    const [searchText, setSearchText] = useState('');
    const debouceSearchText = useDebounce(searchText, 200);
    const [hasFocus, setHasFocus] = useState(false);
    const [ableToRequest, setAbleToRequest] = useState(true);
    const userRole = MessagesStore?.ThreadsStore?.constants?.employee?.role;
    const isOwnerOrLeader = userRole === 0 || userRole === 1;

    if (viewTag) {
        MessagesStore.onSuccess = () => {
            onSuccess();
        };
    }

    useOutsideClick(wrapperRef, () => {
        setHasFocus(false);
    });

    const searchTextChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
        setSearchText(e.target.value);

    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.keyCode === 27) setSearchText('');
    };

    const clear = () => {
        setSearchText('');
        inputRef.current.focus();
    };

    const startEdition = (el) => {
        const req =
            viewTag ?
                TagsStore.EditedTagStore.setTagEdition(el) :
                ConversationsStore.EditedChannelStore.setChannelEdition(el);
        return req
        .then(() => {
            setOpenEdition(true);
        });
    };

    const closeEdition = () => {
        setOpenEdition(false);
        setOpenCreation(false);
        if (viewTag) TagsStore.EditedTagStore.closeTagEdition();
        if (viewTeamChannel) ConversationsStore.EditedChannelStore.closeConversation();
    };

    const groupDeleted = (isDeletedBroadcastSelected: boolean) => {
        if (isDeletedBroadcastSelected) history.replace({ pathname: '/messages/' });
        closeEdition();
    };

    const selectItem = (el) => {
        Promise.all([
            ThreadsStore.closeCurrentThread(),
            ConversationsStore.SelectedChannelStore.closeConversation(),
            ConversationsStore.SelectedDirectConversationStore.closeConversation()
        ])
        .then(() => {
            if (viewTeamDirect) {
                ConversationsStore.SelectedDirectConversationStore.selectDirectConversation(el.conversationId).then(() => {
                    ThreadsStore.closeCurrentThread();
                    history.replace({ pathname: `/messages/d/${el.conversationId}` });
                    if (el.lastupdate > el.lastread) {
                        ConversationsStore.ConversationsListStore.markAsRead(el.conversationId);
                    }
                });
            } else if (viewTeamChannel) {
                ConversationsStore.SelectedChannelStore.selectChannel(el.conversationId, el.name).then(() => {
                    ThreadsStore.closeCurrentThread();
                    history.replace({ pathname: `/messages/c/${el.conversationId}` });
                    if (el.lastupdate > el.lastread) {
                        ConversationsStore.ConversationsListStore.markAsRead(el.conversationId);
                    }
                });
            };
        })
    };

    const openNew = () => {
        if (viewTag) TagsStore.EditedTagStore.newTag();
        setOpenCreation(true);
    };

    const fetchNewItemsAfterRemoving = () => {
        if (viewTag) {
            setShowEmptyTag(true);
            TagsStore.TagsListStore.resetPage();
            return TagsStore.TagsListStore.searchMoreTags(searchText, 'tagsFilter-created', 'created.desc')
            .then(() => setShowEmptyTag(false));
        }
    }

    useEffect(() => {
        if (viewTag) {
            setListLength(TagsStore.TagsListStore.displayTagsListAsOpen ? TagsStore.TagsListStore.tagsList.length : tagsListItemsNumberWhenListNotExpanded);
            if (!TagsStore.TagsListStore.displayTagsListAsOpen && TagsStore.TagsListStore.tagsList.length < tagsListItemsNumberWhenListNotExpanded && TagsStore.TagsListStore.total >= tagsListItemsNumberWhenListNotExpanded ) {
                fetchNewItemsAfterRemoving();
            }
        }
    }, [
        TagsStore.TagsListStore.displayTagsListAsOpen,
        TagsStore?.TagsListStore?.tagsList?.length
    ]);

    const getItems = (emptySearch?: boolean) => {
        if (viewTag) {
            const source = !TagsStore.TagsListStore.displayTagsListAsOpen ? 'tagsFilter-created' : 'tagsFilter-value';
            const sort = !TagsStore.TagsListStore.displayTagsListAsOpen ? 'created.desc' : 'value.asc';
            return TagsStore.TagsListStore.searchMoreTags(emptySearch ? '' : searchText, source, sort)?.then(() => {
                setAbleToRequest(true);
            });
        }
    }

    useEffect(() => {
        if (viewTag) {
            if (searchText.length !== 1) {
                TagsStore.TagsListStore.setSearchedTagsPage(0);
                getItems();
            }
        }
    }, [debouceSearchText]);

    useEffect(() => {
        if (viewTag) {
            if (searchText.length !== 1) {
                TagsStore.TagsListStore.setSearchedTagsPage(0);
                setSearchText('')
                getItems(true);
            }
        }
    }, [TagsStore.TagsListStore.displayTagsListAsOpen]);

    const loadMoreItems = () => {
        if (!ableToRequest) {
            return;
        }
        setAbleToRequest(false);
        if (viewTag) {
            getItems();
        }
    };

    useEffect(() => {
        setOpenCreation(false);
    }, [history.location.pathname]);

    const removeConversation = (id: string) => {
        ConversationsStore.ConversationsListStore.deleteConversation(id)
        .then(() => {
            if (id === ConversationsStore.SelectedDirectConversationStore.selectedDirect?.id) {
                history.replace({ pathname: '/messages/' });
                ConversationsStore.SelectedDirectConversationStore.closeConversation();
            }
        })
    };

    const changeList = () => {
        TagsStore.TagsListStore.setLoading(LoadingState.Loading);
        TagsStore.TagsListStore.setDisplayTagsListAsOpen(TagsStore.TagsListStore.displayTagsListAsOpen ? false : true);
    };

    return (
        <div className="LeftMenu__section">
            <div className="LeftMenu__sectionTitle">
                <div className="title">
                    {
                    viewTag ? 'Tags' :
                    viewTeamDirect ? 'Direct Messages' :
                    viewTeamChannel ? 'Groups' :
                    null}
                </div>
                <div className="actions">
                    {(viewTag && TagsStore.TagsListStore.tagsList.length && TagsStore.TagsListStore.selectedTagsIds.length) ?
                        <>
                            <div className="selectedTagsNumber">{TagsStore.TagsListStore.selectedTagsIds.length} selected</div>
                            <div className="verticalSelector" />
                            <div
                                className="yellowBtn"
                                onClick={() => TagsStore.TagsListStore.filterByTag('')}
                            >
                                clear
                            </div>
                        </>
                    : null}
                    {(!viewTeamChannel || isOwnerOrLeader) &&
                        <>
                            <ReactTooltip />
                            <AddIcon
                                data-tip={
                                    viewTag ? 'Create New Tag' :
                                    viewTeamDirect ? 'Create New Direct Message' :
                                    viewTeamChannel ? 'Create New Group' :
                                    null
                                }
                                onClick={() => openNew()}
                            />
                        </>
                    }
                </div>
            </div>
            {(viewTag && TagsStore.TagsListStore.displayTagsListAsOpen && TagsStore.TagsListStore.loading !== LoadingState.Loading) && (
                <div
                    className={classNames('LeftMenu__sectionSearch', {'filled-out': searchText || hasFocus})}
                    ref={wrapperRef}
                >
                    {/* <SearchIcon/> */}
                    <input
                        name="searchTemplate"
                        type="text"
                        placeholder={"Search tags"}
                        value={searchText}
                        ref={inputRef}
                        onFocus={() => setHasFocus(true)}
                        onKeyDown={(e) => keyDown(e)}
                        onChange={(e) => searchTextChange(e)}
                    />
                    {searchText && (
                        <button className="clear-btn" onClick={(): void => clear()}>
                            <ClearIcon />
                        </button>
                    )}
                </div>
            )}
            <div className={classNames('LeftMenu__sectionOption filter', {controlListHeight: viewTag })}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMoreItems}
                    initialLoad={false}
                    hasMore={viewTag ? TagsStore.TagsListStore.hasMoreTagsToLoad : null}
                    useWindow={false}
                    threshold={100}
                >
                    {viewTag &&
                        TagsStore.TagsListStore.tagsList?.slice(0, listLength).map((t) => {
                            const isActive = TagsStore.TagsListStore.selectedTagsIds.some(
                                (f) => f === t.id
                            );
                            return (
                                <div key={uuidv4()} className="tagItem">
                                    <div
                                        className={classNames('name', { active: isActive })}
                                        onClick={() => TagsStore.TagsListStore.filterByTag(t.id)}
                                    >
                                        {isActive ? <TagSelected /> : <Tag />}
                                        <ReactTooltip />
                                        <span data-tip={t.value}>{t.value}</span>
                                    </div>
                                    <img
                                        className="edition showOnHover"
                                        src={images.editPencil}
                                        alt="edit-icon"
                                        onClick={() => startEdition(t)}
                                    />
                                </div>
                            );
                        })
                    }
                    {(viewTag && showEmptyTag) &&
                        <div className={viewTag ? "tagItem" : "groupItem"}>
                            <div className={classNames('name')}>
                                <span> </span>
                            </div>
                        </div>
                    }
                    {viewTeamDirect &&
                        ConversationsStore.ConversationsListStore.directConversationsList?.map((d) => {
                            return (
                                <div key={uuidv4()} className="DMItem">
                                    <div
                                        className={classNames('name', {
                                            active: ConversationsStore.SelectedDirectConversationStore.selectedDirect?.id === d.conversationId,
                                        })}
                                        onClick={() => selectItem(d)}
                                    >
                                        <ChatIcon />
                                        <ReactTooltip />
                                        <span data-tip={d.colleagueName}>{d.colleagueName}</span>
                                    </div>
                                    <div className="unreadOrEdition">
                                        {d.lastupdate > d.lastread &&
                                            <div className="unreadDot" />
                                        }
                                        <button
                                            className="remove showOnHover"
                                            onClick={() => removeConversation(d.conversationId)}
                                        >
                                            <CloseIcon />
                                        </button>
                                    </div>
                                </div>
                            );
                        })
                    }
                    {viewTeamChannel &&
                        ConversationsStore.ConversationsListStore.channelsList?.map((ch) => {
                            if (ch.name === 'General') {
                                console.log(ConversationsStore.SelectedChannelStore.selectedChannel?.id, ch.conversationId, ConversationsStore.SelectedChannelStore.selectedChannel?.id === ch.conversationId)
                            };
                            return (
                                <div
                                    key={uuidv4()}
                                    className={classNames('channelItem', { isOwnerOrLeader: isOwnerOrLeader })}
                                >
                                    <div
                                        className={classNames('name', {
                                            active: ConversationsStore.SelectedChannelStore.selectedChannel?.id === ch.conversationId,
                                        })}
                                        onClick={() => selectItem(ch)}
                                    >
                                        <Hashtag />
                                        <ReactTooltip />
                                        <span data-tip={ch.name}>{ch.name}</span>
                                    </div>
                                    <div className="unreadOrEdition">
                                        {ch.lastupdate > ch.lastread &&
                                            <div className="unreadDot" />
                                        }
                                        {isOwnerOrLeader &&
                                            <img
                                                className="edition showOnHover"
                                                src={images.editPencil}
                                                alt="edit-icon"
                                                onClick={() => startEdition(ch)}
                                            />
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </InfiniteScroll>
            </div>
            {viewTag &&
                <div
                    className="yellowBtn showMoreHide"
                    onClick={() => changeList()}
                >
                    {TagsStore.TagsListStore.hasMoreTagsToLoad && !TagsStore.TagsListStore.displayTagsListAsOpen ?
                        <><ArrowThinDownIcon />Show more</> :
                        TagsStore.TagsListStore.displayTagsListAsOpen ?
                        <><ArrowThinUpIcon />Hide</> :
                        null
                    }
                </div>
            }
            {(viewTag || viewTeamChannel) &&
                <Dialog
                    className="LeftMenu__dialog"
                    open={openEdition || openCreation}
                    onClose={() => closeEdition()}
                >
                    <TagsChannelsEdition
                        type={
                            viewTag ? 'Tag' :
                            viewTeamChannel ? 'Group' :
                            null}
                        isNew={openCreation ? true : false}
                        onDelete={
                            viewTeamChannel ? groupDeleted :
                            viewTag ? closeEdition :
                            null}
                    />
                </Dialog>
            }
            {(viewTeamDirect) &&
                <Dialog
                    className="LeftMenu__dialog"
                    open={openCreation}
                    onClose={() => closeEdition()}
                >
                    <NewDirectMessage onClose={() => closeEdition()} onNewDirectConversation={selectItem} />
                </Dialog>
            }
        </div>
    );
};

export const LeftMenuList = withStores(LeftMenuListWithStore, [
    STORES.ThreadsStore,
    STORES.Messages,
    STORES.Tags,
    STORES.ConversationsStore,
]);
