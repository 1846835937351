import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { useOutsideClick } from '@src/hooks';
import { ReactComponent as CloseIcon } from '@src/theme/icons/close.svg';

import './dialog.sass';

interface DialogProps {
    open?: boolean;
    title?: string;
    noShadowBehind?: true;
    className?: string;
    dontCloseOnOutsideClick?: boolean;
    onClose: () => void;
}

export const Dialog: React.FC<PropsWithChildren<DialogProps>> = (props) => {
    const dialogBodyRef = React.createRef<HTMLDivElement>();
    useOutsideClick(dialogBodyRef, () => {
        if (!props.dontCloseOnOutsideClick) props.onClose();
    });

    const html = !props.open ? null : (
        <div data-test="Dialog" className={classNames('Dialog', { noShadowBehind: props.noShadowBehind })}>
            <div className={`card ${props.className ?? ''}`} ref={dialogBodyRef}>
                <div className={classNames('Dialog__header', { empty: !props.title })}>
                    <h3>{props.title}</h3>
                    <button onClick={() => props.onClose()}>
                        <CloseIcon />
                    </button>
                </div>
                {props.children}
            </div>
        </div>
    );

    return ReactDOM.createPortal(html, document.body);
};
