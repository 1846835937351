import React, { useState, useRef, useEffect } from 'react';
import { NavLink, Link, useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { authPages } from '@src/pages';
import { useOutsideClick } from '@src/hooks';
import {
    STORES,
    WithAuthStoreProps,
    WithUserStoreProps,
    WithBillingStoreProps,
    withStores,
    WithThreadsStoreProps,
    WithReviewsStoreProps,
    WithNotificationsStoreProps,
} from '@src/stores/with-store';
import { NotificationType, ThreadFeed } from '@src/stores/models';
import { Badge } from '@src/components';
import { images } from '@src/theme/images';
import { newMsgFavicons } from './newMsgFavicons/newMsgFavicons';
import {
    AlertIcon,
    HelpIcon,
    ProfileIcon,
    Message, //notification dropdown
} from './svgImages';


import './header.sass';

declare const window: any;

const dontShowHeaderIfTheseUrls = [
    '/account',
    '/activate',
    '/confirm',
    '/user/password/reset',
    '/forgot',
];

type HeaderProps = WithAuthStoreProps &
    WithUserStoreProps &
    WithBillingStoreProps &
    WithThreadsStoreProps &
    WithReviewsStoreProps &
    WithNotificationsStoreProps;

export const HeaderWithStore: React.FC<HeaderProps> = (props) => {
    const location = useLocation()
    const history = useHistory()
    const onboarding = location.pathname === '/onboarding';
    const pathname = location.pathname.split('/')[1];
    const areNewMessages = props.UserStore?.user?.newMessages?.customerThreads?.length || props.UserStore?.user?.newMessages?.teamConversations?.length;

    useEffect(() => {
        if (areNewMessages) {
            document.getElementById('favicon32')['href'] = newMsgFavicons.newMessageFavicon32;
            document.getElementById('favicon192')['href'] = newMsgFavicons.newMessageFavicon192;
        } else {
            document.getElementById('favicon32')['href'] = 'https://cdn.myopolis.com/2020/06/cropped-appIcon-dark-32x32.png';
            document.getElementById('favicon192')['href'] = 'https://cdn.myopolis.com/2020/06/cropped-appIcon-dark-192x192.png';
        }
    }, [areNewMessages]);

    const doesUrlInclude = dontShowHeaderIfTheseUrls.findIndex(el => location.pathname.search(el) > -1 && location.pathname.search('/settings/account') === -1);
    if (doesUrlInclude > -1) {
        return;
    };

    const authPagesToDisplay = authPages
    .filter(el =>
        !el.notInHeader
        && (!el.hideForMembers || props.UserStore.user?.employee?.role < 2)
        // && (!el.hideForSubscriptionProblem || props.AuthStore.accountStatus !== 'LOCKED')
        // && (!el.hideIfBroadcastsLocked || props.UserStore.user.isAllowedToUseBroadcast)
    );

    const [currentLocation, setCurrentLocation] = useState(
        authPagesToDisplay.find((el) => el.path == '/dashboard')?.label ||
        authPagesToDisplay.find((el) => el.path == '/settings')?.label ||
        authPagesToDisplay.find((el) => el.path == '/customers')?.label ||
        'menu'
    );

    const isMessage = (type: number) => type === NotificationType.MESSAGE_RECEIVED;

    const { notifications, init } = props.NotificationsStore;

    const threadsStore = props.ThreadsStore;
    const reviewsStore = props.ReviewsStore;

    const msgCount = threadsStore.filtersCount?.get(ThreadFeed.ALL);
    const notificationsCount = 0;
    const reviewsCount = reviewsStore.generalData?.unanswered
        ? reviewsStore.generalData.unanswered
        : 0;

    const [profileDropdown, setProfileDropdown] = useState(false);
    const [notificationsDropdown, setNotificationsDropdown] = useState(false);
    const [mobileNav, setMobileNav] = useState(false);

    const profileRef = useRef(null);
    useOutsideClick(profileRef, () => {
        setProfileDropdown(false);
    });

    const notificationsRef = useRef(null);
    useOutsideClick(notificationsRef, () => {
        setNotificationsDropdown(false);
    });

    const handleLogoutClick = () => {
        props.AuthStore.logout().then(() => {
            history.push("/signin");
        }).catch(() => {
            toast.error("Logout failed. Please check your internet connection, or try again later.")
        })
    };

    const mobileNavRef = React.createRef<HTMLDivElement>();
    useOutsideClick(mobileNavRef, () => {
        setMobileNav(false);
    });

    useEffect(() => init(), [init, threadsStore.init]);

    useEffect(() => {
        const page = authPagesToDisplay.find((el) => el.path.indexOf(pathname) >= 0);
        setCurrentLocation((page && page.label) || 'menu');
    }, [pathname]);

    const closeMobileNav = () => {
        setMobileNav(false);
    };

    const logoLink =
        props.AuthStore.accountStatus !== 'LOCKED' ? "/dashboard" :
        props.UserStore.user?.employee?.role < 2 ? "/settings" :
        "/customers";

    const isOwnerOrLeader = props.UserStore.user?.employee.role < 2;

    const banner = props.UserStore.trialBanner.show &&
        <>
            <div className={`banner`}>
                {props.UserStore.trialBanner.daysLeft === 0 ?
                    'TRIAL: ONLY TODAY.' :
                    `TRIAL: ${props.UserStore.trialBanner.daysLeft} DAYS LEFT.`
                }
                {isOwnerOrLeader ?
                    <>{' '}<span onClick={() => history.push("/settings/billing")}>ADD BILLING INFO</span></> :
                    <>{' '}ASK YOUR MANAGER TO ADD BILLING INFO.</>
                }
            </div>
        </>

    return (
        <header>
            {banner}
            <div className={classNames('header', { displayingBanner: props.UserStore.trialBanner.show })}>
                <NavLink key="logo" to={logoLink}>
                    <img src={images.logo} className="app-logo" alt="app-logo" />
                </NavLink>
                {!onboarding && (
                    <>
                        <nav>
                        {/* <nav className={classNames({ limitedTabs: props.AuthStore.accountStatus === 'LOCKED' })}> */}
                            <div className="navigation">
                                {authPagesToDisplay.map((page) => {
                                    const finalLink =
                                        page.path.indexOf(':') > -1
                                            ? page.path.substring(0, page.path.indexOf(':'))
                                            : page.path;
                                    return (
                                        <NavLink key={page.label} to={finalLink} activeClassName="active">
                                            {page.label}
                                            {/* {page.label === 'messages' && <div className={classNames('unreadDot', { show: !!areNewMessages })} />} uncomment this and remove below when BE for unread message tab notification is done */}
                                            {page.label === 'messages' && <Badge count={msgCount} />}
                                            {page.label === 'reviews' && <Badge count={reviewsCount} />}
                                        </NavLink>
                                    );
                                })}
                            </div>
                        </nav>
                        <div className="mobile">
                            <div>
                                <div className="mobile-current" onClick={() => setMobileNav(true)}>
                                    <span>{currentLocation}</span>
                                    <img src={images.arrowDown} />
                                </div>
                                <div className={classNames('mobile-list', { active: mobileNav })}>
                                    <div ref={mobileNavRef}>
                                        {authPagesToDisplay.map((page) => {
                                            const finalLink =
                                                page.path.indexOf(':') > -1
                                                    ? page.path.substring(0, page.path.indexOf(':'))
                                                    : page.path;
                                            return (
                                                <NavLink
                                                    onClick={() => closeMobileNav()}
                                                    key={page.label}
                                                    to={finalLink}
                                                    activeClassName="active"
                                                >
                                                    {page.label}
                                                    {/* {page.label === 'messages' && <div className={classNames('unreadDot', { show: !!areNewMessages })} />} uncomment this and remove below when BE for unread message tab notification is done */}
                                                    {page.label === 'messages' && <Badge count={msgCount} /> }
                                                    {page.label === 'reviews' && (
                                                        <Badge count={reviewsCount} />
                                                    )}
                                                </NavLink>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="icon-buttons">
                            {false && (
                                <>
                                    {/* For now, this button is unnecessary */}
                                    {/* Notifications are switched off */}
                                    <button
                                        ref={notificationsRef}
                                        className={classNames('notifications', {
                                            active: notificationsDropdown,
                                        })}
                                        onClick={() =>
                                            !notificationsDropdown && setNotificationsDropdown(true)
                                        }
                                    >
                                        <AlertIcon className="alert-icon" />
                                        <Badge count={notificationsCount} />
                                        {notificationsDropdown && ( //            !notification dropdown!
                                            <div className="dropdown">
                                                <div className="dropdown-header">
                                                    <div>Notifications</div>
                                                    {/*<div className="btn" onClick={() => markAllNotificationsAsRead()}>Mark all as read</div>*/}
                                                </div>
                                                {!notificationsCount ? (
                                                    <div className="dropdown-info">
                                                        You don&apos;t have any notifications
                                                    </div>
                                                ) : (
                                                    <div className="dropdown-options">
                                                        {/*<Loader loadingState={loading}/>*/}
                                                        {notifications.map((notification) => (
                                                            <div
                                                                key={notification.id}
                                                                className={classNames(
                                                                    'dropdown-option',
                                                                    { read: notification.read }
                                                                )}
                                                            >
                                                                <div className="dropdown-option-icon">
                                                                    {isMessage(notification.type) && (
                                                                        <Message />
                                                                    )}
                                                                    {/*{isReviewNew(notification.type) && <Review/>}
                                                            {isReviewUpdated(notification.type) &&
                                                            <ReviewUpdated className="star"/>}*/}
                                                                </div>
                                                                <Link
                                                                    to={
                                                                        isMessage(notification.type)
                                                                            ? `/messages/${notification.entityId}`
                                                                            : '/reviews'
                                                                    }
                                                                    onClick={() =>
                                                                        setNotificationsDropdown(false)
                                                                    }
                                                                    className="dropdown-option-title"
                                                                >
                                                                    {notification.title}
                                                                </Link>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                <div className="dropdown-footer">
                                                    <Link
                                                        to={'/notifications'}
                                                        onClick={() => setNotificationsDropdown(false)}
                                                        className="btn"
                                                    >
                                                        See all
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </button>
                                </>
                            )}
                            <button className="info" title='Help'>
                                <HelpIcon className="help-icon" onClick={() => props.UserStore.setShowSupportScreen(true)} />
                            </button>
                            <div ref={profileRef} className={classNames('profile')}>
                                <button
                                    data-test="profile-dropdown-btn"
                                    title="Profile options"
                                    className={classNames({ active: profileDropdown })}
                                    onClick={() => setProfileDropdown(pd => !pd)}
                                >
                                    <ProfileIcon />
                                </button>
                                {profileDropdown && (
                                    <div className="dropdown" title="">
                                        <Link
                                            to={'/user-settings'}
                                            onClick={() => setProfileDropdown(false)}
                                            className="dropdown-option"
                                        >
                                            <img src={images.profileEditBlack} alt="edit-profile" />
                                            {/* <ProfileBlackIcon /> */}
                                            <span>User Settings</span>
                                        </Link>
                                        <button className="dropdown-option logout" onClick={handleLogoutClick} type="button">
                                            {/* <ArrowDownIcon /> */}
                                            <img src={images.arrowLeftBlack} alt="log-out" />
                                            <span>Log Out</span>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </header>
    );
};

export const Header = withStores(HeaderWithStore, [
    STORES.Notifications,
    STORES.Auth,
    STORES.User,
    STORES.Billing,
    STORES.ThreadsStore,
    STORES.Reviews,
]);
