import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { displayNameOrPhone } from '@src/theme/utils/helpers';
import { useDebounce, useOutsideClick } from '@src/hooks';
import { Customer } from '@src/stores/models';
import { FormViewType } from '@src/pages/Messages/components/ContactForm/FormViewType';
import { ReactComponent as ClearIcon } from '@src/theme/icons/clear.svg';
import { ReactComponent as SearchIcon } from '@src/theme/icons/search-inactive.svg';
import { ReactComponent as ProfileIcon } from '@src/theme/icons/profile.svg';

import './search-input.sass';

export interface SearchSubmitArgs {
    search: string;
    open?: boolean;
}

interface SearchInputProps {
    people: Customer[];
    clearSearchText?: { tags: number; feed: number; open: boolean };
    onSearchTextChange?: (searchText: string) => void;
    onSearchSubmit?: (s: SearchSubmitArgs) => void;
    onCustomerSendMsg?: (action: FormViewType, customer?: Customer) => void;
}

export const SearchInput: React.FC<SearchInputProps> = (props) => {
    const inputRef = useRef(null);
    const wrapperRef = useRef(null);
    const [hasFocus, setHasFocus] = useState<boolean>(false);
    const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);
    const [searchText, setSearchText] = useState('');
    const [activeIdx, setActiveIdx] = useState<number>(-1);
    const debounceSearchText = useDebounce(searchText, 250);
    const people = props.people;
    let globalIdx = -1;

    useOutsideClick(wrapperRef, () => {
        setShowAutocomplete(false);
        setHasFocus(false);
    });

    useEffect(() => {
        setShowAutocomplete(false);
        props.onSearchTextChange && props.onSearchTextChange(debounceSearchText);
    }, [debounceSearchText]);

    useEffect(() => {
        if (props.clearSearchText?.tags > 0) {
            setSearchText('');
        }
    }, [props.clearSearchText?.tags]);

    useEffect(() => {
        if (props.clearSearchText?.feed >= 0) {
            setSearchText('');
        }
    }, [props.clearSearchText?.feed]);

    useEffect(() => {
        if (props.clearSearchText?.open !== undefined) {
            setSearchText('');
        }
    }, [props.clearSearchText?.open]);

    useEffect(() => {
        if (!hasFocus) {
            setShowAutocomplete(false);
        } else {
            const onlyOne =
                people.length === 1 &&
                `${people[0].firstName} ${people[0].lastName}` === searchText;
            setShowAutocomplete(searchText.length && people.length && !onlyOne && !!people.length !== showAutocomplete);
            setTimeout(() => inputRef?.current?.focus(), 10);
        }
    }, [props.people]);

    const clear = () => {
        setSearchText('');
        // props.onSearchTextChange('');
        // props.onSearchSubmit({search: null});
        inputRef.current.focus();
    };

    const submitSearch = (s?: string): void => {
        if (!s && searchText === '') {
            props.onSearchSubmit({search: '', open: true});
        } else {
            props.onSearchSubmit({search: s || searchText});
        }
        setActiveIdx(-1);
        setShowAutocomplete(false);
    };

    const onRowSelect = (customer: Customer): void => {
        setSearchText(`${customer.firstName} ${customer.lastName}`);
        submitSearch(`${customer.firstName} ${customer.lastName}`);
        props.onCustomerSendMsg(FormViewType.SEND_MESSAGE, customer);
    };

    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if ([9, 13, 27, 38, 40].includes(e.keyCode)) {
            if (e.keyCode === 27) {
                setShowAutocomplete(false);
                return;
            }

            if (e.keyCode === 9 || e.keyCode === 13) {
                const selectedItemNumber = activeIdx;
                if (selectedItemNumber < 0 || selectedItemNumber > people.length) {
                    submitSearch();
                } else {
                    onRowSelect(people[selectedItemNumber]);
                }
                return;
            }

            if (!showAutocomplete && e.keyCode === 40) {
                setShowAutocomplete(true);
                return;
            }

            const cursor = e.keyCode === 40 ? 1 : -1;
            const totalItemsCount = people.length;
            let newVal = activeIdx + cursor;
            newVal = newVal < 0 ? totalItemsCount - 1 : newVal >= totalItemsCount ? 0 : newVal;
            setActiveIdx(newVal);
        }
    };

    // const searchTextChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    //     setSearchText(e.target.value);

    const buttons = debounceSearchText ? (
        <>
            <button
                className={classNames('clear-btn', { 'show': !hasFocus })}
                onClick={(): void => clear()}
            >
                <ClearIcon />
            </button>
            <button
                className={classNames('go-btn', { 'show': hasFocus })}
                onClick={(): void => submitSearch()}
            >
                Go
            </button>
        </>
    ) : null;

    const lookupPeople = !(showAutocomplete && people.length) ? null : (
        <div className="section-container">
            {people.map((lp) => {
                globalIdx++;
                return (
                    <div
                        key={uuidv4()}
                        className={classNames({
                            'lookup-row strong': true,
                            active: globalIdx === activeIdx,
                        })}
                        onClick={() => onRowSelect(lp)}
                    >
                        <div>
                            <ProfileIcon />
                            <span>{displayNameOrPhone(lp)}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    return (
        <div className="SearchInput" ref={wrapperRef}>
            <div className={classNames({ 'input-container': true, 'filled-out': searchText || hasFocus })}>
                <SearchIcon />
                <input
                    type="text"
                    placeholder="Search"
                    value={searchText}
                    ref={inputRef}
                    onFocus={() => setHasFocus(true)}
                    onKeyDown={(e) => keyDown(e)}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                {buttons}
            </div>
            {showAutocomplete ? <div className="lookup">{lookupPeople}</div> : null}
        </div>
    );
};
// TODO: Use when content search is added
// const markText = (content: string, searchArr: string[]): { __html: string } => {
// 	let returnTxt = content;
// 	searchArr.forEach(searchTxt => {
// 		const regExp = new RegExp(searchTxt, 'ig');
// 		returnTxt = returnTxt.replace(regExp, `<span>$&</span>`)
// 	});
// 	return { __html: returnTxt.replace(/ /g, '\u00a0') };
// };
