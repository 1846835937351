const trimDynamicSlugs = (url: string): string => {
  const urlParts = url.split('/');
  if (urlParts[urlParts.length - 1][0] === ':') {
      return trimDynamicSlugs(urlParts.slice(0, urlParts.length - 1).join('/'));
  } else {
      return url;
  }
};

export default trimDynamicSlugs;
