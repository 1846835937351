import api from '@src/api/api';
import {
    Attachment,
    CustomerBase,
    Message,
    MessagesFilter,
    MessagesSearch,
    MessagingConstants,
    ThreadSearchResult,
    ThreadSearchResultWithTotal,
} from '@src/stores/models';
import { AxiosResponse } from 'axios';
import { toJS } from 'mobx';

export class MessagesApi {
    static baseUrl = '/messaging';

    static assignUser(tid: string, userId: string): Promise<void> {
        const url = `${this.baseUrl}/${tid}/assign`;
        const params = {};
        params['threadId'] = tid;
        if (!!userId) params['assignee'] = userId;

        return api.post(url, params);
    }

    static appInvite(phone: string): Promise<void> {
        const url = `/appinvite?phone=${phone}`;
        return api.post(url);
    }

    static getMessagingInitCall(): Promise<MessagingConstants> {
        return api.get(this.baseUrl).then((res: AxiosResponse<MessagingConstants>) => res.data);
    }

    static getThread(tid: string, size?: number, page?: number): Promise<MessagesSearch> {
        const threadUrl = `${this.baseUrl}/${tid}/search`;
        const params = { size: 20, page: 1 };

        if (size) {
            params['size'] = size;
        }

        if (page) {
            params['page'] = page;
        }

        return api.get(threadUrl, { params }).then((res) => res.data);
    }

    static markAsViewed(tid: string, lastMessageId: string): Promise<void> {
        const url = `/message/${tid}/viewed`;
        return api.post(
            url,
            { lastMessageId },
            {
                headers: {
                    Accept: 'application/json',
                },
            }
        );
    }

    static sendMessage(
        tid: string,
        msg: string,
        attachments: Attachment[] = []
    ): Promise<{ message: Message }> {
        const url = `${this.baseUrl}/${tid}/reply`;
        return api
            .post(url, {
                message: msg,
                threadId: tid,
                attachments: attachments ? attachments : [],
            })
            .then((res) => res.data);
    }

    static addCustomer(customer) {
        return api.post('/customers/add', { ...customer });
    }

    static startNewThread(
        customer: CustomerBase,
        message: string,
        attachments: Attachment[] = [],
        isNewCustomer?: boolean
    ): Promise<ThreadSearchResult> {
        const url = `${this.baseUrl}/new`;
        let model: { [key: string]: any } = {
            message: message,
            attachments: attachments ? attachments : [],
            opened: true,
            read: true,
        };
        if (isNewCustomer) {
            model = { ...model, customer: customer };
        } else {
            model = { ...model, customerId: customer.id };
        }

        return api.post(url, model).then((res: AxiosResponse<ThreadSearchResult>) => res.data);
    }

    static searchThreads(
        filters?: MessagesFilter,
        page?: number,
        size?: number
    ): Promise<ThreadSearchResultWithTotal> {
        const params = {
            feed: filters?.feed,
            status: filters?.open,
            tags: toJS(filters?.tags),
            size: 20,
            page: 1,
        };
        const searchVal = filters?.search;
        if (searchVal) {
            params['search'] = searchVal;
            params.status = undefined;
        }
        if (size) {
            params['size'] = size;
        }
        if (page) {
            params['page'] = page;
        }
        const data = filters ? params : null;

        return api
            .get(`${this.baseUrl}/search`, { params: data })
            .then((res: AxiosResponse<ThreadSearchResultWithTotal>) => {
                const deduplicatedThreadsObj = res.data.threads.reduce((acc, thread) => {
                    return {
                        ...acc,
                        [thread.thread?.id || thread.customer.id]: thread as ThreadSearchResult,
                    };
                }, {} as Record<string, ThreadSearchResult>);
                const deduplicatedThreads = Object.values(deduplicatedThreadsObj);
                return {
                    total: res.data.total,
                    threads: deduplicatedThreads,
                };
            });
    }

    static toggleOpen(tid: string, open: boolean): Promise<boolean> {
        const url = `${this.baseUrl}/${tid}/close`;
        return api
            .post(url, { markAsClosed: open })
            .then((res: AxiosResponse<any[]>) => (res.data.length > 1 ? res.data[1] : false));
    }

    static async uploadAttachment(file: File) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return api.post('/message/upload/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    static getNotes(customerId: string): Promise<void> {
        return api.get(`/notes/${customerId}`);
    }

    static postNote(customerId: string, text: string): Promise<void> {
        return api.post(`/notes`, { customerId, text });
    }
}
