import api from '@src/api/api';

export const getBilling = () => {
    return api.get('/billing');
};

export const getActivePlans = () => {
    return api.get('/billing/activePlans');
};

export const manageSubscription = (action: string, subType: string, sid: string, stripeToken?: string) => {
    //action: "cancel" || "reactivate"
    //subType: "subscription" || "contract"
    const params = {stripeToken} || {};
    return api.get(`/billing/${subType}/${sid}/${action}`, { params });
};

export const updateBillingPayment = (stripeToken: string) => {
    return api.post('/billing/updatepayment', { stripeToken });
};

export const updateBillingEmail = (billingEmail: string) => {
    return api.post('/billing/updateemail', { billingEmail });
};

export const changeSubscription = (subscriptionId: string, priceId: string) => {
    return api.post(`/billing/subscription/change`, { subscriptionId, priceId });
};

export const cancelTrial = () => {
    return api.delete(`/billing/trial`);
};