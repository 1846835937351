import React from 'react';
import { BarChart, Bar, XAxis, ResponsiveContainer, YAxis, CartesianGrid } from 'recharts';
import './chart.sass';
import DashboardCard from '../DashboardCard/DashboardCard';
import { CHART_HEIGHT, FONT_COLOR, NAVY, ResponsesHistogramProps } from './common';

const segmentToTick = {
    excellent: '14 min',
    good: '1 hr',
    average: '4 hr',
    fair: '8 hr',
    poor: '1 day',
    veryPoor: '3 days',
};

export const ResponseHistogramChart: React.FC<ResponsesHistogramProps> = ({
    dataChart,
    margin,
    barSize,
    title,
    icon,
}) => {
    const dataResponseChart = Object.entries(dataChart).map((responseItem) => ({
        segment: segmentToTick[responseItem[0]],
        count: responseItem[1],
    }));

    return (
        <DashboardCard className="dashboard-chart">
            <div className="chart-title">
                <img src={icon} alt="chart icon" />
                <span>{title}</span>
            </div>
            <div className="dashboard-chart__container">
                <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                    <BarChart data={dataResponseChart} margin={margin}>
                        <XAxis
                            dataKey="segment"
                            interval={0}
                            tickMargin={4}
                            tick={{
                                fontSize: 10,
                                color: FONT_COLOR,
                            }}
                        />
                        <YAxis
                            domain={[
                                0,
                                (dataMax) =>
                                    dataMax < 5
                                        ? dataMax % 2
                                            ? dataMax + 1
                                            : dataMax
                                        : (dataMax + 2) % 2
                                        ? dataMax + 1
                                        : dataMax + 2,
                            ]}
                            axisLine={false}
                            tickLine={false}
                            orientation="right"
                            tick={{
                                fontSize: 10,
                                color: FONT_COLOR,
                            }}
                            allowDecimals={false}
                        />
                        <CartesianGrid />
                        <Bar
                            dataKey="count"
                            fill={NAVY}
                            barSize={barSize}
                            radius={[barSize / 2, barSize / 2, barSize / 2, barSize / 2]}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </DashboardCard>
    );
};
