import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { STORES, withStores, WithWalkthroughStoreProps } from '@src/stores/with-store';
import AuthStore from '@src/stores/auth.store';
import {
    Dialog,
    // Loader
} from '@src/components';
import { images } from './images/images';
import { firstPopupsStepsCompletedElBeforeBEData } from '@src/theme/utils/constants';
import { CompletedSteps } from './WalkthroughMeta';

import './walkthrough.sass';

const views = [
    {image: images.view1, title: 'Welcome to Myopolis 👋', desc: 'Use your Myopolis number to respond fast, get more responses, and improve your reviews... All in one place!'},
    {image: images.view2, title: 'Respond 👩‍💻 quickly to leads via text directly from the dashboard ', desc: 'Text a customer or a group of customers, all from one place'},
    {image: images.view3, title: 'Build online 👌 reviews authentically', desc: 'Increase your positive reviews from happy clients and get notified the moment they come in'},
    {image: images.view4, title: 'Keep track of 👁 communications', desc: 'One inbox where anyone can respond by text'},
    {image: images.view5, title: 'Send group  📩 texts to multiple customers at once', desc: 'Broadcast group messages to send to many customers at one time'}
];

const WalkthroughWithStore: React.FC<WithWalkthroughStoreProps> = (props: WithWalkthroughStoreProps) => {
    const WalkthroughStore = props.WalkthroughStore;
    const history = useHistory();
    const [displayingNowNumber, setDisplayingNowNumber] = useState<number>(0);
    const [stepsAreAlreadySet, setStepsAreAlreadySet] = useState(false);
    const lockedAccount = AuthStore.accountStatus === 'LOCKED';

    const setHintsToDisplay = () => {
        if (!WalkthroughStore.popupsStepsCompleted.includes(CompletedSteps.walkthroughOpen)) {
            WalkthroughStore.setShowWalkthrough(true);
        }
        setStepsAreAlreadySet(true);
    };

    const checkHintsToDisplay = () => {
        if (WalkthroughStore.popupsStepsCompleted[0] !== firstPopupsStepsCompletedElBeforeBEData) {
            setHintsToDisplay();
        } else {
            WalkthroughStore.getAndSetHintPopupSteps()
            .then(() => {
                setHintsToDisplay();
            });
        }
    };

    useEffect(() => {
        if (lockedAccount) {
            return;
        }
        if (
            history.location.pathname !== '/signupfull' &&
            history.location.pathname !== '/onboarding' &&
            history.location.pathname.search('/messages') === -1 &&
            history.location.pathname.search('/activate') === -1 &&
            history.location.pathname.search('/account/') === -1 &&
            history.location.pathname.search('/confirm') === -1 &&
            history.location.pathname.search('/user/password/reset') === -1 &&
            history.location.pathname.search('/forgot') === -1 &&
            history.location.pathname.search('/coupon') === -1 &&
            !stepsAreAlreadySet
        ) {
            checkHintsToDisplay();
        }
    }, [history.location.pathname]);

    // const preloadImage = (src) => {
    //     return new Promise(function(resolve) {
    //         const image = document.createElement('img');
    //         image.setAttribute('src', src);
    //         image.addEventListener('load', function() {
    //             resolve(image);
    //         });
    //     });
    // };

    // async function preloadImages() {
    //     setLoading(true);
    //     const loadedImages = await Promise.all(views.map((v) => preloadImage(v.image)))
    //     .finally(() => {
    //         setLoading(false);
    //     });
    //     loadedImages.forEach(el => document.body.appendChild(el as Node))
    // };

    useEffect(() => {
        if (lockedAccount) {
            return;
        }
        // preloadImages();
    }, []);

    const walkthroughCompleted = () => {
        setDisplayingNowNumber(0);
        WalkthroughStore.setShowWalkthrough(false);
        Promise.all([
            WalkthroughStore.markHintPopupStepAsRead(CompletedSteps.walkthroughOpen),
            WalkthroughStore.markHintPopupStepAsRead(CompletedSteps.walkthroughCompleted)
        ]).then(() => {
            WalkthroughStore.setPopupStepsCompleted([...WalkthroughStore.popupsStepsCompleted, CompletedSteps.walkthroughOpen, CompletedSteps.walkthroughCompleted]);
        });
    }

    const onDialogClose = () => {
        setDisplayingNowNumber(0);
        WalkthroughStore.setShowWalkthrough(false);
        WalkthroughStore.markHintPopupStepAsRead(CompletedSteps.walkthroughOpen).then(() => {
            WalkthroughStore.setPopupStepsCompleted([...WalkthroughStore.popupsStepsCompleted, CompletedSteps.walkthroughOpen]);
        });
    };

    const goBack = () => {
        setDisplayingNowNumber(displayingNowNumber - 1);
    };

    const goNext = () => {
        if (displayingNowNumber === 4) {
            walkthroughCompleted();
        } else {
            setDisplayingNowNumber(displayingNowNumber + 1);
        }
    };

    return (
        <Dialog
            key="1"
            className="Walkthrough"
            open={Boolean(!WalkthroughStore.hidePopups && WalkthroughStore.showWalkthrough)}
            onClose={() => onDialogClose()}
        >
            {/* <Loader loading={loading} /> */}
            <div className="Walkthrough__content">
                <div className="imagewrapper" id="imagewrapper">
                    {views.map((view, i) => {
                        return <Fragment key={view.image}>
                            <img
                                src={view.image}
                                alt={"walkthrough slide " + (i + 1)}
                                className={classNames([
                                    'image',
                                    "image"+i,
                                    displayingNowNumber == i && "shown" || ""
                                ])}
                            />
                        </Fragment>
                    })}
                </div>
                <div className="text">
                    <div className={classNames('title', { lastView: displayingNowNumber === 4})}>{views[displayingNowNumber].title}</div>
                    <div className={classNames('desc', { lastView: displayingNowNumber === 4})}>{views[displayingNowNumber].desc}</div>
                </div>
            </div>
            <div className="Walkthrough__actions">
                {displayingNowNumber !== 0 &&
                    <button className="btn-white" onClick={() => goBack()}>
                        Back
                    </button>
                }
                <button className="btn-primary-full" onClick={() => goNext()}>
                    {displayingNowNumber === 4 ? 'Done' : 'Next'}
                </button>
            </div>
            <div className="Walkthrough__counter">
                {[0, 1, 2, 3, 4].map(dotNumber => (
                    <div
                        key={dotNumber}
                        className={classNames('dot', { active: displayingNowNumber === dotNumber })}
                        onClick={() => setDisplayingNowNumber(dotNumber)}
                    />
                ))}
            </div>
        </Dialog>
    );
};

export const Walkthrough = withStores(WalkthroughWithStore, [STORES.Walkthrough]);
