import { Role, Status } from '@src/stores/models/employee';
import { Message } from '@src/stores/models/message';
import { Review } from '@src/stores/models/review';
import { Notifications } from '@src/stores/models/notifications';
import { Employee } from '@src/stores/models/employee';

export enum AccountStatus {
    ACTIVE = 'ACTIVE',
    INCOMPLETE = 'INCOMPLETE',
    LOCKED = 'LOCKED',
}

export interface User {
    accountStatus: AccountStatus;
    address: string;
    id: string;
    accountId: string;
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
    emailVerified?: boolean;
    status: Status;
    role: Role;
    phone: string;
    timeZone: string;
    messages: Message[];
    // messagesFilters?: MessagesFilters;
    reviews: Review[];
    notifications: Notifications;
    employee?: Employee;
    employees?: Employee[];
    featureFlags: string[];
    employeeFromExternalAuth?: boolean;
    isAllowedToUseBroadcast: boolean;
    addedCard: boolean;
    trialExpires: Date;
    locationId: string;
    newMessages: {
        customerThreads: string[],
        teamConversations: string[],
    }
}
