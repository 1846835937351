import React from 'react';

import './DashboardCard.sass';
interface DashboardCardProps {
    className?: string;
    onClick?: () => void;
};

const DashboardCard: React.FunctionComponent<DashboardCardProps> = ({children, className, onClick}) => (
    <div className={`dashboard-card ${!!className ? className : ''}`} onClick={onClick}>
        {children}
    </div>
);

export default DashboardCard;
