import React from 'react';

import './PageHeader.sass';
import { Loader } from '@src/components/Loader/Loader';
import HeaderTitle from './HeaderTitle';

interface PageMainHeader {
    isLoading?: boolean;
    title?: string;
};

const PageMainHeader: React.FunctionComponent<PageMainHeader> = ({ isLoading, title }) => {
    return (
        <div className="PageHeader__main">
            {isLoading && <div className="PageHeader__loader-container"><Loader loading={isLoading}/></div>}
            {title && <HeaderTitle title={title}/>}
        </div>
    );
};

export default PageMainHeader;
