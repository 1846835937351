import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { withNotificationsStore, WithNotificationsStoreProps } from '@src/stores/with-store';
import { Loader, Table, TableHeader } from '@src/components';
import { useLoading } from '@src/hooks';
import { NotificationType } from '@src/stores/models';

import './notifications.sass';

const dateCellTemplate = (field, data) => {
    const timestamp = data[field] / 1000;
    return moment.unix(timestamp).format('MM/DD/YYYY, HH:mm');
};

const readTemplate = (field, data) => {
    return data[field] ? 'Yes' : 'No';
};

const typeTemplate = (field, data) => {
    return NotificationType[data[field]];
};

const HEADERS: TableHeader[] = [
    { title: '', cls: ['title'], field: 'title', flexWidth: 5 },
    { title: 'Read', template: (header, data) => readTemplate('read', data), flexWidth: 2 },
    { title: 'Type', template: (header, data) => typeTemplate('type', data), flexWidth: 2 },
    {
        title: 'Created at (MM/DD/YYYY)',
        template: (header, data) => dateCellTemplate('created', data),
        flexWidth: 2,
    },
];

export const NotificationsWithStore: React.FC<WithNotificationsStoreProps> = (
    props: WithNotificationsStoreProps
) => {
    const { notifications, loadNotification, loadingState } = props.NotificationsStore;
    const loading = useLoading(loadingState);
    const [page, setPage] = useState(1);
    const pageSize = 8;

    const loadNotif = () => {
        loadNotification(page, pageSize);
    };

    useEffect(() => {
        loadNotif();
    }, [page]);

    return (
        <div className="Notifications card">
            <Loader loadingState={loading} />
            <div className="card-title">Notifications</div>
            <div className="Notifications__content">
                <Table
                    headers={HEADERS}
                    data={notifications}
                    totalCount={50} //TODO
                    currentPage={page}
                    onPageChange={(page) => setPage(page)}
                />
            </div>
        </div>
    );
};

export const Notifications = withNotificationsStore(NotificationsWithStore);
