import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { IObservableValue, ObservableMap } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { Dialog, FilterBarSection } from '@src/components';
import { withReviewsStore, WithReviewsStoreProps } from '@src/stores/with-store';
import {
    ReviewDate,
    ReviewRating,
    ReviewRecommendation,
    ReviewSource,
    ReviewSourceValue,
    ReviewStatus,
} from '@src/stores/models';
import { images } from '@src/theme/images';
import { DateRange as ReactDateRange } from 'react-date-range';
import { dateRangeFormat } from '@src/theme/utils/constants';
import { ReactComponent as Tag } from '@src/theme/icons/tag-outlined-grey.svg';
import { ReactComponent as Stars1 } from '@src/theme/icons/stars-1.svg';
import { ReactComponent as Stars2 } from '@src/theme/icons/stars-2.svg';
import { ReactComponent as Stars3 } from '@src/theme/icons/stars-3.svg';
import { ReactComponent as Stars4 } from '@src/theme/icons/stars-4.svg';
import { ReactComponent as Stars5 } from '@src/theme/icons/stars-5.svg';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const RATING_RECOMENDATIONS = {
    [ReviewRecommendation.RECOMMENDS]: 'Recommended',
    [ReviewRecommendation.NOT_RECOMMENDS]: 'Not Recommended',
};

const RATINGS_FILTERS = {
    [ReviewRating.ONE]: Stars1,
    [ReviewRating.TWO]: Stars2,
    [ReviewRating.THREE]: Stars3,
    [ReviewRating.FOUR]: Stars4,
    [ReviewRating.FIVE]: Stars5,
};

const RATING_STATUS: FilterBarSection = {
    [ReviewStatus.ALL]: 'All',
    [ReviewStatus.ATTENTION]: 'Attention',
    [ReviewStatus.UNANSWERED]: 'Unanswered',
};

const DATE_FILTERS: FilterBarSection = {
    [ReviewDate.TODAY]: 'Today',
    [ReviewDate.THIS_WEEK]: 'This week',
    [ReviewDate.THIS_MONTH]: 'This month',
    [ReviewDate.THIS_YEAR]: 'This year',
    [ReviewDate.CUSTOM]: 'Custom',
};

export interface DateRange {
    startDate: Date;
    endDate?: Date;
    key: string;
}

export function hasSelection<T extends { selection?: unknown }>(obj): obj is T {
    return typeof obj.selection === "object";
}

const initialDateRangeState: DateRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
};

const ReviewsFiltersWithStore: React.FC<WithReviewsStoreProps> = (props: WithReviewsStoreProps) => {
    const store = props.ReviewsStore;
    const { statuses, recommendation, sources, rating, date } = store.filters;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dateRange, setDateRangeState] = useState<DateRange>(initialDateRangeState);
    const [counts, setCounts] = useState<{ [key: string]: number }>({});
    const [sourceFilters, setSourceFilters] = useState<FilterBarSection>({});
    const getFilterCls = (selected: boolean): string =>
        classNames({ 'reviewFilterItem': true, selected: !!selected });
    const getStarFilterCls = (selected: boolean): string =>
        classNames({ stars: true, selected: !!selected });

    useEffect(() => {
        const stats = store.generalData;
        if (stats) {
            const newCounts = {
                [ReviewStatus.ATTENTION]: stats.attention || 0,
                [ReviewStatus.UNANSWERED]: stats.unanswered || 0,
            };
            const newSourceFilters = {};
            stats.sources?.forEach((item) => {
                newSourceFilters[ReviewSource.values[item.source]] = `${ReviewSource[item.source]
                    .charAt(0)
                    .toUpperCase()}${ReviewSource[item.source].toLowerCase().slice(1)}`;
                newCounts[ReviewSource.values[item.source]] = item.totalReviews || 0;
            });
            setCounts(newCounts);
            setSourceFilters(newSourceFilters);
        }
    }, [store.generalData]);

    const renderSectionItems = (
        filterMap: FilterBarSection,
        source: ObservableMap<string, boolean> | IObservableValue<any>,
        setAction: (key: string) => void,
        Icon?,
        single = false
    ) => {
        return Object.entries(filterMap).map(([key, label]) => {
            const filterCount = counts[key];
            const cls = getFilterCls(single ? (source as IObservableValue<any>).get() === key : source.get(key));
            return (
                <div key={uuidv4()} className={cls} onClick={() => setAction(key)}>
                    {Icon ? <Icon /> : null}
                    <p>{label}</p>
                    {!!filterCount && <div className="pill white_background">{filterCount}</div>}
                </div>
            );
        });
    };

    const acceptCustomRange = () => {
        setDialogOpen(false);
        store.filters.customDate.set([dateRange.startDate, dateRange.endDate]);
        store.setReviewDate(ReviewDate.CUSTOM);
    };

    const dateSelectAction = (dateType: ReviewDate) => {
        if (dateType !== ReviewDate.CUSTOM) {
            store.setReviewDate(dateType);
            return;
        }

        setDialogOpen(true);
    };

    const onCustomDateClose = () => {
        setDialogOpen(false);
        setDateRangeState(initialDateRangeState);
        store.setReviewDate(null);
    };

    return (
        <section className="LeftMenu">
            <div className="LeftMenu__division">
                <div className="LeftMenu__sections">
                    <div className="LeftMenu__section type">
                        {renderSectionItems(
                            RATING_STATUS,
                            statuses,
                            (key) => store.setReviewStatus(key as ReviewStatus),
                            null,
                            true
                        )}
                    </div>
                </div>
            </div>
            <div className="LeftMenu__division">
                <div className="LeftMenu__sections">
                    <div className="LeftMenu__section">
                        <div className="LeftMenu__sectionTitle">
                            <div className="title">Source</div>
                            <div className="actions">
                                <Link to={'/settings/integrations'}>
                                    <button>
                                        <img src={images.addCircle} alt="add-icon" />
                                    </button>
                                </Link>
                            </div>
                        </div>
                        {renderSectionItems(
                            sourceFilters,
                            sources,
                            (key) => store.setReviewSource(key as ReviewSourceValue),
                            Tag
                        )}
                    </div>
                </div>
            </div>
            {(!!sourceFilters[ReviewSourceValue.GOOGLE] || !!sourceFilters[ReviewSourceValue.FACEBOOK]) && (
                <div className="LeftMenu__division">
                    <div className="LeftMenu__sections">
                        {!!sourceFilters[ReviewSourceValue.FACEBOOK] && (
                            <div className="LeftMenu__section">
                                <div className="LeftMenu__sectionTitle">
                                    <div className="title">By Recommendation</div>
                                </div>
                                {renderSectionItems(
                                    RATING_RECOMENDATIONS,
                                    recommendation,
                                    (key) => store.setReviewRecommendation(key as ReviewRecommendation),
                                    null,
                                    true
                                )}
                            </div>
                        )}
                        {!!sourceFilters[ReviewSourceValue.GOOGLE] && (
                            <div className="LeftMenu__section">
                                <div className="LeftMenu__sectionTitle">
                                    <div className="title">By Rating</div>
                                </div>
                                <div className="list-column">
                                    {Object.entries(RATINGS_FILTERS).map(([key, Svg]) => {
                                        const ratingKey = +key as ReviewRating;
                                        return (
                                            <Svg
                                                key={key}
                                                className={getStarFilterCls(ratingKey === rating.get())}
                                                onClick={() => store.setReviewRating(ratingKey)}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className="LeftMenu__division">
                <div className="LeftMenu__sections">
                    <div className="LeftMenu__section">
                        <div className="LeftMenu__sectionTitle">
                            <div className="title">By Date</div>
                        </div>
                        {renderSectionItems(
                            DATE_FILTERS,
                            date,
                            (key) => dateSelectAction(key as ReviewDate),
                            null,
                            true
                        )}
                    </div>
                </div>
            </div>
            <Dialog
                open={dialogOpen}
                title="Select custom date"
                onClose={() => onCustomDateClose()}
            >
                <div className="date-range-select">
                    <ReactDateRange
                        editableDateInputs={true}
                        onChange={(item )=> {if (hasSelection(item)) { setDateRangeState(item.selection as DateRange) }}}
                        moveRangeOnFirstSelection={false}
                        dateDisplayFormat={dateRangeFormat}
                        ranges={[dateRange]}
                    />
                    <button className="btn-primary" onClick={() => acceptCustomRange()}>
                        Accept
                    </button>
                </div>
            </Dialog>
        </section>
    );
};

export const ReviewsFilters = withReviewsStore(ReviewsFiltersWithStore);
