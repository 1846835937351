import React, { useEffect, useRef, useState } from 'react';
import { images } from '@src/theme/images';
import { ReactComponent as CloneIcon } from '@src/theme/icons/clone.svg';
import Pill from '@src/components/Pill/Pill';
import { transformPhoneNumber } from '@src/theme/utils/helpers';
import { useHistory } from 'react-router-dom';

import './Profile.sass';

interface ProfilePhoneProps {
    loading: boolean;
    phone?: string;
};

const ProfilePhone: React.FunctionComponent<ProfilePhoneProps> = ({ loading, phone }) => {
    const [showPill, setShowPill] = useState(false);
    const copyRef = useRef(null);
    const history = useHistory();

    const handleCopy = () => {
        copyRef.current.select();
        document.execCommand('copy');
        setShowPill(true);
    };

    useEffect(() => {
        if (showPill) {
            const timeout = setTimeout(() => {
                setShowPill(false);
            }, 1500);
            
            return () => clearTimeout(timeout);
        }
    }, [showPill]);

    const renderPhoneNr = <>
        <div className="Profile__phone-nr-container">
            <Pill show={showPill} text="Copied!"/>
            <h4 className="Profile__phone-nr">{transformPhoneNumber(phone)}</h4>
            <button type="button" disabled={phone === ''} onClick={handleCopy}>
                <CloneIcon/>
            </button>
        </div>
    </>;

    const renderNoPhoneNr = <div className="Profile__no-phone-nr-container">
        <button
            className="btn-primary-full"
            type="button"
            onClick={() => history.push('/settings/phone-number')}
        >
            Claim Phone #
        </button>
    </div>;

    const renderContent = loading ? <div className="Profile__phone-loading"/> : phone ? renderPhoneNr : renderNoPhoneNr;

    return (
        <div className="Profile__phone">
            <div className="Profile__phone-header">
                <img src={images.phoneIcon2} alt="phone"/>
                <h3>Your Number</h3>
            </div>
            {renderContent}
            <input value={phone || ''} readOnly ref={copyRef}/>
        </div>
    )
};

export default ProfilePhone;
