import {STORES, WithAuthStoreProps, WithSignupStoreProps, withStores, WithUserStoreProps} from '@src/stores/with-store';
import React, { useEffect, useState } from 'react';
import { images } from '@src/theme/images';
import { AsYouType } from 'libphonenumber-js';
import PhoneDialog from './PhoneDialog';
import { trackGoogleEvent, SignupAnalyticsEvents, setupLinkedinPixel, trackFacebookStandardEvent } from '@src/theme/utils/Analytics';
import { toast } from 'react-toastify';
import './PhoneSelection.sass';
import { Loader } from '@src/components/Loader/Loader';
import {useHistory, useLocation} from "react-router-dom";
import { ERROR_TOAST_AUTOCLOSE, gtagConversionSendTo } from '@src/theme/utils/constants';

type LocationState = {
    priceId: string,
    priceAmount: number,
    twilioPhone: string
};

type PhoneSelectionWithStoreProps = WithSignupStoreProps & WithAuthStoreProps & WithUserStoreProps;

export const PhoneSelectionWithStore: React.FunctionComponent<PhoneSelectionWithStoreProps> = (
    {
        SignupStore,
        AuthStore,
        UserStore
    }): JSX.Element | null => {
    const [ selectedPhone, setSelectedPhone ] = useState<string>('');
    const [ showSelectPhoneDialog, setShowSelectPhoneDialog ] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    const priceId: string = location.state ? (location.state as {priceId: string}).priceId : undefined
    const priceAmount: number = location.state ? (location.state as {priceAmount: number}).priceAmount : undefined
    const requireCC: boolean = location.state ? (location.state as {requireCC: boolean}).requireCC : undefined

    const getNumbers = () => {
        SignupStore.getNumbers({areaCode: null, regionCode: null})
        .then(() => {
            if (SignupStore.numbersToSelect.length !== 0) {
                setSelectedPhone(SignupStore.numbersToSelect[0]);
            }
        });
    };

    useEffect(() => {
        getNumbers();
    }, []);

    const handleNewNumber = (phoneNumber: string): void => {
        setShowSelectPhoneDialog(false);
        setSelectedPhone(phoneNumber);
    };

    const handleFinishClick = () => {
        if (requireCC) {
            SignupStore.setPhoneNumber(selectedPhone)
            .then((val) => {
                if (val && location.state) {
                    trackGoogleEvent(SignupAnalyticsEvents.NumberSelected);
                    history.push('/signup/cc', {
                        priceId: (location.state as LocationState).priceId,
                        twilioPhone: selectedPhone
                    });
                };
            });
        } else {
            SignupStore.setPhoneNumber(selectedPhone)
                .then((val) => {
                    if (val) {
                        trackGoogleEvent(SignupAnalyticsEvents.NumberSelected);
                        SignupStore.setBillingForm({priceId}, {})
                            .then((val) => {
                                if (val) {
                                    AuthStore.loadAuthLogin().then(() => {
                                        history.push('/dashboard');
                                        UserStore.setSignupUser(null);
                                    });
                                    trackGoogleEvent(SignupAnalyticsEvents.SignedUpNoCC, gtagConversionSendTo);
                                    trackFacebookStandardEvent('StartTrial', {value: priceAmount, currency: 'USD', predicted_ltv: priceAmount});
                                    setupLinkedinPixel();
                                }
                            })
                            .catch(() => {
                                getNumbers();
                                toast.error('Something went wrong. Please try again.', ERROR_TOAST_AUTOCLOSE);
                            });
                    }
                });
        }
    };

    if (!priceId) {
        history.push('/signup');
        toast.error('Something went wrong, please try again', ERROR_TOAST_AUTOCLOSE);
    };

    return (
        <div className="Signup__main PhoneSelection">
            <h2 className="Signup__subtitle">Your own new number is...</h2>
            <div className="PhoneSelection__selectedPhoneWrapper">
                <Loader loadingState={SignupStore.loadingState} />
                { SignupStore.numbersToSelect.length ?
                    <div className="PhoneSelection__selectedPhone">
                        <img src={images.phone2} alt="phone" />
                        {new AsYouType('US').input(('' + selectedPhone).replace(/\D/g, ''))}
                    </div>
                    : null
                }
            </div>
            <p className="Signup__main-comment">This lets you send & receive business messages without compromising your personal number. Cool, huh?</p>
            <button
                className="btn-tetriary-blue PhoneSelection__changeNumber"
                onClick={() => setShowSelectPhoneDialog(true)}
            >
                I want to change the number
            </button>
            <button
                className="btn-primary-full btn-next-shortSignup"
                disabled={!selectedPhone}
                onClick={handleFinishClick}
            >
                {requireCC ? 'Next' : 'Finish'}
            </button>
            {showSelectPhoneDialog &&
                <PhoneDialog
                    onClose={() => setShowSelectPhoneDialog(false)}
                    onNumberSelect={handleNewNumber}
                />
            }
        </div>
    );
};

export const PhoneSelection = withStores(PhoneSelectionWithStore, [STORES.User, STORES.Signup, STORES.Auth]);
