import Countdown from '@src/components/Countdown/Countdown';
import couponStore, { Coupon, CouponOffer } from '@src/stores/coupon.store';
import { images } from '@src/theme/images';
import React from 'react';
import { useHistory } from "react-router-dom";
import { isCouponOfferClaimed, isCouponOfferExpired } from '../PaymentInsideStripe';
import classNames from 'classnames';
import { SingleBillingPlan } from '@src/stores/models';
import { displayIntegerOrTwoDecimalPlaces } from '@src/theme/utils/helpers';

import '../payment-inside-stripe.sass';

export const getCouponDurationText = (coupon: Coupon, period: string): string => {
    if (coupon.duration === 'forever')
        return `${period === 'year' ? 'annually' : 'monthly'}`;
    if (coupon.duration === 'once')
        return `for 1 ${period}`;
    if (coupon.duration === 'repeating' && coupon.months > 0)
        return `for ${coupon.months} month${coupon.months > 1 ? 's' : ''}`;
    return '';
};

export const getUndiscountedAmount = (selectedPlan: SingleBillingPlan, coupon?: Coupon) => {
    if (!(selectedPlan && selectedPlan.price.interval && coupon)) {
        return 0;
    };
    const months = selectedPlan?.price.interval?.interval === 'year' && coupon.months ? 12 : 1;
    return selectedPlan.price.amount / months;
};

export const getDiscountedAmount = (plan: SingleBillingPlan, coupon?: Coupon) => {
    if (coupon && coupon.amountOff) {
        return (plan.price.amount - coupon.amountOff / 100);
    } else if (coupon) {
        return (getUndiscountedAmount(plan, coupon) * (1 - coupon.percentOff / 100));
    } else {
        return 0;
    };
};

interface CouponHeaderProps {
    offer?: CouponOffer;
    selectedPlan?: SingleBillingPlan;
};

const CouponHeader: React.FunctionComponent<CouponHeaderProps> = ({ offer, selectedPlan }) => {
    const history = useHistory();
    const undiscountedAmount = displayIntegerOrTwoDecimalPlaces(getUndiscountedAmount(selectedPlan, offer.coupon));

    const newPrice = displayIntegerOrTwoDecimalPlaces(getDiscountedAmount(selectedPlan, offer.coupon));

    const handleGoToSettings = () => {
        history.push('/settings/billing');
    };

    const displayCountdown = !(isCouponOfferExpired(offer) || isCouponOfferClaimed(offer)) && offer.redeemBefore;

    const discountPeriod = getCouponDurationText(offer.coupon, selectedPlan?.price.interval?.interval).toUpperCase();

    const renderCountdown = displayCountdown &&
        <div className="PaymentInsideStripe__countdown-container">
            <Countdown expires={offer.redeemBefore}/>
        </div>;

    const renderExpired = isCouponOfferExpired(offer) &&
        <div className="PaymentInsideStripe__stamp">
            <h2>Coupon expired</h2>
        </div>;

    const renderClaimed = isCouponOfferClaimed(offer) &&
        <div className="PaymentInsideStripe__stamp">
            <h2>Coupon already claimed</h2>
        </div>;

    const renderButtonLink = (isCouponOfferExpired(offer) || isCouponOfferClaimed(offer)) &&
        <div className="PaymentInsideStripe__form-actions oneElement">
            <button className="btn-primary-full" onClick={handleGoToSettings}>Go to settings</button>
        </div>;

    const renderStamp = renderClaimed || renderExpired;

    const renderSubtitle = <p>We love having you as a customer. Let's keep it going. Claim your coupon to keep responding faster, grow more positive reviews, and take your business in your pocket. Cancel any time.</p>
    const renderDiscount = !!offer.coupon.percentOff ? offer.coupon.percentOff + '%' : '$' + offer.coupon.amountOff / 100;

    return (
        <>
            <h2>Redeem your offer</h2>
            {renderCountdown}
            {renderSubtitle}
            <div className="PaymentInsideStripe__header-content">
                <div className="PaymentInsideStripe__coupon-left">
                    <div className={classNames('PaymentInsideStripe__planDescription', { noCountdown: !displayCountdown })}>
                        <div className="PaymentInsideStripe__planDescription__image">
                            <img src={images.planHouseImg} alt="house" />
                        </div>
                        <div className="PaymentInsideStripe__planDescription__text">
                            <div className="PaymentInsideStripe__planDescription__text-yourPlan">YOUR PLAN</div>
                            <div className="PaymentInsideStripe__planDescription__text-planName">{selectedPlan.billingPlan.name}</div>
                            <div className="PaymentInsideStripe__planDescription__text-noFreeTrial">${displayIntegerOrTwoDecimalPlaces(selectedPlan?.price.amount)} per {selectedPlan?.price.interval?.interval}</div>
                        </div>
                    </div>
                </div>
                <div className="PaymentInsideStripe__coupon-container">
                    <div className="PaymentInsideStripe__coupon-discount">
                        <p className="PaymentInsideStripe__coupon-discount-amount">{renderDiscount} OFF</p>
                        <p className="PaymentInsideStripe__coupon-discount-old-price">${undiscountedAmount}</p>
                        <p className="PaymentInsideStripe__coupon-discount-new-price">${newPrice}</p>
                        <p className="PaymentInsideStripe__coupon-discount-duration">{discountPeriod}</p>
                    </div>
                </div>
                {renderStamp}
            </div>
            {renderButtonLink}
        </>
    )
};

export default CouponHeader;
