import React from 'react';
import './Message.sass';

interface MessageProps {
    message?: React.ReactNode;
    caption?: string;
};

const Message: React.FunctionComponent<MessageProps> = ({ message, caption }) => {
    return (
    <div className="_message-container">
        <div className='message'>
            {message ? <p>{message}</p> : <p>No messages</p> }
        </div>
        {caption &&
            <div className="message-caption">
                {caption}
            </div>
        }
    </div>
    );
};

export default Message;
