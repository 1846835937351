import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import {
    Employee,
    MessageWithAttachments,
    VoiceMail,
    Closings,
    Assignments,
    Notes,
    Receipts,
    PhoneCall,
    Attachment,
} from '@src/stores/models';
import { SingleMessage } from './SingleMessage';
import { Loader } from '@src/components';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import { formatDate, formatTime, isIE11 } from '@src/theme/utils/helpers';
import { WithThreadsStoreProps, STORES, withStores } from '@src/stores/with-store';
import CustomerStore from '@src/stores/customer.store';
import { ReactComponent as PhoneIcon } from '@src/theme/icons/phone-alt.svg';
import { AsYouType } from 'libphonenumber-js';

import { useDebounce } from '@src/hooks';

import './chat.sass';
import MessageAttachments from './MessageAttachments';

interface CustomerChatProps {
    employeesMap: { [key: string]: Employee };
    slim?: boolean;
    notesOnly?: boolean;
}
interface MessagesWithType extends MessageWithAttachments {
    type: string;
    dateToCompare: Date;
}
interface VoiceMailWithType extends VoiceMail {
    type: string;
    dateToCompare: Date;
}
interface ClosingsWithType extends Closings {
    type: string;
    dateToCompare: Date;
    open: boolean;
}
interface AssignmentsWithType extends Assignments {
    type: string;
    dateToCompare: Date;
}
interface NotesWithType extends Notes {
    type: string;
    dateToCompare: Date;
}

interface ReceiptsWithType extends Receipts {
    type: string;
    dateToCompare: Date;
}

interface PhoneCallWithType extends PhoneCall {
    type: string;
    dateToCompare: Date;
}

type props = CustomerChatProps & WithThreadsStoreProps;


const CustomerChatWithStore: React.FC<props> = (props) => {
    const threadsStore = props.ThreadsStore;
    const { currentThread } = threadsStore;
    const customer = props.notesOnly ? null : currentThread?.customer;
    const receipts = props.notesOnly ? null : currentThread?.receipts;
    const messages = props.notesOnly ? null : currentThread?.messages;
    const voiceMail = props.notesOnly ? null : currentThread?.voiceMail;
    const closings = props.notesOnly ? null : currentThread?.closings;
    const assignments = props.notesOnly ? null : currentThread?.assigned;
    const calls = props.notesOnly ? null : currentThread?.calls;
    const notes = currentThread?.notes || CustomerStore?.customerData?.customer.notes;

    const { employeesMap } = props;
    const conversationRef = useRef(null);
    const [heightToStay, setHeightToStay] = useState(null);

    const [initedAlready, setInitedAlready] = useState(false);
    const debouncedKeyChange = useDebounce(threadsStore.key, 20);

    const [firstUnviewedMessage, setFirstUnviewedMessage] = useState(null);

    useEffect(() => {
        if (conversationRef?.current && !heightToStay) {
            if (!initedAlready || isIE11) {
                conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
                if (!initedAlready) setInitedAlready(true);
            } else {
                conversationRef.current.scrollBy({ top: 10000, behavior: "smooth"});
            }
        }
    }, [debouncedKeyChange]);
    // }, [debouncedMessagesChange, voiceMail, notes, closings, assignments, receipts]);

    useEffect(() => {
        if (conversationRef?.current && !heightToStay) {
            conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
        };
    }, [currentThread?.id]);

    useEffect(() => {
        checkFirstUnviewedMessage(messagesSorted);
        if (heightToStay) {
            conversationRef.current.scrollTop = conversationRef.current.scrollHeight - heightToStay;
            setHeightToStay(null);
        }
    }, [threadsStore.pageMessages]);

    const checkFirstUnviewedMessage = (sortedByDate) => {
        const currentUserId = threadsStore.constants?.employee.id;
        const usersReceipt =
            receipts && Object.values(receipts)
            .map(receiptArr => (receiptArr as any).find(receipt => receipt.employeeId === currentUserId))
            .filter(el => el);

        const lastViewedByCurrentUser = usersReceipt && usersReceipt[0]?.time;

        if (!lastViewedByCurrentUser) {
            return;
        };

        const firstUnviewedMessage = sortedByDate.find(el => {
            return (
                el.type === 'message' &&
                el.dateToCompare > lastViewedByCurrentUser &&
                (!el.message.sentBy || el.message.sentBy !== currentUserId)
            )
        });

        if (firstUnviewedMessage) {
            setFirstUnviewedMessage(firstUnviewedMessage.message.id);
        };
    };

    const messagesSorted = useMemo(() => {
        if (Boolean(messages || voiceMail || notes || closings || assignments || receipts || calls)) {
            const messagesWithType =
                messages &&
                Object.values(messages).map((m) => {
                    return {
                        ...m,
                        type: 'message',
                        dateToCompare: m.message.created,
                    };
                });
            const voiceMailWithType =
                voiceMail &&
                Object.values(voiceMail).map((m) => {
                    return {
                        ...m,
                        type: 'voiceMail',
                        dateToCompare: m.created,
                    };
                });
            const notesWithType =
                notes &&
                Object.values(notes).map((m) => {
                    return {
                        ...m,
                        type: 'note',
                        dateToCompare: m.created,
                    };
                });
            const closingsWithType =
                closings &&
                Object.values(closings).map((m) => {
                    return {
                        ...m,
                        type: 'closing',
                        dateToCompare: m.created,
                        open: m.open,
                    };
                });
            const assignmentsWithType =
                assignments &&
                Object.values(assignments).map((m) => {
                    return {
                        ...m,
                        type: 'assignment',
                        dateToCompare: m.created,
                    };
                });
            const callsWithType =
                calls &&
                Object.values(calls).map((m) => {
                    return {
                        ...m,
                        type: 'call',
                        dateToCompare: m.finishedAt,
                    }
                });
            let aggregated = [];

            aggregated = []
                .concat(messagesWithType)
                .concat(voiceMailWithType)
                .concat(notesWithType)
                .concat(closingsWithType)
                .concat(assignmentsWithType)
                .concat(callsWithType)
                .filter((x) => x);

            const sortedByDate = aggregated?.length && aggregated.sort((event1, event2) => {
                return (
                    // event1.message.id.startsWith('fake') ? 1 :
                    new Date(event1.dateToCompare).getTime() -
                    new Date(event2.dateToCompare).getTime()
                );
            });

            // sortedByDate.forEach((el, i) => {
            //     if (el.message.id.startsWith('fake')) {
            //         sortedByDate.splice(i, 1);
            //         sortedByDate.push(el);
            //     }
            // });

            return sortedByDate;
        }
    }, [messages, voiceMail, notes, closings, assignments, receipts, calls, threadsStore.key]);

    if (!messages && !voiceMail && !notes && !closings && !assignments && !calls) {
        return (
            <div className="Chat no-messages">
                <h5>No messages</h5>
            </div>
        );
    }

    const renderAudio = (voiceMail: VoiceMailWithType) => {
        const updated = new Date(voiceMail.updated);
        return (
            <div key={voiceMail?.id} className="Chat__message notUser">
                <audio key='2' controls src={voiceMail.url}>
                    Your browser does not support the audio element.
                </audio>
                <div key='3' className="send-time">
                    <p>
                        {' '}
                        {formatTime(updated, 'hh:mm a')} {formatDate(updated)}
                    </p>
                </div>
            </div>
        );
    };

    const getColor = (code: number) => {
        if (code <= 140) return 'blue';
        if (code <= 150) return 'green';
        if (code <= 160) return 'red';
        if (code <= 170) return 'orange';
        if (code <= 180) return 'yellow';
        else return 'blue';
    };

    const getReceiptsToDisplay = (messageId: string) => {
        const currentUser = threadsStore.constants?.employee;
        return (
            receipts && Object.values(receipts)
            .map((rcpt) =>
                rcpt && Object.values(rcpt)
                .filter((obj, pos, arr) => {
                    return arr.map(mapObj => mapObj['employeeId']).indexOf(obj['employeeId']) === pos;
                })
                .map((r) => {
                    return (
                        employeesMap && {
                            ...employeesMap[r['employeeId']],
                            messageId: r['messageId'],
                        }
                    );
                })
                .map((em) => {
                    if (currentUser?.id !== em?.id && em.messageId === messageId) {
                        const firstNameEmployee = em?.firstName;
                        const lastNameEmployee = em?.lastName;
                        return {
                            employeeId: em?.id,
                            firstName: firstNameEmployee,
                            lastName: lastNameEmployee,
                            firstLetter: firstNameEmployee?.charAt(0),
                            color: getColor(
                                firstNameEmployee?.charCodeAt(0) +
                                    lastNameEmployee?.charCodeAt(0)
                            ),
                        };
                    }
                })
                .filter((el) => el)
            )
            .filter((el) => el.length)
        );
    };

    const renderMessage = (msg: MessagesWithType) => {
        const employee = employeesMap && employeesMap[msg.message.sentBy];
        const author = employee ? `${employee.firstName} ${employee.lastName}` : '';
        const receiptIdemployee = getReceiptsToDisplay(msg.message.id);

        return (
            <div key={msg.message?.id} className={classNames({ Chat__message: true, notUser: !employee })}>
                {msg.message.id === firstUnviewedMessage &&
                    <div className="firstViewedMessage">
                        <div className="firstViewedMessageLine" />
                        <div className="firstViewedMessageText">New</div>
                    </div>
                }
                {msg.attachments && <MessageAttachments msg={msg} slim={props.slim}/>}
                {msg.message.text.trim() !== '' && (
                    <SingleMessage textToSplit={msg.message.text} />
                )}
                <div key='5' className="send-time">
                    <p key='6'>
                        {(msg.message.unsuccessfulSending || msg.message.sendingStatus === 'undelivered' || msg.message.sendingStatus === 'failed') ?
                            <span className="sendingError">Sending error</span> :
                        msg.message.id.startsWith('fake') ?
                            'Sending...' :
                            `${author} ${formatTime(msg.message.updated, 'hh:mm a')} ${formatDate(msg.message.updated)}`
                        }
                    </p>
                    <p key='7' className="feedback">
                        {msg.message.feedback && '(feedback from review request)'}
                    </p>
                </div>
                <div className="Chat__message viewed">
                    {receipts && receipts[msg?.message?.id] && receiptIdemployee?.length
                        ? receiptIdemployee.map(
                              (employeeReceipt) =>
                                  employeeReceipt &&
                                  Object.values(employeeReceipt).map((e: any, index) => (
                                      <>
                                          <ReactTooltip />
                                          <div key='8' data-tip={e.firstName + ' ' + e.lastName}>
                                              <span
                                                  key={`${e.firstName} - ${index}`}
                                                  className={classNames('avatar', e?.color)}
                                              >
                                                  {e.firstLetter}
                                              </span>
                                          </div>
                                      </>
                                  ))
                          )
                        : null}
                </div>
            </div>
        );
    };

    const renderNote = (note: NotesWithType) => {
        const employee = employeesMap && employeesMap[note.createdBy];
        const author = employee ? `${employee.firstName} ${employee.lastName}` : '';
        const updated = new Date(note.updated);
        const created = new Date(note.created);

        return (
            <div key={uuidv4()} className="Chat__message note">
                <SingleMessage textToSplit={note.text} />
                <div key='10' className="send-time">
                    <p>
                        {author} {formatTime(created, 'hh:mm a')} {formatDate(created)}
                    </p>
                    {note.created !== note.updated && (
                        <p className="edited">(edited, last on {formatDate(updated)})</p>
                    )}
                </div>
            </div>
        );
    };

    const renderClosing = (closing: ClosingsWithType) => {
        return (
            <div key={uuidv4()} className="Chat__comment">
                <div key='11' className="text">
                    {closing.open ? 'Opened' : 'Closed'} on {formatDate(closing.created)}{' '}
                    {formatTime(closing.created, 'hh:mm a')}
                </div>
                <div className="line" />
            </div>
        );
    };

    const renderAssignment = (assignment: AssignmentsWithType) => {
        const employee = employeesMap && employeesMap[assignment.assigneeId];
        const assignee = employee ? `${employee.firstName} ${employee.lastName}` : '';
        return (
            <div key={uuidv4()} className="Chat__comment">
                <div key='12' className="text">
                    {assignee ? `${assignee} was assigned to the chat` : 'Chat was unassigned'}
                </div>
                <div className="line" />
            </div>
        );
    };

    const renderCall = (call: PhoneCallWithType) => {
        // Don't render these types of calls for now
        if(call.status == "AnsweredByConference" || call.status == "FailedOrCanceled") {
            return (<></>)
        }

        const finishedAt = new Date(call.finishedAt);
        const employee = employeesMap && employeesMap[call.employeeId];

        let callText = undefined;
        switch (call.status) {
            case "Completed":
                const minutes = Math.floor(call.durationInSeconds / 60);
                const seconds = call.durationInSeconds % 60;
                const minutesStr = minutes < 10 ? `0${minutes}` : minutes.toString();
                const secondsStr = seconds < 10 ? `0${seconds}` : seconds.toString();
                if (call.isOutgoing) {
                    callText =
                        <>
                            <div className="bold">{
                                customer?.firstName ? 'You called ' + customer.firstName :
                                customer?.phone ? 'You called ' + new AsYouType('US').input(customer.phone) :
                                null
                            }</div>
                            <div>{minutesStr}:{secondsStr}</div>
                        </>
                } else {
                    callText =
                        <>
                            <div className="bold">{
                                customer?.firstName ? customer.firstName + ' called you' :
                                customer?.phone ? new AsYouType('US').input(customer.phone) + ' called you' :
                                null
                            }</div>
                            <div>{minutesStr}:{secondsStr}</div>
                        </>
                }
                break;

            case "Busy":
                callText = <div className="bold">Line busy</div>
                break;

            case "NoAnswer": {
                if (call.isOutgoing) {
                    callText = <div className="bold">{
                        customer?.firstName ? customer.firstName + ' missed your call' :
                        customer?.phone ? new AsYouType('US').input(customer.phone) + ' missed your call' :
                        'No answer'
                    }</div>
                } else {
                    callText = <div className="bold">Missed call{
                        customer?.firstName ? ' from ' + customer.firstName :
                        customer?.phone ? ' from ' + new AsYouType('US').input(customer.phone) :
                        ''
                    }</div>
                }
                break;
            }
        }

        return (
            <div key={call.id} className={classNames('Chat__message', {notUser: !call.isOutgoing})}>
                <div className={classNames("call", {
                    user: call.isOutgoing,
                    notUser: !call.isOutgoing,
                    answered: call.status === "Completed",
                    unanswered: call.status !== "Completed"
                })}>
                    <div className={classNames("call-box", {
                        user: call.isOutgoing,
                        notUser: !call.isOutgoing,
                        unanswered: call.status !== "Completed"
                    })}>
                        <PhoneIcon className="call-icon"/>
                        <div className="call-text">{callText}</div>
                    </div>
                </div>
                <div key='13' className="send-time">
                    <p>
                        {employee?.firstName} {employee?.lastName} {formatTime(finishedAt, 'hh:mm a')}{' '}
                        {formatDate(finishedAt)}{' '}
                    </p>
                </div>
            </div>
        )
    }

    const renderEvent = (
        details:
            | MessagesWithType
            | VoiceMailWithType
            | AssignmentsWithType
            | NotesWithType
            | ClosingsWithType
            | PhoneCallWithType
    ) => {
        switch (details.type) {
            case 'message':
                return renderMessage(details as MessagesWithType);
            case 'voiceMail':
                return renderAudio(details as VoiceMailWithType);
            case 'note':
                return renderNote(details as NotesWithType);
            case 'closing':
                return renderClosing(details as ClosingsWithType);
            case 'assignment':
                return renderAssignment(details as AssignmentsWithType);
            case 'call':
                return renderCall(details as PhoneCallWithType);
            default:
                return;
        }
    };

    const cls = classNames({ Chat: true, slim: props.slim });

    const items = messagesSorted && messagesSorted.map((msgDetails) => renderEvent(msgDetails));

    const loaderThreshold = 100;

    const loadMoreFunc = () => {
        if (!heightToStay) {
            setHeightToStay(conversationRef.current.scrollHeight);
            if (!threadsStore.currentThread?.id) return;
            threadsStore.loadMorePages();
        }
    };

    return (
        <div className={cls} ref={conversationRef}>
            <Loader loadingState={threadsStore.threadLoading} />
            <InfiniteScroll
                pageStart={0}
                loadMore={loadMoreFunc}
                initialLoad={false}
                hasMore={threadsStore.loadMoreMessages}
                isReverse={true}
                adjustReverseScroll={true}
                loader={threadsStore.loadMoreMessages && items?.length > 10 &&
                    <div key={0}>Loading...</div>
                }
                useWindow={false}
                threshold={loaderThreshold}
            >
                {items || []}
                {!items && props.notesOnly && 'No notes. You can add one below.'}
            </InfiniteScroll>
        </div>
    );
};

export const CustomerChat = withStores(CustomerChatWithStore, [STORES.ThreadsStore]);
