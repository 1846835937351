import React from 'react';
import { ButtonType } from '../Button/Button';
import './IconButtonBar.sass';

export interface IconButton {
    disabled?: boolean;
    type?: ButtonType;
    icon: JSX.Element;
    onClick?: () => void;
};

interface IconButtonBarProps {
    className?: string;
    buttons: IconButton[];
};

const IconButtonBar: React.FunctionComponent<IconButtonBarProps> = ({ className = '', buttons }) => (
    <div
        className={`icon-button-bar ${className}`}
    >
        {buttons.map((button, i) => 
            <button 
                key={i} 
                disabled={button.disabled}
                onClick={button.onClick}
                type={button.type || "button"}
            >
                {button.icon}
            </button>
        )}
    </div>
);

export default IconButtonBar;
