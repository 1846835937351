import React from 'react';
import { images } from '@src/theme/images';
import { ReactComponent as InfoIcon } from '@src/theme/icons/info.svg';
import ReactTooltip from 'react-tooltip';
import { Profile } from '@src/stores/models/profile';
import { WalkthroughStore } from '@src/stores/walkthrough.store';

import './Profile.sass';

type ActionFunction = () => void;
type Action = string | ActionFunction;

export interface Task {
   name: string;
   completed: boolean;
   lockedForMembers?: boolean;
   action: string | Action;
};

export const getTasks = (profile: Profile, walkthroughStore: WalkthroughStore): Task[] => [
    {
        name: 'Select phone number',
        completed: profile.phoneNumber,
        lockedForMembers: true,
        action: '/settings/phone-number',
    },
    {
        name: 'Finish onboarding',
        completed: profile.onboarding,
        action: () => walkthroughStore.setShowWalkthrough(true),
    },
    {
        name: 'Link/setup review sites',
        completed: profile.reviewSites,
        action: '/reviews',
    },
    {
        name: 'Upload contacts',
        completed: profile.contacts,
        action: '/customers',
    },
    {
        name: 'Set your office hours',
        completed: profile.officeHours,
        lockedForMembers: true,
        action: '/settings/account',
    },
    {
        name: 'Set your call-forward numbers',
        completed: profile.callForward,
        lockedForMembers: true,
        action: '/settings/phone-number',
    },
    {
        name: 'Add team members',
        completed: profile.teamMembers,
        lockedForMembers: true,
        action: '/settings/team',
    },
    {
        name: 'Create template',
        completed: profile.template,
        lockedForMembers: true,
        action: '/settings/templates',
    },
];

interface ProfileTasksProps {
    tasks: Task[];
    userRole?: number;
    onGo: (action: string) => void;
}

const ProfileTasks: React.FunctionComponent<ProfileTasksProps> = ({tasks, userRole, onGo}) => {
    const tip = 'Ask your manager to complete the task';

    const renderCompleted = <span className="action-completed">Completed</span>;
    
    const renderButton = (action: Action) => <button
            className="action-incompleted"
            onClick={typeof action === 'string' ? () => onGo(action) : action}
        >
            GO
        </button>;
    
    const renderInfo = <>
        <ReactTooltip/>
        <InfoIcon data-tip={tip}/>
    </>;

    const renderAction = (task: Task) => <>
        {task.completed ? renderCompleted : (
            userRole === 2 && task.lockedForMembers ? renderInfo : renderButton(task.action)
        )}
    </>;

    return (
        <div className="Profile__tasks">
            {tasks.map((task) => (
                <div className="Profile__task" key={task.name}>
                    <div className="left">
                        <div className="completed">
                            <img
                                src={images[task.completed ? 'checkmarkSelected' : 'checkmarkUnselected']}
                                alt="checkmark"
                            />
                        </div>
                        <div className="name">{task.name}</div>
                    </div>
                    <div className="right">
                        <div className="action">
                            {renderAction(task)}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
};

export default ProfileTasks;
