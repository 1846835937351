import React, { useState, useEffect } from 'react';
import { FormField } from '@src/components/FormField/FormField';
import { Address } from '@src/stores/models';
import { CompanySize, Industry, industryInputs, UserRoleInCompany, userRoleInputs } from './CompanyInfoInputs';
import { SignupForm } from '@src/stores/models/signup';
import { SignupSelect } from '@src/components';
import SignupStore from '@src/stores/signup.store';
import { LoadingState } from '@src/stores/models';
import { trackGoogleEvent, SignupAnalyticsEvents } from '@src/theme/utils/Analytics';

import './Signup.sass';


export interface FormData {
    companyName: string;
    industry: Industry;
    role: UserRoleInCompany;
    companySize: CompanySize;
    address: Address;
};

interface CompanyFormProps {
    previousData: SignupForm;
    blockChangingEmail: boolean;
    onNext: (data: FormData) => void;
    onBack: (data: FormData) => void;
};

export const CompanyForm: React.FunctionComponent<CompanyFormProps> = ({ previousData, blockChangingEmail, onNext, onBack }) => {
    const [ typedSthInCompanyName, setTypedSthInCompanyName ] = useState(false);
    const [ setupDone, setSetupDone ] = useState(false);
    const [ placeData, setPlaceData ] = useState({
        companyName: '',
        address: null
    });
    const [ currentData, setCurrentData ] = useState({
        companyName: '',
        industry: null,
        role: null,
        companySize: null,
        address: null,
    });

    useEffect(() => {
        setCurrentData({
            ...currentData,
            ...placeData
        })
    }, [placeData])

    const convertedArray = (arr, em?) => {
        return Object.keys(arr).map((el) => {
            if (em) {
                const emoji = em.find(ii => ii.input === arr[el])?.emoji
                return {
                    label: arr[el],
                    value: arr[el],
                    // value: el,
                    emoji
                };
            } else {
                return {
                    label: arr[el],
                    value: arr[el]
                    // value: el
                };
            }
        })
    };
    const industry = convertedArray(Industry, industryInputs);
    const userRoleInCompany = convertedArray(UserRoleInCompany, userRoleInputs);
    const companySize = convertedArray(CompanySize);

    useEffect(() => {
        setCurrentData({
            companyName: previousData?.companyName || '',
            industry: previousData?.industry,
            role: previousData?.role,
            companySize: previousData?.companySize,
            address: previousData?.address
        });
        if (previousData?.companyName?.length) {
            setTypedSthInCompanyName(true);
        };
    }, [previousData]);

    const changeNameAndAddress = (address, companyName) => {
        setPlaceData({
            address,
            companyName
        });
    };

    const onPlaceSelected = (autocomplete, input) => {

        const place = autocomplete.getPlace();

        input.value = place.name;
        input.style.color = "initial";
        SignupStore.setLoadingState(LoadingState.Loaded);

        let street = "";
        let postalCode = "";
        let state = "";
        let country = "";
        let city = "";

        for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    street = `${component.long_name} `;
                    break;
                }

                case "route": {
                    street += component.short_name;
                    break;
                }

                case "sublocality_level_1": {
                    city = component.long_name;
                    break;
                }

                case "locality": {
                    city = component.long_name;
                    break;
                }

                case "administrative_area_level_1": {
                    state = component.short_name;
                    break;
                }

                case "postal_code": {
                    postalCode = component.long_name;
                    break;
                }

                case "country": {
                    country = component.short_name;
                    break;
                }
            }
        };

        changeNameAndAddress({
                street,
                streetTwo: '',
                city,
                state,
                postalCode: postalCode.split(' ').join(''),
                country,
            },
            place.name
        );
    };

    const manageAutocomplete = () => {
        const input = document.getElementById('companyName') as HTMLInputElement;
        const options = {
            fields: ["address_components", "name"],
            componentRestrictions: { country: ["us", "ca"] },
            strictBounds: false,
            types: ["establishment"],
        };

        const autocomplete = new google.maps.places.Autocomplete(input, options);

        autocomplete.addListener("place_changed", () => {
            onPlaceSelected(autocomplete, input);
        });
    };

    useEffect(() => {
        manageAutocomplete();
    }, []);

    useEffect(() => {
        const pacContainers = document.getElementsByClassName('pac-container');
        const lastPacContainer = pacContainers && pacContainers[pacContainers.length - 1];
        if (lastPacContainer && !setupDone && typedSthInCompanyName) {
            lastPacContainer.addEventListener('mousedown', () => {
                const input = document.getElementById('companyName') as HTMLInputElement;
                input.style.color = "transparent";
                SignupStore.setLoadingState(LoadingState.Loading);
            });
            setSetupDone(true);
        }
    }, [typedSthInCompanyName]);

    return (
        <div className="step-card">
            <h2>We wanna know about you</h2>
            <p className="subtitle AuthContainer__subtitle">Tell us a little about your business</p>
            <form className="signup-step-form">
                <div className="form-content">
                    <FormField label="Company name" htmlFor="companyName">
                        <input
                            data-test="name"
                            type="text"
                            name="companyName"
                            placeholder="Enter name"
                            className='form-input'
                            // value={currentData.companyName}
                            defaultValue={previousData?.companyName}
                            id="companyName"
                            onChange={(e) => {
                                setCurrentData({...currentData, companyName: e.target.value})
                                setTypedSthInCompanyName(true);
                            }}
                        />
                    </FormField>
                    <FormField label="Industry" htmlFor="industry">
                        <SignupSelect
                            data-test="industry"
                            options={industry}
                            selectedOptionValue={currentData?.industry || previousData?.industry}
                            placeholder="Select industry"
                            onChange={(e) => setCurrentData({...currentData, industry: e.value})}
                            classname="fullWidth"
                        />
                    </FormField>
                    <div className="form-row">
                        <FormField label="Your role" htmlFor="role">
                            <SignupSelect
                                data-test="role"
                                options={userRoleInCompany}
                                selectedOptionValue={currentData?.role || previousData?.role}
                                placeholder="Select role"
                                onChange={(e) => setCurrentData({...currentData, role: e.value})}
                                classname="onLeft"
                            />
                        </FormField>
                        <FormField label="Company size" htmlFor="companySize">
                            <SignupSelect
                                data-test="size"
                                options={companySize}
                                selectedOptionValue={currentData?.companySize || previousData?.companySize}
                                placeholder="Select"
                                onChange={(e) => setCurrentData({...currentData, companySize: e.value})}
                                classname="onRight"
                            />
                        </FormField>
                    </div>
                </div>
                <div className="form-footer">
                    <div>
                        {!blockChangingEmail &&
                            <button type="button" className="btn-tetriary-blue" onClick={() => onBack(currentData)}>Back</button>
                        }
                    </div>
                    <button
                        type="button"
                        disabled={!currentData.companyName || !currentData.industry || !currentData.role || !currentData.companySize}
                        className="btn-primary-full nextfromcompany"
                        key="nextfromcompany"
                        onClick={() => {
                            trackGoogleEvent(SignupAnalyticsEvents.CompanyInfo);
                            onNext(currentData)
                        }}
                    >
                        Continue
                    </button>
                </div>
            </form>
        </div>
    );
};
