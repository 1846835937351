import React, { PropsWithChildren, useState, useEffect } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowLeftIcon } from '@src/theme/icons/arrow-left.svg';

import './filter-bar.sass';

interface FilterBarProps {
    expanded?: boolean;
    expandedChange?: (expanded: boolean) => void;
    showingPopupHints?: boolean;
}

export interface FilterBarSection {
    [key: string]: string;
}

export const FilterBar: React.FC<PropsWithChildren<FilterBarProps>> = (props) => {
    const [expanded, setExpanded] = useState(props.expanded ? props.expanded : true);

    const toggleExpand = () => {
        const val = !expanded;
        props.expandedChange(val);
    };

    useEffect(() => {
        setExpanded(props.expanded);
    }, [props.expanded]);

    return (
        <div className="filterBarWrapper">
            <div className={classNames('filterBar', { expanded: expanded })}>
                <div className="filterBar-burger">
                    <button onClick={(): void => toggleExpand()}>
                        <div className="burger">
                            <div />
                            <ArrowLeftIcon />
                            <div />
                        </div>
                    </button>
                </div>
                <div className={classNames('filterBar-content', { showingPopupHints: props.showingPopupHints })}>{props.children}</div>
            </div>
        </div>
    );
};
