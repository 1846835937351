import React from 'react';
import { useHistory } from "react-router-dom";
import { BlockingPopup } from '@src/components/BlockingPopup/BlockingPopup';

import './Broadcasts.sass';


interface ConsentPopupProps {
    onGrantConsent: () => void;
}


export const ConsentPopup: React.FC<ConsentPopupProps> = (props) => {
    const history = useHistory();

    return (
        <BlockingPopup>
            <div className="header">Additional Terms and Conditions</div>
            <div className="consentCopy">You will be solely responsible for all use of these services in accordance with the Myopolis terms of service and any applicable law or regulation; including, you will not send communications to parties for which you do not have an established business relationship or prior express invitation or permission.</div>
            <div className="actions">
                <button
                    className="btn-secondary"
                    onClick={() => history.push(`/dashboard`)}
                >
                    Cancel
                </button>
                <button
                    className="btn-primary"
                    onClick={() => props.onGrantConsent()}
                >
                    I agree
                </button>
            </div>
        </BlockingPopup>
    );
};
