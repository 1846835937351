import React from 'react';
import {
    STORES,
    WithUserStoreProps,
    withStores,
} from '@src/stores/with-store';
import classNames from 'classnames';

import './BlockingPopup.sass';

interface BlockingPopupProps {
    className?: string;
    heading?: string;
}

const BlockingPopupWithStore: React.FunctionComponent<WithUserStoreProps & BlockingPopupProps> = ({ UserStore, children, className, heading }) => {
    return (
        <div className={classNames('BlockingPopup', { displayingBanner: UserStore.trialBanner.show })}>
            <div className={`BlockingPopup-inside-wrapper ${!!className ? className : ''}`}>
                {heading &&
                    <div className="header">
                        <h2>{heading}</h2>
                    </div>
                }
                {children}
            </div>
        </div>
    )
};

// export default BlockingPopup;
export const BlockingPopup = withStores(BlockingPopupWithStore, [
    STORES.User,
]);
