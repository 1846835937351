import React from 'react';
import { AsYouType } from 'libphonenumber-js';
import { images } from '@src/theme/images';
import './new-contact.sass';
import '../messages-common.sass';

interface MessageProps {
    userPhoneNumber: string;
    onSendFirstMessage: (toUsersPhone: boolean) => void;
};


export const SendFirstMessage: React.FC<MessageProps> = (props) => {

    return (
        <section className="NewContactSendFirstMessage">
            <div className="NewContactSendFirstMessage__image">
            <img src={images.sendfirstmsg} alt="" />
            </div>
            <div className="NewContactSendFirstMessage__title">
                <h2>Send your first message</h2>
            </div>
            <div className="NewContactSendFirstMessage__subtitle">
                Send a test message to yourself. You’ll see how easy it is to be able to respond to your customers right away.
            </div>
            <div className="NewContactSendFirstMessage__textProvidedNumber">
                <button
                    className="btn-primary-full"
                    onClick={() => props.onSendFirstMessage(true)}
                >
                    Text {new AsYouType('US').input(
                        props.userPhoneNumber.startsWith('1') ? props.userPhoneNumber.slice(1) :
                        props.userPhoneNumber.startsWith('+1') ? props.userPhoneNumber.slice(2) :
                        props.userPhoneNumber
                    )}
                </button>
            </div>
            <div className="NewContactSendFirstMessage__textOtherNumber">
                <button
                    className="btn-tetriary-blue"
                    onClick={() => props.onSendFirstMessage(false)}
                >
                    Send a message to a different number
                </button>
            </div>
        </section>
    );
};