import React from 'react';
import { Loader } from '@src/components';
import { images } from '@src/theme/images';

interface BasicProps {
    setView?: (view: string) => void;
    setSucceeded?: (success: string) => void;
}

interface SiteProps {
    siteId: string;
    site: number;
    siteName?: string;
}

interface LinksProps extends BasicProps {
    reviews?: boolean;
    linksReload?: () => void;
    addSite?(site: SiteProps): void;
    pages?: any;
    selectPage?: (pageOrLocation, type) => void;
    save?: () => void;
    loading?: string;
}

declare const window: any;

export const Google: React.FC<LinksProps> = (props) => {
    return (
        <div>
            <Loader loading={props.loading === 'loading'} />
            <h3>Link Google</h3>
            <p>
                Make sure you have access to the business Google page. If you do not have a google
                page for your business you will need to create one.
            </p>
            <div className="Onboarding__Buttons">
                <button className="btn-secondary" onClick={() => props.setView('links')}>
                    Back
                </button>
                <button className="btn-primary" onClick={() => props.save()}>
                    <img src={images.google} alt="google" />
                    Connect with Google
                </button>
            </div>
        </div>
    );
};
