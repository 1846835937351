import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Header } from '@src/components';
import { TwilioCall } from '../TwilioCall/TwilioCall';
import { BillingStore } from '@src/stores/billing.store';
import UserStore from '@src/stores/user.store';
import MessagesStore from '@src/stores/messages/messages.store';
import ConversationsStore from '@src/stores/teamchat/conversations.store';
import { AuthStore } from '@src/stores/auth.store';
import { STORES, withStores } from '@src/stores/with-store';
import { Walkthrough } from '@src/components/Layout/Walkthrough/Walkthrough';
import { Support } from '@src/components/Layout/Support/Support';
import { AsYouType } from 'libphonenumber-js';
import LockedAccountDialog from './LockedAccountDialog';

import './layout.sass';

interface LayoutProps {
    BillingStore?: BillingStore;
    AuthStore?: AuthStore;
}

type props = PropsWithChildren<{}> & LayoutProps;

const LayoutWithStore: React.FC<props> = (props) => {

    const problemCCExpiresSoon = props.BillingStore?.problemCCExpiresSoon;
    const problemCCExpired = props.BillingStore?.problemCCExpired;
    const lockedAccount = props.AuthStore.accountStatus === 'LOCKED';
    const problemStatus = props.BillingStore?.problemStatus;
    const history = useHistory();
    const [wasMessagesView, setWasMessagesView] = useState(false);
    if(!UserStore.user) return <>Updating Authentication...</> // THIS SHOULDN'T HAPPEN

    const closeAllConversations = () => {
        Promise.all([
            MessagesStore.ThreadsStore.closeCurrentThread(),
            ConversationsStore.SelectedChannelStore.closeConversation(),
            ConversationsStore.SelectedDirectConversationStore.closeConversation()
        ]);
    };

    useEffect(() => {
        if (!wasMessagesView && history.location.pathname.split('/')[1] === 'messages') {
            setWasMessagesView(true);
        } else if (wasMessagesView && history.location.pathname.split('/')[1] !== 'messages') {
            setWasMessagesView(false);
            closeAllConversations();
        };
    }, [history.location.pathname]);

    useEffect(() => {
        if (!lockedAccount && problemCCExpiresSoon)
            toast.error(
                <div className="problemToast">
                    Your billing credit card expires next month. Go to{' '}
                    <Link to={'/settings/billing'}>Billing Settings</Link> to update your credit
                    card information.
                </div>
            );
    }, [problemCCExpiresSoon?.exp_month]);

    const transformText = (text: string) => {
        return '\'' + text.split('_').join(' ').toLowerCase() + '\'';
    };

    useEffect(() => {
        if (!lockedAccount && problemCCExpired)
            toast.error(
                <div className="problemToast">
                    Your billing credit has expired. Go to{' '}
                    <Link to={'/settings/billing'}>Billing Settings</Link> to update your credit
                    card information.
                </div>
            );
    }, [problemCCExpired?.exp_month]);

    useEffect(() => {
        // if ((lockedAccount && !problemStatus) || (problemStatus && !lockedAccount)) {
        if (problemStatus && !lockedAccount) {
            setTimeout(() => {
                if (UserStore?.user?.employee?.role < 2) {
                    if (history.location.pathname === '/settings/billing') {
                        toast.error(
                            <div className="problemToast">
                                Your account status is {transformText(lockedAccount ? props.AuthStore.accountStatus : problemStatus)}. Please update your credit card information.
                            </div>
                        );
                    } else if (!history.location.pathname.startsWith('/coupon')) {
                        toast.error(
                            <div className="problemToast">
                                Your account status is {transformText(lockedAccount ? props.AuthStore.accountStatus : problemStatus)}. Please update your credit card information in <Link to={'/settings/billing'}>Billing Settings</Link>.
                            </div>
                        );
                    };
                } else {
                    toast.error(
                        <div className="problemToast">
                            Your account status is {transformText(lockedAccount ? props.AuthStore.accountStatus : problemStatus)}. Please reach out to your billing contact.
                        </div>
                    );
                };
            }, 100);
        };
    }, [lockedAccount, problemStatus]);

    const getAndSetBilling = () => {
        props.BillingStore?.getAndSetBilling();
    };

    useEffect(() => {
        if (UserStore?.user?.employee?.role === 2) {
            return;
        };
        if (history.location.pathname !== '/settings/billing') {
            getAndSetBilling();
        };
    }, []);

    const showNotification = (data) => {
        if (data.channel) {
            const channelName = data.channel.name;
            const msgAuthor = MessagesStore.ThreadsStore.constants.employees[ConversationsStore.incomingMessage.sentBy];
            const msgContent = ConversationsStore.incomingMessage.message.length > 90 ? `${ConversationsStore.incomingMessage.message.slice(0, 90)}...` : ConversationsStore.incomingMessage.message;
            const showN = () => {
                const notification = new Notification(`New message in ${channelName}!`, {
                    body: `${msgAuthor.firstName} ${msgAuthor.lastName} writes: ${msgContent}`
                });
                notification.onclick = () => {
                    Promise.all([
                        MessagesStore.ThreadsStore.closeCurrentThread(),
                        ConversationsStore.SelectedChannelStore.closeConversation(),
                        ConversationsStore.SelectedDirectConversationStore.closeConversation()
                    ])
                    .then(() => {
                        ConversationsStore.SelectedChannelStore.selectChannel(data.conversationId, channelName)
                        .then(() => history.replace({ pathname: `/messages/c/${data.conversationId}` }));
                    })
                };
            }
            if (Notification.permission === "granted") {
                showN();
            } else if (Notification.permission !== "denied") {
                Notification.requestPermission().then(permission => {
                    if (permission === "granted") showN();
                });
            }
            ConversationsStore.removeIncomingMessage();
        } else if (data.direct) {
            const msgAuthor = MessagesStore.ThreadsStore.constants.employees[ConversationsStore.incomingMessage.sentBy];
            const msgContent = ConversationsStore.incomingMessage.message.length > 120 ? `${ConversationsStore.incomingMessage.message.slice(0, 120)}...` : ConversationsStore.incomingMessage.message;
            const showN = () => {
                const notification = new Notification(`New message from ${msgAuthor.firstName} ${msgAuthor.lastName}!`, {
                    body: `${msgContent}`
                });
                notification.onclick = () => {
                    Promise.all([
                        MessagesStore.ThreadsStore.closeCurrentThread(),
                        ConversationsStore.SelectedChannelStore.closeConversation(),
                        ConversationsStore.SelectedDirectConversationStore.closeConversation()
                    ])
                    .then(() => {
                        ConversationsStore.SelectedDirectConversationStore.selectDirectConversation(data.conversationId)
                        .then(() => history.replace({ pathname: `/messages/d/${data.conversationId}` }));
                    })
                };
            }
            if (Notification.permission === "granted") {
                showN();
            } else if (Notification.permission !== "denied") {
                Notification.requestPermission().then(permission => {
                    if (permission === "granted") showN();
                });
            }
            ConversationsStore.removeIncomingMessage();
        } else if (data.customerMessage && MessagesStore.ThreadsStore.incomingMessage) {

            const msgAuthor = MessagesStore.ThreadsStore.incomingMessage.message.payload.customer;
            const msgContent = MessagesStore.ThreadsStore.incomingMessage.message.payload.text.length > 120 ? `${MessagesStore.ThreadsStore.incomingMessage.message.payload.text.slice(0, 120)}...` : MessagesStore.ThreadsStore.incomingMessage.message.payload.text;
            const showN = () => {
                const notification = new Notification(`New message from customer - ${msgAuthor.firstName || new AsYouType('US').input(('' + msgAuthor.phone).replace(/\D/g, ''))} ${msgAuthor.lastName || ''}!`, {
                    body: `${msgContent}`
                });
                notification.onclick = () => {
                    Promise.all([
                        MessagesStore.ThreadsStore.closeCurrentThread(),
                        ConversationsStore.SelectedChannelStore.closeConversation(),
                        ConversationsStore.SelectedDirectConversationStore.closeConversation()
                    ])
                    .then(() => {
                        MessagesStore.ThreadsStore.selectThreadByThreadId(data.conversationId)
                        .then(() => history.replace({ pathname: `/messages/t/${data.conversationId}` }));
                    })
                };
            }
            if (Notification.permission === "granted") {
                showN();
            } else if (Notification.permission !== "denied") {
                Notification.requestPermission().then(permission => {
                    if (permission === "granted") showN();
                });
            }
            MessagesStore.ThreadsStore.removeIncomingMessage();
        }
    }

    const showIfSettingsAllowToNotify = () => {
        if (!MessagesStore.ThreadsStore.constants.notificationSettings) return;
        const assignedTo = MessagesStore.ThreadsStore.incomingMessage?.message.thread.assigned;
        const conversationId = MessagesStore.ThreadsStore.incomingMessage?.message.threadId || MessagesStore.ThreadsStore.incomingMessage?.message.thread.id;
        if (
            (
                MessagesStore.ThreadsStore.constants.notificationSettings?.data?.message?.assignedMeDesktop &&
                assignedTo === UserStore?.user?.employee?.id
            ) || (
                MessagesStore.ThreadsStore.constants.notificationSettings?.data?.message?.assignedOthrDesktop &&
                assignedTo &&
                assignedTo !== UserStore?.user?.employee?.id
            ) || (
                MessagesStore.ThreadsStore.constants.notificationSettings?.data?.message?.unassignedDesktop &&
                !assignedTo
            )
        ) {
            showNotification({conversationId, customerMessage: MessagesStore.ThreadsStore.incomingMessage});
        }
    };

    const loadNotificationSettings = () => {
        MessagesStore.loadNotificationSettings(UserStore?.user?.employee?.id).then(() => {
            showIfSettingsAllowToNotify();
        })
    };

    useEffect(() => {
        const conversationId = MessagesStore.ThreadsStore.incomingMessage?.message?.payload?.threadId;
        if (MessagesStore.ThreadsStore.incomingMessage && history.location.pathname.split('/')[3] !== conversationId) {

            const notifications = MessagesStore.ThreadsStore.constants.notificationSettings;
            if (notifications) {
                showIfSettingsAllowToNotify();
            } else {
                loadNotificationSettings();
            }
        }
    }, [MessagesStore.ThreadsStore.incomingMessage]);

    const getAndSetConversations = (conversationId) => {
        ConversationsStore.ConversationsListStore.getAndSetConversations()
        .then(() => {
            const ch = ConversationsStore.ConversationsListStore.channelsList.find(el => el.conversationId === conversationId);
            const d = ConversationsStore.ConversationsListStore.directConversationsList.find(el => el.conversationId === conversationId);
            showNotification({conversationId, channel: ch, direct: d});
        })
    };

    useEffect(() => {
        const conversationId = ConversationsStore?.incomingMessage?.conversationId;
        if (ConversationsStore.incomingMessage && history.location.pathname.split('/')[3] !== conversationId) {
            const channel = ConversationsStore.ConversationsListStore.channelsList.find(el => el.conversationId === conversationId);
            const direct = ConversationsStore.ConversationsListStore.directConversationsList.find(el => el.conversationId === conversationId);
            if (channel || direct) {
                showNotification({conversationId, channel, direct});
            } else {
                getAndSetConversations(conversationId);
            }
        }
    }, [ConversationsStore.incomingMessage]);

    return (
        <React.Fragment>
            <Header />
            <TwilioCall />
            <main className="layout">
                {props.children}
                {UserStore.user &&
                    <>
                        <Walkthrough />
                        <Support />
                    </>
                }
                <LockedAccountDialog/>
            </main>
        </React.Fragment>
    );
};

const Layout = withStores(LayoutWithStore, [STORES.Billing, STORES.Auth]);

export default Layout;
