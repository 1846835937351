import { IObservableValue, ObservableMap } from 'mobx';

export enum ReviewStatus {
    ALL = 'ALL',
    ATTENTION = 'ATTENTION',
    UNANSWERED = 'UNANSWERED',
}

export enum ReviewRecommendation {
    RECOMMENDS = 'positive',
    NOT_RECOMMENDS = 'negative',
}

export enum ReviewRating {
    ONE = 1,
    TWO,
    THREE,
    FOUR,
    FIVE,
}

export enum ReviewDate {
    TODAY = 'TODAY',
    THIS_WEEK = 'THIS_WEEK',
    THIS_MONTH = 'THIS_MONTH',
    THIS_YEAR = 'THIS_YEAR',
    CUSTOM = 'CUSTOM',
    ALL = 'ALL',
}

export enum ReviewSource {
    GOOGLE = 'GOOGLE',
    FACEBOOK = 'FACEBOOK',
}

export enum ReviewSourceValue {
    GOOGLE = '0',
    FACEBOOK = '1',
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReviewSource {
    export const values: { [source: string]: ReviewSourceValue } = {
        [ReviewSource.GOOGLE]: ReviewSourceValue.GOOGLE,
        [ReviewSource.FACEBOOK]: ReviewSourceValue.FACEBOOK,
    };
}

export interface ReviewFilter {
    sources: ObservableMap<ReviewSourceValue, boolean>;
    statuses: IObservableValue<ReviewStatus>;
    rating: IObservableValue<ReviewRating>;
    recommendation: IObservableValue<ReviewRecommendation>;
    date: IObservableValue<ReviewDate>;
    customDate: IObservableValue<[Date, Date]>;
    search: IObservableValue<string>;
}
