import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { onSuccess } from '@src/theme/utils/constants';
import {
    WithReviewsStoreProps,
    WithReviewSitesStoreProps,
    WithCustomersStoreProps,
    STORES,
    withStores,
} from '@src/stores/with-store';
import ImportCustomersStore from '@src/stores/import-customers.store';
import { Import } from '@src/pages/Customers/components/Import/Import';
import { ReactComponent as CheckGrey } from '@src/theme/icons/check-grey.svg';
import { ReactComponent as Check } from '@src/theme/icons/check-green.svg';
import { ReactComponent as CheckBig } from '@src/theme/icons/check-green-big.svg';
import { Links, Other } from './Links';
import { Fb } from './Fb';
import { Google } from './Google';
import { CSV } from './CSV';
import { Loader } from '@src/components';

import './Onboarding.sass';

interface BasicProps {
    setView?: (view: string) => void;
    setSucceeded?: (success: string) => void;
    anySites?: boolean;
    anyCustomers?: boolean;
}

const Populate: React.FC<BasicProps> = (props) => {
    return (
        <div>
            <h3>Thank You for your payment!</h3>
            <p>
                Your account has been set up, we recommend the following to get the most of the
                Myopolis platform.
            </p>
            <div className="Onboarding__Cards">
                <div onClick={() => props.setView('links')} className="Onboarding__Card">
                    <div className="Onboarding__CardImage">
                        {props.anySites ? <Check className="svg" /> : <CheckGrey className="svg" />}
                    </div>
                    <span>
                        <h4 className="Onboarding__CardTitle">Link Social Accounts</h4>
                        <p className="Onboarding__CardDescription">
                            Populate your review to address bad reviews and thank your customers to
                            increase engagement.
                        </p>
                    </span>
                </div>
                <div onClick={() => props.setView('csv')} className="Onboarding__Card">
                    <div className="Onboarding__CardImage">
                        {props.anyCustomers ? (
                            <Check className="svg" />
                        ) : (
                            <CheckGrey className="svg" />
                        )}
                    </div>
                    <span>
                        <h4 className="Onboarding__CardTitle">Upload your Customer Base</h4>
                        <p className="Onboarding__CardDescription">
                            Populate your database with an existing database of your customers.
                        </p>
                    </span>
                </div>
            </div>
            <NavLink className="btn-primary" to="/dashboard">
                {props.anySites ? 'Go to My Dashboard' : 'Skip'}
            </NavLink>
        </div>
    );
};

const SUCCESS_TITLES = {
    fb: 'Facebook linked',
    google: 'Google linked',
    customers: 'Customers Uploaded',
};

interface SuccessProps {
    successType: string;
    setView: () => void;
}

const SuccessView: React.FC<SuccessProps> = (props) => {
    return (
        <div className="Onboarding__SuccessContainer">
            <h3>{SUCCESS_TITLES[props.successType]}</h3>
            <div className="Onboarding__SuccessContent">
                <CheckBig className="svg" />
                <p>
                    Your customers are now available to contact through Myopolis. You can review,
                    edit, or add more customers through the Customers tab.
                </p>
            </div>
            <button className="btn-secondary" onClick={() => props.setView()}>
                Back
            </button>
            <NavLink to="/dashboard" className="btn-primary">
                Go to My Dashboard
            </NavLink>
        </div>
    );
};

interface OnboardingProps {
    reviews?: boolean;
    linksReload?: () => void;
}

type props = OnboardingProps &
    WithReviewsStoreProps &
    WithReviewSitesStoreProps &
    WithCustomersStoreProps;

export const OnboardingWithStore: React.FC<props> = (props) => {
    const [view, setView] = useState(props.reviews ? 'links' : 'populate');
    const [succeeded, setSucceeded] = useState(null);
    const [pages, setPages] = useState(null);
    const reviewSites = props.ReviewSitesStore?.reviewSites;
    const loading = props.ReviewSitesStore.statusDialog;
    const [localLoading, setLocalLoading] = useState(false);
    const [pageConnected, setPageConnected] = useState(false);

    props.ReviewSitesStore.onError = (type: string) => {
        switch (type) {
            case 'siteAuth': {
                toast.error(
                    'Something went wrong. Please check your data (for example you may have not provided access to your business page) and save once again.'
                );
                break;
            }
            default: {
                return;
            }
        }
    };
    props.ReviewSitesStore.onSuccess = () => onSuccess();

    useEffect(() => {
        setPages(props.ReviewSitesStore.pages);
    }, [props.ReviewSitesStore.pages]);

    const initSettings = () => {
        props.ReviewSitesStore.init();
        props.CustomersStore.searchCustomers();
    }

    useEffect(() => {
        initSettings();
    }, []);

    useEffect(() => {
        if (view === 'populate') {
            initSettings();
        }
    }, [view]);

    const addSite = (data) => {
        props.ReviewsStore.addSite(data);
    };

    const selectPage = (pageOrLocation, type) => {
        setLocalLoading(true)
        if (type === 'FACEBOOK') {
            props.ReviewSitesStore?._fbConnect(pageOrLocation)
            .then(() => {
                setLocalLoading(false);
            });
        } else {
            props.ReviewSitesStore?._googleConnect(pageOrLocation)
            .then(() => {
                setLocalLoading(false);
            });
        };
    };

    useEffect(() => {
        if (view === 'fb' && reviewSites.findIndex((el) => el.site.name === 'FACEBOOK') > -1) {
            setPageConnected(true);
            setView('links');
            toast.success('Facebook page connected.', { autoClose: 3000 });
        }
    }, [reviewSites.length]);

    const save = (site): void => {
        const dialogInput = '';
        const dialogName = '';
        const reviewSiteId = null;
        if (!props.ReviewSitesStore?.edition) {
            props.ReviewSitesStore?.addSite({
                siteId: dialogInput,
                site: site,
                siteName: dialogName,
            });
        } else {
            const site = reviewSites.find((s) => s.id === reviewSiteId);

            props.ReviewSitesStore?.editSite({
                ...site,
                siteId: dialogInput,
                siteName: dialogName,
                reviewSiteId: reviewSiteId,
                site: site,
            });
        }
    };

    const uploadCustomers = (file) => {
        ImportCustomersStore.uploadCustomers(file, () => setView('csv2'));
    };

    return (
        <div
            className={classNames('Onboarding card', {
                reviews: props.reviews,
                wider: view === 'csv2',
            })}
        >
            <div className="revWrapper">
                {view === 'csv2' && (
                    <div className="csv2">
                        <Import
                            onboarding={true}
                            goBack={(success) =>
                                success ? setView('success') : setView('populate')
                            }
                        />
                    </div>
                )}
                {view === 'populate' && (
                    <Populate
                        setView={setView}
                        anySites={Boolean(props.ReviewsStore.anySites)}
                        anyCustomers={Boolean(props.CustomersStore.customers.length)}
                    />
                )}
                {view === 'csv' && (
                    <CSV
                        setView={setView}
                        setSucceeded={setSucceeded}
                        uploadCustomers={(file) => uploadCustomers(file)}
                    />
                )}
                {view === 'links' && (
                    <Links
                        setView={setView}
                        reviews={props.reviews}
                        isGoogleConnected={Boolean(
                            props.ReviewSitesStore.reviewSites.find(
                                (el) => el.site.name === 'GOOGLE'
                            )
                        )}
                        isFbConnected={Boolean(
                            props.ReviewSitesStore.reviewSites.find(
                                (el) => el.site.name === 'FACEBOOK'
                            )
                        )}
                    />
                )}
                {view === 'fb' && (
                    <Fb
                        setSucceeded={setSucceeded}
                        setView={setView}
                        reviews={props.reviews}
                        linksReload={props.linksReload}
                        addSite={addSite}
                        pages={pages}
                        selectPage={(pageOrLocation, type) => selectPage(pageOrLocation, type)}
                        save={() => save(1)}
                        loading={loading}
                        pageConnected={pageConnected}
                    />
                )}
                {(view === 'google' && props.ReviewSitesStore.pages.length === 0) && (
                    <Google
                        setSucceeded={setSucceeded}
                        setView={setView}
                        reviews={props.reviews}
                        linksReload={props.linksReload}
                        addSite={addSite}
                        selectPage={(pageOrLocation, type) => selectPage(pageOrLocation, type)}
                        save={() => save(0)}
                        loading={loading}
                    />
                )}
                {((view === 'google' || view === 'fb') && props.ReviewSitesStore.pages.length !== 0) && (
                    (
                        <div className="Integrations__dialogCards">
                            {props.ReviewSitesStore.pages.map((p) => {
                                return (
                                    <div
                                        key={p.locationName}
                                        onClick={() => selectPage(p, view === 'fb' ? 'FACEBOOK' : 'GOOGLE')}
                                        className="Integrations__dialogCard"
                                    >
                                        <Loader loading={localLoading} />
                                        <div className="Integrations__dialogCardImage">
                                            <CheckGrey className="svg" />
                                        </div>
                                        <h4 className="Integrations__dialogCardTitle">
                                            {p.locationName}
                                        </h4>
                                    </div>
                                );
                            })}
                        </div>
                    )
                )}
                {view === 'other' && (
                    <Other
                        setSucceeded={setSucceeded}
                        setView={setView}
                        reviews={props.reviews}
                        linksReload={props.linksReload}
                        addSite={addSite}
                    />
                )}
                {view === 'success' && (
                    <SuccessView successType={succeeded} setView={() => setView('populate')} />
                )}
            </div>
        </div>
    );
};

export const Onboarding = withStores(OnboardingWithStore, [
    STORES.Reviews,
    STORES.ReviewSites,
    STORES.Customers,
]);
