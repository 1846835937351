import React from 'react';
import classNames from 'classnames';
import './badge.sass';

interface BadgeProps {
    count: number;
}

export const Badge: React.FC<BadgeProps> = (props) => {
    if (!props.count) {
        return null;
    }

    const badgeNumber = props.count > 9 ? '9+' : props.count.toString();
    const cls = classNames({
        Badge: true,
        'large-number': props.count > 9,
    });

    return (
        <div className={cls}>
            <span>{badgeNumber}</span>
        </div>
    );
};
