type CampaignUrlParams = Record<string, string>;

const URL_PARAMS_PREFIX = "ext_";

const URL_PARAMS_TO_CAPTURE = [
    "utm_campaign",
    "utm_source",
    "utm_medium",
    "utm_term",
    "utm_content"
];

export const calculateForwardedCampaignParams = (search: string): CampaignUrlParams => {
    const locationParts = search.split("?")
    const lastPart = locationParts[locationParts.length-1]
    const includesAny = (text, arry): Boolean => arry.filter((x) => text.indexOf(x) != -1 ).length > 0
    const params = lastPart.split("&").filter((x) => includesAny(x, URL_PARAMS_TO_CAPTURE)).map((x) => x.replace(URL_PARAMS_PREFIX, ""))
    const paramsAsObject = params.reduce((acc, x) => {acc[x.split("=")[0]] = x.split("=")[1]; return acc }, {})
    return paramsAsObject;
};
