import React, { useEffect, useState } from 'react';
import { BroadcastReceipientsLocalStore, RecipientOption as OptionType} from './BroadcastReceipientsLocalStore.store';

import { observer } from 'mobx-react';
import {Autocomplete} from './Autocomplete';

import { ReactComponent as ProfileOutlineSvg } from '@src/theme/icons/profile_outline.svg';
import { ReactComponent as TagSvg } from '@src/theme/icons/tag-outlined-grey.svg';
import { checkPhoneNumber, transformPhoneNumber } from '@src/theme/utils/helpers';
import { useDebounce } from '@src/hooks';
import { NewRecipientOption, RecipientOption } from './RecipientOption';

interface RecipientsPickerProps {
    broadcastReceipientsLocalStore: BroadcastReceipientsLocalStore;
}

const RecipientsPicker: React.FunctionComponent<RecipientsPickerProps> = observer(({ broadcastReceipientsLocalStore }) => {
    const { recipients, recipientsSearch, availableRecipients } = broadcastReceipientsLocalStore;

    const [ queryString, setQueryString ] = useState("");

    const [ newRecipient, setNewRecipient ] = useState<OptionType>({
        label: "",
        totalNumber: 1,
        type: 'CUSTOMER',
        value: ''
    });

    const handleRecipientValueChange = (value: string) => {
        const fixedPhoneNumber = transformPhoneNumber(value)
        setNewRecipient({
            label: fixedPhoneNumber,
            totalNumber: 1,
            type: 'CUSTOMER',
            value: ''
        });
        setQueryString( fixedPhoneNumber );
    };

    const handleRecipientOptionClick = (option: OptionType) => {
        if (option) {
            broadcastReceipientsLocalStore.addNewRecipient(option);
            setNewRecipient(null);
        };
    };

    const handleNewRecipientClick = () => {
        broadcastReceipientsLocalStore.addNewRecipient(newRecipient)
        setNewRecipient(null);
        setQueryString("");
    };

    const newRecipientListOption = newRecipient?.label
        && recipientsSearch.results.length === 0
        && !recipients.results.find((x) => x.label === newRecipient.label)
        && checkPhoneNumber(transformPhoneNumber(newRecipient.label.trim()))
        && <NewRecipientOption label="Add new recipient" onClick={handleNewRecipientClick}/>;


    const recipientListListOption = (option: OptionType, selected: boolean) => {
        return <RecipientOption
            icon={ option.type === 'CUSTOMER' ? <ProfileOutlineSvg className="itemIcon" /> : <TagSvg className="itemIcon" /> }
            option={option}
            selected={selected}
        />;
    };

    const renderOption = recipientListListOption

    const debounceRecipientQuery = useDebounce(queryString, 200);

    const setRecipientsSearchQuery = (cleaned) => {
        broadcastReceipientsLocalStore.setRecipientsSearchQuery(cleaned);
    };

    useEffect(() => {
        const regExp = /[a-zA-Z]/g;
        let cleaned = debounceRecipientQuery;
        if(!regExp.test(cleaned)) {
            cleaned = cleaned.replace(/\D/g, '');
        }
        setRecipientsSearchQuery(cleaned);
    }, [debounceRecipientQuery]);

    const runRecipientsSearchQuery = () => {
        broadcastReceipientsLocalStore.runRecipientsSearchQuery()
    }

    useEffect(() => {
        if (availableRecipients.length < 5 && recipientsSearch.moreAvailable) {
            runRecipientsSearchQuery();
        };
    }, [availableRecipients])

    return (
        <Autocomplete<OptionType>
            className="flex1"
            value={queryString}
            emptyOptionsText={"No customers/tags to display."}
            hasMore={recipientsSearch.moreAvailable}
            loadMore={() => broadcastReceipientsLocalStore.runRecipientsSearchQuery()}
            name="recipients"
            onChange={handleRecipientValueChange}
            options={availableRecipients}
            renderOption={renderOption}
            renderAddNewElementOption={newRecipientListOption}
            onOptionClick={handleRecipientOptionClick}
        />
    )
});

export default RecipientsPicker;
