import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { StripeProvider } from 'react-stripe-elements';
import { Elements } from 'react-stripe-elements';
import { STORES, WithSignupStoreProps, withStores } from '@src/stores/with-store';
import { getBillingPlanAndPriceByPriceId } from '@src/theme/utils/helpers';
import { PaymentInsideStripe, Loader } from '@src/components';
import { ERROR_TOAST_AUTOCLOSE } from '@src/theme/utils/constants';
import { Address } from './Address';
import { Address as AddressModel } from '@src/stores/models';
import AddPaymentOnShortSignupHeader from '@src/components/PaymentInsideStripe/headers/AddPaymentOnShortSignup';

import '@src/pages/Auth/SignUp2/Signup.sass';


export interface PaymentProps {
    stripe: any;
}

type props = PaymentProps & WithSignupStoreProps;

export const PaymentForStripe: React.FC<props> = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [ showCCfields, setShowCCfields ] = useState<boolean>(false);

    const priceId: string = location.state ? (location.state as {priceId: string}).priceId : undefined
    const twilioPhone: string = location.state ? (location.state as {twilioPhone: string}).twilioPhone : undefined

    const selectedPlan = getBillingPlanAndPriceByPriceId(props.SignupStore.plans, priceId);

    useEffect(() => {
        if (!priceId || !twilioPhone) {
            history.push('/signup');
            toast.error('Something went wrong, please try again', ERROR_TOAST_AUTOCLOSE);
        } else if (props.SignupStore.user?.stripeKey) {
            setShowCCfields(true);
        } else if (!props.SignupStore.user) {
            props.SignupStore.getUser()
            .then((val) => {
                if (val && props.SignupStore.user?.stripeKey) {
                    setShowCCfields(true);
                } else {
                    history.push('/signup');
                    toast.error('Something went wrong, please try again', ERROR_TOAST_AUTOCLOSE);
                };
            });
        };

        if (!props.SignupStore.plans) {
            props.SignupStore.getPlans()
        };
    }, []);

    const CCfields =
        <div className="Signup__main">
            <h2 className="Signup__subtitle">Subscription</h2>
            <AddPaymentOnShortSignupHeader selectedPlan={selectedPlan} />
            <StripeProvider apiKey={props.SignupStore.user?.stripeKey}>
                <Elements>
                    <Address
                        previousData={props.SignupStore.user?.companyInfo}
                        user={props.SignupStore.user}
                        phone={twilioPhone}
                        selectedPlan={selectedPlan}
                        oldDataToIncludeWhileAddingAddress={{
                            companyName: props.SignupStore.user?.companyInfo.companyName,
                            address: props.SignupStore.user?.companyInfo.address,
                            firstName: props.SignupStore.user?.user.firstName,
                            lastName: props.SignupStore.user?.user.lastName,
                            phone: props.SignupStore.user?.user.phone,
                            smsNumber: twilioPhone,
                            email: props.SignupStore.user?.user.email,
                            timeZone: props.SignupStore.user?.user.timeZone
                        }}
                    />
                </Elements>
            </StripeProvider>
        </div>

    return (
        <>
            {showCCfields ? CCfields : <Loader loading={true} />}
        </>
    )
};

export const CollectingCC = withStores(PaymentForStripe, [STORES.Signup]);
