import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { FormField } from '@src/components';
import { NamesAndPhoneInterface } from './models';
import { convertToPureNumber } from '@src/theme/utils/helpers';

import './personal-info.sass';

interface NamesAndPhoneProps {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    updateData: (data: NamesAndPhoneInterface) => void;
}

export const NamesAndPhone: React.FC<NamesAndPhoneProps> = (props) => {
    const { register, handleSubmit, errors, setValue } = useForm();
    const [phone, setPhone] = useState(convertToPureNumber(props.phone));

    useEffect(() => {
        setValue('number', convertToPureNumber(props.phone));
    }, [props]);

    return (
        <form className="PersonalInfo__content" onSubmit={handleSubmit(props.updateData)}>
            <FormField label="First name" htmlFor="firstName" error={errors.firstName}>
                <input
                    type="text"
                    name="firstName"
                    className={classNames('form-input', { error: errors.firstName })}
                    defaultValue={props.firstName}
                    ref={register({ required: 'Missing first name' })}
                />
            </FormField>
            <FormField label="Last name" htmlFor="lastName" error={errors.lastName}>
                <input
                    type="text"
                    name="lastName"
                    className={classNames('form-input', { error: errors.lastName })}
                    defaultValue={props.lastName}
                    ref={register({ required: 'Missing last name' })}
                />
            </FormField>
            <FormField label="Phone number" htmlFor="number" error={errors.number}>
                <InputMask
                    mask="(999) 999-9999"
                    value={phone ? phone : ''}
                    onChange={(e) => setPhone(convertToPureNumber(e.target.value))}
                    onBlur={(e) => setValue('number', e.target.value)}
                    inputRef={register(
                        { name: 'number' },
                        {
                            required: 'Provide valid number',
                            validate: (number) =>
                                convertToPureNumber(number).length == 10
                                    ? undefined
                                    : 'Provide valid number'
                        }
                    )}
                    className={classNames('form-input', { error: errors.number })}
                />
            </FormField>
            <button className="btn-primary">Update</button>
        </form>
    );
};
