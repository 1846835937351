import React, { useState, useEffect } from 'react';
import { Loader } from '@src/components';
import { v4 as uuidv4 } from 'uuid';

import { images } from '@src/theme/images';
import { ReactComponent as CheckGrey } from '@src/theme/icons/check-grey.svg';

interface BasicProps {
    setView?: (view: string) => void;
    setSucceeded?: (success: string) => void;
}

interface SiteProps {
    siteId: string;
    site: number;
    siteName?: string;
}

interface LinksProps extends BasicProps {
    reviews?: boolean;
    linksReload?: () => void;
    addSite?(site: SiteProps): void;
    pages?: any;
    selectPage?: (pageOrLocation, type) => void;
    save?: () => void;
    loading?: string;
    pageConnected?: boolean;
}

declare const window: any;

export const Fb: React.FC<LinksProps> = (props) => {
    const [pages, setPages] = useState(null);

    useEffect(() => {
        setPages(props.pages);
    }, [props.pages]);

    return (
        <div>
            <Loader loading={props.loading === 'loading'} />
            <h3>Link Facebook</h3>
            <p>
                Make sure you have access to the business Facebook page. If you do not have a
                facebook page for your business you will need to create one.
            </p>
            {pages?.length ? (
                <div className="Onboarding__Cards">
                    {pages.map((p) => {
                        return (
                            <div
                                key={uuidv4()}
                                onClick={() => props.selectPage(p, 'FACEBOOK')}
                                className="Onboarding__Card"
                            >
                                <div className="Onboarding__CardImage">
                                    <CheckGrey className="svg" />
                                </div>
                                <span>
                                    <h4 className="Onboarding__CardTitle only">{p.locationName}</h4>
                                </span>
                            </div>
                        );
                    })}
                </div>
            ) : null}
            <div className="Onboarding__Buttons">
                <button className="btn-secondary" onClick={() => props.setView('links')}>
                    Back
                </button>
                {(!pages || pages?.length == 0) && (
                    <button
                        className="btn-primary"
                        onClick={() => props.save()}
                        disabled={props.pageConnected}
                    >
                        <img src={images.fb} alt="fb" />
                        Continue with Facebook
                    </button>
                )}
            </div>
        </div>
    );
};
