import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { Input } from 'reactstrap';
import InputMask from 'react-input-mask';
import {
    Customer,
    LoadingState,
} from '@src/stores/models';
import { toast } from 'react-toastify';
import { onSuccess } from '@src/theme/utils/constants';
import { AsYouType } from 'libphonenumber-js';
import {
    Attachments,
    AttachmentFile,
    FormField,
    VariableInput
} from '@src/components';
import {
    Attachment,
    HashMap,
    Template,
    ParsedPrompts
} from '@src/stores/models';
import { withStores, STORES } from '@src/stores/with-store';
import { ThreadsStore } from '@src/stores/messages/threads.store';
import { ReactComponent as AttachmentIcon } from '@src/theme/icons/attach.svg';
import { ReactComponent as TemplateIcon } from '@src/theme/icons/template.svg';
import { TemplatesMes, TemplatesResponse } from '..';
import { InsertedData } from './NewContactWrapper';
import MessagesStore from '@src/stores/messages/messages.store';
import { convertToPureNumber } from '@src/theme/utils/helpers';
import { ERROR_TOAST_AUTOCLOSE } from '@src/theme/utils/constants';

import './new-contact.sass';
import '../messages-common.sass';
import { DraftEditorLocalStore } from '@src/components/VariableInput/DraftEditorLocalStore';

let loaded: HashMap<Attachment> = {};

interface MessageProps {
    ThreadsStore: ThreadsStore;
    sendMessage: (customer: Customer) => void;
    insertedData: InsertedData;
}

const NewContactAddAndMessageWithStore: React.FC<MessageProps> = (props) => {
    const store = props.ThreadsStore;
    const attachmentRef = useRef(null);
    const history = useHistory();
    const { templates } = store;
    const [attachments, setAttachments] = useState<AttachmentFile[]>([]);
    const [message, setMessage] = useState<string>(props.insertedData.message || '');
    const [selectedTemplate, setSelectedTemplate] = useState<string>('');
    const [showTemplates, setShowTemplates] = useState(false);
    const [focusOnMsg, setFocusOnMsg] = useState(false);
    const [phone, setPhone] = useState(props.insertedData.phone);
    const [firstName, setFirstName] = useState(props.insertedData.firstName);
    const [lastName, setLastName] = useState(props.insertedData.lastName);
    const isPhoneInputFirst = Boolean(props.insertedData.phone);
    const [draftEditorLocalStore] = useState(() => new DraftEditorLocalStore())

    const inputsRefs = {
        all: useRef(null),
        firstName: useRef(null),
        lastName: useRef(null),
        phone: useRef(null),
        msg: useRef(null),
    };

    const validData = (data: string) => {
        if (data === 'firstName' && firstName) return true;
        if (data === 'lastName' && lastName) return true;
        if (
            data === 'phone' &&
            phone &&
            convertToPureNumber(phone).length === 10
        ) {
            return true;
        }
        return false;
    };

    const putFocusOnBeginning = () => {
        isPhoneInputFirst && validData('phone') ?
            inputsRefs.firstName.current.focus() :
        isPhoneInputFirst && !validData('phone') ?
            inputsRefs.phone.current.focus() :
        props.insertedData.lastName ?
            inputsRefs.lastName.current.focus() :
            inputsRefs.firstName.current.focus();
    };

    useEffect(() => {
        loaded = {};
        const timeout = setTimeout(() => {
            putFocusOnBeginning();
        }, 200);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (props.insertedData.firstName && props.insertedData.lastName && props.insertedData.phone && props.insertedData.message) {
            const sto = setTimeout(() => {
                putFocusOnMsg();
            }, 100);
            return () => {clearTimeout(sto)};
        } else if (props.insertedData.firstName && props.insertedData.lastName && props.insertedData.message) {
            inputsRefs.phone.current.focus();
        };
    }, [props.insertedData]);

    const putFocusOnMsg = () => {
        setFocusOnMsg(true);
        const timeout = setTimeout(() => {
            setFocusOnMsg(false);
        }, 100);
        setMessage(props.insertedData.message || '');
        return () => clearTimeout(timeout);
    };

    const attachmentsChange = (loadedAtt: Attachment) => (loaded[loadedAtt.id] = loadedAtt);
    const removeAttachment = (attId: string) => {
        delete loaded[attId];
        const idx = attachments.findIndex((x) => x.id === attId);
        if (idx < 0) {
            return;
        }
        attachments.splice(idx, 1);
        setAttachments([...attachments]);
    };

    const onFilesAdded = (files: FileList) => {
        const newAttachments = [];
        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);
            file['id'] = uuidv4();
            newAttachments.push(file);
        }
        setAttachments(attachments.concat(newAttachments));
        attachmentRef.current.value = '';
    };

    const templateSelected = (template: Template) => {
        if (template.attachments.length > 0) {
            const atts = template.attachments.map((elem) => {
                const att = { ...elem, uri: elem.url };
                attachmentsChange(att);
                return att;
            });
            setAttachments(attachments.concat(atts));
        }
        setShowTemplates(false);
        setSelectedTemplate(template.message);

        const timeout = setTimeout(() => {
            setSelectedTemplate(null);
        }, 100);
        return () => clearTimeout(timeout);
    };

    const sendMessage = (m: string, customer) => {
        if (!m) return;

        MessagesStore.loading = LoadingState.Loading;

        store.createNewThread(customer, true)
        .then(
            () => {
                const mappedAttachments = Object.values(loaded).map((att) => {
                    const loaded = { ...att, id: Math.random().toString(36).slice(2) };
                    delete loaded.url;
                    return loaded;
                });

                store.sendMessage(m, mappedAttachments, true, true)
                .then(
                    () => {
                        history.replace({ pathname: `/messages/?new` });
                        const timeout = setTimeout(() => {
                            setMessage('');
                            setAttachments([]);
                            loaded = {};
                        }, 5);
                        return () => clearTimeout(timeout);
                    },
                    () => {
                        MessagesStore.loading = LoadingState.Error
                    }
                )
            },
            () => {
                MessagesStore.loading = LoadingState.Error
            }
        );
    };

    const onErrorIncorrectPhone = () => {
        toast.error('Phone number is invalid. Please provide a valid 10 digit phone number', ERROR_TOAST_AUTOCLOSE);
    };
    store.onSuccess = () => {
        store.loading = LoadingState.Loaded;
        onSuccess();
    };
    store.onErrorIncorrectPhone = () => {
        store.loading = LoadingState.Error;
        onErrorIncorrectPhone();
    };

    const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(new AsYouType('US').input(e.target.value));
    };

    const inputClicked = (e: React.KeyboardEvent<HTMLInputElement>, input: string) => {
        if (e.keyCode === 9) {
            e.preventDefault();
        };
        if ((e.keyCode === 13 || e.keyCode === 9) && validData(input)) { // enter, tab
            if (input === 'firstName' && firstName) inputsRefs.lastName.current.focus();
            if (isPhoneInputFirst && input === 'phone' && phone && validData('phone')) inputsRefs.firstName.current.focus();
            if (!isPhoneInputFirst && input === 'phone' && phone && validData('phone')) putFocusOnMsg();
            if (isPhoneInputFirst && input === 'lastName' && lastName) putFocusOnMsg();
            if (!isPhoneInputFirst && input === 'lastName' && lastName) inputsRefs.phone.current.focus();
        } else if (e.keyCode === 8) { // backspace
            if (input === 'lastName' && !lastName) {
                inputsRefs.firstName.current.focus();
                e.preventDefault();
            } else if (!isPhoneInputFirst && input === 'phone' && (!phone || phone === '+1 (___) ___-____' || phone === '+1 ' || phone === '+1')) {
                inputsRefs.lastName.current.focus();
                e.preventDefault();
            } else if (isPhoneInputFirst && input === 'firstName' && !firstName) {
                inputsRefs.phone.current.focus();
                e.preventDefault();
            };
        } else {
            // if (
            //     e.keyCode == 32 || // spacebar
            //     (e.keyCode > 47 && e.keyCode < 58) || // number keys
            //     (e.keyCode > 64 && e.keyCode < 91) || // letter keys
            //     (e.keyCode > 95 && e.keyCode < 112) || // numpad keys
            //     (e.keyCode > 185 && e.keyCode < 193) || // ;=,-./` (in order)
            //     (e.keyCode > 218 && e.keyCode < 223) // [\]' (in order)
            // ) {
            const timeout = setTimeout(() => {
                if (isPhoneInputFirst && input === 'phone' && phone && convertToPureNumber(phone).length === 9) {
                    inputsRefs.firstName.current?.focus();
                };
                if (!isPhoneInputFirst && input === 'phone' && phone && convertToPureNumber(phone).length === 9) {
                    putFocusOnMsg();
                };
            }, 100);
            return () => clearTimeout(timeout);
            // }
        }
    };

    const nameInputs = (
        <div key="name-wrapper" className="ncRow nameRow">
            {(!isPhoneInputFirst || (isPhoneInputFirst && phone && validData('phone'))) &&
                <>
                    <FormField label="First Name" htmlFor="firstName">
                        <input
                            type="text"
                            name="firstName"
                            defaultValue={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                            }}
                            onKeyDown={(e) => inputClicked(e, 'firstName')}
                            className='form-input'
                            ref={inputsRefs.firstName}
                        />
                    </FormField>
                    <FormField label="Last Name" htmlFor="lastName">
                        <input
                            type="text"
                            name="lastName"
                            defaultValue={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            onKeyDown={(e) => inputClicked(e, 'lastName')}
                            className='form-input'
                            ref={inputsRefs.lastName}
                        />
                    </FormField>
                </>
            }
        </div>
    );

    const phoneInput = (
        <div key="phone" className="ncRow">
            {(isPhoneInputFirst || (!isPhoneInputFirst && firstName && lastName)) &&
                <FormField label="Phone Number" htmlFor="phone">
                    <Input
                        type="text"
                        mask="+1 (999) 999-9999"
                        name="phone"
                        defaultValue={phone}
                        onChange={(e) => onPhoneChange(e)}
                        onKeyDown={(e) => inputClicked(e, 'phone')}
                        className='form-input'
                        inputRef={(e) => inputsRefs.phone.current = e}
                        tag={InputMask}
                    />
                </FormField>
            }
        </div>
    );

    const templatesView = showTemplates && (
        <TemplatesMes
            key={templates.length}
            onTemplateSelect={(t) => templateSelected(t)}
        />
    );

    const attachmentsView = (
        <Attachments
            onAttachmentsChange={(att) => attachmentsChange(att)}
            onAttachmentRemove={(att) => removeAttachment(att)}
            attachments={attachments}
        />
    );

    const templateReply = (
        <div className="template-reply" key="templateReply">
            <TemplatesResponse
                key={templates.length}
                templates={templates}
                onTemplateSelect={(t) => templateSelected(t)}
            />
        </div>
    );

    const typebar = (
        <div className="typebar" key="typebar">
            <div className="action-buttons" key="typebaractionbuttons">
                <label key="typebarlabel">
                    <AttachmentIcon />
                    <input
                        key="typebarinput"
                        ref={attachmentRef}
                        multiple
                        type="file"
                        onChange={(e) => onFilesAdded(e.target.files)}
                    />
                </label>
                <div key="emptydiv" />
                <button
                    key="showhidetemplates"
                    className={classNames({ clicked: showTemplates })}
                    onClick={() => setShowTemplates(!showTemplates)}
                >
                    <TemplateIcon />
                </button>
            </div>
            <VariableInput
                draftEditorLocalStore={draftEditorLocalStore}
                onChange={setMessage}
                message={message}
                notes={false}
                usedTemplate={selectedTemplate}
                activateVariablesView={'viewNewContact'}
                prompts={ParsedPrompts({
                    customer: {firstName, lastName},
                    owner: store.owner,
                    constants: store.messagingConstants
                })}
                send={(m) => sendMessage(m, { firstName, lastName, phone })}
                focus={focusOnMsg}
            />
        </div>
    );

    return (
        <section className="NewContactAddAndMessage">
            <div className="NewContactAddAndMessage__header">
                <h2>Send Message</h2>
            </div>
            <div className="NewContactAddAndMessage__form">
                {isPhoneInputFirst ? [phoneInput, nameInputs] : [nameInputs, phoneInput]}
            </div>
            <div className="NewContactAddAndMessage__bottom">
                {templatesView || attachmentsView}
                <div className={classNames('messageFieldWrapper', { visible: (firstName && lastName && phone && validData('phone')) })}>
                    {[templateReply, typebar]}
                </div>
                {/* {(firstName && lastName && phone && validData('phone')) && [templateReply, typebar]} */}
            </div>
        </section>
    );
};

export const NewContactAddAndMessage = withStores(NewContactAddAndMessageWithStore, [STORES.ThreadsStore]);
