import api from '@src/api/api';

export const getSmsSettings = () => {
    return api.get('/smssettings');
};

export const postNewForwardNumber = (forwardPhone: string, extension?: string) => {
    return api.post('/smssettings/forwardnumber', { forwardPhone, extension });
};

export const removeForwardNumber = (id: string) => {
    return api.post('/smssettings/removeforwardnumber', { id });
};

export const toggleActive = (id: string, active: boolean) => {
    return api.post('/smssettings/active', { id, active });
};

export const toggleMassForward = (id: string, active: boolean) => {
    return api.post('/smssettings/massforward', { id, active });
};

export const toggleForwardDuringOfficeHours = (id: string, active: boolean) => {
    return api.post('/smssettings/officehrsforward', { id, active });
};

export const toggleAutoMessage = (id: string, active: boolean) => {
    return api.post('/smssettings/toggleautomessage', { id, active });
};

export const updateAutoMessage = (id: string, message: string) => {
    return api.post('/smssettings/updateautomessage', { id, message });
};

export const greetingUpload = (file: Blob) => {
    const url = '/smssettings/greeting';
    const formData = new FormData();
    formData.append('file', file);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return  api.post(url, formData, config);
}

export const phoneRequests = {
    getSmsSettings,
    postNewForwardNumber,
    removeForwardNumber,
    toggleMassForward,
    toggleForwardDuringOfficeHours,
    toggleAutoMessage,
    updateAutoMessage,
    toggleActive,
    greetingUpload
};
