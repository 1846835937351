import { Customer, Employee, MessagingConstants } from '@src/stores/models';
interface InputsRefs {
    firstName: any | null;
    lastName: any | null;
}
export const parseTemplate = (
    template: string,
    customer?: Customer,
    owner?: Employee,
    constants?: MessagingConstants,
    inputsRefs?: InputsRefs
) => {
    const employee = constants?.employee,
        address = constants?.address;
    const firstNameRef = inputsRefs?.firstName.current.value;
    const lastNameRef = inputsRefs?.lastName.current.value;
    let fullName;
    if (customer?.firstName) {
        fullName = `${customer?.firstName} ${customer?.lastName}`;
    } else if (customer?.phone) {
        fullName = customer?.phone;
    } else if (firstNameRef) {
        fullName = `${firstNameRef} ${lastNameRef}`;
    } else {
        fullName = 'Full Name';
    }
    const ownerObj: Employee = owner ? owner : ({} as any);
    let parsed = template;
    const placeholders = [
        { placeholder: ':full_name:', color: '#01579B', value: fullName },
        {
            placeholder: ':first_name:',
            color: '#01579B',
            value: customer?.firstName
                ? customer.firstName
                : firstNameRef
                ? firstNameRef
                : 'First Name',
        },
        {
            placeholder: ':last_name:',
            color: '#01579B',
            value: customer?.lastName ? customer.lastName : lastNameRef ? lastNameRef : 'Last Name',
        },
        {
            placeholder: ':user_name:',
            color: '#1B5E20',
            value:
                employee?.firstName && employee?.lastName
                    ? `${employee.firstName} ${employee.lastName}`
                    : 'User Name',
        },
        {
            placeholder: ':user_email:',
            color: '#1B5E20',
            value: employee?.email ? employee.email : 'User Email',
        },
        {
            placeholder: ':owner_name:',
            color: '#1E90FF',
            value:
                ownerObj?.firstName && ownerObj?.lastName
                    ? `${ownerObj.firstName} ${ownerObj.lastName}`
                    : 'Owner Name',
        },
        {
            placeholder: ':owner_email:',
            color: '#1E90FF',
            value: ownerObj?.email ? ownerObj.email : 'Owner Email',
        },
        {
            placeholder: ':company:',
            color: '#C62828',
            value: address?.company ? address.company : 'Company',
        },
        {
            placeholder: ':street:',
            color: '#E65100',
            value: address?.street ? address.street : 'Street',
        },
        { placeholder: ':city:', color: '#E65100', value: address?.city ? address.city : 'City' },
        {
            placeholder: ':state:',
            color: '#E65100',
            value: address?.state ? address.state : 'State',
        },
        {
            placeholder: ':zip:',
            color: '#E65100',
            value: address?.postalCode ? address.postalCode : 'Zip',
        },
        {
            placeholder: ':address:',
            color: '#E65100',
            value:
                address?.street && address?.city && address?.state && address?.postalCode
                    ? `${address.street}, ${address.city}, ${address.state}, ${address.postalCode}`
                    : 'Address',
        },
        { placeholder: ':review_invitation:', color: '#FFAB00', value: 'Review Invitation' },
    ];

    const substitutePlaceholders = () => {
        let number = 0;
        placeholders.forEach((item) => {
            const amount = parsed.match(new RegExp(item.placeholder, 'g'))?.length;
            if (amount > number) number = amount;
        });
        placeholders.forEach((item) => {
            Array.from({ length: number }, () => {
                parsed = parsed.replace(
                    item.placeholder,
                    `<span style="padding: 1px 8px; border-radius: 10px; background-color: ${item.color}; color: white; font-size: 11px; font-weight: 500; white-space: nowrap;">${item.value}</span>`
                    // `<span data-value="${item.placeholder}" style="padding: 1px 8px; border-radius: 10px; background-color: ${item.color}; color: white; font-size: 11px; font-weight: 500; white-space: nowrap;">${item.value}</span>`
                );
            });
        });
    };
    substitutePlaceholders();
    return parsed;
};
