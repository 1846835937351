import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { onSuccess, re } from '@src/theme/utils/constants';
import { convertToPureNumber } from '@src/theme/utils/helpers';
import { withTeamStore, WithTeamStoreProps } from '@src/stores/with-store';
import { Role, RoleTxt, LoadingState, MemberData, OnErrorDataTeam } from '@src/stores/models';
import { Loader, FormField, ErrorToastSave } from '@src/components';
import LockedAccountDialog from '@src/components/Layout/LockedAccountDialog';

enum Action {
    EDIT = 'EDIT',
    ADD = 'ADD',
}

export const TeamFormWithStore: React.FC<WithTeamStoreProps> = (props: WithTeamStoreProps) => {
    const store = props.TeamStore;
    const loading = store.loadingState;
    const { register, handleSubmit, errors, formState, setValue, watch } = useForm();

    const { employees, seats } = store;

    const history = useHistory();
    const { employeeId, action } = useParams();
    const view = action == Action.EDIT.toLowerCase() ? Action.EDIT : Action.ADD;
    const member = employeeId && employees.find((e) => e.id === employeeId);

    const [phone, setPhone] = useState(member?.phone ? convertToPureNumber(member?.phone) : '');

    JSON.stringify(formState); // hack, without this can't get touched form-fields
    const touched = Object.keys(formState.touched);

    const changes = {
        namePhone: Boolean(
            touched.find((t) => t === 'firstName' || t === 'lastName' || t === 'phone')
        ),
        role: Boolean(watch('role') ? watch('role') != member?.role : false),
        email: Boolean(watch('email') ? watch('email') !== member?.email : false),
    };

    store.onErrorAlreadyUsedEmail = (data: string) => {
        store.loadingState = LoadingState.Error;
        toast.error(data);
    };

    store.onErrorLimitHit = () => {
        store.loadingState = LoadingState.Error;
        toast.error(
            <div className="problemToast">
                You have reached a limit of team members you can add in your billing plan.{' '}
                <Link to="/pricing">Click here</Link> to upgrade your billing plan and add new team members.
            </div>
        )
    };

    store.onError = (type: string, data?: OnErrorDataTeam) => {
        store.loadingState = LoadingState.Error;
        switch (type) {
            case 'addTeamMember': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() => store.addTeamMember(data.newMember, 'addTeamMember')}
                    />
                );
                break;
            }
            case 'updateMember': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.updateMember(
                                data.id,
                                data.memberData,
                                data.changes,
                                'updateMember'
                            )
                        }
                    />
                );
                break;
            }
            default: {
                return;
            }
        }
    };
    store.onSuccess = () => {
        store.loadingState = LoadingState.Loaded;
        onSuccess();
    };

    const updateMember = (memberData: MemberData) => {
        const member = { ...memberData, phone: convertToPureNumber(memberData.phone) };
        store.updateMember(employeeId, member, changes, 'updateMember').then((data) => {
            if (data) history.push('/settings/team');
        });
    };

    const addMember = (memberData: MemberData) => {
        const newMember = { ...memberData, role: Number(memberData.role), phone: convertToPureNumber(memberData.phone) };
        store.addTeamMember(newMember, 'addTeamMember').then((data) => {
            if (data) history.push('/settings/team');
        });
    };

    useEffect(() => {
        setPhone(member?.phone ? convertToPureNumber(member?.phone) : '');
        setValue('phone', member?.phone ? convertToPureNumber(member?.phone) : '');
    }, [member]);

    useLayoutEffect(() => {
        store.init();
    }, []);

    return (
        <div className="TeamForm">
            <Loader loadingState={store.loadingState} />
            <>
                {loading !== LoadingState.Init && loading !== LoadingState.FirstLoading && (
                    <>
                        <Link className="TeamForm__back btn btn-tetriary" to={'/settings/team'}>
                            &lt; Team Members List
                        </Link>
                        <div className="TeamForm__title">
                            <div>{view === Action.EDIT ? 'Edit' : 'Add'} Team Member</div>
                        </div>
                        {!member && action !== Action.ADD.toLowerCase() && (
                            <div className="TeamForm__warning">NO MEMBER WITH ID: {employeeId}</div>
                        )}
                        <div className="TeamForm__content">
                            <form
                                onSubmit={handleSubmit(
                                    view === Action.EDIT ? updateMember : addMember
                                )}
                            >
                                <div className="TeamForm__content-name">
                                    <FormField
                                        label="First name"
                                        htmlFor="firstName"
                                        error={errors.firstName}
                                    >
                                        <input
                                            type="text"
                                            name="firstName"
                                            className={classNames('form-input', {
                                                error: errors.firstName,
                                            })}
                                            defaultValue={member?.firstName}
                                            ref={register({ required: 'Missing first name' })}
                                        />
                                    </FormField>
                                    <FormField
                                        label="Last name"
                                        htmlFor="lastName"
                                        error={errors.lastName}
                                    >
                                        <input
                                            type="text"
                                            name="lastName"
                                            className={classNames('form-input', {
                                                error: errors.lastName,
                                            })}
                                            defaultValue={member?.lastName}
                                            ref={register({ required: 'Missing last name' })}
                                        />
                                    </FormField>
                                </div>
                                <div className="TeamForm__content-role">
                                    {member?.role !== Role.OWNER && (
                                        <FormField label="Role" htmlFor="role" error={errors.role}>
                                            <select
                                                name="role"
                                                id="role"
                                                className={classNames(
                                                    'form-input',
                                                    { error: errors.role },
                                                    'select'
                                                )}
                                                defaultValue={member?.role}
                                                ref={register({ required: 'Missing role' })}
                                            >
                                                <option value={Role.LEADER}>
                                                    {RoleTxt[Role.LEADER]}
                                                </option>
                                                <option value={Role.MEMBER}>
                                                    {RoleTxt[Role.MEMBER]}
                                                </option>
                                            </select>
                                        </FormField>
                                    )}
                                    {member?.role === Role.OWNER && (
                                        <FormField label="Role" htmlFor="role" error={errors.role}>
                                            <input
                                                type="text"
                                                name="role"
                                                value={RoleTxt[Role.OWNER]}
                                                className={classNames(
                                                    'form-input',
                                                    { error: errors.role },
                                                    'disabled'
                                                )}
                                                disabled
                                                ref={register}
                                            />
                                        </FormField>
                                    )}
                                </div>
                                <div className="TeamForm__content-contact">
                                    <FormField
                                        label="Phone number"
                                        htmlFor="phone"
                                        error={errors.phone}
                                    >
                                        <InputMask
                                            mask="(999) 999-9999"
                                            value={phone ? phone : ''}
                                            onChange={(e) => setPhone(e.target.value)}
                                            onBlur={(e) => setValue('phone', convertToPureNumber(e.target.value))}
                                            inputRef={register(
                                                { name: 'phone' },
                                                {
                                                    required: 'Provide valid number',
                                                    validate: (phone) =>
                                                        convertToPureNumber(phone).length == 10
                                                            ? undefined
                                                            : 'Provide valid number'
                                                }
                                            )}
                                            className={classNames('form-input', {
                                                error: errors.phone,
                                            })}
                                        />
                                    </FormField>
                                </div>
                                <div className="TeamForm__content-contact-mail">
                                    <FormField label="Email" htmlFor="email" error={errors.email}>
                                        <input
                                            type="text"
                                            name="email"
                                            className={classNames('form-input', {
                                                error: errors.email,
                                            })}
                                            defaultValue={member?.email}
                                            ref={register({
                                                required: 'Missing email address',
                                                pattern: {
                                                    value: re,
                                                    message: 'Invalid email address',
                                                },
                                            })}
                                        />
                                    </FormField>
                                    <div
                                        className={classNames('confirm', {
                                            optional: changes.email,
                                        })}
                                    >
                                        <FormField
                                            label="Confirm email"
                                            htmlFor="emailConfirm"
                                            error={errors.emailConfirm}
                                        >
                                            <input
                                                type="text"
                                                name="emailConfirm"
                                                className={classNames('form-input', {
                                                    error: errors.emailConfirm,
                                                })}
                                                ref={register({
                                                    pattern: {
                                                        value: re,
                                                        message: 'Invalid email address',
                                                    },
                                                    validate: (value) =>
                                                        changes.email && value !== watch('email')
                                                            ? 'Email fields do not match'
                                                            : undefined,
                                                })}
                                            />
                                        </FormField>
                                    </div>
                                </div>
                                <div className="TeamForm__content-actions">
                                    <Link className="btn btn-secondary" to={'/settings/team'}>
                                        Cancel
                                    </Link>
                                    <button className="btn btn-primary" type="submit">
                                        {view === Action.EDIT ? 'Update' : 'Save'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </>
            {(seats && employees.length >= seats) && <LockedAccountDialog warningCase={'addNewEmployee'} />}
        </div>
    );
};

export const TeamForm = withTeamStore(TeamFormWithStore);
