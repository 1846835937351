import { BaseStore } from '@src/stores/base.store';
import { SocketWrapper } from '@src/api/socket-wrapper';
import { LoadingState, MessagingConstants, ThreadFeed } from '@src/stores/models';
import { action, observable, ObservableMap, runInAction } from 'mobx';
import SocketInstance from '../socket-instance';
import { MessagesApi } from '@src/requests';
import { getNotif } from '@src/requests';
import { ThreadsStore } from './threads.store';
import ConversationsStore from '../teamchat/conversations.store';
import AuthStore from '@src/stores/auth.store';

export class MessagesStore extends BaseStore {
    private _onError: (message: string) => void;
    private _onSuccess: () => void;
    private _messagingConstants: MessagingConstants;
    @observable _threadsStore: ThreadsStore;
    private socketWrapper: SocketWrapper;
    @observable customersLoadMore = false;
    @observable filtersCount: ObservableMap<ThreadFeed, number>;
    @observable ff = null;
    @observable ffBrowserCallingEnabled: boolean;

    @observable loading: LoadingState;

    set onError(fn: (message: string) => void) {
        this._onError = fn;
        this._threadsStore.onError = fn;
    }

    set onSuccess(fn: () => void) {
        this._onSuccess = fn;
    }

    get ThreadsStore(): ThreadsStore {
        return this._threadsStore;
    }

    constructor(socketWrapper: SocketWrapper) {
        super();
        this.socketWrapper = socketWrapper;
        this._threadsStore = new ThreadsStore(this, this.socketWrapper);
    }

    @action init(): void {
        this.loading = LoadingState.Init;
    }

    @action destroy(): void {
        if (this.socketWrapper) {
            this.socketWrapper.destroy();
        };
        this.loading = LoadingState.Init;
        this._messagingConstants = null;
        this.customersLoadMore = false;
        this.filtersCount = null;
        this.ff = null;
        this.ffBrowserCallingEnabled = false;
    }

    @action loadNotificationSettings(id) {
        return getNotif(id)
            .then(
                (res) => {
                    this._threadsStore.setMessagingConstantsNotifications(res);
                },
                (err) => {
                    if (err.response.status === 401) {
                        AuthStore.resetAuth();
                    } else {
                        this.onLoadingError(err);
                    };
                }
            )
    }

    @action loadMessagesConstants(data) {
        const { id, type, isFirstLoading = false } = data;
        this.loading = isFirstLoading ? LoadingState.FirstLoading : LoadingState.Loading;
        return MessagesApi.getMessagingInitCall()
            .then(
                (res) => {
                    runInAction(() => {
                        this._messagingConstants = res;
                        this._threadsStore.setMessagingConstants(res);
                        this.ff = res.featureFlags;
                        this.ffBrowserCallingEnabled = Boolean(
                            res.featureFlags['phone.browser_calling']
                        );
                    });
                    if (data.isCallFromMessages) {
                        this._threadsStore.getCounts();
                        const savedSearchData = localStorage.getItem('messagingSearchData');
                        const dto = savedSearchData ? JSON.parse(savedSearchData) : {firstLoading: isFirstLoading, open: true}
                        return this._threadsStore.searchThreads(dto);
                    }
                },
                (err) => {
                    if (err.response?.status === 401) {
                        AuthStore.resetAuth();
                    } else {
                        this.onLoadingError(err);
                    };
                }
            )
            .then(() => {
                if (type === 't') {
                    return this._threadsStore.selectThreadByThreadId(id).catch((err) =>
                        this.onLoadingError(err)
                    );
                } else if (type === 'c') {
                    const channel = ConversationsStore.ConversationsListStore.channelsList.find((c) => c.conversationId === id);
                    return ConversationsStore.SelectedChannelStore.selectChannel(id, channel?.name).catch((err) =>
                        this.onLoadingError(err)
                    );
                } else if (type === 'd') {
                    return ConversationsStore.SelectedDirectConversationStore.selectDirectConversation(id).catch((err) =>
                        this.onLoadingError(err)
                    );
                } else return Promise.resolve();
            })
            .then(() => {
                this.loading = LoadingState.Loaded;
            })
    };

    @action sendMobileAppLink = async (phone: string, message: string): Promise<void> => {
        this.loading = LoadingState.Loading;
        try {
            await MessagesApi.appInvite(phone)
            this.loading = LoadingState.Loaded;
        }
        catch(error) {
            this.loading = LoadingState.Error;
            throw 'Incorrect phone number';
        }
    };

    private onLoadingError = (err) => {
        runInAction(() => (this.loading = LoadingState.Error));
        if (this._onError) {
            this._onError(err.message);
        }
    };

    // @action searchMoreCustomers(search?: string, bySearch?: boolean) {
    //     this.loading = LoadingState.Loading;
    //     if (bySearch) {
    //         this.customersPage = 1;
    //         this.customersLoadMore = false;
    //     }

    //     return CustomersApi.searchCustomersDt(search, this.customersPage).then(
    //         ({ data }) => {
    //             if (data.length === PAGE_SIZE) {
    //                 this.customersPage += 1;
    //                 this.customersLoadMore = true;
    //             }
    //             this.loading = LoadingState.Loaded;
    //         },
    //         (err) => this.onLoadingError(err)
    //     );
    // }
}

export default new MessagesStore(SocketInstance);
