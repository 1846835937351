import React from 'react';
import RecipientItem from './RecipientItem';
import { ReactComponent as TagSvg } from '@src/theme/icons/tag-outlined-grey.svg';
import { ReactComponent as PeopleSvg } from '@src/theme/icons/people.svg';

import '../Broadcasts.sass';
import './RecipientsLists.sass';
import { Loader } from '@src/components/Loader/Loader';
import { observer } from 'mobx-react';
import { BroadcastReceipientsLocalStore, RecipientOption } from './BroadcastReceipientsLocalStore.store';

interface ListProps {
    headerIcon: React.ReactNode;
    title: string;
    recipients: RecipientOption[];
    onRemoveClick: (recipient: RecipientOption) => void;
}

const RecipientList: React.FunctionComponent<ListProps> = ({ headerIcon, title, recipients, onRemoveClick }) => (
    <div className="list">
        <div className="list-header">
            {headerIcon}<span>{title}</span>
        </div>
        <div className="list-content">
            {recipients.map((recipient, i) => <RecipientItem
                key={i}
                option={recipient}
                showRemoveButton
                onRemoveClick={() => onRemoveClick(recipient)}
            />)}
        </div>
    </div>
)

interface RecipientsProps {
    broadcastReceipientsLocalStore: BroadcastReceipientsLocalStore;
}

const RecipientsLists: React.FunctionComponent<RecipientsProps> = observer(({ broadcastReceipientsLocalStore }) => {
    const {recipients} = broadcastReceipientsLocalStore
    const tags = recipients.results.filter(recipient => recipient?.type === 'TAG');
    const customers = recipients.results.filter(recipient => recipient?.type === 'CUSTOMER');

    const loadMoreBtn = broadcastReceipientsLocalStore.recipients.moreAvailable && <button className={"load-more-btn"} onClick={() => broadcastReceipientsLocalStore.runRecipientsQuery()}>
        Load More
    </button>

    return (
        <div className="recipients-container">
            <header>
                Broadcast Recipients
            </header>
            {recipients.loading && <div className="loader-container"><Loader loading={recipients.loading}/></div>}
            {!recipients.loading &&
                <div className="content">
                    <div>
                        <RecipientList
                            headerIcon={<TagSvg viewBox="0 0 12 12"/>}
                            title={`${tags.length} Tag${tags.length !== 1 ? "s" : ""}`}
                            recipients={tags}
                            onRemoveClick={(recipient) => broadcastReceipientsLocalStore.removeRecipient(recipient)}
                        />
                        <RecipientList
                            headerIcon={<PeopleSvg/>}
                            title={`${customers.length} Individual${customers.length !== 1 ? "s" : ""}`}
                            recipients={customers}
                            onRemoveClick={(recipient) => broadcastReceipientsLocalStore.removeRecipient(recipient)}
                        />
                    </div>
                    {loadMoreBtn}
                </div>
            }
        </div>
    )
});

export default RecipientsLists;
