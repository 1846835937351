import React from 'react';
import cx from 'classnames';

import './progress-bar.sass';

interface Element {
    name?: string;
    value: number;
}

interface ProgressBarProps {
    data?: Element[];
    current?: number;
    total?: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = (props) => {
    let bars;

    if (props.data) {
        let sum = 0;
        const barsData = props.data
            .filter((d) => d.value > 0)
            .reduce((acc, curr) => {
                const newSum = sum + curr.value;
                acc.push({
                    ...curr,
                    start: sum,
                    end: newSum,
                    width: curr.value,
                });
                sum = newSum;
                return acc;
            }, [])
            .map((b) => {
                const n = 100 / sum;
                return {
                    ...b,
                    start: b.start * n,
                    end: b.end * n,
                    width: b.width * n,
                };
            });

        const shortBar = 20; //% of progressBar,
        const ultraShortBar = 6; //% od progressBar
        // if 6 < bar < 20 - hide bar name
        // if bar < 6 - show name and % under bar

        bars = barsData.map((bar, i) => (
            <div
                title={bar.name}
                key={`bar-${i}`}
                className={cx(
                    'ProgressBar__fill',
                    'fill',
                    { first: i === 0 },
                    { last: i === barsData.length - 1 },
                    { even: i % 2 === 0 },
                    { short: bar.width < ultraShortBar }
                )}
                style={{
                    left: `${bar.start}%`,
                    width: `${bar.width}%`,
                }}
            >
                {bar.name && (
                    <div className="ProgressBar__name current">
                        <span className="percent">{Math.round(bar.width * 100) / 100}%</span>
                        <span
                            className={cx({
                                hidden: bar.width < shortBar && bar.width >= ultraShortBar,
                            })}
                        >
                            {bar.name}
                        </span>
                    </div>
                )}
            </div>
        ));
    }

    if (props.current) {
        const fillWidthPercent: number = (props.current / props.total) * 100;
        const width: number = fillWidthPercent > 100 ? 100 : fillWidthPercent;

        const styleFill = {
            width: `${width}%`,
        };

        const fillClass = cx({
            ProgressBar__fill: true,
            fill: true,
            first: true,
            max: width == 100,
        });

        bars = <div className={fillClass} style={styleFill}></div>;
    }

    return <div className="ProgressBar">{bars}</div>;
};
