import React from 'react';
import { images } from '@src/theme/images';
import { useParams } from 'react-router-dom';
import { activateAccount } from '@src/requests/auth.requests';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormField } from '@src/components/FormField/FormField';
import classNames from 'classnames';

import '../Auth.sass';

export const ActivateAccount: React.FC = () => {
    const history = useHistory();
    const params = useParams() as {token: string};
    const { register, handleSubmit, errors, watch } = useForm();

    const submit = (data) => {
        activateAccount(params.token, data).then(
            () => {
                history.push('/dashboard');
                toast.success('Thank you, your account is activated.', { autoClose: 4000 });
            },
            (err: { response; message }) => {
                if (err.response.data && err.response.status == 409) {
                    toast.error('This activation link is incorrect or it has been used before.');
                } else {
                    toast.error(err.message);
                }
            }
        );
    };

    return (
        <div className="Auth">
            <div className="AuthLogo">
                <img src={images.logo} className="AuthLogo-image" alt="app-logo" />
            </div>
            <div className="AuthContainer short">
                <h1 className="AuthContainer__header">Activate account</h1>
                <h4>
                    Set your password to activate your account. It should be at least 8 characters.
                    Strong passwords include numbers, letters and punctuation marks.
                </h4>
                <form onSubmit={handleSubmit(submit)}>
                    <FormField label="Password" htmlFor="password" error={errors.password}>
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            className={classNames('form-input', { error: errors.password })}
                            ref={register({
                                required: 'Missing Password',
                                minLength: 8,
                            })}
                            autoComplete="new-password"
                        />
                    </FormField>
                    <FormField
                        label="Confirm Password"
                        htmlFor="passwordConfirm"
                        error={errors.passwordConfirm}
                    >
                        <input
                            type="password"
                            name="passwordConfirm"
                            placeholder="Confirm Password"
                            className={classNames('form-input', { error: errors.passwordConfirm })}
                            ref={register({
                                required: 'Missing Confirmation',
                                minLength: 8,
                                validate: (value) =>
                                    value === watch('password') ? true : 'Emails must be the same',
                            })}
                            autoComplete="new-password"
                        />
                    </FormField>
                    <div>
                        <input type="submit" className="btn-primary" value="Activate Account" />
                    </div>
                </form>
            </div>
        </div>
    );
};
