import React, { useState, useEffect } from 'react';
import { FormField } from '@src/components/FormField/FormField';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import { convertToPureNumber } from '@src/theme/utils/helpers';
import { ReactComponent as EyeOpen } from '@src/theme/icons/eye-open.svg';
import { ReactComponent as EyeClosed } from '@src/theme/icons/eye-closed.svg';
import { Address, countriesToSelect, statesOfUS, provincesOfCanada } from '@src/stores/models';
import { SignupForm } from '@src/stores/models/signup';
import { SignupSelect } from '@src/components';
import { trackGoogleEvent, SignupAnalyticsEvents } from '@src/theme/utils/Analytics';

import './Signup.sass';

export interface FormData {
    firstName: string;
    lastName: string;
    phone: string;
    password: string;
    address: Address;
};

interface AccountFormProps {
    previousData: SignupForm;
    dontCollectPassword: boolean;
    onNext: (data: FormData) => void;
    onBack: (data: FormData) => void;
};

export const AccountForm: React.FunctionComponent<AccountFormProps> = ({ previousData, dontCollectPassword, onNext, onBack }) => {
    const { register, handleSubmit, errors, setValue } = useForm();
    const [ isShowPwd, setIsShowPwd ] = useState(false);
    const [ selectedCountry, setSelectedCountry ] = useState(null);
    const [ selectedState, setSelectedState ] = useState(null);
    const [ currentData, setCurrentData ] = useState({firstName: null, lastName: null, phone: null, street: null, streetTwo: null, postalCode: null, city: null, password: null});

    useEffect(() => {
        setCurrentData({
            firstName: previousData?.firstName,
            lastName: previousData?.lastName,
            phone: previousData?.phone,
            street: previousData?.address?.street,
            streetTwo: previousData?.address?.streetTwo,
            postalCode: previousData?.address?.postalCode,
            city: previousData?.address?.city,
            password: previousData?.password,
        });
        setSelectedState(previousData?.address?.state);
        setSelectedCountry(previousData?.address?.country);
        setValue('phone', convertToPureNumber(previousData?.phone));
    }, [previousData]);

    const arrays = [countriesToSelect, statesOfUS, provincesOfCanada];
    const convertedArrays = arrays.map(arr => Object.keys(arr).map((el) => {
        return {
            label: arr[el],
            value: el,
        };
    }));
    const countries = convertedArrays[arrays.indexOf(countriesToSelect)];
    const statesUS = convertedArrays[arrays.indexOf(statesOfUS)];
    const provincesCA = convertedArrays[arrays.indexOf(provincesOfCanada)];

    const dataSubmitted = () => {
        trackGoogleEvent(SignupAnalyticsEvents.AccountInfo);
        const dto = {
            firstName: currentData.firstName,
            lastName: currentData.lastName,
            phone: currentData.phone,
            password: currentData.password,
            address: {
                street: currentData.street,
                streetTwo: currentData.streetTwo,
                city: currentData.city,
                state: selectedState,
                postalCode: currentData.postalCode,
                country: selectedCountry,
            }
        };
        onNext(dto);
    };

    const goBack = () => {
        const dto = {
            firstName: currentData.firstName,
            lastName: currentData.lastName,
            phone: currentData.phone,
            password: currentData.password,
            address: {
                street: currentData.street,
                streetTwo: currentData.streetTwo,
                city: currentData.city,
                state: selectedState,
                postalCode: currentData.postalCode,
                country: selectedCountry,
            }
        };
        onBack(dto);
    };

    return (
        <div className="step-card">
            <h2>You are almost there...</h2>
            <p className="subtitle AuthContainer__subtitle">{"Let's create some simple login details to access your new account"}</p>
            <form className="signup-step-form" onSubmit={handleSubmit(dataSubmitted)}>
                <div className="form-content">
                    <div className="form-row">
                        <FormField label="First name" htmlFor="firstName" error={errors.firstName}>
                            <input
                                type="text"
                                name="firstName"
                                placeholder="First name"
                                defaultValue={previousData?.firstName}
                                className={classNames('form-input', { error: errors.firstName })}
                                ref={register({
                                    required: 'Required',
                                })}
                                onChange={(e) => setCurrentData({...currentData, firstName: e.target.value})}
                            />
                        </FormField>
                        <FormField label="Last name" htmlFor="lastName" error={errors.lastName}>
                            <input
                                type="text"
                                name="lastName"
                                placeholder="Last name"
                                defaultValue={previousData?.lastName}
                                className={classNames('form-input', { error: errors.lastName })}
                                ref={register({
                                    required: 'Required',
                                })}
                                onChange={(e) => setCurrentData({...currentData, lastName: e.target.value})}
                            />
                        </FormField>
                    </div>
                    <FormField label="Mobile phone" htmlFor="phone" error={errors.phone}>
                        <InputMask
                            mask="(999) 999-9999"
                            name="phone"
                            placeholder="Mobile phone (required for verification)"
                            className={classNames('form-input', { error: errors.phone })}
                            defaultValue={previousData?.phone || ''}
                            onChange={(e) => setCurrentData({...currentData, phone: convertToPureNumber(e.target.value)})}
                            onBlur={(e) => setValue('phone', convertToPureNumber(e.target.value))}
                            inputRef={register(
                                { name: 'phone' },
                                {
                                    required: 'Required',
                                    validate: (phone) =>
                                        convertToPureNumber(phone).length == 10
                                            ? undefined
                                            : 'Provide valid number'
                                }
                            )}
                        />
                    </FormField>
                    <FormField label="Business Address" htmlFor="street" error={errors.street}>
                        <input
                            type="text"
                            name="street"
                            placeholder="Address"
                            defaultValue={previousData?.address?.street}
                            className={classNames('form-input', { error: errors.street })}
                            ref={register({
                                required: 'Required',
                            })}
                            onChange={(e) => setCurrentData({...currentData, street: e.target.value})}
                        />
                    </FormField>
                    <FormField label="Apt, suite, etc." htmlFor="streetTwo" error={errors.streetTwo}>
                        <input
                            type="text"
                            name="streetTwo"
                            placeholder="Apt, suite, etc. (optional)"
                            defaultValue={previousData?.address?.streetTwo}
                            className={classNames('form-input', { error: errors.streetTwo })}
                            onChange={(e) => setCurrentData({...currentData, streetTwo: e.target.value})}
                        />
                    </FormField>
                    <div className="form-row">
                        <FormField label="City" htmlFor="city" error={errors.city}>
                            <input
                                type="text"
                                name="city"
                                placeholder="City"
                                defaultValue={previousData?.address?.city}
                                className={classNames('form-input', { error: errors.city })}
                                ref={register({
                                    required: 'Required',
                                })}
                                onChange={(e) => setCurrentData({...currentData, city: e.target.value})}
                            />
                        </FormField>
                        <FormField key={'state' + selectedCountry} label={selectedCountry === 'CA' ? 'Province' : 'State'} htmlFor="state" error={errors.state}>
                            <SignupSelect
                                data-test="state"
                                key={selectedState || previousData?.address?.state || '1'}
                                options={selectedCountry === 'CA' ? provincesCA : statesUS}
                                selectedOptionValue={selectedState}
                                placeholder={selectedCountry === 'CA' ? 'Province' : 'State'}
                                onChange={(e) => setSelectedState(e.value)}
                                classname="onRight"
                            />
                        </FormField>
                    </div>
                    <div className="form-row">
                        <FormField label="Zipcode" htmlFor="postalCode" error={errors.postalCode}>
                            <input
                                type="text"
                                name="postalCode"
                                placeholder={selectedCountry === 'CA' ? 'K2C3G1' : '12345'}
                                defaultValue={previousData?.address?.postalCode}
                                className={classNames('form-input', { error: errors.postalCode })}
                                ref={register({
                                    required: 'Required',
                                    pattern: {
                                        value: selectedCountry === 'CA' ? /^[A-​Za-z0-9]+$/ : /^[0-9]+$/,
                                        message: 'Invalid zipcode',
                                    },
                                    minLength: {
                                        value: selectedCountry === 'CA' ? 6 : 5,
                                        message: 'Invalid zipcode'
                                    },
                                    maxLength: {
                                        value: selectedCountry === 'CA' ? 6 : 5,
                                        message: 'Invalid zipcode'
                                    }
                                })}
                                onChange={(e) => setCurrentData({...currentData, postalCode: e.target.value})}
                            />
                        </FormField>
                        <FormField label="Country" htmlFor="country" error={errors.country}>
                            <SignupSelect
                                data-test="country"
                                options={countries}
                                selectedOptionValue={selectedCountry}
                                placeholder="Country"
                                onChange={(e) => {
                                    if ((!selectedCountry && e.value === 'CA') || (selectedCountry && e.value !== selectedCountry)) {
                                        setSelectedState(undefined);
                                    };
                                    setSelectedCountry(e.value);
                                }}
                                classname="onLeft"
                            />
                        </FormField>
                    </div>
                    {((!previousData?.password || previousData?.password?.length < 8) && !dontCollectPassword) &&
                        <div className="password-field-container">
                            <h4>Create Password</h4>
                            <FormField label="Password" htmlFor="password" error={errors.password}>
                                <div className="password-field">
                                    <input
                                        type={isShowPwd ? "text" : "password"}
                                        name="password"
                                        placeholder="Minimum 8 characters"
                                        defaultValue={previousData?.password}
                                        className={classNames('form-input', { error: errors.password })}
                                        ref={(e) => {
                                            register(e, {
                                                required: 'Required',
                                                minLength: {
                                                    value: 8,
                                                    message: 'At least 8 characters long',
                                                },
                                            });
                                        }}
                                        autoComplete="new-password"
                                        onChange={(e) => setCurrentData({...currentData, password: e.target.value})}
                                    />
                                    <button type="button" onClick={() => setIsShowPwd(prevShowPwd => !prevShowPwd)}>
                                        {isShowPwd ? <EyeClosed/> : <EyeOpen/>}
                                    </button>
                                </div>
                            </FormField>
                        </div>
                    }
                </div>
                <div className="form-footer">
                    <button type="button" className="btn-tetriary-blue" onClick={() => goBack()}>Back</button>
                    <button
                        type="submit"
                        className="btn-primary-full nextfromaccount"
                        key="nextfromaccount"
                        disabled={
                            !currentData.firstName ||
                            !currentData.lastName ||
                            !currentData.phone ||
                            !currentData.street ||
                            !currentData.postalCode ||
                            !currentData.city ||
                            (!currentData.password && !previousData?.password && !dontCollectPassword) ||
                            !selectedState ||
                            !selectedCountry
                        }
                    >
                        Continue
                    </button>
                </div>
            </form>
        </div>
    );
};
