import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactDOM from 'react-dom';
import {LiPixel} from "@src/pages/Auth/SignUp/LiPixel";

export enum SignupAnalyticsEvents {
    FlowEntry = '1 - Flow entry',
    Email = '2 - Email',
    Google = '2 - Google',
    CompanyInfo = '3 - Company Info',
    AccountInfo = '4 - Account Info',
    NumberSelected = '5 - Number Selected',
    SignedUpNoCC = '6 - Signed Up (NO CC)',
    Payment = '6 - Payment'
};

export interface GtagProps {
    command: string;
    target: string;
    info?: {
        'event-category': string;
        'event_label': string;
        'send_to': string;
    }
};

export const gtagProps: GtagProps = {
    command: 'event',
    target: 'form_submit',
    info: {
        'event-category': 'signup',
        'event_label': '',
        'send_to': 'group1'
    }
};

const localOrQaUrls = ['localhost', 'qa.myopolis.com', 'aq.myopolis.com', 'local.myopolis.com'];

const isHereLocalOrQa = localOrQaUrls.some(el => el === window.location.hostname);

export const trackGoogleEvent = (event_label: SignupAnalyticsEvents, conversionSendTo?: {'send_to': string}) => {
    if (!isHereLocalOrQa) {
        try {
            const gtag = window["gtag"];
            if (gtag) {
                if (conversionSendTo) {
                    gtag('event', 'conversion', conversionSendTo);
                };

                gtag(gtagProps.command, gtagProps.target, {...gtagProps.info, event_label});
            };
        } catch(err) {
            console.log(err);
        };
    }
};

export const trackFacebookStandardEvent = (ev: string, val?: object) => {
    if (!isHereLocalOrQa) {
        try {
            ReactPixel.track(ev, val);
        } catch(err) {
            console.log(err);
        };
    };
};

export const trackFacebookCustomEvent = (ev: string, val: object) => {
    if (!isHereLocalOrQa) {
        try {
            ReactPixel.trackCustom(ev, val);
        } catch(err) {
            console.log(err);
        };
    };
};

export const setupLinkedinPixel = () => {
    if (!isHereLocalOrQa) {
        try {
            if (window["_env_"].GOOGLE_TRACKING_ID_2) {
                ReactDOM.render(<LiPixel/>, document.body.appendChild(document.createElement("DIV")));
            };
        } catch(err) {
            console.log(err);
        };
    };
};
