import React, { useState } from 'react';
import { images } from '@src/theme/images';
import { FormField } from '@src/components/FormField/FormField';
import { NavLink, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { forgotPwd, resetPwd } from '@src/requests/auth.requests';
import SignupStore from '@src/stores/signup.store';

import '../Auth.sass';

export const SendForgot: React.FC = () => {
    const re = /^(?:(?:[\w`~!#$%^&*\-=+;:{}'|,?\/]+(?:(?:\.(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)*"|[\w`~!#$%^&*\-=+;:{}'|,?\/]+))*\.[\w`~!#$%^&*\-=+;:{}'|,?\/]+)?)|(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d\-]+(?:\.[a-zA-Z\d\-]+)*|\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])$/;
    const { register, handleSubmit, errors } = useForm();
    const [error, setError] = useState(false);
    const [checkEmail, setCheckEmail] = useState(false);

    const forgot = (data) => {
        SignupStore.getUser().then(
            (res) => {
                if (res) {
                    location.reload();
                } else {
                    forgotPwd(data)
                    .catch(() => setError(true))
                    .then(
                        () => setCheckEmail(true),
                        () => setError(true)
                    );
                }
            },
            () => {
                forgotPwd(data)
                    .catch(() => setError(true))
                    .then(
                        () => setCheckEmail(true),
                        () => setError(true)
                    );
            }
        );
    };

    return (
        <>
            <h1 className="AuthContainer__header">Forgot pwd?</h1>
            <h4>
                Please enter your email address and we will send you an email with further
                instructions to reset your password.
            </h4>
            {checkEmail ? (
                <div className="forgot-confirmation">
                    <h3>
                        Instructions to reset your password have been sent to the email address you
                        provided.
                    </h3>
                </div>
            ) : (
                <form className="AuthContainer__form" onSubmit={handleSubmit(forgot)}>
                    {error && (
                        <div className="AuthContainer__form-error">
                            Something went wrong. Please try again.
                        </div>
                    )}
                    <FormField>
                        <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            className={classNames('form-input', { error: errors.email })}
                            ref={register({
                                required: 'Missing Email',
                                pattern: {
                                    value: re,
                                    message: 'Invalid email',
                                },
                            })}
                        />
                    </FormField>
                    <div>
                        <input type="submit" className="btn-primary" value="Send" />
                    </div>
                </form>
            )}
        </>
    );
};

export const Reset = (props) => {
    const { register, handleSubmit, errors, watch } = useForm();
    const [error, setError] = useState(false);
    const [relogin, setRelogin] = useState(false);

    const reset = (password) => {
        resetPwd({ token: props.token, password: password }).then(
            ({ data }) => setRelogin(data),
            () => setError(true)
        );
    };

    return (
        <>
            <h1 className="AuthContainer__header">Reset pwd</h1>
            {relogin ? (
                <div className="forgot-confirmation">
                    <h3>{relogin}</h3>
                </div>
            ) : (
                <form className="AuthContainer__form" onSubmit={handleSubmit(reset)}>
                    {error && (
                        <div className="AuthContainer__form-error">
                            Something went wrong. Please try again.
                        </div>
                    )}
                    <FormField label="Password" htmlFor="password" error={errors.password}>
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            className={classNames('form-input', { error: errors.password })}
                            ref={register({
                                required: 'Missing Password',
                                minLength: 8,
                            })}
                            autoComplete="new-password"
                        />
                    </FormField>
                    <FormField
                        label="Confirm Password"
                        htmlFor="passwordConfirm"
                        error={errors.passwordConfirm}
                    >
                        <input
                            type="password"
                            name="passwordConfirm"
                            placeholder="Confirm Password"
                            className={classNames('form-input', { error: errors.passwordConfirm })}
                            ref={register({
                                required: 'Missing Confirmation',
                                minLength: 8,
                                validate: (value) =>
                                    value === watch('password') ? true : 'Emails must be the same',
                            })}
                            autoComplete="new-password"
                        />
                    </FormField>
                    <div>
                        <input type="submit" className="btn-primary" value="Reset" />
                    </div>
                </form>
            )}
        </>
    );
};

export const Forgot: React.FC = () => {
    const params = useParams() as {token: string};

    return (
        <div className="Auth">
            <div className="AuthLogo">
                <img src={images.logo} className="AuthLogo-image" alt="app-logo" />
            </div>
            <div className="AuthContainer short">
                {params.token ? <Reset token={params.token} /> : <SendForgot />}
                <div>
                    Remember pwd? <NavLink to="/signin">Sign In Now</NavLink>
                </div>
            </div>
        </div>
    );
};
