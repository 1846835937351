import React from 'react';
import { images } from '@src/theme/images';
import { useParams } from 'react-router-dom';
import { confirmEmail } from '@src/requests/auth.requests';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import '../Auth.sass';

export const ConfirmEmail: React.FC = () => {
    const history = useHistory();
    const params = useParams() as {token: string};
    const confirm = () => {
        confirmEmail(params.token).then(
            () => {
                history.push('/dashboard');
                toast.success('Thank you, your email is confirmed.', { autoClose: 4000 });
            },
            (err) => toast.error(err?.message)
        );
    };

    return (
        <div className="Auth">
            <div className="AuthLogo">
                <img src={images.logo} className="AuthLogo-image" alt="app-logo" />
            </div>
            <div className="AuthContainer short">
                <h1 className="AuthContainer__header">Confirm email</h1>
                <h4 className="AuthContainer__confirmEmail">
                    Please, confirm your email by clicking button below.
                </h4>
                <div>
                    <button className="btn-primary" onClick={() => confirm()}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};
