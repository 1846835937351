import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { AsYouType } from 'libphonenumber-js';
import { onSuccess } from '@src/theme/utils/constants';
import {
    FilterBar,
    Loader,
    SearchInput,
    Dialog,
    ErrorToastSave,
    PopupHintsBubbleMessage,
    BlockingPopup,
    NewNumberAfterSignup
} from '@src/components';
import {
    Message,
    NewContactWrapper,
    ThreadList,
    ChatInfo,
    LeftMenu,
    FormViewType
} from './components';
import {
    STORES,
    WithCustomersStoreProps,
    WithCustomerStoreProps,
    WithMessagesStoreProps,
    withStores,
    WithTemplateStoreProps,
    WithTagStoreProps,
    WithSignupStoreProps,
    WithConversationsStoreProps,
    WithWalkthroughStoreProps
} from '@src/stores/with-store';
import { ThreadsStore } from '@src/stores/messages/threads.store';
import UserStore from '@src/stores/user.store';
import CustomerStore from '@src/stores/customer.store';
import { LoadingState, Customer, OnErrorThread } from '@src/stores/models';
import { ReactComponent as PlusIcon } from '@src/theme/icons/plus.svg';
import { ReactComponent as InfoIcon } from '@src/theme/icons/icon-info.svg';
import { firstPopupsStepsCompletedElBeforeBEData } from '@src/theme/utils/constants';
import { images } from './components/Message/images/images';
import LockedAccountDialog from '@src/components/Layout/LockedAccountDialog';
import { ERROR_TOAST_AUTOCLOSE } from '@src/theme/utils/constants';

import './Messages.sass';


const messagingHintsStepCompleted = 2;

type MessagesProps = WithMessagesStoreProps &
    WithCustomersStoreProps &
    WithCustomerStoreProps &
    WithTemplateStoreProps &
    WithConversationsStoreProps &
    WithTagStoreProps &
    WithSignupStoreProps &
    WithWalkthroughStoreProps &
    { ThreadsStore: ThreadsStore };

const MessagesWithStore: React.FC<MessagesProps> = (props) => {
    const MessagesStore = props.MessagesStore;
    const ThreadsStore = props.ThreadsStore;
    const ConversationsStore = props.ConversationsStore;
    const TagsStore = props.TagsStore;
    const SignupStore = props.SignupStore;
    const WalkthroughStore = props.WalkthroughStore;
    MessagesStore.onError = (message) => toast.error(message);
    const customersStore = props.CustomersStore;
    const history = useHistory();
    const params = useRouteMatch().params as any;
    const [expanded, setExpanded] = useState(true);
    const [showNotesDialog, setShowNotesDialog] = useState(false);
    const isFirstLoading = history.location.pathname === '/messages/' ? true : false;
    const [displayPopupHintsBtn, setDisplayPopupHintsBtn] = useState(
        MessagesStore.loading === LoadingState.Loaded &&
        WalkthroughStore.popupsStepsCompleted[0] !== firstPopupsStepsCompletedElBeforeBEData &&
        !Boolean(WalkthroughStore.popupsStepsCompleted.find((el) => el === messagingHintsStepCompleted)) &&
        history.location.pathname === '/messages/'
    );
    const [showTutorialPopupNumber, setShowTutorialPopupNumber] = useState(null);
    const [showSetPhonePopup, setShowSetPhonePopup] = useState(false);
    const [isFirstMessage, setIsFirstMessage] = useState(false);
    const [showCongratsDialog, setShowCongratsDialog] = useState(false);
    const [openDialogLimitHitForTrial, setOpenDialogLimitHitForTrial] = useState(false);
    const [openDialogLimitHitForMonth, setOpenDialogLimitHitForMonth] = useState(false);

    const calucateDisplayOnRight = () => {
        return history.location.pathname.split('/')[2] === 'd'
            ? 'teamDirect'
            : history.location.pathname.split('/')[2] === 'c'
            ? 'teamChannel'
            : (history.location.pathname.split('/')[2] === 't' || history.location.pathname.split('/')[2] === '?new')
            ? 'thread'
            : 'newMsg'
    }

    const [displayOnRight, setDisplayOnRight] = useState(calucateDisplayOnRight());

    const createNewThread = (customer) => {
        ThreadsStore
            .createNewThread(customer, true, 'addCustomer')
            .then(() => history.replace({ pathname: `/messages/?new` }));
    };

    const onErrorIncorrectPhone = () => {
        toast.error('Phone number is invalid. Please provide a valid 10 digit phone number', ERROR_TOAST_AUTOCLOSE);
    };
    const onErrorNoSelectedPhoneNumber = () => {
        toast.error(
            <div className="problemToast">
                Please <Link to={'/settings/phone-number'}>select your phone number</Link> before
                sending messages.
            </div>
        );
    };
    ThreadsStore.onErrorAddingCustomer = (type: string, data?: OnErrorThread) => {
        MessagesStore.loading = LoadingState.Error;
        switch (type) {
            case 'addCustomer': {
                toast.error(<ErrorToastSave repeatUploading={() => createNewThread(data)} />);
                break;
            }
            default: {
                return;
            }
        }
    };
    ThreadsStore.onSuccess = () => {
        ThreadsStore.loading = LoadingState.Loaded;
        onSuccess();
    };
    ThreadsStore.onErrorIncorrectPhone = () => {
        ThreadsStore.loading = LoadingState.Error;
        onErrorIncorrectPhone();
    };
    ThreadsStore.onErrorNoSelectedPhoneNumber = () => {
        ThreadsStore.loading = LoadingState.Error;
        onErrorNoSelectedPhoneNumber();
    };
    ThreadsStore.onErrorLimitHit = (limitType: "Trial" | "BillingPlan") => {
        ThreadsStore.loading = LoadingState.Error;
        if(limitType === "Trial") {
            setOpenDialogLimitHitForTrial(true);
        } else if(limitType === "BillingPlan") {
            setOpenDialogLimitHitForMonth(true);
        }
    };

    const selectThreadByThreadId = (id: string) => {
        ThreadsStore
            .selectThreadByThreadId(id)
            .then(() => history.replace({ pathname: `/messages/t/${id}` }));
    };

    const openThreadWithCustomer = (action: FormViewType, customer?: Customer) => {
        if (!customer) {
            return;
        }

        if (customer.threadId) {
            selectThreadByThreadId(customer.threadId);
        } else {
            createNewThread(customer);
        }
    };

    const noteAdded = () => {
        setShowNotesDialog(false);
        if (history.location.pathname.split('/')[1] !== 'messages') return;
        const threadId = history.location.pathname.split('/')[3];
        if (threadId && !params.lid && ThreadsStore.currentThread?.id !== threadId) {
            selectThreadByThreadId(threadId);
        }
    };

    const showCustomerEdition = (id: string) => {
        ThreadsStore
            .closeCurrentThread()
            .then(() => history.replace({ pathname: `/customers/edit/${id}` }));
    };

    const markTeamMsgAsRead = (selectedChannelId, selectedDirectId) => {
        const itemOnList =
            selectedChannelId ? ConversationsStore.ConversationsListStore.channelsList.find(el => el.conversationId === selectedChannelId) :
            selectedDirectId ? ConversationsStore.ConversationsListStore.directConversationsList.find(el => el.conversationId === selectedDirectId) : null;
        const isUnread = itemOnList?.lastupdate > itemOnList?.lastread;
        const isInMessages = history.location.pathname.split('/')[1] === 'messages';
        if (isUnread && isInMessages) {
            ConversationsStore.ConversationsListStore.markAsRead(selectedChannelId || selectedDirectId);
        }
    };

    const markCustomerMsgAsViewed = () => {
        const textMessages = ThreadsStore.currentThread?.messages && Object.values(ThreadsStore.currentThread?.messages).map(el => {
            return {id: el.message.id, updated: el.message.updated, sent: el.message.sent}
        });
        const voiceMessages = ThreadsStore.currentThread?.voiceMail && Object.values(ThreadsStore.currentThread?.voiceMail).map(el => {
            return {id: el.id, updated: el.updated, sent: false}
        });
        const allMessages = textMessages.concat(voiceMessages);
        const dates = allMessages && allMessages.map((m) => m.updated);
        const lastMessageDate = dates && dates.length && dates.reduce((a, b) => (a > b ? a : b));
        const lastMessage = lastMessageDate && allMessages.find((m) => m.updated == lastMessageDate);
        const lastMessageSendByCustomer = !lastMessage?.sent;
        const lastMessageId = lastMessage?.id;
        if (
            lastMessageId
            // && lastMessageSendByCustomer
            && ThreadsStore.currentThread.receipts &&
            (!ThreadsStore.currentThread.receipts[lastMessageId] || !Object.values(ThreadsStore.currentThread.receipts[lastMessageId])?.find(el => el.employeeId === UserStore?.user?.employee?.id))
        ) {
            ThreadsStore.markAsViewed(ThreadsStore.currentThread.id, lastMessageId);
        };
    };

    const windowClicked = () => {
        const selectedChannelId = ConversationsStore.SelectedChannelStore?.selectedChannel?.id;
        const selectedDirectId = ConversationsStore.SelectedDirectConversationStore?.selectedDirect?.id;
        const selectedThreadId = MessagesStore.ThreadsStore?.currentThread?.id;
        if (selectedChannelId || selectedDirectId) {
            markTeamMsgAsRead(selectedChannelId, selectedDirectId);
        } else if (selectedThreadId) {
            markCustomerMsgAsViewed();
        };
    };

    const fetchDataAgain = () => {
        const dto = {type: params.tid, id: params.lid, isFirstLoading, isCallFromMessages: true};
        MessagesStore.loadMessagesConstants(dto);
        ConversationsStore.removeIncomingMessage();
    };

    let countdownToRealod;
    let timeToReloadPassed = false;

    const userLeft = () => {
        countdownToRealod = setTimeout(() => {
            timeToReloadPassed = true;
        }, 300000);
    };

    const userIsBack = () => {
        if (timeToReloadPassed) {
            fetchDataAgain();
            console.log(ConversationsStore.SelectedChannelStore.selectedChannel?.id);
            timeToReloadPassed = false;
        };
        clearTimeout(countdownToRealod);
    };

    useLayoutEffect(() => {
        MessagesStore.init();
        // ThreadsStore.init();
        const dto = {type: params.tid, id: params.lid, isFirstLoading, isCallFromMessages: true};
        MessagesStore.loadMessagesConstants(dto);
        ConversationsStore.removeIncomingMessage();

        setShowSetPhonePopup(Boolean(!UserStore?.user?.phone));

        document.getElementById('app').addEventListener("click", windowClicked);

        window.addEventListener("focus", userIsBack);
        window.addEventListener("blur", userLeft);
        window.addEventListener("online", userIsBack);
        window.addEventListener("offline", userLeft);

        return(() => {
            localStorage.removeItem('messagingSearchData');
        });
    }, []);

    const closeThread = () => {
        Promise.all([
            ThreadsStore.closeCurrentThread(),
            ConversationsStore.SelectedChannelStore.closeConversation(),
            ConversationsStore.SelectedDirectConversationStore.closeConversation()
        ]).then(() => history.replace({ pathname: '/messages/' }));
    };

    // useEffect(() => {
    // 	const unlisten = history.listen(res => {
    // 		if (res.pathname.split('/')[1] !== 'messages') return;
    // 		const threadId = res.pathname.split('/')[2];
    // 		if (threadId && threadId !== 'b' && !params.lid && ThreadsStore.currentThread?.id !== threadId) {
    // 			selectThreadByThreadId(threadId);
    // 		}
    // 	})
    // 	return () => {
    // 		unlisten && unlisten();
    // 	};
    // }, []);

    useEffect(() => {
        if (history.location.pathname === '/messages/') {
            ConversationsStore.SelectedChannelStore.setSelectedChannel(null);
            ConversationsStore.SelectedDirectConversationStore.setSelectedDirect(null);
        };
        if (history.location.pathname !== '/messages/?new') {
            CustomerStore.closeCustomer();
        };
        setDisplayOnRight(calucateDisplayOnRight());

        if (history.location.pathname.split('/')[1] !== 'messages') return;

        const threadType = history.location.pathname.split('/')[2];
        const threadId = history.location.pathname.split('/')[3];
        if (
            threadId &&
            threadType == 't' &&
            // !params.lid &&
            ThreadsStore.currentThread?.id !== threadId
        ) {
            selectThreadByThreadId(threadId);
        };
    }, [history.location.pathname]);

    const searchThreads = () => {
        ThreadsStore.searchThreads({ open: false });
    };

    useEffect(() => {
        if (ThreadsStore.currentThread?.customer?.phone) setDisplayOnRight('thread');
        if (ThreadsStore.currentThread?.id) {
            MessagesStore.loading = LoadingState.Loaded;
            if (history.location.pathname === "/messages/?new") {
                history.replace({ pathname: `/messages/` });
                if (isFirstMessage) {
                    setShowCongratsDialog(true);
                    setIsFirstMessage(false);
                };
                searchThreads();
                setTimeout(() => {
                    history.replace({ pathname: `/messages/t/${ThreadsStore.currentThread?.id}` });
                }, 10)
            } else {
                history.replace({ pathname: `/messages/t/${ThreadsStore.currentThread?.id}` });
                markCustomerMsgAsViewed();
            };
        }
    }, [ThreadsStore.currentThread?.id]);

    useEffect(() => {
        if (ConversationsStore.SelectedChannelStore.userWasRemovedFromChannel) {
            history.replace({ pathname: `/messages/` });
            ConversationsStore.SelectedChannelStore.setUserWasRemovedFromChannel(false);
        }
    }, [ConversationsStore.SelectedChannelStore.userWasRemovedFromChannel]);

    useEffect(() => {
        if (MessagesStore.loading === LoadingState.Loaded &&
            WalkthroughStore.popupsStepsCompleted[0] !== firstPopupsStepsCompletedElBeforeBEData &&
            !Boolean(WalkthroughStore.popupsStepsCompleted.find((el) => el === messagingHintsStepCompleted)) &&
            history.location.pathname === '/messages/'
        ) {
            setDisplayPopupHintsBtn(true);
        } else {
            setDisplayPopupHintsBtn(false);
        };
    }, [MessagesStore.loading, WalkthroughStore.popupsStepsCompleted, history.location.pathname]);

    const closeTutorial = () => {
        setShowTutorialPopupNumber(null);
    };

    const closeAndCompleteTutorial = () => {
        setShowTutorialPopupNumber(null);
        WalkthroughStore.markHintPopupStepAsRead(messagingHintsStepCompleted)
        .then(() => {
            WalkthroughStore.setPopupStepsCompleted([...WalkthroughStore.popupsStepsCompleted, messagingHintsStepCompleted]);
        });
    };

    const onNewNumber = (phoneNumber) => {
        SignupStore.buyPhoneNumber(phoneNumber, 'buyPhoneNumber')
        .then(val => {
            if (val) {
                SignupStore.setIsPhoneSet(true);
                setShowSetPhonePopup(false);
            };
        });
    };

    const loader = (
        <Loader
            loading={
                MessagesStore.loading === LoadingState.Loading ||
                TagsStore.TagsListStore.loading === LoadingState.Loading
            }
        />
    );

    const searchInput = (
        <SearchInput
            people={customersStore.customers}
            clearSearchText={{
                tags: ThreadsStore.tags?.length,
                feed: ThreadsStore.feed,
                open: ThreadsStore.open,
            }}
            onSearchSubmit={(s) => ThreadsStore.searchThreads(s.open ? {open: s.open, search: s.search} : {search: s.search})}
            onCustomerSendMsg={(action: FormViewType, customer: Customer) =>
                openThreadWithCustomer(action, customer)
            }
            onSearchTextChange={(search) => customersStore.searchCustomers(search)}
        />
    );

    const newMsgBtn = history.location.pathname.split('/')[3] && (
        <button onClick={() => closeThread()}>
            <PlusIcon />
            <span>New message</span>
        </button>
    );

    const searchAndNewMsgHeader = (
        <div
            className={classNames('Messages__search', {
                expanded: expanded,
                selected: !ThreadsStore.currentThread,
            })}
        >
            <div className={classNames("Messages__search-searchInput", {highlighting: showTutorialPopupNumber === 5 })}>{searchInput}</div>
            <div className="Messages__search-spacer" />
            <div className="Messages__search-newMessage">{newMsgBtn}</div>
        </div>
    );

    const newMsg = displayOnRight === 'newMsg' && (
        <div className={classNames("Messages__views-formView", {popupHintsHighlightedArea: showTutorialPopupNumber === 4, shorter: UserStore.trialBanner.show })}>
            {showTutorialPopupNumber === 4 ?
                <PopupHintsBubbleMessage
                    hint='hint4'
                    title="New Messages & Contacts"
                    number={4}
                    copy={[{content: 'Send a message or add a contact quickly from the right panel.'}]}
                    closeTutorial={() => closeTutorial()}
                    goToView={(n) => setShowTutorialPopupNumber(n)}
                />
            : null}
            <div className={classNames("Messages__views-formView-container", {popupHintsHighlightedAreaContainer: showTutorialPopupNumber === 4 })}>
                <NewContactWrapper
                    openThreadWithCustomer={(action: FormViewType, customer: Customer) =>
                        openThreadWithCustomer(action, customer)
                    }
                    isFirstMessageChanged={(isFirstMessage: boolean) => setIsFirstMessage(isFirstMessage)}
                />
            </div>
        </div>
    );

    const threadConversation = displayOnRight === 'thread' && (
        <div className={classNames('Messages__views-messageView', { infoHidden: expanded })}>
            <Message
                expanded={expanded}
                changeExpanded={() => setExpanded(!expanded)}
                type="customer"
                key={ThreadsStore.currentThread?.id}
            />
        </div>
    );

    const threadInfo = displayOnRight === 'thread' && (
        <div className={classNames('Messages__views-infoView', { hide: expanded })}>
            <ChatInfo
                type="customer"
                customer={ThreadsStore.currentThread?.customer}
                notes={CustomerStore?.customerData?.customer.notes || ThreadsStore?.currentThread?.notes}
                tags={CustomerStore?.customerData?.tags || ThreadsStore?.currentThread?.tags}
                ffBrowserCallingEnabled={MessagesStore.ffBrowserCallingEnabled}
                showNotes={() => setShowNotesDialog(true)}
                showCustomerEdition={(id) => showCustomerEdition(id)}
            />
        </div>
    );

    const teamDirectConversation = displayOnRight === 'teamDirect' && (
        <div className={classNames('Messages__views-messageView', { infoHidden: expanded })}>
            <Message
                expanded={expanded}
                changeExpanded={() => setExpanded(!expanded)}
                type="teamDirect"
                key={ConversationsStore.SelectedDirectConversationStore?.selectedDirect?.id}
            />
        </div>
    );

    const teamDirectInfo = displayOnRight === 'teamDirect' && (
        <div className={classNames('Messages__views-infoView', { hide: expanded })}>
            {<ChatInfo type="teamDirect" />}
        </div>
    );

    const teamChannelConversation = displayOnRight === 'teamChannel' && (
        <div className={classNames('Messages__views-messageView', { infoHidden: expanded })}>
            <Message
                expanded={expanded}
                changeExpanded={() => setExpanded(!expanded)}
                type="teamChannel"
                key={ConversationsStore.SelectedChannelStore?.selectedChannel?.id}
            />
        </div>
    );

    const teamChannelInfo = displayOnRight === 'teamChannel' && (
        <div className={classNames('Messages__views-infoView', { hide: expanded })}>
            {<ChatInfo type="teamChannel" />}
        </div>
    );

    const views = (
        <div className="Messages__views">
            <div className={classNames('Messages__views-filtersView', { expanded: expanded })}>
                <FilterBar
                    expanded={expanded}
                    expandedChange={(val) => setExpanded(val)}
                    showingPopupHints={Boolean(showTutorialPopupNumber === 1 || showTutorialPopupNumber === 2 || showTutorialPopupNumber === 3)}
                >
                    <LeftMenu
                        tutorialNumber={showTutorialPopupNumber}
                        changeTutorialDisplaying={(n) => setShowTutorialPopupNumber(n)}
                        closeTutorial={() => closeTutorial()}
                    />
                </FilterBar>
            </div>
            <div className={classNames("Messages__views-threadsListView", {popupHintsHighlightedArea: showTutorialPopupNumber === 5 })}>
                {showTutorialPopupNumber === 5 ?
                    <PopupHintsBubbleMessage
                        hint='hint5'
                        title="Messages"
                        number={5}
                        copy={[{content: 'Your conversations will be displayed here on the left. They are sorted by Most recent. Once you close a conversation is always accessible under the closed tab.'}]}
                        closeTutorial={() => closeAndCompleteTutorial()}
                        goToView={(n) => setShowTutorialPopupNumber(n)}
                    />
                : null}
                <div className={classNames("Messages__views-threadsListView-container", {popupHintsHighlightedAreaContainer: showTutorialPopupNumber === 5 })}>
                    <ThreadList toBeMarkAsViewed={() => windowClicked()} />
                </div>
            </div>
            {newMsg}
            {threadConversation}
            {threadInfo}
            {teamDirectConversation}
            {teamDirectInfo}
            {teamChannelConversation}
            {teamChannelInfo}
        </div>
    );

    const content = MessagesStore.loading !== LoadingState.Init && MessagesStore.loading !== LoadingState.FirstLoading && (
        <div>
            {searchAndNewMsgHeader}
            {views}
        </div>
    );

    const popupHintsQuestionButton = displayPopupHintsBtn && (
        <button
            className="popupHintsQuestionButton btn-primary"
            onClick={() => {
                setShowTutorialPopupNumber(1);
            }}
        >
            <InfoIcon />
            <span>Take a tour of our Messaging Features</span>
        </button>
    );

    const greyingOut = showTutorialPopupNumber && <div className='greyingOut' />;

    const phonePopup = showSetPhonePopup &&
        <BlockingPopup>
            <NewNumberAfterSignup
                newNumber={onNewNumber}
                view="messages"
                isNotOwnerOrLeader={UserStore?.user?.employee?.role === 2}
            />
        </BlockingPopup>

    const dialogNotes = (
        <Dialog
            className="notesOnly"
            title={`Team Notes of ${
                ThreadsStore.currentThread?.customer?.firstName ||
                new AsYouType('US').input(
                    ('' + ThreadsStore.currentThread?.customer?.phone).replace(/\D/g, '')
                )
            } ${ThreadsStore.currentThread?.customer?.lastName || ''}`}
            onClose={() => setShowNotesDialog(false)}
            open={showNotesDialog}
        >
            <Message
                type="notes"
                closeDialog={() => noteAdded()}
            />
        </Dialog>
    );

    const dialogCongrats = (
        <Dialog
            className="FirstMsgSentCongrats"
            open={showCongratsDialog}
            onClose={() => setShowCongratsDialog(false)}
        >
            <div className="FirstMsgSentCongrats__content">
                <div className='FirstMsgSentCongrats__content-imagewrapper'>
                    <img className='image' src={images.highfive} alt="highfive" />
                </div>
                <div className="FirstMsgSentCongrats__content-text">
                    <div className='title'>Congratulations!</div>
                    <div className='desc'>You sent your first message! Can you believe it’s that easy!!!</div>
                </div>
            </div>
            <div className="FirstMsgSentCongrats__action">
                <button className="btn-primary biggerThanNormal" onClick={() => setShowCongratsDialog(false)}>Done</button>
            </div>
        </Dialog>
    )

    return (
        <div className="Messages">
            {greyingOut}
            {phonePopup}
            {popupHintsQuestionButton}
            {loader}
            {content}
            {dialogNotes}
            {dialogCongrats}
            {openDialogLimitHitForTrial &&
                <LockedAccountDialog
                    warningCase={'limitHitForTrial'}
                    onClose={() => setOpenDialogLimitHitForTrial(false)}
                />
            }
            {openDialogLimitHitForMonth &&
                <LockedAccountDialog
                    warningCase={'limitHitForMonth'}
                    onClose={() => setOpenDialogLimitHitForMonth(false)}
                />
            }
        </div>
    );
};

export const Messages = withStores(MessagesWithStore, [
    STORES.Customers,
    STORES.Messages,
    STORES.Template,
    STORES.ThreadsStore,
    STORES.ConversationsStore,
    STORES.Tags,
    STORES.Signup,
    STORES.Walkthrough
]);
