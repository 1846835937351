import React, { useState, useMemo, useEffect, useRef } from 'react';
import { WithUserStoreProps, WithAuthStoreProps, WithSignupStoreProps, STORES, withStores } from '@src/stores/with-store';
import { postLogin } from '@src/requests/auth.requests';
import { images } from '@src/theme/images';
import { Dialog, Loader, FormField } from '@src/components';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import ReactPixel from 'react-facebook-pixel';
import { EmailForm, FormData as EmailFormData } from './Email';
import { CompanyForm, FormData as CompanyFormData } from './Company';
import { PhoneSelection } from './Phone';
import { CCSetup } from './CC';
import { GridLayout } from './GridLayout';
import { Steps } from './Steps';
import { GoToLogin } from './GoToLogin';
import { AccountForm } from './Account';
import { SignupForm } from '@src/stores/models/signup';
import { useHistory } from 'react-router-dom';
import { trackGoogleEvent, SignupAnalyticsEvents, setupLinkedinPixel, trackFacebookStandardEvent, trackFacebookCustomEvent } from '@src/theme/utils/Analytics';
import { convertToPureNumber, getBillingPlanAndPriceByPriceId, getDefaultPricePlan } from '@src/theme/utils/helpers';
import { REDIRECT_BACK_TO_LOCALSTORAGE_KEY } from '@src/theme/utils/constants';
import { SingleBillingPlan } from '@src/stores/models';
import { PhoneVerificationForm } from '@src/components/PhoneVerificationForm/PhoneVerificationForm';
import { ERROR_TOAST_AUTOCLOSE, gtagConversionSendTo } from '@src/theme/utils/constants';
import { calculateForwardedCampaignParams } from '../SignUp2/Main/ForwardedCampaign';

import './Signup.sass';

type props = WithUserStoreProps & WithAuthStoreProps & WithSignupStoreProps;

export type SignupStep = 'email' | 'company' | 'account' | 'phoneVerification' | 'twilioPhone' | 'cc';

export const steps: SignupStep[] = [
    'email',
    'company',
    'account',
    'phoneVerification',
    'twilioPhone',
    'cc'
];

export const stepsToDisplay: SignupStep[] = [
    'company',
    'account',
    'twilioPhone',
];

export const SignUpWithStore: React.FC<props> = ({ SignupStore, ...props}) => {
    const [step, setStep] = useState<SignupStep>('email');
    const [formData, setFormData] = useState<SignupForm>();
    const [selectedPhone, setSelectedPhone] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState<SingleBillingPlan>(null);
    const [selectedBuyNow, setSelectedBuyNow] = useState<boolean>(true);
    const [fbPixelNotSentYet, setFbPixelNotSentYet] = useState<boolean>(true);
    const [blockChangingEmail, setBlockChangingEmail] = useState<boolean>(false);
    const [isExternalAuth, setIsExternalAuth] = useState<boolean>(false);
    const forceCollectingCCInfo = false;
    const [dialogOpen, setDialogOpen] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const [error, setError] = useState(false);
    const [emailOfUnfinishedSignup, setEmailOfUnfinishedSignup] = useState('');
    const [planSelectedyByUser, setPlanSelectedyByUser] = useState<{priceId: string, buyNow: boolean}>(null);
    const passwordRef = useRef<HTMLInputElement>();
    const [phoneVerified, setPhoneVerified] = useState(false);

    SignupStore.onError = (undefined, message?: string) => {
        if (message) {
            toast.error(message);
        } else {
            toast.error('Something went wrong, please try again later.', ERROR_TOAST_AUTOCLOSE);
        }
    };

    SignupStore.onSuccess = (type: string) => {
        switch (type) {
            case 'buyPhoneNumber': {
                toast.success('Your phone number has been set up!', { autoClose: 4000 });
                break;
            }
            default: {
                return;
            }
        }
    };

    const history = useHistory();
    const urlSearch = history.location.search;

    const resumingSignup = useMemo(() => {
        return Boolean(SignupStore.user);
    }, [SignupStore.user]);

    const userFromBE = useMemo(() => {
        const data: SignupForm = !SignupStore.user ? null :
            {
                companyName: SignupStore.user.companyInfo.companyName,
                industry: SignupStore.user.companyInfo.industry,
                role: SignupStore.user.companyInfo.userRoleInCompany,
                companySize: SignupStore.user.companyInfo.companySize,
                address: SignupStore.user.companyInfo.address,
                firstName: SignupStore.user.user.firstName,
                lastName: SignupStore.user.user.lastName,
                phone: SignupStore.user.user.phone,
                email: SignupStore.user.user.email,
                password: formData?.password,
                timeZone: SignupStore.user.user.timeZone
            };
        setFormData(data);
        if (SignupStore?.user?.user?.email) {
            setBlockChangingEmail(true);
            setStep('company');
        }
        return data;
    }, [SignupStore.user]);

    const loadInitial = () => {
        SignupStore.getPlans();
        SignupStore.getUser();
    };

    useEffect(() => {
        loadInitial();
    }, []);

    const initReactPixel = () => {
        if (selectedPlan && fbPixelNotSentYet && !SignupStore.isReactPixelInitialized) {
            ReactPixel.init('794194067960553', null, { autoConfig: true, debug: false });
            SignupStore.setIsReactPixelInitialized(true);
        };
    };

    const fireReactPixelViewContent = () => {
        const previousFire = JSON.parse(localStorage.getItem('reactPixelViewContentFireTime'));
        const diff = previousFire && (Math.abs((new Date(previousFire) as any) - (new Date() as any))/1000)/60;
        if (!previousFire || diff > 5) {
            localStorage.setItem('reactPixelViewContentFireTime', JSON.stringify(new Date()))
            trackFacebookStandardEvent('ViewContent');
        };
    };

    useEffect(() => {
        initReactPixel();
        fireReactPixelViewContent();
    }, [selectedPlan]);

    useEffect(() => {
        const priceIdFromUrlOrSelectedPreviouslyOrHardcoded =
            planSelectedyByUser?.priceId ||
            urlSearch.split('?').find(el => el.startsWith('id'))?.split('=')[1] ||
            SignupStore.user?.companyInfo?.priceId ||
            SignupStore.plans && getDefaultPricePlan(SignupStore.plans.map(el => el.billingPlan)).id;

        const planFromUrlOrSelectedPreviouslyOrHardcoded = getBillingPlanAndPriceByPriceId(SignupStore.plans, priceIdFromUrlOrSelectedPreviouslyOrHardcoded);
        const planFirstFromList = SignupStore.plans?.length && {billingPlan: SignupStore.plans[0].billingPlan.billingPlan, price: SignupStore.plans[0].billingPlan.prices[0]};
        setSelectedPlan(planFromUrlOrSelectedPreviouslyOrHardcoded || planFirstFromList);

        const URLvalue = urlSearch.split('?').find(el => el.startsWith('type'))?.split('=')[1];
        const fromURL = URLvalue == "2" ? true : URLvalue == "1" ? false : undefined;
        const fromBE = SignupStore.user?.companyInfo?.buyNow;
        const buyNow = () => {
            if (planSelectedyByUser) {
                return planSelectedyByUser.buyNow;
            } else if (fromURL === true) {
                return true;
            } else if (fromURL === false) {
                return false;
            } else if (fromBE === true) {
                return true;
            } else if (fromBE === false) {
                return false;
            } else {
                return false;
            }
        };
        setSelectedBuyNow(buyNow());
    }, [urlSearch, SignupStore.user?.companyInfo, SignupStore.plans, planSelectedyByUser]);

    const handleEmailSubmit = (data: EmailFormData) => {
        const forwardedCampaignParams = calculateForwardedCampaignParams(window.location.search)

        SignupStore.validateEmail(data.email, forwardedCampaignParams)
        .then(val => {
            if (val === 'success') {
                setFormData({...formData, ...data});
                setStep('company');
                trackGoogleEvent(SignupAnalyticsEvents.Email);
                trackFacebookCustomEvent('Lead', {value: selectedPlan?.price.amount});
            } else if (val === 'accountAlreadyExists') {
                setEmailOfUnfinishedSignup(data.email);
                setError(false);
                setDialogOpen(true);
                passwordRef.current.focus();
            }
        });
    };

    const googleAuthorize = () => {
        SignupStore.googleAuthorize();
    };

    const googleAuthorizationSuccessOrRequestCompleteSignup = () => {
        if (step === 'email') {
            setStep('company');
            setBlockChangingEmail(true);
        }
        setIsExternalAuth(true);
        trackGoogleEvent(SignupAnalyticsEvents.Google);
    };

    useEffect(() => {
        if (SignupStore.googleAuthorization === 'success' || SignupStore.googleAuthorization === 'requestCompleteSignup') {
            googleAuthorizationSuccessOrRequestCompleteSignup();
        } else if (SignupStore.googleAuthorization === 'accountAlreadyCreated') {
            history.push('/dashboard');
        } else if (SignupStore.googleAuthorization === 'fail') {
            toast.error('Something went wrong. Please try again.', ERROR_TOAST_AUTOCLOSE);
        }
    }, [SignupStore.googleAuthorization]);

    const handleCompanySubmit = (data: CompanyFormData) => {
        setFormData({...formData, ...data});
        setStep('account');
    };

    const handleGoBackFromCompany = (data: CompanyFormData) => {
        setFormData({...formData, ...data});
        setStep('email');
    };

    const handleAccountSubmit = (data) => {
        let wasPhoneVerified = phoneVerified;
        if (data.phone !== formData.phone) {
            wasPhoneVerified = false;
            setPhoneVerified(false);
        }

        const proviouslyProvidedPassword = formData.password;
        const dto: SignupForm = {...formData, ...data};
        if (!dto.password) {
            dto.password = proviouslyProvidedPassword;
        }
        dto.phone = convertToPureNumber(dto.phone);
        setFormData(dto);
        if (isExternalAuth) {
            SignupStore.postAccountDetailsWithGoogle({...dto, priceId: selectedPlan?.price.id, buyNow: selectedBuyNow})
            .then((val) => {
                if (val === 'ok') {
                    setStep(wasPhoneVerified ? 'twilioPhone' : 'phoneVerification');
                    setBlockChangingEmail(true);
                }
            });
        } else {
            SignupStore.createOrUpdateAccount({...dto, priceId: selectedPlan?.price.id, buyNow: selectedBuyNow}, Boolean(userFromBE))
            .then((val) => {
                if (val === 'ok') {
                    setStep(wasPhoneVerified ? 'twilioPhone' : 'phoneVerification');
                    setBlockChangingEmail(true);
                } else if (val === 'emailAlreadyExists') {
                    setTimeout(() => setStep('email'), 100);
                }
            });
        }
    };

    const handleGoBackFromAccount = (data) => {
        if (data.phone !== formData.phone) {
            setPhoneVerified(false);
        }
        setFormData({...formData, ...data});
        setStep('company');
    };

    const handlePhoneVerificationSubmit = () => {
        setPhoneVerified(true);
        setStep('twilioPhone');
    };

    const payOrFinalize = (): void => {
        if (selectedBuyNow || forceCollectingCCInfo || !selectedPlan?.billingPlan.trialDays || selectedPlan?.billingPlan.trialDays < 1) {
            setStep('cc');
        } else {
            SignupStore.setBillingForm({
                priceId: selectedPlan?.price.id,
                // buyNow: selectedPlan?.buyNow
            }, {})
            .then((val) => {
                if (val) {
                    props.AuthStore.loadAuthLogin().then(() => {
                        history.push('/dashboard');
                        props.UserStore.setSignupUser(null);
                    });

                    trackGoogleEvent(SignupAnalyticsEvents.SignedUpNoCC, gtagConversionSendTo);
                    trackFacebookStandardEvent('StartTrial', {value: selectedPlan?.price.amount, currency: 'USD', predicted_ltv: selectedPlan?.price.amount});
                    setupLinkedinPixel();
                }
            }),
            () => {
                toast.error('Something went wrong. Please try again.', ERROR_TOAST_AUTOCLOSE);
            }
        }
    };

    const handlePhoneSelection = (selectedPhone: string) => {
        trackGoogleEvent(SignupAnalyticsEvents.NumberSelected);
        setSelectedPhone(selectedPhone);
        SignupStore.setPhoneNumber(selectedPhone)
        .then((val) => {
            if (val) {
                payOrFinalize();
            }
        });
    };

    const goToLogin = () => {
        if (SignupStore.user) {
            props.AuthStore.logout()
            .then(() => {
                props.AuthStore.loadAuth();
                history.push("/signin");
            })
            .catch(() => {
                toast.error("Please check your internet connection, or try again later.")
            });
        } else {
            history.push('/signin');
        }
    };

    const signIn = (password) => {
        if (error) setError(false);

        const loginData = {email: emailOfUnfinishedSignup, ...password, rememberMe: false};

        postLogin(loginData).then(
            () => {
                props.AuthStore.loadAuthLogin().then((val) => {
                    if (val === 'successIncomplete') {
                        setStep('company');
                        setDialogOpen(false);
                        SignupStore.getUser();
                    } else if (val === 'success') {
                        const redirectBackTo = localStorage.getItem(REDIRECT_BACK_TO_LOCALSTORAGE_KEY);
                        const redirectPath = redirectBackTo && redirectBackTo || '/dashboard';
                        history.push(redirectPath);
                        localStorage.removeItem(REDIRECT_BACK_TO_LOCALSTORAGE_KEY);
                    }
                });
            },
            () => {
                setError(true);
                passwordRef.current.focus();
            }
        );
    };

    return (
        <div className="signup">
            <Loader loadingState={SignupStore.loadingState} />
            <div className="signup__header">
                <img src={images.logo} className="logo"
                    // onClick={() => setStep('cc')}
                />
            </div>
            <div className={classNames('signup__main', { overflowYScroll: step === 'twilioPhone' })}>
                {step === 'email' &&
                    <EmailForm
                        previousData={formData}
                        onNext={handleEmailSubmit}
                        googleAuthorize={googleAuthorize}
                    />
                }
                {['company', 'account', 'phoneVerification', 'twilioPhone', 'cc'].includes(step) &&
                    <GridLayout
                        rightAsideContent={
                            <div className="selectedPlan">
                                <div className="selectedPlan__yourPlan">YOUR PLAN</div>
                                <div className="selectedPlan__planName">{selectedPlan?.billingPlan.name}</div>
                                {(selectedPlan?.billingPlan.trialDays && selectedPlan?.billingPlan.trialDays > 0 && !selectedBuyNow) &&
                                    <div className="selectedPlan__freeTrial">{selectedPlan?.billingPlan.trialDays}-day <span>FREE</span> trial</div>
                                }
                            </div>
                        }
                    >
                        {['company', 'account', 'phoneVerification', 'twilioPhone'].includes(step) && <Steps current={step === 'phoneVerification' ? 'account' : step}/>}
                        {step === 'company' &&
                            <CompanyForm
                                previousData={formData}
                                onNext={handleCompanySubmit}
                                onBack={handleGoBackFromCompany}
                                blockChangingEmail={blockChangingEmail}
                            />
                        }
                        {step === 'account' &&
                            <AccountForm
                                previousData={formData}
                                onNext={handleAccountSubmit}
                                onBack={handleGoBackFromAccount}
                                dontCollectPassword={isExternalAuth || resumingSignup}
                            />
                        }
                        {step === 'phoneVerification' &&
                            <PhoneVerificationForm
                                phone={formData.phone}
                                onNext={handlePhoneVerificationSubmit}
                                onBack={() => setStep('account')}
                            />
                        }
                        {step === 'twilioPhone' &&
                            <PhoneSelection
                                state={formData?.address?.state}
                                onNext={handlePhoneSelection}
                                onBack={() => setStep('account')}
                                changedContinueBtn={!selectedBuyNow && !forceCollectingCCInfo && selectedPlan?.billingPlan.trialDays && selectedPlan?.billingPlan.trialDays > 0}
                            />
                        }
                        {step === 'cc' &&
                            <CCSetup
                                user={SignupStore.user}
                                selectedPhone={selectedPhone}
                                selectedPlan={selectedPlan}
                                selectedBuyNow={selectedBuyNow}
                                changedContinueBtn={(!selectedBuyNow && selectedPlan?.billingPlan.trialDays && selectedPlan?.billingPlan.trialDays > 0)}
                                onBack={() => setStep('twilioPhone')}
                                onPlanChange={(data: {priceId: string, buyNow: boolean}) => setPlanSelectedyByUser(data)}
                            />
                        }
                        {['company', 'account'].includes(step) && <GoToLogin onGoToLogin={goToLogin} />}
                    </GridLayout>
                }
            </div>
            <Dialog
                    className="resumingSignupPasswordInput"
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                >
                    <div className="resumingSignupPasswordInput__instruction">
                        An account already exists for provided email address. If it is yours, please input password below.
                    </div>
                    <form className="AuthContainer__form" onSubmit={handleSubmit(signIn)}>
                        {error && <div className="AuthContainer__form-error resumingSignupPasswordInput__error">Invalid password</div>}
                        <FormField>
                            <input
                                type="password"
                                name="password"
                                autoComplete="current-password"
                                className={classNames('form-input', { error: errors.password || error })}
                                ref={(e) => {
                                    register(e, {
                                        required: 'Missing Password',
                                    });
                                    passwordRef.current = e;
                                }}
                            />
                        </FormField>
                        <div className="resumingSignupPasswordInput__action">
                            <input type="submit" className="btn-primary" value="Confirm" />
                        </div>
                    </form>
                </Dialog>
        </div>
    );
};

export const SignUp = withStores(SignUpWithStore, [STORES.User, STORES.Auth, STORES.Signup]);
