import React from 'react';
import { Broadcast } from '@src/stores/models/broadcast_new';
import ListCard, { ListCardData } from '../ListCard/ListCard';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import { Loader } from '@src/components/Loader/Loader';
import { broadcastToLabel } from '../helpers/broadcast';

import '../Broadcasts.sass';
import './List.sass';

const broadcastToListCard = (broadcast: Broadcast): ListCardData => {
    return {
        label: broadcastToLabel(broadcast),
        date: broadcast.updated,
        text: broadcast.text,
        firstTag: broadcast.first20Tags && broadcast.first20Tags.length > 0 && broadcast.first20Tags[0].value,
        tagsTotal: broadcast.totalTags,
        contacts: broadcast.totalRecipients
    };
};

interface ListProps {
    broadcasts: Broadcast[];
    selectedId: string;
    totalBroadcasts: number;
    isLoading: boolean;
    onCardClick: (cardId: string) => void;
    onLoadMore: (page: number) => void;
}

const List: React.FunctionComponent<ListProps> = ({ broadcasts, selectedId, totalBroadcasts, isLoading, onCardClick, onLoadMore }) => (
    <InfiniteScroll
        pageStart={0}
        loadMore={onLoadMore}
        initialLoad={false}
        hasMore={!isLoading && (totalBroadcasts - broadcasts.length > 0)}
        useWindow={false}
        threshold={300}
    >
        <ul className="broadcasts-list">
            {broadcasts.map(broadcast => (
                <ListCard
                    key={broadcast.id}
                    card={broadcastToListCard(broadcast)}
                    selected={selectedId === broadcast.id}
                    onClick={() => onCardClick(broadcast.id)}
                />
            ))}
        </ul>
        {isLoading && <div className="loader-container"><Loader loading={isLoading}/></div>}
    </InfiniteScroll>
)

export default List;
