import React from 'react';
import CSVTablePng from './CSVTable.png';

const exampleFileLink = 'https://prod-myopolis-media.s3.amazonaws.com/myopolis_import_template.csv';

interface ImportIntroProps {
    onNextClick: () => void;
}

const ImportIntro: React.FunctionComponent<ImportIntroProps> = ({ onNextClick }) => {
    return (
        <div className="ImportCustomers__Intro">
            <h3 className="ImportCustomers__title">Import Customers 🙋‍♀️</h3>
            <p className="ImportCustomers__subtitle">
                Upload your customer list to Myopolis so you can reach out to any customer in a
                couple clicks.
            </p>
            <p className="ImportCustomers__subtitle">
                Import a table into Myopolis by uploading a .CSV file. Need help formatting?{' '}
                <a href={exampleFileLink} download>
                    Download example
                </a>
            </p>
            <img alt="csv table" src={CSVTablePng} />
            <div className="ImportCustomers__note-container">
                <div className="ImportCustomers__note-mark">!</div>
                <p className="ImportCustomers__note">
                    First name, last name, and phone are required. Email address and company are
                    optional, but including them can help you to organize duplicates. You may also
                    include tags and notes.
                </p>
            </div>
            <button className="btn-primary-full" onClick={onNextClick}>
                Choose a .CSV file
            </button>
        </div>
    );
};

export default ImportIntro;
