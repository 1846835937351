export interface Notifications {
    conversation: {
        assignmentEmail?: boolean;
        assignmentMobile?: boolean;
    };
    message: NotificationsMessage;
    phone: boolean;
    forwardToBrowser: boolean;
    forwardToMobile: boolean;
    review: {
        email?: boolean;
        fiveStar?: boolean;
        fourStar?: boolean;
        oneStar?: boolean;
        threeStar?: boolean;
        twoStar?: boolean;
    };
    summary?: {
        daily: boolean;
        weekly: boolean;
    };
    created?: Date;
    updated?: Date;
}

export interface NotificationsMessage {
    assignedMeDesktop?: boolean;
    assignedMeEmail?: boolean;
    assignedMeMobile?: boolean;
    assignedOtherEmail?: boolean;
    assignedOthrDesktop?: boolean;
    assignedOthrMobile?: boolean;
    unassignedDesktop?: boolean;
    unassignedEmail?: boolean;
    unassignedMobile?: boolean;
}

export interface Notification {
    id: string;
    title: string;
    type: NotificationType;
    entityId: string;
    read: boolean;
    created: number;
}

export enum NotificationType {
    REVIEW_RECEIVED = 1,
    REVIEW_UPDATED,
    MESSAGE_RECEIVED,
}
