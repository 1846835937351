import React from 'react';
import { Template } from '@src/stores/models';

import './templates-response.sass';
interface TemplatesResponse {
    templates: Template[];
    onTemplateSelect: (t: Template) => void;
}

export const TemplatesResponse: React.FC<TemplatesResponse> = (props) => {
    const templates = props.templates;
    return (
        <div className="TemplatesResponse">
            <div className="TemplatesResponse__title">Suggested templates:</div>
            <div className="TemplatesResponse__list">
                {templates &&
                    templates
                        .slice()
                        .sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())
                        .slice(0, 5)
                        .map((t, i) => {
                            return (
                                <button
                                    key={i}
                                    className="item__response"
                                    onClick={() => props.onTemplateSelect(t)}
                                >
                                    {t.title}
                                </button>
                            );
                        })}
            </div>
        </div>
    );
};
