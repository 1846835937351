import React from 'react';
import { Leaderboard } from './Leaderboard/Leaderboard';
import { Customer, CustomerEdit, Customers, CustomersTable, Import } from './Customers';
import { Dashboard } from './Dashboard/Dashboard';
import { Reviews } from './Reviews/Reviews';
import { Messages } from './Messages/Messages';
import { Settings } from './Settings/Settings';
import { Account } from './Settings/components/Account/Account';
import { Team } from './Settings/components/Team/Team';
import { Billing } from './Settings/components/Billing/Billing';
import { Invoices } from './Settings/components/Invoices/Invoices';
import { PhoneNumber } from './Settings/components/PhoneNumber/PhoneNumber';
import { Templates } from './Settings/components/Templates/Templates';
import { Webchat } from './Settings/components/Webchat/Webchat';
import { Integrations } from './Settings/components/Integrations/Integrations';
import { UserSettings } from './UserSettings/UserSettings';
import { PersonalInfo } from './UserSettings/components/PersonalInfo/PersonalInfo';
import { Password } from './UserSettings/components/Password/Password';
import { NotificationsSettings } from './UserSettings/components/Notifications/Notifications';
import { SignIn } from './Auth/SignIn/SignIn';
import { SignUp } from './Auth/SignUp/SignUp';
import { SignUp as SignUp2 } from './Auth/SignUp2/SignUp';
import { Forgot } from './Auth/Forgot/Forgot';
import { Onboarding } from './Onboarding/Onboarding';
import { Notifications } from './Notifications/Notifications';
import { ConfirmEmail } from './Auth/ConfirmEmail/ConfirmEmail';
import { ActivateAccount } from './Auth/ActivateAccount/ActivateAccount';
import { LogoutBeforeActivate } from './Auth/LogoutBefore/LogoutBeforeActivate';
import { LogoutBeforeResetPwd } from './Auth/LogoutBefore/LogoutBeforeResetPwd';
import { Broadcasts } from './Broadcasts/Broadcasts';
import { Coupon } from './Coupon/Coupon';
import { Pricing } from './Pricing/Pricing';
import PhoneVerification from './Auth/SignUp2/Main/PhoneVerification/PhoneVerification';
import { CollectingCC } from './Auth/SignUp2/Main/CollectingCC/CollectingCC';
import { Account as SignupAccount } from './Auth/SignUp2/Main/Account';
import { PhoneSelection } from './Auth/SignUp2/Main/PhoneSelection/PhoneSelection';

export interface Page {
    path: string;
    label: string;
    exact?: boolean;
    notInHeader?: boolean;
    hideForMembers?: boolean;
    hideIfBroadcastsLocked?: boolean;
    hideIfNoWebchatInFF?: boolean;
    hideIfNoCoupons?: boolean;
    hideLockedAccountDialog?: boolean;
    component: React.FC | React.JSXElementConstructor<any>;
}

export interface Pages {
    [key: string]: Page;
}

export const nonAuthPages: Pages = {
    signin: { path: '/signin', label: 'signin', component: SignIn },
    signup: { path: '/signupfull', label: 'signup', component: SignUp },
    forgot: { path: '/forgot', label: 'forgot pwd', component: Forgot },
    confirm: { path: '/confirm', label: 'confirm', component: ConfirmEmail },
    activate: { path: '/activate', label: 'activate', component: ActivateAccount },
    signup2: { path: '/signup', label: 'signup', component: SignUp2 },
};

export const authPages: Page[] = [
    {
        path: '/dashboard',
        label: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/messages/:tid?/:lid?',
        exact: true,
        label: 'messages',
        component: Messages,
    },
    {
        path: '/broadcasts/:broadcastId',
        notInHeader: true,
        label: 'broadcast',
        component: Broadcasts,
    },
    {
        path: '/broadcasts',
        label: 'broadcasts',
        notInHeader: false,
        component: Broadcasts,
        hideIfBroadcastsLocked: true
    },
    {
        path: '/reviews',
        label: 'reviews',
        component: Reviews,
    },
    {
        path: '/customers',
        label: 'customers',
        component: Customers
    },
    {
        path: '/leaderboard',
        label: 'leaderboard',
        component: Leaderboard,
    },
    {
        path: '/settings',
        label: 'settings',
        component: Settings,
        hideForMembers: true,
        hideLockedAccountDialog: true
    },
    {
        path: '/user-settings',
        label: 'user settings',
        component: UserSettings,
        notInHeader: true
    },
    {
        path: '/onboarding',
        label: 'onboarding',
        component: Onboarding,
        notInHeader: true
    },
    {
        path: '/notifications',
        label: 'onboarding',
        component: Notifications,
        notInHeader: true
    },
    {
        path: '/confirm',
        label: 'confirm',
        component: ConfirmEmail,
        notInHeader: true
    },
    {
        path: '/account',
        label: 'logoutBeforeActivate',
        component: LogoutBeforeActivate,
        notInHeader: true,
    },
    {
        path: '/user/password/reset',
        label: 'logoutBeforeResetPwd',
        component: LogoutBeforeResetPwd,
        notInHeader: true,
    },
    {
        path: '/coupon/:couponId',
        notInHeader: true,
        label: 'coupon',
        hideLockedAccountDialog: true,
        component: Coupon
    },
    {
        path: '/pricing',
        notInHeader: true,
        label: 'pricing',
        component: Pricing,
        hideLockedAccountDialog: true
    },
];

export const signup2Pages: Page[] = [
    { path: '', exact: true, label: 'signup2 main', component: SignupAccount },
    { path: '/verify', label: 'signup2 verify', component: PhoneVerification },
    { path: '/phone', label: 'signup2 phone', component: PhoneSelection },
    { path: '/cc', label: 'signup2 cc', component: CollectingCC },
];

export const customersPages: Page[] = [
    { path: '', exact: true, label: 'Customers', component: CustomersTable },
    { path: '/import/upload', label: 'Customer Import', component: Import },
    { path: '/edit/:id', label: 'Customer Edit', component: CustomerEdit },
    { path: '/:id', label: 'Customer', component: Customer },
];

export const settingsPages: Page[] = [
    {
        path: '/account',
        label: 'account',
        component: Account,
    },
    {
        path: '/team',
        label: 'team',
        component: Team
    },
    {
        path: '/billing',
        label: 'billing',
        component: Billing,
        hideLockedAccountDialog: true
    },
    {
        path: '/phone-number',
        label: 'phone number',
        component: PhoneNumber,
    },
    {
        path: '/invoices',
        label: 'invoices',
        component: Invoices,
    },
    {
        path: '/integrations',
        label: 'integrations',
        component: Integrations,
    },
    {
        path: '/templates',
        label: 'templates',
        component: Templates,
    },
    {
        path: '/webchat',
        label: 'webchat',
        component: Webchat,
        hideIfNoWebchatInFF: true,
    },
];

export const userSettingsPages: Page[] = [
    {
        path: '/personal-info',
        label: 'personal info',
        component: PersonalInfo
    },
    {
        path: '/password',
        label: 'password',
        component: Password
    },
    {
        path: '/notifications',
        label: 'notifications',
        component: NotificationsSettings,
    },
];
