import React from 'react';

interface ErrorToastDownloadProps {
    getBEData: () => void;
}
export const ErrorToastDownload: React.FC<ErrorToastDownloadProps> = (props) => {
    return (
        <div className="toast">
            <span>
                Some data has not been loaded successfully. Please reload or try again later.
            </span>
            <button className="btn-primary" onClick={() => props.getBEData()}>
                Reload
            </button>
        </div>
    );
};
