import { CustomerImportColumn } from '@src/stores/models/customer-import';
import { v4 as uuidv4 } from 'uuid';

export const customerImportColumnsToImportColumns = (columns?: CustomerImportColumn[]) => {
  return columns?.map((c) => ({
      key: uuidv4(),
      column: c,
  }))
}

export const columnsDataIntoRows = (columns) => {
  if (columns?.length) {
      const result =  [
          ...columns[0].column.data?.map((r, i) => {
              return columns[0].column.errors
                  ? {
                        data: columns?.map((c) => c.column.data[i]),
                        errors: columns?.map((c) => c.column.errors[i]),
                    }
                  : { data: columns?.map((c) => c.column.data[i]) };
          }),
      ];
      return result
  }
};

export const rowsDataIntoColumns = (rows) => {
  if (rows?.length) {
      const columns = [
          rows[0].data.map((c, i) => {
              return {
                  key: uuidv4(),
                  column: {
                      data: rows.map((r) => r.data[i]),
                      // skip: labels[i]?.skip
                  },
              };
          }),
      ][0];
      return columns;
  }
};

// const exportToCsv = () => {
//   const filename = 'export.csv';

//   const rows = importRows.map((row) => {
//       const data = row.data.map((item) => item.value);
//       let errors = [];
//       row.data.forEach((item) => errors.push(item.error));
//       errors = errors.filter((el) => el);
//       return [...data, errors];
//   });

//   const labelsArr = titles.map((el) => el.value);
//   labelsArr.push('errors');
//   rows.unshift(labelsArr);

//   const processRow = (row) => {
//       let finalVal = '';
//       for (let j = 0; j < row.length; j++) {
//           let innerValue = row[j] === null ? '' : row[j].toString();
//           if (row[j] instanceof Date) {
//               innerValue = row[j].toLocaleString();
//           }
//           let result = innerValue.replace(/"/g, '""');
//           if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
//           if (j > 0) finalVal += ',';
//           finalVal += result;
//       }
//       return finalVal + '\n';
//   };

//   let csvFile = '';
//   for (let i = 0; i < rows.length; i++) {
//       csvFile += processRow(rows[i]);
//   }

//   const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
//   if (navigator.msSaveBlob) {
//       // IE 10+
//       navigator.msSaveBlob(blob, filename);
//   } else {
//       const link = document.createElement('a');
//       if (link.download !== undefined) {
//           // feature detection
//           // Browsers that support HTML5 download attribute
//           const url = URL.createObjectURL(blob);
//           link.setAttribute('href', url);
//           link.setAttribute('download', filename);
//           link.style.visibility = 'hidden';
//           document.body.appendChild(link);
//           link.click();
//           document.body.removeChild(link);
//       }
//   }
// };
