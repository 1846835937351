import React, { useEffect, useRef, useState, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import {
    STORES,
    withStores,
    WithConversationsStoreProps,
    WithUserStoreProps
} from '@src/stores/with-store';
import { formatDate, formatTime, isIE11 } from "@src/theme/utils/helpers";
import { Loader, FormField, Dialog } from '@src/components';
import { Attachment, Employee } from '@src/stores/models';
import { ReactComponent as EditIcon } from '@src/theme/icons/edit-outline.svg';
import { ReactComponent as CloseIcon } from '@src/theme/icons/close-delete-msg.svg';
import { v4 as uuidv4 } from 'uuid';
import { SingleMessage } from './SingleMessage';
import { useDebounce } from '@src/hooks';
import MessageAttachments from './MessageAttachments';

interface Event {
    conversationId?: string;
    created?: Date;
    id: string;
    invitee?: string;
    locationId?: string;
    members?: any;
    type?: string;
    attachments?: any;
    message?: string;
    sentBy?: string;
    employeeId?: string;
    updated?: Date;
    text?: string;
}
interface ChatProps {
    type: string;
}

type props =
    ChatProps
    & WithConversationsStoreProps
    & WithUserStoreProps;


const ChatWithStore: React.FC<props> = (props) => {
    const { register, handleSubmit } = useForm();
    const conversationRef = useRef(null);
    const [heightToStay, setHeightToStay] = useState(null);

    const [openDeleteMsg, setOpenDeleteMsg] = useState(null);
    const [msgToBeDeleted, setMsgToBeDeleted] = useState(null);
    const [initedAlready, setInitedAlready] = useState(false);
    const [firstUnviewedMessage, setFirstUnviewedMessage] = useState(null);

    const ConversationsStore = props.ConversationsStore;
    const UserStore = props.UserStore;
    const viewTeamDirect = props.type === 'teamDirect';
    const viewTeamChannel = props.type === 'teamChannel';
    const viewTeam = props.type === 'teamDirect' || props.type === 'teamChannel';

    const messages: { [key: string]: Event } =
        viewTeamChannel ? ConversationsStore.SelectedChannelStore.messages :
        viewTeamDirect ? ConversationsStore.SelectedDirectConversationStore.messages : null
    const key: number =
        viewTeamChannel ? ConversationsStore.SelectedChannelStore.key :
        viewTeamDirect ? ConversationsStore.SelectedDirectConversationStore.key : null
    const [messagesSortedState, setMessagesSortedState] = useState(!messages ? [] : Object.values(messages).map(el => el.id).reduce((a,b)=> (a[b]='basic',a),{}));
    const memberChanges: { [key: string]: Event } = viewTeamChannel ? ConversationsStore.SelectedChannelStore.memberChanges : null;
    const employeesMap: { [key: string]: Employee } = UserStore.user.employees.reduce((previousValue: { [key: string]: Employee }, currentValue: Employee) => {
        return {...previousValue, [currentValue.id as string]: currentValue as Employee}
    }, {});
    const debouncedKeyChange = useDebounce(key, 20);

    useEffect(() => {
        if (conversationRef?.current && !heightToStay) {
            if (!initedAlready || isIE11) {
                conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
                if (!initedAlready) setInitedAlready(true);
            } else {
                conversationRef.current.scrollBy({ top: 10000, behavior: "smooth"});
            }
        }
    }, [debouncedKeyChange]);

    useEffect(() => {
        if (
            ConversationsStore?.SelectedChannelStore?.selectedChannel?.id ||
            ConversationsStore?.SelectedDirectConversationStore?.selectedDirect?.id
        ) {
            setTimeout(() => {
                if (conversationRef?.current && !heightToStay) {
                    conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
                };
            }, 100)
        };
    }, [
        ConversationsStore?.SelectedChannelStore?.selectedChannel?.id,
        ConversationsStore?.SelectedDirectConversationStore?.selectedDirect?.id
    ]);

    useEffect(() => {
        if (heightToStay) {
            conversationRef.current.scrollTop = conversationRef.current.scrollHeight - heightToStay;
            setHeightToStay(null);
        }
        checkFirstUnviewedMessage(messagesSorted);
    }, [
        ConversationsStore?.SelectedChannelStore?.messagesPage,
        ConversationsStore?.SelectedDirectConversationStore?.messagesPage
    ]);

    useEffect(() => {
        if (messages) setMessagesSortedState(Object.values(messages).map(el => el.id).reduce((a,b)=> (a[b]='basic',a),{}));
    }, [messages]);

    const checkFirstUnviewedMessage = (sortedByDate) => {
        const currentUserId = UserStore.user.employee.id;
        const lastViewedByCurrentUser =
            viewTeamChannel ?
                ConversationsStore.SelectedChannelStore.currentMembers.find(el => el.participantId === currentUserId)?.lastRead :
            viewTeamDirect ?
                ConversationsStore.SelectedDirectConversationStore.selectedDirect?.participants.find(el => el.participantId === currentUserId)?.lastRead : null;

        if (!lastViewedByCurrentUser) {
            return;
        };

        const firstUnviewedMessage = sortedByDate.find(el => {
            return (
                el.eventType === 'message' &&
                el.created > lastViewedByCurrentUser &&
                el.sentBy !== currentUserId
            )
        });
        if (firstUnviewedMessage) {
            setFirstUnviewedMessage(firstUnviewedMessage.id);
        };
    };

    const messagesSorted = useMemo(() => {
        if (Boolean(messages || memberChanges)) {
            const messagesWithType =
                messages && Object.values(messages).map((m) => {
                    return {
                        ...m,
                        eventType: 'message',
                    };
                });
            const memberChangesWithType =
                memberChanges && Object.values(memberChanges).map((m) => {
                    return {
                        ...m,
                        eventType: 'memberChange',
                    };
                });
            let aggregated = [];

            aggregated = []
                .concat(messagesWithType)
                .concat(memberChangesWithType)
                .filter((x) => x && x.id);

            let sortedByDate = aggregated?.length && aggregated.slice().sort((event1, event2) => {
                return (
                    new Date(event1.created).getTime() -
                    new Date(event2.created).getTime()
                );
            });

            return sortedByDate || [];
        };
    }, [messages, memberChanges]);

    const setAllMsgsToBasic = () => {
        const allMessagesBasicState = () => {
            Object.keys(messagesSortedState).forEach(function(key){ messagesSortedState[key] = "basic" });
            return messagesSortedState;
        };
        return allMessagesBasicState();
    };

    const closeMsgEdition = () => {
        setMessagesSortedState({...setAllMsgsToBasic() });
    };

    const openMsgEdition = (msg) => {
        closeMsgEdition();
        setTimeout(() => {
            setMessagesSortedState({...setAllMsgsToBasic(), [msg.id]: 'beingEdited' });
            conversationRef.current.focus();
        }, 100)
    };

    const startDeletingMsg = (msg?) => {
        setOpenDeleteMsg(true);
        if (msg) setMsgToBeDeleted(msg);
    };

    const suspendDeletingMsg = () => {
        setOpenDeleteMsg(false);
        setMsgToBeDeleted(null);
        closeMsgEdition();
    };

    const confirmDeletingMsg = () => {
        const req =
            viewTeamChannel ? ConversationsStore.SelectedChannelStore.deleteMessage(msgToBeDeleted.id, msgToBeDeleted.conversationId) :
            viewTeamDirect ? ConversationsStore.SelectedDirectConversationStore.deleteMessage(msgToBeDeleted.id, msgToBeDeleted.conversationId) :
            null
        return req
        .then(() => {
            setOpenDeleteMsg(false);
            setMsgToBeDeleted(null);
        });
    };

    const saveUpdatedMsg = (m) => {
        if (!m.msg) {
            startDeletingMsg();
            return;
        }
        const msgId = Object.entries(messagesSortedState).find(el => el.includes('beingEdited'))[0];
        const updatedMsg = {
            ...messagesSorted.find(el => el.id === msgId),
            message: m.msg.trim()
        }
        const req =
            viewTeamChannel ? ConversationsStore.SelectedChannelStore.editMessage(updatedMsg) :
            viewTeamDirect ? ConversationsStore.SelectedDirectConversationStore.editMessage(updatedMsg) :
            null
        return req
        .then(() => closeMsgEdition());
    };

    const keyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
        if (e.keyCode === 27) {
            closeMsgEdition();
        }
    };

    if (!messages?.length && !memberChanges) {
        return (
            <div className="Chat no-messages">
                <h5>No messages</h5>
            </div>
        );
    };

    const renderMessage = (msg) => {
        if (typeof(msg) === 'string') return;
        const employee = employeesMap && employeesMap[msg.sentBy];
        const author = employee ? `${employee.firstName} ${employee.lastName}` : '';

        return (
            <div
                key={msg.id}
                className={classNames('Chat__message', {
                    notUser: employee?.id !== UserStore.user.employee.id,
                    user: employee?.id === UserStore.user.employee.id,
                })}
            >
                {(viewTeam && msg.id === firstUnviewedMessage) &&
                    <div className="firstViewedMessage">
                        <div className="firstViewedMessageLine" />
                        <div className="firstViewedMessageText">New</div>
                    </div>
                }
                {msg.attachments && <MessageAttachments msg={msg}/>}
                {viewTeam && msg.message?.trim() !== '' && messagesSortedState[msg.id] !== 'beingEdited' && (
                    <div key={2} className="message-text">
                        <SingleMessage textToSplit={msg.message} withoutWrapper={true} />
                        {employee?.id === props.UserStore.user.employee.id &&
                            <div className="edit">
                                <div
                                    className="editIcon-edit"
                                    title="Edit message"
                                    onClick={() => {
                                        openMsgEdition(msg);
                                        setMsgToBeDeleted(msg);
                                    }}
                                >
                                    <EditIcon />
                                </div>
                                <div
                                    className="editIcon-delete"
                                    title="Delete message"
                                    onClick={() => {startDeletingMsg(msg)}}
                                >
                                    <CloseIcon />
                                </div>
                            </div>
                        }
                    </div>
                )}
                {viewTeam && msg.message?.trim() !== '' && messagesSortedState[msg.id] === 'beingEdited' && (
                    <div key={uuidv4()} className="message-text edited">
                        <Loader loadingState={
                            viewTeamDirect ? ConversationsStore.SelectedDirectConversationStore.directLoading :
                            viewTeamChannel ? ConversationsStore.SelectedChannelStore.channelLoading : null
                        } />
                        <form onSubmit={handleSubmit(saveUpdatedMsg)}>
                            <FormField htmlFor="msg">
                                <textarea
                                    name="msg"
                                    defaultValue={msg.message}
                                    onKeyUp={(e) => keyDown(e)}
                                    ref={register()}
                                    autoComplete="off"
                                />
                            </FormField>
                            <div className="actions">
                                <div
                                    className="btn-tetriary"
                                    onClick={() => closeMsgEdition()}
                                >
                                    Cancel
                                </div>
                                <button className="btn-tetriary">
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                {viewTeam &&
                    <div key={3} className="send-time">
                        <p key={4}>
                            {msg.unsuccessfulSending ?
                                <span className="sendingError">Sending error</span> :
                            msg.id.startsWith('fake') ?
                                'Sending...' :
                                `${author} ${formatTime(msg.created, 'hh:mm a')} ${formatDate(msg.created)}`
                            }
                        </p>
                    </div>
                }
            </div>
        );
    };

    const renderMemberChange = (mc) => {
        if (typeof(mc) === 'string') return;
        const memberChangeObject = mc.members && employeesMap && `${employeesMap[mc.members[0]]?.firstName} ${employeesMap[mc.members[0]]?.lastName}`;
        const memberChangeInvitee = employeesMap && `${employeesMap[mc.invitee]?.firstName} ${employeesMap[mc.invitee]?.lastName}`;
        return (
            <div
                key={mc.id}
                className='Chat__comment'
            >
                <div className="text">
                    {/* {mc.type === 'ADDED_TO' && `${memberChangeInvitee} added ${memberChangeObject} to group`} */}
                    {mc.type === 'ADDED_TO' && `${memberChangeObject} was added to the group`}
                    {mc.type === 'REMOVED_FROM' && `${memberChangeObject} was removed from the group`}
                    {mc.type === 'JOINED_CHANNEL' && `${memberChangeObject} joined the group`}
                    {mc.type === 'LEFT_CHANNEL' && `${memberChangeObject} left the group`}
                </div>
                <div className="line" />
            </div>
        );
    };

    const renderEvent = (details) => {
        switch (details.eventType) {
            case 'message':
                return renderMessage(details);
            case 'memberChange':
                return renderMemberChange(details);
            default:
                return;
        }
    };

    const loadMoreFunc = () => {
        if (!heightToStay) {
            setHeightToStay(conversationRef.current.scrollHeight);

            viewTeamChannel ? ConversationsStore.SelectedChannelStore.getConversationMessages() :
            viewTeamDirect ? ConversationsStore.SelectedDirectConversationStore.getConversationMessages() : null
        }
    };

    const items = messagesSorted
        ? messagesSorted.map((msgDetails) => renderEvent(msgDetails))
        : null;

    return (
        <div className="Chat" ref={conversationRef}>
            <InfiniteScroll
                pageStart={1}
                loadMore={loadMoreFunc}
                initialLoad={false}
                hasMore={
                    viewTeamChannel ? ConversationsStore.SelectedChannelStore.hasMoreMessagesToLoad :
                    viewTeamDirect ? ConversationsStore.SelectedDirectConversationStore.hasMoreMessagesToLoad : null
                }
                isReverse={true}
                adjustReverseScroll={true}
                loader={(
                    viewTeamChannel ? ConversationsStore.SelectedChannelStore.hasMoreMessagesToLoad :
                    viewTeamDirect ? ConversationsStore.SelectedDirectConversationStore.hasMoreMessagesToLoad : null
                    ) &&
                    <div key={uuidv4()}>Loading...</div>
                }
                useWindow={false}
                threshold={1}
            >
                <div key={viewTeamChannel ? ConversationsStore.SelectedChannelStore.key :
                    viewTeamDirect ? ConversationsStore.SelectedDirectConversationStore.key : 1}>
                    {items}
                </div>
            </InfiniteScroll>
            <Dialog
                className="Chat__dialog"
                open={openDeleteMsg}
                onClose={() => setOpenDeleteMsg(false)}
            >
                <div className="Chat__dialog-question">
                    Are you sure you want to delete this message?
                </div>
                <div className="Chat__dialog-msgContent">
                    {msgToBeDeleted?.message?.length > 40 ? `${msgToBeDeleted?.message?.slice(0, 40)}...` : msgToBeDeleted?.message}
                </div>
                <div className="Chat__dialog-actions">
                    <button
                        className="btn-secondary"
                        onClick={() => suspendDeletingMsg()}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn-primary"
                        onClick={() => confirmDeletingMsg()}
                    >
                        Delete
                    </button>
                </div>
            </Dialog>
        </div>
    );
};

export const Chat = withStores(ChatWithStore, [
    STORES.ConversationsStore,
    STORES.User
]);
