import React, { useState } from 'react';
import { Loader } from '@src/components';
import { toast } from 'react-toastify';
import { images } from '@src/theme/images';

import './import-customers.sass';

type FileState = '' | 'loading' | 'loaded' | 'error';

interface ImportUploadProps {
    loading: boolean;
    onClose: () => void;
    onUpload: (file: File) => void;
}

export const ImportUpload: React.FunctionComponent<ImportUploadProps> = ({ loading, onClose, onUpload }) => {
    const [fileState, setFileState] = useState<FileState>('');

    const handleFilesLoaded = (ev: React.ChangeEvent<HTMLInputElement>) => {
        ev.stopPropagation();
        const files = ev.target.files;
        if (files.length < 1) {
            return;
        };

        const filenameDivided = files[0].name.split('.');
        const extension = filenameDivided[filenameDivided.length - 1];
        if (extension !== 'csv') {
            toast.error('Only csv files are accepted', { autoClose: 4000 });
            return;
        };
        onUpload(files[0]);
    };

    return (
        <div className="ImportCustomers__Upload">
            <Loader loading={loading} />
            <h3 className="ImportCustomers__title">Select Files to Upload</h3>
            <div className="ImportCustomers__files">
                {fileState === 'error' ? (
                    <>
                        <div className="error">
                            <h3>Ops, something went wrong!</h3>
                        </div>
                    </>
                ) : (
                    <>
                        <img src={images.upload} alt="upload" />
                        <p>Drag and drop a file here</p>
                        <p>or browse</p>
                    </>
                )}
                <input
                    type="file"
                    accept=".csv"
                    onError={() => setFileState('error')}
                    onLoadStart={() => setFileState('loading')}
                    onLoadedData={() => setFileState('loaded')}
                    onChange={handleFilesLoaded}
                />
            </div>
        </div>
    );
};
