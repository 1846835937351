import {versionsRequests} from "@src/requests/version.requests";
import {toast} from "react-toastify";

const startCheckingVersion = () => {
    check()
    setInterval(() => {
        check();
    }, 1000 * 60 * Math.floor(Math.random() * (10 - 5 + 1) + 5)) // random 5-10min
}

const check = () => {
    versionsRequests.getExternalVersion()
        .then((externalVersion: string) => {
            if (!externalVersion.startsWith('local')) {
                const versionsElements = document.getElementsByName('version')
                let localVersion;
                if (versionsElements.length) {
                    localVersion = (versionsElements[0] as any).content
                }
                if (localVersion
                    && externalVersion.localeCompare(
                        localVersion,
                        undefined,
                        { numeric: true, sensitivity: 'base' }) === 1
                ) {
                    toast.info(
                        `You are using ${localVersion} version of the application.
                                There is a new one ${externalVersion} version.
                                Finish your current task and reload this page.
                                (Windows: Ctrl + F5, OSX: ⌘ + R, or click here)`,
                        {
                            autoClose: false,
                            onClick: () => {
                                location.reload(true);
                            },
                        }
                    );
                }
            }
        });
}
export {
    startCheckingVersion
}
