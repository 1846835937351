import React from 'react';
import { ReactComponent as Check } from '@src/theme/icons/check-no-circle.svg';

import '../Signup.sass';

const features = [
    // 'Connect your review sites to monitor all of them in 1 place.',
    // 'Share 1 phone number with your team',
    // 'Turn inquiries into leads',
    // 'Turn bad reviews into positive'
    'One Inbox: all messaging in one place',
    'Improve reviews & get more customers',
    'Text customers and get responses',
    'Schedule SMS text campaigns',
    'Local phone number included'
];

const Features: React.FunctionComponent = () => {
    return (
        <div className="Signup__features">
            <h2 className="Signup__subtitle">Myopolis lets you:</h2>
            <ul>
                {features.map((feature, i) =>
                    <li key={i}>
                        <Check/>
                        <span>{feature}</span>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Features;
