import React from 'react';
import { ReactComponent as MessageIcon } from '@src/theme/icons/icon-review-respond.svg';
import { STORES, WithDashboardStoreProps, withStores, WithUserStoreProps } from '@src/stores/with-store';
import { Loader } from '@src/components/Loader/Loader';

import './Help.sass';

type HelpProps = WithUserStoreProps & WithDashboardStoreProps;

const HelpWithStores: React.FunctionComponent<HelpProps> = ({DashboardStore, UserStore}) => {
    const message = `👋 Hey ${DashboardStore.employee?.firstName || ''}, welcome aboard! \
    We're fellow humans here at Myopolis and always happy to help. \
    Need a hand?`

    return (
        <div
            className="Dashboard__Help"
        >
            <Loader loadingState={DashboardStore.loadingGeneral}/>
            <div className="Dashboard__Help-description">
                <p>{message}</p>                
            </div>
            <div 
                className="Dashboard__Help-action"
                onClick={() => UserStore.setShowSupportScreen(true)}
            >
                <span>Get Help</span>
                <MessageIcon />
            </div>
        </div>
    );
};

export const Help = withStores(HelpWithStores, [
    STORES.Dashboard,
    STORES.User,
]);
