import { AxiosResponse } from 'axios';
import api from '@src/api/api';
import {
    Customer,
    CustomerColumnConfirm,
    CustomerImport,
    ReviewWithReplies,
    Tag,
} from '@src/stores/models';

export class CustomersApi {
    static baseUrl = '/customers';

    static uploadCustomers(csvFile) {
        return api.post(`${this.baseUrl}/import`, csvFile);
    }

    static identifyCustomers(csvFile) {
        return api.post(`${this.baseUrl}/import/upload`, csvFile, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    static uploadFileProposal(file: File): Promise<CustomerImport> {
        const url = `${this.baseUrl}/import/upload`;
        const formData = new FormData();
        formData.append('file', file, file.name);
        return api
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => res.data);
    }

    static confirmFileUpload(
        fileKey: string,
        data: CustomerColumnConfirm[],
        includesHeader: boolean
    ): Promise<any> {
        const url = `${this.baseUrl}/import`;
        const dataWithSkip = data.map((el) =>
            el.skip === true || el.skip === false ? el : { ...el, skip: true }
        );
        return api
            .post(url, { fileKey, includesHeader, columns: dataWithSkip })
            .then((res: AxiosResponse<{}>) => res.data);
    }

    static correctDataOfImportedCustomers(
        fileKey: string,
        data: CustomerColumnConfirm[]
    ): Promise<any> {
        const url = `${this.baseUrl}/import/changes`;
        const dataWithSkip = data.map((el) =>
            el.skip === true || el.skip === false ? el : { ...el, skip: true }
        );
        return api
            .post(url, { fileKey, columns: dataWithSkip })
            .then((res: AxiosResponse<{}>) => res.data);
    }

    static exportCustomers(): void {
        const url = `${this.baseUrl}/export.csv`;
        window.open(url, '_blank');
    }

    static fetchCustomer(cid: string): Promise<CustomerResponse> {
        const url = `${this.baseUrl}/${cid}`;
        return api.get(url).then((res) => res.data.data);
    }
    static searchCustomersDt(
        search?: string,
        page?: number,
        size = 100,
        sort = 'name',
        sortDir = 'asc'
    ): Promise<{ data: Customer[]; total: number }> {
        const params = this.parseParams(search, page, size, sort);
        if (params['sort'] && sortDir) {
            params['sort'] = `${params['sort']}.${sortDir}`;
        }

        return api
            .get(`${this.baseUrl}/dt/search`, { params })
            .then((res: AxiosResponse<{ data: Customer[]; recordsFiltered: number }>) => ({
                data: res.data.data,
                total: res.data.recordsFiltered,
            }));
    }

    static searchCustomers(
        search?: string,
        page?: number,
        size?: number,
        sort = 'name.asc'
    ): Promise<Customer[]> {
        const params = this.parseParams(search, page, size, sort);
        return api
            .get(`${this.baseUrl}/search`, { params })
            .then((res: AxiosResponse<{ data: Customer[] }>) => res.data.data);
    }

    static searchCustomersAndGroups(
        search?: string,
        page?: number,
        size?: number,
        sort = 'name.asc'
    ): Promise<Customer[]> {
        const params = this.parseParams(search, page, size, sort);
        return api
            .get(`${this.baseUrl}/g/search`, { params })
            .then((res: AxiosResponse<{ data: Customer[] }>) => res.data.data);
    }

    static parseParams(search?: string, page?: number, size?: number, sort?: string) {
        const paramsBase = {
            search: search,
            page: page,
            size: size,
            sort: sort,
        };

        const params = {};
        Object.keys(paramsBase).forEach((key) => {
            if (!!paramsBase[key]) {
                params[key] = paramsBase[key];
            }
        });
        return params;
    }

    static removeCustomer(cid: string): Promise<void> {
        const url = `${this.baseUrl}/${cid}/delete`;
        return api.post(url);
    }

    static updateCustomer(cid: string, customer: Customer): Promise<void> {
        const url = `${this.baseUrl}/${cid}`;
        return api.post(url, { id: cid, ...customer });
    }

    static getNotes(customerId: string): Promise<void> {
        return api.get(`/notes/${customerId}`);
    }

    static postNote(customerId: string, text: string): Promise<void> {
        return api.post(`/notes`, { customerId, text });
    }

    static updateNote(id: string, text: string): Promise<void> {
        return api.put(`/notes`, { id, text });
    }

    static deleteNote(noteId: string): Promise<void> {
        return api.delete(`/notes/${noteId}`);
    }
}

export interface CustomerResponse {
    customer: Customer;
    reviews: ReviewWithReplies[];
    tags: Tag[];
    threadId: string;
    ffBrowserCallingEnabled: boolean;
}
