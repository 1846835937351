import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { AsYouType } from 'libphonenumber-js';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { onSuccess } from '@src/theme/utils/constants';
import { FormField, ErrorToastSave } from '@src/components';
import { Customer, CustomerBase, LoadingState, OnErrorThread } from '@src/stores/models';
import { STORES, withStores } from '@src/stores/with-store';
import { re } from '@src/theme/utils/constants';
import { FormViewType } from './FormViewType';
import { ContactSelect } from '../ContactSelect/ContactSelect';
import { Input } from 'reactstrap';
import InputMask from 'react-input-mask';
import { ERROR_TOAST_AUTOCLOSE } from '@src/theme/utils/constants';

import './contact-form.sass';
import { ThreadsStore } from '@src/stores/messages/threads.store';

interface ContactFormProps {
    viewType?: FormViewType;
    onCustomerSendMsg?: (action: FormViewType, customer?: Customer) => void;
    ThreadsStore: ThreadsStore;
}

const ContactFormWithStore: React.FC<ContactFormProps> = (props) => {
    const store = props.ThreadsStore;
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const [phone, setPhone] = useState('');
    const [view, setView] = useState<FormViewType>(FormViewType.SELECT);
    const [defaultFirstName, setDefaultFirstName] = useState('');
    const [defaultLastName, setDefaultLastName] = useState('');

    const createNewThread = (customer) => {
        store
            .createNewThread(customer, true, 'addCustomer')
            .then(() => history.replace({ pathname: `/messages/?new` }));
    };

    const onErrorIncorrectPhone = () => {
        toast.error('Phone number is invalid. Please provide a valid 10 digit phone number', ERROR_TOAST_AUTOCLOSE);
    };
    store.onErrorAddingCustomer = (type: string, data?: OnErrorThread) => {
        store.loading = LoadingState.Error;
        switch (type) {
            case 'addCustomer': {
                toast.error(<ErrorToastSave repeatUploading={() => createNewThread(data)} />);
                break;
            }
            default: {
                return;
            }
        }
    };
    store.onSuccess = () => {
        store.loading = LoadingState.Loaded;
        onSuccess();
    };
    store.onErrorIncorrectPhone = () => {
        store.loading = LoadingState.Error;
        onErrorIncorrectPhone();
    };

    const actionCall = (action: FormViewType, customer?: Customer, search?) => {
        if (action === 'addContact') {
            setView(action);
            if (search?.firstName) setDefaultFirstName(search.firstName);
            if (search?.lastName) setDefaultLastName(search.lastName);
            if (search?.phone) setPhone(search.phone);
            return;
        }

        props.onCustomerSendMsg(FormViewType.SEND_MESSAGE, customer);
    };

    const updateFormModel = (model: CustomerBase) => createNewThread(model);

    const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setPhone(new AsYouType('US').input(e.target.value));

    const selectContact = view === 'select' && (
        <ContactSelect
            onAction={(action, customer, search) => actionCall(action, customer, search)}
        />
    );

    const newContactForm = view !== 'select' && (
        <form className="new-contact" onSubmit={handleSubmit(updateFormModel)}>
            <div className="new-contact-row">
                <FormField label="First Name" htmlFor="firstName" error={errors.name}>
                    <input
                        type="text"
                        name="firstName"
                        placeholder="Type to add"
                        defaultValue={defaultFirstName}
                        className={classNames('form-input', { error: errors.firstName })}
                        ref={register({ required: 'Missing first name' })}
                    />
                </FormField>
                <FormField label="Last Name" htmlFor="lastName" error={errors.surname}>
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Type to add"
                        defaultValue={defaultLastName}
                        className={classNames('form-input', { error: errors.lastName })}
                        ref={register({ required: 'Missing last name' })}
                    />
                </FormField>
            </div>
            <FormField label="Phone Number" htmlFor="phone" error={errors.phone}>
                <Input
                    type="text"
                    placeholder="Type to add"
                    mask="+1 (999) 999-9999"
                    name="phone"
                    value={phone}
                    onChange={(e) => onPhoneChange(e)}
                    className={classNames('form-input', { error: errors.phone })}
                    inputRef={register({
                        required: 'Missing phone number',
                        minLength: { value: 10, message: 'Minimum 10 digits required' },
                    })}
                    tag={InputMask}
                />
            </FormField>
            <FormField label="Email" htmlFor="email" error={errors.email}>
                <input
                    type="text"
                    name="email"
                    placeholder="Type to add"
                    className={classNames('form-input', { error: errors.email })}
                    ref={register({
                        pattern: {
                            value: re,
                            message: 'Invalid field',
                        },
                    })}
                />
            </FormField>
            <button className="btn-primary" type="submit">
                Create New Contact & Message
            </button>
        </form>
    );

    return (
        <div className="ContactForm card">
            <div className="icon-header">
            </div>
            <h2>Send a Message | Add Contact</h2>
            <h4>
                Type in the name of an existing customer to message, or add & message a new customer
                using the fields below
            </h4>
            <section className="current-form">{selectContact || newContactForm}</section>
        </div>
    );
};

export const ContactForm = withStores(ContactFormWithStore, [STORES.ThreadsStore]);
