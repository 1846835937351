import { Metric } from '@src/components/Metric/Metric';
import React from 'react';
import { InfoCard, InfoCardProps } from '../InfoCard/InfoCard';

interface MetricData {
    label: string;
    value: number;
}
interface MetricsCardProps extends InfoCardProps {
    metrics: MetricData[];
};

export const MetricsCard: React.FunctionComponent<MetricsCardProps> = ({ icon, title, metrics }) => {
    return (
        <InfoCard
            icon={icon}
            title={title}
        >
            {metrics.map((metric, i) => 
                <Metric
                    key={ i + metric.label}
                    toShow={metric.value}
                >
                    {metric.label}
                </Metric>
            )}
        </InfoCard>
    );
};
