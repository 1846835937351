import { Customer, Employee, MessagingConstants } from '@src/stores/models';

export interface GetTemplate {
    id: string;
    title: string;
    message: string;
    attachments: string[];
}

export type ParsedPrompts = {
    value: string;
    name: string;
    color: string;
    parsedValue: string;
}[]

export const PROMPTS = [
    {
        value: ':full_name:',
        name: 'Customer Full Name',
        color: '#01579B',
        parsedValue: ({customer}: {customer?: Customer; owner?: Employee; constants?: MessagingConstants}) => {
            if (customer?.firstName) {
                return `${customer?.firstName} ${customer?.lastName}`;
            }
            // else if (customer?.phone) {
            //     return customer?.phone;
            // }
        }
    },
    {
        value: ':first_name:',
        name: 'Customer First Name',
        color: '#01579B',
        parsedValue: ({customer}: {customer?: Customer; owner?: Employee; constants?: MessagingConstants}) => {
            if (customer?.firstName) {
                return customer?.firstName;
            }
        }
    },
    {
        value: ':last_name:',
        name: 'Customer Last Name',
        color: '#01579B',
        parsedValue: ({customer}: {customer?: Customer; owner?: Employee; constants?: MessagingConstants}) => {
            if (customer?.lastName) {
                return customer?.lastName;
            }
        }
    },
    {
        // value: ":my_name:",
        value: ':user_name:',
        name: 'User Name',
        color: '#1B5E20',
        parsedValue: ({constants}: {customer?: Customer; owner?: Employee; constants?: MessagingConstants}) => {
            const employee = constants?.employee;
            if (employee?.firstName && employee?.lastName) {
                return `${employee.firstName} ${employee.lastName}`;
            }
        }
    },
    {
        // value: ":my_email:",
        value: ':user_email:',
        name: 'User Email',
        color: '#1B5E20',
        parsedValue: ({constants}: {customer?: Customer; owner?: Employee; constants?: MessagingConstants}) => {
            const employee = constants?.employee;
            if (employee?.email) {
                return employee.email;
            }
        }
    },
    {
        value: ':owner_name:',
        name: 'Owner Name',
        color: '#1E90FF',
        parsedValue: ({owner}: {customer?: Customer; owner?: Employee; constants?: MessagingConstants}) => {
            if (owner?.firstName && owner?.lastName) {
                return `${owner.firstName} ${owner.lastName}`;
            }
        }
    },
    {
        value: ':owner_email:',
        name: 'Owner Email',
        color: '#1E90FF',
        parsedValue: ({owner}: {customer?: Customer; owner?: Employee; constants?: MessagingConstants}) => {
            return owner?.email || 'Owner Email';
        }
    },
    {
        value: ':company:',
        name: 'Company Name',
        color: '#C62828',
        parsedValue: ({constants}: {customer?: Customer; owner?: Employee; constants?: MessagingConstants}) => {
            const address = constants?.address;
            return address?.company;
        }
    },
    {
        value: ':street:',
        name: 'Street',
        color: '#E65100',
        parsedValue: ({constants}: {customer?: Customer; owner?: Employee; constants?: MessagingConstants}) => {
            const address = constants?.address;
            return address?.street;
        }
    },
    {
        value: ':city:',
        name: 'City',
        color: '#E65100',
        parsedValue: ({constants}: {customer?: Customer; owner?: Employee; constants?: MessagingConstants}) => {
            const address = constants?.address;
            return address?.city;
        }
    },
    {
        value: ':state:',
        name: 'State',
        color: '#E65100',
        parsedValue: ({constants}: {customer?: Customer; owner?: Employee; constants?: MessagingConstants}) => {
            const address = constants?.address;
            return address?.state;
        }
    },
    {
        value: ':zip:',
        name: 'Zip/Postal Code',
        color: '#E65100',
        parsedValue: ({constants}: {customer?: Customer; owner?: Employee; constants?: MessagingConstants}) => {
            const address = constants?.address;
            return address?.postalCode;
        }
    },
    {
        value: ':address:',
        name: 'Address',
        color: '#E65100',
        parsedValue: ({constants}: {customer?: Customer; owner?: Employee; constants?: MessagingConstants}) => {
            const address = constants?.address;
            return (address?.street && address?.city && address?.state && address?.postalCode)
            && `${address.street}, ${address.city}, ${address.state}, ${address.postalCode}`;
        }
    },
    {
        value: ':review_invitation:',
        name: 'Review Invitation',
        color: '#FFAB00',
        parsedValue: () => null,
    },
];

export const ParsedPrompts = (args: {customer: Customer; owner: Employee; constants: MessagingConstants}): ParsedPrompts => {
    return PROMPTS.map((p) => ({...p, parsedValue: p.parsedValue(args)}));
}

// export const PROMPTS = {
// 	FULL_NAME: {
// 		value: ":full_name:",
// 		name: "Customer full name"
// 	},
// 	FIRST_NAME: {
// 		value: ":first_name:",
// 		name: "Customer first name"
// 	},
// 	LAST_NAME: {
// 		value: ":last_name:",
// 		name: "Customer last name"
// 	},
// 	MY_NAME: {
// 		value: ":my_name:",
// 		name: "Users name"
// 	},
// 	MY_EMAIL: {
// 		value: ":my_email:",
// 		name: "Users email"
// 	},
// 	COMPANY: {
// 		value: ":company:",
// 		name: "Company name"
// 	},
// 	STREET: {
// 		value: ":street:",
// 		name: "Street"
// 	},
// 	CITY: {
// 		value: ":city:",
// 		name: "City"
// 	},
// 	STATE: {
// 		value: ":state:",
// 		name: "State"
// 	},
// 	ZIP: {
// 		value: ":zip:",
// 		name: "Zip/ Postal Code"
// 	},
// 	ADDRESS: {
// 		value: ":address:",
// 		name: "Address"
// 	},
// 	REVIEW_INVITATION: {
// 		value: ":review_invitation:",
// 		name: "Review invitation"
// 	},
// }

// export enum PROMPT_LIST {
// 	FULL_NAME = 'FULL_NAME',
// 	FIRST_NAME = 'FIRST_NAME',
// 	LAST_NAME = 'LAST_NAME',
// 	MY_NAME = 'MY_NAME',
// 	MY_EMAIL = 'MY_EMAIL',
// 	COMPANY = 'COMPANY',
// 	STREET = 'STREET',
// 	CITY = 'CITY',
// 	STATE = 'STATE',
// 	ZIP = 'ZIP',
// 	ADDRESS = 'ADDRESS',
// 	REVIEW_INVITATION = 'REVIEW_INVITATION'
// }
