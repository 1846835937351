import { observable, action, runInAction } from 'mobx';
import { Company, LoadingState, User, OnErrorDataAccount } from './models';
import { getCompanyDetails, editCompanyDetails, updateCompanyOperationHours } from '@src/requests';
import AuthStore from '@src/stores/auth.store';

export class UserStore {
    @observable user: User = null;
    @observable signUpUser = null;
    @observable company: Company = {};
    @observable dataLoaded = false;
    @observable showSupportScreen = false;
    @observable trialBanner = {show: false, daysLeft: null};
    @observable loadingState: LoadingState = LoadingState.Init;
    private _onError: (type: string, data?: OnErrorDataAccount) => void;
    private _onSuccess: () => void;

    set onSuccess(fn: () => void) {
        this._onSuccess = fn;
    }

    set onError(fn: (type: string, data?: OnErrorDataAccount) => void) {
        this._onError = fn;
    }

    hasFeatureFlag(featureFlagKey: string): boolean {
        if(this.user === null) {
            return false;
        } else {
            return this.user.featureFlags.includes(featureFlagKey);
        }
    }

    @action init(): void {
        this.company = {};
        this.dataLoaded = false;
        this.showSupportScreen = false;
        if (!this.user) {
            this.trialBanner = {show: false, daysLeft: null};
        };
        this.loadingState = LoadingState.Init;
    }

    @action destroy(): void {
        this.user = null;
        this.signUpUser = null;
        this.company = {};
        this.dataLoaded = false;
        this.showSupportScreen = false;
        this.trialBanner = {show: false, daysLeft: null};
        this.loadingState = LoadingState.Init;
    }

    @action setTrialBanner(user: User): void {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const today = new Date();
        const trialExpires = !user?.addedCard && user?.trialExpires && new Date(user?.trialExpires);
        const diffDays: number = Math.round(Math.abs(((today as any) - (trialExpires as any)) / oneDay));
        const displayingBanner = Number.isInteger(diffDays) && (today as any) < (trialExpires as any);
        this.trialBanner = {show: displayingBanner, daysLeft: diffDays};
    }

    @action setUser(user: User): void {
        this.user = user;
        this.setTrialBanner(user);
    }

    @action addUnreadMessagesDot(type: 'customerThreads' | 'teamConversations', id: string): void {
        if (!this.user.newMessages) { // remove when BE for unread message tab notification is done
            return;
        };
        this.user = {
            ...this.user,
            newMessages: {
                ...this.user.newMessages,
                [type]: [...this.user.newMessages[type], id],
            }
        };
    };

    @action removeUnreadMessagesDot(type: 'customerThreads' | 'teamConversations', id: string): void {
        if (!this.user.newMessages) { // remove when BE for unread message tab notification is done
            return;
        };
        this.user = {
            ...this.user,
            newMessages: {
                ...this.user.newMessages,
                [type]: this.user.newMessages && this.user.newMessages[type].filter(el => el !== id),
            }
        };
    };

    @action setSignupUser(user: User): void {
        this.signUpUser = user;
    }

    @action setShowSupportScreen(val: boolean): void {
        this.showSupportScreen = val;
    }

    @action getAndSetCompanyDetails = (errAction?: string, firstLoading = false) => {
        this.loadingState = firstLoading ? LoadingState.FirstLoading : LoadingState.Loading;
        getCompanyDetails().then(
            ({ data: company }) => {
                runInAction(() => {
                    this.company = company;
                    this.dataLoaded = true;
                    this.loadingState = LoadingState.Loaded;
                });
            },
            (err) => {
                runInAction(() => {
                    if (err.response.status === 401) {
                        AuthStore.resetAuth();
                    } else {
                        if (this._onError && errAction) {
                            this._onError(errAction);
                        };
                    };
                })
            }
        );
    };

    @action updateCompanyDetails = (companyName: string, address: string, errAction?: string) => {
        this.loadingState = LoadingState.Loading;
        editCompanyDetails(companyName, address).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                // return this.getAndSetCompanyDetails('getBE');
            },
            () => {
                runInAction(() => {
                    if (this._onError && errAction) {
                        this._onError(errAction, { companyName, address });
                    }
                });
            }
        );
    };

    @action updateOperationHours = (timezone: string, hours: [], errAction: string) => {
        this.loadingState = LoadingState.Loading;
        updateCompanyOperationHours(timezone, hours).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return this.getAndSetCompanyDetails('getBE');
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { timezone, hours });
                    }
                });
            }
        );
    };
}

export default new UserStore();
