import React from 'react';
import { ReactComponent as RemoveSvg } from '@src/theme/icons/remove.svg';
import './RecipientItem.sass';
import { RecipientOption } from './BroadcastReceipientsLocalStore.store';

interface RecipientItemProps {
    icon?: React.ReactNode;
    option: RecipientOption;
    selected?: boolean;
    showRemoveButton?: boolean;
    onRemoveClick?: (option: RecipientOption) => void;
};

const RecipientItem: React.FunctionComponent<RecipientItemProps> = ({ icon, option, selected, showRemoveButton, onRemoveClick }) => (
    <div className={`bar-item ${selected && 'bar-item__selected'}`}>
        <div className="title-container">
            {icon}
            <span>{option.label ?? option.value ?? ''}</span>
        </div>
        {showRemoveButton && 
            <div className="item-button red">
                <RemoveSvg onClick={() => onRemoveClick && onRemoveClick(option)}/>
            </div>
        }
    </div>
);

export default RecipientItem;
