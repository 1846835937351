import React, { useEffect, useState } from 'react';
import { Dropdown } from '@src/components/Dropdown/Dropdown';
import { Dialog } from '@src/components/Dialog/Dialog';
import { ReviewDate } from '@src/stores/models/review-filter';
import { TimePeriod } from '@src/stores/models/dashboard';
import { DateRange as ReactDateRange } from 'react-date-range';
import { DateRange, hasSelection } from '@src/pages/Reviews/ReviewsFilters';
import { dateRangeFormat } from '@src/theme/utils/constants';
import { fixDateRange } from '@src/pages/Dashboard/helpers/date';

export const timePeriods = [
    {
        name: 'All',
        short: 'all',
        date: ReviewDate.ALL,
    },
    {
        name: 'This Year',
        short: 'year',
        date: ReviewDate.THIS_YEAR,
    },
    {
        name: 'This Month',
        short: 'month',
        date: ReviewDate.THIS_MONTH,
    },
    {
        name: 'This Week',
        short: 'week',
        date: ReviewDate.THIS_WEEK,
    },
    {
        name: 'Today',
        short: 'day',
        date: ReviewDate.TODAY,
    },
    {
        name: 'Custom',
        short: 'custom',
        date: ReviewDate.CUSTOM,
    },
];

export const defaultPeriodNumber = 2;

interface PeriodDropdownProps {
    onSelectPeriod: (period: TimePeriod) => void;
};

const PeriodDropdown: React.FunctionComponent<PeriodDropdownProps> = ({ onSelectPeriod }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState<TimePeriod>(timePeriods[defaultPeriodNumber]);
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const handlePeriodSelect = (i: number) => {
        const period = timePeriods[i];
        if (period.date === ReviewDate.CUSTOM) {
            setDialogOpen(true);
        }
        setSelectedPeriod(period);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDateRangeChange = (selection: DateRange) => {
        setDateRange(selection)
    };

    const handleAcceptCustomRange = () => {
        setDialogOpen(false);
        const fixedRange = fixDateRange(dateRange);
        setSelectedPeriod({
            ...selectedPeriod,
            firstDay: fixedRange.startDate,
            lastDay: fixedRange.endDate,
        });
    };

    useEffect(() => {
        if (selectedPeriod.date !== ReviewDate.CUSTOM || (selectedPeriod.date === ReviewDate.CUSTOM && selectedPeriod.firstDay && selectedPeriod.lastDay)) {
            onSelectPeriod(selectedPeriod);
        }
    }, [selectedPeriod])

    return (
        <>
            <Dropdown
                optionsArr={timePeriods.map((el) => el.name)}
                selOptionNumber={defaultPeriodNumber}
                darkColor={true}
                optionSelected={handlePeriodSelect}
            />
            <Dialog
                open={dialogOpen}
                title="Select custom date"
                onClose={handleDialogClose}
            >
                <div className="date-range-select">
                    <ReactDateRange
                        editableDateInputs={true}
                        onChange={(item) => { if (hasSelection(item)) {handleDateRangeChange(item.selection as DateRange)}}}
                        moveRangeOnFirstSelection={false}
                        dateDisplayFormat={dateRangeFormat}
                        ranges={[dateRange]}
                    />
                    <button className="btn-primary" onClick={handleAcceptCustomRange}>
                        Accept
                    </button>
                </div>
            </Dialog>
        </>
    );
};

export default PeriodDropdown;
