import React from 'react';
import { EmptyMessagesCard } from './EmptyMessagesCard/EmptyMessagesCard';
import { ResponseTimes } from './ResponseTimes';
import { useHistory } from 'react-router-dom';
import { images } from '@src/theme/icons/dashboard';
import { LoadingState } from '@src/stores/models';
import {
    STORES,
    WithDashboardStoreProps,
    WithProfileStoreProps,
    withStores,
} from '@src/stores/with-store';
import { ResponseHistogramChart } from './ResponseHistogramChart';
import { ResponsesInOutChart } from './ResponsesInOutInfoChart';
import { ConversationsBreakdownChart } from './ConversationsBreakdownChart';

import '../../dashboard.sass';
import { timePeriodToString } from '../../helpers/date';
import { Loader } from '@src/components/Loader/Loader';

const MessagesStatsWithStore: React.FunctionComponent<
    WithDashboardStoreProps & WithProfileStoreProps
> = ({ DashboardStore, ProfileStore }) => {
    const history = useHistory();

    const renderConversationsBreakdownInfo = (
        <ConversationsBreakdownChart
            dataChart={DashboardStore.conversationsBreakdownInfo}
            title="Conversations Segments"
            icon={images.messageB}
            barSize={6}
            margin={{ top: 30, right: -30, left: 0, bottom: 0 }}
        />
    );

    const renderResponseInOutboundInfo = (
        <ResponsesInOutChart
            dataChart={DashboardStore.responseInOutboundInfo}
            title="Messages Exchange"
            icon={images.reviewsB}
            barSize={6}
            margin={{ top: 4, right: -30, left: 0, bottom: 0 }}
        />
    );

    const renderResponseHistogramInfo = (
        <ResponseHistogramChart
            dataChart={DashboardStore.responseHistogramInfo}
            title="Response Times"
            icon={images.dataB}
            barSize={6}
            margin={{ top: 30, right: -30, left: 0, bottom: 0 }}
        />
    );

    const renderResponseTime = DashboardStore.period && (
        <ResponseTimes
            stats={DashboardStore.responseTimeStats}
            period={timePeriodToString(DashboardStore.period)}
        />
    );

    const renderMessagesStats =
        DashboardStore.loadingLeaderboard === LoadingState.Loaded &&
        ProfileStore.loading === LoadingState.Loaded &&
        !ProfileStore.profile?.message ? (
            <EmptyMessagesCard onClick={() => history.push({ pathname: `/messages/` })} />
        ) : (
            <>
                {renderResponseTime}
                <div className="dashboard__row">
                    {renderResponseHistogramInfo}
                    {renderResponseInOutboundInfo}
                </div>
                {/* <div className="dashboard__row">{renderConversationsBreakdownInfo}</div> */}
            </>
        );

    return (
        <div className="dashboard__stats-container">
            <Loader loadingState={DashboardStore.loadingLeaderboard} />
            {renderMessagesStats}
        </div>
    );
};

export const MessagesStats = withStores(MessagesStatsWithStore, [STORES.Dashboard, STORES.Profile]);
