import React from 'react';
import { useHistory } from "react-router-dom";
import { images } from '@src/theme/images';
import { images as localImages } from './assets/images';

type NewBroadcastProps =  {
    userRole: number;
};

export const UpgradeToUseBroadcasts: React.FunctionComponent<NewBroadcastProps> = ({ userRole }) => {
    const history = useHistory();

    return (
        <div className="Broadcasts__Upgrade card">
            <div className="Broadcasts__Upgrade-inside">
                <div className="Broadcasts__Upgrade-inside-title">Broadcast Messaging</div>
                <div className="Broadcasts__Upgrade-inside-description-item withDiagram">
                    <div className="diagram">
                        <img src={localImages.broadcast1} alt="broadcast" />
                        {/* <img src={localImages.helloFirstname} alt="hello Customer First Name" className="firstname" />
                            <span> &#8658; </span>
                            <img src={localImages.helloMike} alt=" hello Mike" className="mike" />
                            <span>, </span>
                            <img src={localImages.helloJack} alt="hello Jack" className="jack" /> */}
                    </div>
                </div>
                <div className="Broadcasts__Upgrade-inside-description">
                    <div className="Broadcasts__Upgrade-inside-description-item">📬 Broadcast personalized messages to targeted audiences while maintaining individual two-way conversations</div>
                    <div className="Broadcasts__Upgrade-inside-description-item">🕰️ Schedule when broadcasts are sent</div>
                    <div className="Broadcasts__Upgrade-inside-description-item">🙋‍♀️ Retarget your audience by response/non-response</div>

                </div>
                {userRole === 2 ?
                    <div className="Broadcasts__Upgrade-inside-action">
                        <span className="bolder">Interested?</span> Reach out to your manager to upgrade your account & enble broadcasts.
                    </div>
                :
                    <div className="Broadcasts__Upgrade-inside-action">
                        <div>Upgrade your account to reach more customers faster. <span className="bolder">Interested?</span></div>
                        <button
                            className="btn-primary-full"
                            onClick={() => history.push('/settings/billing?finishTrial')}
                        >
                            Yes, upgrade now
                        </button>
                    </div>
                }
            </div>
        </div>
    );
};
