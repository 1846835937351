import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AsYouType } from 'libphonenumber-js';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import {
    STORES,
    withStores,
    WithConversationsStoreProps,
    WithMessagesStoreProps,
} from '@src/stores/with-store';
import { images } from '@src/theme/images';
import { ReactComponent as MessageIcon } from '@src/theme/icons/message-grey.svg';
import { ReactComponent as TagIcon } from '@src/theme/icons/tag_m.svg';
import { ReactComponent as People } from '@src/theme/icons/people.svg';
import { ReactComponent as ProfileEditIcon } from '@src/theme/icons/profile-edit.svg';
import { ReactComponent as Tag } from '@src/theme/icons/tag-outlined-grey.svg';
import { TagsChannelsEdition } from '@src/pages/Messages/components/Edition/TagsChannelsEdition';
import { Dialog } from '@src/components/Dialog/Dialog';
import { Customer, Notes, RoleTxt } from '@src/stores/models';
import { TwilioPhone } from '@src/components';

import './chat-info.sass';
import '../messages-common.sass';

interface ChatInfoProps {
    type: string;
    customer?: Customer;
    notes?: any;
    tags?: any;
    ffBrowserCallingEnabled?: boolean;
    showNotes?: () => void;
    showCustomerEdition?: (id: string) => void;
}

type props =
    ChatInfoProps
    & WithConversationsStoreProps
    & WithMessagesStoreProps;

const ChatInfoWithStore: React.FC<props> = (props) => {
    const ConversationsStore = props.ConversationsStore;
    const [tags, setTags] = useState(props.tags);

    const viewCustomer = props.type === 'customer';
    const viewTeamDirect = props.type === 'teamDirect';
    const viewTeamChannel = props.type === 'teamChannel';

    const userRole = props.MessagesStore.ThreadsStore?.constants?.employee?.role;
    const isOwnerOrLeader = userRole === 0 || userRole === 1;

    const notesArr: Notes[] = props.notes && Object.values(props.notes);
    const notesToDisplay =
        props.notes &&
        notesArr.sort((event1, event2) => {
            return new Date(event1.created).getTime() - new Date(event2.created).getTime();
        });
    // let attachments = [];
    // Object.values(messages).forEach(m => attachments = attachments.concat(m.attachments));

    const history = useHistory();
    const [openEdition, setOpenEdition] = useState(false);
    const [openLeaveChannelConfirmation, setOpenLeaveChannelConfirmation] = useState(props.tags);

    const closeEdition = () => {
        setOpenEdition(false);
    };

    const groupDeleted = (currentThread: boolean) => {
        if (currentThread) {
            history.replace({ pathname: '/messages/' });
        }
        closeEdition();
    };

    useEffect(() => {
    	setTags(props.tags)
    }, [props.tags]);

    const editIconClicked = () => {
        if (viewCustomer) {
            props.showCustomerEdition(props.customer.id);
        } else if (viewTeamChannel) {
            const conversationId = ConversationsStore.SelectedChannelStore.selectedChannel.id;
            const conversationName = ConversationsStore.SelectedChannelStore.selectedChannel.channelName;
            ConversationsStore.EditedChannelStore.setChannelEdition({ conversationId, name: conversationName }).then(() => {
                setOpenEdition(true);
            });
        };
    };

    const confirmLeavingChannel = () => {
        ConversationsStore.SelectedChannelStore.leaveChannel()
        .then(() => {
            history.replace({ pathname: '/messages/' });
            toast.success(`You left ${ConversationsStore.SelectedChannelStore.selectedChannel.channelName}`, { autoClose: 4000 });
            ConversationsStore.SelectedChannelStore.closeConversation();
        })
    };

    // ConversationsStore.SelectedDirectConversationStore.deleteConversation('c9a2276321aa4370b67019eacb125b9c')

    const header =
        <div className="header">
            <div className="left">
                <div>{
                    viewCustomer ? 'Customer' :
                    viewTeamChannel ? 'Group' : 'Employee'} Info</div>
            </div>
            <div className="right">
                {(viewCustomer
                    || (viewTeamChannel && isOwnerOrLeader)
                ) &&
                    <button
                        onClick={() => editIconClicked()}
                        title={viewCustomer ? "More Info & Edit Customer" : "Edit Group"}
                    >
                        <ProfileEditIcon />
                    </button>
                }
            </div>
        </div>

    const customerInfo = viewCustomer &&
                <div className="content">
                    <div className="info-section">
                        <img src={images.phone} alt="phone icon" />
                        {!props.customer?.phone ? (
                            <div className="phone">
                                <span className="notAction addBeside">Not entered</span>
                                <span className="add" onClick={() => props.showCustomerEdition(props.customer.id)}>Add</span>
                            </div>
                        ) : (
                            <div>
                                {props.ffBrowserCallingEnabled ? (
                                    <div
                                        title={`Call ${props.customer?.firstName || new AsYouType('US').input(('' + props.customer?.phone).replace(/\D/g, ''))} ${props.customer?.lastName || ''}`}
                                    >
                                        <TwilioPhone phoneNumber={+props.customer.phone.replace(/\D/g, '')} />
                                    </div>
                                ) : (
                                    <span className="notAction">
                                        {new AsYouType('US').input(('' + props.customer.phone).replace(/\D/g, ''))}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="info-section">
                        <MessageIcon />
                        {!props.customer?.email ? (
                            <div className="email">
                                <span className="notAction addBeside">Not entered</span>
                                <span className="add" onClick={() => props.showCustomerEdition(props.customer.id)}>Add</span>
                            </div>
                        ) : (
                            <a
                                href={`mailto:${props.customer.email}`}
                                title={`Email ${props.customer?.firstName} ${props.customer?.lastName}`}
                            >
                                {props.customer.email}
                            </a>
                        )}
                    </div>
                    <div className="info-section">
                        <img src={images.notes} alt="notes icon" />
                        <div className="notes">
                            {!notesToDisplay?.length ? (
                                <>
                                    <span className="notAction addBeside">No Notes</span>
                                    <span className="add" onClick={() => props.showNotes()}>Add</span>
                                </>
                            ) : (
                                <span
                                    className="browse"
                                    title="See & add notes"
                                    onClick={() => props.showNotes()}
                                >
                                    Add/Browse Notes
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="info-section">
                        <TagIcon />
                        <div className="tags">
                            {tags && tags.length ?
                                <span className="notAction">
                                    {tags.map(t => t.value).join(', ')}
                                </span>
                                : <h5>No tags assigned</h5>
                            }
                        </div>
                    </div>
                </div>

    const dialogWithEdition =
        <Dialog
            className="LeftMenu__dialog"
            open={openEdition}
            onClose={() => closeEdition()}
        >
            <TagsChannelsEdition
                type={viewTeamChannel ? 'Group' : null}
                isNew={false}
                onDelete={groupDeleted}
            />
        </Dialog>

    const teamDirectInfo = viewTeamDirect &&
            <div className="content">
                <div className="info-section">
                    <img src={images.phone} alt="phone icon" />
                    {ConversationsStore.SelectedDirectConversationStore.selectedDirect?.colleagueDetails?.email ?
                        <span className="notAction">
                            {new AsYouType('US').input(('' + ConversationsStore.SelectedDirectConversationStore.selectedDirect?.colleagueDetails?.phone).replace(/\D/g, ''))}
                        </span>
                    :
                        <span className="notAction">Not entered</span>
                    }
                </div>
                <div className="info-section">
                    <MessageIcon />
                    {ConversationsStore.SelectedDirectConversationStore.selectedDirect?.colleagueDetails?.email ?
                        <a
                            href={`mailto:${ConversationsStore.SelectedDirectConversationStore.selectedDirect?.colleagueDetails?.email}`}
                            title={`Email ${ConversationsStore.SelectedDirectConversationStore.selectedDirect?.colleagueName}`}
                        >
                            {ConversationsStore.SelectedDirectConversationStore.selectedDirect?.colleagueDetails?.email}
                        </a>
                    :
                        <span className="notAction">Not entered</span>
                    }
                </div>
                <div className="info-section gap">
                    {ConversationsStore.SelectedDirectConversationStore.selectedDirect?.colleagueDetails?.role > -1 &&
                        <span className="notAction">Role in Team: {RoleTxt[ConversationsStore.SelectedDirectConversationStore.selectedDirect?.colleagueDetails?.role]}</span>
                    }
                </div>
                <div className="info-section">
                    {ConversationsStore.SelectedDirectConversationStore.selectedDirect?.colleagueDetails?.timeZone &&
                        <span className="notAction">Time Zone: {ConversationsStore.SelectedDirectConversationStore.selectedDirect?.colleagueDetails?.timeZone}</span>
                    }
                </div>
                <div className="info-section">
                    {ConversationsStore.SelectedDirectConversationStore.selectedDirect?.colleagueDetails?.status === 'INACTIVE' &&
                        <span className="notAction">This employee is inactive.</span>
                    }
                </div>
                <div className="info-section">
                    {ConversationsStore.SelectedDirectConversationStore.selectedDirect?.colleagueDetails?.status === 'ARCHIVED' &&
                        <span className="notAction">This employee is archived.</span>
                    }
                </div>
            </div>

    const teamChannelInfo = viewTeamChannel &&
        <div className="content">
            <div className="info-section">
                <p>
                    <People /> Employees ({ConversationsStore.SelectedChannelStore.totalCurrentMembers})
                </p>
                <div className="groupMembersList channel">
                    {/* <InfiniteScroll
                        pageStart={0}
                        loadMore={loadMoreCurrentMembers}
                        initialLoad={false}
                        hasMore={ConversationsStore.SelectedChannelStore.hasMoreCurrentMembersToLoad}
                        useWindow={false}
                        threshold={1500}
                    > */}
                        {ConversationsStore.SelectedChannelStore.currentMembers?.map((member) => {
                            return (
                                <div key={uuidv4()}>{member.name}</div>
                            );
                        })}
                    {/* </InfiniteScroll> */}
                </div>
            </div>
            <button
                className="btn-secondary leave"
                onClick={() => setOpenLeaveChannelConfirmation(true)}
            >
                Leave Channel
            </button>
            {dialogWithEdition}
            <Dialog
                className="LeftMenu__dialog"
                open={openLeaveChannelConfirmation}
                onClose={() => setOpenLeaveChannelConfirmation(false)}
            >
                <div className="TagsChannelsEdition__deletingTagGroup">
                    <div className="TagsChannelsEdition__deletingTagGroup-image">
                        <img src={images.deleteTagGroup} alt="delete" />
                    </div>
                    <div className="TagsChannelsEdition__deletingTagGroup-confirm">
                        <div className="TagsChannelsEdition__deletingTagGroup-confirm-question">
                            Are you sure you want to leave {ConversationsStore.SelectedChannelStore?.selectedChannel?.channelName}?
                        </div>
                        <div className="TagsChannelsEdition__deletingTagGroup-confirm-actions">
                            <button
                                className="btn-secondary"
                                onClick={() => setOpenLeaveChannelConfirmation(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn-primary"
                                onClick={() => confirmLeavingChannel()}
                            >
                                Leave
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>

    return (
        <section className="ChatInfo">
            <div className="card withDarkHeader">
                {header}
                {customerInfo || teamDirectInfo || teamChannelInfo}
            </div>
        </section>
    );
};

export const ChatInfo = withStores(ChatInfoWithStore, [
    STORES.ConversationsStore,
    STORES.Messages
]);
