import React, { useLayoutEffect } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { onSuccess } from '@src/theme/utils/constants';
import { withPersonalSettingsStore, WithPersonalSettingsStoreProps } from '@src/stores/with-store';
import { FormField, ErrorToastSave, Loader } from '@src/components';
import { LoadingState, OnErrorDataPersonalSettings } from '@src/stores/models';
import UserStore from '@src/stores/user.store';

import './password.sass';

interface Passwords {
    currentPassword: string;
    newPassword: string;
}

export const PasswordWithStore: React.FC<WithPersonalSettingsStoreProps> = (
    props: WithPersonalSettingsStoreProps
) => {
    const { register, handleSubmit, errors, reset } = useForm();
    const store = props.PersonalSettingsStore;
    const loading = store.loadingState;

    const onErrorWrongOldPassword = () => {
        toast.error('The old password you provided is wrong. Please provide the correct one.');
    };

    store.onError = (type: string, data?: OnErrorDataPersonalSettings) => {
        store.loadingState = LoadingState.Error;
        switch (type) {
            case 'changePwd': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.changePwd(data.currentPassword, data.newPassword, 'changePwd')
                        }
                    />
                );
                break;
            }
            default: {
                return;
            }
        }
    };
    store.onSuccess = () => {
        store.loadingState = LoadingState.Loaded;
        onSuccess();
    };
    store.onErrorWrongOldPassword = () => {
        store.loadingState = LoadingState.Error;
        onErrorWrongOldPassword();
    };

    const updatePassword = (data: Passwords) => {
        reset();
        store.changePwd(data.currentPassword, data.newPassword, 'changePwd');
    };

    useLayoutEffect(() => {
        store.init();
    }, []);

    if (UserStore.user.employeeFromExternalAuth) {
        return;
    };

    return (
        <div className="Password">
            <Loader loadingState={loading} />
            <div className="Password__title">Change password</div>
            <div className="Password__subtitle">
                Strong passwords include numbers, letters and punctuation marks.
            </div>
            <form className="Password__content" onSubmit={handleSubmit(updatePassword)}>
                <FormField
                    label="Current password"
                    htmlFor="currentPassword"
                    error={errors.currentPassword}
                >
                    <input
                        type="password"
                        name="currentPassword"
                        autoComplete="current-password"
                        className={classNames('form-input', { error: errors.currentPassword })}
                        ref={register({ required: 'No current password' })}
                    />
                </FormField>
                <FormField label="New password" htmlFor="newPassword" error={errors.newPassword}>
                    <input
                        type="password"
                        name="newPassword"
                        autoComplete="new-password"
                        className={classNames('form-input', { error: errors.newPassword })}
                        placeholder="Minimum 8 characters"
                        ref={register({
                            required: 'No new password',
                            minLength: {
                                value: 8,
                                message: 'Password must be at least 8 characters long',
                            },
                        })}
                    />
                </FormField>
                <button className="btn-primary">Change</button>
            </form>
        </div>
    );
};

export const Password = withPersonalSettingsStore(PasswordWithStore);
