import React, { useState } from 'react';

import './toggle-tab.sass';

interface ToggleTabProps {
    commentBefore?: string;
    commentAfter?: string;
    isChecked: boolean;
    stateChanged: (newState: boolean) => void;
    disabled?: boolean;
}

export const ToggleTab: React.FC<ToggleTabProps> = (props) => {
    const [isChecked, setIsChecked] = useState(props.isChecked);
    const changeState = () => {
        if (isChecked == undefined || isChecked == null) {
            props.stateChanged(!props.isChecked);
            setIsChecked(!props.isChecked);
        } else {
            props.stateChanged(!isChecked);
            setIsChecked(!isChecked);
        }
    };

    return (
        <div className="ToggleTab">
            <span className="ToggleTab__comment">{props.commentBefore}</span>
            <label className="ToggleTab__switch">
                <input type="checkbox" defaultChecked={props.isChecked} disabled={props.disabled} />
                <span className="slider" onClick={() => changeState()}></span>
            </label>
            <span className="ToggleTab__comment">{props.commentAfter}</span>
        </div>
    );
};
