import { Dialog } from '@src/components/Dialog/Dialog';
import { FormField } from '@src/components/FormField/FormField';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as EyeOpen } from '@src/theme/icons/eye-open.svg';
import { ReactComponent as EyeClosed } from '@src/theme/icons/eye-closed.svg';

import '../Signup.sass';

export interface FormData {
    password: string;
};

interface PwdDialogProps {
    onClose: () => void;
    onSubmit: (formData: FormData) => void;
};

const PwdDialog: React.FunctionComponent<PwdDialogProps> = ({ onClose, onSubmit }) => {
    const { register, handleSubmit, errors } = useForm();
    const [ isShowPwd, setIsShowPwd ] = useState(false);
    const [formData, setFormData] = useState<FormData>({ password: '' });

    return (
        <Dialog
            className="Signup__PwdDialog"
            open={true}
            onClose={onClose}
        >
            <div className="Signup__PwdDialog-instructions">
                <span>An account already exists for provided email address. If it is yours, please input your password below.</span>
            </div>
            <form className="Signup__form" onSubmit={handleSubmit(onSubmit)}>
                <FormField label=" " htmlFor="password" error={errors.password}>
                    <div className="password-field">
                        <input
                            type={isShowPwd ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            defaultValue={formData.password}
                            className={classNames('form-input', { error: errors.password })}
                            ref={(e) => {
                                register(e, {
                                    required: 'Required',
                                    minLength: {
                                        value: 8,
                                        message: 'At least 8 characters long',
                                    },
                                });
                            }}
                            autoComplete="new-password"
                            onChange={(e) => setFormData({password: e.target.value})}
                        />
                        <button
                            type="button"
                            onClick={() => setIsShowPwd(prevShowPwd => !prevShowPwd)}
                        >
                            {isShowPwd ? <>hide <EyeClosed/></> : <>show <EyeOpen/></>}
                        </button>
                    </div>
                </FormField>
                <div className="Signup__PwdDialog__action-bar">
                    <button
                        className="btn-primary"
                        type="submit"
                        disabled={formData.password.length === 0}
                    >
                        Confirm
                    </button>
                </div>
            </form>
        </Dialog>
    )
};

export default PwdDialog;
