import { DateRange } from '@src/pages/Reviews/ReviewsFilters';
import { TimePeriod } from '@src/stores/models/dashboard';
import { setHours, setMinutes, setSeconds } from 'date-fns';
import moment from 'moment';

export const getPeriodToCompare = (period: string): string => {
    return period === 'This Year'
        ? 'last year'
        : period === 'This Month'
        ? 'last month'
        : period === 'This Week'
        ? 'last week'
        : period === 'Today'
        ? 'last day'
        : null;
};

export const timePeriodToString = (period?: TimePeriod): string => {
    if (!period || period.short === 'all') return '';
    if (period.short === 'custom') return selectedPeriodDates(period.firstDay, period.lastDay);
    return period.name;
};

export const fixDateRange = (range: DateRange): DateRange => {
    const result = {...range};
    result.startDate = setHours(result.startDate, 0);
    result.startDate = setMinutes(result.startDate, 0);
    result.startDate = setSeconds(result.startDate, 0);
    result.endDate = setHours(result.endDate, 23);
    result.endDate = setMinutes(result.endDate, 59);
    result.endDate = setSeconds(result.endDate, 59);
    return result;
};

export const selectedPeriodDates = (customFirstDay: Date, customLastDay: Date) => {
  const firstDay = {
      year: '',
      month: '',
      day: moment(customFirstDay).format('D'),
  };
  const lastDay = {
      year: '',
      month: '',
      day: moment(customLastDay).format('D'),
  };

  if (
      moment().format('YY') == moment(customFirstDay).format('YY') && // ten sam rok co obecnie
      moment().format('YY') == moment(customLastDay).format('YY')
  ) {
      firstDay.year = '';
      lastDay.year = '';
  } else if (moment(customFirstDay).format('YY') == moment(customLastDay).format('YY')) {
      // wcześniejszy ten sam rok
      firstDay.year = moment(customFirstDay).format('YYYY');
      lastDay.year = '';
  } else {
      // różne lata
      firstDay.year = moment(customFirstDay).format('YYYY');
      lastDay.year = moment(customLastDay).format('YYYY');
  }

  if (moment(customFirstDay).format('MM') == moment(customLastDay).format('MM')) {
      //te same miesiaće
      firstDay.month = moment(customFirstDay).format('MMM');
      lastDay.month = '';
  } else {
      // różne miesiące
      firstDay.month = moment(customFirstDay).format('MMM');
      lastDay.month = moment(customLastDay).format('MMM');
  }

  return `${firstDay.year} ${firstDay.month} ${firstDay.day} - ${lastDay.year} ${lastDay.month} ${lastDay.day}`
      .replace(/ +(?= )/g, '')
      .trim();
};

