import { action, observable, runInAction } from 'mobx';
import { changePeriod, getGeneralDashInfo, getThreads } from '@src/requests';
import {
    LoadingState,
    Employee,
    ConvertedLeaderboardItem,
    KeyMetricsStats,
    MessagesInfo,
    ResponseHistogramInfo,
    ResponseInOutboundInfo,
    ConversationsBreakdownInfo,
    CompanyInfo,
    TimePeriod,
    ReviewsPerSiteStats,
    ResponseTimeStats,
    ThreadSearchResult,
    ReviewWithReplies,
    ReviewSource,
} from './models';
import AuthStore from '@src/stores/auth.store';

export class DashboardStore {
    @observable loadingLeaderboard: LoadingState = LoadingState.Init;
    @observable loadingGeneral: LoadingState = LoadingState.Init;
    @observable leaderboardStats: ConvertedLeaderboardItem[] = [];
    @observable reviewsStats: KeyMetricsStats = null;
    @observable messagesInfo: MessagesInfo = null;
    @observable responseHistogramInfo: ResponseHistogramInfo = {};
    @observable responseInOutboundInfo: ResponseInOutboundInfo[] = [];
    @observable conversationsBreakdownInfo: ConversationsBreakdownInfo[] = [];
    @observable totalMessageSegments: number = 0;
    @observable companyInfo: CompanyInfo = null;
    @observable reviewsPerSiteStats: ReviewsPerSiteStats = null;
    @observable reviewsPerSiteStatsAll: ReviewsPerSiteStats = null;
    @observable responseTimeStats: ResponseTimeStats = null;
    @observable employees = [];
    @observable employee = null;
    @observable threads: ThreadSearchResult[] = [];
    @observable period: TimePeriod = null;
    @observable customerCount: number = 0;
    @observable customersInTimePeriodCount: number = 0;
    @observable latestReviews: ReviewWithReplies[] = [];
    @observable connectedSources: ReviewSource[];
    @observable phone: string | null = null;

    @action init(): void {
        this.leaderboardStats = [];
        this.reviewsStats = null;
        this.messagesInfo = null;
        this.responseHistogramInfo = {};
        this.responseInOutboundInfo = [];
        this.conversationsBreakdownInfo = [];
        this.totalMessageSegments = 0;
        this.companyInfo = null;
        this.loadingLeaderboard = LoadingState.Init;
        this.loadingGeneral = LoadingState.Init;
        this.reviewsPerSiteStats = null;
        this.reviewsPerSiteStatsAll = null;
        this.threads = [];
        this.period = null;
        this.customerCount = 0;
        this.customersInTimePeriodCount = 0;
        this.latestReviews = [];
        this.connectedSources = [];
        this.phone = null;
        this.employees = [];
        this.employee = null;
        this.responseTimeStats = null;
    }

    @action setNewPeriod(data: TimePeriod, onError?: (message) => void) {
        this.loadingLeaderboard = LoadingState.Loading;
        this.period = data;
        return changePeriod(
            data.short === 'custom'
                ? {
                      startDate: Math.round(data.firstDay.getTime() / 1000),
                      endDate: Math.round(data.lastDay.getTime() / 1000),
                  }
                : { timePeriod: data.short }
        ).then(
            (results) => {
                runInAction(() => {
                    const converted = this.employees
                        .map((el: Employee) => {
                            if (results.data.leaders[el.id]) {
                                return {
                                    name:
                                        this.employee.id == el.id
                                            ? `${el.firstName} ${el.lastName} (You)`
                                            : `${el.firstName} ${el.lastName}`,
                                    conversations: results.data.leaders[el.id].conversations,
                                    credited: results.data.leaders[el.id].credited,
                                    sent: results.data.leaders[el.id].sent,
                                    responses: results.data.leaders[el.id].responses,
                                    avgResponseTime: results.data.leaders[el.id].avgResponseTime,
                                    rating: results.data.leaders[el.id].rating,
                                };
                            }
                        })
                        .filter((el: ConvertedLeaderboardItem) => el)
                        .sort((a: ConvertedLeaderboardItem, b: ConvertedLeaderboardItem) =>
                            a.rating < b.rating || !a.rating ? 1 : -1
                        );

                    this.leaderboardStats = converted;
                    this.reviewsStats = results.data.statsData;
                    this.responseHistogramInfo = { ...results.data.responseHistogram };
                    this.responseInOutboundInfo = [...results.data.inOutboundBreakdown];
                    this.conversationsBreakdownInfo = [...results.data.conversationsBreakdown];
                    this.totalMessageSegments = results.data.totalMessageSegments;
                    this.responseTimeStats = {
                        employeeResponses: results.data.responseTimes.employee.totalResponses,
                        employeeResponseTime: results.data.responseTimes.employee.avgTime,
                        locationResponses: results.data.responseTimes.location.totalResponses,
                        locationResponseTime: results.data.responseTimes.location.avgTime,
                    };
                    if (data.short === 'all') {
                        this.reviewsPerSiteStatsAll = results.data.statsData.stats.reduce(
                            (reviewsObject, stat) => {
                                const sourceKey = stat.source.name;
                                reviewsObject[sourceKey] = stat;
                                return reviewsObject;
                            },
                            {}
                        );
                    }

                    this.reviewsPerSiteStats = results.data.statsData.stats.reduce(
                        (reviewsObject, stat) => {
                            const sourceKey = stat.source.name;
                            reviewsObject[sourceKey] = stat;
                            return reviewsObject;
                        },
                        {}
                    );
                    this.latestReviews = results.data.latestReviews;
                    this.customerCount = results.data.customerCount;
                    this.customersInTimePeriodCount = results.data.customersInTimePeriodCount;
                    this.loadingLeaderboard = LoadingState.Loaded;
                });
            },
            (err) => {
                if (err.response.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    if (onError) {
                        onError(err.message);
                    }
                    runInAction(() => {
                        this.loadingLeaderboard = LoadingState.Error;
                    });
                }
            }
        );
    }

    @action getGeneralDashInfo(onError?: (message) => void) {
        this.loadingGeneral = LoadingState.Loading;
        return getGeneralDashInfo().then(
            (res) => {
                runInAction(() => {
                    this.messagesInfo = { ...res.data.messageData };
                    this.companyInfo = {
                        name: res.data.companyName,
                        city: res.data.address,
                    };
                    this.employees = [...res.data.employees];
                    this.employee = { ...res.data.employee };
                    this.connectedSources = [...res.data.connectedSources];
                    this.phone = res.data.phone?.length > 0 ? res.data.phone : null;
                    this.loadingGeneral = LoadingState.Loaded;
                });
            },
            (err) => {
                runInAction(() => {
                    if (onError) {
                        onError(err.message);
                    }
                    this.loadingGeneral = LoadingState.Error;
                });
            }
        );
    }

    @action getThreads(onError?: (message) => void) {
        this.loadingGeneral = LoadingState.Loading;
        return getThreads().then(
            (res) => {
                runInAction(() => {
                    this.threads = [...res.data.threads];
                    this.loadingGeneral = LoadingState.Loaded;
                });
            },
            (err) => {
                if (err.response.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    if (onError) {
                        onError(err.message);
                    }
                    runInAction(() => {
                        this.loadingGeneral = LoadingState.Error;
                    });
                }
            }
        );
    }
}

export default new DashboardStore();
