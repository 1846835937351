import { IObservableValue } from 'mobx';

export enum ThreadFeed {
    ALL,
    ASSIGNED_TO_ME,
    UNASSIGNED,
}

export interface MessageFilter {
    feed: IObservableValue<ThreadFeed>;
    status: IObservableValue<boolean>;
    search: IObservableValue<string>;
}

export interface MessagesFilter {
    feed: ThreadFeed;
    open: boolean;
    search: string;
    tags: string[];
}
