import { observable, action, runInAction } from 'mobx';
import {
    LoadingState,
    Notifications,
    OnErrorDataPersonalSettings,
    NotificationsMessage,
} from './models';
import {
    updateTeamMemberName,
    updateTeamMemberEmail,
    changePwd,
    getNotif,
    setNotif,
    updateNotif,
} from '@src/requests';
import MessagesStore from '@src/stores/messages/messages.store';
import AuthStore from '@src/stores/auth.store';

export class PersonalSettingsStore {
    @observable notifications: Notifications = null;
    @observable notifyMessages: any = null;
    @observable notifyConversation: any = null;
    @observable notifyReviews: any = null;
    @observable dataLoadedNotifications = false;
    @observable loadingState: LoadingState = LoadingState.Init;
    private _onError: (type: string, data?: OnErrorDataPersonalSettings) => void;
    private _onSuccess: () => void;
    private _onSuccessEmailChanged: () => void;
    private _onErrorWrongOldPassword: () => void;

    set onError(fn: (type: string, data?: OnErrorDataPersonalSettings) => void) {
        this._onError = fn;
    }

    set onSuccess(fn: () => void) {
        this._onSuccess = fn;
    }

    set onSuccessEmailChanged(fn: () => void) {
        this._onSuccessEmailChanged = fn;
    }

    set onErrorWrongOldPassword(fn: () => void) {
        this._onErrorWrongOldPassword = fn;
    }

    @action init(): void {
        this.notifications = null;
        this.notifyMessages = null;
        this.notifyConversation = null;
        this.notifyReviews = null;
        this.dataLoadedNotifications = false;
        this.loadingState = LoadingState.Init;
    }

    @action updateTeamMemberName = (
        employeeId: string,
        firstName: string,
        lastName: string,
        phone: string,
        errAction: string
    ) => {
        this.loadingState = LoadingState.Loading;
        updateTeamMemberName(employeeId, firstName, lastName, phone).then(
            () => {
                if (this._onSuccess) {
                    this._onSuccess();
                };
                return AuthStore.updateAuth();
            },
            () => {
                if (this._onError) {
                    this._onError(errAction, { employeeId, firstName, lastName, phone });
                };
            }
        );
    };

    @action updateTeamMemberEmail = (
        employeeId: string,
        email: string,
        confirmEmail: string,
        errAction: string
    ) => {
        this.loadingState = LoadingState.Loading;
        updateTeamMemberEmail(employeeId, email, confirmEmail).then(
            () => {
                if (this._onSuccessEmailChanged) {
                    this._onSuccessEmailChanged();
                };
                return AuthStore.updateAuth();
            },
            () => {
                if (this._onError) {
                    this._onError(errAction, { employeeId, email, confirmEmail });
                };
            }
        );
    };

    @action changePwd = (currentPassword: string, newPassword: string, errAction: string) => {
        this.loadingState = LoadingState.Loading;
        changePwd({ currentPassword, newPassword }).then(
            () => {
                if (this._onSuccess) {
                    this._onSuccess();
                }
            },
            (err) => {
                if (err.response.status === 403 && this._onErrorWrongOldPassword) {
                    this._onErrorWrongOldPassword();
                } else if (this._onError) {
                    this._onError(errAction, { currentPassword, newPassword });
                }
            }
        );
    };

    @action getAndSetNotifications = (
        employeeId: string,
        errAction: string,
        firstLoading = false
    ) => {
        this.loadingState = firstLoading ? LoadingState.FirstLoading : LoadingState.Loading;
        getNotif(employeeId).then(
            (notificationsData) => {
                runInAction(() => {
                    const notifications = notificationsData.data;
                    this.setNotifyComponents(notifications);
                    this.notifications = notifications;
                    this.dataLoadedNotifications = true;
                    MessagesStore.ThreadsStore.setMessagingConstantsNotifications(notificationsData);
                    this.loadingState = LoadingState.Loaded;
                });
            },
            (err) => {
                if (err.response.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    if (this._onError) {
                        this._onError(errAction, { employeeId });
                    }
                };
            }
        );
    };

    @action setNotifyComponents = (notifications: any) => {
        this.notifyMessages = {
            assignedMe: {
                desktop: notifications.message.assignedMeDesktop,
                mobile: notifications.message.assignedMeMobile,
                email: notifications.message.assignedMeEmail,
            },
            assignedOther: {
                desktop: notifications.message.assignedOthrDesktop, // WARNING! typo in BE
                mobile: notifications.message.assignedOthrMobile, // WARNING! typo in BE
                email: notifications.message.assignedOtherEmail,
            },
            unassigned: {
                desktop: notifications.message.unassignedDesktop,
                mobile: notifications.message.unassignedMobile,
                email: notifications.message.unassignedEmail,
            },
        };

        this.notifyConversation = {
            assignmentMobile: notifications.conversation.assignmentMobile,
            assignmentEmail: notifications.conversation.assignmentEmail,
        };

        this.notifyReviews = {
            email: notifications.review.email,
            oneStar: notifications.review.oneStar,
            twoStar: notifications.review.twoStar,
            threeStar: notifications.review.threeStar,
            fourStar: notifications.review.fourStar,
            fiveStar: notifications.review.fiveStar,
        };
    };

    @action setNotificationsData = (data: any) => {
        const notifKeys = Object.keys(data);

        const messageKeys = notifKeys.filter((key) => key.split('-')[0] == 'message');
        const messages: NotificationsMessage = messageKeys.reduce((acc, curr) => {
            let key = curr.split('-')[1];

            if (key == 'assignedOtherDesktop') {
                key = 'assignedOthrDesktop';
            }
            if (key == 'assignedOtherMobile') {
                key = 'assignedOthrMobile';
            }

            return { ...acc, [key]: data[curr] };
        }, {});

        const conversationKeys = notifKeys.filter((key) => key.split('-')[0] == 'conversation');
        const conversation = conversationKeys.reduce((acc, curr) => {
            const key = curr.split('-')[1];
            return { ...acc, [key]: data[curr] };
        }, {});

        this.notifications = {
            ...this.notifications,
            message: messages,
            conversation: conversation,
            review: {
                email: data['review-email'],
                oneStar: data['oneStar'],
                twoStar: data['twoStar'],
                threeStar: data['threeStar'],
                fourStar: data['fourStar'],
                fiveStar: data['fiveStar'],
            },
            forwardToBrowser: data['forwardToBrowser'],
            forwardToMobile: data['forwardToMobile'],
        };
    };

    @action setNotifications = (employeeId: string, data: any, errAction: string) => {
        this.loadingState = LoadingState.Loading;
        const notifications = data;
        setNotif(employeeId, notifications).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return this.getAndSetNotifications(employeeId, 'getNotifications');
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { employeeId, notifications });
                    }
                });
            }
        );
    };

    @action updateNotifications = (employeeId: string, data: any, errAction: string) => {
        this.loadingState = LoadingState.Loading;
        const notifications = data;
        updateNotif(employeeId, notifications).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return this.getAndSetNotifications(employeeId, 'getNotifications');
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { employeeId, notifications });
                    }
                });
            }
        );
    };
}

export default new PersonalSettingsStore();
