import { action, observable, runInAction, computed } from 'mobx';
import { concat } from 'ramda';
import { searchTags } from '@src/requests/tags.requests';
import { LoadingState, Tag } from '@src/stores/models';
import { BaseStore } from '@src/stores/base.store';
import MessagesStore from '@src/stores/messages/messages.store';
import { tagsListItemsNumberWhenListNotExpanded } from '@src/theme/utils/constants';

const PAGE_SIZE = 20;

export default class TagsListStore extends BaseStore {
    @observable TagsStore;
    @observable loading: LoadingState = LoadingState.Init;
    @observable loadingTagsListOnAddContactView: LoadingState = LoadingState.Init;
    @observable _tagsList: Tag[] = [];
    @observable tagsListOnAddContactView: Tag[] = [];
    @observable _selectedTagsIds: string[] = [];
    @observable searchedTagsQuery = '';
    @observable _searchedTagsPage = 0;
    @observable hasMoreTagsToLoad = false;
    private reqWithPage = 0;
    private recentReqSource: string = null;
    @observable total = 0;
    @observable displayTagsListAsOpen = false;

    constructor(TagsStore) {
        super();
        this.TagsStore = TagsStore;
    }

    init(): void {
        this.loading = LoadingState.Init;
        this.loadingTagsListOnAddContactView = LoadingState.Init;
        this._tagsList = [];
        this.tagsListOnAddContactView = [];
        this._selectedTagsIds = [];
        this.searchedTagsQuery = '';
        this._searchedTagsPage = 0;
        this.hasMoreTagsToLoad = false;
        this.reqWithPage = 0;
        this.recentReqSource = null;
        this.total = 0;
        this.displayTagsListAsOpen = false;
    }

    @action resetPage(): void {
        this._searchedTagsPage = 0;
        this.reqWithPage = 0;
    }

    @computed get tagsList(): Tag[] {
        return this._tagsList;
    }

    @action setTagsList(val: Tag[]) {
        this._tagsList = val;
    }

    @computed get selectedTagsIds(): string[] {
        return this._selectedTagsIds;
    }

    @action setSelectedTagsIds(val: string[]) {
        this._selectedTagsIds = val;
    }

    @computed get searchedTagsPage(): number {
        return this._searchedTagsPage;
    }

    @action setSearchedTagsPage(val: number) {
        this._searchedTagsPage = val;
    }

    @action setDisplayTagsListAsOpen(val: boolean) {
        this.displayTagsListAsOpen = val;
    }

    @action setLoading(val: LoadingState) {
        this.loading = val;
    }

    @action filterByTag = (id: string) => {
        const index = this._selectedTagsIds.findIndex((t) => t === id);

        if (!id) {
            this._selectedTagsIds = [];
        } else if (index > -1) {
            this._selectedTagsIds = this._selectedTagsIds.filter((t) => t !== id);
        } else {
            this._selectedTagsIds.push(id);
        }

        MessagesStore._threadsStore.searchThreads({ tags: this._selectedTagsIds });
    };

    sortResults = (a, b) => {
        if (a.value?.toLowerCase() < b.value?.toLowerCase()) {
            return -1;
        }
        if (a.value?.toLowerCase() > b.value?.toLowerCase()) {
            return 1;
        }
        return 0;
    };

    @action searchMoreTags = (query: string, reqSource: string, sort: string): Promise<void> => {
        if (this.recentReqSource !== reqSource) {
            this._searchedTagsPage = 0;
            this.reqWithPage = 0;
            this.recentReqSource = reqSource;
        };
        if (query !== this.searchedTagsQuery) {
            this._searchedTagsPage = 0;
            this.reqWithPage = 0;
            this.searchedTagsQuery = query;
        };
        this._searchedTagsPage += 1;
        if (this.reqWithPage === this._searchedTagsPage) {
            return;
        };
        if (this._searchedTagsPage === 1) {
            this.loading = LoadingState.Loading;
        };

        return searchTags(
            this.searchedTagsQuery,
            this._searchedTagsPage,
            sort === 'created.desc' ? tagsListItemsNumberWhenListNotExpanded : PAGE_SIZE,
            sort
        ).then(
            (res) => {
                runInAction(() => {
                    if (this._searchedTagsPage === 1) {
                        if (sort === 'created.desc') {
                            this._tagsList = res.data.tags;
                        } else {
                            this._tagsList = res.data.tags.sort(this.sortResults);
                        }
                    } else {
                        this._tagsList = concat(this._tagsList, res.data.tags).sort(this.sortResults);
                    }
                    this.hasMoreTagsToLoad = res.data.total !== this._tagsList.length;
                    // this.hasMoreTagsToLoad = res.data.length === PAGE_SIZE;
                    this.total = res.data.total;
                    this.reqWithPage = this._searchedTagsPage;
                    this.loading = LoadingState.Loaded;
                });
            },
            () => {
                this.loading = LoadingState.Error;
            }
        );
    };

    @action searchMoreTagsForAddContactView = (): Promise<void> => {
        this.loadingTagsListOnAddContactView = LoadingState.Loading;
        return searchTags('', '', '', 'created.desc').then(
            (res) => {
                runInAction(() => {
                    this.tagsListOnAddContactView = res.data.tags;
                    this.loadingTagsListOnAddContactView = LoadingState.Loaded;
                });
            },
            () => {
                this.loading = LoadingState.Error;
                this.loadingTagsListOnAddContactView = LoadingState.Error;
            }
        );
    };
}
