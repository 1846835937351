import { STORES, WithBillingStoreProps, WithCouponStoreProps, WithSignupStoreProps, withStores } from '@src/stores/with-store';
import React, { useLayoutEffect } from 'react';
import { PaymentProps } from '../Auth/SignUp/CC';
import { useLocation } from 'react-router-dom';
import { StripeProvider, Elements } from 'react-stripe-elements';

import './Coupon.sass';
import { PaymentInsideStripe } from '@src/components/PaymentInsideStripe/PaymentInsideStripe';
import { SubscriptionAction, SubscriptionType } from '@src/pages/Settings/components/Billing/Billing';

type CouponProps = WithBillingStoreProps & WithCouponStoreProps & WithSignupStoreProps & PaymentProps;

const CouponWithStores: React.FunctionComponent<CouponProps> = ({BillingStore, CouponStore, SignupStore}) => {
    const location = useLocation();
    const couponId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const query = new URLSearchParams(location.search);
    const dt = query.get('dt')
    const redeemBeforeSecs = !!dt && dt.length > 0 ? parseInt(dt, 10) : null;
    const redeemBeforeDate = !!redeemBeforeSecs ? null : new Date(redeemBeforeSecs * 1000);

    const handleGetCouponError = (message: string) => {
        console.log('error', message)
    }

    useLayoutEffect(() => {
        !SignupStore.user && SignupStore.getUser();
        !SignupStore.plans && SignupStore.getPlans();
        !BillingStore.billingAccount?.card && BillingStore.getAndSetBilling('getBE');
        CouponStore.init();
        CouponStore.getCouponOffer(couponId, redeemBeforeDate, handleGetCouponError, dt);
    }, []);

    return (
        <div className="Coupon">
            {(SignupStore.user?.stripeKey && CouponStore.couponOffer && BillingStore.billingAccount && BillingStore.currentPlan) &&
                <StripeProvider apiKey={SignupStore.user.stripeKey}>
                    <Elements>
                        <PaymentInsideStripe
                            paymentCase='addPaymentCoupon'
                            user={SignupStore.user}
                            selectedPlan={BillingStore.currentPlan}
                            offer={CouponStore.couponOffer}
                            hideCCForm={!!BillingStore.billingAccount.card && BillingStore?.currentSubscription?.details?.status !== 'canceled'}
                            reactivationData={{
                                action: SubscriptionAction.REACTIVATE,
                                subType: BillingStore?.contract ? SubscriptionType.CONTRACT : SubscriptionType.SUBSCRIPTION,
                                subscriptionId: BillingStore?.currentSubscription?.id
                            }}
                        />
                    </Elements>
                </StripeProvider>
            }
        </div>
    );
};

export const Coupon = withStores(CouponWithStores, [
    STORES.Billing,
    STORES.Coupon,
    STORES.Signup,
    STORES.User,
]);
