import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { Loader, Dropdown, FormField, CheckboxField } from '@src/components';
import { useLoading } from '@src/hooks';
import { CustomerColumnConfirm, ImportColumn } from '@src/stores/models';
import { ImportCustomersStore } from '@src/stores/import-customers.store';
import { STORES, withStores } from '@src/stores/with-store';
import './import.sass';
import {
    columnsDataIntoRows,
    customerImportColumnsToImportColumns,
    rowsDataIntoColumns,
} from './helpers';

interface ImportColumnState {
    label: string;
    value: string;
    key?: string;
}

interface ImportedRow {
    data?: [];
    errors?: [];
}

interface FieldsOptions {
    value: string;
    label: string;
    versions?: any;
}

const desiredColumns = ['first name', 'last name', 'phone'];

const optionNone = 'None (to be skipped)';

const FIELDS: FieldsOptions[] = [
    { label: optionNone, value: 'none' },
    { label: 'First Name', value: 'firstname', versions: ['firstname', 'first name'] },
    { label: 'Last Name', value: 'lastname', versions: ['lastname', 'last name'] },
    {
        label: 'Phone',
        value: 'phone',
        versions: ['phonenumber', 'phone number', 'phone', 'ph', 'number'],
    },
    { label: 'Email', value: 'email', versions: ['email', 'emailaddress', 'email address'] },
    { label: 'Company', value: 'company', versions: ['company', 'co'] },
    { label: 'Tags', value: 'tags', versions: ['tags', 'tag'] },
    { label: 'Notes', value: 'notes', versions: ['notes', 'note'] },
];

const ImportWithStore: React.FC<{
    ImportCustomersStore: ImportCustomersStore;
    onboarding?: boolean;
    goBack: (success: boolean) => void;
}> = (props) => {
    const store = props.ImportCustomersStore;
    const loading = useLoading(store.uploadState);
    const history = useHistory();
    const { url } = useRouteMatch();
    const [view, setView] = useState('adding');
    const [canUpload, setCanUpload] = useState(false);
    const [headerMatched, setHeaderMatched] = useState(false);
    const [includeFirstRow, setIncludeFirstRow] = useState(true);
    const [fileKey] = useState(store.importUploadData?.fileKey);
    const [importColumns, setImportColumns] = useState<ImportColumn[]>(
        customerImportColumnsToImportColumns(store.importUploadData?.columns)
    );

    if (url === '/customers/import/upload' && !importColumns)
        history.push({
            pathname: '/customers',
            state: { uploadSuccess: false },
        });
    // if (url === '/customers/import/upload' && !importColumns) history.push('/customers');

    const [titles, setTitles] = useState<ImportColumnState[]>(
        importColumns?.map((c) => {
            return {
                key: c.key,
                value: null,
                label: null,
            };
        })
    );

    const [importRows, setImportRows] = useState(() => columnsDataIntoRows(importColumns));
    const header = importRows?.length ? importRows[0] : [];

    const checkIfCanUpload = () => {
        const l = titles.map((el) => el.label);
        const resultObjs = {};
        l.forEach((key) => {
            if (desiredColumns.includes(key?.toLowerCase())) {
                resultObjs[key] = true;
            }
        });

        const isUniqueColumn = new Set(l.filter((el) => el)).size === l.filter((el) => el).length;

        return Object.keys(resultObjs).length > 2 && isUniqueColumn;
    };

    useEffect(() => {
        if (view === 'adding' && titles) {
            const matchingColumns = header.data?.map((h) => {
                return FIELDS.find((f) => {
                    return f.versions?.find((v) => {
                        return v.trim().toLowerCase() === h.trim().toLowerCase();
                    });
                });
            });

            if (matchingColumns?.filter((el) => el).length) {
                setHeaderMatched(true);
                setIncludeFirstRow(false);
            }

            let arr = [...titles];
            matchingColumns?.forEach((c, i) => {
                if (c) {
                    arr = arr.map((el) => {
                        return el.key === titles[i]?.key
                            ? {
                                  ...el,
                                  value: c.value,
                                  label: c.label,
                              }
                            : { ...el };
                    });
                }
            });
            setTitles(arr);
        }
        // }, [header]);
    }, [importRows]);

    const saveLabelValueFromDropdown = (optionIdx: number, i: number) => {
        const arr = titles.map((el) => {
            return el.key === titles[i]?.key
                ? {
                      ...el,
                      value: FIELDS[optionIdx].value,
                      label: FIELDS[optionIdx].label,
                  }
                : { ...el };
        });
        setTitles(arr);
    };

    // const saveLabelValueFromSelect = (opt: FieldsOptions, i: number) => {
    // 	const arr = titles.map(el => {
    // 		return el.key === titles[i]?.key ? {
    // 			...el,
    // 			value: opt.value,
    // 			label: opt.label
    // 		} : { ...el }
    // 	})
    // 	setTitles(arr);
    // };

    const changeData = (newData: string, i: number, j: number) => {
        const ir = [...importRows];
        const customer = ir[i].data;
        customer.splice(j, 1, { value: newData });
        // ir.splice(i, 1, {errors: [...importRowsCopy[i].errors], data: customer});
        setImportRows(ir);
    };

    const onError = (message) => toast.error(message);

    useEffect(() => {
        if (!titles) {
            return;
        }
        const checkCanUpload = checkIfCanUpload();
        if (checkCanUpload !== canUpload) {
            setCanUpload(checkCanUpload);
        }
        return () => {
            store.clearUploadData();
        };
    }, [titles]);

    // if (!importColumns?.length || loading === LoadingState.Loaded) {
    // 	if (!props.onboarding) return <Redirect to={'/customers'}/>
    // 	else props.goBack();
    // }

    const exportToCsv = () => {
        const filename = 'export.csv';

        const rows = importRows.map((row) => {
            const data = row.data.map((item) => item.value);
            let errors = [];
            row.data.forEach((item) => errors.push(item.error));
            errors = errors.filter((el) => el);
            return [...data, errors];
        });

        const labelsArr = titles.map((el) => el.value);
        labelsArr.push('errors');
        rows.unshift(labelsArr);

        const processRow = (row) => {
            let finalVal = '';
            for (let j = 0; j < row.length; j++) {
                let innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                }
                let result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
                if (j > 0) finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        let csvFile = '';
        for (let i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }

        const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    const goBack = (success: boolean) => {
        // props.onboarding ? props.goBack(success) : history.push('/customers');
        props.onboarding
            ? props.goBack(success)
            : history.push({
                  pathname: '/customers',
                  state: { uploadSuccess: success },
              });
    };

    const confirmUpload = () => {
        let newColumns;

        if (view === 'adding') {
            newColumns = [...importColumns];
        } else {
            newColumns = rowsDataIntoColumns(importRows);
            setImportColumns(newColumns);
        }
        setImportRows([]);
        setImportColumns([]);

        const labelInfo = newColumns
            .map((el, i) => {
                return [el.key, titles[i]?.label];
            })
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        const values: CustomerColumnConfirm[] = newColumns.map((col) => {
            const key = col.key;
            const name = labelInfo[key] && labelInfo[key] !== optionNone ? labelInfo[key] : null;
            const val = {
                skip: name ? false : true,
                data: view === 'adding' ? col.column.data : col.column.data.map((el) => el.value),
                name: name,
            };
            if (view === 'adding' && !includeFirstRow) val.data.shift();
            return val;
        });

        // const successUploaded = () => toast.success("After the import finishes you will receive an email with results. This might take from a minute up to a few hours, depending on the file size and current server load.", { autoClose: 12000 });
        const success = (msg) => toast.success(msg, { autoClose: 4000 });
        const includesHeader = !includeFirstRow;
        const req =
            view === 'adding'
                ? store.uploadCustomersColumns(fileKey, values, includesHeader, null, onError)
                : store.correctCustomersColumns(fileKey, values, success, onError);
        req.then((data: any) => {
            if (!data) {
                goBack(true);
            } else {
                setView('correcting');
                setImportRows(data.errorData);
                setImportColumns(rowsDataIntoColumns(data.errorData));
            }
        });
    };

    return (
        <div className="Import card" key="Import">
            <Loader loadingState={loading} />
            <div className="Import__content" key="Import__content">
                <h3 className="Import__title">Import Customers</h3>
                <div className="Import__instruction">
                    {view === 'adding' && (
                        <>
                            <p>
                                Here you can choose which column represents data of: first name,
                                last name, phone, email, company, tags, notes.{' '}
                                <span className="highlight">
                                    First name, last name, and phone are mandatory
                                </span>
                                , others may be skipped.
                            </p>
                            <p>
                                Please notice:{' '}
                                <span className="highlight">
                                    If you are adding several contacts with the same phone number,
                                    only the last one will be recorded
                                </span>
                                . Also, if you upload a contact with already existing phone number,
                                a{' '}
                                <span className="highlight">
                                    previous contact having that number will be updated
                                </span>{' '}
                                with current data.
                            </p>
                        </>
                    )}
                    {view === 'adding' && headerMatched && (
                        <p>
                            Some of the columns have already been matched based on the first row of
                            your data. You can still change them by selecting proper option.
                        </p>
                    )}
                    {view === 'correcting' && (
                        <p>
                            Some of your data has not been loaded as it contained errors. Please
                            look below for details.
                        </p>
                    )}
                </div>
                <div className="Import__table" key="Import__table">
                    <table key="table">
                        <thead>
                            <tr>
                                {titles?.map((l, labelIdx) => (
                                    <th className="column-actions" key={uuidv4()}>
                                        <div className="title">
                                            <Dropdown
                                                optionsArr={FIELDS.map((el) => el.label)}
                                                selOptionNumber={
                                                    l.value
                                                        ? FIELDS.findIndex(
                                                              (el) => el.value === l.value
                                                          )
                                                        : 0
                                                }
                                                darkColor={false}
                                                optionSelected={(optionIdx) =>
                                                    saveLabelValueFromDropdown(optionIdx, labelIdx)
                                                }
                                            />
                                            {/* <Select
												value={l.value ? l.value : FIELDS[0].value}
												options={FIELDS}
												onChange={ev => saveLabelValueFromSelect(ev, labelIdx)}
											/> */}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody key="tbody">
                            {view === 'adding' && (
                                <tr key={uuidv4()} className="header-row">
                                    {header.data?.map((h, i) => (
                                        <td key={uuidv4()}>
                                            <div
                                                className={classNames('header-cell', {
                                                    firstCell: i === 0,
                                                    shortPhrase: i === 0 && h.length < 10,
                                                })}
                                            >
                                                {i === 0 && (
                                                    <>
                                                        <CheckboxField
                                                            defaultValue={includeFirstRow}
                                                            onChange={() =>
                                                                setIncludeFirstRow(!includeFirstRow)
                                                            }
                                                        />
                                                        <div className="info">
                                                            i
                                                            <div className="info-msg">
                                                                <p>
                                                                    If this row contains customer
                                                                    data
                                                                </p>
                                                                <p>
                                                                    (eg. &quot;Emma&quot;,
                                                                    &quot;Smith&quot; etc.), check
                                                                    it on the left
                                                                </p>
                                                                <p>
                                                                    so it will be included in
                                                                    uploaded data.
                                                                </p>
                                                                <p>
                                                                    If it contains columns data (eg.
                                                                    &quot;first name&quot;,
                                                                </p>
                                                                <p>
                                                                    &quot;last name&quot; etc.)
                                                                    leave it unchecked.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {h}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            )}
                            {view === 'adding' ? (
                                <>
                                    {importRows
                                        ?.slice(1)
                                        .splice(0, 4)
                                        ?.map((r) => (
                                            <tr key={uuidv4()}>
                                                {r.data?.map((el) => (
                                                    <td key={uuidv4()}>{el}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    {importRows?.slice(1).length > 4 && (
                                        <tr key={uuidv4()}>
                                            {importRows[0].data?.map(() => (
                                                <td key={uuidv4()}>...</td>
                                            ))}
                                        </tr>
                                    )}
                                </>
                            ) : (
                                importRows?.map((r, i) => (
                                    <tr key={'tr' + i}>
                                        {r['data']?.map((el, j) => {
                                            let untouched = true;
                                            return (
                                                <td key={'td' + i + j}>
                                                    <FormField
                                                        label=" "
                                                        error={{ message: el.error }}
                                                    >
                                                        <input
                                                            type="text"
                                                            className={classNames('form-input', {
                                                                error: el.error && untouched,
                                                            })}
                                                            defaultValue={el.value}
                                                            onChange={(e) => {
                                                                changeData(e.target.value, i, j);
                                                                untouched = false;
                                                            }}
                                                        />
                                                    </FormField>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="Import__instruction onBottom">
                    {canUpload ? (
                        <p>When your data is ready, click &apos;Upload&apos;.</p>
                    ) : (
                        <p>
                            Please make sure you{' '}
                            <span className="highlight">
                                indicated &apos;First Name&apos;, &apos;Last Name&apos;, and
                                &apos;Phone&apos; columns (and every of them is indicated only
                                once).
                            </span>{' '}
                            Other columns may be skipped.
                        </p>
                    )}
                </div>
                <div className="Import__actions">
                    <button
                        onClick={() => goBack(false)}
                        className={classNames('btn-secondary', { onboarding: props.onboarding })}
                    >
                        Cancel & Go Back
                    </button>
                    {view === 'correcting' && (
                        <button
                            onClick={() => exportToCsv()}
                            className={classNames('btn-secondary', {
                                onboarding: props.onboarding,
                            })}
                        >
                            Export Above Data to CSV
                        </button>
                    )}
                    <button
                        disabled={!canUpload}
                        onClick={() => confirmUpload()}
                        className={classNames('btn-primary', { onboarding: props.onboarding })}
                    >
                        Upload
                    </button>
                </div>
            </div>
        </div>
    );
};

export const Import = withStores(ImportWithStore, [STORES.ImportCustomersStore]);
