import React from 'react';
import classNames from 'classnames';
import { images } from '@src/theme/icons/dashboard';

import './metric.sass';

type TitleSize = 'big' | 'medium' | 'small';

interface Metric {
    toCompare?: number;
    toShow?: number;
    iconName?: string;
    titleRow1?: string;
    titleRow2?: string;
    titleSizeRow1?: TitleSize;
    titleSizeRow2?: TitleSize;
    hideComparing?: boolean;
    clazzNames?: object;
}

export const Metric: React.FC<Metric> = (props) => {
    const pointerImg = (prev: number, curr: number): JSX.Element => {
        const drop = <img src={images.drop} alt="drop" />;
        const growth = <img src={images.growth} alt="growth" />;
        return prev > curr ? drop : growth;
    };

    const isCurrEqualPrev = props.toCompare === props.toShow;

    const tileClass = classNames({
        ...{
            Metric: true,
            growth: props.toShow > props.toCompare,
            drop: props.toShow < props.toCompare,
            balance: props.toShow === props.toCompare,
        },
        ...props.clazzNames,
    });
    return (
        <div className={tileClass}>
            {props.titleRow1 && (
                <div className="title">
                    <h3 className={classNames('titleRow1', { medium: props.titleSizeRow1 === 'medium', small: props.titleSizeRow1 === 'small' })}>
                        {props.titleRow1}
                    </h3>
                    <h3 className={classNames('titleRow2', { medium: props.titleSizeRow2 === 'medium', small: props.titleSizeRow2 === 'small' })}>
                        {props.titleRow2}
                    </h3>
                </div>
            )}
            <div className="data">
                <img src={images[props.iconName]} alt={props.iconName} />
                {props.toShow === undefined ? '-' : props.toShow}
            </div>
            {!props.hideComparing && (
                <div className="extra">
                    {((props.toCompare || props.toCompare === 0) &&
                        !isCurrEqualPrev &&
                        pointerImg(props.toCompare, props.toShow)) ||
                        ''}
                    <span>{props.children}</span>
                </div>
            )}
        </div>
    );
};
