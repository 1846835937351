import { ProgressBar } from '@src/components/ProgressBar/ProgressBar';
import { Profile } from '@src/stores/models/profile';
import React, { useMemo } from 'react';

import './Profile.sass';

interface ProfileCompletionProps {
    profile: Profile;
};

const ProfileCompletion: React.FunctionComponent<ProfileCompletionProps> = ({profile}) => {
    const percentage = useMemo(() => {
        return (
            Math.round(
                (Object.values(profile).filter(Boolean).length /
                    Object.values(profile).length) *
                    100
            ) || 0
        );
    }, [profile]);

    return (
        <div className="Profile__completion">
            <div className="number">{percentage}%</div>
            {profile && <ProgressBar current={percentage} total={100} />}
        </div>

    )
};

export default ProfileCompletion;
