import { action, observable, runInAction, computed } from 'mobx';
import { CustomersApi } from '@src/requests';
import { Customer, LoadingState } from './models';
import { BaseStore } from '@src/stores/base.store';
import AuthStore from '@src/stores/auth.store';

export class CustomersStore extends BaseStore {
    @observable loadingState = LoadingState.Init;
    @observable customers: Customer[] = [];
    @observable totalCustomers = 0;
    @observable _pageBeingDisplayed = 1;
    @observable _customersSearchText = '';

    @computed get pageBeingDisplayed() {
        return this._pageBeingDisplayed;
    }

    @action setPageBeingDisplayed(val) {
        this._pageBeingDisplayed = val;
    }

    @computed get customersSearchText() {
        return this._customersSearchText;
    }

    @action setCustomersSearchText(val) {
        this._customersSearchText = val;
    }

    @action init(): void {
        this.loadingState = LoadingState.Init;
        this.customers = [];
        this.totalCustomers = 0;
        this._pageBeingDisplayed = 1;
        this._customersSearchText = '';
    }

    @action setCustomers(customers: Customer[]): void {
        this.customers = customers;
    }

    @action searchCustomers(search?: string, onError?: (message) => void): void {
        // if (search === '') return;
        this.loadingState = LoadingState.Loading;
        CustomersApi.searchCustomers(search).then(
            (res) => {
                runInAction(() => {
                    this.customers = res;
                    this.loadingState = LoadingState.Loaded;
                });
            },
            (err) => {
                if (err.response.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    if (onError) {
                        onError(err.message);
                    };
                    runInAction(() => {
                        this.loadingState = LoadingState.Error;
                    });
                };
            }
        );
    }

    @action searchCustomersForDt = (
        search?: string,
        page?: number,
        size?: number,
        sort?: string,
        sortDir?: string,
        onError?: (message) => void
    ) => {
        this.loadingState = LoadingState.Loading;
        CustomersApi.searchCustomersDt(search, page, size, sort, sortDir)
            .then((res) => {
                runInAction(() => {
                    this.customers = res.data;
                    this.totalCustomers = res.total;
                    this.loadingState = LoadingState.Loaded;
                });
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    if (onError) {
                        onError(err.message);
                    };
                    runInAction(() => {
                        this.loadingState = LoadingState.Error;
                    });
                };
            });
    };
}

export default new CustomersStore();
