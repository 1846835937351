import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import classNames from 'classnames';
import { merge } from 'ramda';
import { toast } from 'react-toastify';
import { injectStripe } from 'react-stripe-elements';
import { STORES, withStores, WithBillingStoreProps, WithAuthStoreProps, WithUserStoreProps, WithSignupStoreProps, WithCouponStoreProps } from '@src/stores/with-store';
import { ErrorToastSave, Loader } from '@src/components';
import CouponHeader from './headers/Coupon';
import AddPaymentAndFinishTrialHeader from './headers/AddPaymentAndFinishTrial';
import AddPaymentOnSignupHeader from './headers/AddPaymentOnSignup';
import AddPaymentOnShortSignupHeader from './headers/AddPaymentOnShortSignup';
import UpdatePaymentInSettingsHeader from './headers/UpdatePaymentInSettings';
import ChangePlanHeader from './headers/ChangePlan';
import CCDetailsForm from './CCDetailsForm';
import Footer from './Footer';
import { CouponOffer } from '@src/stores/coupon.store';
import { isBefore } from 'date-fns';
import { SingleBillingPlan, ChangingPlanData, PlanComponent } from '@src/stores/models';
import { trackGoogleEvent, SignupAnalyticsEvents, setupLinkedinPixel, trackFacebookStandardEvent } from '@src/theme/utils/Analytics';
import { ERROR_TOAST_AUTOCLOSE, gtagConversionSendTo } from '@src/theme/utils/constants';
import './payment-inside-stripe.sass';

export type PaymentCase = 'updatePaymentInSettings' | 'addPaymentAndFinishTrial' | 'addPaymentOnSignup' | 'addPaymentOnShortSignup' | 'addPaymentCoupon' | 'reactivateFromCancelled' | 'changePlan';

export const isCouponOfferExpired = (offer?: CouponOffer): boolean => offer?.redeemBefore && isBefore(offer.redeemBefore, new Date());
export const isCouponOfferClaimed = (offer?: CouponOffer): boolean => offer?.status === 'CLAIMED';

interface PaymentInsideStripeProps {
    user?: {companyInfo: object};
    stripe?: any;
    selectedPlan?: SingleBillingPlan;
    selectedBuyNow?: boolean;
    phone?: any;
    stepBack?: () => void;
    confirmBtn?: string;
    settings?: boolean;
    paymentAdded: () => void;
    paymentCase: PaymentCase;
    offer?: CouponOffer;
    hideCCForm?: boolean;
    reactivationData?: any;
    onPlanChange?: ({priceId: string, buyNow: boolean}) => void;
    planData?: ChangingPlanData;
    components?: PlanComponent[];
}

type props = PaymentInsideStripeProps & WithBillingStoreProps & WithAuthStoreProps & WithUserStoreProps & WithSignupStoreProps & WithCouponStoreProps;

export const PaymentInsideStripeWithStore: React.FC<props> = (props) => {
    const history = useHistory();

    const isUpdatePaymentInSettings = props.paymentCase === 'updatePaymentInSettings';
    const isAddPaymentAndFinishTrial = props.paymentCase === 'addPaymentAndFinishTrial';
    const isAddPaymentOnSignup = props.paymentCase === 'addPaymentOnSignup';
    const isAddPaymentOnShortSignup = props.paymentCase === 'addPaymentOnShortSignup';
    const isAddPaymentCoupon = props.paymentCase === 'addPaymentCoupon';
    const isReactivateFromCancelled = props.paymentCase === 'reactivateFromCancelled';
    const isChangePlan = props.paymentCase === 'changePlan';

    const isTrialPlanOnSignup = !isAddPaymentOnShortSignup && props.selectedPlan && props.selectedPlan.billingPlan.trialDays && props.selectedPlan.billingPlan.trialDays > 0 && !props.selectedBuyNow;

    const [loading, setLoading] = useState(false);

    const collectAlsoAddressData = !props.UserStore.user?.address.replace(/[^a-zA-Z0-9]/g, "").length && !isAddPaymentOnSignup;

    useEffect(() => {
        if (collectAlsoAddressData && !props.UserStore.company?.address?.id) {
            props.UserStore.getAndSetCompanyDetails();
        };
    }, []);

    const updatePayment = (addressData?) => {
        setLoading(true);
        props.stripe.createSource({ type: 'card' })
        .then((res) => {
            if (res.error) {
                if (isAddPaymentOnSignup || isAddPaymentOnShortSignup) {
                    toast.error(res.error.message, ERROR_TOAST_AUTOCLOSE);
                } else {
                    toast.error('Provided credit card data is incorrect thus it has not been saved successfully.', ERROR_TOAST_AUTOCLOSE);
                };
            } else {
                const requests: any[] = collectAlsoAddressData && addressData ?
                    [props.UserStore.updateCompanyDetails(props.UserStore.user?.companyName, {...addressData, id: props.UserStore.company?.address?.id})]
                    : [];

                if (isUpdatePaymentInSettings) {
                    requests.push(props.BillingStore?.updateBillingPayment(res.source.id, true));
                    Promise.all(requests).then(
                        () => {
                            props.BillingStore?.setIsViewBilling(true);
                            // setTimeout(() => toast.dismiss(), 4000);
                        },
                        () => toast.error(<ErrorToastSave repeatUploading={() => updatePayment()} />)
                    );
                } else if (isAddPaymentAndFinishTrial) {
                    requests.push(props.BillingStore?.updateBillingPayment(res.source.id));
                    Promise.all(requests).then(
                        () => {
                            props.BillingStore.cancelTrial('cancelTrial')
                            .then(() => {
                                history.push('/dashboard');
                            })
                        },
                        () => toast.error(<ErrorToastSave repeatUploading={() => updatePayment()} />)
                    );
                } else if (isAddPaymentCoupon) {
                    if (props.BillingStore?.currentSubscription?.details?.status === 'canceled' && props.reactivationData) {
                        setLoading(true);
                        requests.push(props.CouponStore.redeemOffer(props.offer?.id, res.source.id));
                        Promise.all(requests).then(() => {
                            toast.success('Coupon successfully redeemed', { autoClose: 4000 });
                            history.push('/dashboard');
                            props.BillingStore?.getAndSetBilling('getBE');
                        })
                        .finally(() => setLoading(false));
                    } else {
                        requests.push(props.BillingStore?.updateBillingPayment(res.source.id))
                        Promise.all(requests).then(
                            () => {
                                setLoading(true);
                                props.CouponStore.redeemOffer(props.offer?.id)
                                .then(() => {
                                    toast.success('Coupon successfully redeemed', { autoClose: 4000 });
                                    history.push('/dashboard');
                                    props.BillingStore?.getAndSetBilling('getBE');
                                })
                                .finally(() => setLoading(false));
                            },
                            () => toast.error(<ErrorToastSave repeatUploading={() => updatePayment()} />)
                        );
                    }
                } else if (isReactivateFromCancelled && props.reactivationData) {
                    requests.push(props.BillingStore?.manageSubscription(
                        props.reactivationData.action,
                        props.reactivationData.subType,
                        props.reactivationData.subscriptionId,
                        'manageSubscription',
                        res.source.id
                    ));
                    Promise.all(requests);
                } else if (isChangePlan) {
                    setLoading(true);
                    requests.push(props.BillingStore?.updateBillingPayment(res.source.id))
                    Promise.all(requests)
                    .then(() => props.paymentAdded())
                    .finally(() => setLoading(false));
                } else if (isAddPaymentOnSignup || isAddPaymentOnShortSignup) {
                    const dataToFinalize = isAddPaymentOnSignup ? merge(props.user.companyInfo, { smsNumber: props.phone }) : {...props.user.companyInfo, buyNow: true, smsNumber: props.phone };
                    props.SignupStore.setBillingForm(
                        {
                            priceId: props.selectedPlan?.price.id,
                            stripeToken: res.source.id,
                        },
                        dataToFinalize
                    ).finally(
                        () => {
                            props.AuthStore.loadAuthLogin().then(() => {
                                if (props.settings) {
                                    props.paymentAdded();
                                } else {
                                    if (props.UserStore.user.accountStatus !== 'INCOMPLETE') {
                                        history.push('/dashboard');
                                        trackGoogleEvent(SignupAnalyticsEvents.Payment, gtagConversionSendTo);
                                        trackFacebookStandardEvent('Subscribe', {value: props.selectedPlan?.price?.amount, currency: 'USD', predicted_ltv: props.selectedPlan?.price?.amount});
                                        setupLinkedinPixel();
                                    } else {
                                        toast.error('Something went wrong. Please try again.', ERROR_TOAST_AUTOCLOSE);
                                    }
                                };
                                props.UserStore.setSignupUser(null);
                            });
                        }
                    ), () => {
                        toast.error('Something went wrong. Please try again.', ERROR_TOAST_AUTOCLOSE);
                    };
                }
            }
        })
        .finally(() => setLoading(false));
    };

    const handleActionClick = (data) => {
        data.event.preventDefault();
        if (props.hideCCForm) {
            setLoading(true);
            props.CouponStore.redeemOffer(props.offer?.id)
            .then(() => {
                toast.success('Coupon successfully redeemed', { autoClose: 4000 });
                history.push('/dashboard');
            })
            .finally(() => setLoading(false));
        } else {
            if (collectAlsoAddressData && data.address) {
                updatePayment(data.address);
            } else {
                updatePayment();
            };
        };
    };

    const headerUpdatePaymentInSettings = isUpdatePaymentInSettings && <UpdatePaymentInSettingsHeader billingStore={props.BillingStore}/>

    const headerAddPaymentOnSignup = isAddPaymentOnSignup && props.selectedPlan &&
        <AddPaymentOnSignupHeader
            isTrialPlanOnSignup={isTrialPlanOnSignup}
            selectedPlan={props.selectedPlan}
            onPlanChange={props.onPlanChange}
        />

    // const headerAddPaymentOnShortSignup = isAddPaymentOnShortSignup && props.selectedPlan && <AddPaymentOnShortSignupHeader selectedPlan={props.selectedPlan} />

    const headerAddPaymentCoupon = isAddPaymentCoupon && props.selectedPlan && <CouponHeader offer={props.offer} selectedPlan={props.selectedPlan}/>;

    const headerAddPaymentAndFinishTrial = isAddPaymentAndFinishTrial && <AddPaymentAndFinishTrialHeader billingStore={props.BillingStore}/>

    const headerChangePlan = isChangePlan && <ChangePlanHeader billingStore={props.BillingStore} planData={props.planData} components={props.components} />

    // const header = headerUpdatePaymentInSettings || headerAddPaymentCoupon || headerAddPaymentOnSignup || headerAddPaymentOnShortSignup || headerAddPaymentAndFinishTrial || headerChangePlan;
    const header = headerUpdatePaymentInSettings || headerAddPaymentCoupon || headerAddPaymentOnSignup || headerAddPaymentAndFinishTrial || headerChangePlan;

    const footerAddPaymentOnSignup = (isAddPaymentOnSignup || isAddPaymentOnShortSignup || isAddPaymentAndFinishTrial) && props.selectedPlan &&
        <Footer
            isAddPaymentAndFinishTrial={isAddPaymentAndFinishTrial}
            isAddPaymentOnSignup={isAddPaymentOnSignup}
            isTrialPlanOnSignup={isTrialPlanOnSignup}
            trialDays={props.selectedPlan.billingPlan.trialDays}
        />

    const isActiveCoupon = isAddPaymentCoupon && !(isCouponOfferExpired(props.offer) || isCouponOfferClaimed(props.offer));

    const CCForm = (!isAddPaymentCoupon || isActiveCoupon) &&
        <CCDetailsForm
            billingStore={props.BillingStore}
            confirmBtn={props.confirmBtn}
            paymentCase={props.paymentCase}
            selectedBuyNow={props.selectedBuyNow}
            selectedPlan={props.selectedPlan}
            settings={props.settings}
            onActionClick={handleActionClick}
            onStepBack={props.stepBack}
            hideInput={props.hideCCForm}
            collectAlsoAddressData={collectAlsoAddressData}
        />

    return (
        <div className={classNames('PaymentInsideStripe', {
            signup : isAddPaymentOnSignup,
            shortSignupView : isAddPaymentOnShortSignup,
            coupon: isAddPaymentCoupon
        })}>
            <div className={classNames({'step-card': isAddPaymentOnSignup || isAddPaymentCoupon})}>
                <Loader loading={loading} />
                {header}
                {CCForm}
            </div>
            {footerAddPaymentOnSignup}
        </div>
    );
};

export const PaymentInsideStripe = injectStripe(
    withStores(PaymentInsideStripeWithStore, [STORES.Billing, STORES.Auth, STORES.User, STORES.Signup, STORES.Coupon])
);
