import React, { useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import classNames from 'classnames';
import { images } from '@src/theme/images';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as InfoIcon } from '@src/theme/icons/icon-info.svg';
import { WithBillingStoreProps, STORES, withStores } from '@src/stores/with-store';
import { displayIntegerOrTwoDecimalPlaces } from '@src/theme/utils/helpers';
import { setTimeoutAndClear } from "@src/utils/timeout";
import { ChangingPlanData, PlanComponent } from '@src/stores/models/billing';
import './plan-details.sass';


type View = 'billing' | 'changePlan' | 'finishTrial' | 'updatePayment';
interface PlanDetailsProps {
    view: View;
    planData?: ChangingPlanData;
    components?: PlanComponent[];
};

type props = PlanDetailsProps & WithBillingStoreProps;

export const PlanDetailsWithStore: React.FC<props> = ({view, planData, components, BillingStore}) => {
    const history = useHistory();
    const planDetailsRef = useRef(null);
    const [showPlanDetails, setShowPlanDetails] = useState(false);

    const changeShowPlanDetails = () => {
        setShowPlanDetails(!showPlanDetails);
        setTimeoutAndClear(() => {
            if (planDetailsRef?.current) {
                planDetailsRef.current.scrollIntoView({ behavior: "smooth"});
            };
        });
    };

    const planDetails = (components && components.length) &&
        <>
            {[...components].sort((a, b) => a.seqNumber - b.seqNumber).map(component =>
                <div
                    key={component.id}
                    className="PricingPlan__component"
                >
                    <p>{component.description}</p>
                    {component.items &&
                        <ul>
                            {component.items.map((item, i) => <li key={i + item.text} className="PricingPlan__component-detail">{item.text}</li>)}
                        </ul>
                    }
                </div>
            )}
        </>

    return (
        <div className={classNames('PlanDetails', {
            billingView: view === 'billing',
            changePlanView: view === 'changePlan',
            updatePaymentView: view === 'updatePayment',
        })}>
            <div className="PlanDetails__image">
                {(showPlanDetails && components) &&
                    <div className="PlanDetails__image-name">{planData ? planData.name : BillingStore.currentPlan?.billingPlan.name}</div>
                }
                <img src={images.planHouseImg} alt="house" />
            </div>
            <div className="PlanDetails__text">
                <div className="PlanDetails__text-yourPlan">{view === 'changePlan' ? 'SELECTED' : 'YOUR'} PLAN</div>
                <div className="PlanDetails__text-planName">{planData ? planData.name : BillingStore.currentPlan?.billingPlan.name}</div>
                <div className="PlanDetails__text-noFreeTrial">
                    <b>${displayIntegerOrTwoDecimalPlaces(planData ? planData.price : BillingStore.currentPlan?.price.amount)}</b> per {planData ? planData.interval : BillingStore.currentPlan?.price.interval?.interval}
                    {BillingStore.redeemedCoupons.length ?
                        <>
                            <ReactTooltip multiline={true} />
                            <InfoIcon className="info" data-tip={'Please note that this amount does not include applied coupons or discounts.'} />
                        </>
                    : null}
                </div>
                {view === 'changePlan' ?
                    <button className="btn-tetriary-blue" onClick={() => changeShowPlanDetails()}>
                        {!showPlanDetails ? 'Show' : 'Hide'} details
                    </button>
                    : null
                }
                {(showPlanDetails && components) &&
                    <div className="PlanDetails__text-planDetails" ref={planDetailsRef}>
                        {planDetails}
                    </div>
                }
            </div>
        </div>
    );
};

export const PlanDetails = withStores(PlanDetailsWithStore, [STORES.Billing]);
