import React from 'react';
import HappyBagPng from './HappyBag.png';

interface ImportDoneProps {
    addedCustomers: number;
    onDoneClick: () => void;
}

const ImportDone: React.FunctionComponent<ImportDoneProps> = ({ addedCustomers, onDoneClick }) => {
    return (
        <div className="ImportCustomers__Done">
            <img alt="csv table" src={HappyBagPng}/>
            <h3 className="ImportCustomers__title">Customers Uploaded</h3>
            <p className="ImportCustomers__subtitle">After the import finishes you will receive an email with results. This might take from a minute up to a few hours, depending on the file size and current server load.</p>
            <p className="ImportCustomers__info">Your newly imported customers will be then available to contact through Myopolis. You can review, edit, or add more customers through the Customers tab. </p>
            <button className="btn-primary" onClick={onDoneClick}>Done</button>
        </div>
    )
};

export default ImportDone;