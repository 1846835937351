import React, { useState } from 'react';
import { ReactComponent as SaveIcon } from '@src/theme/icons/save.svg';
import { ReactComponent as DeleteIcon } from '@src/theme/icons/delete.svg';
import CreateBroadcastCard from './CreateBroadcastCard/CreateBroadcastCard';
import { STORES, WithBroadcastsStoreProps, withStores, WithUserStoreProps } from '@src/stores/with-store';
import { Broadcast } from '@src/stores/models/broadcast_new';

type NewBroadcastProps = WithUserStoreProps & WithBroadcastsStoreProps & {
    broadcast? : Broadcast;
    onPublish: () => void;
    // onSchedule: () => void;
};

const NewBroadcastWithStores: React.FunctionComponent<NewBroadcastProps> = ({ broadcast, onPublish, ...props }) => {
    // const [topBarButtonsEnabled, setTopBarButtonsEnabled] = useState<boolean>(false)
    const timeZone = props.UserStore.user.employee.timeZone;

    // const onSaveClick = () => {
    //     console.log('Save draft')
    // }

    const onDeleteClick = () => {
        if (props.BroadcastsStore.currentStatus === 'EDIT' && broadcast?.id) {
            props.BroadcastsStore.deleteBroadcast(broadcast.id)
        }
        props.BroadcastsStore.setStatus('SCHEDULED');

    }

    // const handleFormDataChange = (formData: FormData) => {
    //     setTopBarButtonsEnabled(formData.name !== '')
    // }

    return (
        <div className="one-column">
            <div className="Broadcasts__top-bar justify-end">
                    {/* {false &&
                        <button className="top-bar-button" disabled={!topBarButtonsEnabled} onClick={onSaveClick}>
                            <SaveIcon />
                            <span>Save Draft</span>
                        </button>
                    } */}
                    <button
                        className="Broadcasts__top-bar-button"
                        // disabled={!topBarButtonsEnabled}
                        onClick={onDeleteClick}
                    >
                        <DeleteIcon />
                        <span>{ props.BroadcastsStore.currentStatus === 'CREATE' ? "Cancel" : "Delete"} Broadcast</span>
                    </button>
            </div>
            <CreateBroadcastCard broadcast={broadcast} timeZone={timeZone} onPublish={onPublish}
                // onSchedule={onSchedule}
            />
        </div>
    );
};

const NewBroadcast = withStores(NewBroadcastWithStores, [
    STORES.BroadcastsStore,
    STORES.User,
]);

export default NewBroadcast;
