import React, { useState } from 'react';
import Image from './on-the-go.png';

import './OnTheGoCard.sass';
import DashboardCard from '../DashboardCard/DashboardCard';
import { useForm } from 'react-hook-form';
import { FormField } from '@src/components/FormField/FormField';
import { Input } from 'reactstrap';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import MessagesStore from '@src/stores/messages/messages.store';
import { toast } from 'react-toastify';

const appLinkMessage = 'Welcome to Myopolis! Download the mobile app at https://myopolis.page.link/app';

type OnTheGoContent = 'dashboard' | 'customers';

const onTheGoContents = {
    'dashboard': [
        'Put your business in your pocket for instant access and notifications.',
        'One app for your entire team... They share the inbox and number, and you own the number'
    ],
    'customers': [
        'Download the Myopolis mobile app to sync contacts from your phone.',
        "You'll also get instant access and notifications... Your whole business in your pocket!"
    ]
};

interface FormData {
    phone: string;
};

interface OnTheGoCardProps {
    contentType?: OnTheGoContent;
    onSubmit?: () => void;
}

export const OnTheGoCard: React.FunctionComponent<OnTheGoCardProps> = ({ contentType = 'dashboard', onSubmit }) => {
    const { register, handleSubmit, errors } = useForm<FormData>();
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleFormSubmit = (data: FormData, event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const phone = data.phone?.replace(/\D/g, '');
        phone && MessagesStore.sendMobileAppLink(phone, appLinkMessage).then(() => {
            toast.success('The link was sent!', { autoClose: 4000 });
            setPhoneNumber('');
            onSubmit && onSubmit();
        },
        (error) => {
            toast.error(error, { autoClose: 4000 });
            onSubmit && onSubmit();
        });
    };

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const phone = e.target.value.replace(/\D/g, '');
        setPhoneNumber(phone);
    };

    const renderSubtitles = onTheGoContents[contentType].map((par, i) => <p key={contentType + i}>{par}</p>);

    return (
        <DashboardCard className="dashboard__on-the-go-card">
            <div className="dashboard__on-the-go-card-left">
                <h3>Get Myopolis On The Go</h3>
                {renderSubtitles}
                <form className="dashboard__on-the-go-card__form" onSubmit={handleSubmit(handleFormSubmit)}>
                    <div>
                        <FormField label="Mobile Phone" htmlFor="phone">
                            <Input
                                type="text"
                                mask="999-999-9999"
                                maskChar=""
                                name="phone"
                                placeholder="321-888-8888"
                                className={classNames('form-input', { error: errors.phone })}
                                inputRef={register({
                                    required: 'Required',
                                    minLength: { value: 10, message: '10 digits required' },
                                })}
                                tag={InputMask}
                                onChange={handlePhoneChange}
                                value={phoneNumber}
                            />
                        </FormField>
                    </div>
                    <div>
                        <button
                            className="btn-primary-full"
                            type="submit"
                        >
                            Text me a link
                        </button>
                    </div>
                </form>
            </div>
            <div className="dashboard__on-the-go-card-right">
                <img alt="mobile app" src={Image}/>
            </div>
        </DashboardCard>
    );
};
