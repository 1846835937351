import React, { useEffect, useLayoutEffect, useState } from 'react';
import { STORES, WithAuthStoreProps, WithSignupStoreProps, withStores, WithUserStoreProps } from '@src/stores/with-store';
import Form, {FormData} from './Form';
import { calculateForwardedCampaignParams } from './ForwardedCampaign';
import ReactPixel from 'react-facebook-pixel';
import { trackGoogleEvent, trackFacebookStandardEvent, trackFacebookCustomEvent, SignupAnalyticsEvents } from '@src/theme/utils/Analytics';
import PwdDialog, {FormData as PwdDialogFormData} from './PwdDialog';
import { postLogin } from '@src/requests/auth.requests';
import { REDIRECT_BACK_TO_LOCALSTORAGE_KEY } from '@src/theme/utils/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getBillingPlanAndPriceByPriceId } from '@src/theme/utils/helpers';
import { ERROR_TOAST_AUTOCLOSE } from '@src/theme/utils/constants';
import '../Signup.sass';

type AccountProps = WithUserStoreProps & WithAuthStoreProps & WithSignupStoreProps;


const googleAuthorizationSuccessOrRequestCompleteSignup = () => {
    trackGoogleEvent(SignupAnalyticsEvents.Google);
};

const AccountWithStores: React.FunctionComponent<AccountProps> = ({ AuthStore, SignupStore, UserStore }) => {
    const [pwdDialogOpen, setPwdDialogOpen] = useState(false);
    const [formData, setFormData] = useState<FormData>(null);
    const history = useHistory();
    const location = useLocation();

    const initReactPixel = () => {
        if (!SignupStore.isReactPixelInitialized) {
            ReactPixel.init('794194067960553', null, { autoConfig: true, debug: false });
            SignupStore.setIsReactPixelInitialized(true);
        };
    };

    const fireReactPixelViewContent = () => {
        const previousFire = JSON.parse(localStorage.getItem('reactPixelViewContentFireTime'));
        const diff = previousFire && (Math.abs((new Date(previousFire) as any) - (new Date() as any))/1000)/60;
        if (!previousFire || diff > 5) {
            localStorage.setItem('reactPixelViewContentFireTime', JSON.stringify(new Date()))
            trackFacebookStandardEvent('ViewContent');
            trackGoogleEvent(SignupAnalyticsEvents.FlowEntry);
        };
    };

    useEffect(() => {
        initReactPixel();
        fireReactPixelViewContent();
    }, []);

    useEffect(() => {
        if ((SignupStore.googleAuthorization === 'success' || SignupStore.googleAuthorization === 'requestCompleteSignup')) {
            googleAuthorizationSuccessOrRequestCompleteSignup();
        } else if (SignupStore.googleAuthorization === 'accountAlreadyCreated') {
            history.push('/dashboard');
        } else if (SignupStore.googleAuthorization === 'fail') {
            toast.error('Something went wrong. Please try again.', ERROR_TOAST_AUTOCLOSE);
        }
    }, [SignupStore.googleAuthorization]);

    const createAccount = (data: FormData) => {
        const areTrialDays = getBillingPlanAndPriceByPriceId(SignupStore.plans, data.priceId).billingPlan.trialDays;
        return SignupStore.createOrUpdateAccount(data, false)
        .then((val) => {
            if (val === 'ok') {
                trackGoogleEvent(SignupAnalyticsEvents.AccountInfo);
                history.push(location.pathname + `/verify`, {
                    userPhone: data.phone,
                    priceId: data.priceId,
                    priceAmount: getBillingPlanAndPriceByPriceId(SignupStore.plans, data.priceId).price.amount,
                    requireCC: !areTrialDays
                });
            } else if (val === 'emailAlreadyExists') {
                setPwdDialogOpen(true);
            };
        });
    };

    const handleFormSubmit = (data: FormData) => {
        const forwardedCampaignParams = calculateForwardedCampaignParams(window.location.search);

        if (getBillingPlanAndPriceByPriceId(SignupStore.plans, data.priceId).billingPlan.trialDays) {
            data.address = null;
        };

        SignupStore.validateEmail(data.email, forwardedCampaignParams)
        .then(val => {
            if (val === 'success') {
                trackGoogleEvent(SignupAnalyticsEvents.Email);
                trackFacebookCustomEvent('Lead', {value: getBillingPlanAndPriceByPriceId(SignupStore.plans, data.priceId).price.amount});
                if (UserStore.signUpUser || UserStore.user) {
                    AuthStore.pureLogout()
                    .then(() => {
                        createAccount(data);
                    })
                } else {
                    createAccount(data);
                };
            } else if (val === 'accountAlreadyExists') {
                setPwdDialogOpen(true);
            }
        });
    };

    const handleFormSubmitClick = (data: FormData) => {
        setFormData(data);
        handleFormSubmit(data);
    };

    const signIn = (email: string, password: string) => {
        const loginData = {email, password, rememberMe: false};
        postLogin(loginData).then(
            () => {
                AuthStore.loadAuthLogin().then((val) => {
                    if (val === 'success') {
                        const redirectPath = localStorage.getItem(REDIRECT_BACK_TO_LOCALSTORAGE_KEY) ?? '/dashboard';
                        localStorage.removeItem(REDIRECT_BACK_TO_LOCALSTORAGE_KEY);
                        history.push(redirectPath);
                    } else if (val === 'successIncomplete') {
                        history.push('/signupfull');
                    }
                });
            },
            (err) => {
                console.log('error on login:', err);
            }
        );
    };

    const handlePwdDialogSubmit = (data: PwdDialogFormData) => {
        setPwdDialogOpen(false);
        signIn(formData.email, data.password);
    };

    useLayoutEffect(() => {
        trackGoogleEvent(SignupAnalyticsEvents.FlowEntry);
        if (!SignupStore.plans) {
            SignupStore.getPlans()
            .then(() => {
                if (UserStore.signUpUser || UserStore.user) {
                    AuthStore.logout();
                }
            })
        }
    }, []);

    return (
        <div className="Signup__main">
            <h2 className="Signup__subtitle">Let&apos;s create your unique Myopolis account</h2>
            {SignupStore.plans && <Form googleAuth={SignupStore.googleAuthorization} plans={SignupStore.plans.map(el => el.billingPlan)} onSubmit={handleFormSubmitClick}/>}
            {pwdDialogOpen && <PwdDialog onClose={() => setPwdDialogOpen(false)} onSubmit={handlePwdDialogSubmit}/>}
        </div>
    );
};

export const Account = withStores(AccountWithStores, [STORES.User, STORES.Auth, STORES.Signup]);
