import { action, computed, observable, ObservableMap, runInAction } from 'mobx';
import { mergeLeft, concat, isNil } from 'ramda';
import RemoveHtml from '@src/utils/removehtml';
import { statusesOrder } from '@src/theme/utils/constants';
import {
    Attachment,
    CustomerBase,
    Employee,
    HashMap,
    LoadingState,
    MessagesFilter,
    MessagesSearch,
    MessagingConstants,
    Role,
    Template,
    ThreadData,
    ThreadFeed,
    ThreadSearchResult,
    OnErrorThread,
    PhoneCall,
} from '@src/stores/models';
import { MessagesApi } from '@src/requests';
import { SocketEventType, SocketWrapper } from '@src/api/socket-wrapper';
import { BaseStore } from '@src/stores/base.store';
import { MessagesStore } from './messages.store';
import TagsStore from '../tags/tags.store';
import AuthStore from '@src/stores/auth.store';
import UserStore from '@src/stores/user.store';
import { convertToPureNumber } from '@src/theme/utils/helpers';

interface SearchThreads {
    search?: string;
    open?: boolean;
    feed?: ThreadFeed;
    tags?: string[];
    threadId?: string;
    showLoader?: boolean;
    loadMore?: boolean;
    firstLoading?: boolean;
    scroll?: boolean;
}

export class ThreadsStore extends BaseStore {
    private messagesStore: MessagesStore;
    private socketWrapper: SocketWrapper;
    @observable constants: MessagingConstants;
    @observable private _currentThread: ThreadData;
    @observable private _filters: MessagesFilter;
    @observable employees: HashMap<Employee>;
    @observable private _searchResults: ThreadSearchResult[]; // threads open or closed
    @observable private _messageTemplates: Template[];
    @observable filtersCount = new ObservableMap<ThreadFeed, number>({
        [ThreadFeed.ALL]: 0,
        [ThreadFeed.ASSIGNED_TO_ME]: 0,
        [ThreadFeed.UNASSIGNED]: 0,
    });
    @observable threadLoading: LoadingState = LoadingState.Init;
    onError: (message: string) => void;
    private _onSuccess: () => void;
    private _onErrorAddingCustomer: (type: string, data?: OnErrorThread) => void;
    private _onErrorIncorrectPhone: () => void;
    private _onErrorNoSelectedPhoneNumber: () => void;
    private _onErrorLimitHit: (limitType: 'Trial' | 'BillingPlan') => void;
    @observable pageMessages = 2;
    @observable pageThreads = 1;
    @observable loadMoreMessages = false;
    @observable loadMoreThreads = false;
    @observable sizeThreads = 20;
    private msgToBeMarkedAsViewed = {};
    @observable incomingMessage = null;
    @observable key = 0;
    private fakeIdNo = 0;

    set onSuccess(fn: () => void) {
        this._onSuccess = fn;
    }

    set onErrorAddingCustomer(fn: (type: string, data?: OnErrorThread) => void) {
        this._onErrorAddingCustomer = fn;
    }

    set onErrorIncorrectPhone(fn: () => void) {
        this._onErrorIncorrectPhone = fn;
    }

    set onErrorNoSelectedPhoneNumber(fn: () => void) {
        this._onErrorNoSelectedPhoneNumber = fn;
    }

    set onErrorLimitHit(fn: (limitType: 'Trial' | 'BillingPlan') => void) {
        this._onErrorLimitHit = fn;
    }

    @computed get currentThread(): ThreadData {
        return this._currentThread;
    }

    @computed get employee(): Employee {
        return this.constants?.employee;
    }

    @computed get messagingConstants(): MessagingConstants {
        return this.constants;
    }

    @action setMessagingConstants(val: MessagingConstants) {
        this.constants = val;
        this._messageTemplates = val.templates;
        this.employees = val.employees;
    }

    @action setMessagingConstantsNotifications(notificationSettings) {
        this.constants = { ...this.constants, notificationSettings };
    }

    @computed get owner(): Employee {
        if (!this.employees) {
            return null;
        }
        const owners = Object.values(this.employees).filter((emp) => emp.role === Role.OWNER);
        return owners.length ? owners[0] : null;
    }

    @computed get feed(): ThreadFeed {
        return this._filters?.feed;
    }

    @computed get open(): boolean {
        return this._filters?.open;
    }

    @computed get search(): string {
        return this._filters?.search;
    }

    @computed get tags(): string[] {
        return this._filters?.tags;
    }

    @computed get searchResults(): ThreadSearchResult[] {
        return this._searchResults;
    }

    @action setSearchResults(val: ThreadSearchResult[]) {
        this._searchResults = val.sort(this.sortResults);
    }

    @computed get templates(): Template[] {
        return this._messageTemplates ? this._messageTemplates : [];
    }

    constructor(messagesStore: MessagesStore, socketWrapper: SocketWrapper) {
        super();
        this.messagesStore = messagesStore;
        this.socketWrapper = socketWrapper;
    }

    getCounts(): void {
        const msg = {
            messageType: SocketEventType.ThreadCount,
            payload: {
                locationId: this.constants.locationId,
                userId: this.constants.employee?.id,
            },
        };
        this.socketWrapper.send(JSON.stringify(msg));
    }

    init(): void {
        this.loading = LoadingState.Init;
        this.threadLoading = LoadingState.Init;

        if (location.search != '?new') this._currentThread = null;

        this._searchResults = [];
        this._messageTemplates = [];
        this._filters = {} as any;
        this.pageMessages = 2;
        this.pageThreads = 1;
        // this.pageThreads = 2;
        this.loadMoreMessages = false;
        this.loadMoreThreads = false;
        this.filtersCount = new ObservableMap<ThreadFeed, number>({
            [ThreadFeed.ALL]: 0,
            [ThreadFeed.ASSIGNED_TO_ME]: 0,
            [ThreadFeed.UNASSIGNED]: 0,
        });

        if (this.socketWrapper) {
            this.socketWrapper.addEventListener(SocketEventType.NewMessageEvent, (incomingMsg) => {
                this.updateThreadMessages({
                    message: incomingMsg,
                    type: 'msg',
                });
                UserStore.addUnreadMessagesDot('customerThreads', incomingMsg.thread?.id);
            });

            this.socketWrapper.addEventListener(SocketEventType.ThreadStatus, (incomingMsg) => {
                this.updateThreadMessages({
                    message: incomingMsg,
                    type: 'closing',
                });
            });

            this.socketWrapper.addEventListener(SocketEventType.NoteAdded, (incomingMsg) => {
                this.updateThreadMessages({
                    message: incomingMsg,
                    type: 'note',
                });
            });

            this.socketWrapper.addEventListener(SocketEventType.ThreadAssigned, (incomingMsg) => {
                this.updateThreadMessages({
                    message: incomingMsg,
                    type: 'assignment',
                });
            });

            this.socketWrapper.addEventListener(SocketEventType.MessageSent, (incomingMsg) => {
                this.updateThreadMessages({
                    message: incomingMsg,
                    type: 'msg',
                });
            });

            this.socketWrapper.addEventListener(SocketEventType.VoiceMail, (incomingMsg) => {
                this.updateThreadMessages({
                    message: incomingMsg,
                    type: 'voiceMail',
                });
            });

            this.socketWrapper.addEventListener(SocketEventType.PhoneCall, (incomingMsg) => {
                this.updateThreadMessages({
                    message: incomingMsg,
                    type: 'phoneCall',
                });
            });

            this.socketWrapper.addEventListener(SocketEventType.GetThread, (data) => {
                this.updateThreadsListWithNewThread(data);
            });

            this.socketWrapper.addEventListener(SocketEventType.ThreadCount, (counts) => {
                this.updateFilterCounts(counts);
            });

            this.socketWrapper.addEventListener(
                SocketEventType.MessageStatusChange,
                (newStatusInfo) => {
                    this.updateMessageStatus(newStatusInfo);
                }
            );
        }
    }

    @action destroy() {
        if (this.socketWrapper) {
            this.socketWrapper.destroy();
        }
        this.loading = LoadingState.Init;
        this.threadLoading = LoadingState.Init;
        this._currentThread = null;
        this.employees = null;
        this.constants = null;
        this._searchResults = [];
        this._messageTemplates = [];
        this._filters = {} as any;
        this.pageMessages = 2;
        this.pageThreads = 1;
        this.loadMoreMessages = false;
        this.loadMoreThreads = false;
        this.sizeThreads = 20;
        this.msgToBeMarkedAsViewed = {};
        this.incomingMessage = null;
        this.key = 0;
        this.fakeIdNo = 0;
        this.filtersCount = new ObservableMap<ThreadFeed, number>({
            [ThreadFeed.ALL]: 0,
            [ThreadFeed.ASSIGNED_TO_ME]: 0,
            [ThreadFeed.UNASSIGNED]: 0,
        });
    }

    updateThreadsListWithNewThread(data): void {
        this._searchResults = [data, ...this._searchResults].sort(this.sortResults);
    }

    @action assignUser(userId: string): void {
        this.threadLoading = LoadingState.Loading;
        MessagesApi.assignUser(this._currentThread.id, userId).then(() => {
            this.getCounts();
            runInAction(() => {
                this.threadLoading = LoadingState.Loaded;
            });
        });
    }

    @action closeCurrentThread(): Promise<void> {
        if (!this._currentThread) {
            return;
        }

        // this.threadLoading = LoadingState.Loading;
        // setTimeout(() => {
        //     runInAction(() => {
        this._currentThread = null;
        //         this.threadLoading = LoadingState.Loaded;
        //     });
        // }, 250);
        return Promise.resolve();
    }

    @action createNewThreadOnly(customer: CustomerBase): Promise<void> {
        if (customer?.id) {
            this._currentThread = {
                assigned: null,
                messages: {},
                customer: customer,
                voiceMail: {},
                open: true,
                read: true,
                receipts: {},
                calls: {},
            };
            return Promise.resolve();
        }
    }

    @action createNewThread(
        customer: CustomerBase,
        updateCurrentThread: boolean,
        errAction?: string
    ): Promise<void> {
        if (customer?.id) {
            this._currentThread = {
                assigned: null,
                messages: {},
                customer: customer,
                voiceMail: {},
                open: true,
                read: true,
                receipts: {},
                calls: {},
            };
            return Promise.resolve();
        }
        customer.phone = convertToPureNumber(customer.phone);
        if (customer['tags']) {
            customer['tags'] = customer['tags'].split(',').map((t) => t.trim());
        }

        return MessagesApi.addCustomer({
            ...customer,
            disallowReviews: false,
        }).then(
            (res) => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                    if (updateCurrentThread) {
                        this._currentThread = {
                            assigned: null,
                            messages: {},
                            customer: res.data.customer,
                            voiceMail: {},
                            open: true,
                            read: true,
                            receipts: {},
                            tags: res.data.tags,
                            calls: {},
                        };
                    }
                });
                return Promise.resolve();
            },
            (err) => {
                runInAction(() => {
                    if (
                        err.response?.data.phone ===
                            'Phone number is invalid. Please enter a valid 10 digit phone number' &&
                        this._onErrorIncorrectPhone
                    ) {
                        this._onErrorIncorrectPhone();
                    } else if (this._onErrorAddingCustomer && errAction) {
                        this._onErrorAddingCustomer(errAction, {
                            ...customer,
                            disallowReviews: false,
                        });
                    } else if (this.onError) {
                        this.onError('Something went wrong. Please try again later.');
                    }
                });
                return Promise.reject();
            }
        );
    }

    @action markAsViewed(id: string, lastMessageIdWeMarkAsViewed: string) {
        if (
            !this._currentThread ||
            !this._currentThread?.id ||
            this.msgToBeMarkedAsViewed[lastMessageIdWeMarkAsViewed]
        ) {
            return;
        }
        this.msgToBeMarkedAsViewed[lastMessageIdWeMarkAsViewed] = true;
        if (this._currentThread?.id) {
            return MessagesApi.markAsViewed(id, lastMessageIdWeMarkAsViewed).then(
                () => {
                    runInAction(() => {
                        if (this._currentThread) {
                            this._currentThread.read = true;
                        }
                        if (this.searchResults.find((el) => el?.thread?.id === id)?.thread) {
                            this.searchResults.find(
                                (el) => el.thread?.id === id
                            ).thread.read = true;
                        }
                    });
                    UserStore.removeUnreadMessagesDot('customerThreads', id);
                    return;
                },
                (err) => this.onError(err.message)
            );
        }
    }
    @action loadAThread(threadId) {
        return MessagesApi.getThread(threadId).then((thread) => {
            return thread;
        });
    }

    @action selectThreadByThreadId(threadId: string): Promise<void> {
        if (threadId === '?new') return Promise.resolve();
        this.threadLoading = LoadingState.Loading;
        this.pageMessages = 2;
        return MessagesApi.getThread(threadId).then(
            (res) => {
                runInAction(() => {
                    // const assigned = Object.values(res.assigned).sort((a, b) => {
                    //     const date1 = a.created;
                    //     const date2 = b.created;

                    //     return new Date(date1).getTime() - new Date(date2).getTime();
                    // });
                    this._currentThread = {
                        id: threadId,
                        assignedMember: res.thread.assigned,
                        messages: res.messages,
                        customer: res.customer,
                        voiceMail: res.voiceMail,
                        open: res.thread?.open,
                        read: res.thread?.read,
                        closings: res.closings,
                        assigned: res.assigned,
                        notes: res.notes,
                        receipts: res.receipts,
                        tags: res.tags,
                        calls: res.calls,
                    };
                    if (res.events.length === 20) this.loadMoreMessages = true;

                    this.threadLoading = LoadingState.Loaded;
                });
            },
            (err) => {
                // this.onError(err.response?.data || err.message || err);
                this.threadLoading = LoadingState.Error;
            }
        );
    }

    @action selectThread(thread: ThreadSearchResult, threadMessages?: MessagesSearch): void {
        const threadFromMessages = threadMessages?.thread;
        const assigned = Object.values(threadMessages.assigned).sort((a, b) => {
            const date1 = a.created;
            const date2 = b.created;

            return new Date(date1).getTime() - new Date(date2).getTime();
        });

        this._currentThread = {
            id: thread?.thread?.id,
            assigned: assigned[assigned.length - 1] && assigned[assigned.length - 1].assigneeId,
            messages: threadMessages?.messages,
            customer: thread.customer ? thread.customer : threadMessages.customer,
            voiceMail: threadMessages?.voiceMail,
            open: threadFromMessages?.open,
            read: threadFromMessages?.read,
            calls: threadMessages?.calls,
        };
    }

    saveFiltersInLocalStorage(): void {
        if (
            this._filters.search ||
            !this._filters.open ||
            this._filters.feed > 0 ||
            !!this._filters.tags.length
        ) {
            localStorage.setItem('messagingSearchData', JSON.stringify(this._filters));
        } else if (localStorage.getItem('messagingSearchData')) {
            localStorage.removeItem('messagingSearchData');
        }
    }

    @action searchThreads(data: SearchThreads): Promise<void> {
        const newFilters = {
            open:
                data.open !== undefined
                    ? data.open
                    : data.search === null
                    ? true
                    : data.search !== undefined
                    ? undefined
                    : this.open !== undefined
                    ? this.open
                    : true,
            feed:
                data.feed !== undefined
                    ? data.feed
                    : data.search !== undefined || data.tags !== undefined || data.tags?.length > 0
                    ? -1
                    : this.feed || 0,
            tags:
                data.tags !== undefined || data.tags?.length > 0
                    ? data.tags
                    : data.search !== undefined || data.feed !== undefined
                    ? []
                    : this.tags || [],
            search: data.search !== undefined ? data.search : undefined,
        };

        TagsStore.TagsListStore.setSelectedTagsIds(newFilters.tags);

        if (!data.scroll || (!isNil(data.open) && data.open != this._filters.open)) {
            this.pageThreads = 1;
            this.loadMoreThreads = false;
        }

        if (typeof data.feed === 'number' && !data.scroll) this.pageThreads = 1;

        if (this.pageThreads === 1) this._searchResults = [];

        if (
            data.showLoader &&
            !data.scroll &&
            data.feed === this._filters?.feed &&
            data.open === this._filters?.open &&
            data.search?.toLowerCase() === this._filters.search?.toLowerCase() &&
            data.tags === this._filters?.tags
        )
            return Promise.resolve();

        this._filters = newFilters;
        this.loading = data.showLoader ? LoadingState.Loading : LoadingState.Init;

        return MessagesApi.searchThreads(this._filters, this.pageThreads).then(
            ({ threads, total }) => {
                this.saveFiltersInLocalStorage();
                runInAction(() => {
                    this.loadMoreThreads =
                        total > (this.pageThreads - 1) * this.sizeThreads + threads.length;
                    // if (!data.firstLoading) {
                    this.pageThreads += 1;
                    // }
                    const results = threads.sort((thr1, thr2) => {
                        const date1 = (thr1 as any).message?.updated;
                        const date2 = (thr2 as any).message?.updated;
                        return new Date(date2).getTime() - new Date(date1).getTime();
                    });
                    console.log(results);
                    ///////////
                    this._searchResults = concat(
                        data.loadMore ? this._searchResults : [],
                        results
                    ).sort(this.sortResults);
                    this.loading = LoadingState.Loaded;
                });
            },
            (err) => {
                if (err.response?.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    this.onError(err);
                }
                runInAction(() => {
                    this.loading = LoadingState.Error;
                });
            }
        );
    }

    @action sendMessage(
        msg: string,
        attachments: Attachment[],
        isNewThread = false,
        isNewCustomer = false
    ): Promise<void> {
        const sanitizedText = RemoveHtml.rawText(msg, true);
        // const message = sanitizedText.replace('Review Invitation', ':review_invitation:');
        if (isNewThread) {
            return MessagesApi.startNewThread(
                this.currentThread.customer,
                sanitizedText,
                attachments,
                isNewCustomer
            ).then(
                (newThread) => {
                    return MessagesApi.getThread(newThread?.thread.id).then((messages) => {
                        this.selectThread(newThread, messages);
                    });
                },
                (err) => {
                    this.messagesStore.loading = LoadingState.Error;
                    if (
                        err.response.data.error === 'Phone number was not set' &&
                        this._onErrorNoSelectedPhoneNumber
                    ) {
                        this._onErrorNoSelectedPhoneNumber();
                    } else if (err?.response?.status === 402) {
                        this._onErrorLimitHit(err?.response?.data.limitType);
                    } else {
                        this.onError(
                            "Something went wrong and your message hasn't been sent. Please try again later."
                        );
                    }
                }
            );
        }

        const fakeDate = new Date().toISOString();
        this.fakeIdNo = this.fakeIdNo + 1;
        const fakeSendingMsg = {
            type: 'msg',
            message: {
                payload: {
                    attachments: [],
                    created: fakeDate,
                    customer: null,
                    feedback: false,
                    from: '',
                    id: 'fake' + this.fakeIdNo,
                    sent: true,
                    sentBy: this.constants?.employee.id,
                    sourceId: '',
                    text: msg,
                    threadId: this.currentThread.id,
                    to: '',
                    updated: fakeDate,
                },
            },
        };
        this.updateThreadMessages(fakeSendingMsg);

        return MessagesApi.sendMessage(this._currentThread.id, sanitizedText, attachments).then(
            (res) => {
                this.updateThreadMessages({ message: { payload: res.message }, type: 'msg' });
                this.markAsViewed(this._currentThread.id, res.message.id);
                return Promise.resolve();
            },
            (err) => {
                if (err?.response?.status === 402) {
                    this._onErrorLimitHit(err?.response?.data.limitType);
                } else {
                    this.onError(
                        "Something went wrong and your message hasn't been sent. Please try again later."
                    );
                }

                runInAction(() => {
                    const unsentMessage = this._currentThread.messages['fake' + this.fakeIdNo];
                    unsentMessage.message = { ...unsentMessage.message, unsuccessfulSending: true };
                    this._currentThread.messages['fake' + this.fakeIdNo] = { ...unsentMessage };
                    this.key += 1;
                });
                return Promise.resolve();
            }
        );
    }

    @action toggleOpenState(open: boolean): Promise<void> {
        this.threadLoading = LoadingState.Loading;
        return MessagesApi.toggleOpen(this._currentThread.id, open).then(
            () => {
                runInAction(() => {
                    this.threadLoading = LoadingState.Loaded;
                });
            },
            (err) => this.onError(err)
        );
    }

    // @action updateThread(value: unknown, field: string): void {
    //     this._currentThread = {
    //         ...this._currentThread,
    //         [field]: value,
    //     };
    // }

    sortResults = (thr1, thr2) => {
        const date1 = (thr1 as any).thread?.updated;
        const date2 = (thr2 as any).thread?.updated;
        return new Date(date2).getTime() - new Date(date1).getTime();
    };

    updateMessageStatus(newStatusInfo) {
        if (!this._currentThread || this._currentThread.id !== newStatusInfo.thread.id) {
            return;
        }
        const prevStatusIdx = statusesOrder.indexOf(
            this._currentThread.messages[newStatusInfo.messageId]?.message.sendingStatus
        );
        const newStatusIdx = statusesOrder.indexOf(newStatusInfo.newStatus);
        if (newStatusIdx > prevStatusIdx) {
            const updatedMessages = {
                ...this._currentThread.messages,
                [newStatusInfo.messageId]: {
                    ...this._currentThread.messages[newStatusInfo.messageId],
                    message: {
                        ...this._currentThread.messages[newStatusInfo.messageId].message,
                        sendingStatus: newStatusInfo.newStatus,
                    },
                },
            };
            this._currentThread.messages = { ...updatedMessages };
        }
    }

    updateThreadForMessage(threadToUpdate, message, event) {
        const didWeSendIt = message.sent;
        const itsTheCurrentThread =
            this._currentThread && this._currentThread.id === message?.threadId;

        if (threadToUpdate) {
            const theThreadWasOpen = this._searchResults.find(
                (x) => x.thread.id === message?.threadId
            )?.thread.open;
            const shouldItBeOpenNow = threadToUpdate.thread.open || !didWeSendIt;

            const updatedThread = {
                ...threadToUpdate,
                message: message,
                thread: {
                    ...threadToUpdate.thread,
                    updated: message.created,
                    open: shouldItBeOpenNow,
                    read: message.sent,
                },
            };

            if (!theThreadWasOpen && !didWeSendIt) {
                // then we move it to open
                if (this._filters.open) {
                    this._searchResults = [updatedThread, ...this._searchResults];
                } else {
                    this._searchResults = this._searchResults.filter(
                        (el) => el.thread.id !== threadToUpdate.message.threadId
                    );
                }
            }

            this._searchResults =
                this._searchResults
                    .map((el) => {
                        if (el.thread.id == message.threadId) {
                            return updatedThread;
                        } else {
                            return el;
                        }
                    })
                    .sort(this.sortResults) || [];
        } else if (this._filters.open) {
            const payload = {
                messageType: SocketEventType.GetThread,
                payload: { threadId: message.threadId },
            };
            this.socketWrapper.send(JSON.stringify(payload));
        }

        if (itsTheCurrentThread) {
            // add it to the current thread
            const updatedCurrentThreadMessages = {
                ...this._currentThread.messages,
                [message.id]: {
                    message: message,
                    attachments: message.attachments,
                },
            };
            const msgToDelete =
                this._currentThread.messages &&
                Object.values(this._currentThread.messages).find(
                    (el) => el.message.text === message.text && el.message.id.startsWith('fake')
                )?.message.id;
            if (msgToDelete) {
                delete updatedCurrentThreadMessages[msgToDelete];
            }

            this._currentThread = {
                ...this._currentThread,
                messages: updatedCurrentThreadMessages,
                read: didWeSendIt,
                open: this._currentThread.open || !didWeSendIt,
            };
        } else if (!message?.sent) {
            this.incomingMessage = event;
        }
    }

    updateThreadForStatusUpdate(threadToUpdate, message) {
        const itsTheCurrentThread =
            this._currentThread && this._currentThread.id === message?.threadId;

        if (threadToUpdate) {
            const theThreadWasClosed = !threadToUpdate.thread.open;
            const shouldItBeOpenNow = message.open;
            const updatedThread = {
                ...threadToUpdate,
                thread: {
                    ...threadToUpdate.thread,
                    open: shouldItBeOpenNow,
                },
            };
            if (
                (this._filters.open && theThreadWasClosed && shouldItBeOpenNow) ||
                (!this._filters.open && !theThreadWasClosed && !shouldItBeOpenNow)
            ) {
                // then add it to the current list
                this._searchResults = [updatedThread, ...this._searchResults];
                this._searchResults = this._searchResults.slice().sort(this.sortResults) || [];
            } else {
                // then delete it to the current list
                this._searchResults =
                    this._searchResults
                        .filter((el) => el.thread?.id !== threadToUpdate.thread.id)
                        .sort(this.sortResults) || [];
            }
        } else {
            const payload = {
                messageType: SocketEventType.GetThread,
                payload: { threadId: message.threadId },
            };
            this.socketWrapper.send(JSON.stringify(payload));
        }

        if (itsTheCurrentThread) {
            const closings = {
                ...this._currentThread.closings,
                [message.id]: {
                    id: message.id,
                    created: message.created,
                    threadId: message.threadId,
                    open: message.open,
                },
            };
            this._currentThread = {
                ...this._currentThread,
                closings: closings,
                open: message.open,
            };
        }
    }

    updateThreadForAssignment(message) {
        const itsTheCurrentThread =
            this._currentThread && this._currentThread.id === message?.threadId;

        if (itsTheCurrentThread) {
            const assignments = {
                ...this._currentThread.assigned,
                [message.id]: {
                    id: message.id,
                    created: message.created,
                    threadId: message.threadId,
                    assigneeId: message.assigneeId,
                    assignorId: message.assignorId,
                },
            };
            this._currentThread = {
                ...this._currentThread,
                assigned: assignments,
                assignedMember: message.assigneeId,
            };
        }

        this._searchResults =
            this._searchResults
                .map((el) => {
                    if (el.thread.id == message.threadId) {
                        return {
                            ...el,
                            thread: {
                                ...el.thread,
                                updated: message.created,
                            },
                        };
                    } else {
                        return el;
                    }
                })
                .sort(this.sortResults) || [];
    }

    updateThreadForNote(message) {
        const itsTheCurrentThread =
            this._currentThread && this._currentThread.id === message?.threadId;

        if (itsTheCurrentThread) {
            const notes = {
                ...this._currentThread.notes,
                [message.id]: {
                    id: message.id,
                    created: message.created,
                    threadId: message.threadId,
                    createdBy: message.createdBy,
                    updated: message.updated,
                    text: message.text,
                },
            };

            this._currentThread = {
                ...this._currentThread,
                notes: notes,
            };
        }

        this._searchResults =
            this._searchResults
                .map((el) => {
                    if (el.thread.id == message.threadId) {
                        return {
                            ...el,
                            thread: {
                                ...el.thread,
                                updated: message.created,
                            },
                        };
                    } else {
                        return el;
                    }
                })
                .sort(this.sortResults) || [];
    }

    updateThreadForVoiceMail(threadToUpdate, message) {
        if (threadToUpdate) {
            const theThreadWasClosed = !threadToUpdate.thread.open;
            const shouldItBeOpenNow = threadToUpdate.thread.open;

            const updatedThread = {
                ...threadToUpdate,
                message: message,
                thread: {
                    ...threadToUpdate.thread,
                    updated: message.created,
                    read: false,
                    open: shouldItBeOpenNow,
                },
            };

            if (theThreadWasClosed) {
                if (this._filters.open) {
                    this._searchResults = [updatedThread, ...this._searchResults];
                } else {
                    this._searchResults = this._searchResults.filter(
                        (el) => el.thread.id !== threadToUpdate.message.threadId
                    );
                }
            }

            this._searchResults =
                this._searchResults
                    .map((el) => {
                        if (el.thread.id == message.threadId) {
                            return {
                                ...el,
                                thread: {
                                    ...el.thread,
                                    updated: message.created,
                                    read: false,
                                    open: shouldItBeOpenNow,
                                },
                            };
                        } else {
                            return el;
                        }
                    })
                    .sort(this.sortResults) || [];
        } else if (this._filters.open) {
            const payload = {
                messageType: SocketEventType.GetThread,
                payload: { threadId: message.threadId },
            };
            this.socketWrapper.send(JSON.stringify(payload));
        }

        const itsTheCurrentThread =
            this._currentThread && this._currentThread.id === message?.threadId;
        if (itsTheCurrentThread) {
            const voiceMail = {
                ...this._currentThread.voiceMail,
                [message.id]: {
                    id: message.id,
                    created: message.created,
                    updated: message.updated,

                    duration: message.duration,
                    locationId: message.locationId,
                    phone: message.phone,
                    rid: message.rid,
                    url: message.url,
                },
            };

            this._currentThread = {
                ...this._currentThread,
                voiceMail: voiceMail,
                read: false,
                open: true,
            };
        }
    }

    updateThreadForPhoneCall(threadId: string, message: PhoneCall) {
        const itsTheCurrentThread = this._currentThread && this._currentThread.id === threadId;

        if (itsTheCurrentThread) {
            const calls = {
                ...this._currentThread.calls,
                [message.id]: message,
            };

            this._currentThread = {
                ...this._currentThread,
                calls: calls,
            };
        }

        this._searchResults =
            this._searchResults
                .map((el) => {
                    if (el.thread.id == threadId) {
                        return {
                            ...el,
                            thread: {
                                ...el.thread,
                                updated: message.finishedAt,
                            },
                        };
                    } else {
                        return el;
                    }
                })
                .sort(this.sortResults) || [];
    }

    @action checkAllThreads(): Promise<any> {
        return MessagesApi.searchThreads();
    }

    updateThreadMessages(event): void {
        const message =
            event.message.payload ||
            event.message.status ||
            event.message.note ||
            event.message.voiceMail ||
            event.message.call;

        runInAction(() => {
            this.key += 1;
            if (event.type === 'msg') {
                const threadId = message.threadId || message.thread.id;
                let threadToUpdate = this._searchResults.find((x) => x.thread.id === threadId);
                if (threadToUpdate) {
                    this.updateThreadForMessage(threadToUpdate, message, event);
                } else {
                    this.checkAllThreads().then((res) => {
                        threadToUpdate = res.threads.find((x) => x.thread.id === threadId);
                        this.updateThreadForMessage(threadToUpdate, message, event);
                    });
                }
            } else if (event.type === 'closing') {
                const threadId = message.threadId || message.thread.id;
                const threadToUpdate = this._searchResults.find((x) => x.thread.id === threadId);
                this.updateThreadForStatusUpdate(threadToUpdate, message);
            } else if (event.type === 'assignment') {
                this.updateThreadForAssignment(message);
            } else if (event.type === 'note') {
                this.updateThreadForNote(message);
            } else if (event.type === 'voiceMail') {
                const threadId = event.message.thread.id;
                const threadToUpdate = this._searchResults.find((x) => x.thread.id === threadId);
                this.updateThreadForVoiceMail(threadToUpdate, { ...message, threadId });
            } else if (event.type === 'phoneCall') {
                const threadId = event.message.thread.id;
                this.updateThreadForPhoneCall(threadId, message);
            }
        });
    }

    @action removeIncomingMessage = () => {
        this.incomingMessage = null;
    };

    @action updateFilterCounts(counts: HashMap<number>) {
        this.filtersCount.set(ThreadFeed.ALL, counts?.all || 0);
        this.filtersCount.set(ThreadFeed.UNASSIGNED, counts?.notAssigned || 0);
        this.filtersCount.set(ThreadFeed.ASSIGNED_TO_ME, counts?.userAssigned || 0);
    }

    @action getNotes(customerId: string) {
        this.threadLoading = LoadingState.Loading;
        MessagesApi.getNotes(customerId).then(
            (res: any) => {
                runInAction(() => {
                    if (this.currentThread) this.currentThread.notes = res.data;
                    this.key += 1;
                    this.threadLoading = LoadingState.Loaded;
                });
            },
            (err) => {
                this.onError(err.message);
                this.threadLoading = LoadingState.Error;
            }
        );
    }

    @action postNote(customerId: string, text: string) {
        return MessagesApi.postNote(customerId, text).then(
            () => {
                //
            },
            (err) => this.onError(err.message)
        );
    }

    @action loadMorePages(): Promise<void> {
        const threadId = this?._currentThread?.id;
        this.threadLoading = LoadingState.Loading;
        return MessagesApi.getThread(threadId, 20, this.pageMessages).then(
            (res) => {
                if (!this._currentThread || this._currentThread.id != threadId) return;
                runInAction(() => {
                    const newData = {
                        id: threadId,
                        assignedMember: this._currentThread.assignedMember,
                        messages: mergeLeft(res.messages, this._currentThread.messages),
                        customer: res.customer,
                        voiceMail: mergeLeft(res.voiceMail, this._currentThread.voiceMail),
                        open: res.thread?.open,
                        read: res.thread?.read,
                        closings: mergeLeft(res.closings, this._currentThread.closings),
                        assigned: mergeLeft(res.assigned, this._currentThread.assigned),
                        notes: mergeLeft(res.notes, this._currentThread.notes),
                        receipts: mergeLeft(res.receipts, this._currentThread.receipts),
                        calls: mergeLeft(res.calls, this._currentThread.calls),
                    };
                    this._currentThread = newData;

                    this.pageMessages += 1;

                    if (Object.entries(res.events).length != 20) this.loadMoreMessages = false;

                    this.threadLoading = LoadingState.Loaded;
                });
            },
            (err) => this.onError(err)
        );
    }
}
