import { action, observable, runInAction } from 'mobx';
import { LoadingState } from '../models';
import {
    getConversation,
    startChannel,
    addUsersToChannel,
    removeUsersFromChannel,
    deleteConversation,
    editChannelName
} from "@src/requests/team-chat.requests";
import { BaseStore } from "@src/stores/base.store";
import MessagesStore from '@src/stores/messages/messages.store';
import { differenceWith } from 'ramda';

export default class EditedChannelStore extends BaseStore {
    @observable ConversationsStore;
    @observable editedChannel = null;
    @observable dataLoaded = false;
    @observable channelLoading: LoadingState = LoadingState.Init;
    @observable currentMembers = [];
    @observable nonMembers = [];
    @observable hasMoreCurrentMembersToLoad?: boolean = false;
    @observable hasMoreNonMembersToLoad?: boolean = false;
    @observable currentMembersPage?: number = 0;
    @observable nonMembersPage?: number = 0;
    @observable totalCurrentMembers?: number = null;
    @observable searchQueryCurrentMembers?: string = null;
    @observable searchQueryNonMembers?: string = null;
    private reqCurrentMembersWithPage = 0;
    private reqNonMembersWithPage = 0;

    constructor(ConversationsStore) {
        super();
        this.ConversationsStore = ConversationsStore;
    }

    @action init(): void {
        this.editedChannel = null;
        this.dataLoaded = false;
        this.channelLoading = LoadingState.Init;
        this.currentMembers = [];
        this.nonMembers = [];
        this.hasMoreCurrentMembersToLoad = false;
        this.hasMoreNonMembersToLoad = false;
        this.currentMembersPage = 0;
        this.nonMembersPage = 0;
        this.totalCurrentMembers = null;
        this.searchQueryCurrentMembers = null;
        this.searchQueryNonMembers = null;
        this.reqCurrentMembersWithPage = 0;
        this.reqNonMembersWithPage = 0;
    }

    @action setChannelEdition = ({ conversationId, name }): Promise<void> => {
        this.getConversation(name, conversationId);
        return Promise.resolve();
    };

    setChannel = (data, channelName) => {
        const cmp = (x, y) => x.id === y.participantId;
        const nonParticipants = differenceWith(cmp, Object.values(MessagesStore.ThreadsStore.constants.employees), data.participants);
        const participantsWithName = data.participants?.map(p => {
            const participantWithDetails = Object.values(MessagesStore?.ThreadsStore.constants?.employees).find(e => e.id === p.participantId);
            const firstName = participantWithDetails.firstName;
            const lastName = participantWithDetails.lastName;
            return { id: p.participantId, firstName, lastName };
        })
        const nonParticipantsWithName = nonParticipants?.map(p => {
            const participantWithDetails = Object.values(MessagesStore?.ThreadsStore.constants?.employees).find(e => e.id === p.id);
            const name = `${participantWithDetails.firstName} ${participantWithDetails.lastName}`;
            return {...p, name};
        })
        this.editedChannel = {
            id: data.conversation?.id,
            channelName
        };
        this.currentMembers = participantsWithName.sort(this.sortResults);
        this.nonMembers = nonParticipantsWithName.sort(this.sortResults);
        this.totalCurrentMembers = data.participants.length; // liczba z BE?
        this.channelLoading = LoadingState.Loaded;
    }

    @action getConversation = (channelName = this.editedChannel.channelName, cid = this.editedChannel.id) => {
        this.channelLoading = LoadingState.Loading;
        return getConversation(cid)
        .then(
            (conversation) => {
                runInAction(() => {
                    this.setChannel(conversation, channelName);
                    this.channelLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.channelLoading = LoadingState.Error;
                });
                return null;
            }
        )
    }

    @action closeConversation(): Promise<void> {
        if (!this.editedChannel) {
            return;
        }
        setTimeout(() => {
            runInAction(() => {
                this.editedChannel = null;
            });
        }, 10);
        return Promise.resolve();
    }

    @action startChannel = (name: string, participants = []) => {
        this.channelLoading = LoadingState.Loading;
        return startChannel(name, participants).then(
            (res) => {
                return runInAction(() => {
                    this.setChannel(res, name);
                    this.setChannelEdition({conversationId: res.conversation.id, name: res.channelName});
                    this.ConversationsStore.ConversationsListStore.setChannelsList([
                        {
                            conversationId: res.conversation.id,
                            conversationType: "CHANNEL",
                            participants: res.participants,
                            lastupdate: new Date(),
                            lastread: new Date(),
                            name
                        },
                        ...this.ConversationsStore.ConversationsListStore.channelsList
                    ])
                    this.channelLoading = LoadingState.Loaded;
                });
            },
            () => {
                runInAction(() => {
                    this.channelLoading = LoadingState.Error;
                });
                return null;
            }
        );
    };

    @action addUsersToChannel = (participants: string[]) => {
        this.channelLoading = LoadingState.Loading;
        return addUsersToChannel(this.editedChannel.id, participants).then(
            () => {
                runInAction(() => {
                    if ( this.ConversationsStore.SelectedChannelStore.selectedChannel?.id === this.editedChannel?.id) {
                        participants.forEach(p => {
                            const participantWithDetails = Object.values(MessagesStore?.ThreadsStore.constants?.employees).find(e => e.id === p);
                            const name = `${participantWithDetails.firstName} ${participantWithDetails.lastName}`;
                            const newParticipant = { participantId: p, name };
                            const newList = [
                                ...this.ConversationsStore.SelectedChannelStore.currentMembers,
                                newParticipant,
                            ].sort(this.sortResults);
                            this.ConversationsStore.SelectedChannelStore.setCurrentMembers(newList);
                        })
                    }
                    this.channelLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.channelLoading = LoadingState.Error;
                });
                return null;
            }
        );
    }

    @action removeUsersFromChannel = (participants: string[]) => {
        this.channelLoading = LoadingState.Loading;
        return removeUsersFromChannel(this.editedChannel.id, participants).then(
            () => {
                runInAction(() => {
                    if (this.ConversationsStore.SelectedChannelStore.selectedChannel?.id === this.editedChannel?.id) {
                        participants.forEach(p => {
                            this.ConversationsStore.SelectedChannelStore.setCurrentMembers(
                                this.ConversationsStore.SelectedChannelStore.currentMembers.filter((el) => el?.participantId !== p)
                            );
                        })
                    }
                    this.channelLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.channelLoading = LoadingState.Error;
                });
                return null;
            }
        );
    }

    sortResults = (a, b) => {
        const A = a.name ? a.name.toLowerCase() : `${a.firstName} ${a.lastName}`;
        const B = b.name ? b.name.toLowerCase() : `${b.firstName} ${b.lastName}`;
        if (A < B) {
            return -1;
        }
        if (A > B) {
            return 1;
        }
        return 0;
    };

    @action editChannelName = (name: string) => {
        this.channelLoading = LoadingState.Loading;
        return editChannelName(this.editedChannel.id, name).then(
            () => {
                runInAction(() => {
                    this.editedChannel.channelName = name;
                    if (this.ConversationsStore.SelectedChannelStore?.selectedChannel?.id === this.editedChannel?.id) {
                        this.ConversationsStore.SelectedChannelStore.setSelectedChannel({
                            ...this.ConversationsStore.SelectedChannelStore.selectedChannel,
                            channelName: name,
                        });
                    }
                    this.ConversationsStore.ConversationsListStore.setChannelsList(
                        this.ConversationsStore.ConversationsListStore.channelsList
                        ?.map((ch) => (ch?.conversationId === this.editedChannel?.id ? { ...ch, name } : ch))
                    );
                    this.channelLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.channelLoading = LoadingState.Error;
                });
                return null;
            }
        );
    }

    @action deleteChannel = (conversationId = this.editedChannel.id) => {
        this.channelLoading = LoadingState.Loading;
        return deleteConversation(conversationId).then(
            () => {
                runInAction(() => {
                    this.ConversationsStore.ConversationsListStore.setChannelsList(
                        this.ConversationsStore.ConversationsListStore.channelsList?.filter((d) => (d?.conversationId !== conversationId))
                    );
                    this.channelLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.channelLoading = LoadingState.Error;
                });
                return null;
            }
        );
    }
}

// export default new SelectedConversationStore(SocketInstance);
