import {
    ResponseHistogramInfo,
    ResponseInOutboundInfo,
    ConversationsBreakdownInfo,
} from '@src/stores/models';

interface MarginChart {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
}

interface ChartProps {
    margin?: MarginChart;
    barSize?: number;
    title: string;
    icon?: string;
}

export interface ConversationBreakdownProps extends ChartProps {
    dataChart: ConversationsBreakdownInfo[];
}

export interface ResponsesInOutInfoProps extends ChartProps {
    dataChart: ResponseInOutboundInfo[];
}

export interface ResponsesHistogramProps extends ChartProps {
    dataChart: ResponseHistogramInfo;
}

export const BLUE = '#0043ff';
export const ORANGE = '#F7AC2C';
export const FONT_COLOR = '#979797';
export const NAVY = '#232554';
export const CHART_HEIGHT = 187;
