import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { AsYouType } from 'libphonenumber-js';
import { FormField } from '@src/components';
import { useDebounce, useOutsideClick } from '@src/hooks';
import { CustomersApi } from '@src/requests';
import { Customer } from '@src/stores/models';
import { FormViewType } from '../ContactForm/FormViewType';
import { ReactComponent as ProfileOutlineIcon } from '@src/theme/icons/profile_outline.svg';
import { ReactComponent as AddIcon } from '@src/theme/icons/add.svg';

import './contact-select.sass';

interface ContactSelectProps {
    tagsMode?: boolean;
    onAction: (action: FormViewType, customer?: Customer, search?) => void;
}

export const ContactSelect: React.FC<ContactSelectProps> = (props) => {
    const optionsRef = useRef(null);
    const activeRef = useRef(null);
    const [activeIdx, setIndex] = useState(-1);
    const [error, setError] = useState({ message: '' });
    const [initCall, setInitCall] = useState(true);
    const [searchText, setText] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const [customers, setCustomers] = useState<Customer[]>([]);
    const debounceSearchText = useDebounce(searchText, 250);
    let globalIdx = -1;

    useOutsideClick(optionsRef, () => {
        setShowOptions(false);
    });

    useEffect(() => {
        if (initCall && !searchText.length) setInitCall(false);
        else {
            CustomersApi.searchCustomers(searchText).then((customers) => {
                setCustomers(customers);
                setShowOptions(!!debounceSearchText.length && !initCall);
                // setShowOptions(!!customers.length && !initCall);
            });
        }
    }, [debounceSearchText]);

    useEffect(() => {
        if (activeRef?.current) {
            activeRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }, [activeIdx]);

    const onRowSelect = (customer: Customer): void => {
        if (!customer.phone) {
            setError({ message: 'Phone number missing' });
            return;
        }
        props.onAction(FormViewType.SEND_MESSAGE, customer);
    };

    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if ([13, 27, 38, 40].includes(e.keyCode)) {
            if (e.keyCode === 27) {
                setShowOptions(false);
                return;
            }

            if (e.keyCode === 13) {
                const lowerSearchTxt = searchText.toLowerCase();
                const actionType =
                    activeIdx === customers.length
                        ? FormViewType.ADD_CONTACT
                        : FormViewType.SEND_MESSAGE;
                const customer =
                    activeIdx > -1 && activeIdx < customers.length
                        ? customers[activeIdx]
                        : customers.find(
                              (c) =>
                                  `${c.firstName} ${c.lastName}`.toLowerCase() === lowerSearchTxt ||
                                  (c.phone && c.phone.indexOf(lowerSearchTxt) > -1)
                          );

                if (actionType === FormViewType.SEND_MESSAGE) {
                    if (!customer) {
                        setError({ message: 'No customer found' });
                        return;
                    }

                    onRowSelect(customer);
                    return;
                }

                props.onAction(actionType, customer);
                setIndex(-1);
                setShowOptions(false);
                return;
            }

            if (!showOptions && e.keyCode === 40) {
                setShowOptions(true);
                return;
            }

            const cursor = e.keyCode === 40 ? 1 : -1;
            const totalItemsCount = customers.length;
            let newVal = activeIdx + cursor;
            newVal = newVal < 0 ? totalItemsCount : newVal >= totalItemsCount + 1 ? 0 : newVal;
            setIndex(newVal);
        }
    };

    const addCls = classNames({
        'list-item add-item': true,
        active: customers.length === activeIdx,
    });

    const analize = (searchText: string) => {
        if (!isNaN(Number(searchText))) {
            return { phone: searchText };
        }
        if (searchText.split(' ').length === 1) return { firstName: searchText };
        return { firstName: searchText.split(' ')[0], lastName: searchText.split(' ')[1] };
    };

    const options = showOptions && (
        <div className="ContactSelect__list" ref={optionsRef}>
            <div className="inner-list">
                <div className="inner-list-results">
                    {customers.map((customer) => {
                        globalIdx++;
                        const isActive = globalIdx === activeIdx;
                        const cls = classNames({
                            'list-item': true,
                            customer: customer.phone,
                            active: isActive,
                        });
                        return (
                            <div
                                key={uuidv4()}
                                className={cls}
                                {...(isActive && { ref: activeRef })}
                            >
                                <button onClick={() => onRowSelect(customer)}>
                                    <ProfileOutlineIcon className="old" />
                                    <div>
                                        <p>{`${
                                            customer.firstName ||
                                            new AsYouType('US').input(
                                                ('' + customer.phone).replace(/\D/g, '')
                                            )
                                        } ${customer.lastName || ''}`}</p>
                                        {!!customer.phone && (
                                            <p className="phone">
                                                {new AsYouType('US').input(
                                                    ('' + customer.phone).replace(/\D/g, '')
                                                )}
                                            </p>
                                        )}
                                    </div>
                                </button>
                            </div>
                        );
                    })}
                </div>
                {
                    <div className={addCls}>
                        <button
                            onClick={() =>
                                props.onAction(FormViewType.ADD_CONTACT, null, analize(searchText))
                            }
                        >
                            <AddIcon className="new" />
                            <span>Add new</span>
                        </button>
                    </div>
                }
            </div>
        </div>
    );

    return (
        <div className="ContactSelect">
            <FormField
                label="Name, Phone Number or Tag"
                htmlFor="contact-autocomplete"
                error={{
                    type: "contact",
                    ...error
                }}
            >
                <input
                    type="text"
                    placeholder="Type some text"
                    value={searchText}
                    onKeyDown={(e) => keyDown(e)}
                    onChange={(e) => setText(e.target.value)}
                    name="contact-autocomplete"
                    className={classNames('form-input')}
                />
            </FormField>
            {options || null}
        </div>
    );
};
