import React from 'react';

import { Review, ReviewRating, ReviewRecommendation, ReviewSource } from '@src/stores/models';

import { ReactComponent as Stars1 } from '@src/theme/icons/stars-1.svg';
import { ReactComponent as Stars2 } from '@src/theme/icons/stars-2.svg';
import { ReactComponent as Stars3 } from '@src/theme/icons/stars-3.svg';
import { ReactComponent as Stars4 } from '@src/theme/icons/stars-4.svg';
import { ReactComponent as Stars5 } from '@src/theme/icons/stars-5.svg';
import { ReactComponent as RecommendsFb } from '@src/theme/icons/rating-facebook.svg';
import { ReactComponent as NotRecommendsFb } from '@src/theme/icons/rating-facebook-negative.svg';

import './rating.sass';

export interface RatingProps {
    review?: Review;
}

export const Rating: React.FC<RatingProps> = ({ review }) => {
    const isFb = review.source.name === ReviewSource.FACEBOOK;

    const getRating = (): React.ReactNode => {
        if (isFb) {
            return review.recommendationType === ReviewRecommendation.RECOMMENDS ? (
                <RecommendsFb />
            ) : (
                <NotRecommendsFb />
            );
        }

        switch (review.rating) {
            case ReviewRating.ONE:
                return <Stars1 className="Rating" />;
            case ReviewRating.TWO:
                return <Stars2 className="Rating" />;
            case ReviewRating.THREE:
                return <Stars3 className="Rating" />;
            case ReviewRating.FOUR:
                return <Stars4 className="Rating" />;
            case ReviewRating.FIVE:
                return <Stars5 className="Rating" />;
        }
    };

    return <>{getRating()}</>;
};
