import React from 'react';
import classNames from 'classnames';
import { LoadingState } from '@src/stores/models';

import './loader.sass';

interface LoaderProps {
    className?: string;
    fullScreen?: boolean;
    loading?: boolean;
    loadingState?: LoadingState;
}

export const Loader: React.FC<LoaderProps> = (props) => {
    const cls = classNames('Loader', {
        [props.className]: props.className,
    });
    return (
        <div className={cls}>
            {(props.loading ||
                props.loadingState === LoadingState.Loading ||
                props.loadingState === LoadingState.FirstLoading) && (
                <div className={classNames('Loader__background', { fullScreen: props.fullScreen })}>
                    <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
        </div>
    );
};
