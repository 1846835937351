import { action, observable, runInAction, computed } from 'mobx';
import { Attachment, LoadingState } from '../models';
import { Conversation, TeamMessageEvent } from "@src/stores/models/team-chat";
import {
    getConversation,
    getMessages,
    sendMessage,
    startDirectConversation,
    editMessage,
    deleteMessage
} from "@src/requests/team-chat.requests";
import { BaseStore } from "@src/stores/base.store";
import { SocketEventType, SocketWrapper } from "@src/api/socket-wrapper";
import MessagesStore from '@src/stores/messages/messages.store';

export default class SelectedDirectConversationStore extends BaseStore {
    @observable ConversationsStore;
    private socketWrapper: SocketWrapper;
    @observable _selectedDirect = null;
    @observable messages = null;
    @observable messagesPage = null;
    @observable messagesSize = null;
    @observable messagesTotal = null;
    @observable messagesTime = null;
    @observable dataLoaded = false;
    @observable hasMoreMessagesToLoad = false;
    @observable directLoading: LoadingState = LoadingState.Init;
    @observable key = 0;
    private fakeIdNo = 0;

    onError: (message: string) => void;

    constructor(socketWrapper: SocketWrapper, ConversationsStore) {
        super();
        this.socketWrapper = socketWrapper;
        this.ConversationsStore = ConversationsStore;
    }

    @action init(): void {
        this._selectedDirect = null;
        this.dataLoaded = false;
        this.directLoading = LoadingState.Init;
        if (this.socketWrapper) {
            this.socketWrapper.addEventListener(SocketEventType.TeamMessage, (incomingMsg) => {
                this.messageAdded(incomingMsg);
            });
            this.socketWrapper.addEventListener(SocketEventType.TeamMessageUpdated, (incomingMsg) => {
                this.messageUpdated(incomingMsg);
            });
            this.socketWrapper.addEventListener(SocketEventType.TeamMessageDeleted, (incomingMsg) => {
                this.messageDeleted(incomingMsg);
            });
        }
    }

    @action destroy(): void {
        this._selectedDirect = null;
        this.messages = null;
        this.messagesPage = null;
        this.messagesSize = null;
        this.messagesTotal = null;
        this.messagesTime = null;
        this.dataLoaded = false;
        this.hasMoreMessagesToLoad = false;
        this.directLoading = LoadingState.Init;
        this.key = 0;
        this.fakeIdNo = 0;
    }

    @computed get selectedDirect() {
        return this._selectedDirect;
    }

    @action setSelectedDirect(val) {
        this._selectedDirect = val;
    }

    @action messageAdded = (e: TeamMessageEvent) => {
        if (!this._selectedDirect || this._selectedDirect.id !== e.message.conversationId) {
            return;
        }
        const alreadyExists = this.messages.find((msg) => { return msg.id === e.message.id });
        if (!alreadyExists) {
            this.messages = [
                ...this.messages,
                e.message
            ];
        };
        this.messages = this.messages.filter(el => el.message !== e.message.message || !el.id.startsWith('fake'));
        this.key += 1;
    }

    @action messageUpdated = (e: TeamMessageEvent) => {
        if (!this._selectedDirect || this._selectedDirect.id !== e.message.conversationId) {
            return;
        }
        const alreadyExists = this.messages.find((msg) => { return msg.id === e.message.id });
        if (alreadyExists) {
            this.messages =
                this.messages.map((x) => {
                    if (x.id == e.message.id) return e.message;
                    else return x;
                })
        }
        // this.key += 1;
    }

    @action messageDeleted = (e) => {
        if (!this._selectedDirect || this._selectedDirect.id !== e.conversationId) {
            return;
        }
        this.messages = this.messages.filter((msg) => msg.id !== e.messageId);
        // this.key += 1;
    }

    @action selectDirectConversation = (cid: string) => {
        this.directLoading = LoadingState.Loading;
        return getConversation(cid)
        .then(
            (res) => {
                runInAction(() => {
                    const colleagueId = res.participants.filter(p => p.participantId !== MessagesStore?.ThreadsStore.constants?.employee.id)[0].participantId;
                    const colleague = MessagesStore?.ThreadsStore.constants?.employees[colleagueId];
                    this._selectedDirect = {
                        id: res.conversation.id,
                        ...this._selectedDirect,
                        colleagueName: `${colleague.firstName} ${colleague.lastName}`,
                        colleagueDetails: {
                            id: colleague.id,
                            email: colleague.email,
                            phone: colleague.phone,
                            role: colleague.role,
                            status: colleague.status,
                            timeZone: colleague.timeZone,
                        },
                        participants: res.participants
                    };
                    this.messages = Object.values(res.messages.messages);
                    this.messagesTime = res.messages.time;
                    this.messagesTotal = res.messages.total;
                    this.messagesPage = res.messages.page;
                    this.messagesSize = res.messages.size;
                    this.hasMoreMessagesToLoad = res.messages.total > (res.messages.page * res.messages.size);

                    this.directLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.directLoading = LoadingState.Error;
                });
                return null;
            }
        )
    }

    @action getConversationMessages = () => {
        this.directLoading = LoadingState.Loading;
        return getMessages(this._selectedDirect.id, this.messagesPage + 1, this.messagesTime).then(
            (res) => {
                runInAction(() => {
                    this.messages = [...this.messages, ...Object.values(res.messages)];
                    this.messagesPage = res.page;
                    this.messagesTime = res.time;
                    this.messagesTotal = res.total;
                    this.messagesSize = res.size;
                    this.hasMoreMessagesToLoad = res.total > (res.page * res.size);
                    this.directLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.directLoading = LoadingState.Error;
                });
                return null;
            }
        )
    }

    @action closeConversation(): Promise<void> {
        if (!this._selectedDirect) {
            return;
        }
        setTimeout(() => {
            runInAction(() => {
                this._selectedDirect = null;
            });
        }, 10);
        return Promise.resolve();
    }

    @action startConversation = (participant: string) => {
        this.directLoading = LoadingState.Loading;
        return startDirectConversation(participant).then(
            (res) => {
                return runInAction(() => {
                    this._selectedDirect = res;
                    const newConversation: Conversation = {
                        conversationId: res.conversation.id,
                        conversationType: "DIRECT",
                        participants: res.participants,
                        lastupdate: new Date(),
                        lastread: new Date()
                    }
                    this.directLoading = LoadingState.Loaded;
                    return newConversation;
                    //this.conversations = [...this.conversations, newConversation]
                });
            },
            () => {
                runInAction(() => {
                    this.directLoading = LoadingState.Error;
                });
                return null;
            }
        );
    };

    @action sendMessage = (message: string, attachments: Attachment[]) => {
        this.fakeIdNo = this.fakeIdNo + 1;
        const fakeSendingMsg = {
            attachments: [],
            conversationId: this._selectedDirect.id,
            created: new Date().toISOString(),
            id: 'fake' + this.fakeIdNo,
            message: message,
            sentBy: MessagesStore.ThreadsStore.constants?.employee.id,
        };
        this.messages = [
            ...this.messages,
            fakeSendingMsg
        ];
        this.key += 1;

        return sendMessage({conversationId: this._selectedDirect.id, message, attachments}).then(
            (res) => {
                runInAction(() => {
                    if (!this.messages.find(el => el.id === res.id)) {
                        this.messages = [
                            ...this.messages,
                            res
                        ];
                        this.messages = this.messages.filter(el => el.message !== res.message || !el.id.startsWith('fake'));
                    }
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.onError('Something went wrong and your message hasn\'t been sent. Please try again later.');

                    const unsuccesfulSentMsg = {
                        ...this.messages.find(m => m.id === `fake${this.fakeIdNo}`),
                        unsuccessfulSending: true
                    };
                    this.messages = this.messages.filter(m => m.id !== `fake${this.fakeIdNo}`);
                    this.messages = [
                        ...this.messages,
                        unsuccesfulSentMsg
                    ];
                });
                return null;
            }
        );
    }

    @action editMessage = (updatedMsg) => {
        this.directLoading = LoadingState.Loading;
        return editMessage(updatedMsg.id, updatedMsg.message).then(
            () => {
                runInAction(() => {
                    const newMessagesList = this.messages.filter(el => el.id !== updatedMsg.id);
                    this.messages = [
                        ...newMessagesList,
                        updatedMsg
                    ];
                    this.directLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.directLoading = LoadingState.Error;
                });
                return null;
            }
        );
    }

    @action deleteMessage = (messageId: string, conversationId: string) => {
        this.directLoading = LoadingState.Loading;
        return deleteMessage(messageId, conversationId).then(
            () => {
                runInAction(() => {
                    this.messages = this.messages.filter(el => el.id !== messageId);
                    this.directLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.directLoading = LoadingState.Error;
                });
                return null;
            }
        );
    }
}

// export default new SelectedConversationStore(SocketInstance);
