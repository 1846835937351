import React, { useState } from 'react';
import { FormField } from '@src/components/FormField/FormField';
import classNames from 'classnames';
import { ReactComponent as Unlinked } from '@src/theme/icons/unlinked.svg';
import { ReactComponent as Linked } from '@src/theme/icons/linked.svg';
import { ReactComponent as Facebook } from '@src/theme/icons/fb.svg';
import { ReactComponent as G } from '@src/theme/icons/google.svg';

interface BasicProps {
    setView?: (view: string) => void;
    setSucceeded?: (success: string) => void;
}

interface SiteProps {
    siteId: string;
    site: number;
    siteName?: string;
}

interface LinksProps extends BasicProps {
    reviews?: boolean;
    linksReload?: () => void;
    addSite?(site: SiteProps): void;
    pages?: any;
    isGoogleConnected?: boolean;
    isFbConnected?: boolean;
}

export const Links: React.FC<LinksProps> = (props) => {
    return (
        <div>
            {props.reviews ? (
                <>
                    <h2>You have not linked any review sites yet</h2>
                    <p>
                        Linking multiple review sources give you a more accurate view of your
                        customerbase and more data to analyze.
                    </p>
                </>
            ) : (
                <>
                    <h3>Link Review Sites</h3>
                    <p>
                        Once a site is linked, Myopolis will automatically retrieve reviews from
                        that site, as well as allow you to send your customers there to write
                        feedback.
                    </p>
                </>
            )}
            <div className="Onboarding__Cards">
                <div onClick={() => props.setView('fb')} className="Onboarding__Card">
                    <div className="Onboarding__CardImage">
                        <Facebook />
                    </div>
                    <div className="Onboarding__CardLink">
                        <h4 className="Onboarding__CardTitle">Facebook</h4>
                        {props.isFbConnected ? (
                            <Linked className="svg" />
                        ) : (
                            <Unlinked className="svg" />
                        )}
                    </div>
                </div>

                <div onClick={() => props.setView('google')} className="Onboarding__Card">
                    <div className="Onboarding__CardImage">
                        <G />
                    </div>
                    <div className="Onboarding__CardLink">
                        <h4 className="Onboarding__CardTitle">Google</h4>
                        {props.isGoogleConnected ? (
                            <Linked className="svg" />
                        ) : (
                            <Unlinked className="svg" />
                        )}
                    </div>
                </div>

                {props.reviews && (
                    <div onClick={() => props.setView('other')} className="Onboarding__Card other">
                        <div className="Onboarding__CardLink">
                            <h4 className="Onboarding__CardTitle">Add Another</h4>
                            <Unlinked className="svg" />
                        </div>
                    </div>
                )}
            </div>
            {!props.reviews && (
                <button className="btn-secondary" onClick={() => props.setView('populate')}>
                    Back
                </button>
            )}
        </div>
    );
};

export const Other = (props) => {
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');

    return (
        <div>
            <h3>Link Other Review Site</h3>
            <p>
                Enter the name and full URL for your business review site. When review requests are
                sent to your customers, the &apos;Name&apos; will be displayed and &apos;URL&apos;
                is where they will be sent.
            </p>
            <p>NOTE: Reviews posted on this site will not be shown in Myopolis.</p>
            <FormField label="Name" htmlFor="name">
                <input
                    type="text"
                    name="name"
                    className={classNames('form-input')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </FormField>
            <FormField label="URL" htmlFor="url">
                <input
                    type="text"
                    name="url"
                    className={classNames('form-input')}
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
            </FormField>
            <div className="Onboarding__Buttons">
                <button className="btn-secondary" onClick={() => props.setView('links')}>
                    Cancel
                </button>
                <button
                    className="btn-primary"
                    onClick={() => props.addSite({ siteId: url, site: 3, siteName: name })}
                >
                    Connect
                </button>
            </div>
        </div>
    );
};
