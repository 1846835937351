import React, { useState } from 'react';
import { images } from '@src/theme/images';
import { Dialog } from '@src/components';
import { Pricing } from '@src/pages/Pricing/Pricing'
import { SingleBillingPlan } from '@src/stores/models';
import { displayIntegerOrTwoDecimalPlaces } from '@src/theme/utils/helpers';

import '../payment-inside-stripe.sass';

interface HeaderProps {
    isTrialPlanOnSignup: boolean;
    selectedPlan: SingleBillingPlan;
    onPlanChange: ({priceId: string, buyNow: boolean}) => void;
};

const AddPaymentOnSignupHeader: React.FunctionComponent<HeaderProps> = ({ isTrialPlanOnSignup, selectedPlan, onPlanChange }) => {
    const [ dialogOpen, setDialogOpen ] = useState(false);

    const handleOnPlanChange = (data: {priceId: string, buyNow: boolean}) => {
        onPlanChange(data);
        setDialogOpen(false);
    };

    return (
        <>
            <h2>Subscription</h2>
            {isTrialPlanOnSignup &&
                <p className="subtitle"><span>{selectedPlan?.billingPlan.trialDays}-day free trial</span>, cancel anytime. You will not be charged until after the trial ends.</p>
            }
            <div className="PaymentInsideStripe__planDescription">
                <div className="PaymentInsideStripe__planDescription__image">
                    <img src={images.planHouseImg} alt="house" />
                </div>
                <div className="PaymentInsideStripe__planDescription__text">
                    <div className="PaymentInsideStripe__planDescription__text-yourPlan">YOUR PLAN</div>
                    <div className="PaymentInsideStripe__planDescription__text-planName">{selectedPlan?.billingPlan.name}</div>
                    {isTrialPlanOnSignup ?
                        <div className="PaymentInsideStripe__planDescription__text-freeTrial">
                            After your free {selectedPlan?.billingPlan.trialDays}-day trial, pay as little as <b>${displayIntegerOrTwoDecimalPlaces(selectedPlan?.price.amount)}</b> per {selectedPlan?.price.interval.interval}
                        </div> :
                        <div className="PaymentInsideStripe__planDescription__text-noFreeTrial">
                            Pay as little as <b>${displayIntegerOrTwoDecimalPlaces(selectedPlan?.price.amount)}</b> per {selectedPlan?.price.interval.interval}
                        </div>
                    }
                    <button
                        onClick={() => setDialogOpen(true)}
                        className="PaymentInsideStripe__planDescription__text-change btn-tetriary-blue"
                    >
                        Change plan
                    </button>
                </div>
            </div>
            <Dialog
                onClose={() => setDialogOpen(false)}
                open={dialogOpen}
                className="Dialog-Pricing"
            >
                <Pricing
                    isSignup={true}
                    onPlanChange={handleOnPlanChange}
                />
            </Dialog>
        </>
    )
};

export default AddPaymentOnSignupHeader;
