import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { STORES, withStores, WithUserStoreProps, WithAuthStoreProps } from '@src/stores/with-store';
import { ReactComponent as LinkIcon } from '@src/theme/icons/link.svg';
import { ReactComponent as CloseIcon } from '@src/theme/icons/close.svg';
import { ReactComponent as SearchIcon } from '@src/theme/icons/search-inactive.svg';
import { ReactComponent as ClearIcon } from '@src/theme/icons/clear.svg';
import { useOutsideClick } from '@src/hooks';

import './support.sass';

const articlesForAll = [
    {title: 'How to send Messages', url: 'https://support.myopolis.com/article/1nmb1flk4y-messages'},
    {title: 'How to create and use Templates', url: 'https://support.myopolis.com/article/x5frj37k5c-templates'},
    {title: 'How to make an Outgoing Call', url: 'https://support.myopolis.com/article/sumru61aiv-making-calls'},
    {title: 'Manage Reviews', url: 'https://support.myopolis.com/article/q4cqwvihzx-what-reviews-can-i-manage-in-myopolis'},
    {title: 'Change Your Notifications', url: 'https://support.myopolis.com/article/5vng8ggvfm-change-my-notifications'},
    {title: 'How to use Tags', url: 'https://support.myopolis.com/article/ik36d68626-tags'},
    {title: 'Character Limits', url: 'https://support.myopolis.com/article/btfatc1641-what-are-my-character-limits'},
    {title: 'Create a Contact', url: 'https://support.myopolis.com/article/yel1rslmlq-create-a-contact-individually'},
];

const articlesForOwnerLeaderOnly = [
    {title: 'How to Add Team members', url: 'https://support.myopolis.com/article/pjnowy14ea-team'},
    {title: 'Billing and Invoices', url: 'https://support.myopolis.com/article/gpcmv9jfpa-billing'},
    {title: 'Customize your Settings', url: 'https://support.myopolis.com/article/vrmzp4a2tx-set-up-sms-number'},
    {title: 'How to setup Webchat', url: 'https://support.myopolis.com/article/hikaf6r44a-how-to-setup-webchat'},
];

type props = WithUserStoreProps & WithAuthStoreProps;

const SupportWithStore: React.FC<props> = (props) => {
    const lockedAccount = props.AuthStore.accountStatus === 'LOCKED';
    const articlesForCurrentUser = props.UserStore.user?.employee.role < 2 ? [...articlesForAll, ...articlesForOwnerLeaderOnly] : articlesForAll;
    const [articlesToDisplay, setArticlesToDisplay] = useState([]);
    const inputRef = useRef(null);
    const wrapperRef = useRef(null);
    const [hasFocus, setHasFocus] = useState(false);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        if (lockedAccount) {
            return;
        };
    }, []);

    useEffect(() => {
        setArticlesToDisplay(articlesForCurrentUser.sort(() => Math.random() - Math.random()).slice(0, 5));
    }, [props.UserStore.showSupportScreen]);

    useOutsideClick(wrapperRef, () => {
        setHasFocus(false);
    });

    const searchTextChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(e.target.value);

    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.keyCode === 27) {
            setSearchText('');
        } else if (e.keyCode === 13) {
            window.open('https://support.myopolis.com/search/' + encodeURI(searchText), "_blank");
        };
    };

    const clear = () => {
        setSearchText('');
        inputRef.current.focus();
    };

    const buttons = searchText ? (
        <>
            <button
                className={classNames('clear-btn', { 'show': !hasFocus })}
                onClick={(): void => clear()}
            >
                <ClearIcon />
            </button>
            <a
                className={classNames('go-btn', { 'show': hasFocus })}
                href={'https://support.myopolis.com/search/' + encodeURI(searchText)}
                rel="noopener noreferrer" target="_blank"
            >
                Go
            </a>
        </>
    ) : null;

    const closeSupport = () => {
        props.UserStore.setShowSupportScreen(false);
        setSearchText('');
    };

    return (
        <div className={classNames('Support', {
            'show': props.UserStore.showSupportScreen,
        })}>
            <div className="Support__header">
                <div className="Support__header-help">
                    <div className="title">Help</div>
                    <div
                        className="close"
                        onClick={() => closeSupport()}
                    >
                        <CloseIcon />
                    </div>
                </div>
                <div
                    className={classNames('Support__header-search', {
                        'filled-out': searchText || hasFocus,
                    })}
                    ref={wrapperRef}
                >
                    <SearchIcon />
                    <input
                        name="search"
                        type="text"
                        placeholder="Search"
                        value={searchText}
                        ref={inputRef}
                        onFocus={() => setHasFocus(true)}
                        onKeyDown={(e) => keyDown(e)}
                        onChange={(e) => searchTextChange(e)}
                        autoComplete="off"
                    />
                    {buttons}
                </div>
            </div>
            <div className="Support__content">
                <a
                    className="Support__content-quickstart"
                    href="https://support.myopolis.com/article/ycwoc1yt3h-quick-start-guide"
                    rel="noopener noreferrer" target="_blank"
                >
                    <div className="title">Quick start guide</div>
                    <div className="subtitle">Learn the basics about Myopolis</div>
                </a>
                <div className="Support__content-articles">
                    <div className="title">Recommended articles</div>
                    <div className="articles">
                        {articlesToDisplay.map((el, i) =>
                            <a
                                key={i}
                                className="item"
                                href={el.url}
                                rel="noopener noreferrer" target="_blank"
                            >
                                {el.title}
                            </a>
                        )}
                    </div>
                </div>
                <div className="Support__content-bottom">
                    <a
                        className="helpsection btn-tetriary-blue"
                        href="https://support.myopolis.com/"
                        rel="noopener noreferrer" target="_blank"
                    >
                        Visit Help Section
                        <LinkIcon />
                    </a>
                    <div className="contact">
                        <a href={`mailto:support@myopolis.com`} className="btn-primary">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Support = withStores(SupportWithStore, [STORES.User, STORES.Auth]);