import { MentionData } from "@draft-js-plugins/mention";
import { ParsedPrompts } from "@src/stores/models";
import { ContentState, convertFromRaw, convertToRaw, EditorState, RawDraftEntity } from "draft-js";

export interface BlockData {
    text?: string;
    entityRanges?: Draft.RawDraftEntityRange[];
}

export type EntityMap = Record<number, RawDraftEntity>;

export const utf8SafeSlice = function(str, start, end?) {
    return Array.from(str).slice(start, end).join('');
};

export const utf8SafeLength = (str: string): number =>{
    return Array.from(str).length
}

export const blockToString = (entityMap) => {
    return (block) => {
        if (!block.entityRanges.length) return block.text;
        const entityRanges = block.entityRanges.reverse();
        const replaced = entityRanges.reduce((text, range) => {
            const t = [
                utf8SafeSlice(text, 0, range.offset),
                entityMap[range.key]?.data.mention?.value,
                utf8SafeSlice(text, range.offset + range.length),
            ].join('');
            return (entityMap[range.key]?.data.mention?.value) ? t : text;
            // return t;
        }, block.text);
        return replaced;
    }
};


export const joinBlocksToString = (contentState: Draft.ContentState) => {
    const raw = convertToRaw(contentState);
    const blocks = raw.blocks;
    const entityMap = raw.entityMap;
    const replacedBlocks = blocks.map(blockToString(entityMap));
    const value = replacedBlocks.join('\n');
    return value;
};


export const getMessageWithPopulatedData = (mentions: ParsedPrompts, msg: string): string => {
    return mentions.reduce((acc, current) => {
        if (current.parsedValue) {
            return acc?.split(current.value).join(current.parsedValue);
        } else {
            return acc;
        }
    }, msg);
};

export const getBlockWithEntityRanges = (text: string, tags: MentionData[]): BlockData => {
    const ranges: { offset: number; length: number; key: number }[] = [];
    const regex = RegExp('(' + tags.map((t) => t.value).join('|') + ')', 'g');
    const splittedText = text.split(regex);
    const replaced = splittedText.reduce((r, fragment) => {
        if (regex.test(fragment)) {
            //it's a tag :variable:
            const tagI = tags.findIndex((t) => t.value == fragment);
            if (tagI > -1) {
                const tag = tags[tagI];
                ranges.push({
                    offset: utf8SafeLength(r),
                    length: utf8SafeLength(tag.name),
                    key: tagI,
                });
                return r.concat(tag.name);
            }
            return r.concat(fragment);
        }
        return r.concat(fragment);
    }, '');
    return {
        text: replaced,
        entityRanges: ranges,
    };
};


export const convertMessageToContentState = (
    message: string,
    mentions: ParsedPrompts
): Draft.ContentState => {
    const rawContent: Draft.RawDraftContentState = convertToRaw(
        ContentState.createFromText(message)
    );

    const entityMap: EntityMap = mentions.reduce((obj, mention, i) => {
        return {
            ...obj,
            [i]: {
                type: ':mention',
                mutability: 'IMMUTABLE',
                data: { mention }
            },
        };
    }, {});

    rawContent.blocks = rawContent.blocks.map((block) => {
        const result: BlockData = getBlockWithEntityRanges(block.text, mentions);
        return {
            ...block,
            ...result
        };
    });

    rawContent.entityMap = entityMap;

    const content = convertFromRaw(rawContent);
    return content;
};

export const convertInputToValue = (es: Draft.EditorState) => {
    const joined = joinBlocksToString(es.getCurrentContent());
    return joined;
};

export const initializeState = (message: string, mentions: ParsedPrompts): EditorState => {
    if (!message || !message.length) return EditorState.createEmpty();
    return EditorState.createWithContent(convertMessageToContentState(message, mentions));
};
