import React from 'react';
import './PageHeader.sass';

interface HeaderTitleProps {
    title: string;
}

const HeaderTitle: React.FunctionComponent<HeaderTitleProps> = ({ title }) => {
    return (
        <>
            <div className="PageHeader__title">
                <span>{title}</span>
            </div>
            {/* <button className="dropdown-button">
                <ArrowDownSvg/>
            </button> */}
        </>
    );
};

export default HeaderTitle;
