import React from 'react';
import './RecipientOption.sass';
import { RecipientOption as Option } from './BroadcastReceipientsLocalStore.store';
import { AsYouType } from 'libphonenumber-js';
import { ReactComponent as AddSvg } from '@src/theme/icons/add-green.svg';


interface NewRecipientOptionProps {
    label: string;
    onClick: () => void;
};

export const NewRecipientOption: React.FunctionComponent<NewRecipientOptionProps> = ({ label, onClick }) => {
    return (
        <div
            className="recipient-option new-recipient-option"
            onClick={onClick}
        >
            <AddSvg className=""/>
            <span>{label}</span>
        </div>
    );
};

interface RecipientOptionProps {
    icon?: React.ReactNode;
    option: Option;
    selected?: boolean;
};

export const RecipientOption: React.FunctionComponent<RecipientOptionProps> = ({ icon, option, selected }) => (
    <div className={`recipient-option ${selected ? 'recipient-option__selected' : ''}`}>
        {icon}
        <div className="title-container">
            <span>{option.label ?? option.phone ?? option.value ?? ''}</span>
            { option.label && option.phone && option.label.replace(/\D/g, '') !== option.phone &&
                <span className="phone">{new AsYouType('US').input(option.phone)}</span>
            }
        </div>
    </div>
);
