import React from 'react';

interface ErrorToastSaveProps {
    repeatUploading: () => void;
}
export const ErrorToastSave: React.FC<ErrorToastSaveProps> = (props) => {
    return (
        <div className="toast">
            <span>
                Your data has not been saved successfully. Please click the button below or try
                again later.
            </span>
            <button className="btn-primary" onClick={() => props.repeatUploading()}>
                Send again
            </button>
        </div>
    );
};
