import React from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingState } from '@src/stores/models';
import DashboardCard from '@src/pages/Dashboard/components/DashboardCard/DashboardCard';
import { STORES, WithDashboardStoreProps, withStores } from '@src/stores/with-store';
import {
    InfoCard,
    InfoCardProps,
} from '@src/pages/Dashboard/components/ContactsStats/InfoCard/InfoCard';

import { Metric } from '@src/components/Metric/Metric';
import '../../dashboard.sass';
import { ReactComponent as MessageIcon } from '@src/theme/icons/message-b.svg';
import { ReactComponent as AddContactIcon } from '@src/theme/icons/add-contact.svg';
import { EmptyContactsCard } from './EmptyContactsCard/EmptyContactsCard';
import { MetricsCard } from './MetricsCard/MetricsCard';
import { Loader } from '@src/components/Loader/Loader';

const ContactsStatsWithStore: React.FunctionComponent<WithDashboardStoreProps> = ({
    DashboardStore,
}) => {
    const history = useHistory();

    const messagesCards = [
        {
            icon: <MessageIcon />,
            title: 'Threads',
            metrics: [
                {
                    label: 'Assigned to me',
                    value: parseInt(DashboardStore.messagesInfo?.assigned, 10) || 0,
                },
                {
                    label: 'Unassigned',
                    value: parseInt(DashboardStore.messagesInfo?.unassigned, 10) || 0,
                },
            ],
        },
        {
            icon: <MessageIcon />,
            title: 'Message Segments',
            metrics: [
                {
                    label: 'Sent',
                    value: DashboardStore.totalMessageSegments || 0,
                },
            ],
        },
    ];

    const renderContactCard = (
        <div className="dashboard__row">
            <DashboardCard className="Dashboard__info-card">
                <div className="dashboard-card__inside">
                    <div className="dashboard-card__title">
                        <AddContactIcon />
                        <span>Total Contacts</span>
                    </div>
                    <div className="Dashboard__info-card__content">
                        {[
                            {
                                label: 'Recently Added',
                                value: DashboardStore.customersInTimePeriodCount || 0,
                            },
                            {
                                label: 'Total Contacts',
                                value: DashboardStore.customerCount || 0,
                            },
                        ].map((metric, i) => (
                            <Metric key={i + metric.label} toShow={metric.value}>
                                {metric.label}
                            </Metric>
                        ))}
                    </div>
                </div>
            </DashboardCard>
        </div>
    );

    const renderMessagesCards = (
        <div className="dashboard__row">
            {messagesCards.map((card, i) => (
                <MetricsCard
                    key={i + card.title}
                    icon={card.icon}
                    title={card.title}
                    metrics={card.metrics}
                />
            ))}
        </div>
    );

    const renderEmptyCard = (
        <EmptyContactsCard onClick={() => history.push({ pathname: `/customers/` })} />
    );

    const renderContactsStats =
        DashboardStore.loadingLeaderboard !== LoadingState.Loaded ||
        DashboardStore.customerCount > 0
            ? renderContactCard
            : renderEmptyCard;

    return (
        <div className="dashboard__stats-container">
            <Loader loadingState={DashboardStore.loadingLeaderboard} />
            {renderMessagesCards}
            {renderContactsStats}
        </div>
    );
};

export const ContactsStats = withStores(ContactsStatsWithStore, [STORES.Dashboard]);
