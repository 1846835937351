import { action, observable, runInAction, computed } from 'mobx';

import {
    getBroadcasts,
    createBroadcast,
    deleteBroadcast,
    editDetails,
} from '@src/requests/broadcasts.requests';
import { Broadcast, BroadcastStatus, CreateBroadcast, EditBroadcast } from '@src/stores/models/broadcast_new';
import { BaseStore } from '@src/stores/base.store';
import { LoadingState } from '@src/stores/models/loading-state';
import { SimpleCustomer } from './models/customer';
import { parseISO } from 'date-fns';
import WalkthroughStore from './walkthrough.store';
import { firstPopupsStepsCompletedElBeforeBEData } from '@src/theme/utils/constants';
import { CompletedSteps } from '@src/components/Layout/Walkthrough/WalkthroughMeta';

export const PAGE_SIZE = 30;


export class BroadcastsStore extends BaseStore {
    @observable listLoading: LoadingState = LoadingState.Init;
    @observable broadcastLoading: LoadingState = LoadingState.Init;
    @observable total = 0;
    @observable changeUrlToScheduled = 0;
    @observable hasMoreToLoad = true;
    @observable consentGranted = true;
    @observable list: Broadcast[] = [];
    @observable edition = {
        availableRecipientsLoading: LoadingState.Init,
        availableRecipients: {
            customers: [],
            tags: [],
            total: 0,
        },
        availableRecipientsQuery: '',
        availableRecipientsPage: 0,
        recipientsLoading: LoadingState.Init,
        recipients: {
            customers: [],
            tags: [],
            total: 0,
            totalMembers: 0
        },
    };
    @observable replied: SimpleCustomer[] = [];
    @observable unreplied: SimpleCustomer[] = [];
    // @observable detailsOpen = false;

    private _currentPage = 0;
    private _currentQuery: string = '';
    @observable currentStatus: BroadcastStatus = 'SCHEDULED';

    constructor() {
        super();
    };

    @action setList(val: Broadcast[]) {
        this.list = val;
    };

    // @action setDetailsOpen(val: boolean) {
    //     this.detailsOpen = val;
    // };

    @action setChangeUrlToScheduled(val: number) {
        this.changeUrlToScheduled = val;
    };

    @action init(status?: BroadcastStatus): void {
        this.listLoading = LoadingState.Init;
        this.broadcastLoading = LoadingState.Init;
        this.reset(status);
    };

    @action destroy(): void {
        this.listLoading = LoadingState.Init;
        this.broadcastLoading = LoadingState.Init;
        this.changeUrlToScheduled = 0;
        this.consentGranted = true;
        this.edition = {
            availableRecipientsLoading: LoadingState.Init,
            availableRecipients: {
                customers: [],
                tags: [],
                total: 0,
            },
            availableRecipientsQuery: '',
            availableRecipientsPage: 0,
            recipientsLoading: LoadingState.Init,
            recipients: {
                customers: [],
                tags: [],
                total: 0,
                totalMembers: 0
            },
        };
        this.replied = [];
        this.unreplied = [];
        this.list = [];
        this._currentPage = 0;
        this._currentQuery = '';
        this.total = 0;
        this.hasMoreToLoad = true;
        this.currentStatus = 'SCHEDULED';
        this.consentGranted = true;
        // this.detailsOpen = false;
    };

    @action reset(status?: BroadcastStatus): void {
        this.list = [];
        this._currentPage = 0;
        this._currentQuery = '';
        this.total = 0;
        this.hasMoreToLoad = true;
        this.currentStatus = status || 'SCHEDULED';
        this.consentGranted = true;
        // this.detailsOpen = false;
    };

    @action resetEdition(): void {
        this.edition = {
            availableRecipientsLoading: LoadingState.Init,
            availableRecipients: {
                customers: [],
                tags: [],
                total: 0,
            },
            availableRecipientsQuery: '',
            availableRecipientsPage: 0,
            recipientsLoading: LoadingState.Init,
            recipients: {
                customers: [],
                tags: [],
                total: 0,
                totalMembers: 0

            },

        };
    };

    onError: (message: string) => void;

    @action resetPage(): void {
        this._currentPage = 0;
    };

    @computed get currentPage(): number {
        return this._currentPage;
    };

    @action setSearchedGroupsPage(val: number) {
        this._currentPage = val;
    };

    @action setStatus(val: BroadcastStatus) {
        if (val !== this.currentStatus) {
            this.reset(val);
            this.resetEdition();
            this.getBroadcasts({});
        } else if(this.listLoading == LoadingState.Init) {
            this.getBroadcasts({})
        }
    };

    // sortResults = (a: Broadcast, b: Broadcast) => {
    //     if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
    //         return -1;
    //     };
    //     if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
    //         return 1;
    //     };
    //     return 0;
    // };

    @action getBroadcasts = ({status, query}: {status?: BroadcastStatus, query?: string}) => {
        if (['CREATE', 'EDIT'].includes(status)) {
            return;
        };
        this.listLoading = LoadingState.Loading;
        if (!!status && status !== this.currentStatus) {
            this.reset(status);
        };
        if(!!query && query !== this._currentQuery) {
            this.reset(status ?? this.currentStatus);
            this._currentQuery = query;
        };
        if (this.hasMoreToLoad && !['CREATE', 'EDIT'].includes(this.currentStatus)) {
            this._currentPage += 1;
            getBroadcasts(
                this._currentPage,
                PAGE_SIZE,
                this.currentStatus,
                this._currentQuery.length ? this._currentQuery : null
            ).then(
                (res) => {
                    runInAction(() => {
                        const newBroadcasts: Broadcast[] = res.data.data.map(b => ({
                            ...b,
                            created: parseISO(b.created),
                            scheduled: parseISO(b.scheduled),
                            updated: parseISO(b.updated)
                        }));
                        this.list = [...this.list, ...newBroadcasts];
                        this.total = res.data.total;
                        this.hasMoreToLoad = res.data.total > this.list.length;
                        this.listLoading = LoadingState.Loaded;
                    });
                },
                () => {
                    this.listLoading = LoadingState.Error;
                }
            );
        } else {
            this.listLoading = LoadingState.Loaded;
        };
    };

    // sortResultsByName = (a, b) => {
    //     const A = a.description ? a.description.toLowerCase() : a.firstName ? `${a.firstName?.toLowerCase()} ${a.lastName?.toLowerCase()}` : a.value ? a.value.toLowerCase() : a.phone;
    //     const B = b.description ? b.description.toLowerCase() : b.firstName ? `${b.firstName?.toLowerCase()} ${b.lastName?.toLowerCase()}` : b.value ? b.value.toLowerCase() : b.phone;
    //     if (A < B) {
    //         return -1;
    //     }
    //     if (A > B) {
    //         return 1;
    //     }
    //     return 0;
    // };

    @action checkConsent = () => {
        if (WalkthroughStore.popupsStepsCompleted[0] !== firstPopupsStepsCompletedElBeforeBEData && WalkthroughStore.popupsStepsCompleted.includes(CompletedSteps.broadcastsConsent)) {
            this.consentGranted = true;
        } else if (WalkthroughStore.popupsStepsCompleted[0] !== firstPopupsStepsCompletedElBeforeBEData && !WalkthroughStore.popupsStepsCompleted.includes(CompletedSteps.broadcastsConsent)) {
            this.consentGranted = false;
        } else if (WalkthroughStore.popupsStepsCompleted[0] === firstPopupsStepsCompletedElBeforeBEData) {
            WalkthroughStore.getAndSetHintPopupSteps()
            .then(() => {
                runInAction(() => {
                    this.consentGranted = WalkthroughStore.popupsStepsCompleted.includes(CompletedSteps.broadcastsConsent);
                });
            });
        };
    };

    @action grantConsent = () => {
        this.broadcastLoading = LoadingState.Loading;
        WalkthroughStore.markHintPopupStepAsRead(CompletedSteps.broadcastsConsent)
        .finally(() => {
            runInAction(() => {
                this.consentGranted = true;
                WalkthroughStore.setPopupStepsCompleted([...WalkthroughStore.popupsStepsCompleted, CompletedSteps.broadcastsConsent])
                this.broadcastLoading = LoadingState.Loaded;
            });
        });
    };

    @action createBroadcast = (broadcast: CreateBroadcast): Promise<void> => {
        if (!this.consentGranted) {
            return;
        };
        this.broadcastLoading = LoadingState.Loading;
        return createBroadcast(broadcast.name, broadcast.message, broadcast.recipients, broadcast.schedule, broadcast.attachments)
            .then(() => {
                this.broadcastLoading = LoadingState.Loaded;
                if (broadcast.schedule) {
                    this.changeUrlToScheduled +=1
                };
            })
            .catch((err) => {
                this.broadcastLoading = LoadingState.Error;
                this.onError(err);
            });
    };

    @action editBroadcast = (broadcast: EditBroadcast): Promise<void> => {
        if (!this.consentGranted) {
            return;
        };
        this.broadcastLoading = LoadingState.Loading;
        const uriAttachments = broadcast.attachments.map(attachment => ({...attachment, uri: attachment.uri ?? attachment.url}));
        return editDetails(broadcast.id, broadcast.name, broadcast.message, broadcast.schedule || new Date(), uriAttachments)
            .then(() => {
                this.broadcastLoading = LoadingState.Loaded;
                if (broadcast.schedule) {
                    this.changeUrlToScheduled +=1
                };
            })
            .catch((err) => {
                this.broadcastLoading = LoadingState.Error;
                this.onError(err);
            });

    };

    @action deleteBroadcast = (id: string): Promise<void> => {
        this.broadcastLoading = LoadingState.Loading;
        return deleteBroadcast(id)
            .then(() => {
                this.setList(this.list.filter(broadcast => broadcast.id !== id));
                this.broadcastLoading = LoadingState.Loaded;
            })
            .catch((err) => {
                this.broadcastLoading = LoadingState.Error;
                this.onError(err);
            });
    };
};

export default new BroadcastsStore();
