import api from '@src/api/api';
import { SignupForm, UpdateAccountForm } from '@src/stores/models/signup';

export const getAuth = () => {
    return api.get('/index');
};

export const getUser = () => {
    return api.get('/signup/user');
};

export const postAccountDetailsWithGoogle = (data: SignupForm) => {
    return api.post('/signupForExternalAuth', data);
};

export const googleCreateAccount = (code) => {
    return api.get('/authenticate/google', { params: { code: code } });
};

export const createAccount = (data: SignupForm) => {
    return api.post('/signup', data);
};

export const updateAccount = (data: UpdateAccountForm) => {
    return api.post('/signup/update', data);
};

// export const getPlan = () => {
//     return api.get('/signup/plan');
// };

export const getPlans = () => {
    return api.get('/billing/activePlans');
};

export const validateEmail = (email: string, paramsAsObject?: object) => {
    return api.post('/signup/email', {
        ...(paramsAsObject || {}),
        email
    });
};

export const setBillingForm = (billingForm) => {
    return api.post('/signup/plan', billingForm);
};

export const getNumbers = (codes) => {
    return api.get('/smssettings/phonesearch', { params: codes });
};

export const setAndBuyPhoneNumber = (phoneNumber) => {
    // to be used when CC is already set, buys number from twilio
    return api.post('/smssettings/phoneselect', { phoneNumber });
};

export const setPhoneNumber = (smsNumber) => {
    // only sets number till the moment when credit card is provided - then phone purchase is handled by /signup/finalize
    return api.post('/signup/phone', { smsNumber });
};

export const finalize = (data) => {
    return api.post('/signup/finalize', data);
};

export const isPhoneNumberSet = () => {
    return api.get('/smssettings').then((res) => Boolean(res.data.smsSettings.phone));
};

export const smsVerification = (method: 'POST' | 'GET', urlParams: Record<string, any>): Promise<any> => {
    const params = Object.entries(urlParams).map(([key, val]) => `${key}=${val}`).join('&');
    const url = `signup/smsVerification?${params}`;
    if (method === 'GET') {
        return api.get(url)
    }
    return api.post(url)
}

