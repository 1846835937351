import { observable, action, runInAction } from 'mobx';
import {
    getWebchatData,
    saveWebchatData,
} from '@src/requests/webchat.requests';
import AuthStore from '@src/stores/auth.store';
import { WebchatInfo } from '@src/stores/models';

export class WebchatStore {
    @observable welcomeMessage = null;
    @observable ownLogoUrl = null;

    @action init(): void {
        this.welcomeMessage = null;
        this.ownLogoUrl = null;
    };

    @action getWebchatData(): Promise<void> {
        return getWebchatData().then(
            (res: WebchatInfo) => {
                runInAction(() => {
                    this.welcomeMessage = res.welcomeMessage;
                    this.ownLogoUrl = res.ownLogoUrl;
                });
            },
            (err) => {
                if (err.response.status === 401) {
                    AuthStore.resetAuth();
                };
            }
        );
    };

    @action saveWebchatData(data: WebchatInfo): Promise<void> {
        return saveWebchatData(data)
        .then((res: WebchatInfo) => {
            runInAction(() => {
                this.welcomeMessage = res.welcomeMessage;
                this.ownLogoUrl = res.ownLogoUrl;
            });
        });
    };
}

export default new WebchatStore();
