import React from 'react';
import { PROMPTS } from '@src/stores/models/templates';

export const stringToTextWithPrompts = (text: string): React.ReactNode => {
    const splitted = text.split(/[:]/);
    const result = splitted.map((str, i) => {
        const prompt = PROMPTS.find(prompt => prompt.value === `:${str}:`);
        let style = {}
        let fragment = str;
        if (prompt) {
            fragment = prompt.name;
            style = {
                padding: '0px 8px',
                color: 'white',
                backgroundColor: prompt.color,
                borderRadius: '10px',
                display: 'inline-flex',
                alignItems: 'center',
                fontSize: '11px',
                fontWeight: '500',
                whiteSpace: 'nowrap',
                height: '16px'
            };
        }
        return <span key={i + str} style={style}>{fragment}</span>
    })
    return <>{result}</>
};
