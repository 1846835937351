import React from 'react';

interface SingleMessageProps {
    textToSplit: string;
    withoutWrapper?: boolean;
}

export const SingleMessage: React.FC<SingleMessageProps> = (props) => {
    const charactersAtBeginningOrEndNotBeingPartOfLink = [',', '.', '-', '(', ')', '{', '}', '[', ']'];
    const regexUrl = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const msg = props.textToSplit.split('\n').map((line: string, i: number) => {
        if (line && line.length && line.match(regexUrl)) {
            return line.split(' ').map((word, i) => {
                if (word.match(regexUrl)) {

                    const cleanedFromCharactrsNotBeingPartOfLink = word
                    .split('')
                    .reduce((prevValue, curValue) => !prevValue.length && charactersAtBeginningOrEndNotBeingPartOfLink.indexOf(curValue) > -1 ? [] : [...prevValue, curValue], [])
                    .reverse()
                    .reduce((prevValue, curValue) => !prevValue.length && charactersAtBeginningOrEndNotBeingPartOfLink.indexOf(curValue) > -1 ? [] : [...prevValue, curValue], [])
                    .reverse()
                    .join('');

                    if (cleanedFromCharactrsNotBeingPartOfLink.length === word.length) {
                        const link = word.startsWith("http://") || word.startsWith("https://") ? word : `//${word}`;
                        return <a href={link} rel="noopener noreferrer" target="_blank" key={word + i}>{word}</a>;
                    } else {
                        const link = cleanedFromCharactrsNotBeingPartOfLink.startsWith("http://") || cleanedFromCharactrsNotBeingPartOfLink.startsWith("https://") ? cleanedFromCharactrsNotBeingPartOfLink : `//${cleanedFromCharactrsNotBeingPartOfLink}`;
                        return <a href={link} rel="noopener noreferrer" target="_blank" key={word + i}>{word}</a>;
                    }
                } else {
                    return <>{word}</>;
                }
            })
            .reduce((acc, current, idx, array) => {
                if (idx < array.length - 1) {
                    return <>{acc} {current}</>;
                } else {
                    return <p key={i}>{acc} {current}</p>;
                };
            });
        } else {
            return <p key={i}>{line.length ? line : <br></br>}</p>;
        }
    });

    return (
        <>
            {props.withoutWrapper ?
                <div key='singleMessage'>
                    <div className="message-inside" key="singleMessage-inside">
                       {msg}
                    </div>
                </div>
            :
                <div key='singleMessage' className="message-text">
                    <div className="message-inside" key="singleMessage-inside">
                        {msg}
                    </div>
                </div>
            }
        </>
    )
};
