import React from 'react';
import FirstMessagePng from './first_message.png';

import DashboardCard from '../../DashboardCard/DashboardCard';
import './EmptyMessagesCard.sass';

interface EmptyMessagesCardProps {
    onClick: () => void
}

export const EmptyMessagesCard: React.FunctionComponent<EmptyMessagesCardProps> = ({ onClick }) => {
    return (
        <DashboardCard className="dashboard__messages-card-empty">
            <img alt="first message" src={FirstMessagePng}/>
            <h3>Send your first message</h3>
            <p>Let's send your first text message... To yourself. You'll see how easy it is to be able to respond to your customers right away.</p>
            <button
                className="btn-primary-full"
                type="button"
                onClick={onClick}
            >
                Let's Do This
            </button>
        </DashboardCard>
    );
};
