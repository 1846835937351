import React, { useEffect, useRef, useState, useCallback } from 'react';
import classNames from 'classnames';
import Draft, { EditorState, DraftHandleValue, Modifier } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import '@draft-js-plugins/mention/lib/plugin.css';
import createMentionPlugin, { defaultTheme, MentionData, MentionPluginConfig, MentionSuggestions } from '@draft-js-plugins/mention';
import { ParsedPrompts } from '@src/stores/models';
import ReactTooltip from 'react-tooltip';

import './variable-input.sass';

import {ErrorBoundary} from 'react-error-boundary'
import { observer } from 'mobx-react';
import { DraftEditorLocalStore } from './DraftEditorLocalStore';
import { convertInputToValue, getMessageWithPopulatedData, initializeState } from './draftjsEditorUtils';

interface VariableInputProps {
    draftEditorLocalStore: DraftEditorLocalStore;
    onChange: (value: string) => void;
    message: string;
    notes?: boolean;
    templatesBarVisible?: boolean;
    activateVariablesView: string;
    prompts: ParsedPrompts;
    usedTemplate?: string;
    send?: (message: string) => void;
    focus?: boolean;
    hideLettersCounter?: boolean;
    hideSendSaveButton?: boolean;
    openMentions?: boolean;
    onMentionOpenChange?: (open: boolean) => void;
}

const Mention = ({ className, mention, children }) => {
    return (
        <span
            className={className}
            style={{
                background: mention.color,
            }}
        >
            {children}
        </span>
    );
};

const SuggestionItem = (props) => {
    const {
        mention,
        searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
        isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
        ...parentProps
    } = props;

    return (
        <div {...parentProps}>
            <div>{mention.suggestionName}</div>
        </div>
    );
};

const MentionSuggestionsComponent = (props) => {
    const {
        ...otherProps
    } = props
    return <MentionSuggestions {...otherProps} entryComponent={SuggestionItem} />
}

const mentionTriggerChar = ':';

const mentionPluginConfig: MentionPluginConfig = {
    mentionTrigger: mentionTriggerChar,
    supportWhitespace: true,
    entityMutability: 'IMMUTABLE',
    theme: {
        ...defaultTheme,
        mention: 'VariableInput__tag',
        mentionSuggestions: classNames(
            defaultTheme.mentionSuggestions,
            'VariableInput__mentionSuggestions'
        ),
    },
    positionSuggestions: (settings) => {
        return {
            ...settings,
            left: settings.decoratorRect.left + 'px',
            top: settings.decoratorRect.top + 'px',
            position: 'fixed',
            display: 'block',
            transform: 'scale(1)',
            transformOrigin: '1em 0% 0px',
            transition: 'all 0.25s',
        };
    },
    mentionComponent: Mention,
    mentionSuggestionsComponent: MentionSuggestionsComponent
};

const mp = createMentionPlugin(mentionPluginConfig);

const charactersNumberInfo = 'Please notice, the exact message length<br />will depend on customer\'s name.<br />If the message gets longer than 1600 characters,<br />it will be cut after 1600th character.';


export const VariableInput = observer((props: VariableInputProps) => {
    const draftEditorLocalStore = props.draftEditorLocalStore;
    const { editorState, messageJustExceeded1600, setMessageJustExceeded1600 } = draftEditorLocalStore
    const setEditorState = (es: EditorState, mentions?) => props.draftEditorLocalStore.setEditorState(es, mentions);

    const [{ mentionPlugin, MentionSuggestions }] = useState(() => {
        const { MentionSuggestions } = mp;
        const mentionPlugin = [mp];
        return {
            mentionPlugin,
            MentionSuggestions
        };
    });

    const mentions = props.prompts.map((p, i) => ({
        ...p,
        id: i,
        name: p.parsedValue || p.name,
        suggestionName: p.name + (p.parsedValue ? ` (${p.parsedValue})` : '')
    }));
    const [usedPromptsWithoutPopulatedData, setUsedPromptsWithoutPopulatedData] = useState([]);
    const [firstLoading, setFirstLoading] = useState<boolean>(true);
    const [messageBeingSent, setMessageBeingSent] = useState<boolean>(false);

    const ref = useRef(null);

    const setEditorStateInDraftEditorLocalStore = () => {
        props.draftEditorLocalStore.setEditorState(initializeState(props.message, mentions), mentions);
    }

    useEffect(() => {
        setEditorStateInDraftEditorLocalStore();
    }, []);

    useEffect(() => {
        props.onChange(props.draftEditorLocalStore.result);
    }, [props.draftEditorLocalStore.result]);

    const [suggestions, setSuggestions] = useState<MentionData[]>(mentions);
    const [open, setOpen] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);
    const [populatedMessage, setPopulatedMessage] = useState('');

    const focus = () => {
        if (ref && ref.current) {
            ref.current.focus();
            setFocused(true);
        }
    };

    const handleBeforeInput = (
        chars: string,
        es: EditorState,
        // eventTimeStamp: number
    ): DraftHandleValue => {
        const mappedValue = getMessageWithPopulatedData(mentions, convertInputToValue(es));
        if (mappedValue.length < 1600) {
            return 'not-handled';
        } else {
            setMessageJustExceeded1600(true);
            setTimeout(() => setMessageJustExceeded1600(false), 200);
            return 'handled'; // 'not-handled' wywołuje onChange, 'handled' - nope
        }
    };

    const handlePastedText = (
        currentText: string,
        html: string,
        es: EditorState
    ): DraftHandleValue => {
        const previousText = getMessageWithPopulatedData(mentions, convertInputToValue(es));
        const carretPositionStart = es.getSelection()['_map']._root.entries.find((el) => el[0] === 'anchorOffset')[1];
        const carretPositionEnd = es.getSelection()['_map']._root.entries.find((el) => el[0] === 'focusOffset')[1];
        const replacedTextLength = carretPositionEnd - carretPositionStart;
        /// MOVE INSIDE DRAFT EDITOR LOACL STORE
        if (previousText.length + currentText.length - replacedTextLength < 1601) {
            return 'not-handled';
        } else {
            const howManyCharactersLeft = 1600 - previousText.length + replacedTextLength;
            const shortenCurrentText = currentText.slice(0, howManyCharactersLeft);
            const firstPartPreviousText = previousText.slice(0, carretPositionStart);
            const secondPartPreviousText = previousText.slice(carretPositionEnd);
            /// MOVE INSIDE DRAFT EDITOR LOACL STORE
            const finalString = firstPartPreviousText + shortenCurrentText + secondPartPreviousText;
            const state = initializeState(finalString, mentions);
            setEditorState(state);
            /// MOVE INSIDE DRAFT EDITOR LOACL STORE

            draftEditorLocalStore.setResult(finalString);
            setMessageJustExceeded1600(true);
            setTimeout(() => setMessageJustExceeded1600(false), 200);
            return 'handled';
        }
    };

    const onChange = (es: Draft.EditorState) => {
        const textWithVars = convertInputToValue(es)
        const mappedValue = getMessageWithPopulatedData(mentions, textWithVars);

        //STOP ANYTHING IF ITS NOT CONTENT CHANGE
        if (editorState?.getCurrentContent() === es.getCurrentContent()) {
            setEditorState(es)
            return;
        }

        if (mappedValue.length <= 1600) {
            draftEditorLocalStore.update(es);
            /// MOVE INSIDE DRAFT EDITOR LOACL STORE

            draftEditorLocalStore.setResult(mappedValue);
        } else {
            const shorten = mappedValue.slice(0, 1600);
            const newEs = initializeState(shorten, mentions);
            setEditorState(newEs);
            /// MOVE INSIDE DRAFT EDITOR LOACL STORE
            draftEditorLocalStore.setResult(shorten);
            setMessageJustExceeded1600(true);
            setTimeout(() => setMessageJustExceeded1600(false), 200);
        }
    };

    useEffect(() => {
        if (props.focus) focus();
    }, [props.focus]);

    useEffect(() => {
        if (props.usedTemplate) {
            const state = initializeState(props.usedTemplate, mentions);
            onChange(state);
        }
    }, [props.usedTemplate]);

    useEffect(() => {
        setMessageBeingSent(false);
        // if (props.message === undefined || props.message === null) {
        //     console.log('returning')
        //     return;
        // };
        setPopulatedMessage(getMessageWithPopulatedData(mentions, props.message));

        if (!firstLoading && !props.message?.length) {
            setTimeout(() => {
                const state = initializeState(props.message, mentions);
                onChange(state);
                focus(); // without this typing new message after deleting previous one works wrong
            }, 10);
        } else if (firstLoading && !props.message?.length) {
            setFirstLoading(false);
            setTimeout(() => {
                const state = initializeState(props.message, mentions);
                onChange(state);
            }, 10);
        }

        if (props.activateVariablesView === 'viewBroadcast') {
            setUsedPromptsWithoutPopulatedData(mentions
                .filter(el => el.value === ':full_name:' || el.value === ':first_name:' || el.value === ':last_name:')
                .map(el => [el.value, el.suggestionName])
                .reduce((prev, cur) => prev.concat(cur), [])
                .filter(el => props.message.includes(el))
            );
        }
    }, [props.message]);

    useEffect(() => {
        if (props.openMentions && !open) {
            const newContentState = Modifier.insertText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                mentionTriggerChar,
            );

            const newEditorState = EditorState.push(
                editorState,
                newContentState,
                'insert-fragment',
            );

            setEditorState(newEditorState);

            setTimeout(() => {
                ref.current && ref.current.focus();
            }, 200);
        };
    }, [props.openMentions]);
    // }, [draftEditorLocalStore.activePicker]);

    const handleOpenChange = (_open: boolean) => {
        setOpen(_open);
        props.onMentionOpenChange && props.onMentionOpenChange(_open);
    };

    function editorKeyBindingFn(e) {
        if (e.key === 'Escape') {
          setOpen(false);
        }
        return Draft.getDefaultKeyBinding(e)
    }

    const onSearchChange = ({ value }) => {
        const filtered = value.length
            ? mentions
                  .filter(
                      (el) =>
                          el.parsedValue ||
                          el.value === ':review_invitation:' ||
                          props.activateVariablesView === 'viewBroadcast'
                  )
                  .filter((p) => {
                      const a =
                          p.value.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                          p.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
                      return a;
                  })
            : mentions.filter(
                  (el) =>
                      el.parsedValue ||
                      el.value === ':review_invitation:' ||
                      props.activateVariablesView === 'viewBroadcast'
              );
        setSuggestions(filtered);
    };

    const sendMessage = useCallback(() => {
        setMessageBeingSent(true);
        props.send(populatedMessage);
        // const newState = EditorState.push(
        //     editorState,
        //     ContentState.createFromText(''),
        //     'remove-range'
        // );
        // setEditorState(newState);
    }, [props.message, editorState]);

    return editorState && (
        <>
            <div className={classNames('VariableInput', { focused })} onClick={focus}>
                <ErrorBoundary fallbackRender={({
                    // error,
                    resetErrorBoundary
                }) => {
                    console.log("DRAFT.JS ERROR")
                    resetErrorBoundary();
                    return <></>;
                }}>
                    <Editor
                        editorState={editorState}
                        handleBeforeInput={handleBeforeInput}
                        handlePastedText={handlePastedText}
                        keyBindingFn={editorKeyBindingFn}
                        onChange={onChange}
                        plugins={props.activateVariablesView === 'false' ? [] : mentionPlugin}
                        ref={ref}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                    />
                </ErrorBoundary>

                <MentionSuggestions
                    open={open}
                    onSearchChange={onSearchChange}
                    suggestions={suggestions}
                    onOpenChange={handleOpenChange}
                />

            </div>
            {!props.hideLettersCounter && props.activateVariablesView !== 'false' && (
                <div
                    className={classNames('lettersCounter', {
                        exceeded160: populatedMessage?.length > 160,
                        exceeded1599: messageJustExceeded1600,
                    })}
                >
                    {populatedMessage?.length}/160
                    {!!usedPromptsWithoutPopulatedData?.length &&
                        <>
                            <ReactTooltip multiline={true} />
                            <div className="info" data-tip={charactersNumberInfo}>i</div>
                        </>
                    }
                </div>
            )}
            {!props.hideSendSaveButton &&
                <button
                    className={classNames('transparent', { disabled: !props.message || messageBeingSent })}
                    disabled={!props.message || messageBeingSent}
                    onClick={sendMessage}
                >
                    {props.notes
                        ? 'Save'
                        : props.activateVariablesView === 'viewNewContact'
                        ? 'Send & Create'
                        : 'Send'}
                </button>
            }
        </>
    );
});
