import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Input } from 'reactstrap';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { onSuccess } from '@src/theme/utils/constants';
import { CheckboxField, FormField, Loader, ErrorToastSave, Dialog } from '@src/components';
import { AsYouType } from 'libphonenumber-js';
import { useLoading } from '@src/hooks';
import { STORES, withStores, WithThreadsStoreProps } from '@src/stores/with-store';
import { CustomerStore } from '@src/stores/customer.store';
import { re } from '@src/theme/utils/constants';
import { Customer } from '@src/stores/models';
import TagsStore from '@src/stores/tags/tags.store';
import AuthStore from '@src/stores/auth.store';
import { TagsChannelsEdition } from '@src/pages/Messages/components/Edition/TagsChannelsEdition';
import { AddTagDialog } from '../AddTag/AddTag';

import '../customer-common.sass';

type CustomerEditProps = { CustomerStore: CustomerStore } & WithThreadsStoreProps;

const CustomerEditWithStore: React.FC<CustomerEditProps> = (props) => {
    const lockedAccount = AuthStore.accountStatus === 'LOCKED';
    const customerStore = props.CustomerStore;
    const threadsStore = props.ThreadsStore;
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const params = useRouteMatch().params as {id: string};
    const [customerId, setCustomerId] = useState(params.id);
    const [customer, setCustomer] = useState(customerStore?.customerData?.customer);
    const loading = useLoading(customerStore.loading);
    const [newCustomerData, setNewCustomerData] = useState(
        customer ? Object.assign({}, customer) : null
    );
    const [dataUpdated, setDataUpdated] = useState(false);
    const loaded = !!customerStore?.customerData;
    const onError = (message) => toast.error(message);
    const [notes, setNotes] = useState(null);
    const [newNote, setNewNote] = useState('');
    const newNoteRef = useRef(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [openCreation, setOpenCreation] = useState(false);
    const [openEdition, setOpenEdition] = useState(false);
    const [addTagDialogOpen, setAddTagDialogOpen] = useState(false);

    customerStore.onError = (type: string, data?) => {
        switch (type) {
            case 'postNote': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() => customerStore.postNote(customerId, data.text, type)}
                    />
                );
                break;
            }
            case 'updateNote': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            customerStore.updateNote(data.noteId, data.text, customerId, type)
                        }
                    />
                );
                break;
            }
            case 'deleteNote': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            customerStore.deleteNote(data.noteId, customerId, type)
                        }
                    />
                );
                break;
            }
            default: {
                return;
            }
        }
    };
    customerStore.onErrorGeneral = (message) => {
        toast.error(message);
    };
    customerStore.onSuccess = () => {
        onSuccess();
    };

    const callInit = (cid) => {
        customerStore.init();
        customerStore.initData(cid, onError, !lockedAccount);
    };

    useEffect(() => {
        const cid = params.id;
        if (cid) {
            setCustomerId(cid);
            callInit(cid);
            setDataUpdated(true);
        }
    }, []);

    useEffect(() => {
        setCustomer(customerStore?.customerData?.customer);
        setNewCustomerData(
            customerStore?.customerData?.customer
                ? Object.assign({}, customerStore?.customerData?.customer)
                : null
        );
    }, [customerStore?.customerData?.customer]);

    useEffect(() => {
        if (customer?.notes?.length) {
            setNotes(
                customer?.notes.slice().sort(
                    (e1, e2) => new Date(e1.created).getTime() - new Date(e2.created).getTime()
                )
            );
        } else {
            setNotes([]);
        }
    }, [customer?.notes?.length]);

    const submit = (formData: Customer) => {
        if (!customerId) {
            return;
        }

        customerStore.updateCustomer(customerId, formData, onError).then(() => {
            //  history.push(`/customers/${customerId}`)
            toast.success('Customer updated!', { autoClose: 4000 });
        });
    };

    if (loaded && (!customerId || !customer)) {
        return (
            <div className="Customer error card">
                <NavLink to={'/customers?c'}>
                    <button className="btn-tetriary">&lt; Back to Customers List</button>
                </NavLink>
                <h2>Error loading customer with id - {`${customerId}`}</h2>
            </div>
        );
    }

    const updateNote = (noteId: string, noteText: string) => {
        if (!noteText) {
            customerStore.deleteNote(noteId, customerId, 'deleteNote');
        } else {
            customerStore.updateNote(noteId, noteText, customerId, 'updateNote');
        }
    };

    const postNote = () => {
        customerStore.postNote(customerId, newNote, 'postNote').then(() => {
            setNewNote('');
            newNoteRef.current.value = '';
        });
    };

    const onConfirmDeleteUser = () => {
        setDialogOpen(false);
        customerStore.removeCustomer(customerId, onError).then(() => {
            history.push('/customers?c');
        });
    };

    const createNewTag = () => {
        TagsStore.EditedTagStore.newTag(customer);
        setOpenCreation(true);
    };

    const closeEdition = () => {
        // TagsStore.EditedTagStore.unselectAllNonMembers();
        setOpenCreation(false);
        setOpenEdition(false);
        customerStore.fetchCustomer(customerId, onError);
    };

    const closeAddTagDialog = () => {
        setAddTagDialogOpen(false);
        customerStore.fetchCustomer(customerId, onError);
    };

    const openEditTagDialog = (tag) => {
        TagsStore.EditedTagStore.setTagEdition(tag);
        setOpenEdition(true);
    };

    const addTags = () => {
        setAddTagDialogOpen(true);
    };

    const removeTag = (cid, tagId) => {
        TagsStore.EditedTagStore.removeMember(cid, tagId, true);
    };

    const viewMessage = () => {
        if (customerStore.customerData?.threadId) history.push(`/messages/t/${customerStore.customerData?.threadId}`);
        else {
            threadsStore.createNewThread(customerStore.customerData.customer, true)
            .then(() => history.replace({ pathname: `/messages/?new` }));
        }
    };

    return (
        <div className="Customer CustomerEdit">
            <Loader loadingState={loading} />
            {loaded && dataUpdated && (
                <div className="customer-container">
                    <div className="customer-data">
                        <div className="card">
                            <div className="back">
                                <div className="card-title edit">
                                    <NavLink to={`/customers?c`}>
                                        <button className="btn-tetriary">
                                            <span>&lt; Back to Customer List</span>
                                        </button>
                                    </NavLink>
                                    <div className="actions">
                                        <button
                                            className="btn-primary"
                                            onClick={() => viewMessage()}
                                        >
                                            View messages
                                        </button>
                                        <button
                                            className="btn-secondary"
                                            onClick={() => setDialogOpen(true)}
                                        >
                                            <span>Delete</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit(submit)}>
                                <div className="card-title">
                                    <h3>Edit Customer</h3>
                                </div>
                                <div className="data">
                                    <div className="data-editable">
                                        <div className="row twoElements">
                                            <FormField
                                                required={true}
                                                label="First Name"
                                                htmlFor="firstName"
                                                error={errors.firstName}
                                            >
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    placeholder="Required"
                                                    className={classNames('form-input', {
                                                        error: errors.firstName,
                                                    })}
                                                    ref={register({
                                                        required: 'Missing First Name',
                                                    })}
                                                    defaultValue={newCustomerData?.firstName}
                                                />
                                            </FormField>
                                            <FormField
                                                required={true}
                                                label="Last Name"
                                                htmlFor="lastName"
                                                error={errors.lastName}
                                            >
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    placeholder="Required"
                                                    className={classNames('form-input', {
                                                        error: errors.lastName,
                                                    })}
                                                    ref={register({
                                                        required: 'Missing Last Name',
                                                    })}
                                                    defaultValue={newCustomerData?.lastName}
                                                />
                                            </FormField>
                                        </div>
                                        <div className="row twoElements">
                                            <FormField
                                                required={true}
                                                label="Phone Number"
                                                htmlFor="phoneNumber"
                                                error={errors.phoneNumber}
                                            >
                                                <Input
                                                    type="text"
                                                    mask="(999)-999-9999"
                                                    maskChar="_"
                                                    name="phone"
                                                    placeholder="Required"
                                                    className={classNames('form-input', {
                                                        error: errors.phoneNumber,
                                                    })}
                                                    inputRef={register({
                                                        required: 'Missing Phone Number',
                                                    })}
                                                    tag={InputMask}
                                                    defaultValue={newCustomerData?.phone}
                                                />
                                            </FormField>
                                            <FormField
                                                label="Email"
                                                htmlFor="email"
                                                error={errors.email}
                                            >
                                                <input
                                                    type="text"
                                                    name="email"
                                                    placeholder="Optional"
                                                    className={classNames('form-input', {
                                                        error: errors.email,
                                                    })}
                                                    ref={register({
                                                        pattern: {
                                                            value: re,
                                                            message: 'Invalid email',
                                                        },
                                                    })}
                                                    defaultValue={newCustomerData?.email}
                                                />
                                            </FormField>
                                        </div>
                                        <div className="row twoElements">
                                            <FormField
                                                label="Company"
                                                htmlFor="company"
                                                error={errors.company}
                                            >
                                                <input
                                                    type="text"
                                                    name="company"
                                                    placeholder="Optional"
                                                    className={classNames('form-input', {
                                                        error: errors.company,
                                                    })}
                                                    defaultValue={newCustomerData?.company}
                                                />
                                            </FormField>
                                            <div className="checkbox-form-field">
                                                <CheckboxField
                                                    label="Do not send Review Requests"
                                                    defaultValue={newCustomerData?.disallowReviews}
                                                    name="disallowReviews"
                                                    ref={register()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="updateChanges">
                                    <button className="btn-primary" type="submit">
                                        Update
                                    </button>
                                </div>
                            </form>
                            <div className="notesEdition">
                                <div className="row oneElement">
                                    <div className="notesTitle">Notes</div>
                                    {notes?.map((el) => (
                                        <form key={el.id} className="noteEdition">
                                            <FormField>
                                                <input
                                                    type="text"
                                                    name="text"
                                                    className={classNames('form-input')}
                                                    defaultValue={el.text}
                                                    onBlur={(e) => (el.text = e.target.value)}
                                                />
                                            </FormField>
                                            <div
                                                className="btn-primary"
                                                onClick={() => updateNote(el.id, el.text)}
                                            >
                                                Update
                                            </div>
                                            <div
                                                className="btn-secondary"
                                                onClick={() =>
                                                    customerStore.deleteNote(
                                                        el.id,
                                                        customerId,
                                                        'deleteNote'
                                                    )
                                                }
                                            >
                                                Delete
                                            </div>
                                        </form>
                                    ))}
                                    <div className="noteEdition">
                                        <FormField>
                                            <input
                                                type="text"
                                                name="text"
                                                className={classNames('form-input')}
                                                placeholder="Type new note here"
                                                ref={newNoteRef}
                                                onChange={(e) => setNewNote(e.target.value)}
                                            />
                                        </FormField>
                                        <button
                                            disabled={Boolean(!newNote)}
                                            className="btn-primary addNew"
                                            onClick={postNote}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="tagsEdition">
                                <FormField
                                    label="Tags"
                                    htmlFor="tags"
                                    classStyling="customer-tags"
                                >
                                    {!customerStore.customerData?.tags?.length ? (
                                        <p>No tags assigned</p>
                                    ) : (
                                        <div className="tags">
                                            {customerStore.customerData?.tags?.map((tag) => (
                                                <div key={tag.id} className="tag">
                                                    <span
                                                        className="title"
                                                        title="Manage"
                                                        onClick={() =>
                                                            openEditTagDialog(tag)
                                                        }
                                                    >
                                                        {tag.value}
                                                    </span>
                                                    <span
                                                        className="remove"
                                                        title="Remove"
                                                        onClick={() =>
                                                            removeTag(customerId, tag.id)
                                                        }
                                                    >
                                                        x
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <button
                                        className="btn-primary"
                                        onClick={() => addTags()}
                                    >
                                        <span>Assign a Tag</span>
                                    </button>
                                    <button
                                        className="btn-primary"
                                        onClick={() => createNewTag()}
                                    >
                                        <span>Create New Tag</span>
                                    </button>
                                </FormField>
                            </div>
                        </div>
                    </div>
                    <div className="customer-messages card">
                        <div className="card-title">
                            <h3>Messages</h3>
                            {!customerStore?.customerData?.threadId ? null : (
                                <NavLink to={`/messages/t/${customerStore?.customerData?.threadId}`}>
                                    <button className="btn-primary">View</button>
                                </NavLink>
                            )}
                        </div>
                        {/* <CustomerChat
                            slim={true}
                            // customer={customer}
                            employeesMap={{}}
                            // messages={messages?.messages}
                            // voiceMail={messages?.voiceMail}
                            // closings={messages?.closings}
                            // assignments={messages?.assigned}
                            // notes={messages?.notes}
                        /> */}
                    </div>
                </div>
            )}
            <Dialog
                open={dialogOpen}
                title={`Delete ${
                    customer?.firstName ||
                    new AsYouType('US').input(('' + customer?.phone).replace(/\D/g, ''))
                } ${customer?.lastName || ''}`}
                onClose={() => setDialogOpen(false)}
            >
                <div className="CustomerDeleteDialog">
                    <p>Are you sure you want to delete this customer?</p>
                    <p>You will not be able to undo this action.</p>
                    <div className="btns-container">
                        <button className="btn-secondary" onClick={() => setDialogOpen(false)}>
                            Cancel
                        </button>
                        <button className="btn-primary" onClick={() => onConfirmDeleteUser()}>
                            Delete
                        </button>
                    </div>
                </div>
            </Dialog>

            <Dialog
                className="LeftMenu__dialog"
                open={openEdition || openCreation}
                onClose={() => closeEdition()}
            >
                <TagsChannelsEdition
                    type="Tag"
                    isNew={openCreation ? true : false}
                    customerId={customerId}
                    onDelete={closeEdition}
                />
            </Dialog>

            <Dialog
                className="LeftMenu__dialog CustomerDeleteDialog"
                open={addTagDialogOpen}
                title=""
                onClose={() => closeAddTagDialog()}
            >
                <AddTagDialog customerId={customerId} closeDialog={() => closeAddTagDialog()} />
            </Dialog>
        </div>
    );
};

export const CustomerEdit = withStores(CustomerEditWithStore, [STORES.Customer, STORES.ThreadsStore]);
