import { action, observable, computed, runInAction } from 'mobx';
import {
    addTag,
    searchTagMembers,
    searchTagNonMembers,
    addMember,
    deleteMember,
    updateTag,
    deleteTag,
} from '@src/requests/tags.requests';
import { Customer, LoadingState, Tag } from '@src/stores/models';
import { concat } from 'ramda';
import { BaseStore } from '@src/stores/base.store';
import MessagesStore from '@src/stores/messages/messages.store';
import CustomerStore from '@src/stores/customer.store';

const PAGE_SIZE = 20;

export default class EditedTagStore extends BaseStore {
    @observable TagsStore;
    @observable editedTag: Tag = null;

    @observable currentMembers = [];
    @observable nonMembers = [];

    @observable totalCurrentMembers?: number = null;
    @observable nonMembersPage = 0;
    @observable currentMembersPage = 0;
    @observable hasMoreNonMembersToLoad = false;
    @observable hasMoreCurrentMembersToLoad = false;

    @observable tagLoading: LoadingState = LoadingState.Init;
    @observable errorStatus = null;

    @observable searchQueryCurrentMembers?: string = null;
    @observable searchQueryNonMembers?: string = null;
    private reqCurrentMembersWithPage = 0;
    private reqNonMembersWithPage = 0;

    private customersOfTagChanged = false;


    onError: (message: string) => void;

    constructor(TagsStore) {
        super();
        this.TagsStore = TagsStore;
    }

    init(): void {
        this.errorStatus = null;
        this.currentMembers = [];
        this.nonMembers = [];
        this.editedTag = null;
        this.tagLoading = LoadingState.Init;
        this.customersOfTagChanged = false;
        this.totalCurrentMembers = null;
        this.nonMembersPage = 0;
        this.currentMembersPage = 0;
        this.hasMoreNonMembersToLoad = false;
        this.hasMoreCurrentMembersToLoad = false;
        this.searchQueryCurrentMembers = null;
        this.searchQueryNonMembers = null;
        this.reqCurrentMembersWithPage = 0;
        this.reqNonMembersWithPage = 0;
    }

    @action partialInit(): void {
        this.currentMembersPage = 0;
        this.nonMembersPage = 0;
        this.searchQueryCurrentMembers = null;
        this.searchQueryNonMembers = null;
        this.reqCurrentMembersWithPage = 0;
        this.reqNonMembersWithPage = 0;
    }




    // @action toggleNonMember = ( ///////////// do not remove!
    //     generalMember: Customer,
    //     forceSelection?: boolean
    // ): Customer[] | number => {
    //     const index = this.chosenNonMembers.findIndex((cm) => cm?.id === generalMember?.id);
    //     const isSelected = index > -1;
    //     if (isSelected && forceSelection === false) {
    //         return (this.chosenNonMembers = this.chosenNonMembers.filter(
    //             (cm) => cm?.id !== generalMember?.id
    //         ));
    //     } else if (isSelected && forceSelection === true) {
    //         return;
    //     } else if (!isSelected && forceSelection === true) {
    //         return this.chosenNonMembers.push(generalMember);
    //     } else if (!isSelected && forceSelection === false) {
    //         return;
    //     } else if (isSelected && forceSelection === undefined) {
    //         return (this.chosenNonMembers = this.chosenNonMembers.filter(
    //             (cm) => cm?.id !== generalMember?.id
    //         ));
    //     } else {
    //         return this.chosenNonMembers.push(generalMember);
    //     }
    // };

    // @action selectAllNonMembers = () => { ///////////// do not remove!
    //     if (this.tagLoading != LoadingState.Loaded) return;
    //     this.chosenNonMembers = [...this.editedTag.nonMembers];
    // };

    // @action unselectAllNonMembers = () => { ///////////// do not remove!
    //     if (this.tagLoading != LoadingState.Loaded) return;
    //     this.chosenNonMembers = [];
    // };

    @action newTag(customerToStartWith?: Customer) {
        this.editedTag = {
            value: '',
            id: 'new',
            locationId: 'location',
        };
        if (customerToStartWith) {
            this.TagsStore.TagsListStore.setSelectedTagsIds([customerToStartWith?.id]);
        }
        this.tagLoading = LoadingState.Loaded;
    }

    sortResultsByName = (a, b) => {
        if (a.value?.toLowerCase() < b.value?.toLowerCase()) {
            return -1;
        }
        if (a.value?.toLowerCase() > b.value?.toLowerCase()) {
            return 1;
        }
        return 0;
    };

    sortResultsByDate = (a, b) => {
        const A = a.updated ? a.updated : a.created;
        const B = b.updated ? b.updated : b.created;
        if (A > B) {
            return -1;
        }
        if (A < B) {
            return 1;
        }
        return 0;
    };

    @action createTag(name: string) {
        this.tagLoading = LoadingState.Loading;
        return addTag(name, [])
            .then((res) =>
                runInAction(() => {
                    this.tagLoading = LoadingState.Loaded;
                    this.TagsStore.TagsListStore._tagsList.unshift(res.data);
                    this.TagsStore.TagsListStore.setTagsList(
                        this.TagsStore.TagsListStore._tagsList.sort(
                            this.TagsStore.TagsListStore.displayTagsListAsOpen ?
                            this.sortResultsByName :
                            this.sortResultsByDate
                        )
                    );
                    this.setTagEdition(res.data);
                    return res.data;
                })
            )
            .catch((err) => {
                console.error(err);
                this.tagLoading = LoadingState.Error;
                this.onError(err.message);
            });
    }

    @action setEditionDetails(tag) {
        this.editedTag = {
            id: tag?.id,
            value: tag?.value,
            locationId: tag?.locationId
        };
        this.currentMembers = tag?.currentMembers;
        this.nonMembers = tag?.nonMembers;
        this.nonMembersPage = tag?.nonMembersPage;
        this.currentMembersPage = tag?.currentMembersPage;
        this.searchQueryCurrentMembers = tag?.searchQueryCurrentMembers;
        this.searchQueryNonMembers = tag?.searchQueryNonMembers;
        this.hasMoreCurrentMembersToLoad = tag?.hasMoreCurrentMembersToLoad;
        this.hasMoreNonMembersToLoad = tag?.hasMoreNonMembersToLoad;
    }

    @action setTagEdition = ({ id, value, locationId }): Promise<void> => {
        const fetchedTag = {
            id: id,
            value: value,
            locationId: locationId,
            currentMembers: [],
            nonMembers: [],
            nonMembersPage: 0,
            currentMembersPage: 0,
            searchQueryCurrentMembers: null,
            searchQueryNonMembers: null,
            hasMoreNonMembersToLoad: true,
            hasMoreCurrentMembersToLoad: true,
        };
        this.setEditionDetails(fetchedTag);
        return Promise.resolve();
    };

    @action closeTagEdition = () => {
        this.editedTag = null;
        if (this.customersOfTagChanged && this.TagsStore.TagsListStore.selectedTagsIds.length) {
            MessagesStore._threadsStore.searchThreads({
                tags: this.TagsStore.TagsListStore.selectedTagsIds,
            });
        }
        this.customersOfTagChanged = false;
    };

    @action changeName(name: string) {
        this.tagLoading = LoadingState.Loading;

        return updateTag(this.editedTag.id, name).then(
            () => {
                this.editedTag.value = name;
                this.TagsStore.TagsListStore.setTagsList(
                    this.TagsStore.TagsListStore._tagsList
                        .map((b) => (b?.id === this.editedTag?.id ? { ...b, value: name, created: new Date().toISOString() } : b))
                        .sort(
                            this.TagsStore.TagsListStore.displayTagsListAsOpen ?
                            this.sortResultsByName :
                            this.sortResultsByDate
                        )
                );
                // if (customerId) {
                //     CustomerStore.fetchCustomer(customerId).then(
                //         () => {
                //             this.tagLoading = LoadingState.Loaded;
                //         },
                //         (err) =>
                //             runInAction(() => {
                //                 this.onError(err.message);
                //                 this.tagLoading = LoadingState.Error;
                //             })
                //     );
                // }
                this.tagLoading = LoadingState.Loaded;
            },
            (err) =>
                runInAction(() => {
                    this.tagLoading = LoadingState.Error;
                    this.onError(err.message);
                })
        );
    }

    // @action addMembers(): Promise<any> { ///////////// do not remove!
    //     this.tagLoading = LoadingState.Loading;

    //     const membersRequests = this.chosenNonMembers.map((member) => {
    //         return addMember(this.editedTag.id, member.id).then();
    //     });
    //     return Promise.all(membersRequests).then(
    //         () =>
    //             runInAction(() => {
    //                 this.tagLoading = LoadingState.Loaded;
    //                 // this.chosenNonMembers = [];
    //                 this.setTagEdition(this.editedTag);
    //                 const openTags = this.TagsStore.TagsListStore.selectedTagsIds.filter(
    //                     (el) => el === this.editedTag?.id
    //                 );
    //                 if (openTags.length) {
    //                     this.customersOfTagChanged = true;
    //                 }
    //             }),
    //         (err) =>
    //             runInAction(() => {
    //                 this.tagLoading = LoadingState.Error;
    //                 this.onError(err.message);
    //             })
    //     );
    // }

    @action addMember = (customerId: string, tagId = this.editedTag.id) => {
        this.tagLoading = LoadingState.Loading;
        return addMember(tagId, customerId)
        .then(() => {
            runInAction(() => {
                const openTags = this.TagsStore.TagsListStore.selectedTagsIds.filter(
                    (el) => el === this.editedTag?.id
                );
                if (openTags.length) {
                    this.customersOfTagChanged = true;
                } else if (tagId !== this.editedTag?.id) {
                    // CustomerStore.fetchCustomer(customerId);
                }
                this.tagLoading = LoadingState.Loaded;
            })
        },
        (err) =>
            runInAction(() => {
                this.onError(err.message);
                this.tagLoading = LoadingState.Error;
            })
        );
    };

    @action removeMember = (cid: string, tagId = this.editedTag.id, reloadCustomer?) => {
        this.tagLoading = LoadingState.Loading;

        return deleteMember(tagId, cid).then(
            () => {
                if (tagId === this.editedTag?.id && this.TagsStore.TagsListStore.selectedTagsIds.find((t) => t === tagId)) {
                    MessagesStore.ThreadsStore.setSearchResults(
                        MessagesStore.ThreadsStore.searchResults.filter(
                            (el) => el.customer?.id !== cid
                        )
                    );
                } else if (reloadCustomer) {
                    CustomerStore.fetchCustomer(cid);
                }
                this.tagLoading = LoadingState.Loaded;
            },
            (err) =>
                runInAction(() => {
                    this.onError(err.message);
                    this.tagLoading = LoadingState.Error;
                })
        );
    };

    @action deleteTag() {
        this.tagLoading = LoadingState.Loading;

        return deleteTag(this.editedTag.id).then(
            () =>
                runInAction(() => {
                    this.TagsStore.TagsListStore.setTagsList(
                        this.TagsStore.TagsListStore._tagsList.filter(
                            ({ id }) => id != this.editedTag.id
                        )
                    );
                    const openTags = this.TagsStore.TagsListStore.selectedTagsIds.filter(
                        (el) => el === this.editedTag?.id
                    );
                    if (openTags.length) {
                        this.TagsStore.TagsListStore.setSelectedTagsIds([]);
                        MessagesStore._threadsStore.searchThreads({
                            tags: this.TagsStore.TagsListStore.selectedTagsIds,
                        });
                    }
                    // if (customerId) {
                    //     CustomerStore.fetchCustomer(customerId).then(
                    //         () =>
                    //             runInAction(() => {
                    //                 this.tagLoading = LoadingState.Loaded;
                    //                 this.closeTagEdition();
                    //             }),
                    //         (err) =>
                    //             runInAction(() => {
                    //                 this.onError(err.message);
                    //                 this.tagLoading = LoadingState.Error;
                    //             })
                    //     );
                    // } else {
                        this.closeTagEdition();
                        this.tagLoading = LoadingState.Loaded;
                    // }
                }),
            (err) =>
                runInAction(() => {
                    this.onError(err.message);
                    this.tagLoading = LoadingState.Error;
                })
        );
    }

    loadMoreMembers(requestFunc, tagId, page, pageSize, searchQuery) {
        this.tagLoading = LoadingState.Loading;
        return requestFunc(tagId, page, pageSize, searchQuery)
        .then((response) => {
            this.tagLoading = LoadingState.Loaded;
            return {
                newItems: response.data.customers,
                total: response.data.total,
            };
        });
    }

    @action loadMoreCurrentMembersForEdition = (query: string): Promise<void> => {
        if (!this.editedTag) return;
        if (this.searchQueryCurrentMembers != query) {
            this.currentMembersPage = 0;
            this.searchQueryCurrentMembers = query;
            this.currentMembers = [];
            this.reqCurrentMembersWithPage = 0;
        }
        this.currentMembersPage += 1;
        if (this.reqCurrentMembersWithPage === this.currentMembersPage) return;
        return this.loadMoreMembers(
            searchTagMembers,
            this.editedTag.id,
            this.currentMembersPage,
            PAGE_SIZE,
            this.searchQueryCurrentMembers
        ).then(
            ({ newItems, total }) =>
                runInAction(() => {
                    if (this.currentMembersPage === 1) {
                        this.currentMembers = newItems.sort(this.sortResultsByName);
                    } else {
                        this.currentMembers = concat(this.currentMembers, newItems).sort(this.sortResultsByName);
                    }
                    this.hasMoreCurrentMembersToLoad = Boolean(total > this.currentMembers.length && newItems.length);
                    this.totalCurrentMembers = total;
                    this.reqCurrentMembersWithPage = this.currentMembersPage;
                }),
            (err) =>
                runInAction(() => {
                    this.onError(err.message);
                    this.tagLoading = LoadingState.Error;
                })
        );
    };

    @action loadMoreNonMembersForEdition = (query: string): Promise<void> => {
        if (!this.editedTag) return;
        if (this.searchQueryNonMembers != query) {
            this.nonMembersPage = 0;
            this.searchQueryNonMembers = query;
            this.nonMembers = [];
            this.reqNonMembersWithPage = 0;
        }
        this.nonMembersPage += 1;
        if (this.reqNonMembersWithPage === this.nonMembersPage) return;
        return this.loadMoreMembers(
            searchTagNonMembers,
            this.editedTag.id,
            this.nonMembersPage,
            PAGE_SIZE,
            this.searchQueryNonMembers
        ).then(
            ({ newItems, total }) =>
                runInAction(() => {
                    if (this.nonMembersPage === 1) {
                        this.nonMembers = newItems.sort(this.sortResultsByName);
                    } else {
                        this.nonMembers = concat(this.nonMembers, newItems).sort(this.sortResultsByName);
                    }
                    this.hasMoreNonMembersToLoad = Boolean(total > this.nonMembers.length && newItems.length);
                    this.reqNonMembersWithPage = this.nonMembersPage;
                }),
            (err) =>
                runInAction(() => {
                    this.onError(err.message);
                    this.tagLoading = LoadingState.Error;
                })
        );
    };
}
