import api from '@src/api/api';
import { Attachment } from '@src/stores/models';

export const getTemplates = () => {
    return api.get('/settings/templates');
};

export const getDefaultTemplate = () => {
    return api.get('/template');
};

export const getTemplate = (id: string) => {
    return api.get(`/template/${id}`);
};

export const postTemplate = (
    title: string,
    message: string,
    attachments: Attachment[] = [],
    id: string
) => {
    return api.post('/template', { title, message, attachments, id });
};

export const deleteTemplate = (id: string) => {
    return api.delete(`/template/${id}`);
};

export const templatesApi = {
    getTemplates,
    getDefaultTemplate,
    getTemplate,
    postTemplate,
    deleteTemplate,
};
