import React from 'react';
import ReviewsImg from './reviews.png';

import DashboardCard from '../../DashboardCard/DashboardCard';
import { ReactComponent as GoogleIcon } from '@src/theme/icons/google.svg';
import { ReactComponent as FbIcon } from '@src/theme/icons/fb.svg';

import './EmptyReviewsCard.sass';

interface EmptyReviewsCardProps {
    onClick: () => void
}

export const EmptyReviewsCard: React.FunctionComponent<EmptyReviewsCardProps> = ({ onClick }) => {
    return (
        <DashboardCard className="dashboard__reviews-card-empty">
            <h3>Get more Reviews and Respond Quickly</h3>
            <p>Your number of positive reviews matter and increase your search rankings.</p>
            <div className="dashboard__reviews-card-empty__images-container">
                <div className="left">
                    <GoogleIcon className="first-icon"/>
                    <FbIcon className="last-icon"/>
                </div>
                <img alt="reviews stats" src={ReviewsImg}/>
                <div className="right">

                </div>
            </div>
            <p>Responding to negative reviews right away often turns the review positive or deletes the bad review</p>
            <button
                className="btn-primary-full"
                type="button"
                onClick={onClick}
            >
                Connect Now
            </button>
        </DashboardCard>
    );
};
