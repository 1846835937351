import React from 'react';
import {
    NavLink,
    Redirect,
    Route,
    Switch,
    useRouteMatch,
    useLocation,
    useHistory,
} from 'react-router-dom';
import { userSettingsPages } from '@src/pages/pages';
import { Dropdown } from '@src/components';
import UserStore from '@src/stores/user.store';

import './user-settings.sass';

const LINKS = ['/personal-info', '/password', '/notifications'];

export const UserSettings: React.FC = () => {
    const { path } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    const current = LINKS.find((l) => location.pathname.startsWith('/user-settings' + l));
    const userSettingsPagesBasedOnAuth = UserStore.user.employeeFromExternalAuth ? userSettingsPages.filter(el => el.label !== 'password') : userSettingsPages;
    const pagesMobile = userSettingsPagesBasedOnAuth.map((p) => {
        return { label: p.label[0].toUpperCase() + p.label.slice(1), value: p.path };
    });

    const changePage = (i: number) => {
        history.push('/user-settings' + pagesMobile[i].value);
    };

    return (
        <div className="UserSettings">
            <div className="UserSettings__nav">
                {userSettingsPagesBasedOnAuth.map((page) => (
                    <NavLink key={page.label} to={`${path}${page.path}`} activeClassName="active">
                        {page.label}
                    </NavLink>
                ))}
            </div>

            {current && (
                <div className="UserSettings__navMobile">
                    <Dropdown
                        optionsArr={pagesMobile.map((el) => el.label)}
                        selOptionNumber={pagesMobile.findIndex((p) => p.value === current)}
                        darkColor={true}
                        optionSelected={(i) => changePage(i)}
                    />
                </div>
            )}

            <div className="UserSettings__content card">
                <Switch>
                    {userSettingsPagesBasedOnAuth.map((page) => (
                        <Route
                            key={page.path}
                            path={`${path}${page.path}`}
                            component={page.component}
                        />
                    ))}
                    <Redirect from={`${path}`} exact to={`${path}/personal-info`} />
                </Switch>
            </div>
        </div>
    );
};
