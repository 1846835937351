import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import './customers.sass';
import { customersPages } from '@src/pages';

export const Customers = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            {customersPages.map((page) => (
                <Route
                    key={page.path}
                    exact={!!page.exact}
                    path={`${path}${page.path}`}
                    component={page.component}
                />
            ))}
        </Switch>
    );
};
