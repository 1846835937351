import { action, observable, runInAction } from 'mobx';
import { LoadingState, Employee, ConvertedLeaderboardItem } from './models';
import { getLeaderboard } from '@src/requests';
import AuthStore from '@src/stores/auth.store';

export class LeaderboardStore {
    @observable leaderboard = [];
    @observable loadingState: LoadingState = LoadingState.Init;
    private _onError: (type: string, days: number) => void;

    set onError(fn: (type: string, days: number) => void) {
        this._onError = fn;
    }

    @action init(): void {
        this.leaderboard = [];
        this.loadingState = LoadingState.Init;
    }

    @action setLeaderboard(leaderboard: []): void {
        this.leaderboard = leaderboard;
    }

    @action getLeaderboard(days: number, errAction: string, firstLoading = false) {
        this.loadingState = firstLoading ? LoadingState.FirstLoading : LoadingState.Loading;
        return getLeaderboard(days).then(
            (res) => {
                runInAction(() => {
                    this.leaderboard = res.data.employees
                        .map((el: Employee) => {
                            if (res.data.stats[el.id]) {
                                return {
                                    name:
                                        res.data.employee.id == el.id
                                            ? `${el.firstName} ${el.lastName} (You)`
                                            : `${el.firstName} ${el.lastName}`,
                                    conversations: res.data.stats[el.id].conversations,
                                    credited: res.data.stats[el.id].credited,
                                    sent: res.data.stats[el.id].sent,
                                    responses: res.data.stats[el.id].responses,
                                    avgResponseTime: res.data.stats[el.id].avgResponseTime,
                                    rating: res.data.stats[el.id].rating,
                                };
                            }
                        })
                        .sort((a: ConvertedLeaderboardItem, b: ConvertedLeaderboardItem) =>
                            a.rating < b.rating || !a.rating ? 1 : -1
                        )
                        .filter((el: ConvertedLeaderboardItem) => el);

                    this.loadingState = LoadingState.Loaded;
                });
            },
            (err) => {
                if (err.response.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    if (this._onError) {
                        this._onError(errAction, days);
                    }
                };
            }
        );
    }
}

export default new LeaderboardStore();
