import React, { useEffect, useLayoutEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { onSuccess } from '@src/theme/utils/constants';
import { withPersonalSettingsStore, WithPersonalSettingsStoreProps } from '@src/stores/with-store';
import { LoadingState, OnErrorDataPersonalSettings } from '@src/stores/models';
import { toUpperCaseFirst } from '@src/theme/utils/helpers';
import { CheckboxField, ErrorToastDownload, ErrorToastSave, Loader } from '@src/components';
import { ReactComponent as StarFullIcon } from '@src/theme/icons/star-full-big.svg';
import { ReactComponent as StarEmptyIcon } from '@src/theme/icons/star-empty-big.svg';
import UserStore from '@src/stores/user.store';

import './notifications.sass';

export const NotificationsSetWithStore: React.FC<WithPersonalSettingsStoreProps> = (
    props: WithPersonalSettingsStoreProps
) => {
    const { register, handleSubmit } = useForm();
    const store = props.PersonalSettingsStore;
    const userId = UserStore.user.employee?.id;

    const notifyMessages = store.notifyMessages;
    const notifyConversation = store.notifyConversation;
    const notifyReviews = store.notifyReviews;
    const forwardToBrowser = store.notifications?.forwardToBrowser;
    const forwardToMobile = store.notifications?.forwardToMobile;
    const loading = store.loadingState;
    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const [email, setEmail] = useState(notifyReviews?.email);
    const [stars, setStars] = useState({
        oneStar: notifyReviews?.oneStar,
        twoStar: notifyReviews?.twoStar,
        threeStar: notifyReviews?.threeStar,
        fourStar: notifyReviews?.fourStar,
        fiveStar: notifyReviews?.fiveStar,
    });
    const [notificationsPermission, setNotificationsPermission] = useState(Notification.permission);

    store.onError = (type: string, data?: OnErrorDataPersonalSettings) => {
        store.loadingState = LoadingState.Error;
        switch (type) {
            case 'getNotifications': {
                toast.error(
                    <ErrorToastDownload
                        getBEData={() => store.getAndSetNotifications(userId, 'getNotifications')}
                    />
                );
                break;
            }
            case 'setNotifications': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.updateNotifications(
                                data.employeeId,
                                data.notifications,
                                'setNotifications'
                            )
                        }
                    />
                );
                break;
            }
            default: {
                return;
            }
        }
    };

    store.onSuccess = () => {
        store.loadingState = LoadingState.Loaded;
        onSuccess();
    };

    const getAndSetNotifications = () => {
        store.getAndSetNotifications(userId, 'getNotifications', isFirstLoading);
        setIsFirstLoading(false);
    };

    const updateUserNotifications = (data: any) => {
        store.setNotificationsData({ ...data, ...stars });
        store.updateNotifications(userId, store.notifications, 'setNotifications');
    };

    useEffect(() => {
        getAndSetNotifications();
    }, []);

    useEffect(() => {
        setStars({
            oneStar: notifyReviews?.oneStar,
            twoStar: notifyReviews?.twoStar,
            threeStar: notifyReviews?.threeStar,
            fourStar: notifyReviews?.fourStar,
            fiveStar: notifyReviews?.fiveStar,
        });
        setEmail(notifyReviews?.email);
    }, [
        notifyReviews?.oneStar,
        notifyReviews?.twoStar,
        notifyReviews?.threeStar,
        notifyReviews?.fourStar,
        notifyReviews?.fiveStar,
        notifyReviews?.email,
    ]);

    useLayoutEffect(() => {
        store.init();
    }, []);

    const changeEmail = (d) => {
        if (d.target.name === 'review-email') setEmail(!email);
    };

    const changePermission = () => {
        Notification.requestPermission()
        .then(permission => {
            setNotificationsPermission(permission);
        });
    }

    const browserNotificationsSettings = useMemo(() => {
        return (
            <div className="row browserNotifications">
                You have {
                    notificationsPermission === "granted" ? 'enabled' :
                    notificationsPermission === "denied" ? 'disabled' :
                    'not enabled yet'} notifications for this site in this browser.
                {notificationsPermission === "default" ?
                    <button
                        className="btn-primary"
                        onClick={() => changePermission()}
                    >
                        Enable
                    </button> :
                    ' You can change it in your browser settings.'
                }
        </div>)
    }, [notificationsPermission]);

    return (
        <div className="NotificationsSet">
            <Loader loadingState={loading} />
            <>
                <>
                    <div className="NotificationsSet__title">Notification Settings</div>
                    <div className="NotificationsSet__section NotificationsSet__browserNotifications">
                        <div className="row header">
                            <div className="cell th">Browser notifications</div>
                            <div className="cell center"></div>
                            <div className="cell center"></div>
                            <div className="cell center"></div>
                        </div>
                        {browserNotificationsSettings}
                    </div>
                    {store.dataLoadedNotifications && (
                        <form
                            onSubmit={handleSubmit(updateUserNotifications)}
                            onChange={(d) => changeEmail(d)}
                        >
                            <div className="NotificationsSet__section NotificationsSet__phone">
                                <div className="row header">
                                    <div className="cell th">Phone notifications</div>
                                    <div className="cell center"></div>
                                    <div className="cell center"></div>
                                    <div className="cell center"></div>
                                </div>
                                <div className="row">
                                    <div className="cell th">
                                        Calls forwarded to browser app
                                    </div>
                                    <div className="cell center">
                                        <CheckboxField
                                            name="forwardToBrowser"
                                            defaultValue={forwardToBrowser}
                                            ref={register()}
                                        />
                                    </div>
                                    <div className="cell"></div>
                                    <div className="cell"></div>
                                </div>
                                <div className="row">
                                    <div className="cell th">
                                        Calls forwarded to mobile app
                                    </div>
                                    <div className="cell center">
                                        <CheckboxField
                                            name="forwardToMobile"
                                            defaultValue={forwardToMobile}
                                            ref={register()}
                                        />
                                    </div>
                                    <div className="cell"></div>
                                    <div className="cell"></div>
                                </div>
                            </div>

                            <div className="NotificationsSet__section NotificationsSet__messages">
                                <div className="row header">
                                    <div className="cell th">New messages notifications</div>
                                    <div className="cell center">Browser*</div>
                                    <div className="cell center">Mobile app</div>
                                    <div className="cell center">Email</div>
                                </div>
                                <div className="row">
                                    <div className="cell th">
                                        Conversations I&lsquo;m assigned to
                                    </div>
                                    {Object.keys(notifyMessages?.assignedMe).map((platform) => (
                                        <div
                                            key={'assignedMe' + toUpperCaseFirst(platform)}
                                            className="cell center"
                                        >
                                            <CheckboxField
                                                name={
                                                    'message-assignedMe' +
                                                    toUpperCaseFirst(platform)
                                                }
                                                defaultValue={
                                                    notifyMessages?.assignedMe[platform]
                                                }
                                                ref={register()}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="row">
                                    <div className="cell th">
                                        Conversations assigned to others
                                    </div>
                                    {Object.keys(notifyMessages?.assignedOther).map(
                                        (platform) => (
                                            <div
                                                key={
                                                    'assignedOther' + toUpperCaseFirst(platform)
                                                }
                                                className="cell center"
                                            >
                                                <CheckboxField
                                                    name={
                                                        'message-assignedOther' +
                                                        toUpperCaseFirst(platform)
                                                    }
                                                    defaultValue={
                                                        notifyMessages?.assignedOther[platform]
                                                    }
                                                    ref={register()}
                                                />
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className="row">
                                    <div className="cell th">Unassigned conversations</div>
                                    {Object.keys(notifyMessages?.unassigned).map((platform) => (
                                        <div
                                            key={'unassigned' + toUpperCaseFirst(platform)}
                                            className="cell center"
                                        >
                                            <CheckboxField
                                                name={
                                                    'message-unassigned' +
                                                    toUpperCaseFirst(platform)
                                                }
                                                defaultValue={
                                                    notifyMessages?.unassigned[platform]
                                                }
                                                ref={register()}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="NotificationsSet__section NotificationsSet__conversation">
                                <div className="row header">
                                    <div className="cell th">Conversation notifications</div>
                                    <div className="cell center"></div>
                                    <div className="cell center">Mobile app</div>
                                    <div className="cell center">Email</div>
                                </div>
                                <div className="row">
                                    <div className="cell th">
                                        Someone assigns you to a conversation
                                    </div>
                                    <div className="cell"></div>
                                    {Object.keys(notifyConversation).map((conversation) => (
                                        <div
                                            key={'conversation' + conversation}
                                            className="cell center"
                                        >
                                            <CheckboxField
                                                name={'conversation-' + conversation}
                                                defaultValue={notifyConversation[conversation]}
                                                ref={register()}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="NotificationsSet__section NotificationsSet__review">
                                <div className="row header">
                                    <div className="cell th">New review notifications</div>
                                    <div className="cell center"></div>
                                    <div className="cell center"></div>
                                    <div className="cell center">Email</div>
                                </div>
                                <div className="row reviewsRow">
                                    <div className="cell th">New reviews</div>
                                    <div className="cell"></div>
                                    <div className="cell"></div>
                                    <div className="cell center">
                                        <CheckboxField
                                            name="review-email"
                                            defaultValue={notifyReviews?.email}
                                            ref={register()}
                                        />
                                    </div>
                                </div>
                                <div className={classNames('row', { hide: !email })}>
                                    <div className="cell th starTh">
                                        What star-rating do you want to know about?
                                    </div>
                                    <div className="cell"></div>
                                    <div className="cell"></div>
                                    <div className="cell center">
                                        <div
                                            className="star"
                                            onClick={() =>
                                                setStars({ ...stars, oneStar: !stars.oneStar })
                                            }
                                        >
                                            {stars?.oneStar ? (
                                                <StarFullIcon />
                                            ) : (
                                                <StarEmptyIcon />
                                            )}
                                            <span>1</span>
                                        </div>
                                        <div
                                            className="star"
                                            onClick={() =>
                                                setStars({ ...stars, twoStar: !stars.twoStar })
                                            }
                                        >
                                            {stars?.twoStar ? (
                                                <StarFullIcon />
                                            ) : (
                                                <StarEmptyIcon />
                                            )}
                                            <span>2</span>
                                        </div>
                                        <div
                                            className="star"
                                            onClick={() =>
                                                setStars({
                                                    ...stars,
                                                    threeStar: !stars.threeStar,
                                                })
                                            }
                                        >
                                            {stars?.threeStar ? (
                                                <StarFullIcon />
                                            ) : (
                                                <StarEmptyIcon />
                                            )}
                                            <span>3</span>
                                        </div>
                                        <div
                                            className="star"
                                            onClick={() =>
                                                setStars({
                                                    ...stars,
                                                    fourStar: !stars.fourStar,
                                                })
                                            }
                                        >
                                            {stars?.fourStar ? (
                                                <StarFullIcon />
                                            ) : (
                                                <StarEmptyIcon />
                                            )}
                                            <span>4</span>
                                        </div>
                                        <div
                                            className="star"
                                            onClick={() =>
                                                setStars({
                                                    ...stars,
                                                    fiveStar: !stars.fiveStar,
                                                })
                                            }
                                        >
                                            {stars?.fiveStar ? (
                                                <StarFullIcon />
                                            ) : (
                                                <StarEmptyIcon />
                                            )}
                                            <span>5</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="NotificationsSet__messages-info">
                                <div>*Browser (Desktop) notifications will {
                                        notificationsPermission === "granted" ? 'work because you have enabled them' :
                                        notificationsPermission === "denied" ? 'not work because you have disabled them' :
                                        'not work because you have not enabled them yet'} for this site in this browser.
                                </div>
                            </div>

                            <button className="btn-primary button-update">Update</button>
                        </form>
                    )}
                </>
            </>
        </div>
    );
};

export const NotificationsSettings = withPersonalSettingsStore(NotificationsSetWithStore);
