import React, { useState } from 'react';
import './DetailsCard.sass';
import { Broadcast } from '@src/stores/models/broadcast_new';
import CardHeader from './DetailsCardHeader';
import CardFooter from './DetailsCardFooter';
import CardContent from './DetailsCardContent';

export interface CardData {
    label: string;
    tags: string[];
    recipients: number;
    members: string[];
    message: string;
}

interface CardProps {
    broadcast: Broadcast;
    timezone: string;
    onDeleteClick: () => void;
    onEditBroadcastClick: (broadcast: Broadcast) => void;
    classname?: string
}

const DetailsCard: React.FunctionComponent<CardProps> = ({broadcast, classname, timezone, onDeleteClick, onEditBroadcastClick}) => {
    return (
        <>
            {broadcast &&
                <div className={`Broadcasts-details-card ${classname}`}>
                    <CardHeader
                        broadcast={broadcast}
                    />
                    <CardContent
                        broadcast={broadcast}
                        timezone={timezone}
                    />
                    <CardFooter
                        status={broadcast.status}
                        onDeleteDraftClick={onDeleteClick}
                        onEditBroadcastClick={() => onEditBroadcastClick(broadcast)}
                    />
                </div>
            }
        </>
    );
};

export default DetailsCard;
