import { HashMap, Message } from '@src/stores/models';
import { SocketEventType } from '@src/api/socket-wrapper/socket-event-type';
import { action, observable } from 'mobx';

export class SocketWrapper {
    private _wsSocket: WebSocket = null;
    @observable private listeners: HashMap<Array<(evt: any) => void>> = {};

    get isConnecting(): boolean {
        return this._wsSocket && this._wsSocket.readyState === this._wsSocket.CONNECTING;
    }

    get isOpen(): boolean {
        return this._wsSocket && this._wsSocket.readyState === this._wsSocket.OPEN;
    }

    get wsSocket(): WebSocket {
        return this._wsSocket;
    }

    @action init(): void {
        if (this._wsSocket && (this.isOpen || this.isConnecting)) {
            return;
        }

        try {
            this._wsSocket = new WebSocket(window['_env_']['WS_URL']);
            this.wsSocket.onerror = (evt) => {
                console.log('Error', evt);
            };
            this.wsSocket.addEventListener('open', () => {
                this._wsSocket.onclose = () => {
                    setTimeout(() => this.init(), 100);
                };
                this.setupEvents();
            });
        } catch (e) {
            console.log(e);
        }
    }

    @action destroy() {
        this.listeners = {}
    }

    @action addEventListener(type: SocketEventType, newListenerCallback: (evt: any) => void) {
        this.listeners[type] = [
            ...(this.listeners[type] && this.listeners[type] || []),
            newListenerCallback
        ];
    }

    @action close(): void {
        if (this._wsSocket) {
            this._wsSocket.onmessage = null;
            this._wsSocket.onerror = null;
            this._wsSocket.close();
        }
        this.listeners = {};
        this._wsSocket = null;
    }

    @action open(): void {
        if (!this._wsSocket || !this.isOpen) {
            this.init();
        }
    }

    @action send(msg: string): void {
        if (!this.isOpen) {
            this.open();
        }

        if (this.isConnecting) {
            setTimeout(() => this.send(msg), 250);
            return;
        }

        if (this._wsSocket.OPEN) {
            this._wsSocket.send(msg);
        }
    }

    private setupEvents(): void {
        if (!this._wsSocket) {
            return;
        }

        this.wsSocket.onmessage = (evt) => {
            // TODO add notification, error, unexpected  when models are created
            const parsed: { messageType: SocketEventType; payload: Message | any } = evt
                ? JSON.parse(evt.data)
                : null;

            if (!parsed) {
                return;
            }

            const listenersForThisMessageType = this.listeners[parsed.messageType];
            listenersForThisMessageType?.map((listenerCallback) => {

                if (listenerCallback) {
                    listenerCallback(parsed.payload);
                    return;
                }
                switch (parsed.messageType) {
                    case SocketEventType.Unexpected:
                    case SocketEventType.Error:
                        console.log('Error', parsed);
                        break;
                }
            })
        };
    }
}
