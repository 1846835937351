/* eslint-disable react/display-name */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import { AsYouType } from 'libphonenumber-js';
import Recorder from 'react-mp3-recorder'
import ReactAudioPlayer from 'react-audio-player'
// import { TwilioPhone } from '@src/components';
import classNames from 'classnames';
import { onSuccess } from '@src/theme/utils/constants';
import { withStores, STORES, WithPhoneStoreProps, WithSignupStoreProps } from '@src/stores/with-store';
import { LoadingState, ForwardNumberData, OnErrorDataPhone } from '@src/stores/models';
import {
    Loader,
    Table,
    TableHeader,
    ToggleTabNew,
    FormField,
    ErrorToastDownload,
    ErrorToastSave,
    Dialog,
    NewNumberAfterSignup
} from '@src/components';
import { PhoneNumberSection } from './PhoneNumberSection';

import './phone-number.sass';

type props = WithSignupStoreProps & WithPhoneStoreProps

export const PhoneNumberWithStore: React.FC<props> = (props) => {
    const { register, handleSubmit, errors, setValue } = useForm();
    const [number, setNumber] = useState('');
    const [recordingUrl, setRecordingUrl] = useState(null);
    const [recordingBlob, setRecordingBlob] = useState(null);
    const [showRecordDialog, setShowRecordDialog] = useState(false);
    const store = props.PhoneStore;
    const { forwardNumbers, smsSettings, featureFlags } = store;
    const loading = store.loadingState;
    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const [showSelectPhoneDialog, setShowSelectPhoneDialog] = useState(false);
    const {
        phone,
        massForward,
        officeHoursForward,
        officeHoursAutoTxt,
        officeHoursAutoTxtMsg,
        voiceRecordingURL
    } = smsSettings;
    const history = useHistory();

    store.onError = (type: string, data?: OnErrorDataPhone) => {
        store.loadingState = LoadingState.Error;
        switch (type) {
            case 'getBE': {
                toast.error(<ErrorToastDownload getBEData={() => store.getBEData('getBE')} />);
                break;
            }
            case 'addPhoneNumber': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() => store.addPhoneNumber(data.number, 'addPhoneNumber')}
                    />
                );
                break;
            }
            case 'removePhoneNumber': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.removePhoneNumber(data.id, 'removePhoneNumber')
                        }
                    />
                );
                break;
            }
            case 'changeActivity': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.changeActivity(
                                { id: data.id, active: data.active },
                                'changeActivity'
                            )
                        }
                    />
                );
                break;
            }
            case 'toggleSettings': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.toggleSettings(
                                data.toggleWhat,
                                data.id,
                                data.active,
                                'toggleSettings'
                            )
                        }
                    />
                );
                break;
            }
            case 'changeMsg': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() => store.changeMsg(data.id, data.msg, 'changeMsg')}
                    />
                );
                break;
            }
            case 'uploadGreeting': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() => store.uploadGreeting(data.file, 'uploadGreeting')}
                    />
                );
                break;
            }
            default: {
                return;
            }
        }
    };
    store.onSuccess = () => {
        store.loadingState = LoadingState.Loaded;
        onSuccess();
    };
    props.SignupStore.onError = (type: string) => {
        switch (type) {
            case 'buyPhoneNumber': {
                toast.error('Something went wrong, please try again later.', { autoClose: 4000 });
                break;
            }
            default: {
                return;
            }
        }
    };
    props.SignupStore.onSuccess = (type: string) => {
        switch (type) {
            case 'buyPhoneNumber': {
                toast.success('Your phone number has been set up!', { autoClose: 4000 });
                break;
            }
            default: {
                return;
            }
        };
    };

    const forwardNumbersFormated = forwardNumbers.map((el) => ({
        ...el,
        phone: new AsYouType('US').input(('' + el.phone).replace(/\D/g, '')),
    }));
    const forwardNumbersCount = forwardNumbersFormated.length;

    const getBEData = () => {
        store.getBEData('getBE', isFirstLoading);
        setIsFirstLoading(false);
    };

    const addPhoneNumber = ({ number }) => {
        store.addPhoneNumber(number, 'addPhoneNumber').then(() => setNumber(''));
    };

    const removePhoneNumber = (data: ForwardNumberData) => {
        store.removePhoneNumber(data.id, 'removePhoneNumber');
    };

    const changeActivity = (data: ForwardNumberData) => {
        store.changeActivity(data, 'changeActivity');
    };

    const toggleSettings = (toggleWhat: string, id: string, active: boolean) => {
        store.toggleSettings(toggleWhat, id, active, 'toggleSettings');
    };

    const changeMsg = (id: string, msg: string) => {
        store.changeMsg(id, msg, 'changeMsg');
    };

    const onNewNumber = (phoneNumber: string): void => {
        setShowSelectPhoneDialog(false);
        props.SignupStore.buyPhoneNumber(phoneNumber, 'buyPhoneNumber')
        .then(() => {
            getBEData();
        });
    };

    useEffect(() => {
        getBEData();
    }, []);

    useLayoutEffect(() => {
        store.init();
    }, []);

    useLayoutEffect(() => {
        if (history.location.search === '?select' && !phone) setShowSelectPhoneDialog(true);
    }, [history.location.pathname]);

    const HEADERS: TableHeader[] = [
        {
            title: 'Number',
            headerCls: ['left'],
            cls: ['left'],
            template: (h, data) => <span>{data['phone']}</span>,
            flexWidth: 2,
        },
        {
            title: 'Active',
            template: (header, data) => (
                <ToggleTabNew
                    commentBefore="OFF"
                    commentAfter="ON"
                    isChecked={data['active']}
                    stateChanged={() => changeActivity(data)}
                />
            ),
            flexWidth: 2,
        },
        {
            title: 'Actions',
            template: (header, data) => (
                <div>
                    <button className="btn-secondary" onClick={() => removePhoneNumber(data)}>
                        Remove
                    </button>
                </div>
            ),
            flexWidth: 2,
        },
    ];

    const content = [
        {
            title: 'Mass Forwarding',
            instruction:
                'Forward incoming calls to all active Forward Numbers. The first phone to respond will be connected.',
            value: massForward,
            input: '',
            update: (id: string, active: boolean) =>
                toggleSettings('toggleMassForward', id, active),
            ffLabel: {
                key: 'phone.call_forwarding',
                // value: featureFlags['phone.call_forwarding'] ? true : false
                value: featureFlags['phone.call_forwarding'] ? false : true,
            },
        },
        {
            title: 'Only Forward During Office Hours',
            instruction:
                'Calls will only be forwarded during specified office hours. Outside of office hours calls will be sent directly to voice mail.',
            value: officeHoursForward,
            input: '',
            update: (id: string, active: boolean) =>
                toggleSettings('toggleForwardDuringOfficeHours', id, active),
            ffLabel: {
                key: 'phone.call_forwarding',
                value: featureFlags['phone.call_forwarding'] ? false : true,
            },
        },
        {
            title: 'Out of Office Auto Text Reply',
            instruction:
                'Send an auto text response when messages are received outside of normal office hours. You can set the response below.',
            value: officeHoursAutoTxt,
            input: officeHoursAutoTxtMsg,
            update: (id: string, active: boolean) =>
                toggleSettings('toggleAutoMessage', id, active),
            updateMsg: (id: string, msg: string) => changeMsg(id, msg),
            ffLabel: {
                key: 'sms.after_hours_responder',
                value: featureFlags['sms.after_hours_responder'] ? false : true,
            },
        },
    ];

    // const greetingPhoneUs = () => {
    //     return window['_env_'].MYOPOLIS_GREETING_US;
    // }

    // const greetingPhoneCA = () => {
    //     return window['_env_'].MYOPOLIS_GREETING_CA;
    // }

    const blobToBuffer = (blob, cb) => {
        if (typeof Blob === 'undefined' || !(blob instanceof Blob)) {
            throw new Error('first argument must be a Blob')
        }
        if (typeof cb !== 'function') {
            throw new Error('second argument must be a function')
        }

        const reader = new FileReader()

        function onLoadEnd (e) {
            reader.removeEventListener('loadend', onLoadEnd, false)
            if (e.error) cb(e.error)
            else cb(null, Buffer.from(reader.result))
        }

        reader.addEventListener('loadend', onLoadEnd, false)
        reader.readAsArrayBuffer(blob)
    }

    const _onRecordingComplete = (blob) => {
        console.log('blobbing recording', blob)
        setRecordingBlob(blob);
        blobToBuffer(blob, (
            err,
            // buffer
        ) => {
            if (err) {
                console.error(err);
                return
            }

            if (recordingUrl) {
                window.URL.revokeObjectURL(recordingUrl);
            }

            setRecordingUrl(window.URL.createObjectURL(blob));
        })
    }

    const _onRecordingError = (err) => {
        console.log('recording error', err)

        if (recordingUrl) {
            window.URL.revokeObjectURL(recordingUrl);
        }

        setRecordingUrl(null);
        setRecordingBlob(null);
    }

    const _uploadGreeting = () => {
        setShowRecordDialog(false);
        store.uploadGreeting(recordingBlob, "uploadGreeting").then(() => {
            setRecordingUrl(null);
            setRecordingBlob(null);
        });
    }

    const _closeDialog = () => {
        setShowRecordDialog(false);
        setRecordingUrl(null);
        setRecordingBlob(null);
    }

    const dialogVoiceMail = (
        <Dialog
            className="LeftMenu__dialog"
            title={"Record Voicemail Greeting"}
            onClose={() => _closeDialog()}
            open={showRecordDialog}
        >
            <div className="PhoneNumber__voiceRecordingContainer">
                <div>Click and hold to start recording your voicemail greeting</div>
                <div className="PhoneNumber__voiceRecordingButton">
                    <Recorder
                        onRecordingComplete={_onRecordingComplete}
                        onRecordingError={_onRecordingError}
                    />
                </div>
                {recordingUrl && (
                    <div>
                        <p>
                            Click upload to save this recording as your voicemail greeting or rerecord your greeting.
                        </p>
                        <div className="PhoneNumber__voiceRecordingPlayback">
                            <ReactAudioPlayer
                                src={recordingUrl}
                                controls
                                style={{
                                    width: '100%'
                                }}
                            />
                        </div>
                        <div className="PhoneNumber__buttonRow">
                            <div className="AuthContainer__form-buttons">
                                <button
                                    className="btn-secondary"
                                    onClick={() => _closeDialog()}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn-primary"
                                    onClick={() => _uploadGreeting() }
                                >
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {!recordingUrl && (
                    <div className="PhoneNumber__buttonRow">
                        <div className="AuthContainer__form-buttons">
                            <button
                                className="btn-secondary"
                                onClick={() => _closeDialog()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </Dialog>
    );

    return (
        <div className="PhoneNumber">
            <Loader loadingState={props.PhoneStore.loadingState} />
            <>
                {loading !== LoadingState.Init && loading !== LoadingState.FirstLoading && (
                    <>
                        <div className="PhoneNumber__header">
                            <div className="PhoneNumber__title">Phone Number</div>
                            {!phone && (
                                <button
                                    className="btn-primary"
                                    onClick={() => setShowSelectPhoneDialog(true)}
                                >
                                    Select number
                                </button>
                            )}
                        </div>
                        {store.dataLoaded && (
                            <>
                                <div className="PhoneNumber__appPhoneNumber">
                                    {phone && (
                                        <InputMask
                                            mask="+1 (999) 999-9999"
                                            value={phone}
                                            disabled
                                        />
                                    )}
                                </div>
                                <div className="PhoneNumber__subtitle">
                                    {phone
                                        ? 'Once selected you cannot change this number.'
                                        : 'You have not selected the phone number yet. Please click the button above to do that. Once done you can configure the settings below.'}
                                    &nbsp;If you have any questions please contact us at&nbsp;
                                    <a href={`mailto:support@myopolis.com`}>support@myopolis.com</a>.
                                </div>
                                <div className="PhoneNumber__addNumbers-title">
                                    Voicemail Greeting
                                </div>
                                <div className="PhoneNumber__addNumbers-instruction">
                                    <button
                                        className="btn-primary"
                                        onClick={() => setShowRecordDialog(true)}
                                    >
                                        Edit Greeting
                                    </button>
                                    {voiceRecordingURL && (
                                        <div>
                                            <p>
                                                Listen to your voicemail greeting
                                            </p>
                                            <ReactAudioPlayer
                                                src={voiceRecordingURL}
                                                controls
                                                style={{
                                                    minWidth: '500px'
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className='PhoneNumber__addNumbers'>
                                    <div
                                        className={classNames({
                                            underFeatureFlagLabel: !featureFlags[
                                                'phone.call_forwarding'
                                            ],
                                        })}
                                    >
                                        <div className="PhoneNumber__addNumbers-title">
                                            Call Forwarding
                                        </div>
                                        <div className="PhoneNumber__addNumbers-instruction">
                                            Forward all incoming calls to one or more numbers, such
                                            as landlines or personal phones. Calls are evenly
                                            distributed among the configured numbers. Toggle a
                                            number off to temporarily remove it from the list.
                                        </div>
                                        {forwardNumbersFormated.length > 0 && (
                                            <div className="PhoneNumber__addNumbers-numbersList">
                                                <Table
                                                    key={forwardNumbersCount}
                                                    headers={HEADERS}
                                                    data={forwardNumbersFormated}
                                                    totalCount={forwardNumbersCount}
                                                />
                                            </div>
                                        )}
                                        <form
                                            className="PhoneNumber__addNumbers-addNewNumber"
                                            onSubmit={handleSubmit(addPhoneNumber)}
                                        >
                                            <div className="title">New phone number</div>
                                            <FormField
                                                label=" "
                                                htmlFor="number"
                                                error={errors.number}
                                            >
                                                <InputMask
                                                    mask="1 (999) 999-9999"
                                                    value={number}
                                                    onChange={(e) => setNumber(e.target.value)}
                                                    onBlur={(e) =>
                                                        setValue('number', e.target.value)
                                                    }
                                                    inputRef={register(
                                                        { name: 'number' },
                                                        {
                                                            required: 'Provide valid number',
                                                            validate: (number) =>
                                                                ('' + number).replace(/\D/g, '')
                                                                    .length == 11
                                                                    ? undefined
                                                                    : 'Provide valid number',
                                                        }
                                                    )}
                                                    className={classNames('form-input', {
                                                        error: errors.number,
                                                    })}
                                                    disabled={!phone}
                                                />
                                            </FormField>
                                            <button className="btn-primary" disabled={!phone}>
                                                Add
                                            </button>
                                        </form>
                                    </div>
                                    {!featureFlags['phone.call_forwarding'] && (
                                        <div className="featureFlagLabel">
                                            <button
                                                className="btn-primary-full"
                                                onClick={() => history.push('/pricing')}
                                            >
                                                Upgrade to enable this feature
                                            </button>
                                        </div>
                                    )}
                                </div>
                                {content.map((el, i) => (
                                    <PhoneNumberSection
                                        key={i}
                                        order={i}
                                        title={el.title}
                                        instruction={el.instruction}
                                        value={el.value}
                                        input={el.input}
                                        ffLabel={el.ffLabel}
                                        updateValue={(value) => el.update(smsSettings.locationId, value)}
                                        updateMsg={(msg) => el.updateMsg(smsSettings.locationId, msg)}
                                        disabled={!phone}
                                    />
                                ))}
                            </>
                        )}
                    </>
                )}
                <Dialog
                    onClose={() => setShowSelectPhoneDialog(false)}
                    open={showSelectPhoneDialog}
                    className="setupPhoneNumberDialog"
                >
                    <NewNumberAfterSignup
                        closeDialog={() => setShowSelectPhoneDialog(false)}
                        newNumber={onNewNumber}
                    />
                </Dialog>
                {dialogVoiceMail}
            </>
        </div>
    );
};

export const PhoneNumber = withStores(PhoneNumberWithStore, [STORES.Phone, STORES.Signup]);
// export const PhoneNumber = withPhoneStore(PhoneNumberWithStore);
