import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Stars5 } from '@src/theme/icons/stars-5-yellow.svg';

import '../Signup.sass';

const quotes = {
    '/signup': [
        {
            text: "Very user friendly, easy to understand and work on. The ability to send out broadcasts was a good way to stay in touch with my customers and keep servicing them.",
            who: "Home Services",
            where: "Citrus Heights, CA",
        },
        {
            text: "Myopolis is GOLD! Texting has become the standard means of communication between me and my clients - and with 30+ active clients at any given time who all expect immediate responses.",
            who: "Consultant",
            where: "Denver, CO",
        }
    ],
    '/signup/verify': [
        {
            text: "I primarily use it after I have a lead from BARK.com - the template has made landing a client much easier as I can respond faster than anyone else. I need to explore more follow up options - I'm sure there are a lot of features I haven't taken advantage of.",
            who: "Professional Services",
            where: "Astoria, NY",
        },
        {
            text: "Myopolis has been a lifesaver. From organizing them into groups, to being able to keep ongoing threads open, this app takes client management to a new level.",
            who: "Solopreneur",
            where: "Willow River, CA",
        }
    ],
    '/signup/phone': [
        {
            text: "Simplicity. Organized. Never missed a conversation. In touch with my customers. Communicating so much faster.",
            who: "Marketing Agency",
            where: "Brookfield, WI",
        },
    ],
    '/signup/cc': [
        {
            text: "I'm 100% [certain] Myopolis is why my close rate is so high. I quickly qualify people for my work in a simple text. No time wasted for either party!",
            who: "Online coach",
            where: "Twin Falls, ID",
        },
        {
            text: "I LOVE the ability to text multiple at the same time! The reason the phone option working properly would be a slam dunk is that many customers call back at the number they are texted at so when the service doesn't work properly, it makes us seen unprofessional/ have bad phone issues. This is a super simple tool that's also very powerful.",
            who: "Financier",
            where: "Brooklyn, NY",
        },
    ]
};

const Quote: React.FunctionComponent = () => {
    const pathname = useHistory().location.pathname;

    if (!quotes[pathname]) {
        return null;
    };

    return (
        <>
            {quotes[pathname].map((el, i) => {
                return (
                    <div className='Signup__quote' key={el.who + i}>
                        <div className="Signup__quote-header">
                            <Stars5 />
                        </div>
                        <div className="Signup__quote-main">{el.text}</div>
                        <div className="Signup__quote-footer">
                            <div className="Signup__quote-name-container">
                                <div className="Signup__quote-name">{el.who}</div>
                                <div className="Signup__quote-role">{el.where}</div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    );
};

export default Quote;
