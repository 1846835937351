import React, { useState, useEffect, useRef } from 'react';
import { STORES, withStores, WithTagStoreProps } from '@src/stores/with-store';
import { useOutsideClick } from '@src/hooks';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import { Loader } from '@src/components';
import customerStore from '@src/stores/customer.store';
import { ReactComponent as SearchIcon } from '@src/theme/icons/search-inactive.svg';
import { ReactComponent as ClearIcon } from '@src/theme/icons/clear.svg';
import { ReactComponent as Tag } from '@src/theme/icons/tag-outlined-grey.svg';
import { useDebounce } from '@src/hooks';


import './AddTag.sass';

interface EditionProps {
    customerId?: string;
    closeDialog?: () => void;
}

type AddTagDialogProps = WithTagStoreProps & EditionProps;

const AddTagDialogWithStore: React.FC<AddTagDialogProps> = (props) => {
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const TagsStore = props.TagsStore;
    TagsStore.EditedTagStore.onError = (message) => toast.error(message);
    const selectedTags = customerStore?.customerData?.tags;
    const [searchText, setSearchText] = useState('');
    const [hasFocus, setHasFocus] = useState(false);
    const [ableToRequest, setAbleToRequest] = useState(true);
    const [tags, setTags] = useState(TagsStore.TagsListStore.tagsList);
    const [tagsState, setTagsState] = useState(TagsStore.TagsListStore.tagsList?.map(el => el.id).reduce((a,b)=> (a[b]='basic',a),{}));

    const debouceSearchText = useDebounce(searchText, 200);

    useEffect(() => {
        setTags(TagsStore.TagsListStore.tagsList);

        const onlyNewItems = TagsStore.TagsListStore.tagsList
        ?.filter(el => !Object.keys(tagsState).includes(el.id))
        .map(el => el.id).reduce((a,b)=> (a[b]='basic',a),{});

        setTagsState({...tagsState, ...onlyNewItems});
    }, [TagsStore.TagsListStore.tagsList]);

    useOutsideClick(wrapperRef, () => {
        setHasFocus(false);
    });

    const searchTextChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
        setSearchText(e.target.value);

    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.keyCode === 27) setSearchText('');
    };

    const clear = () => {
        setSearchText('');
        inputRef.current.focus();
    };

    const searchTags = () => {
        TagsStore.TagsListStore.setSearchedTagsPage(0);
        TagsStore.TagsListStore.searchMoreTags(searchText, 'assignTags');
    };

    useEffect(() => {
        if (searchText.length !== 1) {
            searchTags();
        }
    }, [debouceSearchText]);

    const loadMoreTags = () => {
        if (!ableToRequest) {
            return;
        }
        setAbleToRequest(false);
        TagsStore.TagsListStore.searchMoreTags(searchText, 'assignTags')
        .then(() => {
            setAbleToRequest(true);
        });
    };

    const assignMember = (t) => {
        TagsStore.EditedTagStore.addMember(props.customerId, t.id)
        .then(() => {
            setTagsState({...tagsState, [t.id]: 'added'});
            // toast.success('Member assigned', { autoClose: 4000 });
        });
    }

    return (
        <div className="AddTag__dialog">
            <Loader loadingState={TagsStore.EditedTagStore.tagLoading} />
            <div>
                <h2 className="AddTag__dialogHeader">Assign Tags to Customer</h2>
                <div className="TagDialog__customersSearch SearchInput">
                    <div
                        className={classNames('input-container', {
                            'filled-out': searchText || hasFocus,
                        })}
                        ref={wrapperRef}
                    >
                        <SearchIcon />
                        <input
                            name="searchTags"
                            type="text"
                            placeholder="Search tags"
                            value={searchText}
                            ref={inputRef}
                            onFocus={() => setHasFocus(true)}
                            onKeyDown={(e) => keyDown(e)}
                            onChange={(e) => searchTextChange(e)}
                        />
                        {searchText && (
                            <button className="clear-btn" onClick={(): void => clear()}>
                                <ClearIcon />
                            </button>
                        )}
                    </div>
                </div>
                <div className="AddTag__dialogContentTable">
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={loadMoreTags}
                        initialLoad={false}
                        hasMore={TagsStore.TagsListStore.hasMoreTagsToLoad}
                        useWindow={false}
                        threshold={100}
                    >
                        {tags.filter((tag) => !selectedTags.find((x) => x.id == tag.id)).map((t, i) => {
                            return (
                                <div key={i} className="item">
                                    <span>
                                        <Tag />
                                        {t.value}
                                    </span>
                                    <div className="actions">
                                        {
                                            tagsState[t.id] === 'basic' ?
                                                <div
                                                    className="btn-tetriary-blue"
                                                    onClick={() => assignMember(t)}
                                                >
                                                    Add
                                                </div>
                                            : <div className="added btn-tetriary">Added</div>
                                        }
                                    </div>
                                </div>
                            );
                        })}
                    </InfiniteScroll>
                </div>
                <div className="AddTag__dialogContentButtons">
                    <button className="btn-primary navigate" onClick={() => props.closeDialog()}>
                        Done
                    </button>
                </div>
            </div>
        </div>
    );
};

export const AddTagDialog = withStores(AddTagDialogWithStore, [STORES.Customer, STORES.Tags]);
