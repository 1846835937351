import React from 'react';
import { images } from '@src/theme/images';

import './Signup.sass';

const SignupHeader: React.FunctionComponent = () => (
    <div className="Signup__header">
        <img src={images.logo} className="logo"/>
    </div>
);

export default SignupHeader;
