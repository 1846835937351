import React, { ReactNode } from 'react';
import './RecipientBar.sass';

interface RecipientBarItemProps {
    label: string;
    onRemoveClick?: (label: string) => void;
}

const RecipientBarItem: React.FunctionComponent<RecipientBarItemProps> = ({ label }) => (
    <div className='recipient-bar-item'>
        <span>{label}</span>
    </div>
);

interface RecipientBarProps {
    icon: ReactNode;
    items: string[];
    label: string;
    onItemRemoveClick?: (label: string) => void;
}

const RecipientBar: React.FunctionComponent<RecipientBarProps> = ({ icon, items, label, onItemRemoveClick }) => (
    <div className="recipient-bar">
        <div className="recipient-bar-header">
            {icon}
            <span>{items?.length} {label}</span>
        </div>
        <ul className='recipient-bar-list'>
            {items && items.map((item, i) => <RecipientBarItem key={i} label={item} onRemoveClick={onItemRemoveClick}/>)}
        </ul>
    </div>
);

export default RecipientBar;
