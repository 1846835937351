import React from 'react';
import { BarChart, Bar, XAxis, ResponsiveContainer, YAxis, CartesianGrid } from 'recharts';
import './chart.sass';
import DashboardCard from '../DashboardCard/DashboardCard';
import { CHART_HEIGHT, FONT_COLOR, NAVY, ConversationBreakdownProps } from './common';

const showItemsNo = 6;

export const ConversationsBreakdownChart: React.FC<ConversationBreakdownProps> = ({
    dataChart,
    margin,
    barSize,
    title,
    icon,
}) => {
    const dataChartLength = dataChart.length;
    const differenceBetweenItems = (dataChartLength - 1) / (showItemsNo - 1);
    const itemsToKeepRawIndexes = [0];
    dataChartLength &&
        Array.from(Array(dataChartLength).keys()).forEach(() =>
            itemsToKeepRawIndexes.push(
                itemsToKeepRawIndexes[itemsToKeepRawIndexes.length - 1] + differenceBetweenItems
            )
        );
    const itemsToKeepUltimateIndexes = itemsToKeepRawIndexes.map((t) => Math.round(t)).splice(0, 6);
    let dataConversationsBreakdownChart = dataChart;
    if (dataChartLength > showItemsNo) {
        dataConversationsBreakdownChart =
            dataChartLength &&
            dataChart.map((el, i) => {
                if (itemsToKeepUltimateIndexes.includes(i)) {
                    return el;
                } else {
                    return { ...el, period: '' };
                }
            });
    }

    return (
        <DashboardCard className="dashboard-chart">
            <div className="chart-title">
                <img src={icon} alt="chart icon" />
                <span>{title}</span>
            </div>
            <div className="dashboard-chart__container">
                <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                    <BarChart data={dataConversationsBreakdownChart} margin={margin}>
                        <XAxis
                            dataKey="period"
                            interval={0}
                            tickMargin={4}
                            tick={{
                                fontSize: 10,
                                color: FONT_COLOR,
                            }}
                        />
                        <YAxis
                            domain={[
                                0,
                                (dataMax) =>
                                    dataMax < 5
                                        ? dataMax % 2
                                            ? dataMax + 1
                                            : dataMax
                                        : (dataMax + 2) % 2
                                        ? dataMax + 1
                                        : dataMax + 2,
                            ]}
                            axisLine={false}
                            tickLine={false}
                            orientation="right"
                            tick={{
                                fontSize: 10,
                                color: FONT_COLOR,
                            }}
                            allowDecimals={false}
                        />
                        <CartesianGrid />
                        <Bar
                            dataKey="total"
                            fill={NAVY}
                            barSize={barSize}
                            radius={[barSize / 2, barSize / 2, barSize / 2, barSize / 2]}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </DashboardCard>
    );
};
