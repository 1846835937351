import React from 'react';
import { useHistory } from 'react-router-dom';
import { STORES, WithDashboardStoreProps, withStores, WithUserStoreProps } from '@src/stores/with-store';
import { LeaderboardTable, Loader } from '@src/components';
import DashboardCard from '../DashboardCard/DashboardCard';
import { EmptyLeaderboard } from './EmptyLeaderboard';
import { LoadingState } from '@src/stores/models';
import { images } from '@src/theme/icons/dashboard';

import '../../dashboard.sass';
import './Leaderboard.sass';

const LeaderboardWithStore: React.FunctionComponent<WithDashboardStoreProps & WithUserStoreProps> = ({ DashboardStore, UserStore }) => {
    const history = useHistory();
    const isNoEmployees = DashboardStore.loadingLeaderboard === LoadingState.Loaded && (UserStore.user.employees.length <= 1);
    const shortLeaderboard = DashboardStore.leaderboardStats.slice(0, 5);

    const handleCardClick = () => {
        if (!isNoEmployees) {
                history.push('/leaderboard');
        };
    };

    const renderTable =
        <div className="leaderboard">
            <LeaderboardTable
                leaderboard={shortLeaderboard}
                dashboard={true}
                loading={DashboardStore.loadingLeaderboard !== LoadingState.Loaded}
            />
        </div>;

    const renderEmpty = isNoEmployees && <EmptyLeaderboard/>;

    const renderLeaderboard = (renderEmpty || renderTable);

    const renderCard = <DashboardCard
        className={`Leaderboard ${isNoEmployees ? '' : 'Leaderboard-clickable'}`}
        onClick={handleCardClick}>
        <div className="dashboard-card__title">
            <img src={images.leaderboardB} alt="crown icon" className="card-icon" />
            <span>Leaderboard</span>
        </div>
        {renderLeaderboard}
    </DashboardCard>;

    return (
        <div className="dashboard__stats-container">
            <Loader loadingState={DashboardStore.loadingLeaderboard} />
            {renderCard}
        </div>
    );
};

export const Leaderboard = withStores(LeaderboardWithStore, [
    STORES.Dashboard,
    STORES.User
]);
