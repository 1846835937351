import React from 'react';
import { ReactComponent as FileEmptyIcon } from '@src/theme/icons/file-empty.svg';

import './generic-file-icon.sass';

interface GenericFileIconProps {
    text?: string;
}
export const GenericFileIcon: React.FC<GenericFileIconProps> = ({ text }) => {
    return (
        <div className="GenericFileIcon">
            <FileEmptyIcon />
            <span>{text}</span>
        </div>
    );
};
