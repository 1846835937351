import axios from 'axios';
import qs from 'qs';

const paramsSerializer = (params): string => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
};

const api = axios.create({
    baseURL: process.env.API_URL,
    paramsSerializer: (params) => paramsSerializer(params),
});

api.defaults.headers['Csrf-Token'] = 'nocheck';

api.interceptors.request.use(
    (config) => {
        config.withCredentials = true;
        config.headers['Csrf-Token'] = 'nocheck';
        config.headers['Content-Type'] = 'application/json';
        config.headers['Accept'] = 'application/json';

        return config;
    },
    (error) => Promise.reject(error)
);

// api.interceptors.response.use(
//   (response) => {
//     if (response.status === 200 && response.data && typeof response.data == 'string' && response.statusText !== 'OK') {
//       return Promise.reject();
//     }

//     return response
//   },
//   (err) => {
//     // if (err.response.status === 500 && location.pathname !== '/signin') {
//     //   location.replace('/signin')
//     // }

//     return Promise.reject(err)
//   }
// )

export const getAuth = () => {
    return api.get('/index');
};
export const logout = () => {
    return api.post('/logout');
};

export default api;
