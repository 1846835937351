import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingState, ReviewSource } from '@src/stores/models';
import { STORES, WithDashboardStoreProps, WithReviewsStoreProps, withStores } from '@src/stores/with-store';

import '../../dashboard.sass';
import { EmptyReviewsCard } from './EmptyReviewsCard/EmptyReviewsCard';
import { ReviewsPerSite } from './ReviewsPerSite/ReviewsPerSite';
import { RecentReviews } from './RecentReviews/RecentReviews';
import { Loader } from '@src/components/Loader/Loader';


type Props = WithReviewsStoreProps &
    WithDashboardStoreProps;

const ReviewsStatsWithStore: React.FunctionComponent<Props> = ({ DashboardStore, ReviewsStore }) => {
    const history = useHistory();

    const renderEmptyCard = <EmptyReviewsCard onClick={() => history.push({ pathname: `/reviews/` })}/>;
    const reviewsStatsKeys: ReviewSource[] = !!DashboardStore?.reviewsPerSiteStats ?
        Object.keys(DashboardStore.reviewsPerSiteStats).filter(k => Object.keys(ReviewSource).includes(k)).map(k => k as ReviewSource) :
        [];

    const fetchReviewGeneralData = () => {
        ReviewsStore.fetchReviewGeneralData();
    };

    useEffect(() => {
        if (!ReviewsStore.generalData || Object.keys(ReviewsStore.generalData).length === 0) {
            fetchReviewGeneralData();
        }
    }, []);

    const renderReviewsPerSite = () => <ReviewsPerSite
        reviewsStats={DashboardStore.reviewsPerSiteStats}
        sources={reviewsStatsKeys}
        period={DashboardStore.period?.name || 'this month'}
        total={DashboardStore.reviewsPerSiteStatsAll}
        loading={DashboardStore.loadingGeneral}
    />;

    const renderRecentReviews = () => <RecentReviews
        reviews={DashboardStore.latestReviews}
        loading={DashboardStore.loadingGeneral !== LoadingState.Loaded && ReviewsStore.searchLoading !== LoadingState.Loaded ? LoadingState.Loading : LoadingState.Loaded}
        removeReviewReply={(replySourceId: string) =>
            ReviewsStore.removeReplyFromReview(replySourceId)
        }
        saveReviewReply={(reviewId, replyId, replyTxt) =>
            ReviewsStore.saveReviewReply(reviewId, replyId, replyTxt)
        }
    />;

    const renderReviewsStats = DashboardStore.connectedSources?.length === 0
        ? renderEmptyCard
        : <>
            {renderReviewsPerSite()}
            {renderRecentReviews()}
        </>;

    return (
        <div className="dashboard__stats-container">
            <Loader loadingState={DashboardStore.loadingLeaderboard} />
            {renderReviewsStats}
        </div>
    );
};

export const ReviewsStats = withStores(ReviewsStatsWithStore, [
    STORES.Dashboard,
    STORES.Reviews
]);
