import React from 'react';
import { images } from '@src/theme/images';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { authorizeOAuthApp } from '@src/requests/auth.requests';

import '../Auth.sass'

export const OAuthConsent: React.FC = () => {

    const location = useLocation();

    const query = new URLSearchParams(location.search)

    const queryData = {
        client_id: query.get('client_id'),
        reditect_uri: query.get('redirect_uri'),
        state: query.get('state')
    }

    console.log(queryData);

    const onButtonClick = () => {
        authorizeOAuthApp(queryData).then(
            ({ data }) => {
                window.location.replace(data.url)
            },
            err => {
                toast.error(err.message);
            }
        )
    };

    return (
        <div className="Auth">
            <div className="AuthLogo">
                <img src={images.logo} className="AuthLogo-image" alt="app-logo" />
            </div>

            <div className="AuthContainer oauth">
                <h1 className="AuthContainer__header">This app is requesting permission to access your data</h1>

                <div>
                    <input type="submit" className="btn-primary" value="Allow" onClick={onButtonClick} />
                </div>
            </div>
        </div>
    )
}