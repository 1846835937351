import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { FormViewType } from '../ContactForm/FormViewType';
import { Customer } from '@src/stores/models';
import { AsYouType } from 'libphonenumber-js';
import { displayNameOrPhone } from '@src/theme/utils/helpers';
import { FormField } from '@src/components';
import { ReactComponent as ProfileOutlineIcon } from '@src/theme/icons/profile_outline.svg';
import { ReactComponent as AddIcon } from '@src/theme/icons/add.svg';
import { useDebounce } from '@src/hooks';
import { CustomersApi } from '@src/requests';

import './new-contact.sass';
import '../messages-common.sass';

interface MessageProps {
    setAddContactView: () => void;
    setAddAndMessageContactView: (insertedData) => void;
    openThreadWithCustomer: (action: FormViewType, customer?: Customer) => void;
};


export const NewContactSearch: React.FC<MessageProps> = (props) => {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const activeRef = useRef(null);
    const [activeIdx, setActiveIdx] = useState(-1);
    const [initCall, setInitCall] = useState(true);
    const [searchText, setSearchText] = useState('');
    const debounceSearchText = useDebounce(searchText, 250);
    const optionsRef = useRef(null);

    useEffect(() => {
        let unmounted = false;
        if (initCall && !searchText.length) {
            setInitCall(false);
        } else {
            CustomersApi.searchCustomersAndGroups(searchText)
            .then((customersAndGroups) => {
                if (!unmounted) {
                    setCustomers(customersAndGroups);
                };
            });
        };
        return () => { unmounted = true };
    }, [debounceSearchText]);

    useEffect(() => {
        if (activeRef?.current) {
            activeRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }, [activeIdx]);

    const onRowSelect = (customer: Customer): void => {
        props.openThreadWithCustomer(FormViewType.SEND_MESSAGE, customer);
    };

    const analizeWhatsInInput = () => {
        const text = searchText.trim();
        const num = text.replace(/\D/g, '');
        if (num.length) {
            return { phone: num };
        } else if (text.split(' ').length === 1) {
            return { firstName: text };
        } else {
            return { firstName: text.split(' ')[0], lastName: text.split(' ')[1] };
        };
    };

    const changeViewToAddAndMessageContact = () => {
        const insertedData = analizeWhatsInInput();
        props.setAddAndMessageContactView(insertedData);
    };

    const inputClicked = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.keyCode === 27) { // esc
            setSearchText('');
            return;
        }
        if ((e.keyCode === 13 || e.keyCode === 9)) { // enter, tab
            if (activeIdx === -1 && searchText.trim()) {
                changeViewToAddAndMessageContact();
            } else {
                if (activeIdx < 0 || activeIdx > customers.length) {
                    setActiveIdx(-1);
                    return;
                }
                setActiveIdx(-1);
                const selectedCustomer = customers[activeIdx];
                onRowSelect(selectedCustomer);
            }
            return;
        }
        if ((e.keyCode === 38 || e.keyCode === 40)) { // arrow up, arrow down
            const cursor = e.keyCode === 40 ? 1 : -1;
            const totalItemsCount = customers.length;
            let newVal = activeIdx + cursor;
            newVal = newVal < 0 ? totalItemsCount - 1 : newVal >= totalItemsCount ? 0 : newVal;
            setActiveIdx(newVal);
            return;
        };
    };

    const optionsView = searchText && (
        <div className="options">
            <div className="options-list" ref={optionsRef}>
                {Boolean(searchText || customers.length) && (
                    <div
                        className={classNames('item add-item', {
                            active: customers.length === activeIdx,
                        })}
                    >
                        <button onClick={() => changeViewToAddAndMessageContact()}>
                            <AddIcon className="new" />
                            <span>Add Contact & Message</span>
                        </button>
                    </div>
                )}
                <div className="options-list-results">
                    {customers.map((customer, i) => {
                        const isActive = i === activeIdx;
                        const cls = classNames({
                            item: true,
                            customer: customer.firstName || customer.phone,
                            active: isActive,
                        });
                        return (
                            <div
                                key={uuidv4()}
                                className={cls}
                                {...(isActive && { ref: activeRef })}
                            >
                                <button onClick={() => onRowSelect(customer)}>
                                    <ProfileOutlineIcon className="old" />
                                    <div className="customerData">
                                        <p className="name">{displayNameOrPhone(customer)}</p>
                                        {!!customer.phone && (
                                            <p className="phone">
                                                {new AsYouType('US').input(
                                                    ('' + customer.phone).replace(/\D/g, '')
                                                )}
                                            </p>
                                        )}
                                    </div>
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );

    return (
        <section className="NewContactSearch">
            <div className="NewContactSearch__header">
                <h2>Send Message</h2>
                <button
                    className="btn-primary"
                    onClick={() => props.setAddContactView()}
                >
                    Add Contact
                </button>
            </div>
            <div className="NewContactSearch__form">
                <div className="ncRow">
                    <FormField label="Send To:" htmlFor="contact-autocomplete">
                        <input
                            type="text"
                            placeholder="Enter name or phone"
                            value={searchText}
                            onKeyDown={inputClicked}
                            onChange={(e) => setSearchText(e.target.value)}
                            name="contact-autocomplete"
                            className={'form-input'}
                            autoComplete="off"
                        />
                    </FormField>
                </div>
            </div>
            <div className="NewContactSearch__bottom">
                {optionsView}
            </div>
        </section>
    );
};