import React from 'react';
import { useHistory } from 'react-router-dom';

import './Leaderboard.sass';

export const EmptyLeaderboard: React.FunctionComponent = () => {
    const history = useHistory();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        history.push('/settings/team');
    };

    return (
        <div className="Leaderboard-empty">
            <button
                className="btn-primary-full"
                type="button"
                onClick={handleClick}
            >
                Add Team Members
            </button>
        </div>
    );
};
