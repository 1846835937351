import React from 'react';
import { Loader, Metric, ProgressBar } from '@src/components';
import DashboardCard from '../DashboardCard/DashboardCard';
import { getPeriodToCompare, timePeriodToString } from '../../helpers/date';
import { STORES, WithDashboardStoreProps, withStores } from '@src/stores/with-store';
import { DashboardStore } from '@src/stores/dashboard.store';
import { images } from '@src/theme/icons/dashboard';
import '@src/components/KeyMetrics/keyMetrics.sass';
import './ReviewsMetrics.sass';
import { LoadingState } from '@src/stores/models';

const absolutePercentChange = (value: number, total: number): (number | null) => {
    if (value === 0 && total === 0)
        return 0;
    if (total === 0)
        return 100;
    return Math.abs(Math.round(((total - value)/total) * 100));
};

interface MetricsProps {
    dashboardStore: DashboardStore;
}

const ReviewGoals: React.FunctionComponent<MetricsProps> = ({dashboardStore}) => {
    return (
        <div className="Metric">
            <div className="title">
                <h3 className="medium">Review Goal</h3>
            </div>
            <div className="data">
                <img src={images.goal} alt="goal" />
                {dashboardStore.reviewsStats?.reviewGoalTotal || 0}/{dashboardStore.reviewsStats?.reviewGoal || 0}
            </div>
            <ProgressBar current={dashboardStore.reviewsStats?.reviewGoalTotal} total={dashboardStore.reviewsStats?.reviewGoal} />
        </div>
    )
}

const RequestSent: React.FunctionComponent<MetricsProps> = ({dashboardStore}) => {
    const currentRequestsToLastRequestsPercent = absolutePercentChange(dashboardStore.reviewsStats?.currentRequests, dashboardStore.reviewsStats?.lastRequests);
    const comparedPeriod = getPeriodToCompare(dashboardStore.period?.name);
    return (
        <Metric
            titleRow1="Review"
            titleRow2="Request Sent"
            titleSizeRow1="medium"
            titleSizeRow2="medium"
            iconName="calendar"
            toShow={dashboardStore.reviewsStats?.currentRequests || 0}
            toCompare={dashboardStore.reviewsStats?.lastRequests}
            hideComparing={!comparedPeriod}
        >
            {currentRequestsToLastRequestsPercent || 0}% vs {comparedPeriod}
        </Metric>
    );
};

const TotalReviews: React.FunctionComponent<MetricsProps> = ({dashboardStore}) => {
    const currentTotalToLastRequestsPercent = absolutePercentChange(dashboardStore.reviewsStats?.currentTotal, dashboardStore.reviewsStats?.lastRequests);
    const comparedPeriod = getPeriodToCompare(dashboardStore.period?.name);
    const titleRow2 = timePeriodToString(dashboardStore.period);

    return (
        <Metric
            titleRow1="Total Reviews"
            titleRow2={`${titleRow2}`}
            titleSizeRow1="medium"
            titleSizeRow2={titleRow2.length > 15 ? 'small' : 'medium'}
            iconName="total"
            toShow={dashboardStore.reviewsStats?.currentTotal || 0}
            toCompare={dashboardStore.reviewsStats?.lastTotal}
            hideComparing={!comparedPeriod}
        >
            {dashboardStore.reviewsStats?.currentTotal !== dashboardStore.reviewsStats?.lastTotal && `${currentTotalToLastRequestsPercent}% `}
            {!!comparedPeriod && (
                dashboardStore.reviewsStats?.currentTotal === dashboardStore.reviewsStats?.lastTotal
                    ? 'the same as ' + comparedPeriod
                    : dashboardStore.reviewsStats?.currentTotal > dashboardStore.reviewsStats?.lastTotal
                        ? 'higher increase'
                        : 'lower increase'
            )}
        </Metric>
    );
};

const OverallRating: React.FunctionComponent<MetricsProps> = ({dashboardStore}) => {
    const comparedPeriod = getPeriodToCompare(dashboardStore.period?.name);
    const titleRow2 = timePeriodToString(dashboardStore.period);

    return (
        <Metric
            titleRow1="Overall Rating"
            titleRow2={`${titleRow2}`}
            titleSizeRow1="medium"
            titleSizeRow2={titleRow2.length > 15 ? 'small' : 'medium'}
            iconName="star"
            toShow={dashboardStore.reviewsStats?.currentRating || 0}
            toCompare={dashboardStore.reviewsStats?.lastRating}
            hideComparing={!comparedPeriod}
        >
            {!!comparedPeriod && (
                dashboardStore.reviewsStats?.currentRating === dashboardStore.reviewsStats?.lastRating
                    ? 'the same as ' + comparedPeriod
                    : 'from ' + dashboardStore.reviewsStats?.lastRating
            )}
        </Metric>
    );
};

type ResponseTimeProps = WithDashboardStoreProps

export const ReviewsMetricsWithStore: React.FunctionComponent<ResponseTimeProps> = ({ DashboardStore }) => {
    const showCard = !(DashboardStore.loadingGeneral === LoadingState.Loaded && DashboardStore.loadingLeaderboard === LoadingState.Loaded) || 
        DashboardStore.connectedSources?.length > 0;
        
    return (showCard ?
        <DashboardCard className="KeyMetrics dashboard-card__flex ReviewsMetrics">
            <Loader loadingState={DashboardStore.loadingGeneral === LoadingState.Loaded && DashboardStore.loadingLeaderboard === LoadingState.Loaded ? LoadingState.Loaded : LoadingState.Loading} />
            <ReviewGoals dashboardStore={DashboardStore}/>
            <RequestSent dashboardStore={DashboardStore}/>
            <TotalReviews dashboardStore={DashboardStore}/>
            <OverallRating dashboardStore={DashboardStore}/>
        </DashboardCard>
        : null
    );
};

export const ReviewsMetrics = withStores(ReviewsMetricsWithStore, [
    STORES.Dashboard,
]);
