/* eslint-disable react/display-name */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, Route, Redirect, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { onSuccess } from '@src/theme/utils/constants';
import { withTeamStore, WithTeamStoreProps } from '@src/stores/with-store';
import { Status, RoleTxt, LoadingState, OnErrorDataTeam } from '@src/stores/models';
import {
    Loader,
    Table,
    TableHeader,
    Dialog,
    ErrorToastDownload,
    ErrorToastSave,
} from '@src/components';
import { transformPhoneNumber } from '@src/theme/utils/helpers';
import { TeamForm } from './TeamForm';
import { PasswordForm } from './PasswordForm';

import { images } from '@src/theme/images';
import { Employee } from '@src/stores/models/employee';

import './team.sass';

const datePersonTemplate = (firstName: string, lastName: string, email: string, data: Employee) => {
    return (
        <>
            <ReactTooltip />
            <div data-tip={`${data[firstName]} ${data[lastName]} ${data[email]}`}>
                <div className="memberName">
                    {data[firstName]} {data[lastName]}
                </div>
                <div className="memberEmail">{data[email]}</div>
            </div>
        </>
    );
};

const datePhoneTemplate = (phone: string, data: Employee) => {
    const number = transformPhoneNumber(data[phone]);
    return <>{number}</>;
};

const dateRoleTemplate = (role: string, data: Employee) => {
    const roleTxt = RoleTxt[data[role]];
    return <>{roleTxt}</>;
};

export const TeamWithStore: React.FC<WithTeamStoreProps> = (props: WithTeamStoreProps) => {
    const store = props.TeamStore;
    const loading = store.loadingState;
    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const { employees } = props.TeamStore;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [restoreMemberId, setMemberToRestore] = useState('');
    const { path, url } = useRouteMatch();
    const history = useHistory();
    const members = employees;
    const widerColumnsWidth = window.innerWidth > 1200 ? 2 : 3;

    store.onError = (type: string, data?: OnErrorDataTeam) => {
        store.loadingState = LoadingState.Error;
        switch (type) {
            case 'getBE': {
                toast.error(<ErrorToastDownload getBEData={() => store.getAndSetTeam('getBE')} />);
                break;
            }
            case 'restoreTeamMember': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.restoreTeamMember(
                                data.id,
                                data.password,
                                data.confirmPassword,
                                'restoreTeamMember'
                            )
                        }
                    />
                );
                break;
            }
            case 'resendInvitation': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.resendInvitation(
                                data.id,
                                'resendInvitation'
                            )
                        }
                    />
                );
                break;
            }
            case 'archiveTeamMember': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.archiveTeamMember(data.id, 'archiveTeamMember')
                        }
                    />
                );
                break;
            }
            case 'addTeamMember': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() => store.addTeamMember(data.newMember, 'addTeamMember')}
                    />
                );
                break;
            }
            case 'updateMember': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.updateMember(
                                data.id,
                                data.memberData,
                                data.changes,
                                'updateMember'
                            )
                        }
                    />
                );
                break;
            }
            default: {
                return;
            }
        }
    };
    store.onSuccess = () => {
        store.loadingState = LoadingState.Loaded;
        onSuccess();
    };
    store.onSuccessReinvite = () => {
        store.loadingState = LoadingState.Loaded;
        toast.success('User activation email has been resend.', { autoClose: 4000 });;
    };

    const getAndSetTeam = () => {
        store.getAndSetTeam('getBE', isFirstLoading);
        setIsFirstLoading(false);
    };

    const restore = (id: string, password: string, confirmPassword: string) => {
        store.restoreTeamMember(id, password, confirmPassword, 'restoreTeamMember');
    };

    const resendInvitation = (id: string) => {
        store.resendInvitation(id, 'resendInvitation');
    };

    const archive = (id: string) => {
        store.archiveTeamMember(id, 'archiveTeamMember');
    };

    const confirmRestore = ({ password, confirmPassword }): void => {
        restore(restoreMemberId, password, confirmPassword);
        setMemberToRestore('');
        setDialogOpen(false);
    };

    const startRestoreMember = (id: string) => {
        setDialogOpen(true);
        setMemberToRestore(id);
    };

    const toggleMemberStatus = (id: string, status: string) => {
        status === Status.ARCHIVED ? startRestoreMember(id) : archive(id);
    };

    const HEADERS: TableHeader[] = [
        {
            title: 'Person',
            headerCls: ['left'],
            cls: ['left'],
            flexWidth: widerColumnsWidth,
            template: (header, data) => datePersonTemplate('firstName', 'lastName', 'email', data),
        },
        {
            title: 'Active',
            flexWidth: 2,
            template: (header, data, idx) => (
                <div className="memberStatus">
                    <ReactTooltip />
                    {members[idx].status === Status.ACTIVE && (
                        <img src={images.checkmarkSelected} alt="checkmark" data-tip="Active" />
                    )}
                    {members[idx].status === Status.INACTIVE && (
                        <div className="inactiveMember">
                            <img src={images.checkmarkUnselected} alt="checkmark" data-tip="Inactive" />
                            <button
                                className="btn-secondary reinvite"
                                onClick={() => {
                                    resendInvitation(members[idx].id)
                                }}
                            >
                                Reinvite
                            </button>
                        </div>
                    )}
                    {members[idx].status === Status.ARCHIVED && (
                        <img src={images.clear} alt="checkmark" className="icon-archived" data-tip="Archived" />
                    )}
                </div>
            ),
        },
        {
            title: 'Phone',
            headerCls: ['left'],
            cls: ['left'],
            flexWidth: widerColumnsWidth,
            template: (header, data) => datePhoneTemplate('phone', data),
        },
        {
            title: 'Role',
            headerCls: ['left'],
            cls: ['left'],
            flexWidth: 2,
            template: (header, data) => dateRoleTemplate('role', data),
        },
        {
            title: 'Actions',
            flexWidth: 2,
            template: (header, data, idx) => (
                <div className="actions">
                    <Link to={`${url}/member/edit/${members[idx].id}`} className="btn-primary">
                        Edit
                    </Link>
                    <button
                        className="btn-secondary"
                        onClick={() => toggleMemberStatus(members[idx].id, members[idx].status)}
                    >
                        {members[idx].status === Status.ARCHIVED ? 'Restore' : 'Archive'}
                    </button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        getAndSetTeam();
    }, [history.location]);

    useLayoutEffect(() => {
        store.init();
    }, []);

    return (
        <div className="Team">
            <Loader loadingState={store.loadingState} />
            <Switch>
                <Route exact path={path}>
                    <>
                        {loading !== LoadingState.Init && loading !== LoadingState.FirstLoading && (
                            <>
                                <div className="TeamList">
                                    <div className="TeamList__header">
                                        <div className="left">
                                            <div className="title">Team Members</div>
                                            <div className="subtitle">
                                                Manage user accounts and access
                                            </div>
                                        </div>
                                        {store.dataLoaded && (
                                            <div className="right">
                                                <div className="add">
                                                    <Link
                                                        to={`${url}/member/add`}
                                                        className="btn-primary"
                                                    >
                                                        Add Member
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {store.dataLoaded && (
                                        <>
                                            <div className="TeamList__list">
                                                <Table
                                                    headers={HEADERS}
                                                    data={members}
                                                    totalCount={members.length}
                                                />
                                            </div>
                                            <Dialog
                                                title="Restore a member"
                                                open={dialogOpen}
                                                onClose={() => setDialogOpen(false)}
                                            >
                                                <p className="Team__restore-info">
                                                    Please provide password to restore a member
                                                </p>
                                                <div className="Team__restore-form">
                                                    <PasswordForm onSubmit={confirmRestore} />
                                                </div>
                                            </Dialog>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                </Route>
                <Route path={`${path}/member/:action/:employeeId?`}>
                    <TeamForm />
                </Route>
                <Redirect from={`${path}/member`} to={path} />
            </Switch>
        </div>
    );
};

export const Team = withTeamStore(TeamWithStore);
