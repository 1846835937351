import React from 'react';

interface PlanActionBarProps {
    isContactSales: boolean;
    trialDays?: number
    currentPriceId?: string;
    noCC: boolean;
    isSignup: boolean;
    priceId: string;
    onCancelTrial: () => void;
    onTryFreeClick: (priceId: string) => void;
    onBuyNowClick: (priceId: string) => void;
    onSelectClick: () => void;
}

const PlanActionBar: React.FunctionComponent<PlanActionBarProps> = (props) => {
    const upgradeToFullVersion = (props.noCC && props.priceId === props.currentPriceId) &&
        <>
            <button
                className="btn-primary-full"
                onClick={() => props.onCancelTrial()}
            >
                Upgrade
            </button>
            <div className="PricingPlan__yourPlan">IT'S YOUR CURRENT PLAN</div>
        </>

    const itsCurrentPlan = (!props.noCC && props.priceId === props.currentPriceId) &&
        <div className="PricingPlan__yourPlan">IT'S YOUR CURRENT PLAN</div>

    const buttonsSignup = props.isSignup &&
        <div className="PricingPlan__action-onSignup">
            <div>
                {props.trialDays ?
                    <button
                        className="btn-primary btnSignup"
                        onClick={() => props.onTryFreeClick(props.priceId)}
                    >
                        Try Free
                    </button> :
                    <button
                        className="btn-primary-full btnSignup"
                        onClick={() => props.onBuyNowClick(props.priceId)}
                    >
                        Buy Now
                    </button>
                }
            </div>
        </div>

    const buttonSelect =
        <button
            className="btn-primary-full"
            onClick={() => props.onSelectClick()}
        >
            Choose plan
        </button>

    return (upgradeToFullVersion || itsCurrentPlan || buttonsSignup || buttonSelect);
};

export default PlanActionBar;
