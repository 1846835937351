import React, { useState } from 'react';
import classNames from 'classnames';
import { SortColumn, TableHeader } from './Table';

import './table.sass';

interface TableHeadersProps {
    headers: TableHeader[];
    onSortChange: (sortColumn: SortColumn) => void;
};

const TableHeaders: React.FunctionComponent<TableHeadersProps> = (props) => {
    const [sortCol, setSortCol] = useState<SortColumn>({} as any);

    const sortChange = (col: TableHeader) => {
        if (!col.sortField) {
            return;
        }

        const newSort = {
            col: col.sortField,
            dir: col.sortField === sortCol.col ? (sortCol.dir === 'asc' ? 'desc' : 'asc') : 'asc',
        };
        setSortCol(newSort);
        if (props.onSortChange) {
            props.onSortChange(newSort);
        }
    };

    return (
        <div className="Table__headers">
            {props.headers.map((col, i) => {
                const cls = {
                    Table__header: true,
                    [`col-${col.flexWidth}`]: col.flexWidth,
                    sorting: !!col.sortField,
                };
                const sortArrowCls = {
                    sorting: true,
                    'sort-acs': sortCol.col === col.sortField && sortCol.dir === 'asc',
                    'sort-desc':
                        sortCol.col === col.sortField && sortCol.dir === 'desc',
                };

                col.headerCls?.forEach((hCls) => {
                    cls[hCls] = true;
                });

                return (
                    <div
                        className={classNames(cls)}
                        onClick={() => sortChange(col)}
                        key={'h-' + i}
                    >
                        {col.title}
                        {!!col.sortField && (
                            <span className={classNames(sortArrowCls)} />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default TableHeaders;
