import { authPages } from '@src/pages';
import { STORES, WithAuthStoreProps, WithBillingStoreProps, withStores, WithUserStoreProps } from '@src/stores/with-store';
import { images } from '@src/theme/images';
import trimDynamicSlugs from '@src/utils/trimDynamicSlugs';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BlockingPopup } from '../BlockingPopup/BlockingPopup';
import { ReactComponent as CloseIcon } from '@src/theme/icons/close.svg';

import './LockedAccountDialog.sass';

const messages = {
    'trial-end': {
        message: "You're at the end of your trial.",
        link: '/pricing',
        upgrade: <>{' '}to upgrade your billing plan and ensure you don't lose your local business number.</>
    },
    'expired-CC': {
        message: 'There was an issue processing your payment.',
        link: '/settings/billing',
        upgrade: <>{' '}to ensure your payment information is up to date.</>
    },
    'add-new-employee': {
        message: 'You have reached a limit of team members you can add in your billing plan.',
        link: '/pricing',
        upgrade: <>{' '}to upgrade your billing plan and add new team members.</>
    },
    'limit-hit-for-trial': {
        message: 'You have reached a limit of messages during the trial period.',
        link: '/settings/billing?finishTrial',
        upgrade: <>{' '}to finish your trial period now and upgrade your billing plan.</>
    },
    'limit-hit-for-month': {
        message: 'You have reached a limit of messages you can send this month.',
        link: '/pricing',
        upgrade: <>{' '}to upgrade your billing plan and send more messages.</>
    }
};

interface LockedAccountDialogProps {
    warningCase?: string;
    onClose: () => void;
}

const LockedAccountDialogWithStore: React.FunctionComponent<LockedAccountDialogProps & WithAuthStoreProps & WithBillingStoreProps & WithUserStoreProps> = ({warningCase, onClose, AuthStore, BillingStore, UserStore}) => {
    const [show, setShow] = useState<boolean>(false);
    const [showCallSales, setShowCallSales] = useState<boolean>(false);
    const location = useLocation();
    const name = UserStore.user.employee.firstName ? UserStore.user.employee.firstName : '';
    const isOwnerOrLeader = UserStore.user.employee.role === 0 || UserStore.user.employee.role === 1;
    const addNewEmployee = warningCase === 'addNewEmployee';
    const limitHitForTrial = warningCase === 'limitHitForTrial';
    const limitHitForMonth = warningCase === 'limitHitForMonth';
    const expiredRejectedCC = !!BillingStore.problemCCExpired || !!BillingStore.problemStatus;
    const trialEnd = !expiredRejectedCC && !addNewEmployee && !limitHitForTrial && !limitHitForMonth;
    const messageContent =
        (addNewEmployee && 'add-new-employee') ||
        (limitHitForTrial && 'limit-hit-for-trial') ||
        (limitHitForMonth && 'limit-hit-for-month') ||
        (expiredRejectedCC && 'expired-CC') ||
        (trialEnd && 'trial-end');

    const handleCallSalesClick = () => {
        setShowCallSales(true);
    };

    const handleCallSalesClose = () => {
        setShowCallSales(false);
    };

    useEffect(() => {
        const hidePopup: boolean = !!authPages.find(page => location.pathname.includes(trimDynamicSlugs(page.path)) && page.hideLockedAccountDialog);
        setShow(((AuthStore.accountStatus === 'LOCKED') && !hidePopup) || !!warningCase);
    }, [AuthStore, location, warningCase]);

    const renderDefaultContent = !showCallSales && <>
        {(limitHitForTrial || limitHitForMonth) &&
            <div className='closeButton'>
                <button onClick={() => onClose()}>
                    <CloseIcon />
                </button>
            </div>
        }
        <h2>Hi {name},</h2>
        <p className="messageCopy">{messages[messageContent].message}</p>
        <p>{isOwnerOrLeader ? <Link className="pricing-link" to={messages[messageContent].link}>Click here</Link> : 'Reach out to your manager'} {messages[messageContent].upgrade}</p>
        {isOwnerOrLeader && <p>If you have any questions <span className="dialog-link" onClick={handleCallSalesClick}>click here</span> to talk to sales.</p>}
        <p className="LockedAccount__signature">Team Myopolis</p>
    </>;

    const renderCallSalesContent = showCallSales && <>
        <h2>Talk to Sales</h2>
        <a className="CallSales__phone" href="tel:262-279-4058">
            <img src={images.phoneIcon2} alt="phone" />
            <div>
                <span>(262) 279-4058</span>
                <span className="CallSales__country">United States</span>
            </div>
        </a>
        <button className="btn-primary" onClick={handleCallSalesClose}>
            Back
        </button>
    </>

    const renderContent = renderDefaultContent || renderCallSalesContent;

    return (
        <>
            {show &&
                <BlockingPopup
                    className={`${showCallSales ? "CallSales" : "LockedAccount"}`}
                >
                    {renderContent}
                </BlockingPopup>
            }
        </>
    );
};

const LockedAccountDialog = withStores(
    LockedAccountDialogWithStore,
    [
        STORES.Auth,
        STORES.Billing,
        STORES.User
    ]
);

export default LockedAccountDialog;
