import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ConvertedLeaderboardItem } from '@src/stores/models';
import { Table } from '@src/components';

import './leaderboard-table.sass';

interface LeaderboardTableProps {
    leaderboard: ConvertedLeaderboardItem[];
    dashboard?: boolean;
    sortChanged?: (col: Col) => void;
    loading?: boolean;
};

interface Col {
    col: string;
    dir: string;
};

export const LeaderboardTable: React.FC<LeaderboardTableProps> = (props: LeaderboardTableProps) => {
    const [page, setPage] = useState(1);
    const [sortingChanged, setSortingChanged] = useState(Math.random());
    const pageSize = 6;

    function round(value: number, precision: number): number {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    };

    function secondsToDisplayTime(seconds: number): [number, string] {
        if (seconds === undefined) {
            return [undefined, undefined];
        };

        if (seconds < 60) {
            return [seconds, ' s'];
        } else if (seconds < 3600) {
            return [round(seconds / 60, 1), ' m'];
        } else if (seconds < 86400) {
            return [round(seconds / 3600, 1), ' h'];
        } else {
            return [round(seconds / 86400, 1), ' d'];
        };
    };

    const handleSettingLeaderboardToDisplay = () => {
        return props.leaderboard
        .map((el) =>
            el.avgResponseTime
                ? { ...el, avgResponseTime: secondsToDisplayTime(el.avgResponseTime) }
                : { ...el, avgResponseTime: '-' }
        )
        .slice((page - 1) * pageSize, page * pageSize);
    };

    const [leaderboardToDisplay, setLeaderboardToDisplay] = useState(handleSettingLeaderboardToDisplay());

    const headersPattern = [
        {
            title: '',
            cls: ['bold'],
            template: (header, data, idx) => <>{pageSize * (page - 1) + idx + 1}.</>,
            flexWidth: 2,
        },
        {
            title: '',
            field: 'name',
            cls: ['bold', 'left'],
            flexWidth: 11,
        },
        {
            title: 'Request Sent',
            field: 'sent',
            sortField: props.dashboard ? null : 'sent',
            flexWidth: 5,
        },
        {
            title: 'Credited Reviews',
            field: 'credited',
            sortField: props.dashboard ? null : 'credited',
            flexWidth: 5,
        },
        {
            title: 'Rating',
            field: 'rating',
            sortField: props.dashboard ? null : 'rating',
            flexWidth: 5,
        },
        {
            title: 'Conversations',
            field: 'conversations',
            sortField: props.dashboard ? null : 'conversations',
            flexWidth: 5,
        },
        {
            title: 'Responses',
            field: 'responses',
            sortField: props.dashboard ? null : 'responses',
            flexWidth: 5,
        },
        {
            title: 'Avg Response Time',
            field: 'avgResponseTime',
            sortField: props.dashboard ? null : 'avgResponseTime',
            flexWidth: 5,
        },
    ];

    const [HEADERS, setHEADERS] = useState(headersPattern);

    const sortChanged = (col: Col) => {
        props.sortChanged(col);
        setSortingChanged(Math.random());
    };

    const removeCreditedOnDashboard = () => {
        const creditedIndex = HEADERS.findIndex((el) => el.field === 'credited');
        if (props.dashboard && creditedIndex > -1) {
            const headersCopy = [...HEADERS];
            headersCopy.splice(creditedIndex, 1);
            setHEADERS(headersCopy);
        }
    };

    useEffect(() => {
        removeCreditedOnDashboard();
    }, []);

    removeCreditedOnDashboard();

    useEffect(() => {
        setLeaderboardToDisplay(handleSettingLeaderboardToDisplay());
        setHEADERS(headersPattern);
    }, [props.leaderboard?.length && props.leaderboard[0], page, sortingChanged]);

    const renderTable =
        <div
            className={classNames('LeaderboardTable__list', {
                isPagination: props.leaderboard.length > 6 && !props.dashboard,
            })}
        >
            <Table
                headers={HEADERS}
                data={leaderboardToDisplay}
                totalCount={props.dashboard ? 5 : props.leaderboard.length}
                emptyTable="No members activity in the chosen period"
                onSortChange={(col) => sortChanged(col)}
                currentPage={props.dashboard ? null : page}
                onPageChange={props.dashboard ? null : (page) => setPage(page)}
            />
        </div>
    ;

    const renderEmpty = !props.loading && leaderboardToDisplay.length === 0 &&
        <div className="LeaderboardTable__empty">
            <h2 className="empty">Your team has not performed any action in the chosen period</h2>
        </div>;

    const renderLeaderboard = renderTable || renderEmpty;

    return (
        <>
            {renderLeaderboard}
        </>
    );
};
