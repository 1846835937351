import { action, computed, observable, ObservableMap, runInAction } from 'mobx';
import {
    KeyMetricsStats,
    LoadingState,
    ReviewDate,
    ReviewFilter,
    ReviewRating,
    ReviewRecommendation,
    ReviewReply,
    ReviewsGeneral,
    ReviewSourceValue,
    ReviewStatus,
    ReviewWithReplies,
} from './models';
import { ReviewsApi } from '@src/requests';
import { userSites } from '@src/requests/links.requests';
import { BaseStore } from './base.store';
import ReviewSitesStore from './review-sites.store';
import { addReviewsSite } from '@src/requests/links.requests';
import SocketInstance from './socket-instance';
import { SocketEventType, SocketWrapper } from '@src/api/socket-wrapper';
import { concat } from 'ramda';
import AuthStore from '@src/stores/auth.store';

declare const window: any;

export class ReviewsStore extends BaseStore {
    @observable private _reviews: ReviewWithReplies[];
    @observable private _generalData: ReviewsGeneral;
    @observable private _stats: KeyMetricsStats;
    @observable.struct private _filters: ReviewFilter;
    @observable count: number;
    @observable page: number;
    @observable initLoading: LoadingState;
    @observable goalLoading: LoadingState;
    @observable searchLoading: LoadingState;
    @observable reviewsLoadings: ObservableMap<string, LoadingState>;
    @observable pages = [];
    @observable generalSiteData = null;
    private socketWrapper: SocketWrapper;
    onError: (message: string) => void;

    constructor(socketWrapper: SocketWrapper) {
        super();
        this.socketWrapper = socketWrapper;
    }

    @action setPage = (val: number): void => {
        this.page = val;
    };

    @computed get sources(): boolean[] {
        return Array.from(this._filters.sources.values()).map((s) => s.valueOf());
    }

    @computed get anySites(): boolean {
        return !!this.generalSiteData?.reviewSites?.length || !!ReviewSitesStore.reviewSites.length;
    }

    get filters(): ReviewFilter {
        return this._filters;
    }

    get reviews(): ReviewWithReplies[] {
        return this._reviews;
    }

    get generalData(): ReviewsGeneral {
        return this._generalData;
    }

    get stats(): KeyMetricsStats {
        return this._stats;
    }

    @action init(): void {
        this._reviews = [];
        this._generalData = null;
        this._stats = null;
        this.count = 0;
        this.page = 1;
        this.initLoading = LoadingState.Init;
        this.goalLoading = LoadingState.Init;
        this.searchLoading = LoadingState.Init;
        this.reviewsLoadings = new ObservableMap<string, LoadingState>();
        this.onError = null;
        this._filters = {
            date: observable.box(null),
            customDate: observable.box(null),
            search: observable.box(''),
            rating: observable.box(null),
            recommendation: observable.box(null),
            statuses: observable.box(ReviewStatus.ALL),
            sources: new ObservableMap<ReviewSourceValue, boolean>(),
        };

        if (this.socketWrapper) {
            this.socketWrapper.addEventListener(SocketEventType.AllReviewsStats, (incomingMsg) => {
                this._generalData = incomingMsg;
            });

            this.socketWrapper.addEventListener(SocketEventType.Review, (incomingMsg) => {
                const newReviews = concat(this._reviews, [{ review: incomingMsg }]);
                this.setReviews(newReviews);
            });
        }
    }

    @action destroy(): void {
        this._reviews = [];
        this._generalData = null;
        this._stats = null;
        this.count = 0;
        this.page = 1;
        this.initLoading = LoadingState.Init;
        this.goalLoading = LoadingState.Init;
        this.searchLoading = LoadingState.Init;
        this.reviewsLoadings = new ObservableMap<string, LoadingState>();
        this.pages = [];
        this.generalSiteData = null;
        this.onError = null;
        this._filters = {
            date: observable.box(null),
            customDate: observable.box(null),
            search: observable.box(''),
            rating: observable.box(null),
            recommendation: observable.box(null),
            statuses: observable.box(ReviewStatus.ALL),
            sources: new ObservableMap<ReviewSourceValue, boolean>(),
        };
        if (this.socketWrapper) {
            this.socketWrapper.destroy();
        };
    }

    @action initReviewsCalls(size?: number): void {
        this.initLoading = LoadingState.Loading;
        Promise.all([
            this.fetchReviewGeneralData(),
            this.fetchReviewsStats(),
            this.searchReviews(size),
            this.fetchSiteGeneralData(),
        ]).then(
            () => {
                runInAction(() => {
                    this.initLoading = LoadingState.Loaded;
                });
            },
            (err) => {
                runInAction(() => {
                    if (this.onError) {
                        this.onError(err.message);
                    }
                    this.initLoading = LoadingState.Error;
                });
            }
        );
    }

    @action fetchReviewsStats(): Promise<void> {
        return ReviewsApi.fetchReviewsStats().then(
            (data) => {
                runInAction(() => {
                    this._stats = data;
                });
            },
            (err) => {
                if (err.response?.status === 401) {
                    AuthStore.resetAuth();
                };
            }
        );
    }

    @action fetchReviewGeneralData(): Promise<void> {
        return ReviewsApi.fetchReviewsGeneral().then(
            (res) => {
                runInAction(() => {
                    this.setGeneralData(res);
                });
            },
            (err) => {
                if (err.response?.status === 401) {
                    AuthStore.resetAuth();
                };
            }
        );
    }

    @action fetchSiteGeneralData(): Promise<void> {
        return userSites().then(
            (res) => {
                runInAction(() => {
                    this.generalSiteData = res.data.data;
                });
            },
            (err) => {
                if (err.response?.status === 401) {
                    AuthStore.resetAuth();
                };
            }
        );
    }

    @action searchReviews(size?: number): Promise<void> {
        if (!this.anySites) {
            return;
        }
        this.searchLoading = LoadingState.Loading;
        return ReviewsApi.searchReviews(this.filters, this.page, size).then(
            (res) => {
                runInAction(() => {
                    this.count = res.total;
                    this.setReviews(res.data);
                    // this._reviews = res.data;
                    this.searchLoading = LoadingState.Loaded;
                });
            },
            (err) => {
                runInAction(() => {
                    if (this.onError) {
                        this.onError(err.message);
                    }
                    this.searchLoading = LoadingState.Error;
                });
            }
        );
    }

    @action saveNewGoal(newGoal: number): Promise<void> {
        this.goalLoading = LoadingState.Loading;
        return ReviewsApi.setGoal(newGoal).then(
            () => {
                runInAction(() => {
                    this.goalLoading = LoadingState.Loaded;
                    this._stats = { ...this._stats, reviewGoal: newGoal };
                });
            },
            (err) => {
                runInAction(() => {
                    if (this.onError) {
                        this.onError(err.message);
                    }
                    this.goalLoading = LoadingState.Error;
                });
            }
        );
    }

    @action saveReviewReply(reviewId: string, replyId: string, replyTxt: string): Promise<void> {
        this.setReviewLoadingState(reviewId, LoadingState.Loading);
        const call = replyId
            ? ReviewsApi.updateReviewReply(reviewId, replyId, replyTxt)
            : ReviewsApi.replyToReview(reviewId, replyTxt);

        return call.then(
            (reply) => {
                runInAction(() => {
                    this.updateReviewReply(reviewId, reply);
                    this.setReviewLoadingState(reviewId, LoadingState.Loaded);
                });
            },
            (err) => {
                runInAction(() => {
                    if (this.onError) {
                        this.onError(err.message);
                    }
                    this.reviewsLoadings.set(reviewId, LoadingState.Error);
                });
            }
        );
    }

    @action removeReplyFromReview(replySourceId): void {
        const source = replySourceId.split('_');
        const url = 'https://www.facebook.com/' + source[0] + '?comment_id=' + source[1];
        window.open(url);
    }

    @action setReviewLoadingState(reviewId: string, state: LoadingState): void {
        this.reviewsLoadings.set(reviewId, state);
    }

    @action setReviews(reviews: ReviewWithReplies[]): void {
        this._reviews = reviews;
    }

    @action setReviewDate(date: ReviewDate): void {
        const newDate = date === this._filters.date.get() ? null : date;
        this._filters.date.set(newDate);
        if (!newDate || date !== ReviewDate.CUSTOM) {
            this._filters.customDate.set(null);
        }
        this.searchReviews();
    }

    @action setReviewStatus(status: ReviewStatus): void {
        this.page = 1;

        if (status === ReviewStatus.ALL || this._filters.statuses.get() === status) {
            this._filters.statuses.set(ReviewStatus.ALL);
        } else {
            this._filters.statuses.set(status);
        }

        this.searchReviews();
    }

    @action setReviewSource(source: ReviewSourceValue): void {
        this._filters.sources.set(source, !this._filters.sources.get(source));
        this.searchReviews();
    }

    @action setReviewRating(rating: ReviewRating): void {
        const value = this._filters.rating.get() === rating ? null : rating;
        this._filters.rating.set(value);
        this.searchReviews();
    }

    @action setReviewRecommendation(recommendation: ReviewRecommendation): void {
        const value = this._filters.recommendation.get() === recommendation ? null : recommendation;
        this._filters.recommendation.set(value);
        this.searchReviews();
    }

    @action setFilerSearch(search: string): void {
        this.filters.search.set(search);
        this.searchReviews();
    }

    @action setGeneralData(data: ReviewsGeneral): void {
        this._generalData = data;
    }

    @action removeReviewReply(reviewId: string): void {
        const idx = this._reviews.findIndex((r) => r.review.id === reviewId);
        if (idx < 0) {
            return;
        }

        this._reviews[idx] = {
            ...this._reviews[idx],
            replies: {},
        };
    }

    @action updateReviewReply(reviewId: string, reply: ReviewReply): void {
        const idx = this._reviews.findIndex((r) => r.review.id === reviewId);
        if (idx < 0) {
            return;
        }

        this._reviews[idx] = {
            ...this._reviews[idx],
            replies: {
                ...this._reviews[idx].replies,
                [reply.id]: { reply: reply, replies: [] },
            },
        };
    }

    _gAuthorize = () => {
        window.gapi.auth2.authorize(
            {
                client_id: window._env_.GOOGLE_CLIENT_ID,
                scope: 'https://www.googleapis.com/auth/plus.business.manage',
                response_type: 'id_token permission code',
                include_granted_scopes: true,
                access_type: 'offline',
                prompt: 'consent',
            },
            (callback) => {
                if (!callback.error) {
                    this.initReviewsCalls();
                }
            }
        );
    };

    _fbAuthorize = () => {
        window.FB.login(
            (response) => {
                if (response.authResponse) {
                    window.FB.api(`/${response.authResponse.userID}/accounts`, (res) => {
                        if (res && !res.error) {
                            this.pages = res.data;
                        }
                    });
                }
            },
            {
                scope:
                    'pages_read_user_content,pages_read_engagement,pages_manage_engagement,pages_manage_metadata,pages_messaging',
            }
        );
    };

    @action addSite(site) {
        addReviewsSite(site).then(() => {
            if (site.site === 0) {
                this._gAuthorize();
            } else if (site.site === 1) {
                this._fbAuthorize();
            } else {
                this.initReviewsCalls();
            }
        });
    }
}

export default new ReviewsStore(SocketInstance);
