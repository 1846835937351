import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Loader, Rating } from '@src/components';
import { useOutsideClick } from '@src/hooks';
import { LoadingState, ReviewSource, ReviewWithReplies } from '@src/stores/models';
import { images } from '@src/theme/images';
import { ReactComponent as AttentionIcon } from '@src/theme/icons/attention.svg';
import { ReactComponent as MoreIcon } from '@src/theme/icons/more-selected.svg';

import './review-content.sass';

export interface ReviewContentProps {
    review: ReviewWithReplies;
    className?: string;
    loading?: LoadingState;
    saveReviewReply?: (reviewId: string, replyId: string, replyTxt: string) => Promise<void>;
    removeReviewReply?: (replySourceId: string) => void;
}

export const ReviewContent: React.FC<ReviewContentProps> = (props) => {
    const review = props.review?.review;
    const reviewReply = props.review?.replies ? Object.values(props.review.replies)[0] : null;
    const hasReplies = !!reviewReply;
    const isGoogle = review.source.name === ReviewSource.GOOGLE;
    const attention = isGoogle ? review.rating < 3 : review.recommendationType === 'negative';

    const [showReplyInput, setReplyInputState] = useState(false);
    const [showMenu, setShowMenuState] = useState(false);
    const [replyTxt, setReplyText] = useState(reviewReply?.reply?.comment || '');

    const menuRef = React.createRef<HTMLDivElement>();

    useOutsideClick(menuRef, () => setShowMenuState(false));

    const editReply = (): void => {
        setReplyInputState(true);
        setShowMenuState(false);
    };

    const removeReply = (): void => {
        setShowMenuState(false);
        if (props.removeReviewReply) {
            props.removeReviewReply(reviewReply.reply.sourceId);
        }
    };

    const saveReply = (): void => {
        if (!replyTxt || !props.saveReviewReply) {
            return;
        }

        props.saveReviewReply(review.id, reviewReply?.reply?.id, replyTxt).then(() => {
            setReplyInputState(false);
            setShowMenuState(false);
        });
    };

    const btnCls = classNames({ 'attention-btn': attention });
    const menuCls = classNames({ 'action-menu': true, show: showMenu });

    return (
        <div className="ReviewContent">
            <Loader loadingState={props?.loading} />
            {attention && (
                <div className="attention">
                    <AttentionIcon />
                    <h6>Attention</h6>
                </div>
            )}
            <div className="source">
                <div>
                    {isGoogle ? <img src={images.googleCircle} alt="source logo" /> : null}
                    <Rating review={review} />
                </div>
                <div>{moment(review.reviewCreated).format('MMMM DD YYYY')}</div>
            </div>
            <h4>{review.displayName}</h4>
            <div className="review-content">
                <p>{review.comment}</p>
                {!hasReplies && !showReplyInput && (
                    <button className={btnCls} onClick={() => setReplyInputState(true)}>
                        Reply
                    </button>
                )}
            </div>
            {!showReplyInput ? null : (
                <div className="reply">
                    <input
                        type="text"
                        placeholder="Write a comment"
                        value={replyTxt}
                        onChange={(e) => setReplyText(e.target.value)}
                    />
                    <button onClick={() => saveReply()}>Submit</button>
                </div>
            )}
            {!hasReplies || showReplyInput ? null : (
                <div className="comment">
                    <div className="border" />
                    <div>
                        <div className="comment-header">
                            <h5>{reviewReply?.reply.displayName}</h5>
                            <button onClick={() => setShowMenuState(!showMenu)}>
                                <MoreIcon />
                            </button>
                            <div className="menu-hook" ref={menuRef}>
                                <div className={menuCls}>
                                    <button onClick={() => editReply()}>
                                        <img src={images.editPencil} alt="edit reply" />
                                        <span>Edit</span>
                                    </button>
                                    <button onClick={() => removeReply()}>
                                        <img src={images.trash} alt="remove reply" />
                                        <span>Remove</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <p>{reviewReply?.reply.comment}</p>
                    </div>
                </div>
            )}
        </div>
    );
};
