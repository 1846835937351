import { useEffect, useState } from 'react';
import { LoadingState } from '@src/stores/models';

export const useLoading = (value: LoadingState) => {
    const [loading, setLoading] = useState<LoadingState>(LoadingState.Init);
    useEffect(() => {
        setLoading(value);
    }, [value]);
    return loading;
};
