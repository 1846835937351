import { observable, action, runInAction } from 'mobx';
import moment from 'moment';
import {
    BillingAccount,
    Card,
    Subscription,
    BillingPlanOnList,
    SingleBillingPlan,
    Contract,
    Billing,
    LoadingState,
    OnErrorDataBilling,
    ActivePlan,
} from './models';
import {
    getBilling,
    updateBillingEmail,
    manageSubscription as manageSubscriptionReq,
    changeSubscription,
    cancelTrial,
    updateBillingPayment,
    getActivePlans
} from '@src/requests';
import AuthStore from '@src/stores/auth.store';
import { CouponOffer } from './coupon.store';
import { parseISO } from 'date-fns';

export class BillingStore {
    @observable activePlans: ActivePlan[];
    @observable billingAccount: BillingAccount = {};
    @observable currentSubscription: Subscription = {};
    @observable currentPlan: SingleBillingPlan = null;
    @observable billingPlans: BillingPlanOnList[] = [];
    @observable stripeKey: string = null;
    @observable contract: Contract = {};
    @observable problemCCExpiresSoon: Card = null;
    @observable problemCCExpired: Card = null;
    @observable problemStatus: string = null;
    @observable redeemedCoupons: CouponOffer[] = [];
    @observable dataLoaded = false;
    @observable loadingState: LoadingState = LoadingState.Init;
    @observable isViewBilling = true;
    private _onError: (type: string, data?: OnErrorDataBilling) => void;
    private _onSuccess: () => void;
    private _onSuccessCancelTrial: () => void;
    private _onSuccessDismissOtherToasts: () => void;

    set onSuccess(fn: () => void) {
        this._onSuccess = fn;
    }
    set onSuccessCancelTrial(fn: () => void) {
        this._onSuccessCancelTrial = fn;
    }
    set onSuccessDismissOtherToasts(fn: () => void) {
        this._onSuccessDismissOtherToasts = fn;
    }
    set onError(fn: (type: string, data?: OnErrorDataBilling) => void) {
        this._onError = fn;
    }

    @action init(): void {
        this.activePlans = [];
        this.billingAccount = {};
        this.currentSubscription = {};
        this.currentPlan = null;
        this.billingPlans = [];
        this.stripeKey = null;
        this.contract = {};
        this.problemCCExpiresSoon = null;
        this.problemCCExpired = null;
        this.problemStatus = null;
        this.redeemedCoupons = [];
        this.dataLoaded = false;
        this.loadingState = LoadingState.Init;
        this.isViewBilling = true;
    }

    @action setBilling = (billing: Billing): void => {
        this.billingAccount = billing.billingAccount;
        this.currentSubscription = billing.subscription;
        this.currentPlan = billing.billingPlan;
        this.billingPlans = billing.plans;
        this.redeemedCoupons = billing.redeemedCoupons.map(offer => ({
            ...offer,
            coupon: {
                ...offer.coupon,
                created: parseISO(offer.coupon.created),
                redeemBy: offer.coupon.redeemBy ? parseISO(offer.coupon.redeemBy) : null,
                updated: parseISO(offer.coupon.updated)
            },
            created: parseISO(offer.created),
            offerExpires: offer.offerExpires? parseISO(offer.offerExpires) : null,
            updated: parseISO(offer.updated),
            // TODO: remove this prop
            redeemBefore: parseISO(offer.offerExpires)
        }));
        this.stripeKey = billing.stripeKey;
        this.contract = billing.contract ? billing.contract : undefined;
        this.problemStatus = null;
    };

    @action setIsViewBilling = (val: boolean): void => {
        this.isViewBilling = val;
    };

    @action setProblemsInfo = (billing: Billing): void => {
        const ccExpiresSoon = Boolean(
            billing.billingAccount?.card?.exp_year === parseInt(moment().format('YYYY'), 10) &&
                billing.billingAccount?.card?.exp_month === parseInt(moment().format('M'), 10)
        )
        const ccExpired = Boolean(
            billing.billingAccount?.card?.exp_year < parseInt(moment().format('YYYY'), 10) ||
                (billing.billingAccount?.card?.exp_year === parseInt(moment().format('YYYY'), 10) &&
                    billing.billingAccount?.card?.exp_month < parseInt(moment().format('M'), 10))
            )
        const paymentFailedOrPastDue = billing.subscription?.details?.status === 'unpaid' || billing.subscription?.details?.status === 'past_due'

        if (ccExpiresSoon) {
            this.problemCCExpiresSoon = billing.billingAccount.card;
        };
        if (ccExpired) {
            this.problemCCExpired = billing.billingAccount?.card;
        };
        if (
            // billing.subscription?.details?.status === 'canceled' ||
            paymentFailedOrPastDue
        ) {
            this.problemStatus = billing.subscription?.details?.status;
        };
    };

    @action getActivePlans = (errAction?: string, firstLoading = false) => {
        this.loadingState = firstLoading ? LoadingState.FirstLoading : LoadingState.Loading;
        return getActivePlans().then(
            (res) => {
                runInAction(() => {
                    this.activePlans = res.data.filter((el: ActivePlan) => !el.billingPlan.billingPlan.trialDays);
                    this.dataLoaded = true;
                    this.loadingState = LoadingState.Loaded;
                });
            },
            (err) => {
                runInAction(() => {
                    if (err.response.status === 401) {
                        AuthStore.resetAuth();
                    } else {
                        if (this._onError && errAction) {
                            this._onError(errAction);
                        };
                        this.loadingState = LoadingState.Error;
                    };
                });
            }
        );
    };

    @action getAndSetBilling = (errAction?: string, firstLoading = false) => {
        this.loadingState = firstLoading ? LoadingState.FirstLoading : LoadingState.Loading;
        return getBilling().then(
            ({ data: billing }) => {
                runInAction(() => {
                    errAction ? this.setBilling(billing) : this.setProblemsInfo(billing);
                    this.dataLoaded = true;
                    this.loadingState = LoadingState.Loaded;
                });
                return billing;
            },
            (err) => {
                runInAction(() => {
                    if (err.response.status === 401) {
                        AuthStore.resetAuth();
                    } else {
                        if (this._onError && errAction) {
                            this._onError(errAction);
                        };
                        this.loadingState = LoadingState.Error;
                    };
                });
            }
        );
    };

    @action editBillingEmail = (billingEmail: string, errAction: string) => {
        this.loadingState = LoadingState.Loading;
        return updateBillingEmail(billingEmail).then(
            () => {
                runInAction(() => {
                    this.loadingState = LoadingState.Loaded;
                    if (this._onSuccess) {
                        this._onSuccess();
                    };
                });
                return Promise.all([
                    this.getAndSetBilling('getBE'),
                    AuthStore.updateAuth(),
                ]);
            },
            () => {
                runInAction(() => {
                    this.loadingState = LoadingState.Error;
                    if (this._onError) {
                        this._onError(errAction, { billingEmail });
                    };
                });
            }
        );
    };

    @action manageSubscription = (
        action: string,
        subType: string,
        currentSubscriptionId: string,
        errAction: string,
        stripeToken?: string,
        noOtherRequestsInStore?: boolean
    ) => {
        this.loadingState = LoadingState.Loading;
        return manageSubscriptionReq(action, subType, currentSubscriptionId, stripeToken).then(
            () => {
                runInAction(() => {
                    this.loadingState = LoadingState.Loaded;
                    if (this._onSuccessDismissOtherToasts) {
                        this._onSuccessDismissOtherToasts();
                    };
                });
                if (!noOtherRequestsInStore) {
                    return Promise.all([
                        this.getAndSetBilling('getBE'),
                        AuthStore.updateAuth(),
                    ])
                    // .then(() => {
                    //     if (action === 'reactivate') {
                    //         runInAction(() => {
                    //             AuthStore.setAccountStatus('ACTIVE');
                    //         })
                    //     }
                    // })
                };
            },
            () => {
                runInAction(() => {
                    this.loadingState = LoadingState.Error;
                    if (this._onError) {
                        this._onError(errAction, { action, currentSubscriptionId });
                    };
                });
            }
        );
    };

    @action changeSubscription = (
        currentSubscriptionId: string,
        newPriceId: string,
        errAction: string
    ) => {
        this.loadingState = LoadingState.Loading;
        return changeSubscription(currentSubscriptionId, newPriceId).then(
            () => {
                runInAction(() => {
                    this.loadingState = LoadingState.Loaded;
                    if (this._onSuccess) {
                        this._onSuccess();
                    };
                });
                return Promise.all([
                    this.getAndSetBilling('getBE'),
                    AuthStore.updateAuth(),
                ]);
            },
            () => {
                runInAction(() => {
                    this.loadingState = LoadingState.Error;
                    if (this._onError) {
                        this._onError(errAction, { currentSubscriptionId, newPriceId });
                    };
                })
                return 'fail';
            }
        );
    };

    @action updateBillingPayment = (stripeToken: string, isFinalRequest?: boolean) => {
        this.loadingState = LoadingState.Loading;
        return updateBillingPayment(stripeToken)
        .then(() => {
            runInAction(() => {
                this.loadingState = LoadingState.Loaded;
            });
            if (this._onSuccessDismissOtherToasts && isFinalRequest) {
                this._onSuccessDismissOtherToasts();
                return AuthStore.updateAuth()
                // .then(() => {
                //     runInAction(() => {
                //         AuthStore.setAccountStatus('ACTIVE');
                //     })
                // })
            };
        },
        () => {
            runInAction(() => {
                this.loadingState = LoadingState.Error;
            });
        });
    };

    @action cancelTrial = (errAction: string) => {
        this.loadingState = LoadingState.Loading;
        return cancelTrial().then(
            () => {
                runInAction(() => {
                    if (this._onSuccessCancelTrial) {
                        this._onSuccessCancelTrial();
                    };
                    this.loadingState = LoadingState.Loaded;
                });
                return Promise.all([
                    this.getAndSetBilling('getBE'),
                    AuthStore.updateAuth(),
                ])
                // .then(() => {
                //     runInAction(() => {
                //         AuthStore.setAccountStatus('ACTIVE');
                //     })
                // });
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction);
                    };
                    this.loadingState = LoadingState.Error;
                });
            }
        );
    };
}

export default new BillingStore();
