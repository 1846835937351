import api from '@src/api/api';

export const getHintPopupSteps = () => {
    return api.get('/onboarding');
};

export const markHintPopupStepAsRead = (step: number) => {
    return api.post('/markstep', { step });
};

export const hideAllHintPopups = () => {
    return api.post('/hide');
};