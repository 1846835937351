import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { equals } from 'ramda';

import './FormFields.sass';
import { FieldError, FieldValues, NestDataObject } from 'react-hook-form';

interface FormFieldProps {
    label?: string;
    required?: boolean;
    htmlFor?: string;
    classStyling?: string;
    error?: NestDataObject<FieldValues> | NestDataObject<FieldValues>[]| FieldError | FieldError[];
}

export const FormField: React.FunctionComponent<FormFieldProps> = (props) => {
    const { children, label = '', required, htmlFor, classStyling = '' } = props;
    const [error, setError] = useState(props.error ? props.error : null);

    useEffect(() => {
        if (!equals(error, props.error)) {
            setError(props.error);
        }
    });

    return (
        <div className={classNames('form-wrapper', classStyling)}>
            {label && (
                <label className="form-label" htmlFor={htmlFor}>
                    <span>
                        {label}
                        {required ? <span className="required-mark">*</span> : null}
                    </span>
                    {error && <p className="form-error">{Array.isArray(error) ? error[0].message : error.message}</p>}
                </label>
            )}
            {children}
        </div>
    );
};

const CheckboxHook = (props, ref) => {
    return (
        <label className="form-checkbox-container">
            {props.label && <span className="form-checkbox-label">{props.label}</span>}
            <input
                type="checkbox"
                name={props.name}
                defaultChecked={props.defaultValue}
                value={props.value}
                ref={ref}
                onChange={(e) => props.onChange(e)}
            />
            <span className="form-checkbox-checkmark" />
        </label>
    );
};

export const CheckboxField = React.forwardRef(CheckboxHook);

const CheckboxHookPreventDefault = (props, ref) => {
    return (
        <label className="form-checkbox-container">
            {props.label && <span className="form-checkbox-label">{props.label}</span>}
            <input
                type="checkbox"
                name={props.name}
                defaultChecked={props.defaultValue}
                value={props.value}
                ref={ref}
                onClick={(e) => e.preventDefault()}
            />
            <span className="form-checkbox-checkmark" />
        </label>
    );
};

export const CheckboxFieldPreventDefault = React.forwardRef(CheckboxHookPreventDefault);

// export const Checkbox = (props) => {
//     return (
//         <label className="form-checkbox-container">
//             <input
//                 type="checkbox"
//                 onChange={(e) => props.onChange(e)}
//                 defaultChecked={props.defaultValue}
//                 value={props.value}
//             />
//             <span className="form-checkbox-checkmark" />
//         </label>
//     );
// };
