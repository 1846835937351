import api from '@src/api/api';

export const postLogin = (data) => {
    return api.post('/login', data);
};

export const forgotPwd = (email) => {
    return api.post('/user/password/forgot', email);
};

export const resetPwd = (data) => {
    return api.post(`/user/password/reset/${data.token}`, data.password);
};

export const confirmEmail = (token) => {
    return api.get(`/account/confirm/${token}`);
};

export const activateAccount = (token, data) => {
    return api.post(`/account/${token}`, data);
};

export const authorizeOAuthApp = (data) => {
    return api.post('/oauth/authorize', data);
}