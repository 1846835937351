import { useEffect } from 'react';

export const useOutsideClick = (ref, fn: () => void): void => {
    const handleClickOutside = (ev) => {
        if (ref.current && !ref.current.contains(ev.target)) {
            fn();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return (): void => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });
};
