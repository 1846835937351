import React, { useState, useEffect } from 'react';
import { AsYouType } from 'libphonenumber-js';
import { Dialog } from '@src/components';
import { NewNumber2 } from './NewNumber2';
import { withStores, STORES, WithSignupStoreProps } from '@src/stores/with-store';
import { images } from '@src/theme/images';
import './Signup.sass';

interface PhoneSelectionProps {
    state: string;
    onNext: (selectedPhone: string) => void;
    onBack: () => void;
    changedContinueBtn?: boolean;
};

type props = PhoneSelectionProps & WithSignupStoreProps

export const PhoneSelectionWithStore: React.FC<props> = ({SignupStore, onNext, onBack, state, changedContinueBtn}) => {
    const [ selectedPhone, setSelectedPhone ] = useState('');
    const [ showSelectPhoneDialog, setShowSelectPhoneDialog ] = useState(false);

    const getNumbers = () => {
        SignupStore.getNumbers({areaCode: null, regionCode: state})
        .then(() => {
            if (SignupStore.numbersToSelect.length !== 0) {
                setSelectedPhone(SignupStore.numbersToSelect[0]);
            } else {
                SignupStore.getNumbers({areaCode: null, regionCode: null})
                .then(() => {
                    if (SignupStore.numbersToSelect.length !== 0) {
                        setSelectedPhone(SignupStore.numbersToSelect[0]);
                    };
                });
            };
        });
    };

    useEffect(() => {
        getNumbers();
    }, []);

    const onNewNumber = (phoneNumber: string): void => {
        setShowSelectPhoneDialog(false);
        setSelectedPhone(phoneNumber);
    };

    return (
        <div className="step-card">
            <h2>Your own new number is included...</h2>
            <p className="subtitle AuthContainer__subtitle">This lets you send & receive business messages without compromising your personal number. Cool, huh?</p>
            <div className="selectedPhoneWrapper">
                { SignupStore.numbersToSelect.length ?
                    <div className="selectedPhone">
                        <img src={images.phoneIcon2} alt="phone" />
                        {new AsYouType('US').input(('' + selectedPhone).replace(/\D/g, ''))}
                    </div>
                    : null
                }
            </div>
            <div
                className="pickOwn btn-tetriary-blue"
                onClick={() => setShowSelectPhoneDialog(true)}
            >
                I want to pick my own number
            </div>
            <div className="form-footer">
                <button
                    className="btn-tetriary-blue"
                    onClick={() => onBack()}
                >
                    Back
                </button>
                <button
                    className="btn-primary-full nextfromphone"
                    disabled={!selectedPhone}
                    onClick={() => onNext(selectedPhone)}
                    key="nextfromphone"
                >
                    {changedContinueBtn ? 'Complete trial signup' : 'Continue'}
                </button>
            </div>
            <Dialog
                onClose={() => setShowSelectPhoneDialog(false)}
                open={showSelectPhoneDialog}
                className="setupSignupPhoneNumberDialog"
                noShadowBehind
            >
                <NewNumber2
                    closeDialog={() => setShowSelectPhoneDialog(false)}
                    onNumberSelection={onNewNumber}
                />
            </Dialog>
        </div>
    );
};

export const PhoneSelection = withStores(PhoneSelectionWithStore, [STORES.Signup]);