import React, { useState, useEffect, useLayoutEffect } from 'react';
import { toast } from 'react-toastify';
import { LeaderboardTable } from '@src/components/LeaderboardTable/LeaderboardTable';
import { Loader, SortColumn, ErrorToastDownload, Dropdown } from '@src/components';
import { LoadingState } from '@src/stores/models';
import { withLeaderboardStore, WithLeaderboardStoreProps } from '@src/stores/with-store';

import './leaderboard.sass';

const LeaderboardWithStore: React.FC<WithLeaderboardStoreProps> = (
    props: WithLeaderboardStoreProps
) => {
    const store = props.LeaderboardStore;
    const loading = store.loadingState;
    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const [leaderboardTable, setLeaderboardTable] = useState(store.leaderboard);
    const [sortCol, setSortCol] = useState<SortColumn>(null);
    const timePeriods = [7, 14, 30, 60, 120];
    const selectedPeriodNumber = 0;

    store.onError = (type: string, days: number) => {
        store.loadingState = LoadingState.Error;
        switch (type) {
            case 'getBE': {
                toast.error(
                    <ErrorToastDownload getBEData={() => store.getLeaderboard(days, 'getBE')} />
                );
                break;
            }
            default: {
                return;
            }
        }
    };

    const getLeaderboardStats = (days: number) => {
        store.getLeaderboard(days, 'getBE', isFirstLoading);
        setIsFirstLoading(false);
    };

    const periodSelected = (i: number) => {
        getLeaderboardStats(timePeriods[i]);
    };

    useEffect(() => {
        if (sortCol) {
            if (sortCol.col === 'avgResponseTime') {
                setLeaderboardTable(
                    leaderboardTable.slice().sort((a, b) => {
                        if (sortCol.dir === 'asc')
                            return a[sortCol.col] < b[sortCol.col] || !a[sortCol.col] ? 1 : -1; // with !a[sortCol.col] here user with no avgResponseTime is always on bottom; if they should be on top when sorting from longest avgResponseTime, put !b[sortCol.col] instead of !a[sortCol.col]
                        if (sortCol.dir === 'desc')
                            return a[sortCol.col] > b[sortCol.col] || !a[sortCol.col] ? 1 : -1;
                    })
                );
            } else {
                setLeaderboardTable(
                    leaderboardTable.slice().sort((a, b) => {
                        if (sortCol.dir === 'asc')
                            return a[sortCol.col] < b[sortCol.col] || !a[sortCol.col] ? 1 : -1;
                        if (sortCol.dir === 'desc')
                            return a[sortCol.col] > b[sortCol.col] || !b[sortCol.col] ? 1 : -1;
                    })
                );
            };
        };
    }, [sortCol]);

    useEffect(() => {
        setLeaderboardTable(store.leaderboard);
    }, [store.leaderboard]);

    useEffect(() => {
        getLeaderboardStats(7);
    }, []);

    useLayoutEffect(() => {
        store.init();
    }, []);

    return (
        <div className="Leaderboard card">
            <Loader loadingState={loading} />
            <>
                {loading !== LoadingState.Init && loading !== LoadingState.FirstLoading && (
                    <>
                        <div className="Leaderboard__topRow">
                            <h3>Top Performers</h3>
                            <Dropdown
                                optionsArr={timePeriods.map((el) => `last ${el} days`)}
                                selOptionNumber={selectedPeriodNumber}
                                darkColor={false}
                                optionSelected={(i) => periodSelected(i)}
                            />
                        </div>
                        <div className="Leaderboard__content">
                            <LeaderboardTable
                                leaderboard={leaderboardTable}
                                sortChanged={(col) => setSortCol(col)}
                            />
                        </div>
                    </>
                )}
            </>
        </div>
    );
};

export const Leaderboard = withLeaderboardStore(LeaderboardWithStore);
