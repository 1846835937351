import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { uniqWith } from 'ramda';
import { CustomersApi } from '@src/requests';
import { displayNameOrPhone, formatDate } from '@src/theme/utils/helpers';
import { Loader } from '@src/components';
import { useLoading } from '@src/hooks';
import { ThreadSearchResult, LoadingState } from '@src/stores/models';
import {
    STORES,
    withStores,
    WithConversationsStoreProps,
    WithCustomerStoreProps,
} from '@src/stores/with-store';
import { ThreadsStore } from '@src/stores/messages/threads.store';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';

import './thread-list.sass';

interface ThreadListProps {
    toBeMarkAsViewed: () => void;
}

type props = ThreadListProps &
    WithCustomerStoreProps &
    WithConversationsStoreProps & { ThreadsStore: ThreadsStore };

const ThreadListWithStore: React.FC<props> = (props) => {
    const ThreadsStore = props.ThreadsStore;
    const { search, searchResults } = ThreadsStore;
    const history = useHistory();
    const openCls = classNames({ selected: ThreadsStore.open });
    const closeCls = classNames({ selected: ThreadsStore.open == false });
    const resultHeaderCls = classNames({ 'no-results': !searchResults?.length });
    const loading = useLoading(ThreadsStore.loading);
    const [showThreads] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [clickedCustomer, setClickedCustomer] = useState('');

    useEffect(() => {
        setLoadingMore(false);
    }, [ThreadsStore.pageThreads]);

    const selectThreadByThreadId = (id: string) => {
        Promise.all([
            ThreadsStore.closeCurrentThread(),
            props.ConversationsStore.SelectedChannelStore.closeConversation(),
            props.ConversationsStore.SelectedDirectConversationStore.closeConversation(),
            props.CustomerStore.closeCustomer(),
        ]).then(() => {
            ThreadsStore.selectThreadByThreadId(id).then(() => {
                history.replace({ pathname: `/messages/t/${id}` });
            });
        });
    };

    const renderThread = (threadResult: ThreadSearchResult) => {
        const unanswered = threadResult.thread && !threadResult.thread?.read;
        const cls = classNames({
            'thread-row': true,
            unanswered: unanswered,
            selected:
                (threadResult.thread &&
                    threadResult.thread?.id === ThreadsStore.currentThread?.id) ||
                threadResult.customer?.id === clickedCustomer,
        });
        return (
            <div
                className={cls}
                onClick={() =>
                    threadResult.thread?.id
                        ? selectThreadByThreadId(threadResult.thread.id)
                        : CustomersApi.fetchCustomer(threadResult.customer.id).then(
                              (customerData) => {
                                  ThreadsStore.createNewThread(customerData.customer, true).then(
                                      () => {
                                          history.replace({ pathname: `/messages/?new` });
                                          setClickedCustomer(threadResult.customer.id);
                                      }
                                  );
                              }
                          )
                }
            >
                {unanswered ? <div className="dot" /> : null}
                <div className="thread-header">
                    <h4>{displayNameOrPhone(threadResult.customer)}</h4>
                    <span>{formatDate(threadResult?.thread?.updated)}</span>
                </div>
                <div className="thread-spoiler">
                    <p> {threadResult?.message?.text}</p>
                </div>
            </div>
        );
    };

    const emptyView = (
        <div className="empty">
            <h2>{search ? 'Try a different search' : 'You currently have no messages'}</h2>
            {!search ? <h4>Send a message using the form to the right</h4> : null}
        </div>
    );

    const loadMoreFunc = () => {
        if (!loadingMore && ThreadsStore.loadMoreThreads) {
            setLoadingMore(true);
            ThreadsStore.searchThreads({
                search: ThreadsStore.search,
                feed: ThreadsStore.feed,
                open: ThreadsStore.open,
                tags: ThreadsStore.tags,
                loadMore: true,
                scroll: true,
            });
        }
    };

    const searchThreads = (val: boolean) => {
        if (ThreadsStore.open !== val) ThreadsStore.searchThreads({ open: val });
    };

    const items = () => {
        const customersWithoutThreads: ThreadSearchResult[] = ThreadsStore.searchResults.filter(
            (el) => !el.thread
        );
        const uniqueThreads: ThreadSearchResult[] = uniqWith(
            (el1: ThreadSearchResult, el2: ThreadSearchResult) => el1.thread?.id === el2.thread?.id,
            ThreadsStore.searchResults
        );

        const listToDisplay = [...customersWithoutThreads, ...uniqueThreads];
        return listToDisplay.map((thObj: ThreadSearchResult, i: number) => (
            <React.Fragment key={thObj.thread?.id || i}>{renderThread(thObj)}</React.Fragment>
        ));
    };

    return (
        <div className="ThreadList">
            <InfiniteScroll
                pageStart={0}
                loadMore={loadMoreFunc}
                initialLoad={false}
                hasMore={ThreadsStore.loadMoreThreads}
                useWindow={false}
                threshold={100}
            >
                <>
                    <Loader loadingState={loading} />
                    <section>
                        {ThreadsStore.search && (
                            <h5 className={resultHeaderCls}>{searchResults?.length} Results </h5>
                        )}
                        {!search && (
                            <div className="btn-group">
                                <button className={openCls} onClick={() => searchThreads(true)}>
                                    Open
                                </button>
                                <button className={closeCls} onClick={() => searchThreads(false)}>
                                    Closed
                                </button>
                            </div>
                        )}
                        {loading === LoadingState.Loaded && !searchResults.length
                            ? emptyView
                            : null}
                        <div className={classNames('threads', { show: showThreads })}>
                            {items()}
                            {ThreadsStore.loadMoreThreads && !!items().length && (
                                <div key={0}>Loading...</div>
                            )}
                        </div>
                    </section>
                </>
            </InfiniteScroll>
        </div>
    );
};

export const ThreadList = withStores(ThreadListWithStore, [
    STORES.ThreadsStore,
    STORES.ConversationsStore,
    STORES.Customer,
]);
