import api from '@src/api/api';
import {
    ConversationData,
    ConversationList,
    TeamMessage,
    ConversationDetails,
    TeamMessages
} from "@src/stores/models/team-chat";

export const getConversations = (page = 1): Promise<ConversationList> => {
    return api.get(`/conversations?page=${page}`)
        .then((res) => res.data );
};

export const getChannels = (page = 1): Promise<ConversationList> => { // not used?
    return api.get(`/conversations?page=${page}&conversationType=CHANNEL`)
        .then((res) => res.data );
};

export const getDirectConversations = (page = 1): Promise<ConversationList> => { // not used?
    return api.get(`/conversations?page=${page}&conversationType=DIRECT`)
        .then((res) => res.data );
};

export const getConversation = (id: string): Promise<ConversationDetails> => {
    return api.get(`/conversations/${id}`)
        .then((res) => res.data );
};

export const getMessages = (id: string, page: number, time: bigint): Promise<TeamMessages> => {
    return api.get(`/conversations/${id}/messages?page=${page}&time=${time}`)
        .then((res) => res.data.messages );
};

export const startDirectConversation = (participant: string): Promise<ConversationData> => {
    return api.post('/conversations/new', { type: "DIRECT", participants: [participant] })
        .then((res) => res.data );
};

export const startChannel = (name: string, participants = []): Promise<ConversationData> => {
    return api.post('/conversations/new', { type: "CHANNEL", name, participants })
        .then((res) => res.data );
};

export const joinChannel = (channelId: string): Promise<ConversationData> => {
    return api.post('/conversations/join', { channelId })
        .then((res) => res.data );
};

export const leaveChannel = (channelId: string): Promise<TeamMessage> => {
    return api.post('/conversations/leave', { channelId })
        .then((res) => res.data.message );
};

export const addUsersToChannel = (channelId: string, participants: string[]): Promise<ConversationData> => {
    return api.post('/conversations/add', { channelId, participants })
        .then((res) => res.data );
};

export const removeUsersFromChannel = (channelId: string, participants: string[]): Promise<TeamMessage> => {
    return api.post('/conversations/remove', { channelId, participants })
        .then((res) => res.data.message );
};

export const sendMessage = (message: any): Promise<TeamMessage> => {
    return api.post('/conversations/message', message)
        .then((res) => res.data.message );
};

export const editMessage = (id: string, message: string): Promise<TeamMessage> => {
    return api.post('/conversations/message/edit', { id, message })
        .then((res) => res.data.message );
};

export const deleteMessage = (messageId: string, conversationId: string): Promise<any> => {
    return api.delete(`/conversations/message`, { data: { messageId, conversationId } })
        .then((res) => res.data.message );
};

export const editChannelName = (channelId: string, name: string): Promise<TeamMessage> => {
    return api.post('/conversations/edit', { channelId, name })
        .then((res) => res.data.message );
};

export const deleteConversation = (id: string): Promise<TeamMessage> => {
    return api.delete(`/conversations/${id}`)
        .then((res) => res.data.message );
};

export const markAsRead = (channelId: string, time: number): Promise<TeamMessage> => {
    return api.put(`/conversations/markread`, { channelId, time })
        .then((res) => res.data.message );
};
