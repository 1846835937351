import React from 'react';
import classNames from 'classnames';

interface FooterProps {
    isAddPaymentAndFinishTrial: boolean;
    isAddPaymentOnSignup: boolean;
    isTrialPlanOnSignup: boolean;
    trialDays: number;
};

const Footer: React.FunctionComponent<FooterProps> = ({ isAddPaymentAndFinishTrial, isAddPaymentOnSignup, isTrialPlanOnSignup, trialDays }) => {
    return (
        <div className={classNames('PaymentInsideStripe__consents', {narrow : isAddPaymentAndFinishTrial || isAddPaymentOnSignup})}>
            <div className="itemBold">By clicking {(isAddPaymentOnSignup && isTrialPlanOnSignup && trialDays) ? '"Complete trial signup"' : '"Confirm"'} you agree to our <a
            // <div className="itemBold">By clicking {isAddPaymentOnSignup ? '"Complete signup"' : '"Confirm"'} you agree to our <a
                href="https://www.myopolis.com/terms-of-use/"
                rel="noopener noreferrer" target="_blank"
                className="termsLink"
            >terms of service</a>, <a
                href="https://www.myopolis.com/privacy-policy/"
                rel="noopener noreferrer" target="_blank"
                className="termsLink"
            >privacy policy</a>, terms and conditions.</div>
            {isTrialPlanOnSignup ?
                <div className="itemNotBold">The free trial is for {trialDays} days - you will NOT be charged if canceled prior to the end of your trial period.</div>
                : null
            }
            <div className="itemNotBold">By providing a valid credit or debit card, you are authorizing service charges monthly or annually, including recurring payments billed on monthly or annual basis depending on the plan you have selected.</div>
        </div>
    )
};

export default Footer;
