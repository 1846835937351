import { observable, action, runInAction } from 'mobx';
import { BillingAccount, Invoice, LoadingState } from './models';
import { getInvoices } from '@src/requests';
import AuthStore from '@src/stores/auth.store';

export class InvoicesStore {
    @observable billingAccount: BillingAccount = {};
    @observable invoices: Invoice[] = [];
    @observable dataLoaded = false;
    @observable loadingState: LoadingState = LoadingState.Init;
    private _onError: (type: string) => void;

    set onError(fn: (type: string) => void) {
        this._onError = fn;
    }

    @action init(): void {
        this.billingAccount = {};
        this.invoices = [];
        this.dataLoaded = false;
        this.loadingState = LoadingState.Init;
    }

    @action getInvoices = (errAction: string, firstLoading = false) => {
        this.loadingState = firstLoading ? LoadingState.FirstLoading : LoadingState.Loading;
        getInvoices().then(
            ({ data }) => {
                runInAction(() => {
                    const { invoices, billingAccount } = data;
                    this.invoices = invoices.data;
                    this.billingAccount = billingAccount;
                    this.dataLoaded = true;
                    this.loadingState = LoadingState.Loaded;
                });
            },
            (err) => {
                if (err.response.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    if (this._onError) {
                        this._onError(errAction);
                    };
                };
            }
        );
    };
}

export default new InvoicesStore();
