import { observable, action } from 'mobx';
import EditedTagStore from './editedTag.store';
import TagsListStore from './tagsList.store';
import { BaseStore } from '@src/stores/base.store';

export class TagsStore extends BaseStore {
    @observable EditedTagStore;
    @observable TagsListStore;

    constructor() {
        super();
        this.EditedTagStore = new EditedTagStore(this);
        this.TagsListStore = new TagsListStore(this);
    }

    @action init(): void {
        this.EditedTagStore?.init();
        this.TagsListStore?.init();
    }
}

export default new TagsStore();
