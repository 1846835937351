import React from 'react';
import './ProgressBar.sass';

interface ProgressBarProps {
    title?: string;
    caption?: string;
    percent?: number;
} 

const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({ title, caption, percent }) => (
    <div className="_progress-bar-container">
        <h5>{title}</h5>
        <div className="progress-bar">
            <div style={{width: `${percent || 0}%`}}/>
        </div>
        <span>{caption}</span>
    </div>
);

export default ProgressBar;
