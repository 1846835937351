import React, { useLayoutEffect } from 'react';
import {
    NavLink,
    Redirect,
    Route,
    Switch,
    useRouteMatch,
    useLocation,
    useHistory,
} from 'react-router-dom';
import { settingsPages } from '@src/pages/pages';
import { Dropdown } from '@src/components';
import {
    STORES,
    withStores,
    WithMessagesStoreProps,
    WithBillingStoreProps,
    WithAuthStoreProps
} from '@src/stores/with-store';

import './settings.sass';

const LINKS = [
    '/account',
    '/team',
    '/billing',
    '/phone-number',
    '/invoices',
    '/coupons',
    '/reviews',
    '/templates',
    '/webchat',
];

export const SettingsWithStore: React.FC<WithMessagesStoreProps & WithBillingStoreProps & WithAuthStoreProps> = (props: WithMessagesStoreProps & WithBillingStoreProps & WithAuthStoreProps) => {
    const { path } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    const current = LINKS.find((l) => location.pathname.startsWith('/settings' + l));

    useLayoutEffect(() => {
        props.BillingStore.getAndSetBilling('getBE', true);
    }, [])

    const filteredSettingsPages = settingsPages.filter(el => (
        (!el.hideIfNoWebchatInFF || props.MessagesStore.ff?.webchat) &&
        // (!el.hideIfNoCoupons || props.BillingStore.redeemedCoupons.length > 0) &&
        (el.hideLockedAccountDialog || props.AuthStore.accountStatus !== 'LOCKED')
    ));

    const pagesMobile = filteredSettingsPages.map((p) => {
        return { label: p.label[0].toUpperCase() + p.label.slice(1), value: p.path };
    });

    const changePage = (i: number) => {
        history.push('/settings' + pagesMobile[i].value);
    };

    return (
        <div className="Settings">
            <div className="Settings__nav">
                {filteredSettingsPages.map((page) => (
                    <NavLink key={page.label} to={`${path}${page.path}`} activeClassName="active">
                        {page.label}
                    </NavLink>
                ))}
            </div>

            {current && (
                <div className="Settings__navMobile" key={pagesMobile.length}>
                    <Dropdown
                        optionsArr={pagesMobile.map((el) => el.label)}
                        selOptionNumber={pagesMobile.findIndex((p) => p.value === current)}
                        darkColor={true}
                        optionSelected={(i) => changePage(i)}
                    />
                </div>
            )}

            <div className="Settings__content card">
                <Switch>
                    {filteredSettingsPages.map((page) => (
                        <Route
                            key={page.path}
                            path={`${path}${page.path}`}
                            component={page.component}
                        />
                    ))}
                    <Redirect
                        from={`${path}`}
                        exact
                        to={props.AuthStore.accountStatus !== 'LOCKED' ? `${path}/account` : `${path}/billing`}
                    />
                    <Redirect
                        to={props.AuthStore.accountStatus !== 'LOCKED' ? `${path}/account` : `${path}/billing`}
                    />
                </Switch>
            </div>
        </div>
    );
};

export const Settings = withStores(SettingsWithStore, [
    STORES.Messages,
    STORES.Billing,
    STORES.Auth
]);