import { toast } from 'react-toastify';

export const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const usPhoneFormat = /((\(\d{3}\) ?)|(\d{3}))?\d{3}-\d{4}/;
export const dataSaved = 'Your data has been saved successfully.';
export const onSuccess = () => toast.success(dataSaved, { autoClose: 4000 });
export const fileExtensionPattern = /(?:\.([^.]+))?$/;
export const dateFormat = 'MM/DD/YYYY';
export const dateRangeFormat = 'MM/dd/yyyy';
export const firstPopupsStepsCompletedElBeforeBEData = -123456789;
export const statusesOrder = ['accepted', 'queued', 'sending', 'sent', 'delivery_unknown', 'delivered', 'undelivered', 'failed'];
export const REDIRECT_BACK_TO_LOCALSTORAGE_KEY = "myopolis-redirectAfterLoginTo";
export const ERROR_TOAST_AUTOCLOSE = {autoClose: 7000};
export const gtagConversionSendTo = {'send_to': 'AW-383742075/VbBXCIvb-IkCEPvg_bYB'};
export const tagsListItemsNumberWhenListNotExpanded = 3;