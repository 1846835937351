import api from '@src/api/api';

export const getCompanyDetails = () => {
    return api.get('/user/settings');
};

export const editCompanyDetails = (companyName: string, address: {}) => {
    return api.post('/user/settings', { companyName, address });
};

export const updateCompanyOperationHours = (timezone: string, hours: []) => {
    return api.post('/account/settings/hours', { hours, timezone });
};
