import React, { useState, useEffect } from 'react';

import './select.sass';
import classNames from 'classnames';
import { useOutsideClick } from '@src/hooks';
import { ReactComponent as DropdownArrow } from '@src/theme/icons/dropdown-arrow.svg';

type OptionValue = string | number;
export type Option<T extends OptionValue> = {
    value: T;
    label: string;
};

interface SelectProps<T extends OptionValue> {
    options: Option<T>[];
    value: T;
    placeholder?: string;
    forceValue?: boolean;
    onChange?: (value: Option<T>) => void;
    classname?: string;
}

export function Select<T extends OptionValue>(props: SelectProps<T>) {
    const selectRef = React.createRef<HTMLDivElement>();
    const [option, setOption] = useState(null);

    useEffect(() => {
        let result = props.options.find((x) => x.value === props.value);
        result = result
            ? result
            : !result && props.forceValue && props.options.length
            ? props.options[0]
            : null;
        if (props.forceValue && props.onChange && result.value !== props.value) {
            props.onChange(result);
        }

        setOption(result);
    }, [props.value, props.options]);

    const { classname } = props;
    const [open, setOpen] = useState(false);
    const placeholder = props.placeholder ? props.placeholder : 'Select';
    const onValueChange = (opt: Option<T>) => {
        setOption(opt);
        setTimeout(() => {
            setOpen(false);
            if (props.onChange) {
                props.onChange(opt);
            }
        }, 10);
    };

    useOutsideClick(selectRef, () => setOpen(false));

    const selectCls = classNames({ Select: true, open: open });
    let longestLabel = '';
    const options =
        props.options &&
        props.options.map((opt) => {
            longestLabel = longestLabel.length > opt.label.length ? longestLabel : opt.label;
            return (
                <div className="option" key={opt.value} onClick={() => onValueChange(opt)}>
                    <p>{opt.label}</p>
                </div>
            );
        });

    return (
        <div ref={selectRef} className={selectCls} onClick={() => setOpen(!open)}>
            <p className="Select__value">{option ? option.label : placeholder}</p>
            <DropdownArrow />
            <div className="Select__spacer options">{longestLabel}</div>
            <div className={classNames('options', classname)}>{options}</div>
        </div>
    );
}
