import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { mergeAll } from 'ramda';
import moment from 'moment';
import classNames from 'classnames';
import { operatingHoursList, timezones } from '@src/stores/models';
import { FormField, CheckboxField } from '@src/components';
import { OpHours } from './models';

import './account.sass';

interface AccountOperationHoursProps {
    opHours: OpHours[];
    timezone: string;
    updateOperationHours: (newObj: OperationHoursDto) => void;
}

interface Hours {
    'day-0-endHour': string;
    'day-0-startHour': string;
    'day-1-endHour': string;
    'day-1-startHour': string;
    'day-2-endHour': string;
    'day-2-startHour': string;
    'day-3-endHour': string;
    'day-3-startHour': string;
    'day-4-endHour': string;
    'day-4-startHour': string;
    'day-5-endHour': string;
    'day-5-startHour': string;
    'day-6-endHour': string;
    'day-6-startHour': string;
    timezone: string;
}

interface OperationHoursDto {
    hours: OpHours[];
    timezone: string;
}

export const AccountOperationHours: React.FC<AccountOperationHoursProps> = (props) => {
    const { register, handleSubmit, errors } = useForm();
    const [operationHours, setOperationHours] = useState(props.opHours);

    const changeAvailability = ({ target: { value } }) => {
        const temp = [...operationHours];
        temp[value].available = !temp[value].available;
        temp[value].start = '08:00';
        temp[value].end = '18:00';
        setOperationHours(temp);
    };

    const submit = (hours: Hours) => {
        const newTimezone = hours.timezone;
        const daysOfWeekNo = Array.from(Array(7).keys());
        const temp = Object.keys(hours).map((h) => {
            const info = h.split('-');
            return {
                day: Number(info[1]),
                [info[2]]: hours[h],
            };
        });
        const newOpHours = daysOfWeekNo.reduce((accum, dNo) => {
            const dayData = temp.filter((t) => t.day == dNo);
            if (dayData.length == 2) accum.push(mergeAll(dayData));
            return accum;
        }, []);

        const dto: OperationHoursDto = { hours: newOpHours, timezone: newTimezone };
        props.updateOperationHours(dto);
    };

    return (
        <form onSubmit={handleSubmit(submit)} className="operationHours">
            <div className="timezone">
                {props.timezone && (
                    <FormField label="Time Zone" htmlFor="timezone" error={errors.timezone}>
                        <select
                            name="timezone"
                            id="timezone"
                            className={classNames(
                                'form-input',
                                { error: errors.timezone },
                                'select'
                            )}
                            defaultValue={props.timezone}
                            ref={register({ required: 'Select time zone' })}
                        >
                            {timezones.map((el) => (
                                <option key={el.value} value={el.value}>
                                    {el.name}
                                </option>
                            ))}
                        </select>
                    </FormField>
                )}
            </div>
            {operationHours.map((el, i) => (
                <div key={el.day} className="operationHours-row">
                    <div className="operationHours-row-dayOfWeek">
                        <span>{moment().day(el.day).format('dddd')}</span>
                        <CheckboxField
                            defaultValue={el.available}
                            onChange={changeAvailability}
                            value={i}
                        />
                    </div>
                    <div className="operationHours-row-start">
                        {el.available && (
                            <FormField
                                htmlFor={`day-${el.day}-startHour`}
                                error={errors.operationHoursStart}
                            >
                                <select
                                    name={`day-${el.day}-startHour`}
                                    id={`day-${el.day}-startHour`}
                                    className={classNames('form-input', 'select', {
                                        error: errors.operationHoursStart,
                                    })}
                                    defaultValue={el.start}
                                    ref={register({ required: 'Select hours of operation' })}
                                >
                                    {operatingHoursList.map((el) => (
                                        <option key={el.value} value={el.value}>
                                            {el.text}
                                        </option>
                                    ))}
                                </select>
                            </FormField>
                        )}
                    </div>
                    <div className="operationHours-row-end">
                        {el.available && (
                            <FormField
                                htmlFor={`day-${el.day}-endHour`}
                                error={errors.operationHoursEnd}
                            >
                                <select
                                    name={`day-${el.day}-endHour`}
                                    id={`day-${el.day}-endHour`}
                                    className={classNames('form-input', 'select', {
                                        error: errors.operationHoursEnd,
                                    })}
                                    defaultValue={el.end}
                                    ref={register({ required: 'Select hours of operation' })}
                                >
                                    {operatingHoursList.map((el) => (
                                        <option key={el.value} value={el.value}>
                                            {el.text}
                                        </option>
                                    ))}
                                </select>
                            </FormField>
                        )}
                    </div>
                </div>
            ))}
            <button className="btn-primary"> Save </button>
        </form>
    );
};
