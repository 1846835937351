import React from 'react';
import classNames from 'classnames';
import { ReviewWithReplies, LoadingState } from '@src/stores/models';
import { ReviewContent } from '@src/pages/Reviews/ReviewContent';
import { images } from '@src/theme/icons/dashboard';
import { Loader } from '@src/components';

import '../../DashboardCard/DashboardCard.sass';
import './recent-reviews.sass';
import { withStores, STORES } from '@src/stores/with-store';
import { ReviewsStore } from '@src/stores/reviews.store';
import { noReviews } from '../ReviewsPerSite/ReviewsPerSite';
import DashboardCard from '../../DashboardCard/DashboardCard';

export interface RecentReviewsProps {
    reviews: ReviewWithReplies[];
    loading: LoadingState;
    saveReviewReply?: (reviewId: string, replyId: string, replyTxt: string) => Promise<void>;
    removeReviewReply?: (replySourceId: string) => void;
    ReviewsStore?: ReviewsStore;
}

const RecentReviewsWithStore: React.FC<RecentReviewsProps> = (props) => {
    const store = props.ReviewsStore;
    const { reviews, removeReviewReply, saveReviewReply } = props;
    const anyReviews = reviews.length > 0;

    return (
        <DashboardCard className="RecentReviews">
            <Loader loadingState={props.loading} />
            <div className="dashboard-card__title">
                <img src={images.reviewsB} alt="star-blue" className="card-icon" />
                <span>Recent reviews</span>
            </div>
            <div className={classNames('RecentReviews__results', { noResults: !anyReviews })}>
                {anyReviews ? (
                    props.reviews.map((r) => (
                        <ReviewContent
                            key={r.review.id}
                            review={r}
                            loading={store.reviewsLoadings.get(r.review.id)}
                            removeReviewReply={(replySourceId) => removeReviewReply(replySourceId)}
                            saveReviewReply={(reviewId, replyId, replyTxt) =>
                                saveReviewReply(reviewId, replyId, replyTxt)
                            }
                        />
                    ))
                ) : (
                    <div className="empty">
                        <h2 className="info">{noReviews}</h2>
                    </div>
                )}
            </div>
        </DashboardCard>
    );
};

export const RecentReviews = withStores(RecentReviewsWithStore, [STORES.Reviews]);
