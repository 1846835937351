import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { STORES, withStores } from '@src/stores/with-store';
import { Dialog } from '@src/components';
import { toast } from 'react-toastify';

import './import-customers.sass';
import { ImportCustomersStore } from '@src/stores/import-customers.store';
import { ImportUpload } from './ImportUpload';
import { LoadingState } from '@src/stores/models';
import ImportIntro from './ImportIntro';
import ImportDone from './ImportDone';

const onError = (message) => toast.error(message);

type DialogContent = 'intro' | 'upload' | 'done';

interface LocationStateType {
    uploadSuccess: boolean
 }
 

interface ImportCustomersProps {
    openDialog: boolean;
    closeDialog: () => void;
    ImportCustomersStore: ImportCustomersStore;
}

export const ImportCustomersWithStore: React.FC<ImportCustomersProps> = (props) => {
    const store = props.ImportCustomersStore;
    const history = useHistory();
    const location = useLocation<LocationStateType>();
    const [dialogOpen, setDialogOpen] = useState(props.openDialog);
    const [content, setContent] = useState<DialogContent>('intro');

    const handleDialogClose = () => {
        history.replace({pathname: history.location.pathname, state: { uploadSuccess: false }});
        setDialogOpen(false);
        setContent('intro');
        props.closeDialog();
    };

    const handleUploadSuccess = () => {
        history.push('/customers/import/upload');
    }

    const handleUpload = (file: File) => {
        store.uploadCustomers(file, handleUploadSuccess, onError);
    };

    const handleIntroNextClick = () => {
        setContent('upload');
    };

    useLayoutEffect(() => {
        store.init();
    }, []);

    useEffect(() => {
        setDialogOpen(props.openDialog);
    }, [props.openDialog]);

    useEffect(() => {
        if (location.state?.uploadSuccess) {
            setContent('done');
            setDialogOpen(true);
            history.replace({pathname: history.location.pathname, state: { uploadSuccess: false }});
        }
    }, [location.state?.uploadSuccess])

    const renderIntro = content === 'intro' && <ImportIntro onNextClick={handleIntroNextClick}/>;

    const renderUpload = content === 'upload' && <ImportUpload
        loading={store.uploadState === LoadingState.Loading}
        onClose={handleDialogClose}
        onUpload={handleUpload}
    />;

    const renderDone = content === 'done' && <ImportDone addedCustomers={10} onDoneClick={handleDialogClose}/>;

    const renderContent = renderIntro || renderUpload || renderDone;

    return (
        <div className="ImportCustomers">
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                {renderContent}
            </Dialog>
        </div>
    );
};

export const ImportCustomers = withStores(ImportCustomersWithStore, [STORES.ImportCustomersStore]);
