import React, { useEffect, useState } from 'react';
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Input } from 'reactstrap';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { AsYouType } from 'libphonenumber-js';
import { Dialog, FormField, Loader, TwilioPhone } from '@src/components';
import { ReviewContent } from '@src/pages/Reviews/ReviewContent';
import { CustomerStore } from '@src/stores/customer.store';
import { LoadingState } from '@src/stores/models';
import {
    STORES,
    WithReviewsStoreProps,
    withStores,
    WithThreadsStoreProps,
} from '@src/stores/with-store';
import AuthStore from '@src/stores/auth.store';
import { images } from '@src/theme/images';
import ReactTooltip from 'react-tooltip';

import { formatDate } from '@src/theme/utils/helpers';

import '../customer-common.sass';

type CustomerProps = WithReviewsStoreProps & { CustomerStore: CustomerStore } &
    WithThreadsStoreProps;

const CustomerWithStore: React.FC<CustomerProps> = (props) => {
    const lockedAccount = AuthStore.accountStatus === 'LOCKED';
    const customerStore = props.CustomerStore;
    const reviewsStore = props.ReviewsStore;
    const threadsStore = props.ThreadsStore;
    const loading = customerStore.loading;
    const ffBrowserCallingEnabled = customerStore.ffBrowserCallingEnabled;
    const params = useRouteMatch().params as {id: string};
    const cid = params.id;
    const [customerId, setCustomerId] = useState(cid);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [preventUglyReflow, setPreventUglyReflow] = useState(false);
    const [notesToDisplay, setNotesToDisplay] = useState('');
    const [tagsToDisplay, setTagsToDisplay] = useState('');

    const customerData = customerStore.customerData;
    const customer = customerData?.customer;
    const history = useHistory();
    const onError = (message) => toast.error(message);
    const loaded = loading === LoadingState.Loaded;

    useEffect(() => {
        if (customer?.notes?.length) {
            // check if there is any notes
            const notesInOrder = customer.notes.sort(
                (e1, e2) => new Date(e1.created).getTime() - new Date(e2.created).getTime()
            );
            setNotesToDisplay(notesInOrder.map((el) => el.text).join(', '));
        } else {
            setNotesToDisplay('');
        }
    }, [customer?.notes?.length]);

    useEffect(() => {
        if (customerData?.tags?.length) {
            setTagsToDisplay(customerData.tags.map((el) => el.value).join(', '));
        } else {
            setTagsToDisplay('');
        }
    }, [customerData?.tags?.length]);

    const callInit = () => {
        customerStore.init();
        customerStore.initData(cid, onError, !lockedAccount);
    }

    useEffect(() => {
        // initialized the component
        // preventUglyReflow prevent the re render of the modal
        setPreventUglyReflow(true);
        setCustomerId(cid);
        callInit();
    }, []);

    const refreshAfterTag = () => {
        customerStore.fetchCustomer(cid, onError);
        customerStore.refreshAfterTagFunc();
    };

    useEffect(() => {
        // if there is any delete or update in tags it will get the customer new information
        if (customerStore.refreshAfterTag) {
            refreshAfterTag();
        }
    }, [customerStore.refreshAfterTag]);

    if (loaded && (!customerId || !customer)) {
        return (
            <div className="Customer error card">
                <NavLink to={'/customers?c'}>
                    <button className="btn-tetriary">&lt; Back to Customers List</button>
                </NavLink>
                <h2>Error loading customer {customerId ? `with id - ${customerId}` : ''}</h2>
            </div>
        );
    }

    const onConfirmDeleteUser = () => {
        setDialogOpen(false);
        customerStore.removeCustomer(customerId, onError).then(() => {
            history.push('/customers?c');
        });
    };

    const customerFormData = loaded && {
        lastContact: {
            label: 'Last Contacted',
            value: customer?.lastContact ? formatDate(customer?.lastContact) : 'N/A',
        },
        created: {
            label: 'Created',
            value: customer?.created ? formatDate(customer?.created) : 'N/A',
        },
        lastUpdated: {
            label: 'Last Updated',
            value: customer?.updated ? formatDate(customer?.updated) : 'N/A',
        }
    };

    const phoneInput = (
        <Input
            disabled={true}
            type="text"
            mask="(999)-999-9999"
            maskChar="_"
            name="phoneNumber"
            className="form-input"
            tag={InputMask}
            value={customer?.phone}
        />
    );

    const viewMessage = () => {
        if (customerData?.threadId) history.push(`/messages/t/${customerData?.threadId}`);
        else {
            threadsStore.createNewThread(customerData.customer, true)
            .then(() => history.replace({ pathname: `/messages/?new` }));
        }
    };


    const reviewsCls = classNames({
        'reviews-container': true,
        'no-reviews': !customerData?.reviews.length,
    });
    return (
        <div className="Customer">
            <Loader loadingState={loading} />
            <div className="customer-container">
                <div className="customer-data">
                    <div className="card">
                        {customer && preventUglyReflow && (
                            <>
                                <div className="card-title">
                                    <NavLink to={'/customers?c'}>
                                        <button className="btn-tetriary">
                                            &lt; Back to Customers List
                                        </button>
                                    </NavLink>
                                    <div className="actions">
                                        <a onClick={() => viewMessage()}>
                                            <button className="btn-primary">View messages</button>
                                        </a>
                                        {/* <NavLink to={`/customers/edit/${customerId}`}>
											<button className="btn-primary">
												<span>Edit</span>
											</button>
										</NavLink> */}
                                        <button
                                            className="btn-secondary"
                                            onClick={() => setDialogOpen(true)}
                                        >
                                            <span>Delete</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="data">
                                    <div className="data-editable">
                                        <div className="row oneElement">
                                            <FormField
                                                label="Customer Name"
                                                htmlFor="customer-name"
                                            >
                                                <input
                                                    disabled={true}
                                                    name="customer-name"
                                                    className="form-input"
                                                    value={`${customer?.firstName || 'N/A'} ${
                                                        customer?.lastName || ''
                                                    }`}
                                                />
                                            </FormField>
                                        </div>
                                        <div className="row twoElements">
                                            <FormField label="Phone Number" htmlFor="phoneNumber">
                                                {customer?.phone ? (
                                                    <div>
                                                        {ffBrowserCallingEnabled ? (
                                                            <div
                                                                title={`Call ${
                                                                    customer?.firstName ||
                                                                    new AsYouType('US').input(
                                                                        (
                                                                            '' + customer?.phone
                                                                        ).replace(/\D/g, '')
                                                                    )
                                                                } ${customer?.lastName || ''}`}
                                                            >
                                                                <TwilioPhone
                                                                    phoneNumber={customer?.phone}
                                                                >
                                                                    {phoneInput}
                                                                </TwilioPhone>
                                                            </div>
                                                        ) : (
                                                            phoneInput
                                                        )}
                                                    </div>
                                                ) : (
                                                    <input
                                                        disabled={true}
                                                        type="text"
                                                        name="phone"
                                                        className="form-input"
                                                        defaultValue={'N/A'}
                                                    />
                                                )}
                                            </FormField>
                                            <FormField label="Email Address" htmlFor="email">
                                                <a
                                                    className={classNames({
                                                        email: customer?.email,
                                                    })}
                                                    href={
                                                        customer?.email
                                                            ? `mailto:${customer?.email}`
                                                            : null
                                                    }
                                                    title={
                                                        customer?.email
                                                            ? `Email ${
                                                                  customer?.firstName ||
                                                                  customer?.email
                                                              } ${customer?.lastName || ''}`
                                                            : null
                                                    }
                                                >
                                                    <input
                                                        disabled={true}
                                                        type="text"
                                                        name="email"
                                                        className="form-input"
                                                        defaultValue={customer?.email || 'N/A'}
                                                    />
                                                </a>
                                            </FormField>
                                        </div>
                                        <div className="row oneElement">
                                            <FormField label="Company" htmlFor="comapny">
                                                <input
                                                    disabled={true}
                                                    type="text"
                                                    name="comapny"
                                                    className="form-input"
                                                    defaultValue={customer?.company || 'N/A'}
                                                />
                                            </FormField>
                                        </div>
                                        <div className="row oneElement">
                                            <FormField
                                                label="Notes"
                                                htmlFor="notes"
                                                classStyling="customer-notes"
                                            >
                                                <textarea
                                                    disabled={true}
                                                    name="notes"
                                                    className="form-input"
                                                    value={notesToDisplay || 'N/A'}
                                                />
                                            </FormField>
                                        </div>
                                        <div className="row oneElement">
                                            <FormField
                                                label="Tags"
                                                htmlFor="tags"
                                                classStyling="customer-tags"
                                            >
                                                <textarea
                                                    disabled={true}
                                                    name="tags"
                                                    className="form-input"
                                                    value={tagsToDisplay || 'N/A'}
                                                />
                                            </FormField>
                                        </div>
                                    </div>
                                    <div className="data-noneditable">
                                        {Object.entries(customerFormData).map(([key, data]) => {
                                            return (
                                                <FormField
                                                    key={key}
                                                    label={data.label}
                                                    htmlFor={key}
                                                >
                                                    <input
                                                        disabled={true}
                                                        type="text"
                                                        name={key}
                                                        className="form-input"
                                                        defaultValue={data.value}
                                                    />
                                                </FormField>
                                            );
                                        })}
                                        <div className="optOut-label">Allows messages</div>
                                        <div className="optOut-value">
                                            <ReactTooltip />
                                            {customer?.optedOut ?
                                                <img src={images.clearRed} alt="checkmark" className="yes" data-tip="Customer does not agree to receive messages from Myopolis" /> :
                                                <img src={images.checkmarkUnselected} alt="no" data-tip="Customer agrees to receive messages from Myopolis" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {customer && preventUglyReflow && (
                        <div className="reviews">
                            <div>
                                <h3>Reviews</h3>
                            </div>
                            <div className={reviewsCls}>
                                {!customerData?.reviews.length ? (
                                    <h3>No reviews submitted</h3>
                                ) : (
                                    customerData?.reviews.map((rev) => {
                                        return (
                                            <ReviewContent
                                                key={rev?.review.id}
                                                review={rev}
                                                removeReviewReply={(replySourceId) =>
                                                    reviewsStore.removeReplyFromReview(
                                                        replySourceId
                                                    )
                                                }
                                                saveReviewReply={(reviewId, replyId, replyTxt) =>
                                                    reviewsStore.saveReviewReply(
                                                        reviewId,
                                                        replyId,
                                                        replyTxt
                                                    )
                                                }
                                            />
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="customer-messages card">
                    <div className="card-title">
                        <h3>Messages</h3>
                        {!customerData?.threadId ? null : (
                            <NavLink to={`/messages/t/${customerData?.threadId}`}>
                                <button className="btn-primary">View</button>
                            </NavLink>
                        )}
                    </div>
                    {/* <CustomerChat
                        slim={true}
                        // customer={customer}
                        employeesMap={{}}
                        // messages={messages?.messages}
                        // voiceMail={messages?.voiceMail}
                        // closings={messages?.closings}
                        // assignments={messages?.assigned}
                        // notes={messages?.notes}
                    /> */}
                </div>
            </div>

            <Dialog
                open={dialogOpen}
                title={`Delete ${
                    customer?.firstName ||
                    new AsYouType('US').input(('' + customer?.phone).replace(/\D/g, ''))
                } ${customer?.lastName || ''}`}
                onClose={() => setDialogOpen(false)}
            >
                <div className="CustomerDeleteDialog">
                    <p>Are you sure you want to delete this customer?</p>
                    <p>You will not be able to undo this action.</p>
                    <div className="btns-container">
                        <button className="btn-secondary" onClick={() => setDialogOpen(false)}>
                            Cancel
                        </button>
                        <button className="btn-primary" onClick={() => onConfirmDeleteUser()}>
                            Delete
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export const Customer = withStores(CustomerWithStore, [
    STORES.Customer,
    STORES.Reviews,
    STORES.ThreadsStore,
]);
