import React, { useMemo } from 'react';
import { Paging } from '@src/components';
import { LoadingState } from '@src/stores/models';
import TableHeaders from './TableHeaders';
import TableBody from './TableBody';

import './table.sass';

type EmptyTable = string | React.ReactNode;

export interface SortColumn {
    col: string;
    dir: string;
  };
  
export interface TableHeader {
    title: string;
    field?: string;
    cls?: string[];
    headerCls?: string[];
    flexWidth?: number;
    searchable?: boolean;
    sortField?: string;
    template?: <T extends unknown>(header: TableHeader, data: T, idx?: number) => React.ReactNode;
};

interface TableProps<T extends unknown> {
    headers: TableHeader[];
    data: T[];
    search?: string;
    totalCount: number;
    currentPage?: number;
    pageSize?: number;
    loading?: boolean;
    emptyTable?: EmptyTable;
    showHeaderOnNoData?: boolean;
    onPageChange?: (page: number) => void;
    onSortChange?: (sortColumn: SortColumn) => void;
};

export const Table = <T extends unknown>({emptyTable = 'No Results', ...props}: TableProps<T>): JSX.Element => {
    const changePage = (page: number): void =>
        props.onPageChange ? props.onPageChange(page) : null;
    const searchableCols = useMemo(() => props.headers.filter((h) => h.searchable), [ props.headers ]);

    const data = useMemo(() => {
        if (!searchableCols.length || !props.search) {
            return props.data;
        }

        return props.data.filter((d) => {
            return searchableCols.filter((c) => d[c.field].toLowerCase().indexOf(props.search) > -1).length;
        });
    }, [props.search, props.data]);

    const renderEmptyTable = <div className="empty">
        {typeof emptyTable === 'string' ?
            <h2>{emptyTable}</h2> :
            emptyTable
        }
    </div>;

    const renderBody = <TableBody
        data={data}
        headers={props.headers}
    />;
    
    const renderContent = !props.loading && (data?.length > 0 ? renderBody : renderEmptyTable);

    const renderHeader = data?.length > 0 || props.showHeaderOnNoData ?
        <TableHeaders
            headers={props.headers}
            onSortChange={props.onSortChange}
        /> : 
        null;

    return (
        <div className="Table">
            <div className="Table__table">
                {renderHeader}
                {renderContent}
            </div>
            {props.onPageChange ? (
                <Paging
                    currPage={props.currentPage}
                    total={props.totalCount}
                    pageSize={props.pageSize}
                    onPageChange={(page) => changePage(page)}
                />
            ) : null}
        </div>
    );
};
