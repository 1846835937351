import React from 'react';
import { images } from '@src/theme/images';
import { SingleBillingPlan } from '@src/stores/models';
import { displayIntegerOrTwoDecimalPlaces } from '@src/theme/utils/helpers';

import '../payment-inside-stripe.sass';

interface HeaderProps {
    selectedPlan: SingleBillingPlan;
};

const AddPaymentOnShortSignupHeader: React.FunctionComponent<HeaderProps> = ({ selectedPlan }) => {
    return (
        <>
            <div className="PaymentInsideStripe__planDescription">
                <div className="PaymentInsideStripe__planDescription__image">
                    <img src={images.planHouseImg} alt="house" />
                </div>
                <div className="PaymentInsideStripe__planDescription__text">
                    <div className="PaymentInsideStripe__planDescription__text-yourPlan">YOUR PLAN</div>
                    <div className="PaymentInsideStripe__planDescription__text-planName">{selectedPlan?.billingPlan.name}</div>
                    <div className="PaymentInsideStripe__planDescription__text-noFreeTrial">
                        Pay as little as <b>${displayIntegerOrTwoDecimalPlaces(selectedPlan?.price.amount)}</b> per {selectedPlan?.price.interval.interval}
                    </div>
                </div>
            </div>
        </>
    )
};

export default AddPaymentOnShortSignupHeader;
