import api from '@src/api/api';
import { AxiosResponse } from 'axios';
import { WebchatInfo } from '@src/stores/models';

export const getWebchatData = (): Promise<WebchatInfo> => {
    return api.get('/webchatsettings')
    .then((res: AxiosResponse<WebchatInfo>) => res.data);
};

export const saveWebchatData = (data: WebchatInfo): Promise<WebchatInfo> => {
    return api.post('/webchatsettings', { ...data })
    .then((res: AxiosResponse<WebchatInfo>) => res.data);
};