import api from '@src/api/api';
import { Notification } from '@src/stores/models';

export const getNotifications = (
    page?: number,
    size?: number
): Promise<{ data: Notification[] }> => {
    const params = {
        page,
        size,
    };
    return api.get('/notifications', { params });
};

export const markNotificationAsRead = (id: string) => {
    return api.post(`/notifications/${id}/read`);
};

export const markAllNotificationsAsRead = () => {
    return api.post('/notifications/read', {});
};

export const deleteNotification = (id: string) => {
    return api.delete(`/notifications/${id}`);
};

export const notificationsApi = {
    getNotifications,
    markNotificationAsRead,
    markAllNotificationsAsRead,
    deleteNotification,
};
