import React, { useEffect, useLayoutEffect, useState } from 'react';
import moment from 'moment';
import { onSuccess } from '@src/theme/utils/constants';
import { withUserStore, WithUserStoreProps } from '@src/stores/with-store';
import { Loader, CompanyDetails, ErrorToastDownload, ErrorToastSave } from '@src/components';
import { LoadingState, OnErrorDataAccount } from '@src/stores/models';
import { AccountOperationHours } from './AccountOperationHours';
import { OpHours } from './models';
import { toast } from 'react-toastify';

import './account.sass';

export const AccountWithStore: React.FC<WithUserStoreProps> = (props: WithUserStoreProps) => {
    const store = props.UserStore;
    const loading = store.loadingState;
    const { company } = store;
    const { account, address, timezone, hours } = company;
    const [isFirstLoading, setIsFirstLoading] = useState(true);

    store.onError = (type: string, data?: OnErrorDataAccount) => {
        store.loadingState = LoadingState.Error;
        switch (type) {
            case 'getBE': {
                toast.error(
                    <ErrorToastDownload getBEData={() => store.getAndSetCompanyDetails('getBE')} />
                );
                break;
            }
            case 'updateCompanyDetails': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.updateCompanyDetails(
                                data.companyName,
                                data.address,
                                'updateCompanyDetails'
                            )
                        }
                    />
                );
                break;
            }
            case 'updateOperationHours': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.updateOperationHours(
                                data.timezone,
                                data.hours,
                                'updateOperationHours'
                            )
                        }
                    />
                );
                break;
            }
            default: {
                return;
            }
        }
    };
    store.onSuccess = () => {
        store.loadingState = LoadingState.Loaded;
        onSuccess();
    };

    const daysOfWeekNo = Array.from(Array(7).keys());

    const opHours: OpHours[] = daysOfWeekNo.map((day) => {
        const fetchedDay = hours?.find((h) => h.day == day);
        return {
            day,
            available: !!fetchedDay,
            start: fetchedDay && moment(fetchedDay.startHour, 'HH:mm:ss').format('HH:mm'),
            end: fetchedDay && moment(fetchedDay.endHour, 'HH:mm:ss').format('HH:mm'),
        };
    });

    const getAndSetCompanyDetails = () => {
        store.getAndSetCompanyDetails('getBE', isFirstLoading);
        setIsFirstLoading(false);
    };

    const updateCompanyDetails = ({ companyName, address }) => {
        store.updateCompanyDetails(companyName, address, 'updateCompanyDetails');
    };

    const updateOperationHours = ({ timezone, hours }) => {
        store.updateOperationHours(timezone, hours, 'updateOperationHours');
    };

    useEffect(() => {
        getAndSetCompanyDetails();
    }, []);

    useLayoutEffect(() => {
        store.init();
    }, []);

    return (
        <div className="AccountSett">
            <Loader loadingState={store.loadingState} />
            <>
                {loading !== LoadingState.Init && loading !== LoadingState.FirstLoading && (
                    <>
                        <div className="AccountSett__title">Account Information</div>
                        <div className="AccountSett__subtitle">
                            Information about your company and account
                        </div>
                        {store.dataLoaded && (
                            <>
                                <div className="AccountSett__section">
                                    <div className="AccountSett__section-title">Company Details</div>
                                    <div className="AccountSett__section-content">
                                        <CompanyDetails
                                            company={account?.name}
                                            {...address}
                                            updateCompanyDetails={(data) => updateCompanyDetails(data)}
                                        />
                                    </div>
                                </div>
                                <div className="AccountSett__section">
                                    <div className="AccountSett__section-title">Hours of Operation</div>
                                    <div className="AccountSett__section-subtitle">
                                        Set your business hours so we can handle your calls and
                                        messages when you&apos;re closed
                                    </div>
                                    <div className="AccountSett__section-content">
                                        {company?.hours && (
                                            <AccountOperationHours
                                                opHours={opHours}
                                                timezone={timezone}
                                                updateOperationHours={(data) =>
                                                    updateOperationHours(data)
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </>
        </div>
    );
};

export const Account = withUserStore(AccountWithStore);
