import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { countriesToSelect, statesOfUS, provincesOfCanada, Address } from '@src/stores/models';
import { FormField } from '@src/components/FormField/FormField';
import { omit } from 'ramda';

import './company-details.sass';

interface CompanyDetailsProps extends Address {
    updateCompanyDetails: (newObj) => void;
}

interface CompanyDetailsForm extends Address {
    companyName: string;
}

interface CompanyUpdate {
    companyName: string;
    address: Address;
}

export const CompanyDetails: React.FC<CompanyDetailsProps> = (props) => {
    const { register, handleSubmit, errors } = useForm();
    const { updateCompanyDetails, id } = props;
    const [ selectedCountry, setSelectedCountry ] = useState('US');
    const [ selectedState, setSelectedState ] = useState('AL'); // do not remove!!!

    const arrays = [countriesToSelect, statesOfUS, provincesOfCanada];
    const convertedArrays = arrays.map(arr => Object.keys(arr).map((el) => {
        return {
            text: arr[el],
            value: el,
        };
    }));
    const countries = convertedArrays[arrays.indexOf(countriesToSelect)];
    const statesUS = convertedArrays[arrays.indexOf(statesOfUS)];
    const provincesCA = convertedArrays[arrays.indexOf(provincesOfCanada)];

    useEffect(() => {
        setSelectedCountry(props.country);
    }, [props.country]);

    const submit = (data: CompanyDetailsForm) => {
        const correctedAddress = {
            ...data,
            id,
            country: data.country ? data.country : 'US',
            state: data.state ? data.state : props.state ? props.state : data.country === 'US' ? 'AL' : 'AB'
        }
        const dto = {
            companyName: data.companyName,
            address: omit(['companyName'], correctedAddress),
        };
        updateCompanyDetails(dto);
    };

    return (
        <div className="CompanyDetails">
            <form onSubmit={handleSubmit(submit)}>
                <div className="oneItemInLine">
                    <FormField label="Company name" htmlFor="companyName" error={errors.companyName}>
                        <input
                            type="text"
                            name="companyName"
                            className={classNames('form-input', { error: errors.companyName })}
                            defaultValue={props.company}
                            ref={register({ required: 'Missing company name' })}
                        />
                    </FormField>
                </div>
                <div className="oneItemInLine">
                    <FormField label="Country" htmlFor="country" error={errors.country}>
                        <select
                            name="country"
                            id="country"
                            className={classNames('form-input', 'select', { error: errors.country })}
                            defaultValue={props.country}
                            onChange={(e) => {
                                setSelectedCountry(e.target.value);
                                setSelectedState(undefined);
                            }}
                            ref={register({ required: 'Missing country' })}
                        >
                            {countries.map((el) => (
                                <option key={el.value} value={el.value}>
                                    {el.text}
                                </option>
                            ))}
                        </select>
                    </FormField>
                </div>
                <div className="twoItemsInLine">
                    <FormField label="Address" htmlFor="street" error={errors.street}>
                        <input
                            type="text"
                            name="street"
                            className={classNames('form-input', { error: errors.street })}
                            defaultValue={props.street}
                            ref={register({ required: 'Missing address' })}
                        />
                    </FormField>
                    <FormField
                        label="Address (Line 2)"
                        htmlFor="streetTwo"
                        error={errors.streetTwo}
                    >
                        <input
                            type="text"
                            name="streetTwo"
                            className={classNames('form-input', { error: errors.streetTwo })}
                            defaultValue={props.streetTwo}
                            ref={register()}
                        />
                    </FormField>
                </div>
                <div className="threeItemsInLine">
                    <FormField label="City" htmlFor="city" error={errors.city}>
                        <input
                            type="text"
                            name="city"
                            className={classNames('form-input', { error: errors.city })}
                            defaultValue={props.city}
                            ref={register({ required: 'Missing city' })}
                        />
                    </FormField>
                    {props.state && ( // ?
                        <FormField key={'state' + selectedCountry} label={selectedCountry === 'CA' ? 'Province' : 'State'} htmlFor="state" error={errors.state}>
                            <select
                                name="state"
                                id="state"
                                className={classNames('form-input', 'select', { error: errors.state })}
                                defaultValue={props.state}
                                onChange={(e)=> setSelectedState(e.target.value)}
                                ref={register({ required: 'Missing state' })}
                            >
                                {(selectedCountry === 'CA' ? provincesCA : statesUS).map((el) => (
                                    <option key={el.value} value={el.value}>
                                        {el.text}
                                    </option>
                                ))}
                            </select>
                        </FormField>
                    )}
                    <FormField label="Postal Code" htmlFor="postalCode" error={errors.postalCode}>
                        <input
                            // type="number"
                            name="postalCode"
                            placeholder={selectedCountry === 'CA' ? 'K2C3G1' : '10001'}
                            className={classNames('form-input', { error: errors.postalCode })}
                            defaultValue={props.postalCode}
                            ref={register({
                                required: 'Missing postal code',
                                pattern: {
                                    value: selectedCountry === 'CA' ? /^[A-​Za-z0-9]+$/ : /^[0-9]+$/,
                                    message: 'Invalid postal code',
                                },
                                minLength: {
                                    value: selectedCountry === 'CA' ? 6 : 5,
                                    message: 'Invalid postal code'
                                },
                                maxLength: {
                                    value: selectedCountry === 'CA' ? 6 : 5,
                                    message: 'Invalid postal code'
                                }
                            })}
                        />
                    </FormField>
                </div>
                <button className="btn-primary" type="submit">
                    Save
                </button>
            </form>
        </div>
    );
};
