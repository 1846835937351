export default class RemoveHtml {
    static stripTags(string) {
        return string.replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, function (/*$0, $1*/) {
            return '';
        });
    }

    static ltrim(str) {
        return str.replace(/^[ \s\u00A0]+/g, '');
    }

    static rawText(str, trim) {
        let text = str
            .replace(/<br><div>/gi, '\n') //chrome not taking in account white-space:pre
            .replace(/<div><br><\/div>/gi, '\n') //chrome newline
            .replace(/<br>&nbsp;/gi, '\n\n') //mozilla newline
            .replace(/&nbsp;/g, ' ')
            .replace(/<div>|<br>|<\/p>/gi, '\n') //html tags
            .replace(/&amp;/gi, '&')
            .replace(/&lt;/gi, '<')
            .replace(/&gt;/gi, '>');
        text = trim ? this.stripTags(text).trim() : this.ltrim(this.stripTags(text));
        return text;
    }
}
