import React, { useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from 'react-stripe-elements';
import classNames from 'classnames';
import { BillingStore } from '@src/stores/billing.store';
import { PaymentCase } from './PaymentInsideStripe';
import { FormField, SignupSelect } from '@src/components';
import { displayIntegerOrTwoDecimalPlaces } from '@src/theme/utils/helpers';
import { Address as AddressModel, countriesToSelect, statesOfUS, provincesOfCanada, SingleBillingPlan } from '@src/stores/models';

import './payment-inside-stripe.sass';

const ccFieldsStyling = {fontSize: '16px', color: '#232554', '::placeholder': {color: '#697386'}};

interface CCDetailsFormProps {
    billingStore?: BillingStore;
    confirmBtn?: string;
    paymentCase: PaymentCase;
    selectedBuyNow: boolean;
    selectedPlan?: SingleBillingPlan;
    settings: boolean;
    hideInput?: boolean;
    onActionClick: (data: any) => void;
    onStepBack: () => void;
    collectAlsoAddressData: boolean;
};

const CCDetailsForm: React.FunctionComponent<CCDetailsFormProps> = ({ billingStore, confirmBtn, hideInput, paymentCase, selectedBuyNow, selectedPlan, settings, onStepBack, onActionClick, collectAlsoAddressData }) => {
    const isUpdatePaymentInSettings = paymentCase === 'updatePaymentInSettings';
    const isAddPaymentAndFinishTrial = paymentCase === 'addPaymentAndFinishTrial';
    const isAddPaymentOnSignup = paymentCase === 'addPaymentOnSignup';
    const isAddPaymentOnShortSignup = paymentCase === 'addPaymentOnShortSignup';
    const isAddPaymentCoupon = paymentCase === 'addPaymentCoupon';
    const isReactivateFromCancelled = paymentCase === 'reactivateFromCancelled';
    const isChangePlan = paymentCase === 'changePlan';

    const isTrialPlanOnSignup = !isAddPaymentOnShortSignup && selectedPlan && selectedPlan.billingPlan.trialDays && selectedPlan.billingPlan.trialDays > 0 && !selectedBuyNow;

    const [ addressData, setAddressData ] = useState({
        street: undefined,
        streetTwo: undefined,
        postalCode: undefined,
        city: undefined,
        state: undefined,
        country: undefined,
    });

    const [ ccData, setCcData ] = useState({
        cardNumber: undefined,
        cardExpiry: undefined,
        cardCvc: undefined,
    });

    const arrays = [countriesToSelect, statesOfUS, provincesOfCanada];
    const convertedArrays = arrays.map(arr => Object.keys(arr).map((el) => {
        return {
            label: arr[el],
            value: el,
        };
    }));
    const countries = convertedArrays[arrays.indexOf(countriesToSelect)];
    const statesUS = convertedArrays[arrays.indexOf(statesOfUS)];
    const provincesCA = convertedArrays[arrays.indexOf(provincesOfCanada)];

    const setAddressElement = (key: string, value: string | boolean) => {
        const newAddress = {...addressData, [key]: value};
        setAddressData(newAddress);
    };

    const setCountry = (value: string) => {
        let newAddress;
        if ((!addressData.country && value === 'CA') || (addressData.country && value !== addressData.country)) {
            newAddress = {...addressData, state: undefined, country: value};
        } else {
            newAddress = {...addressData, country: value};
        };
        setAddressData(newAddress);
    };

    const setCCDetails = (key: string, details) => {
        if (details.empty) {
            setCcData({...ccData, [key]: false});
        } else {
            setCcData({...ccData, [key]: true});
        };
    };

    const actionClicked = (event) => {
        onActionClick(collectAlsoAddressData ? {event, address: addressData} : {event});
    };

    const actionsAddPaymentOnSignup = (isAddPaymentOnSignup || isAddPaymentOnShortSignup) && selectedPlan &&
        <>
            <div className="PaymentInsideStripe__form-dueToday">
                ${isTrialPlanOnSignup ? '0' : displayIntegerOrTwoDecimalPlaces(selectedPlan?.price.amount)} Due today
            </div>
            <div className="PaymentInsideStripe__form-actions">
                {(!settings && !isAddPaymentOnShortSignup) && (
                    <button className="btn-tetriary-blue" onClick={onStepBack}>
                        Back
                    </button>
                )}
                <input
                    key="nextfrompayment"
                    type="submit"
                    className="btn-primary-full nextfrompayment"
                    value={confirmBtn || "Confirm"}
                />
            </div>
        </>

    const confirmButtonElement = (btnCopy = 'Confirm', btnClass = 'btn-primary') => {
        return (
            <button
                className={btnClass}
                disabled={
                    collectAlsoAddressData &&
                    (!addressData.streetTwo && Object.values({...addressData, ...ccData}).filter(el => el).length < 8
                    || addressData.streetTwo && Object.values({...addressData, ...ccData}).filter(el => el).length < 9)
                }
            >
                {btnCopy}
            </button>
        )
    };

    const actionsChangePlan = isChangePlan &&
        <div className="PaymentInsideStripe__form-actions">
            <button className="btn-secondary" onClick={() => onStepBack()}>Cancel</button>
            {confirmButtonElement()}
        </div>

    const actionsUpdatePaymentInSettings = isUpdatePaymentInSettings &&
        <div className="PaymentInsideStripe__form-actions">
            <div
                className="btn-secondary"
                onClick={() => billingStore?.setIsViewBilling(true)}
            >
                Cancel
            </div>
            {confirmButtonElement()}
        </div>

    const actionsAddPaymentAndFinishTrial = isAddPaymentAndFinishTrial &&
        <div className="PaymentInsideStripe__form-actions oneElement">
            {confirmButtonElement()}
        </div>

    const actionsReactivateFromCancelled = isReactivateFromCancelled &&
        <div className="PaymentInsideStripe__form-actions oneElementOnLeft">
            {confirmButtonElement('Reactivate')}
        </div>

    const actionsAddPaymentCoupon = isAddPaymentCoupon &&
        <div className="PaymentInsideStripe__form-actions oneElement">
            {confirmButtonElement('Redeem Now', 'btn-primary-full')}
        </div>;

    const renderCardElements = !hideInput &&
        <>
            <div className="PaymentInsideStripe__form-input">
                <label className="PaymentInsideStripe__form-input-card">
                    Credit Card
                    <CardNumberElement style={{ base: ccFieldsStyling }} onChange={(e) => setCCDetails('cardNumber', e)} />
                </label>
                <label className="PaymentInsideStripe__form-input-exp">
                    Exp
                    <CardExpiryElement style={{ base: ccFieldsStyling }} onChange={(e) => setCCDetails('cardExpiry', e)} />
                </label>
                <label className="PaymentInsideStripe__form-input-cvc">
                    CVC
                    <CardCvcElement style={{ base: ccFieldsStyling }} onChange={(e) => setCCDetails('cardCvc', e)} />
                </label>
            </div>
            {collectAlsoAddressData &&
                <>
                    <FormField label="Billing Address" htmlFor="street">
                        <input
                            type="text"
                            name="street"
                            placeholder="Address"
                            defaultValue={addressData.street}
                            className='form-input'
                            onChange={(e) => setAddressElement('street', e.target.value)}
                        />
                    </FormField>
                    <FormField label="Apt, suite, etc." htmlFor="streetTwo">
                        <input
                            type="text"
                            name="streetTwo"
                            placeholder="Apt, suite, etc. (optional)"
                            defaultValue={addressData.streetTwo}
                            className='form-input'
                            onChange={(e) => setAddressElement('streetTwo', e.target.value)}
                        />
                    </FormField>
                    <div className="form-row">
                      <FormField label="City" htmlFor="city">
                        <input
                          type="text"
                          name="city"
                          placeholder="City"
                          defaultValue={addressData.city}
                          className='form-input'
                          onChange={(e) => setAddressElement('city', e.target.value)}
                        />
                      </FormField>
                        <FormField key={'state' + addressData.country} label={addressData.country === 'CA' ? 'Province' : 'State'} htmlFor="state">
                            <SignupSelect
                                data-test="state"
                                key={addressData.state || '1'}
                                options={addressData.country === 'CA' ? provincesCA : statesUS}
                                selectedOptionValue={addressData.state}
                                placeholder={addressData.country === 'CA' ? 'Province' : 'State'}
                                onChange={(e) => setAddressElement('state', e.value as statesOfUS)}
                                classname="onRight"
                            />
                        </FormField>
                    </div>
                    <div className="form-row">
                        <FormField label="Zipcode" htmlFor="postalCode">
                            <input
                                type="text"
                                name="postalCode"
                                placeholder={addressData.country === 'CA' ? 'K2C3G1' : '12345'}
                                defaultValue={addressData.postalCode}
                                className='form-input'
                                onChange={(e) => setAddressElement('postalCode', e.target.value)}
                            />
                        </FormField>
                          <FormField label="Country" htmlFor="country">
                            <SignupSelect
                              data-test="country"
                              options={countries}
                              selectedOptionValue={addressData.country}
                              placeholder="Country"
                              onChange={(e) => setCountry(e.value)}
                              classname="onLeft"
                            />
                          </FormField>
                    </div>
                </>
            }
        </>

    return (
        <form
            className={classNames('PaymentInsideStripe__form', {dontCenter : isReactivateFromCancelled})}
            onSubmit={actionClicked}
        >
            {renderCardElements}
            {
                actionsChangePlan ||
                actionsAddPaymentOnSignup ||
                actionsUpdatePaymentInSettings ||
                actionsAddPaymentAndFinishTrial ||
                actionsReactivateFromCancelled ||
                actionsAddPaymentCoupon
            }
        </form>
    );
};

export default CCDetailsForm;
