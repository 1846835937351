import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { withInvoicesStore, WithInvoicesStoreProps } from '@src/stores/with-store';
import { Loader } from '@src/components';

import './invoices.sass';

export const InvoicesSingleDetailsWithStore: React.FC<WithInvoicesStoreProps> = (
    props: WithInvoicesStoreProps
) => {
    const store = props.InvoicesStore;
    const [loading, setLoading] = useState(false);
    const singleInvoiceTableData = [
        { key: 'date', value: 'Date' },
        { key: 'number', value: 'Number' },
        { key: 'payment', value: 'Payment Method' },
        { key: 'retailer', value: 'Retailer' },
        { key: 'subscription', value: 'Subscription' },
        { key: 'tax', value: 'Tax' },
        { key: 'price', value: 'Price' },
    ];
    const { invoiceId } = useParams() as {invoiceId?: string};
    const transformPrice = (price: number) => (price ? `$${(price / 100).toFixed(2)}` : undefined);
    const invoice = store.invoices
        .filter((e) => e.id === invoiceId)
        .map((el) => ({
            id: el.id,
            date: moment.unix(el.created).format('MM/DD/YYYY'),
            number: el.number,
            payment: store.billingAccount.card?.last4 ? `${store.billingAccount.card?.brand || ''} (#### #### #### ${store.billingAccount.card?.last4})` : '',
            retailer: 'Myopolis',
            subscription: el.lines.data.map((d) => d.description).join(', '),
            tax: transformPrice(el.tax) || 'N/A',
            price: transformPrice(el.amount_due),
            pdf: el.invoice_pdf,
        }))[0];

    const showLoader = () => {
        setLoading(true);
        setTimeout(() => setLoading(false), 4000);
    };

    return (
        <div className="InvoicesSingleDetails">
            <Loader loading={loading} />
            <Link className="InvoicesSingleDetails__back btn-tetriary" to={'/settings/invoices'}>
                &lt; Invoices List
            </Link>
            <div className="InvoicesSingleDetails__title">
                <div>Invoice {invoice?.number}</div>
                <a href={invoice?.pdf} className="btn-primary" onClick={() => showLoader()}>
                    Print
                </a>
            </div>
            <div className="InvoicesSingleDetails__table">
                {singleInvoiceTableData.map((s) => (
                    <div key={s.key} className="InvoicesSingleDetails__table-row">
                        <div className="key">{s.value}</div>
                        <div className="value">
                            <span className="smallScreen">{s.value}:&nbsp;</span>
                            {invoice ? invoice[s.key] : ''}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export const InvoicesSingleDetails = withInvoicesStore(InvoicesSingleDetailsWithStore);
