import api from '@src/api/api';

export const fbAuthorize = () => {
    return api.get('/fb/authorize');
};

export const fbAuthorized = (code) => {
    return api.get('/fb/authorized', { params: { code: code } });
};

export const fbConnect = (id) => {
    return api.get(`/fb/connect/${id}`);
};

export const googleAuthorized = (code) => {
    return api.get('/g/authorized', { params: { code: code } });
};

export const googleConnect = (id, placeId) => {
    return api.get(`/g/location`, { params: { name: id, placeId: placeId } });
};

export const addReviewsSite = (data) => {
    return api.post('/reviewsites/addj', data);
};

export const updateSite = (data) => {
    return api.put('/reviewsites/updatej', data);
};

export const orderSite = (order) => {
    return api.post('/reviewsites/order', { reviewSiteIds: order });
};

export const allUserSites = () => {
    return api.get('/reviewsites');
};

export const userSites = () => {
    return api.get('/reviewsites/data');
};

export const deleteSite = (id) => {
    return api.post(`/reviewsites/${id}`);
};

export const activateSite = (id) => {
    return api.post(`/reviewsites/${id}/activate`);
};

export const deactivateSite = (id) => {
    return api.post(`/reviewsites/${id}/deactivate`);
};

export const gAuthorize = () => {
    return api.get('/g/authorize');
};

export const gAuthorized = (code) => {
    return api.get('/g/authorize', { params: { code: code } });
};

export const integrationsInfo = () => {
    return api.get('/crmintegrations/info');
};

export const removeIntegration = (id: string) => {
    return api.delete(`/crmintegrations/${id}`)
};