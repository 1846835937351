import add from './icons/add.svg';
import addCircle from './icons/add-circle.svg';
import addContact from './icons/add-contact.svg';
import arrow from './icons/arrow.svg';
import arrowDown from './icons/arrow-down.svg';
import arrowLeft from './icons/arrow-left.svg';
import arrowLeftBlack from './icons/arrow-left-black.svg';
import attach from './icons/attach.svg';
import attention from './icons/attention.svg';
import chat from './icons/chat.svg';
import checkGreen from './icons/check-green.svg';
import checkGreenFull from './icons/checkmark-green-full.svg';
import checkGreenBig from './icons/check-green-big.svg';
import checkGrey from './icons/check-grey.svg';
import checkmarkSelected from './icons/checkmark-selected-b.svg';
import checkmarkUnselected from './icons/checkmark-unselected.svg';
import clear from './icons/clear.svg';
import clearRed from './icons/clear-red.svg';
import clone from './icons/clone.svg';
import deleteTagGroup from './icons/deleteTagGroup.png';
import dropdownArrow from './icons/dropdown-arrow.svg';
import editPencil from './icons/edit.svg';
import editPencilOutline from './icons/edit-outline.svg';
import facebookCentered from './icons/facebook-centered.svg';
import facebookCircle from './icons/fb.svg';
import fb from './icons/fb.svg';
import fbWhite from './icons/fb-white.png';
import fileEmpty from './icons/file-empty.svg';
import flag from './icons/flag.svg';
import google from './icons/google.svg';
import googleCentered from './icons/google-centered.svg';
import googleCircle from './icons/google.svg';
import greenMostPopular from './icons/green.png';
import groups from './icons/groups.svg';
import highfive from './icons/highfive.png';
import info from './icons/info.svg';
import infoIcon from './icons/icon-info.svg';
import link from './icons/link.svg';
import logo from './logo.svg';
import message from './icons/message.svg';
import messageOpen from './icons/message-open.svg';
import messageYellow from './icons/icon-review-respond.svg';
import moreSelected from './icons/more-selected.svg';
import notes from './icons/notes.svg';
import people from './icons/people.svg';
import phone from './icons/phone.svg';
import phone2 from './icons/phone2.svg';
import phoneIcon2 from './icons/phone-icon2.png';
import illustrationPhone from './icons/illustration-phone.png';
import planHouseImg from './icons/plan-house-img.png';
import plus from './icons/plus.svg';
import profileEdit from './icons/profile-edit.svg';
import profileEditBlack from './icons/profile-edit-black.svg';
import profileOutline from './icons/profile_outline.svg';
import resolve from './icons/resolve.svg';
import reviewsB from './icons/reviews-b.svg';
import reviewUpdated from './icons/review-updated.svg';
import sendfirstmsg from './icons/sendfirstmsg.png';
import tagOutlinedGrey from './icons/tag-outlined-grey.svg';
import template from './icons/template.svg';
import trash from './icons/trash.svg';
import unlinked from './icons/unlinked.svg';
import upload from './icons/upload.svg';
import webchatIcon from './icons/webchat.svg';
import webchatIlustration from './icons/webchat-ilustration.png';
import webchatBubble from './icons/webchat-bubble.png';
import webchatBubble2 from './icons/webchat-bubble2.png';
import yelpCentered from './icons/yelp-centered.svg';
import hubspot from './icons/hubspot.svg';

export const images: { [key: string]: string } = {
    add,
    addCircle,
    addContact,
    arrow,
    arrowDown,
    arrowLeft,
    arrowLeftBlack,
    attach,
    attention,
    chat,
    checkGreen,
    checkGreenFull,
    checkGreenBig,
    checkGrey,
    checkmarkSelected,
    checkmarkUnselected,
    clear,
    clearRed,
    clone,
    deleteTagGroup,
    dropdownArrow,
    editPencil,
    editPencilOutline,
    facebookCentered,
    facebookCircle,
    fb,
    fbWhite,
    fileEmpty,
    flag,
    google,
    googleCentered,
    googleCircle,
    greenMostPopular,
    groups,
    highfive,
    info,
    infoIcon,
    link,
    logo,
    message,
    messageOpen,
    messageYellow,
    moreSelected,
    notes,
    people,
    phone,
    phone2,
    phoneIcon2,
    illustrationPhone,
    planHouseImg,
    plus,
    profileEdit,
    profileEditBlack,
    profileOutline,
    resolve,
    reviewsB,
    reviewUpdated,
    sendfirstmsg,
    tagOutlinedGrey,
    template,
    trash,
    unlinked,
    upload,
    webchatIcon,
    webchatIlustration,
    webchatBubble,
    webchatBubble2,
    yelpCentered,
    hubspot,
};
