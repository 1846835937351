import React, { useEffect, useState } from 'react';
import './DetailsCard.sass';
import { Broadcast } from '@src/stores/models/broadcast_new';
import Message from '../Message/Message';
import ProgressBar from '../ProgressBar/ProgressBar';
import { formatScheduleTime, dateToScheduleTime } from '../helpers/date';
import { format } from 'date-fns';
import { getEstimatedTimeInMins } from '../CreateBroadcastCard/CreateBroadcastCard';
import { stringToTextWithPrompts } from '../helpers/prompts';
import { Attachments } from '@src/components/Attachments';

interface BroadcastDeliveryBarProps {
    totalRecipients: number
};

const BroadcastDeliveryBar: React.FunctionComponent<BroadcastDeliveryBarProps> = ({ totalRecipients }) => {
    const estimatedMinutes = getEstimatedTimeInMins(totalRecipients);
    const [remainingMinutes, setRemainingMinutes] = useState<number>(estimatedMinutes);

    useEffect(() => {
        let timer = setTimeout(() => remainingMinutes > 0 && setRemainingMinutes(prevRemainingMinutes => prevRemainingMinutes - 1), 1000);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <ProgressBar
            title="Broadcast in progress"
            caption={`Estimated time remaining: ${remainingMinutes} minute${remainingMinutes !== 1 && 's'}`}
            percent={(estimatedMinutes - remainingMinutes) / estimatedMinutes * 100}
        />
    )
};

interface CardContentProps {
    broadcast: Broadcast;
    timezone: string;
};

const CardContent: React.FunctionComponent<CardContentProps> = ({ broadcast, timezone }) => {
    const { status } = broadcast;

    const renderAttachments = broadcast.attachments && <div className="Broadcasts-details-card__attachments-container">
        <Attachments
            attachments={broadcast.attachments}
        />
    </div>;

    const renderScheduled = status === 'SCHEDULED' && (
        <div className="Broadcasts-details-card__scheduled-container">
            <div className="header">
                <h5><span>Your Broadcast will publish on</span></h5>
                <h5>{format(broadcast.scheduled, 'MMMM d, yyyy')} at {formatScheduleTime(dateToScheduleTime(broadcast.scheduled, timezone))}</h5>
            </div>
            <Message message={stringToTextWithPrompts(broadcast.text)}/>
            {renderAttachments}
        </div>
    );

    const renderSent = ['RUNNING', 'COMPLETED', 'CANCELLED'].includes(status) && (
        <div className="Broadcasts-details-card__sent-container">
            <Message
                message={stringToTextWithPrompts(broadcast.text)}
                caption={`${broadcast.employeeFirstName} ${broadcast.employeeLastName} ${format(broadcast.created, 'h:mm aa')} ${format(broadcast.created, 'MM/dd/yyyy')}`}
            />
            {renderAttachments}
            {status === 'RUNNING' && <BroadcastDeliveryBar totalRecipients={broadcast.totalRecipients}/>}
        </div>
    );

    const renderContent = renderScheduled || renderSent;

    return (
        <>
            <div className={`Broadcasts-details-card__card-content ${status.toLowerCase()}`}>
                {renderContent}
            </div>
        </>
    );
};

export default CardContent;
