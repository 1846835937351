import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { displayNameOrPhone } from '@src/theme/utils/helpers';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import {
    Option,
    Select,
    Attachments,
    AttachmentFile,
    VariableInput
} from '@src/components';
import {
    Attachment,
    HashMap,
    Template,
    ParsedPrompts
} from '@src/stores/models';
import { ReactComponent as AttachmentIcon } from '@src/theme/icons/attach.svg';
import { ReactComponent as TemplateIcon } from '@src/theme/icons/template.svg';
import { ReactComponent as ArrowLeftIcon } from '@src/theme/icons/arrow-left.svg';
import { ReactComponent as MsgOpenIcon } from '@src/theme/icons/message-open.svg';
import { ReactComponent as MsgResolveIcon } from '@src/theme/icons/resolve.svg';
import { ReactComponent as InfoIcon } from '@src/theme/icons/info.svg';
import { TemplatesMes } from '..';
import { Chat } from '../Chat/Chat';
import { CustomerChat } from '../Chat/CustomerChat';
import {
    STORES,
    withStores,
    WithThreadsStoreProps,
    WithConversationsStoreProps,
} from '@src/stores/with-store';

import './message.sass';
import '../messages-common.sass';
import { DraftEditorLocalStore } from '@src/components/VariableInput/DraftEditorLocalStore';

let loaded: HashMap<Attachment> = {};

interface MessageProps {
    type?: string;
    expanded?: boolean;
    changeExpanded?: () => void;
    closeDialog?: () => void;
}

type props = MessageProps
    & WithThreadsStoreProps
    & WithConversationsStoreProps;

const MessageWithStore: React.FC<props> = (props) => {
    const ThreadsStore = props.ThreadsStore;
    const ConversationsStore = props.ConversationsStore;

    const viewCustomer = props.type === 'customer';
    const viewNotes = props.type === 'notes';
    const viewTeam = props.type === 'teamDirect' || props.type === 'teamChannel';
    const viewTeamDirect = props.type === 'teamDirect';
    const viewTeamChannel = props.type === 'teamChannel';

    const attachmentRef = useRef(null);
    const history = useHistory();
    const { currentThread, employees, templates } = ThreadsStore;
    const [attachments, setAttachments] = useState<AttachmentFile[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<string>('');
    const [showTemplates, setShowTemplates] = useState(false);
    const [isOpen, setOpen] = useState(currentThread?.open ? currentThread?.open : false);
    const [draftEditorLocalStore] = useState(() => new DraftEditorLocalStore())
    const [focusOnMsg, setFocusOnMsg] = useState(false);
    const id = viewCustomer && currentThread?.id ||
            viewTeamChannel && ConversationsStore?.SelectedChannelStore?.selectedChannel?.id ||
            viewTeamDirect && ConversationsStore?.SelectedDirectConversationStore?.selectedDirect?.id;

    const typedMessage = useMemo<string>(() => {
        if (id && JSON.parse(localStorage.getItem('typedMessages'))) {
            return JSON.parse(localStorage.getItem('typedMessages'))[id];
        };
    }, [id]);

    const [message, setMessage] = useState<string>(typedMessage || '');

    ConversationsStore.SelectedChannelStore.onError = (message) => toast.error(message);
    ConversationsStore.SelectedDirectConversationStore.onError = (message) => toast.error(message);

    useEffect(() => {
        loaded = {};
    }, []);

    useEffect(() => {
        setOpen(currentThread?.open ? currentThread?.open : false);
    }, [currentThread]);

    const saveTypedMsg = () => {
        const typedMessages = JSON.parse(localStorage.getItem('typedMessages'));
        const newData = {...typedMessages, [id]: message};

        if (id) {
            localStorage.setItem('typedMessages', JSON.stringify(newData));
        };
    };

    const clearTypedMsg = () => {
        const typedMessages = JSON.parse(localStorage.getItem('typedMessages'));
        const newData = {...typedMessages, [id]: ''};

        if (id) {
            localStorage.setItem('typedMessages', JSON.stringify(newData));
        };
    };

    useEffect(() => {
        if (message?.length) {
            saveTypedMsg();
        };
    }, [message]);

    useEffect(() => {
        setShowTemplates(false);

        if (id && typedMessage) {
            setMessage(typedMessage || '');
            const doFocus = setTimeout(() => {
                setFocusOnMsg(true);
                const undoFocus = setTimeout(() => {
                    setFocusOnMsg(false);
                }, 100);
                return () => clearTimeout(undoFocus);
            }, 100);
            return () => clearTimeout(doFocus);
        }
    }, [typedMessage]);

    const usersWithNone = useMemo<Option<string>[]>(() => {
        return [
            {label: 'Unassign', value: '' },
            ...employees ? Object.values(employees).map((c) => ({ label: `${c.firstName} ${c.lastName}`, value: c.id })): []
        ]
    }, [employees]);

    if (
        !currentThread?.customer &&
        !ConversationsStore.SelectedChannelStore.selectedChannel &&
        !ConversationsStore.SelectedDirectConversationStore.selectedDirect
    ) {
        return null;
    }

    const isNewThread = !currentThread?.id;
    const customer = currentThread?.customer;
    const assignedEmployeeId = currentThread?.assignedMember ? currentThread?.assignedMember : null;

    const attachmentsChange = (loadedAtt: Attachment) => (loaded[loadedAtt.id] = loadedAtt);
    const removeAttachment = (attId: string) => {
        delete loaded[attId];
        const idx = attachments.findIndex((x) => x.id === attId);
        if (idx < 0) {
            return;
        }
        attachments.splice(idx, 1);
        setAttachments([...attachments]);
    };

    const assignUser = (user: Option<string>) => {
        if (user && customer && user.value === customer.id) {
            return;
        }
        ThreadsStore.assignUser(user.value);
    };

    const onFilesAdded = (files: FileList) => {
        const newAttachments = [];
        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);
            file['id'] = uuidv4();
            newAttachments.push(file);
        }
        setAttachments(attachments.concat(newAttachments));
        attachmentRef.current.value = '';
    };

    const templateSelected = (template: Template) => {
        if (template.attachments.length > 0) {
            const atts = template.attachments.map((elem) => {
                const att = { ...elem, uri: elem.url };
                attachmentsChange(att);
                return att;
            });
            setAttachments(attachments.concat(atts));
        }
        setShowTemplates(false);
        setSelectedTemplate(template.message);

        setTimeout(() => {
            setSelectedTemplate(null);
        }, 100)
    };

    const sendMessage = (m: string) => {
        const trimmedMessage = m?.trim();
        if (!trimmedMessage) {
            setMessage('');
            return;
        };

        const mappedAttachments = Object.values(loaded).map((att) => {
            const loaded = { ...att, id: Math.random().toString(36).slice(2) };
            delete loaded.url;
            return loaded;
        });

        if (viewCustomer || viewTeam) {
            setMessage('');
            setAttachments([]);
            loaded = {};
            clearTypedMsg();
        };

        const req =
            viewCustomer ? ThreadsStore.sendMessage(trimmedMessage, mappedAttachments, isNewThread) :
            viewNotes ? ThreadsStore.postNote(customer.id, trimmedMessage) :
            viewTeamChannel ? ConversationsStore.SelectedChannelStore.sendMessage(trimmedMessage, mappedAttachments) :
            viewTeamDirect ? ConversationsStore.SelectedDirectConversationStore.sendMessage(trimmedMessage, mappedAttachments) : null;

        return req.then(() => {
            if (!viewCustomer && !viewTeam) {
                setMessage('');
                setTimeout(() => {
                    setAttachments([]);
                    loaded = {};
                }, 5);
            };
            if (viewNotes) props.closeDialog();
        });
    };

    const changeState = () => {
        ThreadsStore.toggleOpenState(isOpen).then(() => setOpen(!isOpen));
    };

    const closeThread = () => {
        if (viewCustomer) {
            ThreadsStore.closeCurrentThread().then(() => history.replace({ pathname: '/messages/' }));
        } else if (viewTeamChannel) {
            ConversationsStore.SelectedChannelStore.closeConversation().then(() =>
                history.replace({ pathname: '/messages/' })
            );
        } else if (viewTeamDirect) {
            ConversationsStore.SelectedDirectConversationStore.closeConversation().then(() =>
                history.replace({ pathname: '/messages/' })
            );
        }
    };

    const headerLeftSide =
        <div key="left" className={classNames("left", { thereIsContentOnRight: (viewTeam || (viewCustomer && isNewThread))})}>
            <button onClick={() => closeThread()}>
                <ArrowLeftIcon />
            </button>
            {viewCustomer ?
                <div key="1">{displayNameOrPhone(customer)}</div>
            : viewTeamDirect ?
                <div key="3">{ConversationsStore.SelectedDirectConversationStore.selectedDirect?.colleagueName}</div>
            : viewTeamChannel ?
                <div key="4">{ConversationsStore.SelectedChannelStore.selectedChannel?.channelName}</div>
            : null}
        </div>

    const threadIsNotNew = viewCustomer && !isNewThread && (
        <>
            <Select
                options={usersWithNone}
                value={assignedEmployeeId}
                placeholder="Assign to someone"
                onChange={(e) => assignUser(e)}
                classname="select"
            />
            {isOpen && (
                <button
                    className="open-btn-resolve"
                    onClick={() => changeState()}
                    title="Close thread"
                >
                    <MsgResolveIcon />
                </button>
            )}
            {!isOpen && (
                <button
                    className="open-btn-open"
                    onClick={() => changeState()}
                    title="Open thread"
                >
                    <MsgOpenIcon />
                </button>
            )}
            {props.expanded && (
                <button className="info-btn" onClick={() => props.changeExpanded()}>
                    <InfoIcon />
                </button>
            )}
        </>
    );

    const headerRightSide =
        <div key="right" className="right">
            {viewCustomer ?
                threadIsNotNew
            : viewTeam && props.expanded ?
                <button className="info-btn" onClick={() => props.changeExpanded()}>
                    <InfoIcon />
                </button>
            : null}
        </div>

    const customerOptedOutComponent = customer?.optedOut && <div className="customerOptedOut">This customer does not agree to receive messages from Myopolis</div>

    const viewNotesComponent = !viewNotes &&
        <div className="action-buttons" key="typebaractionbuttons">
            <label key="typebarlabel">
                <AttachmentIcon key="typebarAttachmentIcon" />
                <input
                    key="typebarinput"
                    ref={attachmentRef}
                    multiple
                    type="file"
                    onChange={(e) => onFilesAdded(e.target.files)}
                />
            </label>
            {!viewTeam &&
                <>
                    <div key="emptydiv" />
                    <button
                        key="showhidetemplates"
                        className={classNames({ clicked: showTemplates })}
                        onClick={() => setShowTemplates(!showTemplates)}
                    >
                        <TemplateIcon key="typebarTemplateIcon" />
                    </button>
                </>
            }
        </div>

    const inputComponent =
        <VariableInput
            draftEditorLocalStore={draftEditorLocalStore}
            onChange={setMessage}
            message={message}
            notes={viewNotes}
            usedTemplate={selectedTemplate}
            activateVariablesView={viewCustomer ? 'viewCustomer' : 'false'}
            prompts={ParsedPrompts({
                customer: currentThread?.customer,
                owner: ThreadsStore.owner,
                constants: ThreadsStore.messagingConstants
            })}
            send={sendMessage}
            focus={focusOnMsg}
        />

    const placeholder = <div/>

    const typebarComponent = <div
            className="typebar"
            key="typebar"
            onKeyUp={() => saveTypedMsg()}
            onMouseDown={() => saveTypedMsg()}
        >
            {viewNotesComponent}
            {inputComponent || placeholder}
        </div>

    const typebar = customerOptedOutComponent || typebarComponent

    const conversationView = !showTemplates &&
        <div className="content">
            <div className={classNames('conversation', { viewNotes, attachmentsSelected: attachments.length })}>
                {(viewCustomer || viewNotes) ?
                    <CustomerChat
                        employeesMap={employees}
                        notesOnly={viewNotes}
                    />
                : viewTeamChannel ?
                    <Chat
                        key={`viewTeamChannel`}
                        type="teamChannel"
                    />
                : viewTeamDirect ?
                    <Chat
                        key={`viewTeamDirect`}
                        type="teamDirect"
                    />
                : null}
            </div>
            <Attachments
                onAttachmentsChange={(att) => attachmentsChange(att)}
                onAttachmentRemove={(att) => removeAttachment(att)}
                attachments={attachments}
            />
        </div>

    const templatesView = showTemplates && (
        <TemplatesMes
            key={templates.length}
            onTemplateSelect={(t) => templateSelected(t)}
        />
    );

    return (
        <section className="Message">
            <div className={classNames("card withDarkHeader", { wide: !props.expanded })}>
                {!viewNotes && (
                    <div className="header">
                        {[headerLeftSide, headerRightSide]}
                    </div>
                )}
                <div className="Message__text">
                    {conversationView || templatesView}
                    {typebar}
                </div>
            </div>
        </section>
    );
};

export const Message = withStores(MessageWithStore,
    [
        STORES.ThreadsStore,
        STORES.ConversationsStore,
    ]);