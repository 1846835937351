import React from 'react';
import classNames from 'classnames';
import { TableHeader } from './Table';

import './table.sass';


interface TableBodyProps<T extends unknown> {
  data: T[];
  headers: TableHeader[];
}

const TableBody = <T extends unknown>(props: TableBodyProps<T>): JSX.Element => {
  const renderCell = (header: TableHeader, data: unknown, idx: number): React.ReactNode => {
      if (header.field) {
          return <>{data[header.field]}</>;
      }

      if (header.template) {
          return <> {header.template(header, data, idx)} </>;
      }

      return null;
  };

  return (
      <div className="Table__body">
          {props.data.map((row, i) => {
              return (
                  <div className="Table__row" key={'row-' + i}>
                      {props.headers.map((col, j) => {
                          const cls = {
                              Table__cell: true,
                              [`col-${col.flexWidth}`]: col.flexWidth,
                          };
                          if (col.cls) {
                              col.cls.forEach((colCls) => (cls[colCls] = true));
                          }
                          return (
                              <div className={classNames(cls)} key={j}>
                                  {renderCell(col, row, i)}
                              </div>
                          );
                      })}
                  </div>
              );
          })}
      </div>
  );
};

export default TableBody;
