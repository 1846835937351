import React from 'react';
import './Button.sass';

export type ButtonType = 'button' | 'submit' | 'reset';
type ButtonFill = 'full' | 'outlined' | 'none';

interface ButtonProps {
    className?: string;
    disabled?: boolean;
    fill?: ButtonFill;
    type?: ButtonType;
    onClick?: () => void;
};

const Button: React.FunctionComponent<ButtonProps> = ({ children, className = '', disabled, fill = 'full', type = 'submit', onClick }) => (
    <button
        type={type}
        className={`button ${'button__' + fill} ${className}`}
        disabled={disabled}
        onClick={onClick}
    >
        {children}
    </button>
);

export default Button;
