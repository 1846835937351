import React from 'react';
import classNames from 'classnames';
import { formatDate } from '@src/theme/utils/helpers';
import { ReactComponent as TagSvg } from '@src/theme/icons/tag-outlined-grey.svg';
import { ReactComponent as ProfileSvg } from '@src/theme/icons/profile_outline.svg';
import { stringToTextWithPrompts } from '../helpers/prompts';

import './ListCard.sass';

export interface ListCardData {
    label: string;
    date?: Date;
    firstTag?: string;
    tagsTotal: number;
    text?: string;
    contacts: number;
};

interface TagProps {
    tag: string;
    className?: string;
};

const Tag: React.FunctionComponent<TagProps> = ({ tag, className }) => (
    <div className={`list-card-bar-item ${className}`}>
        <TagSvg />
        <span className="ellipsis">{tag}</span>
    </div>
);

interface ContactsProps {
    contacts: number;
};

const Contacts: React.FunctionComponent<ContactsProps> = ({ contacts }) => (
    <div className="list-card-bar-item">
        <ProfileSvg />
        <span>{contacts} {`contact${contacts !== 1 ? 's' : ''}`}</span>
    </div>
);

interface ListCardProps {
    card: ListCardData;
    selected: boolean;
    onClick: () => void;
};

const ListCard: React.FunctionComponent<ListCardProps> = ({card, selected, onClick}) => (
    <div className={classNames('list-card', { selected: selected })} onClick={onClick}>
        <header className="card-header">
            <h4 className="ellipsis">{card.label}</h4>
            <span>{formatDate(card.date)}</span>
        </header>
        <div className="card-content">
            <span className="ellipsis">{stringToTextWithPrompts(card.text)}</span>
        </div>
        <footer className="card-footer">
            <div className="list-card-bar">
                {card.tagsTotal > 0 && card.firstTag &&
                    <>
                        <Tag tag={card.firstTag} className="shrink"/>
                        {card.tagsTotal > 1 &&
                            <div className="list-card-bar-item no-shrink">
                                <span>and {card.tagsTotal - 1} more</span>
                            </div>
                        }
                    </>
                }
            </div>
            <div className="list-card-bar no-shrink">
                <Contacts contacts={card.contacts}/>
            </div>
        </footer>
    </div>
);

export default ListCard;
