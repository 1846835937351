import Draft, { convertToRaw, convertFromRaw, EditorState, Modifier } from 'draft-js';
import { action, observable, runInAction } from 'mobx';
import { BlockData, blockToString, convertInputToValue, getBlockWithEntityRanges, getMessageWithPopulatedData } from './draftjsEditorUtils';

export class DraftEditorLocalStore {
    @observable editorState: Draft.EditorState;
    @observable messageJustExceeded1600: boolean;
    @observable mentions;
    @observable id: string;

    @observable result: string;

    constructor() {
        this.id = Math.random().toString();
    };

    @action
    setEditorState(es: Draft.EditorState, mentions?) {
        this.editorState = es;
        if (mentions) {
            this.mentions = mentions;
        }
    }

    @action
    setResult(result: string) {
        if(result.length > 1600) {
            this.setMessageJustExceeded1600(true);
            setTimeout(() => {
                runInAction(() => {
                    this.setMessageJustExceeded1600(false)
                })
            }, 200);
        }
        this.result = result.slice(0, 1600);
    }

    @action
    setMessageJustExceeded1600(x) {
        this.messageJustExceeded1600 = x;
    }

    @action
    insertEmoji(emoji) {
        const utf8Code = emoji.native;
        const contentState = this.editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('emoji', 'IMMUTABLE', { utf8Code });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const contentWithEmoji = Modifier.insertText(contentState, this.editorState.getSelection(), utf8Code, undefined, entityKey);
        const es = EditorState.push(this.editorState, contentWithEmoji, 'insert-characters');
        this.update(es);
        const textWithVars = convertInputToValue(es)
        const mappedValue = getMessageWithPopulatedData(this.mentions, textWithVars);
        this.setResult(mappedValue)

    }

    @action
    update(es: Draft.EditorState) {
        const changeType = es.getLastChangeType()
        if(changeType == "undo") {
            this.setEditorState(es);
            return;
        }
        /** CREATING NEW STATE WITH HIGHLIGHTED VARIABLES (library bug) */
        const contentState = es.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        const newEntityMap = this.mentions.reduce((obj, mention, i) => {
            return {
                ...obj,
                [i]: {
                    type: ':mention',
                    mutability: 'IMMUTABLE',
                    data: { mention }
                },
            };
        }, {});
        const blockToStringWithEntityMap = blockToString(rawContent.entityMap);
        const newBlocks = rawContent.blocks.map((block) => {
            const blockText = blockToStringWithEntityMap(block);
            const result: BlockData = getBlockWithEntityRanges(blockText, this.mentions);
            return {
                ...block,
                ...result
            };
        });
        rawContent.blocks = newBlocks;
        rawContent.entityMap = newEntityMap;


        const newContentState = convertFromRaw(rawContent);

        /** SKIPPING HIGHLIGHTED ENTITY SELECTION */
        const currentSelection = es.getSelection();

        const previousSelection = this.editorState.getSelection();

        const previousContentState = this.editorState.getCurrentContent();
        const previousBlock = previousContentState.getBlockForKey(previousSelection.getAnchorKey());
        const newBlock = newContentState.getBlockForKey(currentSelection.getAnchorKey());
        const previousBlockText = previousBlock.getText();
        const newBlockText = newBlock.getText();

        const wasDelete = changeType == 'delete-character'

        const difference = newBlockText.length - previousBlockText.length + (wasDelete && 1 || 0);

        const nextOffSet = this.editorState.getSelection().getEndOffset() + difference;
        const newSelection = currentSelection.merge({
            focusOffset: nextOffSet,
            anchorOffset: nextOffSet
        });

        const newEditorState = EditorState.forceSelection(EditorState.push(es, newContentState, "change-block-data"), newSelection);

        this.setEditorState(newEditorState);
    }
}
