import { Dialog } from '@src/components/Dialog/Dialog';
import { OnTheGoCard } from '@src/pages/Dashboard/components/OnTheGoCard/OnTheGoCard';
import React from 'react';

import './OnTheGoDialog.sass';

interface OnTheGoDialogProps {
  open?: boolean;
  onClose: () => void;
}

const OnTheGoDialog: React.FunctionComponent<OnTheGoDialogProps> = ({ open, onClose }) => (
  <Dialog className="OnTheGoDialog" open={open} onClose={onClose}>
    <OnTheGoCard contentType='customers' onSubmit={onClose}/>
  </Dialog>
);

export default OnTheGoDialog;
