import React, { useLayoutEffect, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { AsYouType } from 'libphonenumber-js';

import { TwilioError, TwilioPhoneStore } from '@src/stores/twilio-phone.store';
import { STORES, withStores } from '@src/stores/with-store';
import AuthStore from '@src/stores/auth.store';
import { ReactComponent as CallerIcon } from '@src/theme/icons/profile.svg';
import { ReactComponent as PhoneIcon } from '@src/theme/icons/phone-alt.svg';
import { toast } from 'react-toastify';

import './twilio-call.sass';

interface TwilioCallProps {
    TwilioPhoneStore: TwilioPhoneStore;
}

const TwilioCallWithStore: React.FC<TwilioCallProps> = (props) => {
    const lockedAccount = AuthStore.accountStatus === 'LOCKED';
    const store = props.TwilioPhoneStore;
    const history = useHistory();
    const { statusText, canHangUp, canCall, phoneNumber, showCallWindow, sendDigits } = store;

    const onError = (err: TwilioError) => {
        toast.error(`${err.message} - code: ${err.code}`);
    };
    const onNewIncomingWhileOngoingCall = (phone: string) => {
        toast.error(`Another incoming call from ${phone}. Call them back later.`);
    };

    useLayoutEffect(() => {
        store.onError = onError;
        store.onNewIncomingCallWhileOngoingCall = onNewIncomingWhileOngoingCall;
        if (lockedAccount) {
            return;
        }
        store.init();
    }, []);

    useEffect(() => {
        if (history.location.pathname.split('/')[1] !== 'messages' && showCallWindow)
            history.push({ pathname: `/messages/` });
    }, [showCallWindow]);

    const html = (
        <div className={classNames({ TwilioCall: true, open: showCallWindow })}>
            <p>{statusText}</p>
            <div className="caller-icon">
                <CallerIcon className="caller" />
            </div>
            <p className="phone-number">
                {new AsYouType('US').input(('' + phoneNumber).replace(/\D/g, ''))}
            </p>
            <div className="digits">
                {!canCall &&
                    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'].map((el) => {
                        return (
                            <div key={el} className="digit" onClick={() => sendDigits(el)}>
                                {el}
                            </div>
                        );
                    })}
            </div>
            {(canHangUp || canCall) && (
                <div>
                    {canCall && (
                        <button className="phone-btn pick-up" onClick={() => store.pickup()}>
                            <PhoneIcon />
                        </button>
                    )}
                    {canHangUp && (
                        <button className="phone-btn hang-up" onClick={() => store.hangUp()}>
                            <PhoneIcon />
                        </button>
                    )}
                </div>
            )}
        </div>
    );

    return ReactDOM.createPortal(html, document.body);
};

export const TwilioCall = withStores(TwilioCallWithStore, [STORES.TwilioPhoneStore]);
