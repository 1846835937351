import React from 'react';
// import ReactTooltip from 'react-tooltip';
import { ActivePlan } from '@src/stores/models/billing';
import { getProperPriceObj } from '@src/theme/utils/helpers';
import { displayIntegerOrTwoDecimalPlaces } from '@src/theme/utils/helpers';
import { images } from '@src/theme/images';

import './Plan.sass';


interface PlanBrowserProps {
    activePlan: ActivePlan;
    actionBar: React.ReactNode;
    interval: 'month' | 'year';
    featuresList
}

const PlanBrowser: React.FunctionComponent<PlanBrowserProps> = ({ actionBar, activePlan, interval, featuresList }) => {
    const { billingPlan: plan, components } = activePlan;

    const featuresFlags = featuresList.map(f => {
        return {
            id: f.id,
            flag: components.some(c => c.id === f.id),
            hasSubtext: Boolean(f.subtext)
        };
    });

    const featuresListToDisplay = featuresFlags.map(f => {
        return (
            <div className='PricingPlan__ticks-item' key={f.id}>
                {(f.id === 'msg' && plan.billingPlan.messagesPerMonth) ? <div className="messagesPerMonth">{plan.billingPlan.messagesPerMonth}</div>:
                    f.flag ?
                    <img src={images.checkGreenFull} alt="checkmark" /> :
                    <div className="empty" />
                }
            </div>
        )
    });

    return (
        <div className='PricingPlan'>
            <div className="PricingPlan__name">{plan.billingPlan.name}</div>
            {getProperPriceObj(plan, interval) ?
                <>
                    <div className="PricingPlan__priceContainer">
                        {(interval === 'year' && plan.prices.find(el => el.interval.interval === 'month')) &&
                            <div className="PricingPlan__priceContainer-monthPrice">${displayIntegerOrTwoDecimalPlaces(getProperPriceObj(plan, 'month').amount)}</div>
                        }
                        <div className="PricingPlan__priceContainer-price">
                            <div className="PricingPlan__priceContainer-price-amount">
                                ${displayIntegerOrTwoDecimalPlaces(interval === 'year' ? (getProperPriceObj(plan, interval).amount / 12) : getProperPriceObj(plan, interval).amount)}
                            </div>
                            <div className="PricingPlan__priceContainer-price-perMonth">/mo</div>
                        </div>
                    </div>
                    <div className="PricingPlan__action">
                        {actionBar}
                    </div>
                    <div className="PricingPlan__ticks">
                        {featuresListToDisplay}
                    </div>
                </>
                : <>
                    <div className="PricingPlan__priceContainer">
                        <div className="PricingPlan__priceContainer-price"></div>
                        <div className="PricingPlan__priceContainer-monthly">ONLY AVAILABLE CHARGED PER {interval === 'month' ? 'YEAR' : 'MONTH'}</div>
                    </div>
                    <div className="PricingPlan__action"></div>
                    <div className="PricingPlan__ticks">
                        {featuresListToDisplay}
                    </div>
                </>
            }
        </div>
    );
};

export default PlanBrowser;
