import 'tslib';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import { App } from './app';
import './theme/theme.sass';

import AuthStore from './stores/auth.store';
import BillingStore from '@src/stores/billing.store';
import InvoicesStore from '@src/stores/invoices.store';
import CustomersStore from '@src/stores/customers.store';
import CustomerStore from '@src/stores/customer.store';
import DashboardStore from '@src/stores/dashboard.store';
import SignupStore from '@src/stores/signup.store';
import LeaderboardStore from '@src/stores/leaderboard.store';
import MessagesStore from '@src/stores/messages/messages.store';
import NotificationsStore from '@src/stores/notifications.store';
import PersonalSettingsStore from '@src/stores/personal-settings.store';
import PhoneStore from '@src/stores/phone.store';
import ProfileStore from '@src/stores/profile.store';
import ReviewsStore from '@src/stores/reviews.store';
import ReviewSitesStore from './stores/review-sites.store';
import IntegrationsStore from './stores/integrations.store';
import TagsStore from './stores/tags/tags.store';
import TeamStore from '@src/stores/team.store';
import WebchatStore from '@src/stores/webchat.store';
import TemplateStore from '@src/stores/template.store';
import UserStore from './stores/user.store';
import ImportCustomersStore from '@src/stores/import-customers.store';
import TwilioPhoneStore from '@src/stores/twilio-phone.store';
import ConversationsStore from '@src/stores/teamchat/conversations.store';
import BroadcastsStore from './stores/broadcasts.store';
import WalkthroughStore from '@src/stores/walkthrough.store';
import CouponStore from '@src/stores/coupon.store';

const { ThreadsStore } = MessagesStore;

const stores = {
    AuthStore,
    BillingStore,
    BroadcastsStore,
    InvoicesStore,
    ConversationsStore,
    CustomersStore,
    CustomerStore,
    DashboardStore,
    ImportCustomersStore,
    SignupStore,
    LeaderboardStore,
    MessagesStore,
    ThreadsStore,
    NotificationsStore,
    PersonalSettingsStore,
    PhoneStore,
    ProfileStore,
    ReviewsStore,
    ReviewSitesStore,
    IntegrationsStore,
    TagsStore,
    TeamStore,
    WebchatStore,
    TemplateStore,
    TwilioPhoneStore,
    UserStore,
    WalkthroughStore,
    CouponStore
};

const root = document.getElementById('app');

ReactDOM.render(
    <Provider {...stores}>
        <App />
    </Provider>,
    root
);
