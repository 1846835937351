import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { StripeProvider } from 'react-stripe-elements';
import { Elements } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { STORES, WithBillingStoreProps, WithSignupStoreProps, WithAuthStoreProps, withStores } from '@src/stores/with-store';
import { PaymentInsideStripe, Loader } from '@src/components';
import PlansListBrowser from './PlansListBrowser';
import PlansListMobile from './PlansListMobile';
import { getBillingPlanAndPriceByPriceId } from '@src/theme/utils/helpers';
import { ERROR_TOAST_AUTOCLOSE } from '@src/theme/utils/constants';
import { ReactComponent as TalkBlue } from '@src/theme/icons/web chat blue.svg';
import { ReactComponent as TalkWhite } from '@src/theme/icons/web chat white.svg';
import { ReactComponent as InfoIcon } from '@src/theme/icons/icon-info.svg';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import './pricing.sass';

interface SignupPricingProps {
    isSignup?: boolean,
    onPlanChange?: ({priceId: string, buyNow: boolean}) => void;
}

const tip = 'Each message segment can have up to<br />160 standard characters. Longer<br />messages will count as multiple<br />segments.';

type PlanInterval = 'month' | 'year';

type PricingProps = WithBillingStoreProps & WithSignupStoreProps & SignupPricingProps & WithAuthStoreProps;

const PricingWithStores: React.FunctionComponent<PricingProps> = ({BillingStore, SignupStore, AuthStore, isSignup, onPlanChange}) => {
    const history = useHistory();
    const [renewInterval, setRenewInterval] = useState<PlanInterval>('month');
    const isCCAdded = Boolean(BillingStore.billingAccount?.card?.brand);
    const [selectedPlanPriceId, setSelectedPlanPriceId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cancellingTrial, setCancellingTrial] = useState(false);
    const [showWalls, setShowWalls] = useState(true);
    const plansArr = isSignup ? SignupStore.plans : BillingStore.activePlans;
    const pricingMainRef = useRef(null);
    const pricingPlansTableRef = useRef(null);

    BillingStore.onError = null;

    const featuresList = useMemo(() => {
        if (plansArr?.length) {
            let list: any = [{
                description: 'Message segments per month',
                id: 'msg',
                name: 'Messages',
                seqNumber: 0
            }];

            plansArr.forEach(p => {
                p.components.forEach(c => {
                    if (list.some(co => co.id === c.id)) {
                        return;
                    } else {
                        const el = {...c, subtext: c.items && c.items.length && c.items[0].text};
                        delete el.items;
                        list = [...list, el];
                    };
                });
            });

            list = [...list].sort((a, b) => a.seqNumber - b.seqNumber);

            return list;
        };
    }, [plansArr]);

    useEffect(() => {
        if (!isSignup) {
            BillingStore.getAndSetBilling('getBE');
            BillingStore.getActivePlans();
        }
    }, []);

    useEffect(() => {
        if (pricingPlansTableRef.current?.offsetWidth + 50 >= pricingMainRef.current?.offsetWidth) {
            setShowWalls(true);
        } else {
            setShowWalls(false);
        };
    }, [pricingPlansTableRef.current, pricingPlansTableRef.current?.offsetWidth, pricingMainRef.current, pricingMainRef.current?.offsetWidth, plansArr]);

    const selectedPlanAllDetails = useMemo(() => {
        if (selectedPlanPriceId) {
            return getBillingPlanAndPriceByPriceId(plansArr, selectedPlanPriceId);
        }
    }, [selectedPlanPriceId]);

    const handleCancelTrial = (priceId: string) => {
        setSelectedPlanPriceId(priceId);
        setCancellingTrial(true);
    };

    const handleComingBackFromAddingPayment = () => {
        setSelectedPlanPriceId(null);
        if (cancellingTrial) {
            setCancellingTrial(false);
        };
    };

    const changePlanInApp = (newPriceId: string, paymentAdded?: boolean) => {
        if (cancellingTrial) {
            setLoading(true);
            BillingStore.cancelTrial('cancelTrial')
            .then((val) => {
                setLoading(false);
                if (val && val[0]) {
                    history.push('/dashboard');
                    window.scrollTo(0, 0);
                };
            })
        } else if (isCCAdded || paymentAdded) {
            setLoading(true);
            BillingStore.changeSubscription(
                BillingStore.currentSubscription?.id,
                newPriceId,
                'changeSubscription'
            ).then((val) => {
                setLoading(false);
                if (val !== 'fail') {
                    if (paymentAdded) {
                        AuthStore.updateAuth();
                    }
                    history.push('/dashboard');
                    window.scrollTo(0, 0);
                } else {
                    toast.error('Something went wrong, please try again later', ERROR_TOAST_AUTOCLOSE);
                };
            })
        } else {
            setSelectedPlanPriceId(newPriceId);
        };
    };

    const header =
        <div className="Pricing__header" key="header">
            <div className="Pricing__title1">Simple plans that grow with you</div>
            <div className="Pricing__title2">
                Affordable, Simple, Transparent<br/>
                - No hidden fees
            </div>
            <div className="Pricing__title3"><span>Just choose a plan</span> that best fits your business.</div>
            <div className="Pricing__title3">Not sure which plan is right for you?</div>
            <a className="Pricing__chatWithSales btn-primary" href="sms:262-279-4058">
                <span className="iconWithoutHover"><TalkBlue /></span>
                <span className="iconOnHover"><TalkWhite /></span>
                Chat with sales
            </a>
            {!!plansArr &&
                <div className="Pricing__switch">
                    <div className="ToggleTabNew">
                        <span className="ToggleTabNew__comment">Monthly</span>
                        <label className="ToggleTabNew__switch">
                            <input type="checkbox" className={renewInterval !== 'month' ? 'checked' : 'unchecked'} defaultChecked={renewInterval !== 'month'} />
                            <span
                                className="slider"
                                onClick={() => {
                                    setRenewInterval(prevInterval => prevInterval === 'month' ? 'year' : 'month')
                                }}
                            />
                        </label>
                        <div className="ToggleTabNew__comment">
                            Annual
                            <p>Save up to 15%</p>
                        </div>
                    </div>
                </div>
            }
        </div>

    const featuresListTitles = !!featuresList &&
        <div className="Pricing__features">
            <div className="Pricing__features-title">FEATURES</div>
            <div className="Pricing__features-list">
                {featuresList.map(el => {
                    return (
                        <div className="Pricing__features-listItem" key={el.id}>
                            <div className={classNames("Pricing__features-listItem-text", { isSubtext: el.subtext })}>
                                {el.description} {el.id === 'msg' &&
                                    <>
                                        <ReactTooltip multiline={true} />
                                        <InfoIcon className="info" data-tip={tip} />
                                    </>
                                }
                            </div>
                            {!!el.subtext && <div className="Pricing__features-listItem-subtext">{el.subtext}</div>}
                        </div>
                    )
                })}
            </div>
        </div>

    const tableBigScreen =
        <div className="Pricing__main bigScreen" ref={pricingMainRef} key="tableBigScreen">
            <div className="Pricing__plansTable" ref={pricingPlansTableRef}>
                {featuresListTitles}
                {showWalls && <div className="Pricing__leftWall" />}
                <div className="Pricing__plansList">
                    {!!plansArr ?
                        <PlansListBrowser
                            onCancelTrial={handleCancelTrial}
                            onPlanChange={onPlanChange}
                            changePlanInApp={changePlanInApp}
                            BillingStore={BillingStore}
                            plansArr={plansArr}
                            isSignup={isSignup}
                            interval={renewInterval}
                            featuresList={featuresList}
                        /> :
                        <Loader loading={true} />
                    }
                </div>
                {showWalls && <div className="Pricing__rightWall" />}
            </div>
        </div>

    const tableMobile =
        <div className="Pricing__main mobile" ref={pricingMainRef} key="tableMobile">
            <div className="Pricing__plansTable" ref={pricingPlansTableRef}>
                {featuresListTitles}
                <div className="Pricing__plansList">
                    {!!plansArr ?
                        <PlansListMobile
                            onCancelTrial={handleCancelTrial}
                            onPlanChange={onPlanChange}
                            changePlanInApp={changePlanInApp}
                            BillingStore={BillingStore}
                            plansArr={plansArr}
                            isSignup={isSignup}
                            interval={renewInterval}
                            featuresList={featuresList}
                        /> :
                        <Loader loading={true} />
                    }
                </div>
            </div>
        </div>

    const footer = !!plansArr &&
        <div className="Pricing__footer" key="footer">
            Don't see your plan? <a className="btn-tetriary-blue" href="sms:262-279-4058">Chat with us</a>
        </div>

    const pricingView = !selectedPlanPriceId && <>{[header, tableBigScreen, tableMobile, footer]}</>

    const paymentView = selectedPlanPriceId &&
        <div className="Pricing__addingCC">
            <StripeProvider apiKey={BillingStore.stripeKey}>
                <Elements>
                    <PaymentInsideStripe
                        paymentCase={'changePlan'}
                        paymentAdded={() => changePlanInApp(selectedPlanPriceId, true)}
                        stepBack={handleComingBackFromAddingPayment}
                        planData={{
                            name: selectedPlanAllDetails.billingPlan.name,
                            price: selectedPlanAllDetails.price.amount,
                            interval: selectedPlanAllDetails.price.interval.interval,
                        }}
                        components={plansArr.find(plan => plan.billingPlan.prices.find(price => price.id === selectedPlanPriceId)).components}
                    />
                </Elements>
            </StripeProvider>
        </div>

    return (
        <div className="card PricingWrapper">
            <Loader loading={loading} />
            <div className="Pricing">
                {pricingView || paymentView}
            </div>
        </div>
    );
};

export const Pricing = withStores(PricingWithStores, [
    STORES.Billing,
    STORES.Signup,
    STORES.Auth
]);
