import React from 'react';
import { images } from '@src/theme/icons/dashboard';

import './stars-rating.sass';

interface StarsRating {
    rate: number;
    max?: number;
}

export const StarsRating: React.FC<StarsRating> = ({ rate, max = 5 }) => {
    const fullNumber = Math.round(rate);
    const stars = [];

    for (let i = 0; i < fullNumber; i++) {
        stars.push('full');
    }

    for (let i = fullNumber; i < max; i++) {
        stars.push('empty');
    }

    const starFull = (i: number): React.ReactNode => (
        <img className="star" key={`key-${i}`} src={images.starFull} alt="star" />
    );
    const starEmpty = (i: number): React.ReactNode => (
        <img className="star" key={`key-${i}`} src={images.starEmpty} alt="empty star" />
    );

    return (
        <div className="StarsRating">
            {stars.map((s, i) => (s === 'full' ? starFull(i) : starEmpty(i)))}
        </div>
    );
};
