import { addRecipients, getAvailableRecipients,  getFromOtherBroadcast,  getRecipients, removeRecipient } from "@src/requests/broadcasts.requests";
import { BroadcastsStore } from "@src/stores/broadcasts.store";
import { AvailableRecipients, CreateBroadcastRecipients, RecipientMemberCustomer, RecipientMemberTag, Recipients, RecipientType } from "@src/stores/models/broadcast_new";
import { displayNameOrPhone } from "@src/theme/utils/helpers";
import { action, observable, runInAction } from "mobx";
import { Option } from './Autocomplete';

export type RecipientOption = Option & {
    type: RecipientType;
    totalNumber: number;
    new?: boolean;
    memberId?: string;
    phone?: string;
};

const SEARCH_PAGE_SIZE = 12
const LOAD_PAGE_SIZE = 30

interface PaginatedQuery<T> {
    results: Array<T>;
    query?: string;
    page: number;
    total?: number;
    totalCount: number;
    moreAvailable: boolean;
    loading: boolean;
    error?: object;
}

const EMPTY_QUERY = {
    results: [],
    page: 1,
    moreAvailable: false,
    loading: false,
    totalCount: 0
}


export const recipientOptionsToEditRecipients = (options: RecipientOption[]): CreateBroadcastRecipients => {
    const editRecipients = {customers: [], tags: [], phones: []}
    options.forEach(option => {
        if (option.type === 'CUSTOMER') {
            option.value !== ''
                ? editRecipients.customers.push(option.value)
                : editRecipients.phones.push(option.label);
        } else if (option.type === 'TAG') {
            editRecipients.tags.push(option.value);
        };
    });
    return editRecipients;
};

const recipientsToRecipientOptions = (recipients: Recipients) => {
    const options = recipients.members.map<RecipientOption>(member => {
        if(member.targetType == "TAG") {
            const tagMember = member as RecipientMemberTag
            return {
                value: tagMember.tagData.id,
                type: tagMember.targetType,
                memberId: tagMember.tagData.id,
                label: tagMember.tagData.value,
                totalNumber: tagMember.tagData.customerCount
            }
        }
        if(member.targetType == "CUSTOMER") {
            const customerMember = member as RecipientMemberCustomer
            return {
                value: customerMember.customerData.id,
                type: customerMember.targetType,
                memberId: customerMember.customerData.id,
                label: displayNameOrPhone(customerMember.customerData),
                phone: customerMember.customerData.phone,
                totalNumber: 1
            }
        }
    })
    return options;
};

const availableRecipientsToRecipientOptions = (recipients: AvailableRecipients) => {
    const tagOptions = recipients.tags.map<RecipientOption>(tag => ({
        value: tag.id,
        type: 'TAG',
        label: tag.value,
        totalNumber: tag.customerCount
    }));

    const customerOptions = recipients.customers.map<RecipientOption>(customer => ({
        value: customer.id,
        type: 'CUSTOMER',
        label: displayNameOrPhone(customer),
        phone: customer.phone,
        totalNumber: 1
    }));

    return [...tagOptions, ...customerOptions];
};


export class BroadcastReceipientsLocalStore {
    broadcastId: string;

    @observable recipientsSearch: PaginatedQuery<RecipientOption> = EMPTY_QUERY
    @observable recipients: PaginatedQuery<RecipientOption> = EMPTY_QUERY

    @observable error = false;

    broadcastsStore: BroadcastsStore;

    constructor(broadcastsStore: BroadcastsStore) {
        this.broadcastsStore = broadcastsStore;
        (window as any).BroadcastReceipientsLocalStore = this;
    }

    get availableRecipients() {
        return this.recipientsSearch.results.filter((x) => !this.recipients.results.find((y) => x.value === y.value))
    }

    @action
    setRecipientsSearchQuery(query: string) {
        this.recipientsSearch = {
            ...EMPTY_QUERY,
            query
        };
        this.runRecipientsSearchQuery(query);
    }

    @action
    loadRecipientsFromOtherBroadcast(broadcastId: string, state: "replied" | "unreplied") {
        this.recipients = {
            ...this.recipients,
            loading: true
        }
        return getFromOtherBroadcast(broadcastId, state).then((results) => {
            runInAction(() => {
                this.recipients = {
                    ...this.recipients,
                    loading: false,
                    totalCount: results.data.length,
                    results: [
                        ...this.recipients.results,
                        ...results.data.map((memberFromOldBroadcast) => {
                            return {
                                value: memberFromOldBroadcast.id,
                                type: "CUSTOMER",
                                memberId: memberFromOldBroadcast.id,
                                label: displayNameOrPhone(memberFromOldBroadcast),
                                totalNumber: 1
                            }
                        })
                    ]
                }
            })

        })
    }

    @action
    setRecipientsQuery(query: string) {
        this.recipients = {
            ...EMPTY_QUERY,
            query
        };
        this.runRecipientsQuery();
    }

    @action
    runRecipientsQuery(id?: string) {
        if (this.recipients.loading) return;
        this.broadcastId = id;
        this.recipients = {
            ...this.recipients,
            loading: true
        };
        return getRecipients(this.broadcastId, this.recipients.page, LOAD_PAGE_SIZE, this.recipients.query).then((res)=> {
            const data: Recipients = res.data
            runInAction(() => {
                const recipientOption = recipientsToRecipientOptions(data);
                const results = [...this.recipients.results, ...recipientOption];
                this.recipients = {
                    ...this.recipients,
                    results,
                    page: this.recipients.page + 1,
                    moreAvailable: results.length < data.total,
                    totalCount: data.totalMembers,
                    loading: false
                }
            })
        }).catch((error) => {
            runInAction(() => {
                this.recipients = {
                    ...this.recipients,
                    loading: false,
                    error
                };
                this.error = error;
            })
        })
    }

    @action
    runRecipientsSearchQuery(query?: string) {
        if (this.recipientsSearch.loading) return;
        this.recipientsSearch = {
            ...this.recipientsSearch,
            loading: true
        };
        return getAvailableRecipients(this.broadcastId, this.recipientsSearch.page, SEARCH_PAGE_SIZE, this.recipientsSearch.query).then((res)=> {
            const data: AvailableRecipients = res.data
            runInAction(() => {
                const recipientOption = availableRecipientsToRecipientOptions(data);
                if (!!query && this.recipientsSearch.query !== query) {
                    return;
                };
                const results = [...this.recipientsSearch.results, ...recipientOption];
                this.recipientsSearch = {
                    ...this.recipientsSearch,
                    results,
                    page: this.recipientsSearch.page + 1,
                    moreAvailable: results.length < data.total,
                    loading: false
                }
            })
        }).catch((error) => {
            runInAction(() => {
                this.recipientsSearch = {
                    ...this.recipientsSearch,
                    loading: false,
                    error
                };
                this.error = error;
            })
        })
    }

    @action
    removeRecipient(recipient: RecipientOption) {
        if(this.broadcastId && !recipient.new && recipient.memberId)  {
            this.recipients = {
                ...this.recipients,
                loading: true
            }
            removeRecipient(this.broadcastId, recipient.memberId).then(() => {
                runInAction(() => {
                    this.recipients = {
                        ...this.recipients,
                        results: this.recipients.results.filter((x) => x != recipient),
                        totalCount: this.recipients.totalCount - recipient.totalNumber,
                        loading: false
                    }
                })
            }).catch((error) => {
                runInAction(() => {
                    this.recipients = {
                        ...this.recipients,
                        loading: false,
                        error
                    };
                    this.error = error;
                })
            })

        } else {
            this.recipients = {
                ...this.recipients,
                results: this.recipients.results.filter((x) => x != recipient),
                totalCount: this.recipients.totalCount - recipient.totalNumber
            }
        }
    }

    @action
    addNewRecipient(newRecipient: RecipientOption) {
        if(this.recipients.results.find((x) => x.value === newRecipient.value)) return;
        if(this.broadcastId)  {
            const requestPayload = recipientOptionsToEditRecipients([newRecipient])
            this.recipients = {
                ...this.recipients,
                loading: true
            }
            addRecipients(this.broadcastId, requestPayload).then((result) => {
                runInAction(() => {
                    this.recipients = {
                        ...this.recipients,
                        results: [...this.recipients.results, {
                            ...newRecipient,
                            memberId: result?.data[0]?.id
                        }],
                        loading: false,
                        totalCount: this.recipients.totalCount + newRecipient.totalNumber
                    }
                })
            }).catch((error) => {
                runInAction(() => {
                    this.recipients = {
                        ...this.recipients,
                        loading: false,
                        error
                    };
                    this.error = error;
                })
            })
        } else {
            this.recipients = {
                ...this.recipients,
                results: [...this.recipients.results, {
                    ...newRecipient,
                    new: true
                }],
                totalCount: this.recipients.totalCount + newRecipient.totalNumber
            }
        }
    }
}
