import React from 'react';
import classNames from 'classnames';

import './popup-hints-bubble-message.sass';

interface PopupHintsBubbleMessageProps {
    hint: string;
    title: string;
    number: number;
    copy: any;
    closeTutorial: () => void;
    goToView: (n: number) => void;
}

export const PopupHintsBubbleMessage: React.FC<PopupHintsBubbleMessageProps> = (props) => {
    const { hint, title, number, copy } = props;

    const primaryButtonClicked = () => {
        if (number === 5) {
            props.closeTutorial();
        } else {
            props.goToView(number + 1);
        };
    };

    const secondaryButtonClicked = () => {
        if (number === 5) {
            props.goToView(1);
        } else {
            props.closeTutorial();
        };
    };

    return (
        <div className={classNames('PopupHintsBubbleMessage', {
            'hint1': hint === 'hint1',
            'hint2': hint === 'hint2',
            'hint3': hint === 'hint3',
            'hint4': hint === 'hint4',
            'hint5': hint === 'hint5'
        })}>
            <div className="PopupHintsBubbleMessage__header">
                <div className="PopupHintsBubbleMessage__header-title">{title}</div>
                <div className="PopupHintsBubbleMessage__header-number">{number}/5</div>
            </div>
            <div className="PopupHintsBubbleMessage__copy">
                {copy.map((c, i) => (
                    <div key={i} className="PopupHintsBubbleMessage__copy-paragraph">
                        {c.subtitle ? <span className="PopupHintsBubbleMessage__copy-paragraph-subtitle">{c.subtitle}&nbsp;</span> : null}
                        {c.content}
                    </div>
                ))}
            </div>
            <div className="PopupHintsBubbleMessage__actions">
                <button
                    className="btn-primary"
                    onClick={() => primaryButtonClicked()}
                >
                    {number === 5 ? 'Close' : 'Next'}
                </button>
                <button
                    className="btn-tetriary"
                    onClick={() => secondaryButtonClicked()}
                >
                    {number === 5 ? 'Start over' : 'Close Tutorial'}
                </button>
            </div>
        </div>
    )
};
