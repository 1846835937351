import api from '@src/api/api';
import {
    ReviewDate,
    ReviewFilter,
    ReviewReply,
    ReviewSourceValue,
    ReviewsGeneral,
    ReviewStatus,
    ReviewWithReplies,
    KeyMetricsStats,
} from '@src/stores/models';
import { ObservableMap } from 'mobx';
import moment from 'moment';

export class ReviewsApi {
    static baseUrl = '/reviews';

    static fetchReviewsGeneral(): Promise<ReviewsGeneral> {
        const url = `${this.baseUrl}/stats`;
        return api.get(url).then((res: { data: { data: ReviewsGeneral } }) => {
            return res.data?.data;
        });
    }

    static fetchReviewsStats(): Promise<KeyMetricsStats> {
        return api.get(this.baseUrl).then((res: { data: { statsData: KeyMetricsStats } }) => {
            return res.data?.statsData;
        });
    }

    static searchReviews(
        filters: ReviewFilter,
        page = 1,
        size = 6
    ): Promise<ReviewsSearchResponse> {
        const url = `${this.baseUrl}/search`;
        const dateRange = this.getDateRange(filters);
        const paramsBase = {
            source: this.getFilterSourceValue(filters.sources),
            search: filters.search.get(),
            recommendation: filters.recommendation.get(),
            rating: filters.rating.get(),
            status: this.getFilterStatusValue(filters.statuses.get()),
            page: page,
            size: size,
            start: dateRange ? dateRange[0] : null,
            end: dateRange ? dateRange[1] : null,
        };
        const params = {};
        Object.keys(paramsBase).forEach((key) => {
            if (!!paramsBase[key]) {
                params[key] = paramsBase[key];
            }
        });

        return api.get(url, { params }).then((res: { data: ReviewsSearchResponse }) => {
            return {
                data: res.data.data,
                total: res.data.total,
            };
        });
    }

    static replyToReview(rid: string, comment: string): Promise<ReviewReply> {
        const url = `${this.baseUrl}/${rid}/reply`;
        return api
            .post(url, {
                message: comment,
                id: rid,
            })
            .then((res) => res.data?.reply);
    }

    static removeReviewReply(rid: string, replyId: string): Promise<void> {
        const url = `${this.baseUrl}/${rid}/reply/${replyId}/delete`;
        return api.delete(url);
    }

    static setGoal(goal: number): Promise<void> {
        const url = `${this.baseUrl}/goal`;
        return api.post(url, { goal });
    }

    static updateReviewReply(rid: string, replyId: string, comment: string): Promise<ReviewReply> {
        const url = `${this.baseUrl}/${rid}/reply/${replyId}`;
        return api
            .put(url, {
                message: comment,
                id: rid,
            })
            .then((res) => res.data?.reply);
    }

    private static getFilterSourceValue(
        sources: ObservableMap<ReviewSourceValue, boolean>
    ): string {
        const enumVals = Object.values(ReviewSourceValue);
        const results: string[] = [];
        enumVals.forEach((val: ReviewSourceValue) => {
            if (sources.get(val)) {
                results.push(val);
            }
        });

        if (!results.length || results.length === enumVals.length) {
            return null;
        }

        return results[0];
    }

    private static getFilterStatusValue(status: ReviewStatus): string {
        return !status || status === ReviewStatus.ALL ? null : status.toLowerCase();
    }

    private static getDateRange(filters: ReviewFilter): [number, number] {
        const dateType = filters.date.get();
        const today = moment().startOf('day');
        if (!dateType) {
            null;
        }
        let startOf = null;
        switch (dateType) {
            case ReviewDate.TODAY:
                const last = today.clone().endOf('day').unix();
                return [today.unix(), last];
            case ReviewDate.CUSTOM:
                const customDateRange = filters.customDate.get();
                if (!customDateRange) {
                    return null;
                }
                const rangeStart = moment(customDateRange[0]).startOf('day').unix();
                const rangeEnd = moment(customDateRange[1]).endOf('day').unix();
                return [rangeStart, rangeEnd];
            case ReviewDate.THIS_WEEK:
                startOf = 'week';
                break;
            case ReviewDate.THIS_MONTH:
                startOf = 'month';
                break;
            case ReviewDate.THIS_YEAR:
                startOf = 'year';
                break;
            default:
                startOf = null;
                break;
        }
        return startOf ? [today.clone().startOf(startOf).unix(), today.unix()] : null;
    }
}

export interface ReviewsSearchResponse {
    data: ReviewWithReplies[];
    total: number;
}
