import api from '@src/api/api';
import { Notifications } from '@src/stores/models';

// export const getUser = () => {
//     return api.get('/signup/user');
// };

export const updateTeamMemberName = (
    id: string,
    firstName: string,
    lastName: string,
    phone: string
) => {
    return api.post(`/team/user/name:${id}`, { id, firstName, lastName, phone });
};

export const updateTeamMemberEmail = (id: string, email: string, emailConfirm: string) => {
    return api.post(`/team/user/email:${id}`, { id, email, emailConfirm });
};

export const changePwd = (data) => {
    return api.post('/user/password/change', data);
};

export const getNotif = (id: string) => {
    return api.get(`/user/${id}/notifications`);
};

export const setNotif = (id: string, data: Notifications) => {
    return api.post(`/user/${id}/notifications`, { ...data });
};

export const updateNotif = (id: string, data: Notifications) => {
    return api.post(`/user/notifications/update?userId=${id}`, { ...data });
};
