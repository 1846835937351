export interface InputWithEmoji<A> {
  input: A;
  emoji: string;
}


export enum Industry {
    CreativeAgency = "Creative Agency",
    Ecommerce = "E-commerce",
    FinancialServices = "Financial Services",
    HealthOrWellness = "Health / Wellness",
    Legal = "Legal",
    Retail = "Retail",
    RealEstate = "Real Estate",
    Technology = "Technology",
    TradeContractor = "Trade Contractor",
    Other = "Other"
}

export const industryInputs: InputWithEmoji<Industry>[] = [
    { input: Industry.CreativeAgency, emoji: "🎨" },
    { input: Industry.Ecommerce, emoji: "🛒" },
    { input: Industry.FinancialServices, emoji: "💸" },
    { input: Industry.HealthOrWellness, emoji: "❤️" },
    { input: Industry.Legal, emoji: "⚖️" },
    { input: Industry.Retail, emoji: "🛍" },
    { input: Industry.RealEstate, emoji: "🏠" },
    { input: Industry.Technology, emoji: "📱" },
    { input: Industry.TradeContractor, emoji: "👷‍♂️" },
    { input: Industry.Other, emoji: "🤔" }
]

export enum UserRoleInCompany {
    AccountingOrFinance = "Accounting / Finance",
    BussinessDevelopment = "Business Development",
    OwnerOrFounderOrCEO = "Owner / Founder / CEO",
    CustomerSupport = "Customer Support",
    Events = "Events",
    Legal = "Legal",
    Marketing = "Marketing",
    Operations = "Operations",
    Recruiting = "Recruiting",
    Sales = "Sales",
    Other = "Other"
}

export const userRoleInputs: InputWithEmoji<UserRoleInCompany>[] = [
    { input: UserRoleInCompany.AccountingOrFinance, emoji: "💸" },
    { input: UserRoleInCompany.BussinessDevelopment, emoji: "🤝" },
    { input: UserRoleInCompany.OwnerOrFounderOrCEO, emoji: "🎯" },
    { input: UserRoleInCompany.CustomerSupport, emoji: "📞" },
    { input: UserRoleInCompany.Events, emoji: "🎉" },
    { input: UserRoleInCompany.Legal, emoji: "⚖️" },
    { input: UserRoleInCompany.Marketing, emoji: "📣" },
    { input: UserRoleInCompany.Operations, emoji: "⚙️" },
    { input: UserRoleInCompany.Recruiting, emoji: "☕️" },
    { input: UserRoleInCompany.Sales, emoji: "📈" },
    { input: UserRoleInCompany.Other, emoji: "🤔" }
]

export enum CompanySize {
    JustMe = "Just me",
    Small = "2 - 10",
    Medium = "11 - 100",
    Big = "101 - 500",
    Large = "500+"
}