import React from 'react';
import DashboardCard from '../../DashboardCard/DashboardCard';

import '../../DashboardCard/DashboardCard.sass';
import './InfoCard.sass';

export interface InfoCardProps {
    icon: React.ReactNode;
    title: string;
};

export const InfoCard: React.FunctionComponent<InfoCardProps> = ({ children, icon, title }) => {
    return (
        <DashboardCard className="Dashboard__info-card">
            <div className="dashboard-card__title">
                {icon}
                <span>{title}</span>
            </div>
            <div className="Dashboard__info-card__content">
                {children}
            </div>
        </DashboardCard>
    );
};
