import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { FormField } from '@src/components/FormField/FormField';
import { ToggleTabNew } from '@src/components';

import './phone-number.sass';

interface PhoneNumberSectionProps {
    order: number;
    title: string;
    instruction: string;
    value: boolean;
    input: string;
    ffLabel: any;
    updateValue: (value: boolean) => void;
    updateMsg: (msg: string) => void;
    // upgradeFF: (key: string) => void;
    disabled: boolean;
}

interface NewMsg {
    message: string;
}

export const PhoneNumberSection: React.FC<PhoneNumberSectionProps> = (props) => {
    const { register, handleSubmit, errors } = useForm();
    const history = useHistory();

    const [val, setVal] = useState(props.value);

    const updateAutoReply = (newMsg: NewMsg) => {
        props.updateMsg(newMsg.message);
    };
    const updateValue = (newState: boolean) => {
        props.updateValue(newState);
        setVal(newState);
    };

    useEffect(() => {
        setVal(props.value);
    }, [props.value]);

    return (
        <div className='PhoneNumber__section'>
            <div className={classNames({ underFeatureFlagLabel: props.ffLabel.value })}>
                <div className="PhoneNumber__section-main">
                    <div className="PhoneNumber__section-main-text">
                        <div className="PhoneNumber__section-main-text-title">{props.title}</div>
                        <div className="PhoneNumber__section-main-text-instruction">
                            {props.instruction}
                        </div>
                    </div>
                    <div className="PhoneNumber__section-main-toggle">
                        <ToggleTabNew
                            commentBefore="OFF"
                            commentAfter="ON"
                            isChecked={props.value}
                            stateChanged={(newState) => updateValue(newState)}
                            disabled={props.disabled}
                        />
                    </div>
                </div>
                {props.input && (
                    <form
                        className="PhoneNumber__section-autoReply"
                        onSubmit={handleSubmit(updateAutoReply)}
                    >
                        <div className="PhoneNumber__section-autoReply-msg">
                            <FormField label=" " htmlFor="message" error={errors.message}>
                                <textarea
                                    name="message"
                                    className={classNames(
                                        'form-input',
                                        'textarea',
                                        { error: errors.message },
                                        { disabled: !val }
                                    )}
                                    defaultValue={props.input}
                                    disabled={!val}
                                    ref={register({ required: 'Missing message' })}
                                />
                            </FormField>
                        </div>
                        <div className="PhoneNumber__section-autoReply-action">
                            <button
                                className={classNames('btn-primary', { disabled: !val })}
                                disabled={!val}
                            >
                                Update
                            </button>
                        </div>
                    </form>
                )}
            </div>
            {props.ffLabel.value && (
                <div className="featureFlagLabel">
                    <button
                        className="btn-primary-full"
                        onClick={() => history.push('/pricing')}
                    >
                        Upgrade to enable this feature
                    </button>
                </div>
            )}
        </div>
    );
};
