import React, { useRef, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { ActivePlan } from '@src/stores/models/billing';
import { getProperPriceObj } from '@src/theme/utils/helpers';
import PlanBrowser from './PlanBrowser';
import PlanActionBar from './PlanActionBar';
import { setTimeoutAndClear } from "@src/utils/timeout";

import './PlansListBrowser.sass';


interface PlansListBrowserProps {
    isSignup: boolean;
    plansArr: any;
    interval: 'month' | 'year';
    onCancelTrial: (v) => void;
    onPlanChange: (v) => void;
    changePlanInApp: (v) => void;
    BillingStore;
    featuresList
}

const PlansListBrowser: React.FunctionComponent<PlansListBrowserProps> = ({ plansArr, isSignup, interval, onCancelTrial, onPlanChange, changePlanInApp, BillingStore, featuresList }) => {
    const carouselContainerRef = useRef(null);
    const planWrapperContainerRef = useRef(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const trackRef = useRef(null);
    const howManyPlansFit = () => {
        return window.innerWidth > 1270 ? 5
        : window.innerWidth > 1100 ? 4
        : window.innerWidth > 910 ? 3
        : window.innerWidth > 750 ? 2
        : 1
    }
    const [plansPerPage, setPlansPerPage] = useState(howManyPlansFit());
    const [localState, setLocalState] = useState({
        index: 0,
        showPrev: false,
        showNext: false,
    });

    const moveWidth = useMemo(() => {
        const planWidth = planWrapperContainerRef.current?.offsetWidth + 16;
        return (planWidth * (plansPerPage - 0.5)) > planWidth ? (planWidth * (plansPerPage - 0.5)) : planWidth;
        // if (carouselContainerRef.current) {
        //     return carouselContainerRef.current.offsetWidth;
        // };
    }, [carouselContainerRef.current, carouselContainerRef.current?.offsetWidth]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (): void => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    const onResize =(): void => {
        setTimeoutAndClear(() => {
            const ppp = howManyPlansFit();
            setPlansPerPage(ppp);
            setLocalState({...localState, index: 0, showNext: plansArr?.length > ppp});
            trackRef.current.style.transform = `translateX(0px)`;
        }, 200);
    };

    useEffect(() => {
        setLocalState({...localState, showNext: plansArr?.length > plansPerPage})
    }, [plansArr]);

    const onClickPrev = () => {
        let idx = localState.index;
        idx--;
        trackRef.current.style.transform = `translateX(-${idx * moveWidth}px)`;
        if (idx === 0) {
            setLocalState({index: idx, showNext: true, showPrev: false})
        } else {
            setLocalState({...localState, index: idx, showNext: true})
        };
    };

    const onClickNext = () => {
        let idx = localState.index;
        idx++;
        trackRef.current.style.transform = `translateX(-${idx * moveWidth}px)`;
        if (trackRef.current.offsetWidth - 40 - (idx * moveWidth) < moveWidth) {
            setLocalState({index: idx, showPrev: true, showNext: false})
        } else {
            setLocalState({...localState, index: idx, showPrev: true})
        };
    };

    const actionBar = (plan: ActivePlan) => {
        if (plan && plan.billingPlan && interval) {
            return <PlanActionBar
                isContactSales={getProperPriceObj(plan.billingPlan, interval)?.contactSales}
                trialDays={plan.billingPlan.billingPlan.trialDays}
                currentPriceId={BillingStore.currentPlan?.price.id}
                priceId={getProperPriceObj(plan.billingPlan, interval)?.id}
                isSignup={isSignup}
                noCC={!BillingStore.billingAccount?.card?.brand}
                onCancelTrial={() => onCancelTrial(getProperPriceObj(plan.billingPlan, interval)?.id)}
                onBuyNowClick={() => onPlanChange({priceId: getProperPriceObj(plan.billingPlan, interval)?.id, buyNow: true})}
                onTryFreeClick={() => onPlanChange({priceId: getProperPriceObj(plan.billingPlan, interval)?.id, buyNow: false})}
                onSelectClick={() => changePlanInApp(getProperPriceObj(plan.billingPlan, interval)?.id)}
            />
        };
    };

    return (
        <div
            className={classNames('PlansListBrowser')}
            ref={carouselContainerRef}
        >
            <div className={classNames('PlansListBrowser__inner', { center: plansArr.length < plansPerPage })}>
                <div className="PlansListBrowser__track" ref={trackRef}>
                    {plansArr.map(plan =>
                        <div
                            className="PlansListBrowser__planWrapper"
                            key={plan.billingPlan.billingPlan.id}
                            ref={planWrapperContainerRef}
                        >
                            <PlanBrowser
                                activePlan={plan}
                                actionBar={actionBar(plan)}
                                interval={interval}
                                featuresList={featuresList}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="PlansListBrowser__nav">
                <button
                    className={classNames('prev', { show: localState.showPrev })}
                    ref={prevRef}
                    onClick={() => onClickPrev()}
                >
                    {'<'}
                </button>
                <button
                    className={classNames('next', { show: localState.showNext })}
                    ref={nextRef}
                    onClick={() => onClickNext()}
                >
                    {'>'}
                </button>
            </div>
        </div>
    );
};

export default PlansListBrowser;
