import { action, observable, runInAction } from 'mobx';
import { CustomerResponse, CustomersApi, MessagesApi } from '@src/requests';
import { Customer, LoadingState, MessagesSearch } from '@src/stores/models';
import { BaseStore } from '@src/stores/base.store';
// import TagsStore from './tags/tags.store';
import { deleteTag, updateTag } from '@src/requests/tags.requests';
import AuthStore from '@src/stores/auth.store';

export class CustomerStore extends BaseStore {
    @observable loading: LoadingState = LoadingState.Loading;
    @observable customerData: CustomerResponse = null;
    @observable customerMessages: MessagesSearch = null;
    @observable customers: Customer[] = [];
    @observable customerNotes = null;
    @observable refreshAfterTag = false;
    @observable ffBrowserCallingEnabled: boolean;
    private _onSuccess: () => void;
    private _onError: (type: string, data?) => void;
    private _onErrorGeneral: (msg) => void;

    set onSuccess(fn: () => void) {
        this._onSuccess = fn;
    }
    set onError(fn: (type: string, data?) => void) {
        this._onError = fn;
    }
    set onErrorGeneral(fn: (msg) => void) {
        this._onErrorGeneral = fn;
    }

    @action init(): void {
        this.loading = LoadingState.Loading;
        this.customerData = null;
        this.customerMessages = null;
        this.customers = [];
        this.customerNotes = null;
        this.refreshAfterTag = false;
        this.ffBrowserCallingEnabled = false;
    }

    @action initData(cid, onError, checkFF = true) {
        this.loading = LoadingState.Loading;
        Promise.all([
            this.fetchCustomer(cid, onError),
            // this.getNotes(cid),
            // this.fetchCustomers(),
            checkFF && this.ffBrowserCallingEnabled === undefined && this.getFeatureFlags(onError),
        ]);
    }

    @action dataLoading = () => {
        this.loading = LoadingState.Loading;
    };

    @action finishDataLoading = () => {
        if (this.loading != LoadingState.Error) this.loading = LoadingState.Loaded;
    };

    @action getFeatureFlags = (onError?: (message) => void) => {
        MessagesApi.getMessagingInitCall().then(
            (res) => {
                this.ffBrowserCallingEnabled = Boolean(res.featureFlags['phone.browser_calling']);
            },
            (err) => {
                if (err.response.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    if (onError) onError(err.message);
                };
            }
        );
    };

    @action fetchCustomer(customerId: string, onError?: (message) => void): Promise<void> {
        this.loading = LoadingState.Loading;
        return CustomersApi.fetchCustomer(customerId)
            .then((customerData) => {
                runInAction(() => {
                    this.customerData = customerData;
                    if (this.customerNotes !== null) {
                        this.customerData.customer.notes = this.customerNotes;
                    } else {
                        this.customerData.customer.notes = null;
                        this.getNotes(customerId);
                    }
                    this.loading = LoadingState.Loaded;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (onError) {
                        onError(error.message);
                    }
                    this.loading = LoadingState.Error;
                });
            });
    }

    // @action fetchCustomers(onError?: (message) => void) {
    // 	return CustomersApi.searchCustomersDt()
    // 		.then(({ data }) => {
    // 			runInAction(() => {
    // 				TagsStore.EditedTagStore.setCustomers(data);
    // 			})
    // 		}, (err) => {
    // 			if (onError)
    // 				onError(err.message);

    // 			this.loading = LoadingState.Error;
    // 		})
    // }

    @action closeCustomer = () => {
        this.customerData = null;
    };

    @action removeCustomer(customerId: string, onError?: (message) => void) {
        this.loading = LoadingState.Loading;
        return CustomersApi.removeCustomer(customerId)
            .then(() => {
                runInAction(() => {
                    this.customerData = null;
                    this.customerMessages = null;
                    this.loading = LoadingState.Loaded;
                });
            })
            .catch((err) => {
                runInAction(() => {
                    if (onError) {
                        onError(err.message);
                    }
                    this.loading = LoadingState.Error;
                });
            });
    }

    @action updateCustomer(customerId: string, data: Customer, onError?: (message) => void) {
        this.loading = LoadingState.Loading;
        return CustomersApi.updateCustomer(customerId, data).then(
            () => {
                runInAction(() => {
                    this.loading = LoadingState.Loaded;
                });
            },
            (err) => {
                runInAction(() => {
                    if (onError) onError(err.message);
                    this.loading = LoadingState.Error;
                });
            }
        );
    }

    @action refreshAfterTagFunc(): void {
        this.refreshAfterTag = false;
    }

    @action updateTag(id: string, value: string, onError?: (message) => void) {
        this.loading = LoadingState.Loading;

        return updateTag(id, value).then(
            () => (this.refreshAfterTag = true),
            (err) => {
                if (onError) onError(err.message);
                this.loading = LoadingState.Error;
            }
        );
    }

    @action deleteTag(id: string, onError?: (message) => void) {
        this.loading = LoadingState.Loading;

        return deleteTag(id).then(
            () => (this.refreshAfterTag = true),
            (err) => {
                if (onError) onError(err.message);
                this.loading = LoadingState.Error;
            }
        );
    }

    @action getNotes(customerId: string) {
        this.loading = LoadingState.Loading;
        CustomersApi.getNotes(customerId).then(
            (res: any) => {
                runInAction(() => {
                    if (this.customerData?.customer) {
                        this.customerData.customer.notes = res.data;
                    }
                    this.customerNotes = res.data;
                    this.loading = LoadingState.Loaded;
                });
            },
            (err) => {
                this.loading = LoadingState.Error;
                if (this._onErrorGeneral) this._onErrorGeneral(err.message);
            }
        );
    }

    @action postNote(customerId: string, text: string, errAction) {
        this.loading = LoadingState.Loading;
        return CustomersApi.postNote(customerId, text).then(
            () => {
                runInAction(() => {
                    this.getNotes(customerId);
                    if (this._onSuccess) this._onSuccess();
                });
            },
            () => {
                runInAction(() => {
                    this.loading = LoadingState.Error;
                    if (this._onError) this._onError(errAction, { text });
                });
            }
        );
    }

    @action updateNote(noteId: string, text: string, customerId: string, errAction) {
        this.loading = LoadingState.Loading;
        return CustomersApi.updateNote(noteId, text).then(
            () => {
                runInAction(() => {
                    this.getNotes(customerId);
                    if (this._onSuccess) this._onSuccess();
                });
            },
            () => {
                runInAction(() => {
                    this.loading = LoadingState.Error;
                    if (this._onError) this._onError(errAction, { noteId, text });
                });
            }
        );
    }

    @action deleteNote(noteId: string, customerId: string, errAction) {
        this.loading = LoadingState.Loading;
        return CustomersApi.deleteNote(noteId).then(
            () => {
                runInAction(() => {
                    this.getNotes(customerId);
                    if (this._onSuccess) this._onSuccess();
                });
            },
            () => {
                runInAction(() => {
                    this.loading = LoadingState.Error;
                    if (this._onError) this._onError(errAction, { noteId });
                });
            }
        );
    }
}

export default new CustomerStore();
