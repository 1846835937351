import { getHours, getMinutes } from 'date-fns';
import { timezones } from '@src/stores/models/employee';
import { toDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export interface ScheduleTime {
  hours: number;
  minutes: number;
  ampm: 'AM' | 'PM';
  zone: string;
}

export const getTimeZoneName = (value: string): string => {
  const timezone = timezones.filter(zone => zone.value === value)

  return timezone[0]?.name ?? '' 
}

export const dateToScheduleTime = (date: Date, timeZone?: string): ScheduleTime => {
  const zonedDate = timeZone ? utcToZonedTime(date, timeZone) : date;
  const minutes = (Math.ceil(getMinutes(zonedDate) / 15) * 15) % 60;
  const hours = (getHours(zonedDate) + (minutes === 0 ? 1 : 0)) % 13;

  return ({
      hours: hours,
      minutes: minutes,
      ampm: hours < 12 ? 'AM' : 'PM',
      zone: timeZone
  });
};

export const scheduleTimeToDate = (time: ScheduleTime, date: Date): Date => {
  let hours = time.hours;

  if (time.hours === 12) {
      time.ampm === 'AM' ? hours = 0 : hours = 12;
  } else {
      const offset = time.ampm === 'PM' ? 12 : 0;
      hours = (time.hours + offset);
  };
  const day = date.getDate();
  const month = date.getMonth();
  const fullYear = date.getFullYear();
  const newDate = new Date(fullYear, month, day, hours, time.minutes, 0);
  const zonedDate = zonedTimeToUtc(newDate, time.zone);

  return zonedDate;
};

export const formatScheduleTime = (time: ScheduleTime): string => (
  `${time.hours}:${time.minutes === 0 ? '00' : time.minutes}:00 ${time.ampm} ${getTimeZoneName(time.zone)}`
);

