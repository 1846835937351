 import React, { useEffect, useState } from 'react';
 import { Route, Switch, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { StripeProvider } from 'react-stripe-elements';
import { Elements } from 'react-stripe-elements';
import { isEmail } from '@src/theme/utils/helpers';
import { onSuccess, dataSaved } from '@src/theme/utils/constants';
import {
    WithBillingStoreProps,
    WithSignupStoreProps,
    STORES,
    withStores,
    WithUserStoreProps,
} from '@src/stores/with-store';
import { ReactComponent as AttentionIcon } from '@src/theme/icons/attention_large.svg';
import { LoadingState, OnErrorDataBilling, SubscriptionDetails } from '@src/stores/models';
import {
    Loader,
    FormField,
    ErrorToastDownload,
    ErrorToastSave,
    PaymentInsideStripe,
    PlanDetails
} from '@src/components';
import { displayIntegerOrTwoDecimalPlaces } from '@src/theme/utils/helpers';
import { ERROR_TOAST_AUTOCLOSE } from '@src/theme/utils/constants';

import './billing.sass';
import { getCouponDurationText, getDiscountedAmount, getUndiscountedAmount } from '@src/components/PaymentInsideStripe/headers/Coupon';

export const enum SubscriptionAction {
    CANCEL = 'cancel',
    REACTIVATE = 'reactivate',
}

export const enum SubscriptionType {
    SUBSCRIPTION = 'subscription',
    CONTRACT = 'contract',
}

type props = WithBillingStoreProps & WithUserStoreProps & WithSignupStoreProps;

export const BillingWithStore: React.FC<props> = (props) => {
    const store = props.BillingStore;
    const [user, setUser] = useState(null);
    const phone = props?.UserStore?.user?.employee.phone;
    const loading = store.loadingState;
    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const { path } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    const { billingAccount, currentSubscription, currentPlan, contract, billingPlans } = store;
    const [trialToBeFinished, setTrialToBeFinished] = useState(false);
    const [emailBeingEdited, setEmailBeingEdited] = useState(billingAccount?.billingEmail);

    const status = (subscription: SubscriptionDetails) => {
        if (subscription) {
            if (
                subscription.status === 'active' &&
                (subscription.cancelAt || subscription.cancelAtPeriodEnd)
            )
                return 'beingCancelled';
            if (subscription.status === 'canceled') return 'cancelled';
            if (subscription.status === 'past_due') return 'past due';
            if (subscription.status === 'incomplete_expired') return 'incomplete & expired';
            else return subscription.status;
        }
    };

    store.onError = (type: string, data?: OnErrorDataBilling) => {
        store.loadingState = LoadingState.Error;
        switch (type) {
            case 'getBE': {
                toast.error(
                    <ErrorToastDownload getBEData={() => store.getAndSetBilling('getBE')} />
                );
                break;
            }
            case 'editEmail': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.editBillingEmail(data.billingEmail, 'editEmail')
                        }
                    />
                );
                break;
            }
            case 'manageSubscription': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.manageSubscription(
                                data.action,
                                data.subType,
                                data.currentSubscriptionId,
                                'manageSubscription'
                            )
                        }
                    />
                );
                break;
            }
            case 'cancelTrial': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.cancelTrial('cancelTrial')
                        }
                    />
                );
                break;
            }
            default: {
                return;
            }
        }
    };
    store.onSuccess = () => {
        store.loadingState = LoadingState.Loaded;
        onSuccess();
    };
    store.onSuccessCancelTrial = () => {
        store.loadingState = LoadingState.Loaded;
        toast.dismiss();
        setTimeout(() => {
            toast.success('Congratulations, your account has been upgraded!', ERROR_TOAST_AUTOCLOSE);
        }, 500);
    };
    store.onSuccessDismissOtherToasts = () => {
        store.loadingState = LoadingState.Loaded;
        toast.dismiss();
        setTimeout(() => {
            toast.success(dataSaved, { autoClose: 4000 });
        }, 500);
    };

    const plan = {
        currentSubscriptionId: currentSubscription?.id,
        name: currentPlan?.billingPlan.name,
        price: displayIntegerOrTwoDecimalPlaces(currentPlan?.price.amount),
        interval: currentPlan?.price.interval?.interval,
        renewDate: currentSubscription?.details?.end
            ? moment(currentSubscription?.details?.end).format('MM/D/YYYY')
            : undefined,
        contractEnd: contract ? moment(contract?.contractEnd).format('MM/D/YYYY') : undefined,
        status: status(currentSubscription?.details),
    };

    const account = {
        billingEmail: billingAccount?.billingEmail,
        payment: {
            type: billingAccount?.card?.brand,
            numberEnding: billingAccount?.card?.last4,
            expMonth: billingAccount?.card?.exp_month,
            expYear: billingAccount?.card?.exp_year,
        },
    };

    const ccExpiresSoon = Boolean(
        account?.payment?.expYear === parseInt(moment().format('YYYY'), 10) &&
            account?.payment?.expMonth === parseInt(moment().format('M'), 10)
    );
    const ccExpired = Boolean(
        account?.payment?.expYear < parseInt(moment().format('YYYY'), 10) ||
            (account?.payment?.expYear === parseInt(moment().format('YYYY'), 10) &&
                account?.payment?.expMonth < parseInt(moment().format('M'), 10))
    );

    const getAndSetBilling = () => {
        store.getAndSetBilling('getBE', isFirstLoading);
        setIsFirstLoading(false);
    };

    const editBillingEmail = () => {
        store.editBillingEmail(emailBeingEdited, 'editEmail');
    };

    const manageSubscription = (action: string, subType: string) => {
        store.manageSubscription(action, subType, currentSubscription?.id, 'manageSubscription');
    };

    useEffect(() => {
        setEmailBeingEdited(billingAccount?.billingEmail);
    }, [billingAccount?.billingEmail]);

    useEffect(() => {
        setUser({
            companyInfo: {
                address: props.UserStore?.company?.address,
                companyName: props.UserStore?.company?.account?.name,
            },
        });
    }, [props.UserStore?.company?.address]);

    const onIsViewBilling = () => {
        setIsFirstLoading(false);
        store.getAndSetBilling('getBE', isFirstLoading)
        .then((res) => {
            if (location.search === '?finishTrial' && res?.subscription?.details?.status === 'trialing') {
                setTrialToBeFinished(true);
                if (!res?.billingAccount?.card?.brand) {
                    props.BillingStore?.setIsViewBilling(false);
                };
            };
        });
    };

    useEffect(() => {
        if (props.BillingStore?.isViewBilling) {
            onIsViewBilling();
        } else if (!location.search.length) {
            history.push('/settings/billing?payment');
        };
    }, [props.BillingStore?.isViewBilling]);

    useEffect(() => {
        if (!location.search.length) {
            props.BillingStore?.setIsViewBilling(true);
        };
    }, [location]);

    const getAndSetCompanyDetails = () => props.UserStore.getAndSetCompanyDetails('getBE');

    useEffect(() => {
        getAndSetCompanyDetails();
    }, []);

    const noPlanId = !plan?.status && !plan?.currentSubscriptionId && (
        <div className="selectSubFirstTime">
            <button
                className="btn-primary"
                onClick={() => history.push('/pricing')}
            >
                Select plan
            </button>
        </div>
    );

    const thereIsPlanIdButNoStatus = !plan?.status && plan?.currentSubscriptionId && (
        <div>
            There has been problem with your subscription status. Please contact us at
            support@myopolis.com.
        </div>
    );

    const planStatusBeingCancelled = plan?.status === 'beingCancelled' && (
        <>
            <div className="Billing__section-content-beingCancelled">
                {contract ? 'Contract' : 'Subscription'} is set to expire on{' '}
                {contract ? plan?.contractEnd : plan?.renewDate}. Click the button below to continue
                subscription.
            </div>
            <button
                className="btn-primary"
                onClick={() =>
                    manageSubscription(
                        SubscriptionAction.REACTIVATE,
                        contract ? SubscriptionType.CONTRACT : SubscriptionType.SUBSCRIPTION
                    )
                }
            >
                Reactivate {contract ? 'contract' : 'subscription'}
            </button>
        </>
    );

    const planStatusCancelled = (plan?.status === 'cancelled' && props.BillingStore.stripeKey) && (
        <>
            <div className="Billing__section-content-beingCancelled">
                Your {contract ? 'contract' : 'subscription'} is {plan.status}. Please provide credit card information to reactivate subscription.
            </div>
            <div key={uuidv4()}>
                <StripeProvider apiKey={props.BillingStore.stripeKey}>
                    <Elements>
                        <PaymentInsideStripe
                            paymentCase={'reactivateFromCancelled'}
                            reactivationData={{
                                action: SubscriptionAction.REACTIVATE,
                                subType: contract ? SubscriptionType.CONTRACT : SubscriptionType.SUBSCRIPTION,
                                subscriptionId: currentSubscription?.id
                            }}
                        />
                    </Elements>
                </StripeProvider>
            </div>
        </>
    );

    const lastOffer = props.BillingStore.redeemedCoupons?.length > 0 && props.BillingStore.redeemedCoupons[props.BillingStore.redeemedCoupons?.length - 1];
    const appliedCoupon = lastOffer?.coupon;
    const renderDiscount = appliedCoupon ? appliedCoupon.percentOff ? appliedCoupon.percentOff + '%' : '$' + (appliedCoupon.amountOff / 100) : '';
    // const offerRedeemDate = lastOffer?.updated && `from ${format(lastOffer?.updated, 'MM/dd/yyyy')}`;
    // const renderDiscountStart = currentPlan?.interval?.interval === 'month' && appliedCoupon?.months && <p className="Billing__coupon-discount-from">{offerRedeemDate}</p>

    const planStatusActive = (plan?.status === 'active' || plan?.status === 'trialing') && (
        <>
            <div className="Billing__PlanDetails-container">
                <PlanDetails view="billing" />
                {currentPlan && appliedCoupon &&
                    <div className="Billing__coupon-discount-container">
                        <p className="Billing__coupon-discount-amount">{renderDiscount} OFF</p>
                        <p className="Billing__coupon-discount-old-price">${displayIntegerOrTwoDecimalPlaces(getUndiscountedAmount(currentPlan, appliedCoupon))}</p>
                        <p className="Billing__coupon-discount-new-price">${displayIntegerOrTwoDecimalPlaces(getDiscountedAmount(currentPlan, appliedCoupon))}</p>
                        <div>
                            <p className="Billing__coupon-discount-duration">{getCouponDurationText(appliedCoupon, currentPlan?.price.interval?.interval).toUpperCase()}</p>
                            {/* {renderDiscountStart} */}
                        </div>
                    </div>
                }
            </div>
            {plan?.status === 'active' &&
                <>
                    <div className="Billing__section-content-billingComment">
                        {contract
                            ? `Your contract will automatically renew on ${plan?.contractEnd}.`
                            : `Your plan will automatically renew on ${plan?.renewDate}${appliedCoupon ? '' : ` and you'll be charged $${plan?.price}`}.`}
                    </div>
                    <div className="Billing__section-content-actions">
                        <button
                            className="btn-primary"
                            onClick={() => history.push('/pricing')}
                        >
                            Change plan
                        </button>
                        <button
                            className="btn-secondary"
                            onClick={() =>
                                manageSubscription(
                                    SubscriptionAction.CANCEL,
                                    contract ? SubscriptionType.CONTRACT : SubscriptionType.SUBSCRIPTION
                                )
                            }
                        >
                            Cancel {contract ? 'contract' : 'subscription'}
                        </button>
                    </div>
                </>
            }
            {plan?.status === 'trialing' &&
                <>
                    <div className="Billing__section-content-billingComment">
                        <div>
                            Your {contract ? 'contract' : 'subscription'} is <span className="bolder">trialing</span>.{' '}
                        </div>
                        <div>Upgrade to a paid subscription to enjoy additional features, increased message limits and more.</div>
                    </div>
                    <button
                        className="btn-primary"
                        onClick={() => history.push('/pricing')}
                    >
                        Upgrade or change plan
                    </button>
                </>
            }
        </>
    );

    // const finishTrial = () => {
    //     if (account?.payment?.type) {
    //         store.cancelTrial('cancelTrial');
    //     } else {
    //         setTrialToBeFinished(true);
    //         props.BillingStore?.setIsViewBilling(false);
    //     };
    // };

    const planStatusOther = (
        <div>
            <span>
                Your {contract ? 'contract' : 'subscription'} is <span className={classNames({bolder: plan?.status === 'trialing'})}>{plan?.status}</span>.{' '}
            </span>
            {(plan?.status === 'past due' ||
                plan?.status === 'cancelled' ||
                plan?.status === 'unpaid') && (
                <span>Please update your credit card information below.</span>
            )}
        </div>
    );

    const billingInfo = (
        <div className="Billing__section" key={uuidv4()}>
            <div className="Billing__section-title">Current Subscription</div>
            <div className="Billing__section-content">
                {noPlanId ||
                    thereIsPlanIdButNoStatus ||
                    planStatusBeingCancelled ||
                    planStatusCancelled ||
                    planStatusActive ||
                    planStatusOther}
            </div>
        </div>
    );

    const paymentType = account?.payment?.type && (
        <div>
            {account?.payment?.type} ending in {account?.payment?.numberEnding} exp.{' '}
            {account?.payment?.expMonth < 10
                ? `0${account?.payment?.expMonth}`
                : account?.payment?.expMonth}
            &#47;{account?.payment?.expYear}.
        </div>
    );

    const noPaymentTypeNoPlan = !account?.payment?.type && (
        <div>
            You did not provide credit card information yet. Click the button below to set
            it up. {plan?.status === 'trialing' ? 'You will be charged after your trial period ends.' : ''}
        </div>
    );

    const planButNoPaymentType = (!account?.payment?.type && !currentPlan?.billingPlan?.name && props.BillingStore.stripeKey) && (
        <div key={uuidv4()}>
            <StripeProvider apiKey={props.BillingStore.stripeKey}>
                <Elements>
                    <PaymentInsideStripe
                        paymentCase={'updatePaymentInSettings'}
                        user={user}
                        phone={phone}
                        selectedPlan={currentPlan}
                        finalize={props.SignupStore.finalize}
                        settings={true}
                        paymentAdded={() => getAndSetBilling()}
                    />
                </Elements>
            </StripeProvider>
        </div>
    );

    const ccExpiresSoonHtml = ccExpiresSoon && (
        <div className="expirationInfo">
            <AttentionIcon />
            Your billing credit card expires next month. Click the button below to update your
            credit card information.
        </div>
    );

    const ccExpiredHtml = ccExpired && (
        <div className="expirationInfo">
            <AttentionIcon />
            Your billing credit card has expired. Click the button below to update your credit card
            information.
        </div>
    );

    const paymentDetails = (
        <div className="Billing__section-content-paymentDetails" key={uuidv4()}>
            {paymentType || noPaymentTypeNoPlan || planButNoPaymentType}
            {ccExpiresSoonHtml || ccExpiredHtml}
        </div>
    );

    const paymentAction = (
        <button
            key={uuidv4()}
            className="btn-primary"
            onClick={() => {
                setTrialToBeFinished(false);
                props.BillingStore?.setIsViewBilling(false);
            }}
        >
            {account?.payment?.type ? 'Change' : 'Add'} payment
        </button>
    );

    const paymentInfo = (
        <div className="Billing__section" key={uuidv4()}>
            <div className="Billing__section-title">Payment</div>
            <div className="Billing__section-content">{[paymentDetails, paymentAction]}</div>
        </div>
    );

    const billingEmail = plan?.status && (
        <div className="Billing__section" key={uuidv4()}>
            <div className="Billing__section-title email">Billing Email</div>
            <form className="Billing__section-content">
                <FormField
                    label=" "
                    htmlFor="billingEmail"
                    error={{
                        type: "billingEmail",
                        message: !emailBeingEdited
                            ? 'Missing email address'
                            : isEmail(emailBeingEdited)
                            ? null
                            : 'Incorrect email address',
                    }}
                >
                    <input
                        type="text"
                        name="billingEmail"
                        className={classNames('form-input', {
                            error: !emailBeingEdited || !isEmail(emailBeingEdited),
                        })}
                        defaultValue={emailBeingEdited}
                        onBlur={(e) => setEmailBeingEdited(e.target.value.trim())}
                    />
                </FormField>
                <button className="btn-primary" onMouseUp={() => editBillingEmail()}>
                    {account?.billingEmail ? 'Update' : 'Provide'} email
                </button>
            </form>
        </div>
    );

    const allSections = store.dataLoaded && [billingInfo, billingEmail, paymentInfo];

    const contentBilligView = (loading !== LoadingState.Init && loading !== LoadingState.FirstLoading && props.BillingStore.isViewBilling) &&
        <div key={uuidv4()}>
            <div className="Billing__title">Billing Information</div>
            <div className="Billing__subtitle">Information about your billing and payment</div>
            {allSections}
        </div>

    const contentPaymentView = (loading !== LoadingState.Init && loading !== LoadingState.FirstLoading && !props.BillingStore.isViewBilling && props.BillingStore.stripeKey && props.BillingStore.stripeKey) &&
        <div key={uuidv4()}>
            <StripeProvider apiKey={props.BillingStore.stripeKey}>
                <Elements>
                    <PaymentInsideStripe paymentCase={trialToBeFinished ? 'addPaymentAndFinishTrial' : 'updatePaymentInSettings'} />
                </Elements>
            </StripeProvider>
        </div>

    const content = contentBilligView || contentPaymentView;

    const loader = <Loader loadingState={loading} key={uuidv4()} />;

    const wholePage = <div className="Billing">{[loader, content]}</div>;

    return (
        <div>
            <Switch>
                <Route exact path={path}>
                    {wholePage}
                </Route>
            </Switch>
        </div>
    );
};

export const Billing = withStores(BillingWithStore, [STORES.Billing, STORES.User, STORES.Signup]);
