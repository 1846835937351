import React from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { FormField } from '@src/components/FormField/FormField';

import './password-form.sass';

interface PasswordFormProps {
    onSubmit: (confirmData) => void;
}

export const PasswordForm: React.FC<PasswordFormProps> = (props) => {
    const { register, handleSubmit, errors, watch } = useForm();
    const updatePassword = (data: {}) => {
        props.onSubmit(data);
    };

    return (
        <form className="PasswordForm" onSubmit={handleSubmit(updatePassword)}>
            <FormField label="Password" htmlFor="password" error={errors.password}>
                <input
                    type="password"
                    name="password"
                    className={classNames('form-input', { error: errors.password })}
                    placeholder="Minimum 8 characters"
                    ref={register({
                        required: 'Current password',
                        minLength: {
                            value: 8,
                            message: 'Password must be at least 8 characters long',
                        },
                    })}
                    autoComplete="current-password"
                />
            </FormField>
            <FormField
                label="Confirm password"
                htmlFor="confirmPassword"
                error={errors.confirmPassword}
            >
                <input
                    type="password"
                    name="confirmPassword"
                    className={classNames('form-input', { error: errors.confirmPassword })}
                    placeholder="Minimum 8 characters"
                    ref={register({
                        required: 'Confirm password',
                        validate: (value) =>
                            value !== watch('password')
                                ? 'Password fields do not match'
                                : undefined,
                        minLength: {
                            value: 8,
                            message: 'Password must be at least 8 characters long',
                        },
                    })}
                    autoComplete="current-password"
                />
            </FormField>
            <button className="btn-primary">Confirm</button>
        </form>
    );
};
