import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import { re } from '@src/theme/utils/constants';
import { convertToPureNumber, displayIntegerOrTwoDecimalPlaces, getDefaultPricePlan, getBillingPlanAndPriceByPriceId } from '@src/theme/utils/helpers';
import { GoogleAuthorization, isGoogleAuthorization } from '@src/stores/signup.store';
import { CheckboxField, FormField } from '@src/components/FormField/FormField';
import { OptionType, SignupSelect } from '@src/components/SignupSelect/SignupSelect';
import { BillingPlanOnList, Price } from '@src/stores/models';
import { ReactComponent as EyeOpen } from '@src/theme/icons/eye-open.svg';
import { ReactComponent as EyeClosed } from '@src/theme/icons/eye-closed.svg';
import { ReactComponent as FlagUsaSvg } from '@src/theme/icons/flag-usa-round.svg';
import { Loader } from '@src/components';
import '../Signup.sass';

export interface FormData {
    agree: boolean;
    companyName: string;
    email: string;
    googleAuth: GoogleAuthorization;
    firstName: string;
    lastName: string;
    password: string;
    phone: string;
    priceId: string;
    address: any
}

type PlanOptionType = OptionType<string>;

const emptyFormData: FormData = {
    agree: false,
    companyName: '',
    email: '',
    googleAuth: 'try',
    firstName: '',
    lastName: '',
    password: '',
    phone: '',
    priceId: null,
    address: null
};

const enableSubmit = (formData: FormData): boolean => (
    formData.agree &&
    (isGoogleAuthorization(formData.googleAuth) || (formData.email.length > 0 && formData.password.length > 0)) &&
    formData.firstName.length > 0 &&
    formData.lastName.length > 0 &&
    formData.phone.length > 0 &&
    formData.companyName.length > 0 &&
    formData.priceId.length > 0
);

const agreement: React.ReactFragment = <>
    {`By signing up, I agree to `}
    <a
        href="https://www.myopolis.com/terms-of-use/"
        rel="noopener noreferrer"
        target="_blank"
    >
        Terms of Service
    </a>
    {` and `}
    <a
        href="https://www.myopolis.com/privacy-policy/"
        rel="noopener noreferrer"
        target="_blank"
    >
        Privacy Policy
    </a>
</>;

const messagesAndSeatsDisplay = (messagesPerMonth: number, seats: number) => {
    return seats && messagesPerMonth ?
        ` (${seats} user${seats > 1 ? 's' : ''}, ${messagesPerMonth} messages)` :
        seats ?
        ` (${seats} user${seats > 1 ? 's' : ''})` :
        messagesPerMonth ?
        ` (${messagesPerMonth} messages)` :
        '';
};


interface FormProps {
    googleAuth: GoogleAuthorization;
    plans: BillingPlanOnList[];
    onSubmit: (data: FormData) => void;
}

const Form: React.FunctionComponent<FormProps> = ({ googleAuth, plans, onSubmit }) => {
    const priceIdFromUrl = new URLSearchParams(location.search).get('id');
    const priceFromUrl = priceIdFromUrl && plans.map(pl => pl.prices.find(pr => pr.id === priceIdFromUrl)).find(pl => pl);
    const [ loading, setLoading ] = useState(false);

    const [ typedSthInCompanyName, setTypedSthInCompanyName ] = useState(false);
    const [ setupDone, setSetupDone ] = useState(false);
    const [ placeData, setPlaceData ] = useState({
        companyName: '',
        address: null
    });

    function handleFieldChange<T>(field: string, value: T) {
        const newData = {...formData};
        newData[field] = value;
        setFormData(newData);
    }

    useEffect(() => {
        setFormData({
            ...formData,
            ...placeData
        });
    }, [placeData])

    const changeNameAndAddress = (address, companyName) => {
        setPlaceData({
            address,
            companyName
        });
    };

    const onPlaceSelected = (autocomplete, input) => {

        const place = autocomplete.getPlace();

        input.value = place.name;
        input.style.color = "initial";
        setLoading(false);

        let street = "";
        let postalCode = "";
        let state = "";
        let country = "";
        let city = "";

        for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    street = `${component.long_name} `;
                    break;
                }

                case "route": {
                    street += component.short_name;
                    break;
                }

                case "sublocality_level_1": {
                    city = component.long_name;
                    break;
                }

                case "locality": {
                    city = component.long_name;
                    break;
                }

                case "administrative_area_level_1": {
                    state = component.short_name;
                    break;
                }

                case "postal_code": {
                    postalCode = component.long_name;
                    break;
                }

                case "country": {
                    country = component.short_name;
                    break;
                }
            }
        };

        changeNameAndAddress({
                street,
                streetTwo: '',
                city,
                state,
                postalCode: postalCode.split(' ').join(''),
                country,
            },
            place.name
        );
    };

    const manageAutocomplete = () => {
        const input = document.getElementById('companyName') as HTMLInputElement;
        const options = {
            fields: ["address_components", "name"],
            componentRestrictions: { country: ["us", "ca"] },
            strictBounds: false,
            types: ["establishment"],
        };

        const autocomplete = new google.maps.places.Autocomplete(input, options);

        autocomplete.addListener("place_changed", () => {
            onPlaceSelected(autocomplete, input);
        });
    };

    useEffect(() => {
        manageAutocomplete();
    }, []);

    useEffect(() => {
        const pacContainers = document.getElementsByClassName('pac-container');
        const lastPacContainer = pacContainers && pacContainers[pacContainers.length - 1];
        lastPacContainer && lastPacContainer.classList.add('inShortSignup');
        if (lastPacContainer && !setupDone && typedSthInCompanyName) {
            lastPacContainer.addEventListener('mousedown', () => {
                const input = document.getElementById('companyName') as HTMLInputElement;
                input.style.color = "transparent";
                setLoading(true);
            });
            setSetupDone(true);
        }
    }, [typedSthInCompanyName]);

    const getDefaultFormData = (): FormData => {
        return {
            ...emptyFormData,
            googleAuth: googleAuth,
            priceId: priceFromUrl?.id || getDefaultPricePlan(plans).id,
        };
    };

    const { register, handleSubmit, errors } = useForm<FormData>();
    const [ formData, setFormData ] = useState<FormData>(getDefaultFormData());
    const [ isShowPwd, setIsShowPwd ] = useState<boolean>(false);

    const visibleField = isGoogleAuthorization(googleAuth) ? 'Signup__form-hidden' : '';

    const getOptionsForPlanSelect = (): Array<PlanOptionType> => {
        if (!plans || !plans.length) {
            return;
        };

        const preselectedPricePlan = priceFromUrl || getDefaultPricePlan(plans);

        const preselectedPlanAllDetails = () => {
            let price;
            const plan = plans.find(pl => {
                return pl.prices.find(pr => {
                    if (pr.id === preselectedPricePlan.id) {
                        price = pr;
                        return true;
                    }
                });
            });
            return plan ? {billingPlan: plan?.billingPlan, price} : null;
        };

        const result: any[] = preselectedPlanAllDetails().billingPlan.trialDays ?
            [
                {
                    isTitle: true,
                    label: 'Choose your plan',
                    value: null,
                },
                {
                    label: `Tryout, Free for 14 Days`,
                    subLabel: '75 messages',
                    value: preselectedPricePlan.id,
                }
            ] : [];
        let help = {}
        plans.forEach((plan: BillingPlanOnList) => {
            const name = plan.billingPlan.name;

            plan.prices.forEach((price: Price) => {
                const child = {
                    name,
                    seatsAndMessagesPerMonth: messagesAndSeatsDisplay(plan.billingPlan.messagesPerMonth, plan.billingPlan.seats),
                    price
                }
                if (help[price.interval.interval]) {
                    help[price.interval.interval].children.push(child)
                } else {
                    help[price.interval.interval] = {children: [child]}
                }
            })
        })

        let helpKeys = Object.keys(help);
        const indexOfMonth = helpKeys.findIndex(el => el === 'month');

        if (indexOfMonth > -1) {
            helpKeys.splice(indexOfMonth, 1);
            helpKeys.unshift('month');
        };

        helpKeys.forEach((key: string) => {
            result.push({
                isTitle: true,
                label: key === 'month' ? 'Plans Billed Monthly' : 'Plans Billed Annually',
                value: null,
            });
            help[key].children.forEach((item) => {
                if ((preselectedPlanAllDetails().billingPlan.trialDays && item.price.id !== preselectedPricePlan.id) || !preselectedPlanAllDetails().billingPlan.trialDays) {
                    result.push({
                        label: `$${displayIntegerOrTwoDecimalPlaces(item.price.interval.interval === 'year' ? (item.price.amount / 12) : item.price.amount)}/month`,
                        subLabel: `${item.name}${item.seatsAndMessagesPerMonth}`,
                        value: item.price.id,
                    });
                };
            });
        });

        return result;
    };

    return (
        <form
            className="Signup__form"
            onSubmit={
                handleSubmit((data) => {
                    onSubmit({...data, priceId: formData.priceId, address: formData.address})
                })
            }
        >
            <Loader loading={loading} />
            <FormField classStyling={visibleField} label=" " htmlFor="email" error={errors.email}>
                <input
                    data-test="email"
                    type="text"
                    name="email"
                    placeholder="Enter your business email"
                    defaultValue={formData.email}
                    className={classNames('form-input', { error: errors.email })}
                    ref={register({
                        required: 'Required',
                        pattern: {
                            value: re,
                            message: 'Invalid email',
                        },
                    })}
                    onChange={(e) => handleFieldChange<string>('email', e.target.value)}
                />
            </FormField>
            <FormField classStyling={visibleField} label=" " htmlFor="password" error={errors.password}>
                <div className="password-field">
                    <input
                        type={isShowPwd ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        defaultValue={formData.password}
                        className={classNames('form-input password-input', { error: errors.password })}
                        ref={(e) => {
                            register(e, {
                                required: 'Required',
                                minLength: {
                                    value: 8,
                                    message: 'At least 8 characters long',
                                },
                            });
                        }}
                        autoComplete="new-password"
                        onChange={(e) => handleFieldChange<string>('password', e.target.value)}
                    />
                    <button type="button" onClick={() => setIsShowPwd(prevShowPwd => !prevShowPwd)}>
                        {isShowPwd ? <>hide <EyeClosed/></> : <>show <EyeOpen/></>}
                    </button>
                </div>
            </FormField>
            <FormField label=" " htmlFor="phone" error={errors.phone}>
                <div className="phone-field">
                    <InputMask
                        mask="(999) 999-9999"
                        name="phone"
                        placeholder="Mobile phone (required for verification)"
                        className={classNames('form-input', { error: errors.phone })}
                        defaultValue={formData.phone || ''}
                        onChange={(e) => handleFieldChange<string>('phone', convertToPureNumber(e.target.value))}
                        inputRef={(e) => register(
                            e,
                            {
                                required: 'Required',
                                validate: (phone) =>
                                    convertToPureNumber(phone).length == 10
                                        ? undefined
                                        : 'Provide valid number'
                            }
                        )}
                    />
                </div>
            </FormField>
            <div className="Signup__form-row">
                <FormField label=" " htmlFor="firstName" error={errors.firstName}>
                    <input
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        defaultValue={formData.firstName}
                        className={classNames('form-input', { error: errors.firstName })}
                        ref={register({
                            required: 'Required',
                        })}
                        onChange={(e) => handleFieldChange<string>('firstName', e.target.value)}
                    />
                </FormField>
                <FormField label=" " htmlFor="lastName" error={errors.lastName}>
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Last name"
                        defaultValue={formData.lastName}
                        className={classNames('form-input', { error: errors.lastName })}
                        ref={register({
                            required: 'Required',
                        })}
                        onChange={(e) => handleFieldChange<string>('lastName', e.target.value)}
                    />
                </FormField>
            </div>
            <FormField label=" " htmlFor="companyName">
                <input
                    data-test="name"
                    type="text"
                    name="companyName"
                    placeholder="Company name"
                    className='form-input'
                    defaultValue={formData.companyName}
                    id="companyName"
                    ref={register({
                        required: 'Required',
                    })}
                    onChange={(e) => {
                        handleFieldChange<string>('companyName', e.target.value)
                        setTypedSthInCompanyName(true);
                    }}
                />
            </FormField>
            <FormField label=" " htmlFor="plans">
                <SignupSelect
                    options={getOptionsForPlanSelect()}
                    selectedOptionValue={formData.priceId}
                    placeholder="Choose your plan"
                    onChange={(e) => handleFieldChange<string>('priceId', e.value)}
                    classname="fullWidth"
                />
            </FormField>
            <div className="Signup__form-agreement">
                <CheckboxField
                    label={agreement}
                    defaultValue={formData.agree}
                    name="agree"
                    value={formData.agree}
                    onChange={() => handleFieldChange<boolean>('agree', !formData.agree)}
                    ref={register()}
                />
            </div>
            <button
                type="submit"
                className='btn-primary-full btn-submit btn-next-shortSignup'
                disabled={!enableSubmit(formData)}
            >
                Next
            </button>

        </form>
    );
};

export default Form;
