import api from '@src/api/api';

export const changePeriod = (data) => {
    return api.post('/changeperiod', data);
};

export const getProfileInfo = () => {
    return api.get('/profile');
};

export const getGeneralDashInfo = () => {
    return api.get('/index');
};

export const getThreads = () => {
    return api.get('/messaging/search');
}