import React, { useEffect, useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { FilterBar, KeyMetrics, Loader, Paging, SearchInput } from '@src/components';
import { useLoading } from '@src/hooks';
import { Onboarding } from '@src/pages/Onboarding/Onboarding';
import { LoadingState } from '@src/stores/models';
import { withReviewsStore, WithReviewsStoreProps } from '@src/stores/with-store';

import { ReviewContent } from './ReviewContent';
import { ReviewsFilters } from './ReviewsFilters';

import './reviews.sass';

const ReviewsWithStore: React.FC<WithReviewsStoreProps> = (props) => {
    const period = 'This Month';
    const store = props.ReviewsStore;
    store.onError = (message) => toast.error(message);

    const initLoading = useLoading(store.initLoading);
    const searchLoading = useLoading(store.searchLoading);
    const goalLoading = useLoading(store.goalLoading);
    const [expanded, setExpanded] = useState(true);
    // const [firstLoading, setFirstLoading] = useState(true);

    const anySites = store.anySites;
    const reviews = store.reviews;
    const total = store.count;
    const { filters } = store;
    const { search } = filters;

    const linksReload = () => store.initReviewsCalls();

    const searchReviews = () => store.searchReviews();

    useLayoutEffect(() => store.init(), []);

    useEffect(() => {
        linksReload();
    }, [store.anySites]);

    useEffect(() => {
        // if (!firstLoading) {
            searchReviews();
        // } else {
        //     const timeout = setTimeout(() => setFirstLoading(false), 2000);
        //     return clearTimeout(timeout);
        // };
    }, [store.page]);

    const onPageChange = (page: number) => (store.setPage(page));

    const onSearchSubmit = (searchTxt: string): void => {
        // if (!firstLoading) {
            store.setFilerSearch(searchTxt);
        // } else {
        //     const timeout = setTimeout(() => setFirstLoading(false), 2000);
        //     return clearTimeout(timeout);
        // };
    };

    const resultsLabelCls = classNames({
        'search-count': true,
        empty: !reviews.length,
    });
    const searchCls = classNames({ search: true, expanded: expanded });

    const loader = initLoading !== LoadingState.Loaded && <Loader loadingState={initLoading} />;

    const reviewFilters = (
        <div key="reviewsfilters" className="reviews-filters">
            <FilterBar expanded={expanded} expandedChange={(val) => setExpanded(val)}>
                <ReviewsFilters />
            </FilterBar>
        </div>
    );

    const sites = anySites && (
        <div key="reviewscontent" className="content">
            <div className={searchCls}>
                <SearchInput
                    people={[]}
                    onSearchTextChange={onSearchSubmit}
                    onSearchSubmit={(s) => onSearchSubmit(s.search)}
                />
                <div className="spacer" />
            </div>
            <div className="results">
                <div className="view">
                    <Loader loadingState={searchLoading} />
                    {search.get() || !reviews.length ? (
                        <h5 className={resultsLabelCls}>{reviews.length} Results found</h5>
                    ) : null}
                    {!reviews.length ? (
                        <div className="no-results">
                            <h2>Try a different search</h2>
                        </div>
                    ) : (
                        reviews.map((r) => (
                            <ReviewContent
                                loading={store.reviewsLoadings.get(r.review.id)}
                                key={r.review.id}
                                review={r}
                                removeReviewReply={(replySourceId) =>
                                    store.removeReplyFromReview(replySourceId)
                                }
                                saveReviewReply={(reviewId, replyId, replyTxt) =>
                                    store.saveReviewReply(reviewId, replyId, replyTxt)
                                }
                            />
                        ))
                    )}
                    {total > 1 ? (
                        <Paging
                            total={total}
                            onPageChange={(page) => onPageChange(page)}
                            currPage={store.page}
                        />
                    ) : null}
                </div>
                <div className="statistics">
                    <KeyMetrics
                        stats={store.stats}
                        period={period}
                        goalSaveState={goalLoading}
                        canEditGoal={true}
                        onEditGoal={(goal) => store.saveNewGoal(goal)}
                    >
                        <p className="card-title">Statistics</p>
                    </KeyMetrics>
                </div>
            </div>
        </div>
    );

    const onboarding = !anySites && (
        <div key="reviewslinks" className={classNames('Reviews__Links', { expanded: expanded })}>
            <Onboarding reviews={true} linksReload={linksReload} />
        </div>
    );

    const content = sites || onboarding;

    return <div className="Reviews">{loader || [reviewFilters, content]}</div>;
};

export const Reviews = withReviewsStore(ReviewsWithStore);
