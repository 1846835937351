import React, {useState} from 'react';
import { FormField } from '@src/components';
import { ReactComponent as FlagUsaSvg } from '@src/theme/icons/flag-usa-round.svg';
import InputMask from 'react-input-mask';
import { convertToPureNumber } from '@src/theme/utils/helpers';
import './ChangePhone.sass';

interface ChangePhoneProps {
    onNumberCorrection: (newPhone: string) => void;
};

export const ChangePhone: React.FunctionComponent<ChangePhoneProps> = (props: ChangePhoneProps): JSX.Element | null => {
    const [ correctingNumber, setCorrectingNumber ] = useState(false);
    const [ correctedNumber, setCorrectedNumber ] = useState('');

    const handleNumberCorrection = () => {
        setCorrectingNumber(false);
        setCorrectedNumber('');
        props.onNumberCorrection(correctedNumber);
    };

    return (
        <div className="ChangePhone">
            {!correctingNumber ?
                <div className="ChangePhone__incorrectNumber">
                    <p>Incorrect number?</p>
                    <button onClick={() => setCorrectingNumber(true)}>Change it</button>
                </div>
            :
                <div className="ChangePhone__form">
                    <FormField label=" " htmlFor="phone">
                        <div className="phone-field">
                            <InputMask
                                mask="(999) 999-9999"
                                name="phone"
                                placeholder="Correct mobile phone"
                                className='form-input'
                                onChange={(e) => setCorrectedNumber(convertToPureNumber(e.target.value))}
                            />
                        </div>
                    </FormField>
                    <div className="confirm">
                        <button
                            className="btn-primary"
                            disabled={!correctedNumber || !correctedNumber.length || convertToPureNumber(correctedNumber).length !== 10}
                            onClick={handleNumberCorrection}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};
