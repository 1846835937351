import React from 'react'
import { images } from '@src/theme/icons/dashboard';
import { ProgressBar } from '@src/components/ProgressBar/ProgressBar';

interface FacebookReviewsData {
    period: string,
    totalNumber: number;
    currNumber: number;
    avgRecommendation: number;
    recommends: number;
    doNotRecommends: number;
}

interface ReviewsFacebookProps {
    data: FacebookReviewsData
};

export const ReviewsFacebook: React.FunctionComponent<ReviewsFacebookProps> = ({ data }) => (
  <div className="ReviewsPerSite__facebook column">
    <div className="ReviewsPerSite__avgRate">
        <img className="logo" src={images.fb} alt="facebook-logo" />
        {data.avgRecommendation && (
            <span className="approved">
                <img src={images.thumb} alt="thumb" />
                Recommends
            </span>
        )}
        <span className="info">
            {data.currNumber} Reviews
        </span>
    </div>
    <div className="ReviewsPerSite__details">
        <div className="ReviewsPerSite__details-star">
            <span className="fb">Recommends</span>
            <ProgressBar
                current={
                    data.recommends
                }
                total={
                    data.currNumber
                }
            />
            <span>
                {data.recommends}
            </span>
        </div>
        <div className="ReviewsPerSite__details-star">
            <span className="fb">Do Not</span>
            <ProgressBar
                current={data.doNotRecommends}
                total={data.currNumber}
            />
            <span>
                {
                    data.doNotRecommends
                }
            </span>
        </div>
    </div>
    <div className="ReviewsPerSite__overview">
        <p>
            {' '}
            {data.currNumber} Reviews {data.period.toLocaleLowerCase()}
        </p>
        <p>
            {' '}
            {data.totalNumber} Total Reviews
        </p>
    </div>
  </div>

);
