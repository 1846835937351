import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import List from './List/List';
import DetailsCard from './DetailsCard/DetailsCard';
import { Broadcast, BroadcastStatus, broadcastStatusLabels } from '@src/stores/models/broadcast_new';
import { STORES, WithBroadcastsStoreProps, withStores, WithUserStoreProps } from '@src/stores/with-store';
import { Loader } from '@src/components/Loader/Loader';
import { LoadingState } from '@src/stores/models/loading-state';
import SendCard from './SendCard/SendCard';
import { ReactComponent as PlusIcon } from '@src/theme/icons/plus.svg';
import { SearchInput, SearchSubmitArgs } from '@src/components';
import broadcastsStore from '@src/stores/broadcasts.store';
import NewBroadcastDetailsCard from './DetailsCard/NewBroadcastDetailsCard';

const emptyListInfo = (status: BroadcastStatus) => (`You currently have no ${broadcastStatusLabels[status].toLowerCase()} broadcasts`);

type ExistingBroadcastsProps = WithBroadcastsStoreProps & WithUserStoreProps &
{
    filterBarExpanded: boolean;
    onNewBroadcastClick: (params?: {broadcastId: string, state:"replied" | "unreplied"}) => void;
    onEditBroadcastClick: (broadcast: Broadcast) => void;
}

const ExistingBroadcastsWithStores: React.FunctionComponent<ExistingBroadcastsProps> = ({ filterBarExpanded, onNewBroadcastClick, onEditBroadcastClick, BroadcastsStore, UserStore }) => {
    const [selectedId, setSelectedId] = useState<string | null>(null);

    const broadcasts = BroadcastsStore.list;
    const timeZone = UserStore.user.employee.timeZone;

    useEffect(() => {
        setSelectedId(null);
    }, [BroadcastsStore.currentStatus]);

    useEffect(() => {
        if (broadcasts.length > 0) {
            setSelectedId(broadcasts[0].id);
        };
    }, [broadcasts]);

    const handleCardClick = (broadcastId: string) => {
        setSelectedId(broadcastId);
    };

    const handleDeleteClick = (broadcastId: string) => {
        BroadcastsStore.deleteBroadcast(broadcastId);
        setSelectedId(null);
    };

    const handleEditBroadcastClick = (broadcast: Broadcast) => {
        onEditBroadcastClick(broadcast);
    };

    const handleStartBroadcastClick = (params: {state: "replied" | "unreplied"}) => {
        if(selectedId) {
            onNewBroadcastClick({
                broadcastId: selectedId,
                state: params.state
            });
        };
    };

    const handleLoadMore = () => {
        if (BroadcastsStore.listLoading === LoadingState.Loaded) {
            BroadcastsStore.getBroadcasts({});
        };
    };

    const handleSearchSubmit = (s: SearchSubmitArgs) => {
        if (BroadcastsStore.listLoading === LoadingState.Loaded) {
            BroadcastsStore.getBroadcasts({query: s.search});
        };
    }

    const renderFirstLoader = (
        <Loader
            loading={
                (BroadcastsStore.listLoading === LoadingState.Loading && BroadcastsStore.list.length === 0) ||
                (BroadcastsStore.broadcastLoading === LoadingState.Loading)
             }
        />
    );

    const renderBroadcastDetails = broadcastsStore.listLoading === LoadingState.Loaded &&
        <>
            {selectedId && broadcasts.length > 0 &&
                <>
                    <DetailsCard
                        classname="min-height40vh"
                        broadcast={broadcasts.filter(broadcast => broadcast.id === selectedId)[0]}
                        timezone={timeZone}
                        onDeleteClick={() => handleDeleteClick(selectedId)}
                        onEditBroadcastClick={handleEditBroadcastClick}
                    />
                    <SendCard disabled={BroadcastsStore.currentStatus === 'SCHEDULED'} onStartBroadcastClick={handleStartBroadcastClick}/>
                </>
            }
            {!selectedId && broadcasts.length === 0 &&
                <NewBroadcastDetailsCard onCreateClick={() => onNewBroadcastClick()}/>
            }
        </>

    return (
        <>
            {renderFirstLoader}
            <div className="Broadcasts__column Broadcasts__left-column">
                <div className={classNames('top_bar Broadcasts__top-bar infinte-scroll-margin', { 'with-left-margin': !filterBarExpanded})}>
                    {broadcasts.length > 0 &&
                        <SearchInput
                            people={[]}
                            onSearchSubmit={handleSearchSubmit}
                        />
                    }
                </div>
                <div className="Broadcasts__column-content">
                    {broadcasts.length > 0 &&
                        <List
                            broadcasts={broadcasts}
                            selectedId={selectedId}
                            totalBroadcasts={BroadcastsStore.total}
                            isLoading={BroadcastsStore.listLoading === (LoadingState.Loading || LoadingState.FirstLoading)}
                            onCardClick={handleCardClick}
                            onLoadMore={handleLoadMore}
                        />
                    }
                    {broadcastsStore.listLoading === LoadingState.Loaded && broadcasts.length === 0 &&
                        <div className="Broadcasts__empty-list">
                            {emptyListInfo(BroadcastsStore.currentStatus)}
                        </div>
                    }
                    </div>
            </div>
            <div className="Broadcasts__column Broadcasts__right-column">
                <div className="top-bar Broadcasts__top-bar justify-end">
                    <button className="Broadcasts__top-bar-button" onClick={() => onNewBroadcastClick()}>
                        <PlusIcon />
                        <span>New Broadcast</span>
                    </button>
                </div>
                <div className="Broadcasts__column-content">
                    {renderBroadcastDetails}
                </div>
            </div>
        </>
    );
};

const ExistingBroadcasts = withStores(ExistingBroadcastsWithStores, [
    STORES.BroadcastsStore,
    STORES.User
]);

export default ExistingBroadcasts;
