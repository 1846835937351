import React from 'react';
import { inject, observer } from 'mobx-react';
import { SignupStore } from '@src/stores/signup.store';
import { ReviewsStore } from '@src/stores/reviews.store';
import { CustomerStore } from '@src/stores/customer.store';
import { CustomersStore } from '@src/stores/customers.store';
import { ProfileStore } from '@src/stores/profile.store';
import { DashboardStore } from '@src/stores/dashboard.store';
import { LeaderboardStore } from '@src/stores/leaderboard.store';
import { TeamStore } from '@src/stores/team.store';
import { WebchatStore } from '@src/stores/webchat.store';
import { UserStore } from '@src/stores/user.store';
import { BillingStore } from '@src/stores/billing.store';
import { InvoicesStore } from '@src/stores/invoices.store';
import { AuthStore } from '@src/stores/auth.store';
import { PhoneStore } from '@src/stores/phone.store';
import { PersonalSettingsStore } from '@src/stores/personal-settings.store';
import { TemplateStore } from '@src/stores/template.store';
import { NotificationsStore } from '@src/stores/notifications.store';
import { TagsStore } from '@src/stores/tags/tags.store';
import { ReviewSitesStore } from '@src/stores/review-sites.store';
import { MessagesStore } from './messages/messages.store';
import { ThreadsStore } from './messages/threads.store';
import { ConversationsStore } from "@src/stores/teamchat/conversations.store";
import { IntegrationsStore } from '@src/stores/integrations.store';
import { WalkthroughStore } from '@src/stores/walkthrough.store';
import { BroadcastsStore } from './broadcasts.store';
import { CouponStore } from './coupon.store';

export const STORES = {
    Signup: 'SignupStore',
    Customer: 'CustomerStore',
    Customers: 'CustomersStore',
    Profile: 'ProfileStore',
    Dashboard: 'DashboardStore',
    ImportCustomersStore: 'ImportCustomersStore',
    Leaderboard: 'LeaderboardStore',
    Reviews: 'ReviewsStore',
    Messages: 'MessagesStore',
    Team: 'TeamStore',
    Webchat: 'WebchatStore',
    User: 'UserStore',
    Auth: 'AuthStore',
    Billing: 'BillingStore',
    Invoices: 'InvoicesStore',
    Phone: 'PhoneStore',
    PersonalSettings: 'PersonalSettingsStore',
    Tags: 'TagsStore',
    Template: 'TemplateStore',
    ThreadsStore: 'ThreadsStore',
    TwilioPhoneStore: 'TwilioPhoneStore',
    Notifications: 'NotificationsStore',
    ReviewSites: 'ReviewSitesStore',
    Integrations: 'IntegrationsStore',
    Walkthrough: 'WalkthroughStore',
    BroadcastsStore: 'BroadcastsStore',
    ConversationsStore: 'ConversationsStore',
    Coupon: 'CouponStore'
};

export const withStore = <P extends unknown>(
    WrappedComponent: React.JSXElementConstructor<unknown>,
    storeName: string
): React.FC => {
    return inject(storeName)((props: P) => {
        return <WrappedComponent {...props} />;
    });
};

export const withStores = <P extends unknown>(
    WrappedComponent: React.JSXElementConstructor<unknown>,
    storesNames: string[]
): React.JSXElementConstructor<any> => {
    const Wrapped = observer(WrappedComponent);
    return inject(...storesNames)((props: P) => {
        return <Wrapped {...props} />;
    });
};

export interface WithCouponStoreProps {
    CouponStore?: CouponStore;
}

export interface WithSignupStoreProps {
    SignupStore?: SignupStore;
}

export interface WithReviewsStoreProps {
    ReviewsStore?: ReviewsStore;
}

export interface WithMessagesStoreProps {
    MessagesStore?: MessagesStore;
}

export interface WithCustomerStoreProps {
    CustomerStore?: CustomerStore;
}

export interface WithCustomersStoreProps {
    CustomersStore?: CustomersStore;
}

export interface WithProfileStoreProps {
    ProfileStore?: ProfileStore;
}

export interface WithDashboardStoreProps {
    DashboardStore?: DashboardStore;
}

export interface WithLeaderboardStoreProps {
    LeaderboardStore?: LeaderboardStore;
}

export interface WithTagStoreProps {
    TagsStore?: TagsStore;
}

export interface WithTeamStoreProps {
    TeamStore?: TeamStore;
}

export interface WithWebchatStoreProps {
    WebchatStore?: WebchatStore;
}

export interface WithThreadsStoreProps {
    ThreadsStore?: ThreadsStore;
}

export interface WithUserStoreProps {
    UserStore?: UserStore;
}

export interface WithBillingStoreProps {
    BillingStore?: BillingStore;
}

export interface WithInvoicesStoreProps {
    InvoicesStore?: InvoicesStore;
}

export interface WithAuthStoreProps {
    AuthStore?: AuthStore;
}

export interface WithPhoneStoreProps {
    PhoneStore?: PhoneStore;
}

export interface WithPersonalSettingsStoreProps {
    PersonalSettingsStore?: PersonalSettingsStore;
}

export interface WithTemplateStoreProps {
    TemplateStore?: TemplateStore;
}

export interface WithNotificationsStoreProps {
    NotificationsStore?: NotificationsStore;
}

export interface WithReviewSitesStoreProps {
    ReviewSitesStore?: ReviewSitesStore;
}

export interface WithIntegrationsStoreProps {
    IntegrationsStore?: IntegrationsStore;
}

export interface WithBroadcastsStoreProps {
    BroadcastsStore?: BroadcastsStore;
}

export interface WithConversationsStoreProps {
    ConversationsStore?: ConversationsStore;
}

export interface WithWalkthroughStoreProps {
    WalkthroughStore?: WalkthroughStore;
}

export const WithSignupStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithSignupStoreProps>(observer(WrappedComponent), STORES.Signup);
};

export const withReviewsStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithReviewsStoreProps>(observer(WrappedComponent), STORES.Reviews);
};

export const withCustomerstore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithCustomerStoreProps>(observer(WrappedComponent), STORES.Customer);
};

export const withCustomersStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithCustomersStoreProps>(observer(WrappedComponent), STORES.Customers);
};

export const withProfileStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithProfileStoreProps>(observer(WrappedComponent), STORES.Profile);
};

export const withDashboardStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithDashboardStoreProps>(observer(WrappedComponent), STORES.Dashboard);
};

export const withLeaderboardStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithLeaderboardStoreProps>(observer(WrappedComponent), STORES.Leaderboard);
};

export const withTeamStore = (WrappedComponent: React.JSXElementConstructor<unknown>): React.FC => {
    return withStore<WithTeamStoreProps>(observer(WrappedComponent), STORES.Team);
};

export const withWebchatStore = (WrappedComponent: React.JSXElementConstructor<unknown>): React.FC => {
    return withStore<WithWebchatStoreProps>(observer(WrappedComponent), STORES.Webchat);
};

export const withUserStore = (WrappedComponent: React.JSXElementConstructor<unknown>): React.FC => {
    return withStore<WithUserStoreProps>(observer(WrappedComponent), STORES.User);
};

export const withMessagesStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithMessagesStoreProps>(observer(WrappedComponent), STORES.Messages);
};

export const withBillingStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithBillingStoreProps>(observer(WrappedComponent), STORES.Billing);
};

export const withInvoicesStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithInvoicesStoreProps>(observer(WrappedComponent), STORES.Invoices);
};

export const withAuthStore = (WrappedComponent: React.JSXElementConstructor<unknown>): React.FC => {
    return withStore<WithAuthStoreProps>(observer(WrappedComponent), STORES.Auth);
};

export const withPhoneStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithPhoneStoreProps>(observer(WrappedComponent), STORES.Phone);
};

export const withPersonalSettingsStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithPersonalSettingsStoreProps>(
        observer(WrappedComponent),
        STORES.PersonalSettings
    );
};

export const withTemplateStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithTemplateStoreProps>(observer(WrappedComponent), STORES.Template);
};

export const withNotificationsStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithNotificationsStoreProps>(observer(WrappedComponent), STORES.Notifications);
};

export const withReviewSitesStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithReviewSitesStoreProps>(observer(WrappedComponent), STORES.ReviewSites);
};

export const withIntegrationsStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithIntegrationsStoreProps>(observer(WrappedComponent), STORES.Integrations);
};

export const withBroadcastsStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithBroadcastsStoreProps>(observer(WrappedComponent), STORES.BroadcastsStore);
};

export const withConversationsStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithConversationsStoreProps>(observer(WrappedComponent), STORES.ConversationsStore);
};

export const withWalkthroughStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithWalkthroughStoreProps>(observer(WrappedComponent), STORES.Walkthrough);
};

export const withCouponStore = (
    WrappedComponent: React.JSXElementConstructor<unknown>
): React.FC => {
    return withStore<WithCouponStoreProps>(observer(WrappedComponent), STORES.Coupon);
};
