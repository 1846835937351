import { action, observable, runInAction, computed } from 'mobx';
import { Attachment, LoadingState } from '../models';
import { TeamMessageEvent } from "@src/stores/models/team-chat";
import {
    getConversation,
    getMessages,
    sendMessage,
    joinChannel,
    editMessage,
    deleteMessage,
    leaveChannel,
} from "@src/requests/team-chat.requests";
import { BaseStore } from "@src/stores/base.store";
import { SocketEventType, SocketWrapper } from "@src/api/socket-wrapper";
import MessagesStore from '@src/stores/messages/messages.store';
import UserStore from '@src/stores/user.store';

export default class SelectedChannelStore extends BaseStore {
    @observable ConversationsStore;
    private socketWrapper: SocketWrapper;
    @observable _selectedChannel = null;
    @observable dataLoaded = false;
    @observable hasMoreMessagesToLoad = false;
    @observable totalCurrentMembers = 0;
    @observable _currentMembers = [];
    @observable messages = null;
    @observable memberChanges = null;
    @observable messagesPage = null;
    @observable messagesSize = null;
    @observable messagesTotal = null;
    @observable messagesTime = null;
    @observable channelLoading: LoadingState = LoadingState.Init;
    @observable key = 0;
    @observable userWasRemovedFromChannel = false;
    private fakeIdNo = 0;

    onError: (message: string) => void;

    constructor(socketWrapper: SocketWrapper, ConversationsStore) {
        super();
        this.socketWrapper = socketWrapper;

        this.ConversationsStore = ConversationsStore;
    }

    @action init(): void {
        this._selectedChannel = null;
        this.dataLoaded = false;
        this.totalCurrentMembers = 0;
        this._currentMembers = [];
        this.messages = null;
        this.memberChanges = null;
        this.channelLoading = LoadingState.Init;
        this.userWasRemovedFromChannel = false;
        if (this.socketWrapper) {
            this.socketWrapper.addEventListener(SocketEventType.TeamMessage, (incomingMsg) => {
                this.messageAdded(incomingMsg);
                this.checkIfDisplayNewMessageIcon(incomingMsg);
            });
            this.socketWrapper.addEventListener(SocketEventType.TeamMessageUpdated, (incomingMsg) => {
                this.messageUpdated(incomingMsg);
            });
            this.socketWrapper.addEventListener(SocketEventType.TeamMessageDeleted, (incomingMsg) => {
                this.messageDeleted(incomingMsg);
            });
            this.socketWrapper.addEventListener(SocketEventType.TeamMembershipChange, (incomingMsg) => {
                this.memberChangeAdded(incomingMsg);
            });
        }
    }

    @action destroy(): void {
        this._selectedChannel = null;
        this.dataLoaded = false;
        this.hasMoreMessagesToLoad = false;
        this.totalCurrentMembers = 0;
        this._currentMembers = [];
        this.messages = null;
        this.memberChanges = null;
        this.messagesPage = null;
        this.messagesSize = null;
        this.messagesTotal = null;
        this.messagesTime = null;
        this.channelLoading = LoadingState.Init;
        this.key = 0;
        this.userWasRemovedFromChannel = false;
        this.fakeIdNo = 0;
    }

    @computed get selectedChannel() {
        return this._selectedChannel;
    }

    @action setSelectedChannel(val) {
        this._selectedChannel = val;
    }

    @computed get currentMembers() {
        return this._currentMembers;
    }

    @action setCurrentMembers(val) {
        this._currentMembers = val;
    }

    @action setUserWasRemovedFromChannel(val) {
        this.userWasRemovedFromChannel = val;
    }

    checkIfDisplayNewMessageIcon = (e: TeamMessageEvent) => {
        if (e.sender !== UserStore.user?.employee?.id) {
            UserStore.addUnreadMessagesDot('teamConversations', e.message.conversationId);
        };
    };

    @action messageAdded = (e: TeamMessageEvent) => {
        if (!this._selectedChannel || this._selectedChannel.id !== e.message.conversationId) {
            return;
        }
        const alreadyExists = this.messages.find((msg) => { return msg.id === e.message.id });
        if (!alreadyExists) {
            this.messages = [
                ...this.messages,
                e.message
            ];
        };
        this.messages = this.messages.filter(el => el.message !== e.message.message || !el.id.startsWith('fake'));
        this.key += 1;
    }

    @action messageUpdated = (e: TeamMessageEvent) => {
        if (!this._selectedChannel || this._selectedChannel.id !== e.message.conversationId) {
            return;
        }
        const alreadyExists = this.messages.find((msg) => { return msg.id === e.message.id });
        if (alreadyExists) {
            this.messages =
                this.messages.map((x) => {
                    if (x.id == e.message.id) return e.message;
                    else return x;
                })
        }
        // this.key += 1;
    }

    @action memberChangeAdded = (e) => {
        if (!this._selectedChannel || this._selectedChannel.id !== e.conversation.id) {
            return;
        }
        const alreadyExists = this.memberChanges.find((mc) => { return mc.id === e.data.id });
        if (!alreadyExists) {
            this.memberChanges = [
                ...this.memberChanges,
                e.data
            ];
        };
        // this.key += 1;
    }

    @action messageDeleted = (e) => {
        if (!this._selectedChannel || this._selectedChannel.id !== e.conversationId) {
            return;
        }
        this.messages = this.messages.filter((msg) => msg.id !== e.messageId);
        // this.key += 1;
    }

    @action selectChannel = (cid: string, channelName: string) => {
        this.channelLoading = LoadingState.Loading;
        return getConversation(cid)
        .then(
            (res) => {
                runInAction(() => {
                    const participantsWithName = res.participants.map(p => {
                        const participantWithDetails = Object.values(MessagesStore?.ThreadsStore.constants?.employees).find(e => e.id === p.participantId);
                        const name = `${participantWithDetails.firstName} ${participantWithDetails.lastName}`;
                        return {...p, name};
                    })
                    this._selectedChannel = {
                        id: res.conversation.id,
                        channelName
                    }
                    this._currentMembers = participantsWithName;
                    this.messages = Object.values(res.messages.messages);
                    this.memberChanges = Object.values(res.messages.memberChanges);
                    this.messagesTime = res.messages.time;
                    this.messagesTotal = res.messages.total;
                    this.messagesPage = res.messages.page;
                    this.messagesSize = res.messages.size;
                    this.totalCurrentMembers = res.participants.length // liczba z BE?
                    this.hasMoreMessagesToLoad = res.messages.total > (res.messages.page * res.messages.size);
                    this.channelLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.channelLoading = LoadingState.Error;
                });
                return null;
            }
        )
    }

    @action getConversationMessages = () => {
        this.channelLoading = LoadingState.Loading;
        return getMessages(this._selectedChannel.id, this.messagesPage + 1, this.messagesTime).then(
            (res) => {
                runInAction(() => {
                    this.messages = [...this.messages, ...Object.values(res.messages)];
                    this.memberChanges = [...this.memberChanges, ...Object.values(res.memberChanges)];
                    this.messagesPage = res.page
                    this.messagesTime = res.time
                    this.messagesTotal = res.total
                    this.messagesSize = res.size
                    this.hasMoreMessagesToLoad = res.total > (res.page * res.size)
                    this.channelLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.channelLoading = LoadingState.Error;
                });
                return null;
            }
        )
    }

    @action closeConversation(): Promise<void> {
        if (!this._selectedChannel) {
            return;
        }
        setTimeout(() => {
            runInAction(() => {
                this._selectedChannel = null;
            });
        }, 10);
        return Promise.resolve();
    }

    @action joinChannel = (channelId: string) => {
        this.channelLoading = LoadingState.Loading;
        return joinChannel(channelId).then(
            () => {
                runInAction(() => {
                    this.channelLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.channelLoading = LoadingState.Error;
                });
                return null;
            }
        );
    }

    @action leaveChannel = (channelId = this._selectedChannel.id) => {
        this.channelLoading = LoadingState.Loading;
        return leaveChannel(channelId).then(
            () => {
                runInAction(() => {
                    this.ConversationsStore.ConversationsListStore.setChannelsList(
                        this.ConversationsStore.ConversationsListStore.channelsList?.filter((ch) => (ch?.conversationId !== channelId))
                    );
                    this.channelLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.channelLoading = LoadingState.Error;
                });
                return null;
            }
        );
    }

    @action sendMessage = (message: string, attachments: Attachment[]) => {
        this.fakeIdNo = this.fakeIdNo + 1;
        const fakeSendingMsg = {
            attachments: [],
            conversationId: this._selectedChannel.id,
            created: new Date().toISOString(),
            id: 'fake' + this.fakeIdNo,
            message: message,
            sentBy: MessagesStore.ThreadsStore.constants?.employee.id,
            // x: ''
        };
        this.messages = [
            ...this.messages,
            fakeSendingMsg
        ];
        this.key += 1;

        return sendMessage({conversationId: this._selectedChannel.id, message: message, attachments: attachments}).then(
            (res) => {
                runInAction(() => {
                    if (!this.messages.find(el => el.id === res.id)) {
                        this.messages = [
                            ...this.messages,
                            res
                        ];
                        this.messages = this.messages.filter(el => el.message !== res.message || !el.id.startsWith('fake'));
                    }
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.onError('Something went wrong and your message hasn\'t been sent. Please try again later.');

                    const unsuccesfulSentMsg = {
                        ...this.messages.find(m => m.id === `fake${this.fakeIdNo}`),
                        unsuccessfulSending: true
                    };
                    this.messages = this.messages.filter(m => m.id !== `fake${this.fakeIdNo}`);
                    this.messages = [
                        ...this.messages,
                        unsuccesfulSentMsg
                    ];
                });
                return null;
            }
        );
    }

    @action editMessage = (updatedMsg) => {
        this.channelLoading = LoadingState.Loading;
        return editMessage(updatedMsg.id, updatedMsg.message).then(
            () => {
                runInAction(() => {
                    const newMessagesList = this.messages.filter(el => el.id !== updatedMsg.id);
                    this.messages = [
                        ...newMessagesList,
                        updatedMsg
                    ];
                    this.channelLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.channelLoading = LoadingState.Error;
                });
                return null;
            }
        );
    }

    @action deleteMessage = (messageId: string, conversationId: string) => {
        this.channelLoading = LoadingState.Loading;
        return deleteMessage(messageId, conversationId).then(
            () => {
                runInAction(() => {
                    this.messages = this.messages.filter(el => el.id !== messageId);
                    this.channelLoading = LoadingState.Loaded;
                });
                return true;
            },
            () => {
                runInAction(() => {
                    this.channelLoading = LoadingState.Error;
                });
                return null;
            }
        );
    }
}

// export default new SelectedConversationStore(SocketInstance);
