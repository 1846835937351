import api from '@src/api/api';

export const searchTags = (tag = '', page, size, sort = 'value.asc') => {
    return api.get('/tags', { params: { tag, page, size, sort } });
};

export const addTag = (value, customerIds) => {
    return api.post('/tags/add', { value, customerIds });
};

export const updateTag = (id, value) => {
    return api.post(`/tags/${id}`, { value: value });
};

export const deleteTag = (id) => {
    return api.delete(`/tags/${id}/delete`);
};

export const searchTagMembers = (id: string, page = 1, size = 100, search = null) => {
    let params;
    const someParams = { page, size, tags: [id] };
    search ? (params = { ...someParams, search }) : (params = { ...someParams });
    return api.get('/tags/customers/search', { params });
};

export const searchTagNonMembers = (id: string, page = 1, size = 100, search = null) => {
    let params;
    const someParams = { page, size, tags: [id] };
    search ? (params = { ...someParams, search }) : (params = { ...someParams });
    return api.get('/tags/nonmember/search', { params });
};

export const addMember = (tagId, cid) => {
    return api.post(`/tags/${tagId}/customers/${cid}`);
};

export const deleteMember = (tagId, cid) => {
    return api.delete(`/tags/${tagId}/customers/${cid}`);
};
