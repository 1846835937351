import { observable, action, computed, runInAction } from 'mobx';
import { uniq } from 'ramda';
import { Template, LoadingState, OnErrorDataTemplates, Attachment } from './models';
import { templatesApi } from '@src/requests';
import AuthStore from '@src/stores/auth.store';

export class TemplateStore {
    @observable _templates: Template[] = [];
    @observable dataLoaded = false;
    @observable loadingState = LoadingState.Init;
    private _onError: (type: string, data?: OnErrorDataTemplates) => void;
    private _onSuccess: () => void;

    set onSuccess(fn: () => void) {
        this._onSuccess = fn;
    }

    set onError(fn: (type: string, data?: OnErrorDataTemplates) => void) {
        this._onError = fn;
    }

    @action init(): void {
        this._templates = [];
        this.dataLoaded = false;
        this.loadingState = LoadingState.Init;
    }

    @action setTemplates(templates: Template[]): void {
        this._templates = templates;
    }

    @computed get templates(): Template[] {
        return this._templates ? this._templates : [];
    }

    @action getBEData (errAction?: string, firstLoading = false) {
        this.loadingState = firstLoading ? LoadingState.FirstLoading : LoadingState.Loading;
        return templatesApi.getTemplates().then(
            ({ data }) => {
                runInAction(() => {
                    const rawTemplates = data.templates;
                    const uniqueIds = uniq(rawTemplates.map((t: Template) => t.id));
                    const templates = uniqueIds
                        .map((id: string) => {
                            const templatesById = rawTemplates.filter((t: Template) => t.id === id);
                            const template = templatesById[0];
                            const hasTemplateNoAttachments =
                                templatesById.length == 1 && !template.attachments;
                            const attachments = hasTemplateNoAttachments
                                ? []
                                : templatesById.map((t: Template) => t.attachments);
                            return {
                                ...template,
                                attachments,
                            };
                        })
                        .sort(
                            (a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime()
                        );

                    this._templates = templates;
                    this.dataLoaded = true;
                    this.loadingState = LoadingState.Loaded;
                });
            },
            (err) => {
                if (err.response.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    if (this._onError && errAction) {
                        this._onError(errAction);
                    };
                };
            }
        );
    };

    @action removeTemplate = (id: string, errAction: string) => {
        this.loadingState = LoadingState.Loading;
        templatesApi.deleteTemplate(id).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return this.getBEData('getBE');
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { templateId: id });
                    }
                });
            }
        );
    };

    @action updateTemplate = (
        templateId: string,
        title: string,
        message: string,
        loaded: {},
        errAction: string
    ) => {
        const mappedAttachments = Object.values(loaded).map((att: Attachment) => {
            const loaded = { ...att, id: Math.random().toString(36).slice(2) };
            delete loaded.url;
            return loaded;
        });
        this.loadingState = LoadingState.Loading;
        return templatesApi.postTemplate(title, message, mappedAttachments, templateId).then(
            (res) => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return res.data.data;
                // return this.getBEData('getBE');
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { templateId, title, message, mappedAttachments });
                    }
                });
            }
        );
    };
}

export default new TemplateStore();
