import React from 'react';
import { BillingStore } from '@src/stores/billing.store';
import { PlanDetails } from '@src/components';
import { ChangingPlanData, PlanComponent } from '@src/stores/models/billing';

import '../payment-inside-stripe.sass';

interface HeaderProps {
    billingStore?: BillingStore;
    planData: ChangingPlanData;
    components?: PlanComponent[];
};

const ChangePlanHeader: React.FunctionComponent<HeaderProps> = ({ billingStore, planData, components }) => {
    return (
        <>
            <div className="Pricing__title2">Subscription</div>
            {billingStore.currentSubscription?.details?.status === 'trialing' ?
                <div className="Pricing__title3">Changing plan will finish your trial period - you will benefit from new features and be charged the new plan price.</div>
                : <div className="Pricing__title3">To finalize changing plan, please provide your credit card data - you will be charged the new plan price.</div>
            }
            <PlanDetails
                view="changePlan"
                planData={planData}
                components={components}
            />
        </>
    )
};

export default ChangePlanHeader;
