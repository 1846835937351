import React from 'react'
import { images } from '@src/theme/icons/dashboard';
import { StarsRating } from '../../StarsRating/StarsRating';
import { ProgressBar } from '@src/components/ProgressBar/ProgressBar';

interface GoogleReviewsData {
    period: string,
    totalNumber: number;
    currNumber: number;
    avgRate: number;
    stars: number[];
}

interface ReviewsGoogleProps {
    data: GoogleReviewsData
};

export const ReviewsGoogle: React.FunctionComponent<ReviewsGoogleProps> = ({ data }) => (
    <div className="ReviewsPerSite__google column">
        <div className="ReviewsPerSite__avgRate">
            <img className="logo" src={images.google} alt="google-logo" />
            <StarsRating
                rate={data.avgRate}
            />
            <span className="info">
                {data.avgRate}
            </span>
        </div>
        <div className="ReviewsPerSite__details">
            <div className="ReviewsPerSite__details-star">
                <span>5 star</span>
                <ProgressBar
                    current={data.stars[5]}
                    total={data.currNumber}
                />
                <span>
                    {data.stars[5]}
                </span>
            </div>
            <div className="ReviewsPerSite__details-star">
                <span>4 star</span>
                <ProgressBar
                    current={data.stars[4]}
                    total={data.currNumber}
                />
                <span>
                    {data.stars[4]}
                </span>
            </div>
            <div className="ReviewsPerSite__details-star">
                <span>3 star</span>
                <ProgressBar
                    current={data.stars[3]}
                    total={data.currNumber}
                />
                <span>
                    {data.stars[3]}
                </span>
            </div>
            <div className="ReviewsPerSite__details-star">
                <span>2 star</span>
                <ProgressBar
                    current={data.stars[2]}
                    total={data.currNumber}
                />
                <span>
                    {data.stars[2]}
                </span>
            </div>
            <div className="ReviewsPerSite__details-star">
                <span>1 star</span>
                <ProgressBar
                    current={data.stars[1]}
                    total={data.currNumber}
                />
                <span>
                    {data.stars[1]}
                </span>
            </div>
        </div>
        <div className="ReviewsPerSite__overview">
            <p>
                {' '}
                {
                    data.currNumber
                } Reviews {data.period.toLocaleLowerCase()}
            </p>
            <p>
                {' '}
                {data.totalNumber} Total
                Reviews
            </p>
        </div>
    </div>
);
