import { observable, action, runInAction } from 'mobx';
import { Employee, LoadingState, MemberData, OnErrorDataTeam, Changes, NewMember } from './models';
import {
    getTeam,
    archiveTeamMember,
    restoreTeamMember,
    resendInvitation,
    addTeamMember,
    updateTeamMemberName,
    updateTeamMemberRole,
    updateTeamMemberEmail,
} from '@src/requests/team.requests';
import AuthStore from '@src/stores/auth.store';

export class TeamStore {
    @observable employees: Employee[] = [];
    @observable stats = {};
    @observable seats: number;
    @observable dataLoaded = false;
    @observable loadingState: LoadingState = LoadingState.Init;
    private _onError: (type: string, data?: OnErrorDataTeam) => void;
    private _onErrorAlreadyUsedEmail: (data: string) => void;
    private _onErrorLimitHit: () => void;
    private _onSuccess: () => void;
    private _onSuccessReinvite: () => void;

    set onSuccess(fn: () => void) {
        this._onSuccess = fn;
    }

    set onSuccessReinvite(fn: () => void) {
        this._onSuccessReinvite = fn;
    }

    set onError(fn: (type: string, data?: OnErrorDataTeam) => void) {
        this._onError = fn;
    }

    set onErrorAlreadyUsedEmail(fn: (data: string) => void) {
        this._onErrorAlreadyUsedEmail = fn;
    }

    set onErrorLimitHit(fn: () => void) {
        this._onErrorLimitHit = fn;
    }

    @action init(): void {
        this.stats = {};
        this.seats = null;
        this.employees = [];
        this.dataLoaded = false;
        this.loadingState = LoadingState.Init;
    }

    @action setEmployees = (employees: Employee[]): void => {
        this.employees = employees;
    };

    @action setTeamStats = (stats): void => {
        this.stats = stats;
    };
    @action getAndSetTeam = (errAction: string, firstLoading = false) => {
        this.loadingState = firstLoading ? LoadingState.FirstLoading : LoadingState.Loading;
        getTeam().then(
            ({ data }) => {
                runInAction(() => {
                    this.setEmployees(data.employees);
                    this.seats = data.seats;
                    this.dataLoaded = true;
                    this.loadingState = LoadingState.Loaded;
                });
            },
            (err) => {
                if (err.response.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    if (this._onError) {
                        this._onError(errAction);
                    }
                };
            }
        );
    };

    @action restoreTeamMember = (
        id: string,
        password: string,
        confirmPassword: string,
        errAction: string
    ) => {
        this.loadingState = LoadingState.Loading;
        restoreTeamMember(id, password, confirmPassword).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return this.getAndSetTeam('getBE');
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { id, password, confirmPassword });
                    }
                });
            }
        );
    };

    @action resendInvitation = (
        id: string,
        errAction: string
    ) => {
        this.loadingState = LoadingState.Loading;
        resendInvitation(id).then(
            () => {
                runInAction(() => {
                    if (this._onSuccessReinvite) {
                        this._onSuccessReinvite();
                    }
                });
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { id });
                    }
                });
            }
        );
    };

    @action archiveTeamMember = (id: string, errAction: string) => {
        this.loadingState = LoadingState.Loading;
        archiveTeamMember(id).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return this.getAndSetTeam('getBE');
            },
            () => {
                runInAction(() => {
                    if (this._onError) {
                        this._onError(errAction, { id });
                    }
                });
            }
        );
    };

    @action addTeamMember = (newMember: NewMember, errAction: string) => {
        this.loadingState = LoadingState.Loading;
        return addTeamMember(newMember).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return true;
            },
            (err) => {
                runInAction(() => {
                    if (
                        err.response.data === 'An account already exists for this email address' &&
                        this._onErrorAlreadyUsedEmail
                    ) {
                        this._onErrorAlreadyUsedEmail(err.response.data);
                    } else if(err.response.status === 402) {
                        this._onErrorLimitHit();
                    } else if (this._onError) {
                        this._onError(errAction, { newMember });
                    }
                });
                return null;
            }
        );
    };

    @action updateMember = (
        employeeId: string,
        memberData: MemberData,
        changes: Changes,
        errAction: string
    ): Promise<any> => {
        this.loadingState = LoadingState.Loading;
        const { firstName, lastName, phone, email, emailConfirm, role } = memberData;

        const requestTypes = Object.keys(changes).filter((key) => changes[key]);
        const requestsToCall = requestTypes.map((requestType) => {
            switch (requestType) {
                case 'namePhone': {
                    return updateTeamMemberName(employeeId, firstName, lastName, phone);
                }
                case 'role': {
                    return updateTeamMemberRole(employeeId, Number(role));
                }
                case 'email': {
                    return updateTeamMemberEmail(employeeId, email, emailConfirm);
                }
            }
        });
        return Promise.all(requestsToCall).then(
            () => {
                runInAction(() => {
                    if (this._onSuccess) {
                        this._onSuccess();
                    }
                });
                return true;
            },
            (err) => {
                runInAction(() => {
                    if (
                        err.response.data === 'An account already exists for this email address' &&
                        this._onErrorAlreadyUsedEmail
                    ) {
                        this._onErrorAlreadyUsedEmail(err.response.data);
                    } else if (this._onError) {
                        this._onError(errAction, { id: employeeId, memberData, changes });
                    }
                });
                return null;
            }
        );
    };
}

export default new TeamStore();
