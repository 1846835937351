/* eslint-disable react/display-name */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { parseTemplate } from '@src/pages/Messages/components/Message/parse-template';
import { LoadingState, OnErrorDataTemplates } from '@src/stores/models';
import { onSuccess } from '@src/theme/utils/constants';
import { withTemplateStore, WithTemplateStoreProps } from '@src/stores/with-store';
import { toast } from 'react-toastify';
import { TemplatesForm } from './TemplatesForm';
import { Loader, Table, TableHeader, ErrorToastDownload, ErrorToastSave } from '@src/components';
import { images } from '@src/theme/images';
import { formatDate, formatTime } from '@src/theme/utils/helpers';

import './templates.sass';

export const TemplatesWithStore: React.FC<WithTemplateStoreProps> = (
    props: WithTemplateStoreProps
) => {
    const store = props.TemplateStore;
    const { templates } = store;
    const loading = store.loadingState;
    const { path, url } = useRouteMatch();
    const history = useHistory();
    const [isFirstLoading, setIsFirstLoading] = useState(true);

    store.onError = (type: string, data?: OnErrorDataTemplates) => {
        store.loadingState = LoadingState.Error;
        switch (type) {
            case 'getBE': {
                toast.error(<ErrorToastDownload getBEData={() => store.getBEData('getBE')} />);
                break;
            }
            case 'removeTemplate': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.removeTemplate(data.templateId, 'removeTemplate')
                        }
                    />
                );
                break;
            }
            default: {
                return;
            }
        }
    };
    store.onSuccess = () => {
        store.loadingState = LoadingState.Loaded;
        onSuccess();
    };

    const getBEData = () => {
        store.getBEData('getBE', isFirstLoading);
        setIsFirstLoading(false);
    };

    const removeTemplate = (id: string) => {
        store.removeTemplate(id, 'removeTemplate');
    };

    const HEADERS: TableHeader[] = [
        {
            title: 'Working title',
            headerCls: ['left'],
            cls: ['bold', 'left'],
            field: 'title',
            flexWidth: 5,
        },
        {
            title: 'Template',
            headerCls: ['left'],
            cls: ['left'],
            template: (header, data) => (
                <div dangerouslySetInnerHTML={{ __html: parseTemplate(data['message']) }} />
            ),
            flexWidth: 5,
        },
        {
            title: 'Attachment',
            cls: ['attachment'],
            template: (header, data) => (
                <div className="memberActive">
                    <img
                        src={
                            images[
                                data['attachments'].length > 0
                                    ? 'checkmarkSelected'
                                    : 'checkmarkUnselected'
                            ]
                        }
                        alt="checkmark"
                    />
                </div>
            ),
            flexWidth: 2,
        },
        {
            title: 'Updated at',
            headerCls: ['left'],
            cls: ['left'],
            template: (header, data) => {
                const baseDate = data['updated'];
                const date = baseDate ? `${formatDate(baseDate)}, ${formatTime(baseDate)}` : '';
                return <>{date}</>;
            },
            flexWidth: 3,
        },
        {
            title: 'Actions',
            template: (header, data) => (
                <div className="buttons">
                    <Link to={`${url}/edit-template/${data['id']}`} className="edit btn-primary">
                        Edit
                    </Link>
                    <button className="btn-secondary" onClick={() => removeTemplate(data['id'])}>
                        Remove
                    </button>
                </div>
            ),
            flexWidth: 4,
        },
    ];

    useEffect(() => {
        getBEData();
    }, [history.location]);

    useLayoutEffect(() => {
        store.init();
    }, []);

    return (
        <div className="Templates">
            <Loader loadingState={loading} />
            <Switch>
                <Route exact path={path}>
                    <>
                        {loading !== LoadingState.Init && loading !== LoadingState.FirstLoading && (
                            <>
                                <div className="TemplatesList">
                                    <div className="TemplatesList__header">
                                        <div className="left">
                                            <div className="title">Templates</div>
                                            <div className="subtitle">
                                                Create reusable messages to quickly respond to
                                                customers
                                            </div>
                                        </div>
                                        {store.dataLoaded && (
                                            <div className="right">
                                                <div className="add">
                                                    <Link
                                                        to={`${url}/add-template/`}
                                                        className="btn-primary"
                                                    >
                                                        Create New Template
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {store.dataLoaded && (
                                        <div className="TemplatesList__list">
                                            <Table
                                                key={templates.length}
                                                headers={HEADERS}
                                                data={templates}
                                                totalCount={templates.length}
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                </Route>
                <Route path={`${path}/edit-template/:templateId`}>
                    <TemplatesForm />
                </Route>
                <Route path={`${path}/add-template`}>
                    <TemplatesForm />
                </Route>
            </Switch>
        </div>
    );
};

export const Templates = withTemplateStore(TemplatesWithStore);
