import React, { useEffect, useState } from 'react';
import { Attachment } from '@src/stores/models';
import { AttachmentTile } from './AttachmentTile';
import { AttachmentFile } from './attachment-file';
import BigPicture from 'bigpicture';

import './attachments.sass';

interface AttachmentsProps {
    attachments: Array<AttachmentFile | Attachment>;
    isInTemplatesFormComponent?: boolean;
    onAttachmentsChange?: (attachments: Attachment) => void;
    onAttachmentRemove?: (attachments: string) => void;
}

export const Attachments: React.FC<AttachmentsProps> = (props) => {
    const [attachments, setAttachments] = useState<Array<AttachmentFile | Attachment>>([]);

    const handleAttachmentClick = (e: React.MouseEvent, attachmentId: string) => {
        const atts = attachments.map((att: Attachment) => ({src: att.url}));
        const position = attachments.findIndex((att: Attachment) => att.id === attachmentId);
        BigPicture({
            el: e.target,
            gallery: atts,
            position: position
        });
    };

    useEffect(() => {
        !props.isInTemplatesFormComponent
            ? setAttachments(props.attachments)
            : setAttachments(prevAttachments => [...prevAttachments, ...props.attachments]);
        if (props.attachments.length == 0) {
            setAttachments([]);
        };
    }, [props.attachments]);

    if (!attachments?.length) {
        return null;
    };

    const removeAttachment = (e: string) => {
        props.onAttachmentRemove && props.onAttachmentRemove(e);
        if (props.isInTemplatesFormComponent) {
            const idx = attachments.findIndex((x) => x.id === e);
            if (idx < 0) {
                return;
            }
            attachments.splice(idx, 1);
            setAttachments([...attachments]);
        };
    };

    return (
        <div className="Attachments">
            {attachments.map((att, i) => (
                <AttachmentTile
                    key={att.id + i}
                    onClick={(e) => handleAttachmentClick(e, att.id)}
                    onUploaded={props.onAttachmentsChange ? (e) =>  props.onAttachmentsChange(e): null}
                    onRemove={props.onAttachmentRemove ? (e) => removeAttachment(e) : null}
                    attachment={att}
                />
            ))}
        </div>
    );
};
