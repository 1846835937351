import React, { PropsWithChildren } from 'react';
import { ResponseTimeStats } from '@src/stores/models';
import { Metric } from '@src/components';
import DashboardCard from '../DashboardCard/DashboardCard';

import './MessagesStats.sass';

function getClazzName(value: number): object {
    if (value === undefined) return {};
    if (value <= 3600) {
        return { growth: true };
    } else if (value > 28800) {
        return { drop: true };
    } else return {};
};

function round(value: number, precision: number): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
};

function secondsToDisplayTime(seconds: number): [number, string] {
    if (seconds === undefined) {
        return [undefined, undefined];
    };

    if (seconds < 60) {
        return [seconds, 'seconds'];
    } else if (seconds < 3600) {
        return [round(seconds / 60, 1), 'minutes'];
    } else if (seconds < 86400) {
        return [round(seconds / 3600, 1), 'hours'];
    } else {
        return [round(seconds / 86400, 1), 'days'];
    };
};

interface ResponseTimeProps {
    period: string;
    stats: ResponseTimeStats;
}

export const ResponseTimes: React.FC<PropsWithChildren<ResponseTimeProps>> = ({
    period,
    stats,
}) => {

    const [employeeDisplayTime, employeeTimeDescription] = secondsToDisplayTime(
        stats?.employeeResponseTime
    );

    const [locationDisplayTime, locationTimeDescription] = secondsToDisplayTime(
        stats?.locationResponseTime
    );

    return (
        <DashboardCard className="KeyMetrics dashboard-card__flex ResponseTimes">
            <Metric
                titleRow1="Your"
                titleRow2="Responses"
                titleSizeRow1="medium"
                titleSizeRow2="medium"
                toShow={stats?.employeeResponses || 0}
            ></Metric>

            <Metric
                titleRow1="Your Avg"
                titleRow2="Response Time"
                titleSizeRow1="medium"
                titleSizeRow2="medium"
                toShow={employeeDisplayTime}
                clazzNames={getClazzName(stats?.employeeResponseTime)}
            >
                {employeeTimeDescription}
            </Metric>

            <Metric
                titleRow1="Total"
                titleRow2="Responses"
                titleSizeRow1="medium"
                titleSizeRow2="medium"
                toShow={stats?.locationResponses || 0}
            ></Metric>

            <Metric
                titleRow1="Total Avg"
                titleRow2="Response Time"
                titleSizeRow1="medium"
                titleSizeRow2="medium"
                toShow={locationDisplayTime}
                clazzNames={getClazzName(stats?.locationResponseTime)}
            >
                {locationTimeDescription}
            </Metric>
        </DashboardCard>
    );
};
