import { Attachment, Customer, Tag } from '../models';

export type BroadcastStatus = 'SCHEDULED' | 'RUNNING' | 'COMPLETED' | 'CANCELLED' | 'CREATE' | 'EDIT';

export const broadcastStatusLabels: {[key in BroadcastStatus]: string} = {
    'SCHEDULED': 'Scheduled',
    'RUNNING': 'In progress',
    'COMPLETED': 'Completed',
    'CANCELLED': 'Cancelled',
    'CREATE' : 'Create',
    'EDIT': 'Edit'
}

// TODO: check which fields are optional
export interface Broadcast {
    attachments: Attachment[];
    created: Date;
    delivered: number;
    employeeId: string;
    employeeFirstName: string;
    employeeLastName: string;
    errors: number;
    first20Customers: Customer[];
    first20Tags: Tag[];
    id: string;
    locationId: string;
    name?: string;
    scheduled: Date;
    status: BroadcastStatus;
    text?: string;
    totalRecipients: number;
    totalTags?: number;
    totalCustomers?: number;
    updated?: Date;
}

export type RecipientType = 'CUSTOMER' | 'TAG';

export interface RecipientMemberBase {
    targetType: RecipientType;
    broadcastListId: string;
    id: string;
    created: string;
    value: string;
}

export interface RecipientMemberTagData {
    value: string;
    id: string;
    customerCount: number;
}

export interface RecipientMemberCustomerData {
    value: string;
    id: string;
    optedOut: boolean;
    disallowReviews: boolean;
    phone: string;
}

export type RecipientMemberCustomer = RecipientMemberBase & {
    customerData: RecipientMemberCustomerData;
}

export type RecipientMemberTag = RecipientMemberBase & {
    tagData: RecipientMemberTagData;
}

export interface Recipients {
    members: Array<RecipientMemberCustomer | RecipientMemberTag>;
    total: number;
    totalMembers: number;
}

export interface AvailableRecipientsTagResponse {
    customerCount: number;
    id: string;
    value: string;
}

export interface AvailableRecipients {
    customers: Customer[];
    tags: AvailableRecipientsTagResponse[];
    total: number;
    totalMembers?: number;
}

export interface CreateBroadcastRecipients {
    customers: string[];
    tags: string[];
    phones: string[];
}

interface CommonBroadcastProps {
    attachments?: Attachment[];
    message: string;
    name: string;
    schedule: Date;
}

export type CreateBroadcast = CommonBroadcastProps & {
    recipients: CreateBroadcastRecipients;
}

export type EditBroadcast = CommonBroadcastProps & {
    id: string;
}
