import React from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { FormField } from '@src/components';
import { re } from '@src/theme/utils/constants';
import { EmailsInterface } from './models';

import './personal-info.sass';

interface EmailAddressesProps {
    id: string;
    email: string;
    updateEmail: (data: EmailsInterface) => void;
}

export const EmailAddresses: React.FC<EmailAddressesProps> = (props) => {
    const { register, handleSubmit, errors, watch, formState } = useForm();
    JSON.stringify(formState); // hack, without this can't get touched form-fields

    const updateEmail = (data: EmailsInterface) => {
        const touched = Object.keys(formState.touched);
        props.updateEmail({ email: data.email, confirmEmail: data.confirmEmail, touched });
    };

    const emailChanged = watch('email') ? watch('email') !== props.email : false;

    return (
        <form className="PersonalInfo__content" onSubmit={handleSubmit(updateEmail)}>
            <FormField label="Email" htmlFor="email" error={errors.email}>
                <input
                    type="text"
                    name="email"
                    className={classNames('form-input', { error: errors.email })}
                    defaultValue={props.email}
                    ref={register({
                        required: 'Provide email',
                        pattern: {
                            value: re,
                            message: 'Invalid email address',
                        },
                    })}
                />
            </FormField>
            <FormField label="Confirm email" htmlFor="confirmEmail" error={errors.confirmEmail}>
                <input
                    type="text"
                    name="confirmEmail"
                    className={classNames('form-input', { error: errors.confirmEmail })}
                    ref={register({
                        required: 'Confirm email address',
                        pattern: {
                            value: re,
                            message: 'Invalid email address',
                        },
                        validate: (value) =>
                            emailChanged && value !== watch('email')
                                ? 'Email addresses must be identical'
                                : undefined,
                    })}
                />
            </FormField>
            <button className="btn-primary">Update</button>
        </form>
    );
};
