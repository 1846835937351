import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { BroadcastStatus, broadcastStatusLabels } from '@src/stores/models/broadcast_new';
import './Menu.sass';

export interface MenuItem {
    label: string;
    items?: MenuItem[];
    statusValue?: BroadcastStatus | BroadcastStatus[];
}

const menuGroups: MenuItem[] = [
    {
        label: 'Broadcasts',
        items: [
            {
                label: broadcastStatusLabels['SCHEDULED'],
                statusValue: 'SCHEDULED'
            },
            {
                label: 'Sent',
                statusValue: ['RUNNING' , 'COMPLETED', 'CANCELLED'],
                items: [
                    {
                        label: broadcastStatusLabels['RUNNING'],
                        statusValue: 'RUNNING'
                    },
                    {
                        label: broadcastStatusLabels['COMPLETED'],
                        statusValue: 'COMPLETED'
                    },
                    {
                        label: broadcastStatusLabels['CANCELLED'],
                        statusValue: 'CANCELLED'
                    },
                ]
            }
        ]
    }
];

interface ItemProps {
    broadcastStatus?: BroadcastStatus;
    path: MenuItem[];
    item: MenuItem;
    onClick?: (path: MenuItem[]) => void;
};

const Item: React.FunctionComponent<ItemProps> = ({ broadcastStatus, item, path, onClick}) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event.stopPropagation()
        item.items && setExpanded(prevExpanded => !prevExpanded)
        onClick && onClick([...path, item])
    }

    const isSelected = (): boolean => {
        if (typeof item.statusValue === 'string') {
            return item.statusValue === broadcastStatus
        } else {
            return item.statusValue.includes(broadcastStatus)
        };
    };

    useEffect(() => {
        item.items && Array.isArray(item.statusValue) && !expanded && setExpanded(item.statusValue.includes(broadcastStatus));
    }, [broadcastStatus])

    return (
        <li
            className={classNames('menu-item', { indented: path.length > 1, selected: isSelected() })}
            onClick={handleClick}
        >
            {item.label}
            {item.items && expanded &&
                <ul>
                    {item.items.map((item, i) =>
                        <Item broadcastStatus={broadcastStatus} key={i} item={item} path={[...path, item]} onClick={onClick}/>
                    )}
                </ul>
            }
        </li>
    )
};

interface MenuProps {
    broadcastStatus?: BroadcastStatus;
    onItemClick: (item: BroadcastStatus) => void;
};

export const Menu: React.FC<MenuProps> = ({ onItemClick, broadcastStatus}) => {
    const handleClick = (path: MenuItem[]) => {
        onItemClick && typeof path[path.length -1]?.statusValue === 'string' && onItemClick(path[path.length -1]?.statusValue as BroadcastStatus);
    }

    return (
        <nav className="Menu LeftMenu">
            <div className="LeftMenu__division">
                <div className="LeftMenu__sections">
                        {menuGroups.map((group, i) =>
                            <div  key={i} className="LeftMenu__section">
                                <div className="LeftMenu__sectionTitle">
                                    <div className="title">{group.label}</div>
                                </div>
                                <ul>
                                    {group.items.map((item, i) => (
                                        <Item broadcastStatus={broadcastStatus} key={i} path={[item]} item={item} onClick={handleClick}/>
                                    ))}
                                </ul>
                            </div>
                        )}
                </div>
            </div>
        </nav>
    );
};
