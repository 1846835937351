import React from 'react';
import PhoneScreenPng from './phone_screen.png';

import DashboardCard from '../../DashboardCard/DashboardCard';
import './EmptyContactsCard.sass';

interface EmptyContactsCardProps {
    onClick: () => void
}

export const EmptyContactsCard: React.FunctionComponent<EmptyContactsCardProps> = ({ onClick }) => {
    return (
        <DashboardCard className="dashboard__contacts-card-empty">
            <div className="left">
                <img alt="first message" src={PhoneScreenPng}/>
            </div>
            <div className="right">
                <h3>Text Customers & New Leads</h3>
                <p>Customers are <span>4x</span> more likely to respond to a text message than call back to a voicemail.</p>
                <p>Add your contacts individually or as a group import.</p>
                <button
                    className="btn-primary-full"
                    type="button"
                    onClick={onClick}
                >
                    Add Your Contacts
                </button>
            </div>
        </DashboardCard>
    );
};
