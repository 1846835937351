import React, { useEffect } from 'react';
import { Dialog } from '@src/components';
import { NewContactOnlyAdd } from '@src/pages/Messages/components';

interface AddCustomerProps {
    openDialog: boolean;
    onCloseDialog: () => void;
    onSuccess?: () => void
}

export const AddCustomer: React.FC<AddCustomerProps> = (props) => {

    const handleDialogClose = () => {
        props.onCloseDialog();
    };

    useEffect(() => {
    }, [props.openDialog]);

    return (
            <Dialog open={props.openDialog} onClose={handleDialogClose}>
                <div className="AddCustomer">
                    <NewContactOnlyAdd
                        ownerPage='customers'
                        onCreateClick={handleDialogClose}
                        onSuccess={props.onSuccess}
                    />
                </div>
            </Dialog>
    );
};
