import React from 'react';
import {
    ReviewSource,
    ReviewRating,
    ReviewRecommendation,
    ReviewsPerSiteStats,
    LoadingState,
} from '@src/stores/models';
import { ProgressBar, Loader } from '@src/components';
import { images } from '@src/theme/icons/dashboard';

import '../../DashboardCard/DashboardCard.sass';
import './reviewsPerSite.sass';
import { ReviewsGoogle } from './ReviewsGoogle';
import { ReviewsFacebook } from './ReviewsFacebook';
import DashboardCard from '../../DashboardCard/DashboardCard';

export const noReviews = "You don't have any review in the chosen period";

const periodToPeriodLabel = (period: string): string => {
    const lowerCasePeriod = period.toLocaleLowerCase()
    if (lowerCasePeriod === 'all') return '';
    if (lowerCasePeriod === 'custom') return 'in the chosen period';
    return lowerCasePeriod
} 

interface ReviewsPerSiteProps {
    reviewsStats: ReviewsPerSiteStats;
    loading: LoadingState;
    period: string;
    sources: ReviewSource[];
    total: ReviewsPerSiteStats;
}

export const ReviewsPerSite: React.FC<ReviewsPerSiteProps> = ({
    period,
    sources,
    total,
    loading,
    reviewsStats,
}) => {
    const facebook = reviewsStats && reviewsStats[ReviewSource.FACEBOOK];
    const google = reviewsStats && reviewsStats[ReviewSource.GOOGLE];
    const otherKeys =
        reviewsStats &&
        Object.keys(reviewsStats).filter(
            (site) => site != ReviewSource.FACEBOOK && site != ReviewSource.GOOGLE
        );
    const others = reviewsStats &&
        otherKeys.length > 0 && {
            total: otherKeys.reduce((sum, key) => sum + reviewsStats[key].total, 0),
        };

    const reviewsMetrics = [
        {
            value: google?.total || 0,
            name: ReviewSource.GOOGLE,
        },
        {
            value: facebook?.total || 0,
            name: ReviewSource.FACEBOOK,
        },
        {
            value: others?.total,
            name: 'OTHERS',
        },
    ];

    const reviewsByCompany = {
        [ReviewSource.GOOGLE]: {
            isLinked: sources?.find((source) => source === ReviewSource.GOOGLE),
            data: {
                period: periodToPeriodLabel(period),
                totalNumber: (total && total[ReviewSource.GOOGLE]?.total) || 0,
                currNumber: google?.total || 0,
                avgRate: google?.rating,
                stars: [
                    0,
                    google?.stats.find((g) => g.rating == ReviewRating.ONE)?.count || 0,
                    google?.stats.find((g) => g.rating == ReviewRating.TWO)?.count || 0,
                    google?.stats.find((g) => g.rating == ReviewRating.THREE)?.count || 0,
                    google?.stats.find((g) => g.rating == ReviewRating.FOUR)?.count || 0,
                    google?.stats.find((g) => g.rating == ReviewRating.FIVE)?.count || 0,
                ],
            }
        },
        [ReviewSource.FACEBOOK]: {
            isLinked: sources?.find((source) => source === ReviewSource.FACEBOOK),
            data: {
                period: periodToPeriodLabel(period),
                totalNumber: (total && total[ReviewSource.FACEBOOK]?.total) || 0,
                currNumber: facebook?.total || 0,
                recommends:
                    facebook?.stats.find(
                        (f) => f.recommendation_type === ReviewRecommendation.RECOMMENDS
                    )?.count || 0,
                doNotRecommends:
                    facebook?.stats.find(
                        (f) => f.recommendation_type === ReviewRecommendation.NOT_RECOMMENDS
                    )?.count || 0,
                avgRecommendation: facebook?.rating,
            }
        },
    };

    const isReviews = (reviewsByCompany[ReviewSource.GOOGLE].isLinked && reviewsByCompany[ReviewSource.GOOGLE].data.totalNumber > 0) || 
        (reviewsByCompany[ReviewSource.FACEBOOK].isLinked && reviewsByCompany[ReviewSource.FACEBOOK].data.totalNumber > 0) || false;

    const renderData = sources?.length > 0 && isReviews &&
        <>
            <div className="ReviewsPerSite__bar">
                <ProgressBar data={reviewsMetrics} />
            </div>
            <div className="ReviewsPerSite__comparison data">
                {reviewsByCompany[ReviewSource.GOOGLE].isLinked && <ReviewsGoogle data={reviewsByCompany[ReviewSource.GOOGLE].data}/>}
                {reviewsByCompany[ReviewSource.FACEBOOK].isLinked && <ReviewsFacebook data={reviewsByCompany[ReviewSource.FACEBOOK].data}/>}
            </div>
        </>;

    const renderOnLoading = <>
        <div className="ReviewsPerSite__bar">
            <ProgressBar data={reviewsMetrics} />
        </div>
        <div className="ReviewsPerSite__comparison">
            <ReviewsFacebook data={reviewsByCompany[ReviewSource.FACEBOOK].data}/>
        </div>
    </>;
    
    const renderNoReviews = 
        <div className="ReviewsPerSite__no-reviews">
            <h2>{noReviews}</h2>
        </div>;

    const renderReviews = (loading !== LoadingState.Loaded && renderOnLoading) || renderData || renderNoReviews;

    return (
        <DashboardCard className="ReviewsPerSite">
            <Loader loadingState={loading} />
            <>
                <div className="dashboard-card__title">
                    <img src={images.perSiteB} alt="per-site-b" className="card-icon" />
                    <span>Reviews per site</span>
                </div>
                {renderReviews}
            </>
        </DashboardCard>
    );
};
