import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { ActivePlan } from '@src/stores/models/billing';
import { getProperPriceObj } from '@src/theme/utils/helpers';
import PlanMobile from './PlanMobile';
import PlanActionBar from './PlanActionBar';
import { images } from '@src/theme/images';

import './PlansListMobile.sass';


interface PlansListMobileProps {
    isSignup: boolean;
    plansArr: ActivePlan[];
    interval: 'month' | 'year';
    onCancelTrial: (v) => void;
    onPlanChange: (v) => void;
    changePlanInApp: (v) => void;
    BillingStore;
    featuresList
}

const PlansListMobile: React.FunctionComponent<PlansListMobileProps> = ({ plansArr, isSignup, interval, onCancelTrial, onPlanChange, changePlanInApp, BillingStore, featuresList }) => {
    const carouselContainerRef = useRef(null);
    const basicInfoRowRef = useRef(null);
    const planBasicInfoRef = useRef(null);
    const [highlightedPlanIdx, setHighlightedPlanIdx] = useState<number>(0);
    const [moveWidth, setMoveWidth] = useState<number>(planBasicInfoRef.current?.offsetWidth + 16);

    useEffect(() => {
        setMoveWidth(planBasicInfoRef.current?.offsetWidth + 16);
    }, [planBasicInfoRef.current?.offsetWidth]);

    const planClicked = (idx: number) => {
        if (idx === highlightedPlanIdx) {
            return;
        } else {
            setHighlightedPlanIdx(idx);
            basicInfoRowRef.current.style.transform = `translateX(-${idx * moveWidth}px)`;
        };
    };

    const actionBar = (plan: ActivePlan, isHighlighted: boolean) => {
        if (plan && plan.billingPlan && interval) {
            return <PlanActionBar
                isContactSales={getProperPriceObj(plan.billingPlan, interval)?.contactSales}
                trialDays={plan.billingPlan.billingPlan.trialDays}
                currentPriceId={BillingStore.currentPlan?.price.id}
                priceId={getProperPriceObj(plan.billingPlan, interval)?.id}
                isSignup={isSignup}
                noCC={!BillingStore.billingAccount?.card?.brand}
                onCancelTrial={() => isHighlighted && onCancelTrial(getProperPriceObj(plan.billingPlan, interval)?.id)}
                onBuyNowClick={() => isHighlighted && onPlanChange({priceId: getProperPriceObj(plan.billingPlan, interval)?.id, buyNow: true})}
                onTryFreeClick={() => isHighlighted && onPlanChange({priceId: getProperPriceObj(plan.billingPlan, interval)?.id, buyNow: false})}
                onSelectClick={() => isHighlighted && changePlanInApp(getProperPriceObj(plan.billingPlan, interval)?.id)}
            />
        };
    };

    return (
        <div
            className="PlansListMobile"
            ref={carouselContainerRef}
        >
            <div className="PlansListMobile__inner">
                <div className="PlansListMobile__basicInfo" ref={basicInfoRowRef}>
                    {plansArr.map((plan, idx) =>
                        <div
                            className={classNames('PlansListMobile__basicInfo-item', { highlightedPlan: idx === highlightedPlanIdx })}
                            ref={planBasicInfoRef}
                            onClick={() => planClicked(idx)}
                            key={plan.billingPlan.billingPlan.id}
                        >
                            <PlanMobile
                                activePlan={plan}
                                actionBar={actionBar(plan, idx === highlightedPlanIdx)}
                                interval={interval}
                            />
                        </div>
                    )}
                </div>
                <div className="PlansListMobile__nav">
                    {plansArr.map((plan, idx) => {
                        return idx === highlightedPlanIdx ?
                            <div className="item dash" key={plan.billingPlan.billingPlan.id + idx} /> :
                            <div className="item dot" key={plan.billingPlan.billingPlan.id + idx} onClick={() => planClicked(idx)} />
                    })}
                </div>
                <div className="PlansListMobile__features">
                    <div className="PricingPlan__ticks">
                        {featuresList && featuresList
                        .map(f => {
                            return {
                                id: f.id,
                                flag: plansArr[highlightedPlanIdx].components.some(c => c.id === f.id),
                                hasSubtext: Boolean(f.subtext)
                            };
                        })
                        .map(f => {
                            return (
                                <div className='PricingPlan__ticks-item' key={f.id}>
                                    {(f.id === 'msg' && plansArr[highlightedPlanIdx].billingPlan.billingPlan.messagesPerMonth) ?
                                        <div className="messagesPerMonth">{plansArr[highlightedPlanIdx].billingPlan.billingPlan.messagesPerMonth}</div> :
                                        f.flag ?
                                        <img src={images.checkGreenFull} alt="checkmark" /> :
                                        <div className="empty" />
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlansListMobile;
