import { action, computed, observable, runInAction } from 'mobx';
import { CustomersApi } from '@src/requests';
import { BaseStore } from '@src/stores/base.store';
import { CustomerColumnConfirm, CustomerImport, LoadingState } from '@src/stores/models';

export class ImportCustomersStore extends BaseStore {
    @observable private _loadingState = LoadingState.Init;
    @observable importUploadData: CustomerImport = null;

    @computed get uploadState(): LoadingState {
        return this._loadingState;
    }

    @action init(): void {
        this._loadingState = LoadingState.Init;
        this.importUploadData = null;
    }

    @action clearUploadData(): void {
        this.importUploadData = null;
    }

    @action uploadCustomers = (
        file: File,
        onSuccess: () => void,
        onError?: (message) => void
    ): Promise<void> => {
        this._loadingState = LoadingState.Loading;
        return CustomersApi.uploadFileProposal(file).then(
            (res) => {
                runInAction(() => {
                    this.importUploadData = res;
                    if (this.importUploadData?.columns && onSuccess) {
                        onSuccess();
                    }
                    this._loadingState = LoadingState.Init;
                });
            },
            (err) => {
                if (onError) {
                    onError(err.message);
                }
                runInAction(() => {
                    this._loadingState = LoadingState.Error;
                });
            }
        );
    };

    @action uploadCustomersColumns = (
        fileKey: string,
        data: CustomerColumnConfirm[],
        includesHeader: boolean,
        onSuccess?: () => void,
        onError?: (message) => void
    ): Promise<void> => {
        this._loadingState = LoadingState.Loading;
        return CustomersApi.confirmFileUpload(fileKey, data, includesHeader).then(
            (data) => {
                runInAction(() => {
                    if (onSuccess) {
                        onSuccess();
                    }
                    this._loadingState = LoadingState.Loaded;
                    return data;
                });
                return data;
            },
            (err) => {
                if (onError) {
                    onError(err.message);
                }
                runInAction(() => {
                    this._loadingState = LoadingState.Error;
                });
            }
        );
    };

    @action correctCustomersColumns = (
        fileKey: string,
        data: CustomerColumnConfirm[],
        onSuccess: (msg: string) => void,
        onError?: (message) => void
    ): Promise<void> => {
        this._loadingState = LoadingState.Loading;
        return CustomersApi.correctDataOfImportedCustomers(fileKey, data).then(
            (data) => {
                runInAction(() => {
                    if (onSuccess) {
                        onSuccess(data.info);
                    }
                    this._loadingState = LoadingState.Loaded;
                    return data;
                });
                return data;
            },
            (err) => {
                if (onError) {
                    onError(err.message);
                }
                runInAction(() => {
                    this._loadingState = LoadingState.Error;
                });
            }
        );
    };
}

export default new ImportCustomersStore();
