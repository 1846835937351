import React from 'react';
import './DetailsCard.sass';
import './NewBroadcastDetailsCard.sass';

export interface CardData {
    label: string;
    tags: string[];
    recipients: number;
    members: string[];
    message: string;
}

interface CardProps {
    onCreateClick: () => void;
}

const EmptyDetailsCard: React.FunctionComponent<CardProps> = ({ onCreateClick }) => {
    return (
        <div className="Broadcasts-details-card new-broadcast-details-card">
            <h3>Send a new broadcast</h3>
            <button className="btn-primary" type="button" onClick={onCreateClick}>New Broadcast</button>
        </div>
    );
};

export default EmptyDetailsCard;
