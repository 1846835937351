import { action, observable, runInAction, computed } from 'mobx';
import {
    getAuth,
    createAccount,
    updateAccount,
    getUser,
    setPhoneNumber,
    finalize,
    getPlans,
    validateEmail,
    setBillingForm,
    setAndBuyPhoneNumber,
    isPhoneNumberSet,
    getNumbers,
    googleCreateAccount,
    postAccountDetailsWithGoogle,
    smsVerification,
} from '@src/requests';
import { LoadingState } from '@src/stores/models';
import { ActivePlan } from '@src/stores/models';
import UserStore from '@src/stores/user.store';

declare const window: any;

export type ValidateEmailResponse = 'success' | 'accountAlreadyExists' | 'fail';

export type GoogleAuthorization = 'try' | 'success' | 'fail' | 'requestCompleteSignup' | 'accountAlreadyCreated';

export const isGoogleAuthorization = (auth: GoogleAuthorization): boolean => (['success', 'requestCompleteSignup'].includes(auth));

export class SignupStore {
    @observable user = null;
    @observable plans: ActivePlan[] = null;
    @observable companyInfo = null;
    @observable numbersToSelect = [];
    @observable isPhoneSet: boolean = null;
    @observable googleAuthorization: GoogleAuthorization = 'try';
    @observable loadingState: LoadingState = LoadingState.Init;
    private _onError: (type?: string, message?: string) => void;
    private _onSuccess: (type?: string) => void;
    @observable isReactPixelInitialized: boolean = false;

    @computed get isGoogleAuthorization(): boolean {
        return ['success', 'requestCompleteSignup'].includes(this.googleAuthorization);
    };

    @action destroy() {
        this.user = null;
        this.plans = null;
        this.companyInfo = null;
        this.numbersToSelect = [];
        this.isPhoneSet = null;
        this.googleAuthorization = 'try';
        this.loadingState = LoadingState.Init;
    }

    set onSuccess(fn: (type?: string) => void) {
        this._onSuccess = fn;
    }
    set onError(fn: (type?: string, message?: string) => void) {
        this._onError = fn;
    }

    @action setIsPhoneSet = (val: boolean): void => {
        this.isPhoneSet = val;
    };

    @action setIsReactPixelInitialized = (val: boolean): void => {
        this.isReactPixelInitialized = val;
    };

    @action setLoadingState = (val: LoadingState): void => {
        this.loadingState = val;
    };

    @action getUser = () => {
        this.loadingState = LoadingState.Loading;
        return getUser()
        .then((res) => {
            runInAction(() => {
                if (res.data.employeeFromExternalAuth) {
                    this.googleAuthorization = 'success';
                };
                UserStore.setSignupUser(res.data);
                this.user = res.data;
                this.loadingState = LoadingState.Loaded;
            });
            return 'ok';
        }, () => {
            runInAction(() => {
                this.loadingState = LoadingState.Error;
            });
            return false;
        });
    };

    @action googleAuthorize = () => {
        this.loadingState = LoadingState.Loading;
        this.googleAuthorization = 'try';
        if (window._env_.GOOGLE_CLIENT_ID) {
            window.gapi.auth2.authorize(
                {
                    client_id: window._env_.GOOGLE_CLIENT_ID,
                    scope: 'email profile',
                    response_type: 'code',
                    include_granted_scopes: true,
                    access_type: 'offline',
                    prompt: 'consent',
                },
                (callback) => {
                    console.log(JSON.stringify(callback));
                    if (!callback.error) {
                        googleCreateAccount(callback.code).then(
                            (res) => {
                                runInAction(() => {
                                    if (res.data.account.status === 'INCOMPLETE') {
                                        this.googleAuthorization = 'requestCompleteSignup';
                                        this.user = res.data;
                                    } else if (res.data.account.status === 'ACTIVE') {
                                        this.googleAuthorization = 'accountAlreadyCreated';
                                    } else {
                                        this.googleAuthorization = 'success';
                                        this.user = res.data;
                                    };
                                    this.loadingState = LoadingState.Loaded;
                                    setTimeout(() => this.googleAuthorization = 'try', 1000)
                                });
                            },
                            (err) => {
                                runInAction(() => {
                                    if (err.response.status === 404) {
                                        this.googleAuthorization = 'requestCompleteSignup';
                                    } else {
                                        this.googleAuthorization = 'fail';
                                    };
                                    this.loadingState = LoadingState.Error;
                                    setTimeout(() => this.googleAuthorization = 'try', 1000)
                                });
                            }
                        );
                    } else {
                        runInAction(() => {
                            if (callback.error === 'popup_closed_by_user') {
                                this.loadingState = LoadingState.Loaded;
                                return;
                            };
                            this.googleAuthorization = 'fail';
                            this.loadingState = LoadingState.Error;
                            setTimeout(() => this.googleAuthorization = 'try', 1000)
                        });
                    }
                }
            );
        } else {
            runInAction(() => {
                this.googleAuthorization = 'fail';
                this.loadingState = LoadingState.Error;
                setTimeout(() => this.googleAuthorization = 'try', 1000)
            });
        };
    };

    @action postAccountDetailsWithGoogle = (data) => {
        this.loadingState = LoadingState.Loading;
        return postAccountDetailsWithGoogle(data).then(
            () => {
                runInAction(() => {
                    this.loadingState = LoadingState.Loaded;
                });
                return this.getUser();
            },
            () => {
                runInAction(() => {
                    this.loadingState = LoadingState.Error;
                });
                this._onError();
                return false;
            }
        )
    };

    @action createOrUpdateAccount = (data, isUpdating: boolean) => {
        this.loadingState = LoadingState.Loading;
        const req = isUpdating ? updateAccount(data) : createAccount(data);

        return req.then(
            () => {
                runInAction(() => {
                    this.loadingState = LoadingState.Loaded;
                });
                return this.getUser();
            },
            ({ response }) => {
                runInAction(() => {
                    this.loadingState = LoadingState.Error;
                });
                if (response && response.data && response.status == 400 && this._onError) {
                    this._onError(undefined, response.data);
                    return 'emailAlreadyExists';
                } else if (this._onError) {
                    this._onError();
                    return false;
                };
            }
        );
    };

    @action initVerification = (to: string): Promise<any> => {
        this.loadingState = LoadingState.Loading;
        return smsVerification('POST', {to})
            .finally(() => {
                this.loadingState = LoadingState.Loaded;
            })
    }

    @action makeVerification = (to: string, code: string): Promise<any> => {
        this.loadingState = LoadingState.Loading;
        return smsVerification('GET', {to, code})
            .finally(() => {
                this.loadingState = LoadingState.Loaded;
            })
    }

    @action getPlans = () => {
        this.loadingState = LoadingState.Loading;
        return getPlans()
        .then((res) => {
            runInAction(() => {
                this.plans = res.data
                .map((el: ActivePlan) => {
                    return {
                        ...el,
                        billingPlan: {
                            billingPlan: el.billingPlan.billingPlan,
                            prices: el.billingPlan.prices.filter(pr => !pr.contactSales)}
                    };
                })
                .filter((el: ActivePlan) => el.billingPlan.prices.length);

                this.loadingState = LoadingState.Loaded;
            });
        }, () => {
            runInAction(() => {
                this.loadingState = LoadingState.Error;
            });
            this._onError && this._onError();
        });
    };

    @action validateEmail = (email: string, paramsAsObject?: object): Promise<ValidateEmailResponse> => {
        this.loadingState = LoadingState.Loading;
        return validateEmail(email, paramsAsObject)
        .then(() => {
            runInAction(() => {
                this.loadingState = LoadingState.Loaded;
            });
            return 'success';
        }, ({ response }) => {
            runInAction(() => {
                this.loadingState = LoadingState.Error;
            });
            if (response && response.data && response.data === 'An account already exists for this email address' && response.status == 400) {
                return 'accountAlreadyExists';
            } else {
                this._onError();
                return 'fail';
            };
        });
    };

    @action finalize = (data) => {
        this.loadingState = LoadingState.Loading;
        return finalize(data)
        .then(() => {
            runInAction(() => {
                this.loadingState = LoadingState.Loaded;
            });
            return true;
        }, () => {
            runInAction(() => {
                this.loadingState = LoadingState.Error;
            });
            if (this._onError) this._onError();
            return false;
        });
    };

    @action setBillingForm = (billingData, finalizeData) => {
        this.loadingState = LoadingState.Loading;
        return setBillingForm(billingData)
        .then(() => {
            runInAction(() => {
                this.loadingState = LoadingState.Loaded;
            });
            return this.finalize(finalizeData);
        }, () => {
            runInAction(() => {
                this.loadingState = LoadingState.Error;
            });
            this._onError();
            return false;
        });
    };

    @action setPhoneNumber = (data) => {
        this.loadingState = LoadingState.Loading;
        return setPhoneNumber(data).then(
            () => {
                runInAction(() => {
                    this.loadingState = LoadingState.Loaded;
                });
                // return this.getUser();
                return true;
            },
            ({ response }) => {
                runInAction(() => {
                    this.loadingState = LoadingState.Error;
                });
                if (response && response.data && response.status == 400) {
                    if (this._onError) {
                        this._onError(undefined, response.data);
                    };
                } else {
                    this._onError();
                };
                return false;
            });
    };

    @action buyPhoneNumber = (phoneNumber, errSuccAction: string) => {
        this.loadingState = LoadingState.Loading;
        return getAuth().then(
            () => {
                return setAndBuyPhoneNumber(phoneNumber).then(
                    () => {
                        runInAction(() => {
                            this.isPhoneSet = true;
                            this.loadingState = LoadingState.Loaded;
                        });
                        if (this._onSuccess) {
                            this._onSuccess(errSuccAction);
                        };
                        return true;
                    },
                    () => {
                        runInAction(() => {
                            this.loadingState = LoadingState.Error;
                        });
                        if (this._onError) {
                            this._onError(errSuccAction);
                        };
                        return false;
                    }
                )
            },
            () => {
                runInAction(() => {
                    this.loadingState = LoadingState.Error;
                });
                if (this._onError) {
                    this._onError(errSuccAction);
                };
                return false;
            }
        );
    };

    @action getNumbers = (codes) => {
        this.numbersToSelect = [];
        return getNumbers(codes)
            .then(({ data }) => {
                runInAction(() => {
                    this.numbersToSelect = data.phoneNumbers;
                });
            }).catch(() => {
                runInAction(() => {
                    this.loadingState = LoadingState.Error;
                });
                if (this._onError) {
                    this._onError('Error while loading phone numbers');
                }
            });
    };

    @action isPhoneNumberSet = () => {
        return isPhoneNumberSet().then((isPhoneSet) => {
            runInAction(() => {
                this.isPhoneSet = isPhoneSet;
            });
        });
    };
}
export default new SignupStore();
