import React from 'react';

import './Signup.sass';
import './GridLayout.sass';

interface GridLayoutProps {
  rightAsideContent?: React.ReactNode;
}

export const GridLayout: React.FunctionComponent<GridLayoutProps> = ({
    children,
    rightAsideContent
}) => {
    return (
        <div className="signup-grid-layout">
            <div className="main">
                {children}
            </div>
            <aside className="right">
                {rightAsideContent}
            </aside>
        </div>
    )
};
