import React, { useState } from 'react';
import { ReactComponent as BroadcastSvg } from '@src/theme/icons/broadcast.svg';
import classNames from 'classnames';
import './DetailsCard.sass';
import { displayNameOrPhone, formatDate, formatTime } from '@src/theme/utils/helpers';
import { Broadcast } from '@src/stores/models/broadcast_new';
import Bar from './RecipientBar';
import { ReactComponent as TagSvg } from '@src/theme/icons/tag-outlined-grey.svg';
import { ReactComponent as PeopleSvg } from '@src/theme/icons/people.svg';
import { broadcastToLabel } from '../helpers/broadcast';

interface CardHeaderProps {
    broadcast: Broadcast;
}

const CardHeader: React.FunctionComponent<CardHeaderProps> = ({ broadcast }) => {
    const [showSlider, setShowSlider] = useState<boolean>(false)

    return(
        <div className="Broadcasts-details-card__card-header">
            <div className="Broadcasts-details-card__title-bar">
                <div className="title">
                    <BroadcastSvg className="svg"/>
                    <h4>{broadcastToLabel(broadcast)}</h4>
                </div>
                <div className="dropdown-arrow-container">
                    <div className={classNames('dropdown-arrow', { rotate180: showSlider })} role="button" onClick={() => setShowSlider(prevShow => !prevShow)} />
                </div>
            </div>
            <div className={`Broadcasts-details-card__slider ${showSlider ? 'expanded' : ''}`}>
                <div className="Broadcasts-details-card__bars-container">
                    <Bar
                        icon={<TagSvg viewBox="0 0 12 12"/>}
                        items={broadcast.first20Tags && broadcast.first20Tags.map(tag => tag.value)}
                        label="Tags"
                    />
                    <Bar
                        icon={<PeopleSvg/>}
                        items={broadcast.first20Customers && broadcast.first20Customers.map(customer => displayNameOrPhone(customer))}
                        label="Members"
                    />
                </div>
            </div>
        </div>
    );
};

export default CardHeader;
