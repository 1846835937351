import moment from 'moment';
import { dateFormat } from './constants';
import { AsYouType } from 'libphonenumber-js';
import { ActivePlan, BillingPlanDetails, BillingPlanOnList, Price } from '@src/stores/models';

// takes phone number as 1 222 333 4444 or 12223334444 and converts to (222) 333-4444 (or leaves untouched if shorter/longer)
export const transformPhoneNumber = (number: string) => {
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    const isLongEnough = cleaned.length === 10 || cleaned.length === 11;
    // const fixed = isLongEnough && match && ['+1 ', '(', match[2], ') ', match[3], '-', match[4]].join('').slice(noCountryPrefix ? 3 : 0)
    const fixed = isLongEnough && match && ['(', match[2], ') ', match[3], '-', match[4]].join('');
    return fixed || number;
};

// chech if phone has 11 or 10 digits
export const checkPhoneNumber = (number: string) => {
    const cleaned = number.replace(/\D/g, ''); // \D == non-digit chars
    // what if my phone number is 111 222 3333 ?
    return Boolean(parseInt(cleaned.split('')[0], 10) == 1)
        ? Boolean(cleaned.match(/^[0-9]{11}$/))
        : Boolean(cleaned.match(/^[0-9]{10}$/));
};

export const convertToPureNumber = (number: string) => {
    if (!number?.length) {
        return;
    }
    let newPhone = number;
    if (newPhone.slice(0, 3) === '+1 ') {
        newPhone = newPhone.slice(3).replace(/\D/g, '');
    }
    if (newPhone.slice(0, 2) === '1 ') {
        newPhone = newPhone.slice(2).replace(/\D/g, '');
    }
    if (newPhone.slice(0, 1) === '1') {
        newPhone = newPhone.slice(1).replace(/\D/g, '');
    }
    newPhone = ('' + newPhone).replace(/\D/g, '');
    return newPhone;
};

export const formatDate = (date: Date): string => {
    return date ? moment(date).format(dateFormat) : '';
};

export const formatTime = (date: Date, timeFormat = 'HH:mm'): string => {
    return date ? moment(date).format(timeFormat) : '';
};

export const calcAverage = (values: string[]): number => {
    const sum = values.reduce((acc, curr) => acc + Number(curr), 0);
    const emptyArray = values.length === 0;
    return emptyArray ? null : sum / values.length;
};

export const getLast = <T>(number: number, array: T[]): T[] => {
    return array.filter((r, i) => i < number);
};

export const toUpperCaseFirst = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isEmail = (email: string) => {
    return !email
        ? true
        : email.match(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
};

export const isIE11 = !!window.MSInputMethodContext && !!(document as any).documentMode;

export const displayNameOrPhone = (item) => {
    if (!item) {
        return;
    }

    if (!!item.firstName && !!!item.lastName) {
        return `${item.firstName}`;
    }
    if (!!!item.firstName && !!item.lastName) {
        return `${item.lastName}`;
    }
    if (!!item.firstName && !!item.lastName) {
        return `${item.firstName} ${item.lastName}`;
    }
    if (!!item.name) {
        return `${item.name}`;
    }
    return new AsYouType('US').input(('' + item?.phone).replace(/\D/g, ''));
};

export const getProperPriceObj = (arr, int) => {
    return arr.prices.find(el => el.interval.interval === int)
}

export const displayIntegerOrTwoDecimalPlaces = (price: number) => {
    if (price === 0) {
        return 0;
    };
    if (!price) {
        return;
    };
    return Number.isInteger(price) ? price : price.toFixed(2);
};

export const getBillingPlanAndPriceByPriceId = (arr: ActivePlan[], priceId: string): null | {billingPlan: BillingPlanDetails, price: Price} => {
    if (!arr) {
        return null;
    }
    let price;
    const plan = arr.map(pl => pl.billingPlan).find(pl => {
        return pl.prices.find(pr => {
            if (pr.id === priceId) {
                price = pr;
                return true;
            }
        });
    });
    return plan ? {billingPlan: plan?.billingPlan, price} : null;
};

/**
 *      return Price with default flag set to true.
 *      or
 *      return first with "default" in case of many
 *      or
 *      return first on list in case of none
 */
export const getDefaultPricePlan = (arr: BillingPlanOnList[]): Price => {
    let result;
    arr.some((plan: BillingPlanOnList, index: number) => {
        if (result) {
            return true;
        }
        plan.prices.some((price: Price) => {
            if (price.default) {
                result = price
                return true;
            }
        })
    })
    if (!result) {
        result = arr[0].prices.find(el => el.interval.interval === 'month') || arr[0].prices[0];
    }
    return result
};
