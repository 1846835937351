import { BaseStore } from '@src/stores/base.store';
import { action, computed, observable, runInAction } from 'mobx';
import api from '@src/api/api';
import { Connection, Device } from 'twilio-client';

export enum CallStatus {
    ready = 'Ready',
    calling = 'Calling...',
    incoming = 'Incoming call...',
    error = 'Error',
}

export interface TwilioError {
    code: number;
    message: string;
}

export class TwilioPhoneStore extends BaseStore {
    private device: Device;
    private timer;
    private ready = false;

    private pickedUp = false;

    @observable private _status: CallStatus | string;
    @observable private startTime: number;
    @observable canHangUp = false;
    @observable canCall = false;
    @observable showCallWindow = false;
    @observable phoneNumber: number;
    @observable callTime: string;
    onError?: (msg: TwilioError) => void;
    onNewIncomingCallWhileOngoingCall?: (phone: string) => void;

    @computed get statusText(): CallStatus | string {
        return this._status;
    }

    @action init(): void {
        this.reset();
        this.initDevice();
        (window as any).TwilioPhonestore = this;
    }

    @action closeConnection(): void {
        if (this.device) this.device.destroy();
    }

    @action reset(): void {
        if (this.device) {
            this.device.disconnectAll();
        }
        clearInterval(this.timer);
        this.pickedUp = false;
        this.startTime = 0;
        this.callTime = '00:00';
        this.ready = false;
        this.showCallWindow = false;
        this.phoneNumber = null;
    }

    @action call(phone: number, employeeId: string): void {
        if (!phone || !this.device || this.phoneNumber) {
            return;
        }
        this.phoneNumber = phone;
        this.showCallWindow = true;
        this.setCallButtons(false, true);
        this._status = CallStatus.calling;
        this.device.connect({ phoneNumber: phone.toString(), employeeId: employeeId });
    }

    @action sendDigits = (digit: string): void => {
        this.device.activeConnection().sendDigits(digit);
    };

    @action pickup(): void {
        this.pickedUp = true;
        this.device.activeConnection().accept();
    }

    @action hangUp(): void {
        const isOpen = this.device.activeConnection().status() === 'open';
        if (isOpen) {
            this.device.activeConnection().reject();
        } else {
            this.device.activeConnection().ignore();
        }
        this.reset();
    }

    private initDevice(): void {
        const pathname = window.location.pathname;
        api.post('/token/generate', { page: pathname })
            .then((res) => {
                // TODO: Remove debug flag on prod launch
                this.device = new Device(res?.data.token, { debug: false });
                this.device.on('cancel', (connection: Connection) => this.cancel(connection));
                this.device.on('connect', () => this.onConnection());
                this.device.on('ready', () => this.deviceReady());
                this.device.on('disconnect', () => this.disconnect());
                this.device.on('error', (error: TwilioError) => this.twilioError(error));
                this.device.on('incoming', (connection: Connection) => this.incoming(connection));
            })
            .catch((err) => {
                if (!pathname.includes('signin')) {
                    console.log('Twilio token generation error', err);
                }
                this.device && this.device.destroy();
            });
    }

    @action
    private cancel(connection: Connection): void {
        const iPickedUp = this.pickedUp;
        const isThisCurrentConnection = connection?.parameters?.From === this.phoneNumber;
        if (!iPickedUp && isThisCurrentConnection) {
            this._status = CallStatus.ready;
            this.reset();
            this.setCallButtons(false, false);
        }
    }

    @action
    private deviceReady(): void {
        this.ready = true;
        this._status = CallStatus.ready;
    }

    @action
    private disconnect(): void {
        this.setCallButtons(false, false);
        this.reset();
    }

    @action
    private incoming(connection: Connection): void {
        if (!this.phoneNumber) {
            this.phoneNumber = connection?.parameters?.From;
            this._status = CallStatus.incoming;
            this.setCallButtons(true, true);
            this.showCallWindow = true;
        } else if (this.phoneNumber !== connection?.parameters?.From) {
            this.newIncomingWhileOngoingCall(connection?.parameters?.From);
            connection.ignore();
        }
    }

    @action
    private newIncomingWhileOngoingCall(phone: string): void {
        if (this.onNewIncomingCallWhileOngoingCall) {
            this.onNewIncomingCallWhileOngoingCall(phone);
        }
    }

    @action
    private twilioError(error: TwilioError): void {
        console.error(error);
        if (this.onError) {
            this.onError(error);
        }
        this._status = CallStatus.error;
        this.reset();
    }

    @action
    private startTimer(): void {
        this.startTime = Math.floor(Date.now() / 1000);
    }

    private onConnection(): void {
        this.startTimer();
        clearInterval(this.timer);
        runInAction(() => {
            this.setCallButtons(false, true);
            this.timer = setInterval(() => {
                this.callTime = this.getTimePassed();
                this._status = this.callTime;
            }, 1000);
        });
    }

    private setCallButtons(showPickup: boolean, showHangUp: boolean): void {
        runInAction(() => {
            this.canHangUp = showHangUp;
            this.canCall = showPickup;
        });
    }

    private getTimePassed(): string {
        const diff = Math.floor(Date.now() / 1000) - this.startTime;
        const seconds = this.pad(Math.floor(diff % 60).toString());
        const minutes = this.pad(parseInt(Math.floor(diff / 60).toString()).toString());
        return `${minutes}:${seconds}`;
    }

    private pad(val: string) {
        return val.length < 2 ? `0${val}` : val;
    }
}

export default new TwilioPhoneStore();
