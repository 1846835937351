import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { useOutsideClick } from '@src/hooks';
import { CheckboxFieldPreventDefault } from '@src/components/FormField/FormField';
import { ReactComponent as InfoIcon } from '@src/theme/icons/icon-info.svg';
import './signup-select.sass';

type OptionValue = string | number;
export type OptionType<T extends OptionValue> = {
    value: T;
    label: string;
    subLabel?: string;
    emoji?: string;
    isTitle?: boolean
};

interface SignupSelectProps<T extends OptionValue> {
    options: OptionType<T>[];
    selectedOptionValue: T;
    placeholder?: string;
    forceValue?: boolean;
    onChange?: (value: OptionType<T>) => void;
    classname?: string;
    'data-test'?: string;
}

const tip = 'Each message segment can have up to<br />160 standard characters. Longer<br />messages will count as multiple<br />segments.';

export function SignupSelect<T extends OptionValue>(props: SignupSelectProps<T>) {
    const selectRef = useRef(null);
    const [ selectedOption, setSelectedOption ] = useState(null);
    const [ open, setOpen ] = useState(false);
    const { classname, options, selectedOptionValue } = props;
    const placeholder = props.placeholder ? props.placeholder : 'Select';

    useEffect(() => {
        let result = options.find((x) => x.value === selectedOptionValue);
        result = result
            ? result
            : !result && props.forceValue && options.length
            ? options[0]
            : null;
        if (props.forceValue && props.onChange && result.value !== selectedOptionValue) {
            props.onChange(result);
        }

        setSelectedOption(result);
    }, [selectedOptionValue, options]);

    const onValueChange = (opt: OptionType<T>) => {
        setTimeout(() => {
            setOpen(false);
            if (props.onChange) {
                props.onChange(opt);
            }
        }, 10);
    };

    useOutsideClick(selectRef, () => {
        setOpen(false)
    });

    let longestLabel = '';

    return (
        <div
            data-test={props['data-test']}
            ref={selectRef}
            className={classNames('SignupSelect', { open })}
            onClick={() => setOpen(true)}
        >
            <div className="SignupSelect__placeholder">
                <div className={classNames('SignupSelect__placeholder-text', { isSelectedOption: selectedOption })}>
                    {selectedOption ?
                        <>
                            {selectedOption.subLabel?.includes('messages') &&
                                <>
                                    <ReactTooltip multiline={true} />
                                    <InfoIcon className="info" data-tip={tip} />
                                </>
                            }
                            {selectedOption.emoji ? <span className="SignupSelect__placeholder-text-emoji">{selectedOption.emoji}</span> : null}
                            <span className="SignupSelect__placeholder-text-label">
                                {selectedOption.subLabel ? `${selectedOption.label} - ${selectedOption.subLabel}` : selectedOption.label}
                            </span>
                        </>
                    :
                        placeholder
                    }
                </div>
                <div className="SignupSelect__placeholder-arrow">
                    <div className="rotatedSquare" />
                </div>
            </div>
            <div className="SignupSelect__spacer">{longestLabel}</div>
            <div
                ref={selectRef}
                className={classNames('SignupSelect__options', {
                    fullWidth: classname === 'fullWidth',
                    onLeft: classname === 'onLeft',
                    onRight: classname === 'onRight'
                })}
            >
                {
                    options && options.map((opt: OptionType<T>, index: number) => {
                        longestLabel = longestLabel.length > opt.label.length ? longestLabel : opt.label;
                        return (
                            <div
                                className={classNames('item', {
                                    selected: selectedOptionValue === opt.value,
                                    isTitle: opt.isTitle
                                })}
                                key={`${opt.value}-${opt.label}-${index}`}
                                onClick={() => !opt.isTitle && onValueChange(opt)}
                            >
                                {opt.isTitle
                                    ? <span className="item-title">{opt.label}</span>
                                    : (
                                    <span className="item-label">
                                        {opt.subLabel && <CheckboxFieldPreventDefault/>}
                                        <span>
                                            <span className={classNames({
                                                withSubLabel: opt.subLabel,
                                                noSubLabel: !opt.subLabel
                                            })}>
                                                {opt.emoji && <span className="item-emoji">{opt.emoji}</span>}
                                                {opt.label}
                                            </span>
                                            {opt.subLabel && <span>{opt.subLabel}</span>}
                                        </span>
                                    </span>
                                      )
                                }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}
