import { observable, runInAction, action } from 'mobx';
import SelectedChannelStore from './selectedChannel.store';
import EditedChannelStore from './editedChannel.store';
import SelectedDirectConversationStore from './selectedDirectConversation.store';
import ConversationsListStore from './conversationsList.store';
import { BaseStore } from '@src/stores/base.store';
import SocketInstance from "@src/stores/socket-instance";
import { SocketEventType, SocketWrapper } from "@src/api/socket-wrapper";
import { TeamMessage, TeamMessageEvent } from "@src/stores/models/team-chat";
import MessagesStore from '@src/stores/messages/messages.store';

export class ConversationsStore extends BaseStore {
    @observable SelectedChannelStore;
    @observable EditedChannelStore;
    @observable SelectedDirectConversationStore;
    @observable ConversationsListStore;
    private socketWrapper: SocketWrapper;
    @observable incomingMessage: TeamMessage;

    constructor(socketWrapper: SocketWrapper) {
        super();
        this.socketWrapper = socketWrapper;
        this.SelectedChannelStore = new SelectedChannelStore(socketWrapper, this);
        this.EditedChannelStore = new EditedChannelStore(this);
        this.SelectedDirectConversationStore = new SelectedDirectConversationStore(socketWrapper, this);
        this.ConversationsListStore = new ConversationsListStore(socketWrapper, this);
    }

    @action init(): void {
        this.SelectedChannelStore && this.SelectedChannelStore.init();
        this.SelectedDirectConversationStore && this.SelectedDirectConversationStore.init();
        this.ConversationsListStore && this.ConversationsListStore.init();
        if (this.socketWrapper) {
            this.socketWrapper.addEventListener(SocketEventType.TeamMessage, (incomingMsg) => {
                runInAction(() => {
                    this.displayMessage(incomingMsg);
                })
            });
        }
    }

    @action destroy(): void {
        this.ConversationsListStore && this.ConversationsListStore.init();
        this.EditedChannelStore && this.EditedChannelStore.init();
        this.SelectedChannelStore && this.SelectedChannelStore.destroy();
        this.SelectedDirectConversationStore && this.SelectedDirectConversationStore.destroy();
        if (this.socketWrapper) {
            this.socketWrapper.destroy();
        };
    }

    @action displayMessage = (e: TeamMessageEvent) => {
        if (e.message.sentBy === MessagesStore.ThreadsStore.constants.employee.id) {
            this.ConversationsListStore.updateList(e.message.conversationId, 'lastupdate+lastread');
        } else {
            this.incomingMessage = e.message;
            this.ConversationsListStore.updateList(e.message.conversationId, 'lastupdate');
        };
    }

    @action removeIncomingMessage = () => {
        this.incomingMessage = null;
    }
}

export default new ConversationsStore(SocketInstance);
