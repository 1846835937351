import { BillingStore } from '@src/stores/billing.store';
import React from 'react';
import { useHistory } from "react-router-dom";
import { PlanDetails } from '@src/components';

import '../payment-inside-stripe.sass';

interface HeaderProps {
    billingStore?: BillingStore;
};

const AddPaymentAndFinishTrialHeader: React.FunctionComponent<HeaderProps> = ({ billingStore }) => {
    const history = useHistory();

    return (
        <>
            {!location.search.includes('?finishTrial') &&
                <button
                    className="PaymentInsideStripe__back btn-tetriary"
                    onClick={() => {
                        history.push('/settings/billing');
                        billingStore?.setIsViewBilling(true);
                    }}
                >
                    &lt; See Billing
                </button>
            }
            <div className="PaymentInsideStripe__title">Upgrade Subscription</div>
            <div className="PaymentInsideStripe__subtitle">Upgrade to a paid subscription to enjoy additional features, increased message limits and more. Cancel any time.</div>
            <PlanDetails view="finishTrial" />
        </>
    )
};

export default AddPaymentAndFinishTrialHeader;
