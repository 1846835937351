import React from 'react';

import '../Auth.sass';

interface GoToLoginProps {
  onGoToLogin?: () => void;
};

export const GoToLogin: React.FunctionComponent<GoToLoginProps> = ({ onGoToLogin }) => (
  <div className="email-form-container below-signup">
    <div className="email-form-footer">
        <p className="subtitle AuthContainer__subtitle">Already have an account?</p>
        <button
            className="btn-tetriary-blue"
            onClick={() => onGoToLogin()}
        >
            Login
        </button>
    </div>
  </div>
);
