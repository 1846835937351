import api from '@src/api/api';

export const redeemOffer = (oid: string, stripeToken?: string) => {
  const params = stripeToken ? { stripeToken: stripeToken } : {}
  return api.post(`/coupon/redeem/${oid}`, params);
};

export const getCoupon = (cid: string, dt?) => {
  const params = dt ? {dt} : {};
  return api.get(`/coupon/${cid}`, { params });
};
