import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { AsYouType } from 'libphonenumber-js';
import {
    ImportCustomers,
    Loader,
    SortColumn,
    Table,
    TableHeader,
    /*TwilioPhone*/
} from '@src/components';
import { useDebounce, useOutsideClick } from '@src/hooks';
import { CustomersApi } from '@src/requests';
import { STORES, WithCustomersStoreProps, withStores, WithUserStoreProps } from '@src/stores/with-store';
import { formatDate, formatTime } from '@src/theme/utils/helpers';
import { ReactComponent as SearchIcon } from '@src/theme/icons/search-inactive.svg';
import { ReactComponent as ClearIcon } from '@src/theme/icons/clear.svg';
import { useHistory } from 'react-router-dom';
import AuthStore from '@src/stores/auth.store';

import './customers-table.sass';
import CustomersEmptyTable from './EmptyTable';
import { Customer, LoadingState } from '@src/stores/models';
import PageHeader from '@src/components/PageHeader/PageHeader';
import OnTheGoDialog from './OnTheGoDialog';
import { AddCustomer } from './AddCustomer';

const dateCellTemplate = (field, data) => {
    const baseDate = data[field];
    const date = baseDate ? `${formatDate(baseDate)}, ${formatTime(baseDate)}` : '';
    return <>{date}</>;
};

// const phoneCellTemplate = (field, data) => {
//     return <TwilioPhone phoneNumber={data[ 'phone' ]}/>
// }

const tableHeaders: TableHeader[] = [
    {
        title: 'Name',
        sortField: 'name',
        searchable: true,
        flexWidth: 2,
        cls: ['bold', 'left'],
        headerCls: ['left'],
        // eslint-disable-next-line react/display-name
        template: (header, data) => {
            return `${data['firstName']} ${data['lastName']}`;
        },
    },
    {
        title: 'Phone',
        sortField: 'phone',
        flexWidth: 2,
        cls: ['left'],
        headerCls: ['left'],
        template: (header, data) =>
            new AsYouType('US').input(('' + data['phone']).replace(/\D/g, '')),
        // template: phoneCellTemplate
    },
    {
        title: 'Created',
        sortField: 'created',
        flexWidth: 2,
        cls: ['left'],
        headerCls: ['left'],
        template: (header, data) => dateCellTemplate('created', data),
    },
    {
        title: 'Last contacted',
        sortField: 'contacted',
        flexWidth: 2,
        cls: ['left'],
        headerCls: ['left'],
        template: (header, data) => dateCellTemplate('lastContact', data),
    },
    {
        title: 'Actions',
        flexWidth: 2,
        // eslint-disable-next-line react/display-name
        template: (header, data) => {
            return (
                <div className="customer-actions">
                    <NavLink key={data['id'] + 'view'} to={`customers/${data['id']}`}>
                        <button className="btn-primary customer-action view">View</button>
                    </NavLink>
                    <NavLink key={data['id'] + 'edit'} to={`/customers/edit/${data['id']}`}>
                        <button className="btn-primary customer-action">Edit</button>
                    </NavLink>
                </div>
            );
        },
    },
];

const formatCustomers = (customers: Customer[]): Customer[] => {
    return customers.map((el) => {
        if (el.firstName && el.lastName) {
            return el;
        } else if (el.firstName && !el.lastName) {
            return {
                ...el,
                firstName: el.firstName,
                lastName: '',
            };
        } else if (!el.firstName && el.lastName) {
            return {
                ...el,
                firstName: '',
                lastName: el.lastName,
            };
        } else if (!el.firstName && !el.lastName) {
            return {
                ...el,
                firstName: new AsYouType('US').input(('' + el.phone).replace(/\D/g, '')),
                lastName: '',
            };
        };
    });
}

const CustomersTableWithStore: React.FC<WithCustomersStoreProps & WithUserStoreProps> = ({ CustomersStore, UserStore}) => {
    const lockedAccount = AuthStore.accountStatus === 'LOCKED';
    const store = CustomersStore;
    const customers = store.customers;
    const history = useHistory();

    const customersToDisplay = formatCustomers(customers);

    const pageSize = 25;
    const [addCustomerDialogOpen, setAddCustomerDialogOpen] = useState(false);
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [sortCol, setSortCol] = useState<SortColumn>(null);
    const [hasFocus, setHasFocus] = useState(false);
    const [isOnTheGoOpen, setIsOnTheGoOpen] = useState(false);
    const [keepPrevPageBeingDisplayed, setKeepPrevPageBeingDisplayed] = useState(true);
    const debounceSearchText = useDebounce(store.customersSearchText, 250);
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);

    const onError = (message) => toast.error(message);

    const searchRequest = () => {
        store.searchCustomersForDt(
            debounceSearchText,
            store.pageBeingDisplayed,
            pageSize,
            sortCol?.col,
            sortCol?.dir,
            onError
        );
    };

    useOutsideClick(wrapperRef, () => {
        setHasFocus(false);
    });

    const searchTextChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        store.setCustomersSearchText(e.target.value);

    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.keyCode === 27) store.setCustomersSearchText('');
    };

    const clear = () => {
        store.setCustomersSearchText('');
        inputRef.current.focus();
    };

    useLayoutEffect(() => {
        store.init();
        if (!history.location.search.length) {
            setKeepPrevPageBeingDisplayed(false);
            store.setPageBeingDisplayed(1);
            store.setCustomersSearchText('');
        }
    }, []);

    const searchReq = () => {
        searchRequest()
    }

    useEffect(() => searchReq(), [store.pageBeingDisplayed, sortCol]);

    useEffect(() => {
        if (debounceSearchText.length === 1) {
            return;
        }
        if (!keepPrevPageBeingDisplayed) {
            if (store.pageBeingDisplayed > 1) {
                store.setPageBeingDisplayed(1);
            } else if (
                store.customersSearchText.length === 0 ||
                store.customersSearchText.length > 1
            ) {
                searchReq();
            }
        } else {
            setKeepPrevPageBeingDisplayed(false);
        }
    }, [debounceSearchText]);

    const handleAddCustomerSuccess = () => {
        searchRequest();
    };

    const handleMobileLinkClick = () => {
        setIsOnTheGoOpen(true);
    };

    const handleOnTheGoClose = () => {
        setIsOnTheGoOpen(false);
    };

    const renderTable = <Table
        headers={tableHeaders}
        data={customersToDisplay}
        pageSize={pageSize}
        totalCount={store.totalCustomers}
        currentPage={store.pageBeingDisplayed}
        loading={store.loadingState !== LoadingState.Loaded}
        onSortChange={(col) => setSortCol(col)}
        onPageChange={(page) => store.setPageBeingDisplayed(page)}
        emptyTable={<CustomersEmptyTable isSearchResult={store.customersSearchText.length > 0}/>}
        showHeaderOnNoData
    />

    return (
        <>
            <PageHeader
                isLoading={UserStore.loadingState === LoadingState.Loading}
                title={UserStore.user.companyName}
            />
            <div className={`CustomersTable card ${store.loadingState !== LoadingState.Loaded ? 'CustomersTable__loading' : ''} ${customersToDisplay.length === 0 ? 'CustomersTable__NoPaging' : ''}`}>
                <Loader loadingState={store.loadingState} />
                <div className="CustomersTable__header">
                    <div>
                        <h3>Manage Customers</h3>
                    </div>
                    <div className="CustomersTable__headerButtons">
                        <button className="btn-primary" onClick={() => CustomersApi.exportCustomers()}>
                            Export to CSV
                        </button>
                        {!lockedAccount ? <>
                            <button className="btn-primary" onClick={() => setImportDialogOpen(true)}>
                                Import
                            </button>
                            <button data-test="add-btn" className="btn-primary-full" onClick={() => setAddCustomerDialogOpen(true)}>
                                Add Contact
                            </button>
                        </>
                    : null}
                    </div>
                </div>
                {!lockedAccount ?
                    <>
                        <div
                            className={classNames('CustomersTable__search', {
                                'filled-out': store.customersSearchText || hasFocus,
                            })}
                            ref={wrapperRef}
                        >
                            <SearchIcon />
                            <input
                                name="searchCustomer"
                                className="Faq__search-input"
                                type="text"
                                placeholder="Search customers"
                                value={store.customersSearchText}
                                ref={inputRef}
                                onFocus={() => setHasFocus(true)}
                                onKeyDown={(e) => keyDown(e)}
                                onChange={(e) => searchTextChange(e)}
                            />
                            {store.customersSearchText && (
                                <button className="clear-btn" onClick={(): void => clear()}>
                                    <ClearIcon />
                                </button>
                            )}
                        </div>
                        <div className="CustomersTable__list">
                            {renderTable}
                        </div>
                    </>
                : null}
                <ImportCustomers openDialog={importDialogOpen} closeDialog={() => setImportDialogOpen(false)} />
                <AddCustomer
                    openDialog={addCustomerDialogOpen}
                    onCloseDialog={() => setAddCustomerDialogOpen(false)}
                    onSuccess={handleAddCustomerSuccess}
                />
            </div>
            {/* <MobileLinkBanner onClick={handleMobileLinkClick}/> */}
            {isOnTheGoOpen && <OnTheGoDialog open={isOnTheGoOpen} onClose={handleOnTheGoClose}/>}
        </>
    );
};

export const CustomersTable = withStores(CustomersTableWithStore, [
    STORES.Customers,
    STORES.User
]);
