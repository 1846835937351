import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { AsYouType } from 'libphonenumber-js';
import { useDebounce } from '@src/hooks';
import {
    STORES,
    withStores,
    WithTagStoreProps,
    WithConversationsStoreProps,
} from '@src/stores/with-store';
import { ThreadsStore } from '@src/stores/messages/threads.store';
import { FormField, Loader } from '@src/components';
import { ReactComponent as SearchIcon } from '@src/theme/icons/search-inactive.svg';
import { ReactComponent as Tag } from '@src/theme/icons/tag-outlined-grey.svg';
import { checkPhoneNumber } from '@src/theme/utils/helpers';
import { images } from '@src/theme/images';
import { LoadingState } from '@src/stores/models';

import InfiniteScroll from 'react-infinite-scroll-up-n-down';

import './tags-channels-edition.sass';

interface TagsChannelsEditionProps {
    onDelete: (selectedGroup: boolean) => void;
    isNew: boolean;
    type: string;
    customerId?: string;
}

type props =
    TagsChannelsEditionProps &
    WithTagStoreProps &
    { ThreadsStore: ThreadsStore } &
    WithConversationsStoreProps;

const TagsChannelsEditionWithStore: React.FC<props> = (props) => {
    const TagsStore = props.TagsStore;
    const ThreadsStore = props.ThreadsStore;
    const ConversationsStore = props.ConversationsStore;

    const type = props.type;
    const viewTag = props.type === 'Tag';
    const viewTeamChannel = props.type === 'Group';

    // const widerColumnsWidth = window.innerWidth > 1200 ? 2 : 3;
    const { employee } = ThreadsStore.constants;
    const [isCreation, setIsCreation] = useState(props.isNew);
    const [editionState, setEditionState] = useState('settings');
    const [ableToRequest, setAbleToRequest] = useState(true);
    const [addingPerformed, setAddingPerformed] = useState(false);
    const [removingPerformed, setRemovingPerformed] = useState(false);
    // const [showCreateNewMemberBtn, setShowCreateNewMemberBtn] = useState(false);
    const [showDeleteView, setShowDeleteView] = useState(false);

    const addMembersRef = useRef(null);
    const removeMembersRef = useRef(null);

    const [searchNonMembers, setSearchNonMembers] = useState('');
    const [searchCurrentMembers, setSearchCurrentMembers] = useState('');
    const debounceSearchNonMembers = useDebounce(searchNonMembers, 200);
    const debounceSearchCurrentMembres = useDebounce(searchCurrentMembers, 200);

    const itemName =
        viewTag ? TagsStore?.EditedTagStore?.editedTag?.value :
        ConversationsStore?.EditedChannelStore?.editedChannel?.channelName;
    const itemCurrentMembers =
        viewTag ? TagsStore?.EditedTagStore?.currentMembers :
        ConversationsStore?.EditedChannelStore?.currentMembers;
    const itemNonMembers =
        viewTag ? TagsStore?.EditedTagStore?.nonMembers :
        ConversationsStore?.EditedChannelStore?.nonMembers;
    const itemTotalCurrentMembers =
        viewTag ? TagsStore?.EditedTagStore?.totalCurrentMembers :
        ConversationsStore?.EditedChannelStore?.totalCurrentMembers;

    const [name, setName] = useState(itemName);
    const [editedName, setEditedName] = useState(itemName);
    const [currentMembers, setCurrentMembers] = useState(itemCurrentMembers);
    const [currentMembersState, setCurrentMembersState] = useState(itemCurrentMembers?.map(el => el.id).reduce((a,b)=> (a[b]='basic',a),{}));
    const [nonMembers, setNonMembers] = useState(itemNonMembers);
    const [nonMembersState, setNonMembersState] = useState(itemNonMembers?.map(el => el.id).reduce((a,b)=> (a[b]='basic',a),{}));
    const [currentMembersNumber, setCurrentMembersNumber] = useState(itemTotalCurrentMembers);
    const loading =
        viewTag ? TagsStore.EditedTagStore.tagLoading :
        ConversationsStore.EditedChannelStore.channelLoading;

    useEffect(() => {
        setName(itemName);
    }, [ itemName ]);

    useEffect(() => {
        setCurrentMembers(itemCurrentMembers);

        const onlyNewItems = itemCurrentMembers
        ?.filter(el => !Object.keys(currentMembersState).includes(el.id))
        .map(el => el.id).reduce((a,b)=> (a[b]='basic',a),{});

        setCurrentMembersState({...currentMembersState, ...onlyNewItems});
    }, [ itemCurrentMembers ]);

    useEffect(() => {
        setNonMembers(itemNonMembers);

        const onlyNewItems = itemNonMembers
        ?.filter(el => !Object.keys(nonMembersState).includes(el.id))
        .map(el => el.id).reduce((a,b)=> (a[b]='basic',a),{});

        setNonMembersState({...nonMembersState, ...onlyNewItems});
    }, [ itemNonMembers ]);

    useEffect(() => {
        if (searchCurrentMembers === '') {
            setCurrentMembersNumber(itemTotalCurrentMembers);
        }
    }, [ itemTotalCurrentMembers ]);

    const onEditionStateChange = () => {
        if (isCreation) return;
        // setShowCreateNewMemberBtn(false);
        setNonMembersState(Object.keys(nonMembersState).reduce((a,b)=> (a[b]='basic',a),{}));
        setCurrentMembersState(Object.keys(currentMembersState).reduce((a,b)=> (a[b]='basic',a),{}));

        if (searchCurrentMembers.length) {
            setSearchCurrentMembers('');
            if (viewTag) {
                TagsStore.EditedTagStore.partialInit();
            }
        } else if (searchNonMembers.length) {
            setSearchNonMembers('');
            if (viewTag) {
                TagsStore.EditedTagStore.partialInit();
            }
        };

        if (viewTag && (removingPerformed || addingPerformed || TagsStore.EditedTagStore.currentMembersPage > 1 || TagsStore.EditedTagStore.nonMembersPage > 1)) {
            TagsStore.EditedTagStore.partialInit();
            TagsStore.EditedTagStore.loadMoreCurrentMembersForEdition('')
            TagsStore.EditedTagStore.loadMoreNonMembersForEdition('')
            setRemovingPerformed(false);
            setAddingPerformed(false);
        } else if (viewTeamChannel && (removingPerformed || addingPerformed || ConversationsStore.EditedChannelStore.currentMembersPage > 1 || ConversationsStore.EditedChannelStore.nonMembersPage > 1)) {
            ConversationsStore.EditedChannelStore.getConversation();
            setRemovingPerformed(false);
            setAddingPerformed(false);
        }
    }

    useEffect(() => {
        onEditionStateChange();
    }, [editionState]);

    const onDebounceSearchCurrentMembresChange = () => {
        if (isCreation) return;
        if (searchCurrentMembers.length !== 1) {
            if (viewTag && searchCurrentMembers !== TagsStore.EditedTagStore.searchQueryCurrentMembers) {
                TagsStore.EditedTagStore.loadMoreCurrentMembersForEdition(searchCurrentMembers);
            }
        }
    };

    const onDebounceSearchNonMembresChange = () => {
        if (isCreation) return;
        if (searchNonMembers.length !== 1) {
            if (viewTag && searchNonMembers !== TagsStore.EditedTagStore.searchQueryNonMembers) {
                TagsStore.EditedTagStore.loadMoreNonMembersForEdition(searchNonMembers);
            }
        }
    };

    useEffect(() => {
        onDebounceSearchCurrentMembresChange();
    }, [debounceSearchCurrentMembres]);

    useEffect(() => {
        onDebounceSearchNonMembresChange();
    }, [debounceSearchNonMembers]);

    const changeName = () => {
        if (isCreation) {
            const req =
                viewTag ?
                    TagsStore.EditedTagStore.createTag(editedName, props.customerId) :
                    ConversationsStore.EditedChannelStore.startChannel(editedName);
            req.then(() => {
                toast.success(type + ' created!', { autoClose: 4000 });
                if (viewTag) {
                    Promise.all([
                        TagsStore.EditedTagStore.loadMoreCurrentMembersForEdition(''),
                        TagsStore.EditedTagStore.loadMoreNonMembersForEdition('')
                    ]).then(() => {
                        setIsCreation(false);
                    })
                } else if (viewTeamChannel) { // sprawdzic jak zadziala dodawania channeli
                    // ConversationsStore.EditedChannelStore.getConversation(editedName)
                    // .then(() => {
                        setIsCreation(false);
                    // })
                }
            });
        } else {
            const req =
                viewTag ?
                    TagsStore.EditedTagStore.changeName(editedName, props.customerId) :
                    ConversationsStore.EditedChannelStore.editChannelName(editedName);
            req.then(() => {
                toast.success(type + ' name changed!', { autoClose: 4000 });
            });
        }
    }

    const confirmDeletingItem = () => {
        const req =
            viewTag ?
                TagsStore.EditedTagStore.deleteTag() :
                ConversationsStore.EditedChannelStore.deleteChannel();
        req.then((selectedGroup) => {
            setShowDeleteView(false);
            props.onDelete(selectedGroup);
            toast.success(type + ' deleted!', { autoClose: 4000 });
        });
    };

    const loadMore = (nonMembers) => {
        if (!ableToRequest) {
            return;
        }
        setAbleToRequest(false);
        const search = nonMembers ? searchNonMembers : searchCurrentMembers;
        let req;
        if (viewTag) {
            if (nonMembers) {
                req = TagsStore.EditedTagStore.loadMoreNonMembersForEdition(search)
            } else {
                req = TagsStore.EditedTagStore.loadMoreCurrentMembersForEdition(search)
            }
        }
        return req?.then(
            () => {
                setAbleToRequest(true);
            }
        );
    };

    const loadMoreCurrentMembers = () => {
        if (
            viewTag && TagsStore.EditedTagStore.hasMoreCurrentMembersToLoad ||
            viewTeamChannel && ConversationsStore.EditedChannelStore.hasMoreCurrentMembersToLoad
        ) {
            loadMore(false);
        }
    }
    const loadMoreNonMembers = () => {
        if (
            viewTag && TagsStore.EditedTagStore.hasMoreNonMembersToLoad ||
            viewTeamChannel && ConversationsStore.EditedChannelStore.hasMoreNonMembersToLoad
        ) {
            loadMore(true);
        }
    }

    const changeMemberState = (m, newState: string) => {
        if (newState === 'added') {
            const customer = m.phone ? m : null;
            const tag = m.value ? m : null;
            const req =
                viewTag ?
                    TagsStore.EditedTagStore.addMember(m.id) :
                    ConversationsStore.EditedChannelStore.addUsersToChannel([m.id]);
            req.then(() => {
                setNonMembersState({...nonMembersState, [m.id]: newState});
                setCurrentMembersNumber(currentMembersNumber + 1);
                setAddingPerformed(true);
            });
        } else if (newState === 'removed') {
            const req =
                viewTag ?
                    TagsStore.EditedTagStore.removeMember(m.id) :
                    ConversationsStore.EditedChannelStore.removeUsersFromChannel([m.id]);
            req.then(() => {
                setCurrentMembersState({...currentMembersState, [m.id]: newState});
                setCurrentMembersNumber(currentMembersNumber === 0 ? 0 : currentMembersNumber - 1);
                setRemovingPerformed(true);
            });
        } else {
            setCurrentMembersState({...currentMembersState, [m.id]: newState})
        }
    }

    const header = (
        <div className="TagsChannelsEdition__header">
            <div
                className={classNames('TagsChannelsEdition__header-tab settings', { 'active': editionState === 'settings' })}
                onClick={() => setEditionState('settings')}
            >
                <div className="text">Settings</div>
            </div>
            <div
                className={classNames('TagsChannelsEdition__header-tab add', {
                    'disabled': !name && isCreation,
                    'active': editionState === 'add',
                    'showLeftBorder': editionState === 'remove',
                    'showRightBorder': editionState === 'settings',
                })}
                onClick={() => {
                    setAbleToRequest(false);
                    setTimeout(() => setAbleToRequest(true), 500);
                    (!name && isCreation) ? null : setEditionState('add')}}
            >
                <div className="text">+ Add {viewTeamChannel ? 'Employees' : 'Members'}</div>
            </div>
            <div
                className={classNames('TagsChannelsEdition__header-tab remove', {
                    'disabled': !name && isCreation,
                    'active': editionState === 'remove'
                })}
                onClick={() => {
                    setAbleToRequest(false);
                    setTimeout(() => setAbleToRequest(true), 500);
                    (!name && isCreation) ? null : setEditionState('remove')
                }}
            >
                <div className="text">Manage {viewTeamChannel ? 'Employees' : 'Members'} ({isCreation ? '0' : currentMembersNumber})</div>
            </div>
        </div>
    );

    const settings = editionState === 'settings' && (
        <div className="TagsChannelsEdition__content-view settings">
            <div className="name">
                <FormField label={type + ' name'} htmlFor="name">
                    <input
                        type="text"
                        name="name"
                        className="form-input"
                        defaultValue={name}
                        onChange={(e) => setEditedName(e.target.value)}
                    />
                </FormField>
                <button
                    className="btn-primary"
                    onClick={() => changeName()}
                    disabled={editedName === name || !editedName}
                >
                    Save
                </button>
            </div>
            {!isCreation &&
                <div className="deleteTagGroup">
                    <button
                        className="btn-secondary"
                        onClick={() => setShowDeleteView(true)}
                    >
                        Delete {type}
                    </button>
                </div>
            }
        </div>
    );

    useEffect(() => {
        if (
            (viewTag) &&
            (nonMembers?.length || searchNonMembers?.length) &&
            editionState === 'add'
        ) {
            setTimeout(() => addMembersRef?.current?.focus(), 300);
        }
    }, [nonMembers?.length, searchNonMembers?.length]);

    useEffect(() => {
        if (
            (viewTag) &&
            (currentMembers?.length || searchCurrentMembers?.length) &&
            editionState === 'remove'
        ) {
            setTimeout(() => removeMembersRef?.current?.focus(), 300);
        }
    }, [currentMembers?.length, searchCurrentMembers?.length]);

    const add = editionState === 'add' && (
        <div className="TagsChannelsEdition__content-view add">
            {(
                (
                    viewTag
                ) && (nonMembers.length || searchNonMembers.length)) ?
                <div className="search SearchInput">
                    <div className="input-container">
                        <SearchIcon />
                        <input
                            ref={addMembersRef}
                            // type={showCreateNewMemberBtn ? 'text' : 'search'}
                            type='search'
                            placeholder={viewTag ? "Search" : "Search or Type New Number"}
                            value={searchNonMembers}
                            onChange={(e) => setSearchNonMembers(e.target.value)}
                        />
                        {/* {showCreateNewMemberBtn &&
                            <div
                                className="btn-tetriary-blue createNewMemberBtn"
                                onClick={() => createNewMemberByPhone()}
                            >
                                + Create New Member & Add
                            </div>
                        } */}
                    </div>
                </div>
                : null
            }
            <div className={classNames('list', { 'channel': viewTeamChannel })}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMoreNonMembers}
                    initialLoad={false}
                    hasMore={TagsStore.EditedTagStore.hasMoreNonMembersToLoad}
                    useWindow={false}
                    threshold={100}
                >
                    {(!nonMembers.length && loading !== LoadingState.Loading) ?
                        <div className="noItemsToDisplay">
                            No { viewTag ? 'customers' : 'employees' } to display.
                        </div> :
                        nonMembers.map((el, i) => {
                            return (
                                <div
                                    key={i}
                                    className="item"
                                >
                                    <div className="name">
                                        {el.value && <Tag />}
                                        {el.firstName || (el.phone && new AsYouType('US').input(('' + el.phone).replace(/\D/g, ''))) || el.value} {el.lastName} {el.participantId === employee.id && ' (You)'}
                                    </div>
                                    <div className="actions adding">
                                        {
                                            nonMembersState[el.id] === 'basic' ?
                                                <div
                                                    className="btn-tetriary-blue"
                                                    onClick={() => changeMemberState(el, 'added')}
                                                >
                                                    Add
                                                </div>
                                            : <div className="added btn-tetriary">Added</div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </InfiniteScroll>
            </div>
        </div>
    );

    const remove = editionState === 'remove' && (
        <div className="TagsChannelsEdition__content-view remove">
            {(viewTag && (currentMembers.length || searchCurrentMembers.length)) ?
                <div className="search SearchInput">
                    <div className="input-container">
                        <SearchIcon />
                        <input
                            ref={removeMembersRef}
                            type="search"
                            placeholder="Search"
                            value={searchCurrentMembers}
                            onChange={(e) => setSearchCurrentMembers(e.target.value)}
                        />
                    </div>
                </div>
                : null
            }
            <div className={classNames('list', { 'channel': viewTeamChannel })}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMoreCurrentMembers}
                    initialLoad={false}
                    hasMore={TagsStore.EditedTagStore.hasMoreCurrentMembersToLoad}
                    useWindow={false}
                    threshold={100}
                >
                    <div>
                        {(!currentMembers.length && loading !== LoadingState.Loading) ?
                        <div className="noItemsToDisplay">
                            No { viewTag ? 'customers' : 'employees' } to display.
                        </div> :
                            currentMembers.map((el, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="item"
                                    >
                                        <div className="name">
                                            {el.value && <Tag />}
                                            {el.firstName || (el.phone && new AsYouType('US').input(('' + el.phone).replace(/\D/g, ''))) || el.value} {el.lastName} {el.participantId === employee.id && ' (You)'}
                                        </div>
                                        <div className="actions removing">
                                            {
                                                currentMembersState[el.id] === 'basic' ?
                                                    <div
                                                        className="askToRemove btn-tetriary-blue"
                                                        onClick={() => changeMemberState(el, 'beingRemoved')}
                                                    >
                                                        Remove
                                                    </div>
                                                : currentMembersState[el.id] === 'beingRemoved' ?
                                                    <>
                                                        <div
                                                            className="confirm btn-tetriary-blue"
                                                            onClick={() => changeMemberState(el, 'removed')}
                                                        >
                                                            Remove from {type}
                                                        </div>
                                                        <div
                                                            className="cancel btn-tetriary"
                                                            onClick={() => changeMemberState(el, 'basic')}
                                                        >
                                                            Cancel
                                                        </div>
                                                    </>
                                                : <div className="removed btn-tetriary">Removed</div>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    );

    const editing = !showDeleteView && (
        <>
            <div className="TagsChannelsEdition__title">
                <span className="editionType">{isCreation ? 'Create' : 'Edit'} {type}</span>
                <span className="name">{name}</span>
            </div>
            { header }
            <div className="TagsChannelsEdition__content">
                {settings || add || remove}
            </div>
        </>
    );

    const deleting = showDeleteView && (
        <div className="TagsChannelsEdition__deletingTagGroup">
            <div className="TagsChannelsEdition__deletingTagGroup-image">
                <img src={images.deleteTagGroup} alt="delete" />
            </div>
            <div className="TagsChannelsEdition__deletingTagGroup-confirm">
                <div className="TagsChannelsEdition__deletingTagGroup-confirm-question">
                    Are you sure you want to delete {name}?
                </div>
                <div className="TagsChannelsEdition__deletingTagGroup-confirm-actions">
                    <button
                        className="btn-secondary"
                        onClick={() => setShowDeleteView(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn-primary"
                        onClick={() => confirmDeletingItem()}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <Loader loadingState={loading} />
            <div className="TagsChannelsEdition">
                {editing || deleting}
            </div>
        </div>
    );
};

export const TagsChannelsEdition = withStores(TagsChannelsEditionWithStore, [
    STORES.Tags,
    STORES.ThreadsStore,
    STORES.ConversationsStore
]);
