import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { useOutsideClick } from '@src/hooks';

import './dropdown.sass';

interface DropdownProps {
    optionsArr?: string[];
    selOptionNumber?: number;
    dropdownName?: string;
    darkColor?: boolean;
    optionSelected?: (i: number) => void;
    disabled?: boolean;
}

export const Dropdown: React.FC<DropdownProps> = (props) => {
    const [showingOptions, setShowingOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState(props.optionsArr[props.selOptionNumber]);
    const optionsRef = useRef(null);

    useOutsideClick(optionsRef, () => {
        setShowingOptions(false);
    });

    const optionSelected = (i: number) => {
        props.optionSelected(i);
        setSelectedOption(props.optionsArr[i]);
    };

    return (
        <div className={classNames('Dropdown', { dark: props.darkColor, disabled: props.disabled })}>
            <div
                className="Dropdown__filter"
                ref={optionsRef}
                onClick={() => !props.disabled && setShowingOptions(!showingOptions)}
            >
                <div className={`header ${props.disabled ? "disabled": ""}`}>
                    {props.dropdownName ? (
                        <span className="dropdownName">
                            {showingOptions ? 'Close' : props.dropdownName}
                        </span>
                    ) : (
                        <span className="selectedOption">{selectedOption}</span>
                    )}
                    <span className={classNames('pointerWrapper', { showingOptions })}>
                        <i className="pointer" />
                    </span>
                </div>
                {showingOptions && (
                    <div className="selectOption customDropdown">
                        {props.optionsArr.map((el, i) => (
                            <div key={i} className="item" onClick={() => !props.disabled && optionSelected(i)}>
                                {el}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
