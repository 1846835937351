import { action, observable, runInAction } from 'mobx';
import {
    integrationsInfo,
    removeIntegration
} from '@src/requests/links.requests';
import AuthStore from '@src/stores/auth.store';

export interface IntegrationType {
    name: string;
    url: string;
}

interface IntegrationSource {
    id: string;
    source: string;
    status: "Connected" | "Disconnected";
}

export class IntegrationsStore {
    @observable integrationTypes: IntegrationType[] = [];
    @observable integrationSources: IntegrationSource[] = [];

    @observable status = 'init';
    @observable errorStatus: object = null;
    @observable dataLoaded = false;
    onError?: (type: string, id?: string) => void;
    onSuccess?: () => void;

    @action destroy() {
        this.integrationTypes = [];
        this.integrationSources = [];
        this.status = 'init';
        this.errorStatus = null;
        this.dataLoaded = false;
    }

    @action init() {
        this.initData();
    }

    @action initData() {
        this.status = 'loading';
        integrationsInfo().then(
            ({data}) => {
                const { sources, types } = data;
                runInAction(() => {
                    this.integrationSources = sources;
                    this.integrationTypes = types;
                    this.dataLoaded = true;
                    this.status = 'loaded';
                });
            }
        ,
        (err) => {
            if (err.response.status === 401) {
                AuthStore.resetAuth();
            } else {
                runInAction(() => {
                    this.status = 'errorInit';
                    this.errorStatus = err;
                });
                if (this.onError) {
                    this.onError('getBE');
                };
            };
        });
    }

    saveError = () => {
        runInAction(() => {
            this.onError('siteAuth');
            this.status = 'loaded';
        });
    };

    @action removeIntegration(id: string): void {
        this.status = 'loading';
        removeIntegration(id).then(
            () => this.initData(),
            () => this.saveError()
        );
    }
}

export default new IntegrationsStore();