import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { parseTemplate } from '@src/pages/Messages/components/Message/parse-template';
import { Dialog } from '@src/components';
import { TemplatesForm } from '@src/pages/Settings/components/Templates/TemplatesForm';
import { Template } from '@src/stores/models';
import { useOutsideClick } from '@src/hooks';
import { ReactComponent as ClearIcon } from '@src/theme/icons/clear.svg';
import { ReactComponent as SearchIcon } from '@src/theme/icons/search-inactive.svg';
import { ReactComponent as AttachIcon } from '@src/theme/icons/attach.svg';
import { ReactComponent as AddIcon } from '@src/theme/icons/add.svg';
import {
    STORES,
    withStores,
    WithTemplateStoreProps,
} from '@src/stores/with-store';

import './templates-mes.sass';

interface TemplatesMesProps {
    onTemplateSelect: (t: Template) => void;
}

type props = TemplatesMesProps & WithTemplateStoreProps;

const TemplatesMesWithStore: React.FC<props> = (props) => {
    const templatesStore = props.TemplateStore;
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const [templates, setTemplates] = useState(templatesStore.templates);
    const [searchText, setSearchText] = useState('');
    const [hasFocus, setHasFocus] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    useOutsideClick(wrapperRef, () => {
        setHasFocus(false);
    });

    const searchTextChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
        setSearchText(e.target.value);

    const submitSearch = () => {
        const searchRegEx = new RegExp(searchText, 'i');
        const temp = [];
        templatesStore.templates?.map((t) => {
            if (t.title.search(searchRegEx) > -1 || t.message.search(searchRegEx) > -1) {
                temp.push(t);
            }
        });
        setTemplates(temp);
    };

    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.keyCode === 27) setSearchText('');
    };

    const clear = () => {
        setSearchText('');
        inputRef.current.focus();
    };

    const newTemplateAdded = (t) => {
        setTemplates([t, ...templates])
        setDialogOpen(false);
    };

    useEffect(() => {
        if (searchText === '') setTemplates(templatesStore.templates);
        else submitSearch();
    }, [searchText]);

    const getBEData = () => {
        templatesStore.getBEData()
        .then(() => {
            setTemplates(templatesStore.templates);
        })
    };

    useEffect(() => {
        getBEData();
    }, []);

    return (
        <div className="TemplatesMes">
            <div
                className={classNames('TemplatesMes__search', {
                    'filled-out': searchText || hasFocus,
                })}
                ref={wrapperRef}
            >
                <SearchIcon />
                <input
                    name="searchTemplate"
                    className="Faq__search-input"
                    type="text"
                    placeholder="Search templates"
                    value={searchText}
                    ref={inputRef}
                    onFocus={() => setHasFocus(true)}
                    onKeyDown={(e) => keyDown(e)}
                    onChange={(e) => searchTextChange(e)}
                />
                {searchText && (
                    <button className="clear-btn" onClick={(): void => clear()}>
                        <ClearIcon />
                    </button>
                )}
            </div>

            <button className="TemplatesMes__addNew" onClick={() => setDialogOpen(true)}>
                <AddIcon />
                <div>Create New Template</div>
            </button>
            <div className="TemplatesMes__list">
                {templates?.map((t, i) => {
                        return (
                            <div key={i} className="item" onClick={() => props.onTemplateSelect(t)}>
                                <div className="title">
                                    {t.title}
                                    {t.attachments.length > 0 && <AttachIcon />}
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: parseTemplate(t.message) }} />
                            </div>
                        );
                    })}
            </div>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                title="Create New Template"
                className="TemplatesMes__dialog"
            >
                <TemplatesForm
                    messagesView={true}
                    newTemplateAdded={(t) => newTemplateAdded(t)}
                    closeForm={() => setDialogOpen(false)}
                />
            </Dialog>
        </div>
    );
};

export const TemplatesMes = withStores(TemplatesMesWithStore, [STORES.Template]);