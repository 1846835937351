import React from 'react';
import { SignupStep, stepsToDisplay } from './SignUp';

import './Steps.sass';

const signupSteps = [
  'Your company',
  'Create account',
  'Select number'
]

interface StepProps {
  active: boolean;
  label: string;
  stepNr: number;
};

const Step: React.FunctionComponent<StepProps> = ({ active, label, stepNr }) => (
  <div className={`step ${active && 'active'}`}>
    <span>{stepNr}</span>
    {label}
  </div>
);

interface StepsProps {
  current: SignupStep;
};

export const Steps: React.FunctionComponent<StepsProps> = ({ current }) => (
  <div className="signup-steps">
    {signupSteps.map((step, i) => (
      <Step key={step} label={step} active={stepsToDisplay.indexOf(current) === i} stepNr={i + 1}/>
    ))}
  </div>
);
