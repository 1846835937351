import React from 'react';
import Features from './Features';

import '../Signup.sass';
import Quote from './Quote';

const SignUpAside: React.FunctionComponent = () => {
    return (
        <aside className="Signup__aside">
            <Features/>
            <Quote/>
        </aside>
    );
};

export default SignUpAside;
