import React, { useEffect, useLayoutEffect, useState } from 'react';
import { onSuccess } from '@src/theme/utils/constants';
import { WithReviewSitesStoreProps, WithUserStoreProps, withStores, STORES, WithIntegrationsStoreProps } from '@src/stores/with-store';
import {
    Loader,
    Dialog,
    FormField,
    ToggleTabNew,
    ErrorToastDownload,
    ErrorToastSave,
} from '@src/components';
import { toUpperCaseFirst } from '@src/theme/utils/helpers';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { images } from '@src/theme/images';
import { ReactComponent as CheckGrey } from '@src/theme/icons/check-grey.svg';

import './integrations.sass';
import { IntegrationType } from '@src/stores/integrations.store';

const SITE_DIALOG = {
    FACEBOOK: {
        title: 'Facebook Reviews and Messenger',
        content:
            'Make sure you have access to the Facebook business page. If you do not have a facebook page for your business you will need to create one.',
        saveMsg: (
            <>
                <span className="facebook">
                    <img src={images.fb} alt="facebook" className="color" />
                    <img src={images.fbWhite} alt="facebook" className="white" />
                </span>
                Continue with Facebook
            </>
        ),
    },
    GOOGLE: {
        title: 'Link Google Review Site',
        content: 'Make sure you have access to the business Google page.',
        saveMsg: (
            <>
                <span className="google">
                    <img src={images.google} alt="google" />
                </span>
                Connect with Google
            </>
        ),
    },
    YELP: {
        title: 'Link Yelp Review Site',
        content:
            'Make sure you have access to the business Yelp page. Enter the Yelp biz name below.',
        note: 'NOTE: If you do not have a Yelp page for your business you will need to create one.',
        saveMsg: <span>Connect with Yelp</span>,
    },
    OTHER: {
        title: 'Link Other Review Site',
        content: `Enter the name and full URL for your business review site. When review requests are sent to your customers, the 'Name' will be displayed and 'URL' is where they will be sent.`,
        note: 'NOTE: Reviews posted on this site will not be shown in Myopolis.',
        saveMsg: <span>Connect</span>,
    },
};

const SITES_IMAGES = {
    FACEBOOK: images.fb,
    GOOGLE: images.google,
    YELP: images.yelpCentered,
    Hubspot: images.hubspot
};

export const IntegrationsWithStore: React.FC<WithReviewSitesStoreProps> = (
    props: WithReviewSitesStoreProps & WithIntegrationsStoreProps & WithUserStoreProps
) => {
    const reviewSitesStore = props.ReviewSitesStore;
    const integrationsStore = props.IntegrationsStore;
    const userStore = props.UserStore;
    const { reviewSites, reviewTypes } = reviewSitesStore;
    const { integrationSources, integrationTypes } = integrationsStore;
    const [dialogOpen, setDialogOpen] = useState(null);
    const [dialogInput, setDialogInput] = useState('');
    const [dialogName, setDialogName] = useState('');
    const [reviewSiteId, setReviewSiteId] = useState(null);

    const getBEData = () => {
        reviewSitesStore.init();
        if(userStore.hasFeatureFlag("integrations")) {
            integrationsStore.init();
        }
    };

    const onError = (type: string, id: string) => {
        switch (type) {
            case 'getBE': {
                toast.error(<ErrorToastDownload getBEData={() => getBEData()} />);
                break;
            }
            case 'activateSite': {
                toast.error(
                    <ErrorToastSave repeatUploading={() => reviewSitesStore.activateSite(id)} />
                );
                break;
            }
            case 'deactivateSite': {
                toast.error(
                    <ErrorToastSave repeatUploading={() => reviewSitesStore.deactivateSite(id)} />
                );
                break;
            }
            case 'siteAuth': {
                toast.error(
                    'Something went wrong. Please check your data (for example you may have not provided access to your business page) and save once again.'
                );
                break;
            }
            default: {
                return;
            }
        }
    };
    reviewSitesStore.onError = onError;
    integrationsStore.onError = onError;

    reviewSitesStore.onSuccess = () => onSuccess();
    integrationsStore.onSuccess = () => onSuccess();

    useLayoutEffect(() => {
        getBEData();
    }, []);

    const clear = () => {
        setDialogOpen(null);
        setDialogInput('');
        setDialogName('');
        setReviewSiteId(null);
        reviewSitesStore.setEdition(false);
    };

    useEffect(() => {
        clear();
    }, [reviewSitesStore.reviewSites]);

    const changeActive = (checked: boolean, value: string) => {
        if (checked) reviewSitesStore.activateSite(value);
        else reviewSitesStore.deactivateSite(value);
    };

    const closeDialog = () => {
        clear();
    };

    const selectPage = (pageOrLocation) => {
        closeDialog();
        if (dialogOpen?.name === 'FACEBOOK') {
            reviewSitesStore._fbConnect(pageOrLocation);
        } else {
            reviewSitesStore._googleConnect(pageOrLocation);
        }
    };

    const save = (): void => {
        if (!reviewSitesStore.edition) {
            reviewSitesStore.addSite({
                siteId: dialogInput,
                site: dialogOpen?.value,
                siteName: dialogName,
            });
        } else {
            const site = reviewSites.find((s) => s.id === reviewSiteId);

            reviewSitesStore.editSite({
                ...site,
                siteId: dialogInput,
                siteName: dialogName,
                reviewSiteId: reviewSiteId,
                site: dialogOpen?.value,
            });
        }
    };

    const moveUp = (id: string, index: number) => {
        const ids = reviewSites.map((s) => s.id).filter((s) => s !== id);
        ids.splice(index - 1, 0, id);
        reviewSitesStore.changeOrder(ids);
    };

    const handleAddIntegration = (type: IntegrationType) => {
        const createdAt = new Date().getTime();
        window.open(type.url, "_blank", "location=1");

        const interval = window.setInterval(() => {
            const timeStr = localStorage.getItem("integration_popup_closed_at");

            if(timeStr !== null && parseInt(timeStr) > createdAt) {
                integrationsStore.initData();
                clearInterval(interval);
            }
        }, 250);
    }

    return (
        <div className="Integrations">
            <div className="Integrations__subsection">
                <Loader loading={reviewSitesStore.status === 'loading'} />
                <div className="Integrations__title">Review Integrations</div>
                <div className="Integrations__subtitle">
                    Set which channels you would like to use to communicate with customers and solicit
                    reviews. You will need to establish a business account on each platform if you do
                    not already have one.
                </div>
                {reviewSitesStore.dataLoaded && (
                    <>
                        <table>
                            <thead>
                                <tr>
                                    <th>Enable</th>
                                    <th>Platform</th>
                                    <th>Actions</th>
                                    <th>Sort</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reviewSites.map((s, i) => (
                                    <tr key={uuidv4()}>
                                        <td className="Integrations__checkbox">
                                            <ToggleTabNew
                                                isChecked={s.active}
                                                stateChanged={() => changeActive(!s.active, s.id)}
                                            />
                                        </td>
                                        <td>
                                            { SITES_IMAGES[s.site.name] ? (
                                                <div className="Integrations__site">
                                                    <img
                                                        className="Integrations__site-img"
                                                        src={SITES_IMAGES[s.site.name]}
                                                        alt={s.site.name}
                                                    />
                                                    <span className="Integrations__site-title">
                                                        {toUpperCaseFirst(
                                                            s.site.name.toLocaleLowerCase()
                                                        )}
                                                    </span>
                                                </div>
                                            ) : <span className="Integrations__site-title">
                                                        {toUpperCaseFirst(
                                                            s.siteName.toLocaleLowerCase()
                                                        )}
                                                </span> }
                                        </td>
                                        <td>
                                            <button
                                                className="btn-secondary"
                                                onClick={() => reviewSitesStore.removeSite(s.id)}
                                            >
                                                Remove
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                className={classNames('Integrations__arrow', {
                                                    disabled: i === 0,
                                                })}
                                                onClick={() => moveUp(s.id, i)}
                                                disabled={i === 0}
                                            >
                                                <img src={images.arrowDown} alt="arrow-down" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                {reviewTypes
                                    .filter(
                                        (t) =>
                                            t.name !== 'OTHER' &&
                                            !reviewSites.some((s) => s.site.name === t.name)
                                    )
                                    .map(t =>
                                        <tr key={uuidv4()}>
                                            <td />
                                            <td>
                                                <div className="Integrations__site">
                                                    <img
                                                        className="Integrations__site-img"
                                                        src={SITES_IMAGES[t.name]}
                                                        alt={t.name}
                                                    />
                                                    <span className="Integrations__site-title">
                                                        {toUpperCaseFirst(
                                                            t.name.toLocaleLowerCase()
                                                        )}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <button
                                                    className="btn-primary"
                                                    onClick={() => setDialogOpen(t)}
                                                >
                                                    Add
                                                </button>
                                            </td>
                                            <td />
                                        </tr>
                                    )
                                }
                                <tr>
                                    <td />
                                    <td>
                                        <button
                                            className="btn-primary"
                                            onClick={() =>
                                                setDialogOpen(
                                                    reviewTypes.find((t) => t.name === 'OTHER')
                                                )
                                            }
                                        >
                                            + Add Other Platform
                                        </button>
                                    </td>
                                    <td />
                                    <td />
                                </tr>
                            </tbody>
                        </table>
                        <Dialog
                            className="Integrations__dialog"
                            open={dialogOpen}
                            title={SITE_DIALOG[dialogOpen?.name]?.title}
                            dontCloseOnOutsideClick={reviewSitesStore.statusDialog === 'loading'}
                            onClose={() => closeDialog()}
                        >
                            <Loader loading={reviewSitesStore.statusDialog === 'loading'} />
                            {reviewSitesStore.pages.length === 0 ? (
                                <div>
                                    <p>{SITE_DIALOG[dialogOpen?.name]?.content}</p>
                                    {SITE_DIALOG[dialogOpen?.name]?.note && (
                                        <p>{SITE_DIALOG[dialogOpen?.name]?.note}</p>
                                    )}
                                    {dialogOpen?.name !== 'OTHER' ? (
                                        <div></div>
                                    ) : (
                                        <>
                                            <div>
                                                <FormField label="Name" htmlFor="name">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className="form-input"
                                                        value={dialogName}
                                                        onChange={(e) => setDialogName(e.target.value)}
                                                    />
                                                </FormField>
                                            </div>
                                            <div>
                                                <FormField label="URL" htmlFor="url">
                                                    <input
                                                        type="text"
                                                        name="url"
                                                        className="form-input"
                                                        value={dialogInput}
                                                        onChange={(e) => setDialogInput(e.target.value)}
                                                    />
                                                </FormField>
                                            </div>
                                        </>
                                    )}
                                    <div className="Integrations__dialogButtons">
                                        <button className="btn-secondary" onClick={() => closeDialog()}>
                                            Cancel
                                        </button>
                                        <button
                                            className={classNames(
                                                'Integrations__dialogButtons-save btn-primary',
                                                {
                                                    disabled: false,
                                                }
                                            )}
                                            disabled={false}
                                            onClick={() => save()}
                                        >
                                            {SITE_DIALOG[dialogOpen?.name]?.saveMsg}
                                        </button>
                                        <div className="g-signin2" data-onsuccess="onSignIn"></div>
                                    </div>
                                </div>
                            ) : (
                                <div className="Integrations__dialogCards">
                                    {reviewSitesStore.pages.map((p) => {
                                        return (
                                            <div
                                                key={uuidv4()}
                                                onClick={() => selectPage(p)}
                                                className="Integrations__dialogCard"
                                            >
                                                <div className="Integrations__dialogCardImage">
                                                    <CheckGrey className="svg" />
                                                </div>
                                                <h4 className="Integrations__dialogCardTitle">
                                                    {p.locationName}
                                                </h4>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </Dialog>
                    </>
                )}
            </div>
            {userStore.hasFeatureFlag("integrations") &&
                <div className="Integrations__subsection">
                    <div className="Integrations__title">Customer Integrations</div>
                    <div className="Integrations__subtitle">
                        Integrate with CRMs to enable automatic synchronization of customers.
                    </div>
                    {integrationsStore.dataLoaded && (
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Platform</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {integrationSources
                                    .filter(source => source.status == "Connected")
                                    .map(source => {
                                        return <tr key={uuidv4()}>
                                                <td>
                                                    { SITES_IMAGES[source.source] ? (
                                                        <div className="Integrations__site">
                                                            <img
                                                                className="Integrations__site-img"
                                                                src={SITES_IMAGES[source.source]}
                                                                alt={source.source}
                                                            />
                                                            <span className="Integrations__site-title">
                                                                {toUpperCaseFirst(
                                                                    source.source.toLocaleLowerCase()
                                                                )}
                                                            </span>
                                                        </div>
                                                    ) : <span className="Integrations__site-title">
                                                                {toUpperCaseFirst(
                                                                    source.source.toLocaleLowerCase()
                                                                )}
                                                        </span> }
                                                </td>
                                                <td>
                                                    <button
                                                        className="btn-secondary"
                                                        onClick={() => integrationsStore.removeIntegration(source.id)}
                                                    >
                                                        Remove
                                                    </button>
                                                </td>
                                            </tr>;
                                    }
                                )}
                                {integrationTypes
                                    .filter(type => integrationSources.every(source => source.source !== type.name))
                                    .map(type => {
                                        return (
                                            <tr key={uuidv4()}>
                                                <td>
                                                    <div className="Integrations__site">
                                                        <img
                                                            className="Integrations__site-img"
                                                            src={SITES_IMAGES[type.name]}
                                                            alt={type.name}
                                                        />
                                                        <span className="Integrations__site-title">
                                                            {toUpperCaseFirst(
                                                                type.name.toLocaleLowerCase()
                                                            )}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button
                                                        className="btn-primary"
                                                        onClick={() => handleAddIntegration(type)}
                                                    >
                                                        Add
                                                    </button>
                                                </td>
                                                <td />
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </>
                    ) }
                </div>
            }
        </div>
    );
};

export const Integrations = withStores(IntegrationsWithStore, [STORES.ReviewSites, STORES.Integrations, STORES.User]);
