import React from 'react';
import './PageHeader.sass';
import PageMainHeader from './PageMainHeader';

interface PageHeaderProps {
    asideHeader?: React.ReactNode;
    isLoading: boolean;
    title: string;
}

const PageHeader: React.FunctionComponent<PageHeaderProps> = ({asideHeader, isLoading, title }) => {
    return (
        <div className="PageHeader">
            <PageMainHeader
                isLoading={isLoading}
                title={title}
            />
            {!!asideHeader &&
                <aside className="PageHeader__aside">
                    {asideHeader}
                </aside>
            }
        </div>
    );
};

export default PageHeader;
