import React from 'react';
import { toast } from 'react-toastify';
import { images } from '@src/theme/images';
import { Link } from 'react-router-dom';
import { STORES, WithAuthStoreProps, withStores } from '@src/stores/with-store';

import '../Auth.sass';

interface LogoutBeforeContentProps {
    errorMsg?: string;
    title: string;
    subtitle: string;
}

export const LogoutBeforeContentWithStore: React.FC<LogoutBeforeContentProps & WithAuthStoreProps> = ({errorMsg, title, subtitle, AuthStore}) => {
    const handleLogoutClick = () => {
        AuthStore.logout()
        .catch(() => {
            toast.error(errorMsg ?? "Logout failed. Please check your internet connection, or try again later.")
        });
    };

    return (
        <div className="Auth">
            <div className="AuthLogo">
                <img src={images.logo} className="AuthLogo-image" alt="app-logo" />
            </div>
            <div className="AuthContainer short">
                <h1 className="AuthContainer__header">{title}</h1>
                <h4>{subtitle}</h4>
                <h4>
                    If it&apos;s your account which is logged in, you can{' '}
                    <Link to={'/dashboard'}>go to dashboard</Link>.
                </h4>
                <h4>
                    If you would like to activate your account, please{' '}
                    <button onClick={handleLogoutClick} className="AuthContainer__logout">
                        log out
                    </button>{' '}
                    first.
                </h4>
            </div>
        </div>
    );
};

export const LogoutBeforeContent = withStores(LogoutBeforeContentWithStore, [STORES.Auth]);
