import { action, observable, runInAction } from 'mobx';
import {
    userSites,
    deleteSite,
    activateSite,
    deactivateSite,
    addReviewsSite,
    fbConnect,
    orderSite,
    fbAuthorized,
    googleAuthorized,
    updateSite,
    googleConnect,
} from '@src/requests/links.requests';
import AuthStore from '@src/stores/auth.store';

interface ReviewType {
    value: number;
    name: string;
    canSolicit: boolean;
}

interface ReviewSite {
    id: string;
    locationId: string;
    site: ReviewType;
    siteId: string;
    order: number;
    active: boolean;
    created: Date;
    updated: Date;
    siteName: string;
    reviewSource: {
        status: string;
    };
}

class PageOrLocation {
    id: string;
    locationName: string;
    placeId: string;
    constructor(id, locationName, placeId) {
        this.id = id;
        this.locationName = locationName;
        this.placeId = placeId;
    }
}

declare const window: any;
export class ReviewSitesStore {
    @observable reviewTypes: ReviewType[] = [];
    @observable reviewSites: ReviewSite[] = [];
    @observable status = 'init';
    @observable statusDialog = 'init';
    @observable errorStatus: object = null;
    @observable pages: PageOrLocation[] = [];
    @observable dataLoaded = false;
    @observable edition = false;
    onError?: (type: string, id?: string) => void;
    onSuccess?: () => void;

    @action destroy() {
        this.reviewTypes = [];
        this.reviewSites = [];
        this.status = 'init';
        this.statusDialog = 'init';
        this.errorStatus = null;
        this.pages = [];
        this.dataLoaded = false;
        this.edition = false;
    }

    @action init() {
        this.reviewTypes = [];
        this.reviewSites = [];
        this.errorStatus = null;
        this.pages = [];
        this.dataLoaded = false;
        this.initData();
    }

    @action initData() {
        this.status = 'loading';
        userSites().then(
            ({ data }) => {
                const { reviewSites, reviewTypes } = data.data;
                runInAction(() => {
                    this.reviewSites = reviewSites;
                    this.reviewTypes = reviewTypes;
                    this.dataLoaded = true;
                    this.status = 'loaded';
                    this.statusDialog = 'loaded';
                });
            },
            (err) => {
                if (err.response?.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    runInAction(() => {
                        this.status = 'errorInit';
                        this.statusDialog = 'errorInit';
                        this.errorStatus = err;
                    });
                    if (this.onError) {
                        this.onError('getBE');
                    };
                };
            }
        );
    }

    saveError = () => {
        runInAction(() => {
            this.onError('siteAuth');
            this.status = 'loaded';
            this.statusDialog = 'loaded';
        });
    };

    _gAuthorize = () => {
        if (window._env_.GOOGLE_CLIENT_ID) {
            window.gapi.auth2.authorize(
                {
                    client_id: window._env_.GOOGLE_CLIENT_ID,
                    scope: 'https://www.googleapis.com/auth/plus.business.manage',
                    response_type: 'code',
                    include_granted_scopes: true,
                    access_type: 'offline',
                    prompt: 'consent',
                },
                (callback) => {
                    console.log(JSON.stringify(callback));
                    if (!callback.error) {
                        googleAuthorized(callback.code).then(
                            ({ data }) => {
                                this.pages = data.locations.map(
                                    (location) =>
                                        new PageOrLocation(
                                            location.name,
                                            location.title,
                                            location.metadata.placeId
                                        )
                                );
                                this.statusDialog = 'loaded';
                            },
                            () => this.saveError()
                        );
                    } else {
                        this.onError('siteAuth');
                        this.statusDialog = 'loaded';
                    }
                }
            );
        } else {
            this.onError('siteAuth');
            this.statusDialog = 'loaded';
        }
    };

    _fbAuthorize = () => {
        window.FB.login(
            (response) => {
                if (response.authResponse) {
                    fbAuthorized(response.authResponse.accessToken).then(
                        ({ data }) => {
                            this.pages = data.locations.map(
                                (location) => new PageOrLocation(location.id, location.name, '')
                            );
                            this.statusDialog = 'loaded';
                        },
                        () => this.saveError()
                    );
                } else {
                    // this.onError('siteAuth'); // it's not error
                    this.statusDialog = 'loaded';
                }
            },
            {
                scope:
                    'pages_read_user_content,pages_read_engagement,pages_manage_engagement,pages_manage_metadata,pages_messaging',
            }
        );
    };

    saveSite = (site) => {
        if (site.site === 0) this._gAuthorize();
        else if (site.site === 1) this._fbAuthorize();
        else addReviewsSite(site).then(
                () => {
                    this.statusDialog = 'loaded';
                    this.initData();
                },
                () => this.saveError()
            );
    };

    @action addSite(site) {
        this.statusDialog = 'loading';
        this.saveSite(site);
    }

    @action editSite(site) {
        this.statusDialog = 'loading';
        updateSite(site).then(
            () => this.saveSite(site),
            () => this.saveError()
        );
    }

    @action _fbConnect(page) {
        this.status = 'loading';
        this.statusDialog = 'loading';
        return fbConnect(page.id).then(
            () => {
                this.initData();
                this.pages = [];
            },
            () => this.saveError()
        );
    }

    @action _googleConnect(location) {
        this.status = 'loading';
        this.statusDialog = 'loading';
        return googleConnect(location.id, location.placeId).then(
            () => {
                this.initData();
                this.pages = [];
            },
            () => this.saveError()
        );
    }

    @action removeSite(id) {
        this.status = 'loading';
        deleteSite(id).then(
            () => this.initData(),
            () => this.saveError()
        );
    }

    @action activateSite(id: string) {
        this.status = 'loading';
        activateSite(id).then(
            () => {
                this.initData();
                if (this.onSuccess) {
                    this.onSuccess();
                }
            },
            (err) => {
                runInAction(() => {
                    this.status = 'errorActivate';
                    this.errorStatus = err;
                    if (this.onError) {
                        this.onError('activateSite', id);
                    }
                });
            }
        );
    }

    @action deactivateSite(id: string) {
        this.status = 'loading';
        deactivateSite(id).then(
            () => {
                this.initData();
                if (this.onSuccess) {
                    this.onSuccess();
                }
            },
            (err) => {
                runInAction(() => {
                    this.status = 'errorDectivate';
                    this.errorStatus = err;
                    if (this.onError) {
                        this.onError('deactivateSite', id);
                    }
                });
            }
        );
    }

    @action changeOrder(ids) {
        this.status = 'loading';
        orderSite(ids).then(
            () => this.initData(),
            () => this.saveError()
        );
    }

    @action setEdition(val: boolean): void {
        this.edition = val;
    }
}

export default new ReviewSitesStore();
