import React, { useLayoutEffect } from 'react';
import { toast } from 'react-toastify';
import { EmailAddresses } from './EmailAddresses';
import { NamesAndPhone } from './NamesAndPhone';
import { onSuccess } from '@src/theme/utils/constants';
import { withPersonalSettingsStore, WithPersonalSettingsStoreProps } from '@src/stores/with-store';
import { LoadingState, OnErrorDataPersonalSettings } from '@src/stores/models';
import { NamesAndPhoneInterface, EmailsInterface } from './models';
import { Loader, ErrorToastSave } from '@src/components';
import UserStore from '@src/stores/user.store';
import { convertToPureNumber } from '@src/theme/utils/helpers';

import './personal-info.sass';

export const PersonalInfoWithStore: React.FC<WithPersonalSettingsStoreProps> = (props) => {
    const store = props.PersonalSettingsStore;
    const loading = store.loadingState;
    const user = UserStore.user.employee;

    const onSuccessEmailChanged = () => {
        toast.success(
            'Your email address has been changed succesfully. Authenticatation with the new email address may be needed.',
            {
                autoClose: 5000,
                position: 'top-center',
                hideProgressBar: false,
            }
        );
        setTimeout(() => {
            location.reload();
        }, 5000);
    };

    store.onError = (type: string, data?: OnErrorDataPersonalSettings) => {
        store.loadingState = LoadingState.Error;
        switch (type) {
            case 'updateName': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.updateTeamMemberName(
                                user?.id,
                                data.firstName,
                                data.lastName,
                                data.phone,
                                'updateName'
                            )
                        }
                    />
                );
                break;
            }
            case 'updateEmail': {
                toast.error(
                    <ErrorToastSave
                        repeatUploading={() =>
                            store.updateTeamMemberEmail(
                                user?.id,
                                data.email,
                                data.confirmEmail,
                                'updateEmail'
                            )
                        }
                    />
                );
                break;
            }
            default: {
                return;
            }
        }
    };
    store.onSuccess = () => {
        store.loadingState = LoadingState.Loaded;
        onSuccess();
    };
    store.onSuccessEmailChanged = () => {
        store.loadingState = LoadingState.Loaded;
        onSuccessEmailChanged();
    };

    const updatePersonalInfo = (data: NamesAndPhoneInterface) => {
        store.updateTeamMemberName(
            user?.id,
            data.firstName,
            data.lastName,
            convertToPureNumber(data.number),
            'updateName'
        );
    };

    const updateEmail = (data: EmailsInterface) => {
        if (data.touched.find((t) => t === 'email')) {
            store.updateTeamMemberEmail(
                user?.id,
                data.email,
                data.confirmEmail,
                'updateEmail'
            );
        } else {
            toast.success('Given email address had already been saved.', { autoClose: 4000 });
        }
    };

    useLayoutEffect(() => {
        store.init();
    }, []);

    return (
        <div className="PersonalInfo">
            <Loader loadingState={loading} />
            <>
                <>
                    <div className="PersonalInfo__title editPersonalInfo">
                        Edit Personal Info
                    </div>
                    <>
                        <NamesAndPhone
                            id={user?.id}
                            firstName={user?.firstName}
                            lastName={user?.lastName}
                            phone={user?.phone}
                            updateData={(data) => updatePersonalInfo(data)}
                        />
                        {!UserStore.user.employeeFromExternalAuth &&
                            <>
                                <div className="PersonalInfo__title editEmail">Edit Email</div>
                                <div className="PersonalInfo__subtitle">
                                    You will be logged out and will need to authenticate with the
                                    new email address
                                </div>
                                <EmailAddresses
                                    id={user?.id}
                                    email={user?.email}
                                    updateEmail={(data) => updateEmail(data)}
                                />
                            </>
                        }
                    </>
                </>
            </>
        </div>
    );
};

export const PersonalInfo = withPersonalSettingsStore(PersonalInfoWithStore);
