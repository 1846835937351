import { action, observable, runInAction } from 'mobx';
import { getProfileInfo } from '@src/requests';
import { Profile, LoadingState } from './models';
import AuthStore from '@src/stores/auth.store';

export class ProfileStore {
    @observable loading: LoadingState = LoadingState.Init;
    @observable profile: Profile = {};

    @action init(): void {
        this.profile = {};
        this.loading = LoadingState.Init;
    }

    @action getProfileInfo(onError?: (message) => void) {
        this.loading = LoadingState.Loading;
        return getProfileInfo().then(
            (res) => {
                runInAction(() => {
                    this.profile = res.data;
                    this.loading = LoadingState.Loaded;
                });
            },
            (err) => {
                if (err.response?.status === 401) {
                    AuthStore.resetAuth();
                } else {
                    runInAction(() => {
                        this.loading = LoadingState.Error;
                    });
                    if (onError) {
                        onError(err.message);
                    };
                }
            }
        );
    }
}

export default new ProfileStore();
