import { BroadcastStatus } from '@src/stores/models/broadcast_new';
import React from 'react';
import './DetailsCard.sass';

interface CardFooterProps {
    status: BroadcastStatus;
    onDeleteDraftClick: () => void;
    onEditBroadcastClick: () => void;
}

const CardFooter: React.FunctionComponent<CardFooterProps> = ({ status, onDeleteDraftClick, onEditBroadcastClick }) => (
    <>
        {status == 'SCHEDULED' &&
            <footer className="Broadcasts-details-card__card-footer">
                <button className="red" onClick={onDeleteDraftClick}>Delete Broadcast</button>
                <button className="blue" onClick={onEditBroadcastClick}>Edit Broadcast</button>
            </footer>
        }
    </>
)

export default CardFooter;
