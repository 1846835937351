import helloFirstname from './helloFirstname.png';
import helloJack from './helloJack.png';
import helloMike from './helloMike.png';
import hello from './hello.png';
import broadcast from './broadcast.png';
import broadcast1 from './broadcast1.png';


export const images: { [key: string]: string } = {
    helloFirstname,
    helloJack,
    helloMike,
    hello,
    broadcast,
    broadcast1
};
