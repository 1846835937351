import { action, observable, runInAction } from 'mobx';
import {
    getHintPopupSteps,
    markHintPopupStepAsRead,
    hideAllHintPopups
} from '@src/requests';
import { firstPopupsStepsCompletedElBeforeBEData } from '@src/theme/utils/constants';


export class WalkthroughStore {
    @observable hidePopups = false;
    @observable popupsStepsCompleted = [firstPopupsStepsCompletedElBeforeBEData];
    @observable showWalkthrough = false;

    @action init() {
        this.hidePopups = false;
        this.popupsStepsCompleted = [firstPopupsStepsCompletedElBeforeBEData];
        this.showWalkthrough = false;
    }

    @action setPopupStepsCompleted = (stepsArr): void => {
        this.popupsStepsCompleted = stepsArr;
    };

    @action setShowWalkthrough = (val: boolean): void => {
        this.showWalkthrough = val;
    };

    @action getAndSetHintPopupSteps = () => {
        return getHintPopupSteps().then(({ data }) => {
            runInAction(() => {
                this.hidePopups = data.hidePopups;
                this.popupsStepsCompleted = data.completed;
            });
        });
    };

    @action markHintPopupStepAsRead = (step: number) => {
        return markHintPopupStepAsRead(step);
    };

    @action hideAllHintPopups = () => {
        hideAllHintPopups();
    };
}
export default new WalkthroughStore();
