import { observable } from 'mobx';
import { LoadingState } from '@src/stores/models';

export abstract class BaseStore {
    @observable loading: LoadingState;

    constructor() {
        this.init();
    }

    abstract init(): void;
}
