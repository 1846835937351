import view1 from './1.png';
import view2 from './2.png';
import view3 from './3.png';
import view4 from './4.png';
import view5 from './5.png';

export const images: { [key: string]: string } = {
    view1,
    view2,
    view3,
    view4,
    view5
};
