import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from 'react-stripe-elements';
import ReactTooltip from 'react-tooltip';
import { STORES, withStores, WithBillingStoreProps, WithAuthStoreProps, WithUserStoreProps, WithSignupStoreProps, WithCouponStoreProps } from '@src/stores/with-store';
import { FormField, Loader, SignupSelect } from '@src/components';
import { Address as AddressModel, countriesToSelect, statesOfUS, provincesOfCanada, SingleBillingPlan } from '@src/stores/models';
import { SignupForm } from '@src/stores/models/signup';
import { displayIntegerOrTwoDecimalPlaces } from '@src/theme/utils/helpers';
import { trackGoogleEvent, SignupAnalyticsEvents, setupLinkedinPixel, trackFacebookStandardEvent } from '@src/theme/utils/Analytics';
import { ERROR_TOAST_AUTOCLOSE, gtagConversionSendTo } from '@src/theme/utils/constants';
import { ReactComponent as InfoIcon } from '@src/theme/icons/icon-info.svg';

export interface FormData {
    address: AddressModel;
};

const ccFieldsStyling = {fontSize: '16px', color: '#232554', '::placeholder': {color: '#697386'}};

interface AddressProps {
    previousData?: SignupForm;
    stripe: any;
    user?: {companyInfo: object};
    phone?: any;
    selectedPlan?: SingleBillingPlan;
    oldDataToIncludeWhileAddingAddress: any;

};
type props = AddressProps & WithBillingStoreProps & WithAuthStoreProps & WithUserStoreProps & WithSignupStoreProps & WithCouponStoreProps;

const AddressWithStore: React.FunctionComponent<props> = (props) => {
    const [ loading, setLoading ] = useState(false);
    const [ addressData, setAddressData ] = useState({
        street: props.previousData?.address?.street,
        streetTwo: props.previousData?.address?.streetTwo,
        postalCode: props.previousData?.address?.postalCode,
        city: props.previousData?.address?.city,
        state: props.previousData?.address?.state,
        country: props.previousData?.address?.country,
    });
    const [ ccData, setCcData ] = useState({
        cardNumber: undefined,
        cardExpiry: undefined,
        cardCvc: undefined,
    });
    const history = useHistory();

    const arrays = [countriesToSelect, statesOfUS, provincesOfCanada];
    const convertedArrays = arrays.map(arr => Object.keys(arr).map((el) => {
        return {
            label: arr[el],
            value: el,
        };
    }));
    const countries = convertedArrays[arrays.indexOf(countriesToSelect)];
    const statesUS = convertedArrays[arrays.indexOf(statesOfUS)];
    const provincesCA = convertedArrays[arrays.indexOf(provincesOfCanada)];

    const setAddressElement = (key: string, value: string | boolean) => {
        const newAddress = {...addressData, [key]: value};
        setAddressData(newAddress);
    };

    const setCountry = (value: string) => {
        let newAddress;
        if ((!addressData.country && value === 'CA') || (addressData.country && value !== addressData.country)) {
            newAddress = {...addressData, state: undefined, country: value};
        } else {
            newAddress = {...addressData, country: value};
        };
        setAddressData(newAddress);
    };

    const setCCDetails = (key: string, details) => {
        if (details.empty) {
            setCcData({...ccData, [key]: false});
        } else {
            setCcData({...ccData, [key]: true});
        };
    };

    const addAddressDataAndSetPayment = (ev) => {
        ev.preventDefault();
        setLoading(true);
        props.stripe.createSource({ type: 'card' })
        .then((res) => {
            if (res.error) {
                toast.error(res.error.message, ERROR_TOAST_AUTOCLOSE);
            } else {
                const requests: any[] = [
                    props.SignupStore.setBillingForm(
                        {
                            priceId: props.selectedPlan?.price.id,
                            stripeToken: res.source.id,
                        },
                        {...props.user.companyInfo, buyNow: true, smsNumber: props.phone }
                    ),
                    props.SignupStore.createOrUpdateAccount(
                        {
                            ...props.oldDataToIncludeWhileAddingAddress,
                            address: {
                                ...props.oldDataToIncludeWhileAddingAddress.addres,
                                ...addressData
                            }
                        }, true
                    )
                ];

                Promise.all(requests).finally(
                    () => {
                        props.AuthStore.loadAuthLogin().then(() => {
                            if (props.UserStore.user.accountStatus !== 'INCOMPLETE') {
                                history.push('/dashboard');
                                trackGoogleEvent(SignupAnalyticsEvents.Payment, gtagConversionSendTo);
                                trackFacebookStandardEvent('Subscribe', {value: props.selectedPlan?.price?.amount, currency: 'USD', predicted_ltv: props.selectedPlan?.price?.amount});
                                setupLinkedinPixel();
                            } else {
                                toast.error('Something went wrong. Please try again.', ERROR_TOAST_AUTOCLOSE);
                            }
                            props.UserStore.setSignupUser(null);
                        });
                    }
                ), () => {
                    toast.error('Something went wrong. Please try again.', ERROR_TOAST_AUTOCLOSE);
                };
            }
        })
        .finally(() => setLoading(false));
    };

    return (
        <form
            className='PaymentInsideStripe__form'
            onSubmit={addAddressDataAndSetPayment}
        >
            <Loader loading={loading} />
            <div className="form-content">
                <div className="PaymentInsideStripe__form-input">
                    <label className="PaymentInsideStripe__form-input-card">
                        Credit Card
                        <CardNumberElement style={{ base: ccFieldsStyling }} onChange={(e) => setCCDetails('cardNumber', e)} />
                    </label>
                    <label className="PaymentInsideStripe__form-input-exp">
                        Exp
                        <CardExpiryElement style={{ base: ccFieldsStyling }} onChange={(e) => setCCDetails('cardExpiry', e)} />
                    </label>
                    <label className="PaymentInsideStripe__form-input-cvc">
                        CVC
                        <CardCvcElement style={{ base: ccFieldsStyling }} onChange={(e) => setCCDetails('cardCvc', e)} />
                    </label>
                </div>
                <div className="infoWrapper">
                    <FormField label="Billing Address" htmlFor="street">
                        <input
                            type="text"
                            name="street"
                            placeholder="Address"
                            defaultValue={props.previousData?.address?.street}
                            className='form-input'
                            onChange={(e) => setAddressElement('street', e.target.value)}
                        />
                    </FormField>
                    {(props.previousData?.address && Object.values(props.previousData.address).filter(el => el).length > 1) &&
                        <div className="infoIcon">
                            <ReactTooltip/>
                            <InfoIcon data-tip={'Address data filled based on your company'} />
                        </div>
                    }
                </div>
                <FormField label="Apt, suite, etc." htmlFor="streetTwo">
                    <input
                        type="text"
                        name="streetTwo"
                        placeholder="Apt, suite, etc. (optional)"
                        defaultValue={props.previousData?.address?.streetTwo}
                        className='form-input'
                        onChange={(e) => setAddressElement('streetTwo', e.target.value)}
                    />
                </FormField>
                <div className="form-row">
                    <FormField label="City" htmlFor="city">
                        <input
                            type="text"
                            name="city"
                            placeholder="City"
                            defaultValue={props.previousData?.address?.city}
                            className='form-input'
                            onChange={(e) => setAddressElement('city', e.target.value)}
                        />
                    </FormField>
                    <FormField key={'state' + addressData.country} label={addressData.country === 'CA' ? 'Province' : 'State'} htmlFor="state">
                        <SignupSelect
                            data-test="state"
                            key={addressData.state || props.previousData?.address?.state || '1'}
                            options={addressData.country === 'CA' ? provincesCA : statesUS}
                            selectedOptionValue={addressData.state}
                            placeholder={addressData.country === 'CA' ? 'Province' : 'State'}
                            onChange={(e) => setAddressElement('state', e.value as statesOfUS)}
                            classname="onRight"
                        />
                    </FormField>
                </div>
                <div className="form-row">
                    <FormField label="Zipcode" htmlFor="postalCode">
                        <input
                            type="text"
                            name="postalCode"
                            placeholder={addressData.country === 'CA' ? 'K2C3G1' : '12345'}
                            defaultValue={props.previousData?.address?.postalCode}
                            className='form-input'
                            onChange={(e) => setAddressElement('postalCode', e.target.value)}
                        />
                    </FormField>
                    <FormField label="Country" htmlFor="country">
                        <SignupSelect
                            data-test="country"
                            options={countries}
                            selectedOptionValue={addressData.country}
                            placeholder="Country"
                            onChange={(e) => setCountry(e.value)}
                            classname="onLeft"
                        />
                    </FormField>
                </div>
                <div className="PaymentInsideStripe__form-dueToday">
                    ${displayIntegerOrTwoDecimalPlaces(props.selectedPlan?.price.amount)} Due today
                </div>
                <div className="PaymentInsideStripe__form-actions">
                    <input
                        key="nextfrompayment"
                        type="submit"
                        className="btn-primary-full nextfrompayment"
                        value="Confirm"
                        disabled={
                            !addressData.streetTwo && Object.values({...addressData, ...ccData}).filter(el => el).length < 8
                            || addressData.streetTwo && Object.values({...addressData, ...ccData}).filter(el => el).length < 9
                        }
                    />
                </div>
            </div>
        </form>
    );
};
export const Address = injectStripe(
    withStores(AddressWithStore, [STORES.Billing, STORES.Auth, STORES.User, STORES.Signup, STORES.Coupon])
);