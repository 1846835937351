import { differenceInSeconds } from 'date-fns';
import React, { useEffect, useState } from 'react';

import './Countdown.sass';

interface TimeLeft {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
};

const calculateTimeLeft = (totalSeconds: number): TimeLeft => {
    return {
        days: totalSeconds > 0 ? Math.floor(totalSeconds / (60 * 60 * 24)) : 0,
        hours: totalSeconds > 0 ? Math.floor((totalSeconds / (60 * 60)) % 24) : 0,
        minutes: totalSeconds > 0 ? Math.floor((totalSeconds / 60) % 60) : 0,
        seconds: totalSeconds > 0 ? Math.floor((totalSeconds) % 60) : 0
    };
}

interface CountdownProps {
    expires: Date;
};

const Countdown: React.FunctionComponent<CountdownProps> = ({ expires }) => {
    const [remainingSeconds, setRemaingSeconds] = useState(differenceInSeconds(expires, Date.now()));
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(remainingSeconds));

    useEffect(() => {
        if (remainingSeconds >= 0) {
            setTimeLeft(calculateTimeLeft(remainingSeconds));
            const timer=setTimeout(() => {
                setRemaingSeconds(differenceInSeconds(expires, Date.now()));
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [remainingSeconds]);

    return (
        <div className="countdown-container">
            <div className="countdown-el days">
                <p className="big-text" id="days">{timeLeft.days}</p>
                <span>days</span>
            </div>
            <div className="countdown-el hours">
                <p className="big-text" id="hours">{timeLeft.hours}</p>
                <span>hours</span>
            </div>
            <div className="countdown-el mins">
                <p className="big-text" id="mins">{timeLeft.minutes}</p>
                <span>mins</span>
            </div>
            <div className="countdown-el seconds">
                <p className="big-text" id="seconds">{timeLeft.seconds}</p>
                <span>seconds</span>
            </div>
        </div>
    );
};

export default Countdown;
