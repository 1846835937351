import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useLocation, useParams, useHistory } from "react-router-dom";
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { FilterBar } from '@src/components/FilterBar/FilterBar';
import { Menu } from './Menu/Menu';
import { UpgradeToUseBroadcasts } from './UpgradeToUseBroadcasts';
import ExistingBrodcasts from './ExistingBroadcasts';
import NewBroadcast from './NewBroadcast';
import { ConsentPopup } from './ConsentPopup';
import { STORES, WithBroadcastsStoreProps, WithUserStoreProps, withStores } from '@src/stores/with-store';
import { Broadcast, BroadcastStatus } from '@src/stores/models/broadcast_new';
import { ERROR_TOAST_AUTOCLOSE } from '@src/theme/utils/constants';
import './Broadcasts.sass';

const STATUS_TO_URL = {
    "SCHEDULED": "scheduled",
    "RUNNING": "running",
    "COMPLETED": "completed",
    // "CREATE": "new",
    "CANCELLED": "cancelled"
}
const URL_TO_STATUS = {
    "scheduled": "SCHEDULED",
    "running": "RUNNING",
    "completed": "COMPLETED",
    // "new": "CREATE",
    "cancelled": "CANCELLED"
}

export const locationToBroadcastStatus = (locationPath: string): BroadcastStatus => {
    const splittedPath: string[] = locationPath.split('/');
    const lastPath = splittedPath[splittedPath.length - 1];
    return URL_TO_STATUS[lastPath]
}

type BroadcastsProps = WithBroadcastsStoreProps & WithUserStoreProps;

const BroadcastsWithStores: React.FunctionComponent<BroadcastsProps> = (props) => {
    const [expandedFilterBar, setExpandedFilterBar] = useState(true);
    const [editedBroadcast, setEditedBroadcast] = useState<Broadcast>(null);

    const {broadcastId} = useParams();
    const history = useHistory();
    const location = useLocation();

    props.BroadcastsStore.onError = (message) => toast.error(message, ERROR_TOAST_AUTOCLOSE);

    useEffect(()=> {
        if(broadcastId){
            const nextStatus = URL_TO_STATUS[broadcastId];
            if(nextStatus) {
                props.BroadcastsStore.setStatus(nextStatus)
                setExpandedFilterBar(true)
            } else {
                //TODO
            }
        }
    }, [broadcastId]);

    useEffect(()=> {
        if (props.BroadcastsStore.changeUrlToScheduled > 0) {
            props.BroadcastsStore.setStatus('SCHEDULED');
            history.push(`/broadcasts/scheduled`)
        };
    }, [props.BroadcastsStore.changeUrlToScheduled]);

    const initialSettings = () => {
        const status = locationToBroadcastStatus(location.pathname) || 'SCHEDULED';
        props.BroadcastsStore.init(status);
        history.push(`/broadcasts/${STATUS_TO_URL[status]}`);
        props.BroadcastsStore.setChangeUrlToScheduled(0);
        props.BroadcastsStore.checkConsent();
    };

    useEffect(() => {
        initialSettings();
    }, []);

    const handleMenuItemClick = (value: BroadcastStatus) => {
        if(value) {
            props.BroadcastsStore.setStatus(value);
            history.push(`/broadcasts/${STATUS_TO_URL[value]}`)
        }
    };

    const handleNewBroadcastClick = (params) => {
        props.BroadcastsStore.setStatus('CREATE');
        history.push(`/broadcasts/new`, params)
    };

    const handleEditBroadcastClick = (broadcast: Broadcast) => {
        props.BroadcastsStore.setStatus('EDIT');
        setEditedBroadcast(broadcast);
        history.push(`/broadcasts/${broadcast.id}`);
    };

    const handlePublish = () => {
        props.BroadcastsStore.setStatus('RUNNING');
        history.push(`/broadcasts/running`)
    };

    const renderView =
        !props.UserStore.user.isAllowedToUseBroadcast ?
            <UpgradeToUseBroadcasts userRole={props.UserStore.user.role} />
        : ['CREATE', 'EDIT'].includes(props.BroadcastsStore.currentStatus) ?
            <NewBroadcast
                broadcast={editedBroadcast}
                onPublish={handlePublish}
            />
            : <ExistingBrodcasts
                filterBarExpanded={expandedFilterBar}
                onNewBroadcastClick={handleNewBroadcastClick}
                onEditBroadcastClick={handleEditBroadcastClick}
            />;

    useEffect(() => {
        if (props.BroadcastsStore.currentStatus !== 'EDIT') {
            setEditedBroadcast(null);
        };
    }, [props.BroadcastsStore.currentStatus]);

    return (
        <div className={classNames('Broadcasts', {notAllowedToUseBroadcasts: !props.UserStore.user.isAllowedToUseBroadcast})}>
            {props.UserStore.user.isAllowedToUseBroadcast ?
                <FilterBar expanded={expandedFilterBar} expandedChange={(val) => setExpandedFilterBar(val)}>
                    <Menu broadcastStatus={props.BroadcastsStore.currentStatus} onItemClick={handleMenuItemClick}/>
                </FilterBar>
                : null
            }
            {renderView}
            {(!props.BroadcastsStore.consentGranted && props.UserStore.user.isAllowedToUseBroadcast) &&
                <ConsentPopup onGrantConsent={() => props.BroadcastsStore.grantConsent()}
            />}
        </div>
    );
};

export const Broadcasts = withStores(BroadcastsWithStores, [
    STORES.BroadcastsStore,
    STORES.User
]);
