import React from 'react';
import AntsPng from './ants.png';

import './customers-table.sass';

interface EmptyTableProps {
    isSearchResult: boolean;
};

const CustomersEmptyTable: React.FunctionComponent<EmptyTableProps> = ({ isSearchResult }) => {
    return (
        <div className="CustomersTable__empty">
            <div className="CustomersTable__empty__no-results">
                { isSearchResult && <h2>No Search Results</h2>}
            </div>
            <div className="CustomersTable__empty__image-container">
                <img alt="add customers" src={AntsPng}/>
            </div>
            <h2>Upload your customers, get texting </h2>
            <p>Customers are <span>4 times</span> more likely to respond to a text message than call back to a voicemail.</p>
        </div>
    );
};

export default CustomersEmptyTable;
