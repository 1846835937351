import React from 'react';
import { Loader } from '@src/components/Loader/Loader';
import { STORES, WithSignupStoreProps, withStores } from '@src/stores/with-store';
import Header from './Header';
import SignUpAside from './Aside/Aside';
import { signup2Pages } from '@src/pages/pages';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import './Signup.sass';

type SignUpProps = WithSignupStoreProps;

const SignUpWithStores: React.FunctionComponent<SignUpProps> = ({ SignupStore }) => {
    const { path } = useRouteMatch();

    return (
        <div className="Signup">
            <Loader loadingState={SignupStore.loadingState} />
            <Header/>
            <div className="Signup__content">
                <Switch>
                    {signup2Pages.map((page, i) => (
                        <Route
                            key={i}
                            exact={!!page.exact}
                            path={`${path}${page.path}`}
                            component={page.component}
                        />
                    ))}
                </Switch>
                <SignUpAside/>
            </div>
        </div>
    );
};

export const SignUp = withStores(SignUpWithStores, [STORES.Signup]);
