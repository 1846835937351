import React, { useState, useEffect, useRef } from 'react';
import { useOutsideClick } from '@src/hooks';
import classNames from 'classnames';

import './suggested-variables.sass';

interface FilteredVariables {
    value: string;
    name: string;
}

interface SuggestedVariables {
    optionsArr: any;
    onSelectVariable: (value: FilteredVariables) => void;
    onFilteredValues: (values: FilteredVariables[]) => void;
    filteredVariables: FilteredVariables[];
    message: string;
    cursorY: number;
    cursorX: number;
    activeIdx: number;
    cleanVariables: boolean;
    onShowingChange: (value: boolean) => void;
    showing: boolean;
}

export const SuggestedVariables: React.FC<SuggestedVariables> = (props) => {
    const { message, cursorY, cursorX, activeIdx, optionsArr, cleanVariables } = props;
    const isAVariable = message.match(/:(\w+)/gi) || [];
    const [showingVariables, setShowingVariables] = useState(props.showing);
    const suggestedVariablesRef = useRef(null);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    let globalIdx = -1;

    useOutsideClick(suggestedVariablesRef, () => {
        setShowingVariables(false);
        props.onShowingChange(false);
    });

    const suggestFilter = (variable) => {
        setShowingVariables(true);
        props.onShowingChange(true);
        const suggestions =
            optionsArr &&
            optionsArr.filter((val) => {
                return val.value.toLowerCase().includes(variable);
            });
        setFilteredSuggestions(suggestions);
        props.onFilteredValues(suggestions);
    };

    useEffect(() => {
        let variableSuggest;
        isAVariable.forEach((m) => {
            variableSuggest = m.toLowerCase();
            return variableSuggest;
        });
        suggestFilter(variableSuggest);
    }, [message]);

    useEffect(() => {
        if (cleanVariables) setFilteredSuggestions([]);
    }, [cleanVariables]);

    return (
        <div
            className="SuggestedVariables"
            style={
                showingVariables
                    ? {
                        top: `${cursorY - 180}px`,
                        left: `${cursorX - 50}px`,
                        bottom: '0px'
                      }
                    : null
            }
            ref={suggestedVariablesRef}
        >
            {filteredSuggestions.length > 0 &&
                showingVariables &&
                filteredSuggestions.map((filtered, i) => {
                    globalIdx++;
                    const isActive = globalIdx === activeIdx;
                    return (
                        <li
                            className={classNames('suggestion', {
                                active: isActive,
                            })}
                            key={i}
                            onClick={() => props.onSelectVariable(filtered)}
                        >
                            {filtered.value}
                        </li>
                    );
                })
            }
            {optionsArr &&
                showingVariables &&
                filteredSuggestions.length === 0 &&
                optionsArr.map((variable, i) => {
                    globalIdx++;
                    const isActive = globalIdx === activeIdx;
                    return (
                        <li
                            className={classNames('suggestion', {
                                active: isActive,
                            })}
                            key={i}
                            onClick={() => props.onSelectVariable(variable)}
                        >
                            {variable.value}
                        </li>
                    );
                })
            }
        </div>
    );
};