import React from 'react';
import { Dialog } from '@src/components/Dialog/Dialog';
import { NewNumber2 } from '@src/pages/Auth/SignUp/NewNumber2';

import '../../Signup.sass';

interface PhoneDialogProps {
    onClose: () => void;
    onNumberSelect: (phoneNumber: string) => void;
}

const PhoneDialog: React.FunctionComponent<PhoneDialogProps> = ({ onClose, onNumberSelect }) => (
    <Dialog
        onClose={onClose}
        open={true}
        className="setupSignupPhoneNumberDialog"
        noShadowBehind
    >
        <NewNumber2
            closeDialog={onClose}
            onNumberSelection={onNumberSelect}
        />
    </Dialog>
);

export default PhoneDialog;
