import React, { useState, useRef } from 'react';
import { FormField } from '@src/components';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { AsYouType } from 'libphonenumber-js';
import InputMask from 'react-input-mask';
import { getNumbers } from '@src/requests';
import { v4 as uuidv4 } from 'uuid';
import { useOutsideClick } from '@src/hooks';

import './NewNumberAfterSignup.sass';

interface NewNumberAfterSignupProps {
    newNumber: (number: string) => void;
    closeDialog?: () => void;
    view?: string;
    isNotOwnerOrLeader?: boolean;
}

export const NewNumberAfterSignup = (props: NewNumberAfterSignupProps) => {
    const { register, handleSubmit, errors } = useForm();
    const [numbers, setNumbers] = useState(null);
    const [emptyNumbers, setEmptyNumbers] = useState(false);
    const [number, setNumber] = useState('');
    const numberRef = useRef<HTMLInputElement>();
    const inputBodyRef = useRef<HTMLSpanElement>();

    useOutsideClick(inputBodyRef, () => {
        setNumbers(null);
    });

    const searchNumber = (num: string): void => {
        if (!num || num === "(___)" || num === "+1 (___) ___-____") {
            setEmptyNumbers(false);
            setNumber('');
            return;
        }
        const areaCode = num.slice(1, 4);
        if (areaCode.match(/^-{0,1}\d+$/)) {
            getNumbers({areaCode, regionCode: null}).then(({ data }) => {
                if (data.phoneNumbers.length !== 0) {
                    setNumbers(data.phoneNumbers);
                    setEmptyNumbers(false);
                } else {
                    setEmptyNumbers(true);
                }
            });
        } else if (numbers) setNumbers(null);
    };

    const pickNumber = (num): void => {
        setNumber(num);
        setNumbers(null);
        numberRef.current.value = num;
    };

    const newNumberSelected = () => {
        props.newNumber('+'.concat(number.replace(/\D/g, '')));
    };

    return (
        <div className='NewNumberAfterSignup'>
            <h2 className="NewNumberAfterSignup__header">Set a Phone Number</h2>
            {props.isNotOwnerOrLeader ?
                <div className="infoForMember">Your account has not phone number set. You cannot use the messaging feature without it. Reach out to your manager to set the phone number.</div>
            :
                <>
                    <h4 className="instruction">
                        Myopolis will create a unique phone number for your business to send review requests
                        and messages from.
                    </h4>
                    <form className="NewNumberAfterSignup__form" onSubmit={handleSubmit((d, e) => { e.preventDefault() })}>
                        <div>
                            <span ref={inputBodyRef}>
                                <div className="NewNumberAfterSignup__form-selectPhoneInput">
                                    <FormField
                                        label={!number ? 'Desired Area Code' : 'Selected number'}
                                        htmlFor="phone"
                                    >
                                        <InputMask
                                            type="text"
                                            mask={!number ? '(999)' : '+1 (999) 999-9999'}
                                            maskChar="_"
                                            name="phone"
                                            placeholder="Type in the first 3 digits"
                                            className={classNames('form-input', { error: errors.phone })}
                                            ref={(e) => {
                                                register({ // to jest registered?
                                                    required: 'Missing Value',
                                                });
                                                numberRef.current = e;
                                            }}
                                            onChange={(e) => searchNumber(e.target.value)}
                                        />
                                    </FormField>
                                </div>
                                {numbers && (
                                    <div className='NewNumberAfterSignup__form-numbers'>
                                        {numbers.map((n) => {
                                            return (
                                                <button key={uuidv4()} onClick={() => pickNumber(n)}>
                                                    {new AsYouType('US').input(
                                                        ('' + n).replace(/\D/g, '')
                                                    )}
                                                </button>
                                            );
                                        })}
                                    </div>
                                )}
                            </span>
                            {(!emptyNumbers && !number && !numbers) ?
                                <div className="NewNumberAfterSignup__form-phoneComment">You must select a number to use the messaging feature</div>
                            : null}
                            {emptyNumbers && (
                                <div className="NewNumberAfterSignup__form-noNumbersAvailable">
                                    There are no phone numbers available for the specified area. Please try
                                    an alternative Area Code or check back later.
                                </div>
                            )}
                        </div>
                        {number && (
                            <div className="NewNumberAfterSignup__form-attention">
                                <span>
                                    Make sure you&apos;re happy with your choice, as this number can&apos;t
                                    be changed once selected.
                                </span>
                            </div>
                        )}
                        {number ?
                            <div className="NewNumberAfterSignup__form-buttons">
                                {props.view !== 'messages' ? (
                                    <button className="btn-secondary" onClick={() => props.closeDialog()}>
                                        Cancel
                                    </button>
                                ) : null}
                                <button
                                    className={classNames('btn-primary', { newNumberConfirm: props.view === 'messages' })}
                                    onClick={newNumberSelected}
                                >
                                    Confirm
                                </button>
                            </div>
                        : null}
                    </form>
                </>
            }
        </div>
    );
};
