import React, { useEffect } from 'react';

import { BrowserRouter as Router, Route, Redirect, Switch, useLocation } from 'react-router-dom';

import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Layout from './components/Layout/Layout';
import { authPages, nonAuthPages } from './pages';
import { OAuthConsent } from './pages/Auth/OAuthConsent/OAuthConsent';
import { AuthStore } from './stores/auth.store';
import { FC } from 'react';
import { withStores } from './stores/with-store';
import { UserStore } from './stores/user.store';
import { REDIRECT_BACK_TO_LOCALSTORAGE_KEY } from '@src/theme/utils/constants';
import { Loader } from './components';
import {startCheckingVersion} from "@src/utils/versions";

const AUTH_PATHS = ['/signin', '/signup', '/forgot', '/confirm', '/activate'];

const AuthRoutes = ({ auth, signUp, ...rest }) => {
    const location = useLocation();

    const resetPath = '/user/password/reset/';

    if (!auth && location.pathname.search(resetPath) === 0) {
        const path = location.pathname.replace(resetPath, '/forgot/');

        return <Redirect to={path} {...rest} />;
    }

    const confirmEmailPath = '/account/confirm/';

    if (location.pathname.search(confirmEmailPath) === 0) {
        const path = location.pathname.replace(confirmEmailPath, `/confirm/`);

        return <Redirect to={path} {...rest} />;
    }

    const activationPath = '/account/';

    if (!auth && location.pathname.search(activationPath) === 0) {
        const path = location.pathname.replace(activationPath, '/activate/');

        return <Redirect to={path} {...rest} />;
    }

    if (auth && auth.accountStatus !== 'INCOMPLETE') {
        return <Route {...rest} />;
    } else if (AUTH_PATHS.some((path) => location.pathname.search(path) != -1)) {
        return <Route {...rest} />;
    } else if (signUp && location.pathname !== '/signupfull') {
        return <Redirect to="/signupfull" {...rest} />;
    } else {
        const redirectBackTo = location.pathname.replace("/app/", "") + location.search;
        localStorage.setItem(REDIRECT_BACK_TO_LOCALSTORAGE_KEY, redirectBackTo)
        const redirectOptions = {
            pathname: '/signin',
        };
        return <Redirect to={redirectOptions} {...rest} />;
    }
};

const LoginRoute = (props) => {
    const { auth, lockedAccount, ...rest } = props;
    const role = auth?.employee?.role;

    if (auth && !lockedAccount) {
        return <Redirect to="/dashboard" {...rest} />;
    } else if (auth && role && role < 2) {
        return <Redirect to="/settings" {...rest} />;
    } else if (auth && role) {
        return <Redirect to="/customers" {...rest} />;
    } else {
        return <Route {...rest} />;
    }
};


const AppWithStore: FC<{AuthStore: AuthStore, UserStore: UserStore}> = ({AuthStore: AuthStore, UserStore: UserStore}) => {
    const loadAuth = () => {
        AuthStore.loadAuth().then(() => {
        }).catch((err) =>  {
            console.log('Load Auth Error:', err)
        });
    };

    useEffect(() => {
        if (UserStore.user) {
            AuthStore.setAppLoaded();
        } else {
            if (!UserStore.signUpUser) {
                loadAuth();
            }
        }
    }, [UserStore.user, UserStore.signUpUser])

    useEffect(() => {
        startCheckingVersion()
    }, [])

    const dashboardPage = authPages[0];
    const settingsPage = authPages.find((el) => el.label === 'settings');
    const customersPage = authPages.find((el) => el.label === 'customers');

    const redirectBackToRoute = !UserStore.user &&
        <Route render={() => {
            const dontRedirectTo = [...AUTH_PATHS, '/user/password/reset', '/account'];
            const doesUrlContain = dontRedirectTo.find(el => location.pathname.search(el) > -1);
            if (!doesUrlContain || location.pathname.search('/settings/account') > -1) {
                const redirectBackTo = location.pathname.replace("/app/", "") + location.search;
                localStorage.setItem(REDIRECT_BACK_TO_LOCALSTORAGE_KEY, redirectBackTo);
            }
            const dividedUrl = location.pathname.split('/');
            const newPathname =
                (location.pathname.search('/account/') > -1 && location.pathname.search('/account/confirm/') === -1) ?
                `/activate/${dividedUrl[dividedUrl.length - 1]}` :
                location.pathname.search('/account/confirm/') > -1 ?
                `/confirm/${dividedUrl[dividedUrl.length - 1]}` :
                location.pathname.search('/user/password/reset/') > -1 ?
                `/forgot/${dividedUrl[dividedUrl.length - 1]}` :
                '/signin';
            const redirectOptions = {
                pathname: newPathname,
            };
            return <Redirect to={redirectOptions} />;
        }} />;

    const authedRoutesWithLayout = UserStore.user && (
        <Layout>
            <AuthRoutes
                path="/"
                auth={UserStore.user}
                signUp={UserStore.signUpUser}
            >
                <Switch>
                    {authPages
                        .filter(
                            (page) =>
                                (!page.hideForMembers ||
                                    UserStore.user?.employee?.role < 2) // &&
                                // (!page.hideForSubscriptionProblem ||
                                //     AuthStore.accountStatus !== 'LOCKED')
                        )
                        .map((page) => (
                            <Route
                                key={page.path}
                                path={page.path}
                                component={page.component}
                            />
                        ))
                    }
                    <Redirect
                        from="/"
                        exact
                        to={
                            dashboardPage.path
                            // AuthStore.accountStatus !== 'LOCKED'
                            //     ? dashboardPage.path
                            //     :
                                // UserStore.user?.employee?.role < 2
                                // ? settingsPage.path
                                // : customersPage.path
                        }
                    />
                    <Redirect to={
                        dashboardPage.path
                        // AuthStore.accountStatus !== 'LOCKED'
                        //     ? dashboardPage.path
                        //     :
                            // UserStore.user?.employee?.role < 2
                            // ? settingsPage.path
                            // : customersPage.path
                        }
                    />
                </Switch>
            </AuthRoutes>
        </Layout>
    );

    if (AuthStore.appLoaded) {
        return (
            <Router basename={process.env.BASE_PATH}>
                <Switch>
                    <LoginRoute
                        auth={UserStore.user}
                        lockedAccount={AuthStore.accountStatus === 'LOCKED'}
                        path="/signin"
                        component={nonAuthPages['signin'].component}
                    />
                    <LoginRoute
                        auth={UserStore.user}
                        signUp={UserStore.signUpUser}
                        path="/signupfull"
                        component={nonAuthPages['signup'].component}
                    />
                    <LoginRoute
                        auth={UserStore.user}
                        signUp={UserStore.signUpUser}
                        path="/signup"
                        component={nonAuthPages['signup2'].component}
                    />
                    <LoginRoute
                        auth={UserStore.user}
                        component={nonAuthPages['forgot'].component}
                        path="/forgot/:token"
                    />
                    <LoginRoute
                        auth={UserStore.user}
                        component={nonAuthPages['forgot'].component}
                        path="/forgot"
                    />
                    <LoginRoute
                        auth={UserStore.user}
                        component={nonAuthPages['confirm'].component}
                        path="/confirm/:token"
                    />
                    <LoginRoute
                        auth={UserStore.user}
                        component={nonAuthPages['activate'].component}
                        path="/activate/:token"
                    />
                    <AuthRoutes
                        path="/oauth-consent"
                        auth={UserStore.user}
                        signUp={UserStore.signUpUser}
                    >
                        <Route
                            key="/oauth-consent"
                            path="/oauth-consent"
                            component={OAuthConsent}
                        />
                    </AuthRoutes>

                    <Redirect
                        from="/"
                        exact
                        to="/signin"
                    />
                    {authedRoutesWithLayout || redirectBackToRoute}
                </Switch>
                <div>
                    <ToastContainer autoClose={false} hideProgressBar={true} />
                </div>
            </Router>
        );
    } else return <Loader loading={true} />;
};

export const App = withStores(AppWithStore, ["AuthStore", "UserStore"]);
