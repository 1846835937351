export enum SocketEventType {
    Error = '_error',
    Notification = '_notification',
    NewMessageEvent = '_message',
    ThreadCount = '_getOpenThreadCounts',
    // OpenThread = '_thread_assigned',
    Review = '_review',
    AllReviewsStats = '_getAllReviewStats',
    Unexpected = '_unexpected',
    ThreadStatus = '_thread_status',
    NoteAdded = '_note_added',
    MessageSent = '_message_sent',
    VoiceMail = '_voice_mail',
    PhoneCall = '_phone_call',
    ThreadAssigned = '_thread_assigned',
    LogUserOut = '_loguserout',
    TeamMessage = '_team_message',
    TeamMessageUpdated = '_team_message_updated',
    TeamMessageDeleted = '_team_message_deleted',
    TeamMembershipChange = '_team_membership_change',
    GetThread = '_getThread',
    BroadcastCompleted = '_broadcast_completed',
    MessageStatusChange = '_message_status_change',
}
