import React from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { Elements } from 'react-stripe-elements';
import { STORES, WithBillingStoreProps, withStores } from '@src/stores/with-store';
import { PaymentInsideStripe } from '@src/components/PaymentInsideStripe/PaymentInsideStripe';
import { SingleBillingPlan } from '@src/stores/models';

import './Signup.sass';

interface CCSetupProps {
    user: any;
    selectedPlan: SingleBillingPlan;
    selectedBuyNow: boolean;
    selectedPhone: string;
    changedContinueBtn: boolean;
    onBack: () => void;
    onPlanChange: ({priceId: string, buyNow: boolean}) => void;
};

export interface PaymentProps {
    stripe: any;
}

type props = CCSetupProps & PaymentProps & WithBillingStoreProps;

export const PaymentForStripe: React.FC<props> = (props) => {
    if (!props.user?.stripeKey) return null;

    return (
        <StripeProvider apiKey={props.user?.stripeKey}>
            <Elements>
                <div>
                    <PaymentInsideStripe
                        paymentCase={'addPaymentOnSignup'}
                        user={props.user}
                        stepBack={props.onBack}
                        phone={props.selectedPhone}
                        selectedPlan={props.selectedPlan}
                        selectedBuyNow={props.selectedBuyNow}
                        confirmBtn={props.changedContinueBtn ? 'Complete trial signup' : 'Confirm'}
                        onPlanChange={props.onPlanChange}
                        // confirmBtn="Complete signup"
                    />
                </div>
            </Elements>
        </StripeProvider>
    );
};

export const CCSetup = withStores(PaymentForStripe, [STORES.Billing]);
